import React from "react";
import ChatContext from "../ChatContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  createNewChatGuest,
  getChatMessage
} from "../../../services/chatPersonalService";

const convertToTime = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate() > 9 ? a.getDate() : "0" + a.getDate();
  var hour = a.getHours() > 9 ? a.getHours() : "0" + a.getHours();
  var min = a.getMinutes() > 9 ? a.getMinutes() : "0" + a.getMinutes();
  var sec = a.getSeconds() > 9 ? a.getSeconds() : "0" + a.getSeconds();
  var time =
    date + "  " + month + "  " + year + " - " + hour + ":" + min + ":" + sec;
  return time;
};

const ContactCard = (props) => {
  const ContextEffect = React.useContext(ChatContext);
  const [startLoading, setStartLoading] = React.useState(false);

  const chatObj = React.useRef(ContextEffect.chatObject);
  chatObj.current = ContextEffect.chatObject;


  var searchMoreMessages = false;
  var hasError = false;
  var messagesReceived = 0;
  var newMessages = 0;

  const getMessagesAPI = (
    first = false,
    moreMessages = false,
    lastIndex,
    chatId
  ) => {
      //Verifica quantas mensagens foram recebidas e quantas não estavam no array
      //Caso o array inteiro não estiver na lista, buscar a próxima página até encontrar a ultima mensagem
      messagesReceived = 0;

      var finalMessages = first ? [] : ContextEffect.messages;

      if (!first && messagesReceived === newMessages) {
        searchMoreMessages = true;
      }

      //Ordenar mensagens
      finalMessages = finalMessages.sort(function (a, b) {
        return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
      });

      ContextEffect.setMessages(finalMessages);
      ContextEffect.setPageIndex(0);
      ContextEffect.setTotalPageIndex(0);

      ContextEffect.privateChat.find((item) => {
        return item.chatId === chatId;
      });

      //Marca a ultima mensagem como lida
  };
  const getAllPrivateMessages = () => {
    if(!chatObj.current.chat_id) return;
    let Oldmessages = ContextEffect.messages;
    let Newmessages = [];
    getChatMessage(chatObj.current.chat_id, 30, 1).then(result => {
      const { messages, totalPages } = result.data;
      if(messages.length > 0){
        let messagesTemp = [];
        messages.forEach(element => {
          messagesTemp.push(element);
        });
        messagesTemp = messagesTemp.sort(function (a, b) {
          return a.dateCreated > b.dateCreated ? 1 : b.dateCreated > a.dateCreated ? -1 : 0;
        });
        Newmessages = [...messagesTemp];
        ContextEffect.setMessages(messagesTemp);

      } else {
        ContextEffect.setMessages([]);
      }
    })
  }
  const onClickNewChat = () => {
    
    let currentNewChat = {
      chat_id: props.chatObject.chat_id,
      id: props.chatObject.id,
      name: props.chatObject.name,
      avatar: props.chatObject.avatar,
      institution: props.chatObject.institution
    }    
    ContextEffect.setNewChatObject(currentNewChat);
    props.onChatSelectPress(true);
    getAllPrivateMessages();
    ContextEffect.setMessages([]);
    /*ContextEffect.setMessages([...nulled]);
    ContextEffect.setcurrenGuestID(props.chatObject.guests[1].guestHasChatId);
    ContextEffect.setcurrenChatID(props.chatObject.chatId);
    ContextEffect.setNoMoreOldMessages(false);
    props.onChatSelectPress(true);
    ContextEffect.setNewChatObject({
      name: props.name,
      avatar: props.chatObject.image,
      lastActive: props.chatObject.dateCreatedLastMessage,
    });*/
    //getMessagesAPI(true, false, 0, props.chatObject.chatId);
  };

  const onClickNewPrivateChat = () => {
    setStartLoading(true);

    createNewChatGuest({
      "eventId": props.myCurrentUser.eventId,
      "temp" : true,
      "members": [
        {
          "guestId": props.guestObject.id,
          "name": props.guestObject.name,
          "imageUrl": props.guestObject.image,
          "institution": props.guestObject.institution
        },
        {
          "guestId": props.myCurrentUser.id,
          "name": props.myCurrentUser.name,
          "imageUrl": props.myCurrentUser.image,
          "institution": props.myCurrentUser.company
        }
      ]
    }).then(result => {
      let currentNewChat = {
        chat_id: result.data.data.id,
        id: props.guestObject.id,
        name: props.guestObject.name,
        avatar: props.guestObject.image,
        institution: props.guestObject.institution
      }
      let privates = [];
      ContextEffect.setPrivateChat(
        privates.concat(ContextEffect.privateChat, currentNewChat)
      );

      props.addNewPrivateChat();
      setStartLoading(false);
      ContextEffect.setMessages([]);
      
      ContextEffect.setNewChatObject(currentNewChat);
      props.onChatSelectPress(true);
      
      return;
      if (
        ContextEffect.privateChat.filter(
          (i) => i.guests[1].guestId === props.guestObject.id
        ).length < 1
      ) {
        setStartLoading(true);
        props.addNewPrivateChat(props.guestObject).then((result) => {
          setStartLoading(false);
          ContextEffect.setMessages([]);
          ContextEffect.setNewChatObject({
            name: result.newChat.guestName,
            avatar: result.newChat.image,
            lastActive: "",
          });
          //getMessagesAPI(true, false, 0, result.newChat.chatId);
        });
      } else {
        let tempChatObj = ContextEffect.privateChat.filter(
          (i) => i.guests[1].guestId === props.guestObject.id
        )[0];
        let nulled = [];
        ContextEffect.setMessages([...nulled]);
        ContextEffect.setcurrenGuestID(tempChatObj.guests[1].guestHasChatId);
        ContextEffect.setcurrenChatID(tempChatObj.chatId);
        ContextEffect.setNoMoreOldMessages(false);
        props.onChatSelectPress(true);
        ContextEffect.setNewChatObject({
          name: tempChatObj.guests[0].name,
          avatar: !tempChatObj.guests[0].urlPhoto
            ? "https://s3.amazonaws.com/bucket.aws.public/FotoPerfilInteegra/DEFAULT.png"
            : tempChatObj.guests[0].urlPhoto,
          lastActive: "",
        });
        //getMessagesAPI(true, false, 0, tempChatObj.chatId);
      }

    })
  };
  React.useEffect(() => {
    if (props.chatId == ContextEffect.currenChatID) {
      //ContextEffect.setNewChatObject({ name: props.name });
    }
    if (props.selected) {
      onClickNewChat();
    }
    
  }, []);
  
  return (
    <div
      className="row sideBar-body card-hover"
      style={
        props.areadyPrivate ? 
          props.id == chatObj.current.id
            ? { backgroundColor: "#f2f2f2" }
            : { backgroundColor: "white" }
        : { backgroundColor: "white" }
      }
      onClick={() =>
        props.areadyPrivate ? onClickNewChat() : onClickNewPrivateChat()
      }
    >
      <div className="col-sm-3 col-xs-3 sideBar-avatar">
        <div className="avatar-icon">
          {props.areadyPrivate ? (
            <img
              src={
                props.chatObject
                  ? props.chatObject.avatar == "" ? "https://s3.amazonaws.com/bucket.aws.public/FotoPerfilInteegra/DEFAULT.png" : props.chatObject.avatar
                  : "https://s3.amazonaws.com/bucket.aws.public/FotoPerfilInteegra/DEFAULT.png"
              }
            />
          ) : (
            <img
              src={
                props.avatar
                  ? props.avatar
                  : "https://s3.amazonaws.com/bucket.aws.public/FotoPerfilInteegra/DEFAULT.png"
              }
            />
          )}
          {props.areadyPrivate && (
            <div
              style={{
                backgroundColor: props.chatObject.online ? "#0f8c00" : "red",
                width: 10,
                height: 10,
                position: "absolute",
                left: "61%",
                bottom: 0,
                borderRadius: 12,
              }}
            />
          )}
        </div>
      </div>
      <div className="col-sm-9 col-xs-9 sideBar-main">
        <div className="row">
          <div className="col-sm-9 col-xs-9 sideBar-name">
            <span className="name-meta">{props.name}</span>
            <p>
              <span
                className="name-meta"
                style={{ color: "#a2a2a2", fontSize: 14 }}
              >
                {!props.areadyPrivate && props.guestObject.institution}
                {props.data && props.data.messages && props.data && props.data.messages && props.data.messages.length > 0 && props.data.messages[props.data.messages.length - 1].message}
              </span>
            </p>
          </div>
          <div className="col-sm-3 col-xs-3 pull-right sideBar-time">
            <span className="time-meta pull-right">
              {startLoading ? (
                <CircularProgress
                  color="#337ab7"
                  style={{ width: 20, height: 20 }}
                />
              ) : props.areadyPrivate ? (
                !props.chatObject.lastMessageSaw ? (
                  <span style={{ color: "#0f8c00", fontWeight: 700 }}>
                    
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
