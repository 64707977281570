import axios from 'axios';
import { store } from '../store';
import { getDic } from '../assets/i18n/dictionary';
import { getToken, getUrl } from './apiService';
import { getFileExtension, getFileName } from '../utils';
import { createFileModel, inteegraflixExternalUrlModel } from '../models/fileModel';
import moment from 'moment';

export async function setNewFormDataFile(file, contractId, userId, category, eventId, isInternal, progressEvent) {
	const token = await getToken();
	var method = getUrl().fileServer + 'file/form';

	var formData = new FormData();

	const result = await convertFileToBase64(file).catch((e) => Error(e));
	if (result instanceof Error) {
		console.log('Error: ', result.message);
		return;
	}

	var fileName = getFileName(file);
	var fileExtension = getFileExtension(file);
	var arquive = result.split(';base64,');

	formData.append('bucketName', 'bucket.aws.inteegra');
	formData.append('contract', parseInt(contractId));
	formData.append('table', 'Hall_Pax_Upload');
	formData.append('tableId', parseInt(userId) ? parseInt(userId) : 0);
	formData.append('name', isInternal ? fileName : userId ? userId.toString() : fileName);
	formData.append('extension', fileExtension);
	formData.append('size', file.size);
	formData.append('file', file);
	formData.append('isPublic', true);
	formData.append('category', category);
	formData.append('eventId', parseInt(eventId) ? parseInt(eventId) : 0);

	return new Promise((resolve, reject) => {
		axios
			.post(method, formData, {
				headers: { Authorization: 'bearer ' + token },
				onUploadProgress: progressEvent
			})
			.then((res) => {
				if (res.data.success) {
					var _year = res.data.data.dateCreated.split("-");
					var _obj = {
						...res.data.data,
						url: `https://s3.amazonaws.com/bucket.aws.public/${_year[0]}/${res.data.data.id}.${res.data.data.extension}`
					}
					resolve(_obj);
				} else {
					reject(res.data);
				}
			})
			.catch((err) => {
				console.log('Erro setNewFileApi', err);
				reject(err);
			});
	});
}

export async function setNewFileApi(file, contractId, userId, category, eventId, isInternal, progressEvent) {
	const token = await getToken();
	var method = getUrl().fileServer + 'file';

	// const file = document.querySelector('#myfile').files[0];
	const result = await convertFileToBase64(file).catch((e) => Error(e));
	if (result instanceof Error) {
		console.log('Error: ', result.message);
		return;
	}

	var fileName = getFileName(file);
	var fileExtension = getFileExtension(file);
	var arquive = result.split(';base64,');

	return new Promise((resolve, reject) => {
		const options = {
			url: method,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'bearer ' + token
			},
			data: {
				bucketName: 'bucket.aws.inteegra',
				contract: parseInt(contractId),
				table: 'Hall_Pax_Upload',
				tableId: parseInt(userId) ? parseInt(userId) : 0,
				// name: fileName[0],
				name: isInternal ? fileName : userId ? userId.toString() : fileName,
				extension: fileExtension,
				size: file.size,
				file: arquive[1],
				isPublic: true,
				category: category,
				eventId: parseInt(eventId) ? parseInt(eventId) : 0
			}
		};
		if (progressEvent) options['onUploadProgress'] = progressEvent;
		axios(options)
			.then((res) => {
				if (res.data.success) {
					resolve(res.data.data);
				} else {
					reject(res.data);
				}
			})
			.catch((err) => {
				console.log('Erro setNewFileApi', err);
				reject(err);
			});
	});
}

function convertFileToBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

export async function checkIfFileExists() {
	try {
		const _token = await getToken();
		let _event = store.getState().event;
		let _user = store.getState().user;

		const options = {
			url: `${getUrl().base}upload/file/api/file/created?Contract=${_event.contractId}&TableId=${_user.id}&EventId=${_event.id}&Table=Hall_Pax_Upload`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'bearer ' + _token
			},
		};

		await axios(options);

		return true;
	} catch (error) {
		console.log('Error checkIfFileExists', error);
		throw error;
	}
}

export async function getFilesListApi(pageIndex, category) {
	const token = await getToken();
	const event = store.getState().event;
	var method = getUrl().fileServer + 'file/list';

	return new Promise((resolve, reject) => {
		const options = {
			url: method,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'bearer ' + token
			},
			data: {
				contract: event.contractId,
				table: 'Hall_Pax_Upload',
				name: null,
				extension: null,
				category: category ? category : null,
				dateCreated: null,
				pageIndex: pageIndex,
				pageSize: 100,
				eventId: event.id
			}
		};
		axios(options)
			.then((res) => {
				if (res.data.success) {

					const filesList = [];

					if (res.data.data.files) {
						res.data.data.files.forEach((item) => {
							if (item.active) {
								const file = createFileModel(
									item.id,
									item.contract,
									item.tableId,
									item.dateCreated,
									item.name,
									item.extension,
									item.size,
									item.category
								);
								filesList.push(file);
							}
						});
					}

					resolve({ filesList, totalPages: res.data.data.totalPages });
				} else {
					reject(res.data);
				}
			})
			.catch((err) => {
				console.log('Erro getFilesListApi', err);
				reject(err);
			});
	});
}

export async function convertConfigUrlListInFilesList(config) {
	var _filesList = [];
	const _event = store.getState().event;
	if (config.html && config.html !== "") {
		const _objects = config.html.replace(/(?:\r\n|\r|\n)/g, '').split(";");
		_objects.forEach((obj, index) => {
			var _object = obj.split(",");
			var _urlObj = inteegraflixExternalUrlModel();
			var _fileString = "";
			var _extension = "";
			var _name = "";

			_object.forEach(param => {
				var _param = param.split(/:(.+)/)[0].replace(/ /g, ""); //Replace all;
				var _value = param.split(/:(.+)/)[1];
				_urlObj[_param] = _value;

				if (_param === "url") {
					_fileString = _value.slice(_value.lastIndexOf('/') + 1);
					_name = _fileString.slice(0, _fileString.lastIndexOf('.'));
					_name = decodeURIComponent(_name);
					_extension = _fileString.slice(_fileString.lastIndexOf('.') + 1);
				}
			});

			const file = createFileModel(
				index,
				_event.contractId ? _event.contractId : null,
				null,
				moment().format(),
				_name,
				_extension,
				null,
				_urlObj.categoria,
				_urlObj.url
			);
			_filesList.push(file);
		});
	}
	return _filesList;
}

export async function getCategoriesListApi() {
	const token = await getToken();
	const event = store.getState().event;
	var method = getUrl().fileServer + `file/categories?eventId=${event.id}`;
	return new Promise((resolve, reject) => {
		const options = {
			url: method,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'bearer ' + token
			}
		};
		axios(options)
			.then((res) => {
				if (res.data) {
					const categoriesList = [];

					const defaultItem = {
						text: getDic('selecione'),
						value: null
					};
					categoriesList.push(defaultItem);

					res.data.forEach((item) => {
						const obj = {
							text: item,
							value: item
						};
						categoriesList.push(obj);
					});

					resolve(categoriesList);
				} else {
					reject(res.data);
				}
			})
			.catch((err) => {
				console.log('Erro getCategoriesListApi', err);
				reject(err);
			});
	});
}

export async function addNewUserImageApi(file) {
	const token = await getToken();

	const user = store.getState().user;

	var method = getUrl().hall + 'event/guest/file';

	const result = await convertFileToBase64(file).catch((e) => Error(e));
	if (result instanceof Error) {
		console.log('Error: ', result.message);
		return;
	}

	var fileName = getFileName(file);
	var fileExtension = getFileExtension(file);
	var arquive = result.split(';base64,');

	return new Promise((resolve, reject) => {
		const options = {
			url: method,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'bearer ' + token
			},
			data: {
				id: user.id,
				file: arquive[1],
				bucketName: 'bucket.aws.inteegra',
				fileName: fileName,
				extension: fileExtension
			}
		};
		axios(options)
			.then((res) => {
				if (res.data.success && res.data.data && res.data.data.avatarProfileLink) {
					resolve(res.data.data.avatarProfileLink);
				} else {
					reject(res.data);
				}
			})
			.catch((err) => {
				console.log('Erro addNewUserApi', err);
				reject(err);
			});
	});
}

export async function addNewUserImageApiAvatar(imageSrc) {
	const token = await getToken();
	const user = store.getState().user;
	var method = getUrl().hall + 'event/guest/url-file';
	const urlRedirect = window.location.href.split('/createavatar')[0];
	return new Promise((resolve, reject) => {
		const options = {
			url: method,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'bearer ' + token
			},
			data: {
				id: user.id,
				imageUrl: imageSrc
			}
		};
		axios(options)
			.then((res) => {
				if (res.data.success && res.data.data && res.data.data.avatarProfileLink) {
					resolve(res.data.data.avatarProfileLink);
					alert('Seu avatar foi adicionado');
					// window.location = urlRedirect + '/eventhall';
				} else {
					alert('Ocorreu um erro');
					reject(res.data);
				}
			})
			.catch((err) => {
				alert('Ocorreu um erro');
				console.log('Erro addNewUserApi', err);
				reject(err);
			});
	});
}

export function checkFileFormat(url) {
	try {
		const imageFormats = ["JPG", "JPEG", "GIF", "BMP", "PNG", "RAW", "WEBP", "JFIF"];
		const videoFormats = ["MP4", "WMV", "WMA", "ASF", "MOV", "FLV", "RM", "RMVB", "MKV", "MKS", "AVI"];
		const audioFormats = ["MPEG", "PCM", "WAV", "AIFF", "MP3", "AAC", "OGG", "FLAC", "ALAC", "M4A"];

		let _array = url.split('.');
		let _extension = _array[_array.length - 1];

		if (!_extension) {
			return '';
		}

		if (imageFormats.includes(_extension.toUpperCase())) {
			return 'image';
		}

		if (videoFormats.includes(_extension.toUpperCase())) {
			return 'video';
		}

		if (audioFormats.includes(_extension.toUpperCase())) {
			return 'audio';
		}
		return '';
	} catch (error) {
		console.log('Error checkFileFormat', error);
		throw error;
	}
}
