import React from 'react';
import { Player, BigPlayButton, ControlBar, VolumeMenuButton } from 'video-react';
import { isMobile } from "react-device-detect";

const VideoPlayer = ({
  src = '',
  autoPlay = false,
  width,
  height,
}) => {

  //Caso seja mobile, adaptar player para o tamanho da tela
  var viewport = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  const styles = {
    video: {
      backgroundColor: 'transparent',
      width: width ? width : (isMobile ? viewport.width + "px" : '100%'),
      height: height ? height : (isMobile ? viewport.width + "px" : '100%'),
    }
  };

  // if (src && src.toUpperCase().includes('.MP4')) {
  //   return (
  //     <div style={styles.video}>
  //       <video width='100%' height='100%' autoplay={autoPlay}>
  //         <source src={src} type='video/mp4; codecs="hvc1"'></source>
  //       </video>
  //     </div>
  //   )
  // }
  return (
    <div style={styles.video}>
      <Player autoPlay={autoPlay}>
        <BigPlayButton position="center" />
        <ControlBar>
          <VolumeMenuButton vertical />
        </ControlBar>
        <source src={src} />
      </Player>
    </div>
  )
};

export default VideoPlayer;
