import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import VideoIcon from '@material-ui/icons/PlayCircleFilled';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import SiteIcon from '@material-ui/icons/WebAsset';
import EmailIcon from '@material-ui/icons/Email';
import { isMobile } from "react-device-detect";

//Imports de components
import {
    Container,
    Avatar,
    FormRow,
    ActivityIndicator,
    Card,
    TextBig,
    TextMedium,
    TextSmall,
    MenuButton,

    BackButton
} from '../components';

class SpeakersPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            event: this.props.event,
            speakers: this.props.speakers,
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    componentDidCatch(e) {
        var error = e.toString();

        if (error.includes("Error: Maximum update depth exceeded")) {
            window.location.reload();
        }
    }

    render() {
        if (this.state.isLoading)
            return (
                <Container background="#e8eced" align="center" >
                    <FormRow align="center">
                        <ActivityIndicator />
                    </FormRow>
                </Container>
            );
        return (
            <Container align="center" background="#212121">
                {!this.props.isModal && (
                    <>
                        <MenuButton history={this.props.history} />
                        <BackButton history={this.props.history} color="#212121">
                            {getDic("voltar").toUpperCase()}
                        </BackButton>

                    </>
                )}

                <FormRow paddingTop="7vh" align="center" background="#212121" margin="0px">
                    {this.state.speakers.map((item) => {
                        return (
                            <div key={item.id} style={{ margin: "10px", width: '100%', flexGrow: 1, position: 'relative' }}>
                                <Card>
                                    <FormRow align="center">
                                        <FormRow align="center">
                                            <Avatar size="big" src={item.image}></Avatar>
                                        </FormRow>
                                        <FormRow align="center">
                                            <TextBig fontWeight="bold">
                                                {item.name.toUpperCase()}
                                            </TextBig>
                                        </FormRow>
                                        <FormRow align="center">
                                            <TextBig>
                                                {item.company.toUpperCase()}
                                            </TextBig>
                                        </FormRow>
                                        <FormRow align="center">
                                            <TextMedium>
                                                {item.role}
                                            </TextMedium>
                                        </FormRow>
                                        <FormRow align="center" paddingLeft="10px" paddingRight="10px">
                                            <TextSmall textAlign="justify">
                                                {item.biography}
                                            </TextSmall>
                                        </FormRow>
                                        <FormRow align="center">
                                            {item.video && item.video !== "" ? (
                                                <a title={getDic("video")} style={{ color: "#212121" }} href={item.video} target="_blank">
                                                    <VideoIcon />
                                                </a>
                                            ) : (null)}
                                            {item.facebook && item.facebook !== "" ? (
                                                <a title="Facebook" style={{ color: "#212121" }} href={item.facebook} target="_blank">
                                                    <FacebookIcon />
                                                </a>
                                            ) : (null)}
                                            {item.twitter && item.twitter !== "" ? (
                                                <a title="Twitter" style={{ color: "#212121" }} href={item.twitter} target="_blank">
                                                    <TwitterIcon />
                                                </a>
                                            ) : (null)}
                                            {item.linkedin && item.linkedin !== "" ? (
                                                <a title="LinkedIn" style={{ color: "#212121" }} href={item.linkedin} target="_blank">
                                                    <LinkedInIcon />
                                                </a>
                                            ) : (null)}
                                            {item.site && item.site !== "" ? (
                                                <a title="Web Site" style={{ color: "#212121" }} href={item.site} target="_blank">
                                                    <SiteIcon />
                                                </a>
                                            ) : (null)}
                                            {item.email && item.email !== "" ? (
                                                <a title="Email" style={{ color: "#212121" }} href={"mailto:" + item.email} target="_blank">
                                                    <EmailIcon />
                                                </a>
                                            ) : (null)}
                                        </FormRow>
                                    </FormRow>
                                </Card>
                            </div>
                        );
                    })}
                </FormRow>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        event: state.event,
        speakers: state.speakers,
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SpeakersPage)
