import axios from "axios";
import { store } from "../store";
import { getUrl, getToken } from "./apiService";
import {
  createRankingModel,
  createPointCompositionModel,
} from "../models/gameficationModel";

export async function getRankingApi3DVista(_user_id) {
  const token = await getToken();
  const _event = store.getState().event;
  var method =
    getUrl().direct + `gamification-external/api/classification/${_user_id}`;
  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res && res.data) {
          const _objReturn = [];
          res.data.forEach((item) => {
            _objReturn.push(item);
          });

          resolve(_objReturn);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro getRankingApi", err);
        reject(err);
      });
  });
}

export async function getRankingApi() {
  const token = await getToken();
  const _event = store.getState().event;
  var method =
    getUrl().hall + `event/gamification/ranking?eventId=${_event.id}&top=10`;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res && res.data) {
          const _objReturn = [];
          res.data.forEach((item) => {
            const _obj = createRankingModel(
              item.id,
              item.guestId,
              item.name,
              item.points,
              item.level.id,
              item.level.name,
              item.photoUrl
            );
            _objReturn.push(_obj);
          });

          resolve(_objReturn);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro getRankingApi", err);
        reject(err);
      });
  });
}

export async function getPointComposition() {
  const token = await getToken();
  const _user = store.getState().user;
  var method = getUrl().hall + `event/gamification/points-describe/${_user.id}`;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res && res.data) {
          const _objReturn = [];
          res.data.forEach((item) => {
            if (item.active) {
              const _obj = createPointCompositionModel(
                item.actionTypeId,
                item.actionType.description,
                item.actionId,
                item.points,
                item.description,
                item.dateCreated
              );
              _objReturn.push(_obj);
            }
          });

          resolve(_objReturn);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro getPointComposition", err);
        reject(err);
      });
  });
}
