import React from "react";
import { getDic } from "../assets/i18n/dictionary";
import { connect } from "react-redux";
import flagBrazil from '../assets/icon/FlagBrasil.png';
import flagEngland from '../assets/icon/FlagEngland.jpg';
import flagSpain from '../assets/icon/FlagSpain.jpg';
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { isMobile } from "react-device-detect";

//Imports de components
import { FabButton, Avatar, Link } from "../components";

const isIpad = () => {
  const ua = window.navigator.userAgent;
  if (ua.indexOf("iPad") > -1) {
    return true;
  }

  if (ua.indexOf("Macintosh") > -1) {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) { }
  }

  return false;
};

const ModalProp = (props) => {
  const {
    children,
    open = false,
    background = "#e8eced",
    closeButtonClick,
    onClose,
    disableBackdropClick = true,
    id,
    isVideo = false,
    openModalFullScreen = false,
    multilanguage = false,
    chosenLanguage = "pt",
    showBrFlag = false,
    showEnFlag = false,
    showSpFlag = false,
    modalChildUrls,
    blockButtonCloseModal = false,
    zIndex = 12
  } = props;
  const [varFullScreen, setFullScreen] = React.useState(false);
  const fullScreen = React.useRef(varFullScreen);
  fullScreen.current = varFullScreen;

  const [backgroundConfig, setBackgroundConfig] = React.useState({
    color: background,
    image: "",
  });
  const [marginTop, setMarginTop] = React.useState(isMobile ? "0px" : "60px");
  const [marginBottom, setMarginBottom] = React.useState(
    isMobile ? "0px" : "60px"
  );
  const [marginLeft, setMarginLeft] = React.useState(isMobile ? "0px" : "20%");
  const [marginRight, setMarginRight] = React.useState(
    isMobile ? "0px" : "20%"
  );
  const [maxWidth, setMaxWidth] = React.useState(isMobile ? "100%" : "60%");
  const [height, setHeight] = React.useState(
    isMobile ? "100vh" : props.height ? props.height : "80vh"
  );
  const [localChosenLanguage, setLocalChosenLanguage] = React.useState(chosenLanguage);
  // Método para retornar parametro ao elemento pai
  const modalContentHeight = (heightLocal) => {
    if (props.modalContentHeight) {
      props.modalContentHeight(heightLocal);
    }
  };

  const modalContentWidth = (widthLocal) => {
    if (props.modalContentWidth) {
      props.modalContentWidth(widthLocal);
    }
  };

  //Controle das bandeiras de multilinguagem no modal
  const changeLanguage = (lang) => {
    props.onClose(lang);
  };

  const localOnClose = (e) => {
    if (onClose) {
      onClose(e);
    }
    window.postMessage({
      message: 'onModalComponentClose',
    }, '*');
  }

  const styles = {
    modal: {
      backgroundColor: backgroundConfig.color,
      marginTop: marginTop,
      marginBottom: marginBottom,
      marginLeft: marginLeft,
      marginRight: marginRight,
      maxWidth: maxWidth,
      // maxHeight: "60%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
      outline: "none",
    },
    header: {
      backgroundColor: backgroundConfig.color,
      width: "100%",
      textAlign: "right",
      height: "26px",
      // paddingLeft: '10px',
      // paddingRight: '10px'
    },
    headerFull: {
      // backgroundColor: "#e0e0e0",
      backgroundColor: "transparent",
      width: "100%",
      textAlign: "right",
      top: "0px",
      position: "absolute",
      zIndex: "1",
      // paddingLeft: '10px',
      // paddingRight: '10px'
    },
    body: {
      overflow: "auto",
      width: "100%",
      height: height,
      backgroundImage: `url(${backgroundConfig.image})`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    button: {
      marginRight: "10px",
      color: "white",
      backgroundColor: "gray",
    },
  };

  React.useEffect(() => {
    var helpButton = document.getElementById("launcher");
    if (helpButton) {
      helpButton.style.visibility = "hidden";
    }
    //Aplica configurações
    if (props.modalConfig && props.modalConfig.url.length > 0) {
      var newConfig = backgroundConfig;

      props.modalConfig.url.forEach((item) => {
        var config = item.split(/:(.+)/);
        newConfig[config[0]] = config[1];
      });

      setBackgroundConfig(newConfig);
    }

    //Cria evento para recalcular tamanho da tela
    window.addEventListener("resize", () => {
      getModalSize();

      if (isMobile) {
        var _height = window.innerHeight;
        var _width = window.innerWidth;
        setMarginTop("0px");
        setMarginBottom("0px");
        setMarginLeft("0px");
        setMarginRight("0px");
        setMaxWidth("100%");
        setHeight(_height + "px");

        _height = (window.innerHeight - 26) + "px";
        modalContentHeight(_height);
        modalContentWidth(_width + "px");
      }

    });

    //Caso o modal abrir fullScreen aplicar alterações
    if (openModalFullScreen) {
      changeFullScreen();
    }
    else {
      getModalSize();
    }

    if (isMobile && !isIpad()) {
      //window.openFullscreen();
    }

    if (isMobile) {
      var _height = window.innerHeight;
      var _width = window.innerWidth;
      setMarginTop("0px");
      setMarginBottom("0px");
      setMarginLeft("0px");
      setMarginRight("0px");
      setMaxWidth("100%");
      setHeight(_height + "px");

      _height = (window.innerHeight - 26) + "px";
      modalContentHeight(_height);
      modalContentWidth(_width + "px");
    }

    closeModalByIframeMessage();

    return () => {
      var helpButton = document.getElementById("launcher");
      if (helpButton) {
        helpButton.style.visibility = "visible";
      }
    };
  }, []);

  const closeModalByIframeMessage = () => {
    // A aplicação cliente deve enviar o seguinte comando
    // window.top.postMessage('closeModal', 'http://localhost:3000');

    window.onmessage = function (e) {
      if (e.data === 'closeModal' && modalChildUrls.includes(e.origin)) {
        onClose();
      }
    };
  }

  const getModalSize = (localFullScreen = null) => {
    const _fullScreen = localFullScreen === null ? fullScreen.current : localFullScreen;

    var _height = window.innerHeight;
    var _width = window.innerWidth;

    if (_fullScreen) {
      setMarginTop("0px");
      setMarginBottom("0px");
      setMarginLeft("0px");
      setMarginRight("0px");
      setMaxWidth("100%");
      _height = (window.innerHeight - 26);
      setHeight(_height + "px");
      modalContentHeight(_height + "px");
      modalContentWidth(_width + "px");
    } else {
      setMarginTop((_height * 0.1) + "px");
      setMarginBottom((_height * 0.1) + "px");
      setMarginLeft((_width * 0.1) + "px");
      setMarginRight((_width * 0.1) + "px");
      setMaxWidth((_width * 0.8) + "px");
      setHeight(props.height ? props.height : (_height * 0.8 - 26) + "px");

      modalContentHeight(props.height ? props.height : (_height * 0.8 - 26) + "px");
      modalContentWidth(props.width ? props.width : (_width * 0.8) + "px");
    }
  }

  const changeFullScreen = () => {
    if (fullScreen.current) {
      setFullScreen(false);
      getModalSize(false);
    } else {
      setFullScreen(true);
      getModalSize(true);
    }
  };

  const renderButton = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-end' }}>
        {multilanguage && showBrFlag && (
          <div style={{ paddingRight: 10, opacity: localChosenLanguage !== "pt" ? "0.5" : "1" }}>
            <Avatar
              size="smaller"
              src={flagBrazil}
              onClick={() => {
                changeLanguage("pt");
                setLocalChosenLanguage("pt");
              }}
            />
          </div>
        )}
        {multilanguage && showEnFlag && (
          <div style={{ paddingRight: 10, opacity: localChosenLanguage !== "en" ? "0.5" : "1" }}>
            <Avatar
              size="smaller"
              src={flagEngland}
              onClick={() => {
                changeLanguage("en");
                setLocalChosenLanguage("en");
              }}
            />
          </div>
        )}
        {multilanguage && showSpFlag && (
          <div style={{ paddingRight: 10, opacity: localChosenLanguage !== "sp" ? "0.5" : "1" }}>
            <Avatar
              size="smaller"
              src={flagSpain}
              onClick={() => {
                changeLanguage("sp");
                setLocalChosenLanguage("sp");
              }}
            />
          </div>
        )}
        {!isVideo && !isMobile ? (
          !fullScreen.current ? (
            <IconButton
              size="small"
              onClick={() => changeFullScreen()}
              style={styles.button}
            >
              {/* <TextSmall>{getDic("tela-cheia").toUpperCase()}</TextSmall> */}
              <FullscreenIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={() => changeFullScreen()}
              style={styles.button}
            >
              {/* <TextSmall>{getDic("minimizar").toUpperCase()}</TextSmall> */}
              <FullscreenExitIcon fontSize="small" />
            </IconButton>
          )
        ) : null}
        {!blockButtonCloseModal && (
          <IconButton
            id="modalCloseButton"
            size="small"
            onClick={closeButtonClick}
            style={styles.button}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    );
  };

  if (isMobile) {
    return (
      <Modal
        disableAutoFocus
        disableEnforceFocus
        open={open}
        onClose={localOnClose}
        disableBackdropClick={disableBackdropClick}
        id="modalComponent"
        style={{
          zIndex: zIndex
        }}
      >
        <div id={id} style={styles.modal} className="modalComponent">
          {/* <div style={fullScreen ? styles.headerFull : styles.header}> */}
          <div style={styles.header}>
            {renderButton()}
          </div>
          <div style={styles.body}>
            {/* <FabButton absolute color="gray" onClick={closeButtonClick}>
              X
            </FabButton> */}
            {children}
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      id="modalComponent"
      open={open}
      onClose={localOnClose}
      disableBackdropClick={disableBackdropClick}
      style={{
        zIndex: zIndex
      }}
    >
      <div id={id} style={styles.modal}>
        {/* <div style={fullScreen ? styles.headerFull : styles.header}> */}
        <div style={styles.header}>
          {renderButton()}
        </div>
        <div style={styles.body}>{children}</div>
      </div>
    </Modal>
  );
};

function mapStateToProps(state) {
  //Verifica se existe uma configuração do modal
  const modalConfig = state.config.find((item) => {
    return item.codeScreen === "MODAL-BACKGROUND";
  });

  return {
    modalConfig: modalConfig ? modalConfig : null,
  };
}

export default connect(mapStateToProps, null)(ModalProp);
