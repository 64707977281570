export const SET_TOKEN = 'SET_TOKEN';
const setTokenLocal = token => ({
    type: SET_TOKEN,
    token
});
export function setToken(token) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setTokenLocal(token));
            resolve(true);
        });
    };
}
