import { createMuiTheme } from "@material-ui/core/styles";

export function createPrimaryTheme(colorPrimary = "#0d47a1") {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: colorPrimary
      }
    }
  });

  return theme;
}

export function createSecondaryTheme(colorSecondary = "#fafafa") {
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: colorSecondary
      }
    }
  });

  return theme;
}

export function createCompleteTheme(
  colorPrimary = "#0d47a1",
  colorSecondary = "#fafafa"
) {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: colorPrimary
      },
      secondary: {
        main: colorSecondary
      }
    }
  });

  return theme;
}
