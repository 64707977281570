export const SET_STANDS = 'SET_STANDS';
const setStandsLocal = stands => ({
    type: SET_STANDS,
    stands
});
export function setStands(stands) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setStandsLocal(stands));
            resolve(true);
        });
    };
}

export const RESET_STANDS = 'RESET_STANDS';
const resetStandsLocal = () => ({
    type: RESET_STANDS
});
export function resetStands() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetStandsLocal());
            resolve(true);
        });
    };
}
