import React, { useCallback } from "react";
import { connect } from "react-redux";
import { checkGlobalVlibras } from '../store/actions';
import { getDic } from "../assets/i18n/dictionary";
import {
  getMessagesApi,
  addNewMessageApi,
  getChatSize,
  getDeletedMessagesApi,
  convertToTime,
} from "../services/chatService";
import { insertHyperLink } from "../utils/inputFieldMasks";
import { animateScroll } from "react-scroll";
import Picker from "emoji-picker-react";
import SendIcon from "@material-ui/icons/Telegram";
import EmojiIcon from "@material-ui/icons/InsertEmoticon";
import CloseIcon from "@material-ui/icons/Close";
import { isMobile } from "react-device-detect";
import ChatTransmissionV2 from "./ChatTransmissionV2";
//Imports de components
import {
  FormRow,
  InputFieldOutlined,
  ChatInputFieldOutlined,
  TextMedium,
  TextSmall,
  Card,
  Avatar,
  Link,
  Button,
  ActivityIndicator,
} from "../components";

var timeout;
var timeoutMessages;
var timeoutDeletedMessages;

const Chat = props => {
  /* if(
    props.chosenConfig.url.includes("V2") ||
    props.chosenConfig.enableChatV2
  ) */ return <ChatV2 {...props} />
  /* else 
    return <ChatV1 {...props}/> */
}

const ChatV2 = props => {
  return <ChatTransmissionV2 {...props} />
}

const ChatV1 = (props) => {
  const [state, setState] = React.useState(true);
  const [messages, setMessages] = React.useState([]);
  const [userNewMwssage, setUserNewMwssage] = React.useState("");
  const [isScrolling, setIsScrolling] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [loadingMoreMessages, setLoadingMoreMessages] = React.useState(false);

  const [pageIndex, setPageIndex] = React.useState(0);
  const [totalPageIndex, setTotalPageIndex] = React.useState(0);
  const [noMoreOldMessages, setNoMoreOldMessages] = React.useState(false);

  //Controle de emoji
  const [openModalEmoji, setOpenModalEmoji] = React.useState(false);

  const refMessages = React.useRef(messages);
  refMessages.current = messages;


  React.useEffect(() => {
    timeoutDeletedMessages = setTimeout(() => {
      //getDeletedMessages();
    }, 33000);

    getMessages(true);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeoutMessages);
      clearTimeout(timeoutDeletedMessages);
    };
  }, []);


  const onChangeHandler = (value) => {
    setUserNewMwssage(value);
    setIsScrolling(false)
  };

  const onEmojiClick = (event, emojiObject) => {
    let sym = emojiObject.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);

    var message = userNewMwssage + emoji;
    setUserNewMwssage(message);
    setOpenModalEmoji(false);
  };

  const onKeyPressHandler = (value) => {
    if (value === "Enter") {
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (userNewMwssage && userNewMwssage !== "") {
      setSending(true);
      setOpenModalEmoji(false);
      // clearTimeout(timeoutMessages);
      setIsScrolling(false);

      addNewMessageApi(
        userNewMwssage,
        props.chosenConfig.scheduleId == 0 ? null : props.chosenConfig.scheduleId,
        props.chosenConfig.id
      )
        .then((res) => {
          setUserNewMwssage("");
          // getMessages();
          var _finalMessages = refMessages.current;
          const _message = refMessages.current.find((mesState) => {
            return mesState.id === res.id;
          });
          if (!_message) {
            _finalMessages.push(res);
            setMessages(_finalMessages);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSending(false);
          scrollToBottom();
          getMessages();
        });
    }
  };

  const getMessages = (first = false, moreMessages = false, lastIndex) => {
    //Buscar mensagens a cada período de tempo
    clearTimeout(timeoutMessages);

    var index = (lastIndex ? lastIndex : pageIndex) + 1;
    if (!moreMessages) {
      setPageIndex(0);
      index = 1;
    }

    var searchMoreMessages = false;
    var hasError = false;
    var messagesReceived = 0;
    var newMessages = 0;

    getMessagesApi(
      props.event.id,
      props.chosenConfig.scheduleId == 0 ? null : props.chosenConfig.scheduleId,
      props.chosenConfig.id,
      index
    )
      .then((res) => {
        if (res.messages && res.messages.length > 0) {
          //Verifica quantas mensagens foram recebidas e quantas não estavam no array
          //Caso o array inteiro não estiver na lista, buscar a próxima página até encontrar a ultima mensagem
          messagesReceived = res.messages.length;

          var finalMessages = refMessages.current;

          res.messages.forEach((mes) => {
            //Verifica se a mensagem já existe, se não, inserir no array
            const message = refMessages.current.find((mesState) => {
              return mesState.id === mes.id;
            });
            if (!message) {
              newMessages++;
              finalMessages.push(mes);
            }
          });

          if (!first && messagesReceived === newMessages) {
            searchMoreMessages = true;
          }

          //Ordenar mensagens
          finalMessages = finalMessages.sort(function (a, b) {
            return a.insertDate > b.insertDate
              ? 1
              : b.insertDate > a.insertDate
                ? -1
                : 0;
          });

          setMessages(finalMessages);
          setPageIndex(index);
          setTotalPageIndex(res.totalPages);
        }
      })
      .catch((err) => {
        console.log("Erro getMessagesApi", err);
        hasError = true;
      })
      .finally(() => {
        setState(state === true ? false : true);
        if (!hasError && searchMoreMessages) {
          getMessages(false, true, index);
        } else {
          scrollToBottom();
          props.checkGlobalVlibras();
          timeoutMessages = setTimeout(() => {
            getMessages();
            props.checkGlobalVlibras();
            // }, 10000); // 1 minuto = 60000
          }, 30000); // 1 minuto = 60000
        }
      });
  };

  const getOldMessages = (first = false, lastIndex) => {
    //Buscar mensagens a cada período de tempo
    clearTimeout(timeoutMessages);
    setLoadingMoreMessages(true);

    var index = (lastIndex ? lastIndex : pageIndex) + 1;
    if (first) {
      setPageIndex(0);
      index = 1;
    }

    var searchMoreMessages = false;
    var hasError = false;
    var newMessages = 0;

    getMessagesApi(
      props.event.id,
      props.chosenConfig.scheduleId == 0 ? null : props.chosenConfig.scheduleId,
      props.chosenConfig.id,
      index
    )
      .then((res) => {
        if (res.messages && res.messages.length > 0) {
          setTotalPageIndex(res.totalPages);

          var finalMessages = refMessages.current;

          res.messages.forEach((mes) => {
            //Verifica se a mensagem já existe, se não, inserir no array
            const message = refMessages.current.find((mesState) => {
              return mesState.id === mes.id;
            });
            if (!message) {
              newMessages++;
              finalMessages.push(mes);
            }
          });

          if (newMessages < getChatSize() && index < totalPageIndex) {
            searchMoreMessages = true;
          }

          //Ordenar mensagens
          finalMessages = finalMessages.sort(function (a, b) {
            return a.insertDate > b.insertDate
              ? 1
              : b.insertDate > a.insertDate
                ? -1
                : 0;
          });

          setMessages(finalMessages);
          setPageIndex(index);
        }
      })
      .catch((err) => {
        console.log("Erro getMessagesApi", err);
        hasError = true;
      })
      .finally(() => {
        setLoadingMoreMessages(false);
        setState(state === true ? false : true);
        if (index === totalPageIndex) {
          setNoMoreOldMessages(true);
        }

        if (!hasError && searchMoreMessages) {
          getOldMessages(false, index);
        } else {
          timeoutMessages = setTimeout(() => {
            getMessages();
            // }, 10000); // 1 minuto = 60000
          }, 30000); // 1 minuto = 60000
        }
      });
  };

  const getDeletedMessages = useCallback((index) => {
    clearTimeout(timeoutDeletedMessages);

    var searchMoreMessages = false;
    var hasError = false;

    getDeletedMessagesApi(
      props.event.id,
      props.chosenConfig.scheduleId,
      props.chosenConfig.id,
      index
    )
      .then((res) => {
        if (res.messages && res.messages.length > 0) {
          var finalMessages = [];

          refMessages.current.forEach((message) => {
            var deleted = res.messages.find((del) => {
              return del.id === message.id;
            });
            if (!deleted) {
              finalMessages.push(message);
            }
          });

          if (res.totalPages > index) {
            searchMoreMessages = true;
          }

          //Ordenar mensagens
          finalMessages = finalMessages.sort(function (a, b) {
            return a.insertDate > b.insertDate
              ? 1
              : b.insertDate > a.insertDate
                ? -1
                : 0;
          });

          setMessages(finalMessages);
          setPageIndex(index);
          setTotalPageIndex(res.totalPages);
        }
      })
      .catch((err) => {
        console.log("Erro getDeletedMessagesApi", err);
        hasError = true;
      })
      .finally(() => {
        setState(state === true ? false : true);
        if (!hasError && searchMoreMessages) {
          var newIndex = index + 1;
          //getDeletedMessages(newIndex);
        } else {
          var newIndex = index + 1;
          timeoutDeletedMessages = setTimeout(() => {
            //getDeletedMessages(1);
            // }, 10000); // 1 minuto = 60000
          }, 33000); // 1 minuto = 60000
        }
      });
  });

  const scrollToBottom = () => {
    if (!isScrolling) {
      animateScroll.scrollToBottom({
        containerId: "chatComponent",
      });
    }
  };

  const scrollControl = () => {
    //Marca um período em que o scroll foi ativado
    //Para evitar que a página faça scroll enquanto o usuário estiver consultando mensagens anteriores
    clearTimeout(timeout);

    setIsScrolling(true);

    timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 20000); // 1 minuto = 60000
  };

  const renderOldMessagesButton = () => {
    //Botão para carregar mais mensagens
    if (!noMoreOldMessages) {
      if (loadingMoreMessages) {
        return (
          <div style={{ textAlign: "center" }}>
            <ActivityIndicator />
          </div>
        );
      }
      return (
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              backgroundColor: "#868686",
              border: 0,
              borderRadius: 10,
              fontSize: 12,
              padding: 5,
              color: "white",
            }}
            onClick={() => getOldMessages(true)}
          >
            {getDic("mais")}
          </button>
        </div>
      );
    }
  };

  const getMessageConfig = (messageObj) => {
    var _messageConfig = {
      text: messageObj.message,
      background: "white",
      textColor: "black",
    }
    try {
      if (messageObj.message.includes("${DESTACAR,")) {
        var _textArray = _messageConfig.text.split("${DESTACAR,");
        _textArray = _textArray[1].split("}");
        var _configArray = _textArray[0].split(",");
        _messageConfig.background = _configArray[0].trim();
        _messageConfig.textColor = _configArray[1].trim();
        _messageConfig.text = _textArray[1].trim();
        return _messageConfig;
      }
      else {
        return _messageConfig;
      }
    } catch (error) {
      console.log("Erro ao destacar mensagem", error);
      _messageConfig.background = "white";
      _messageConfig.textColor = "black";
      _messageConfig.text = messageObj.message;
      return _messageConfig;
    }
  }

  const renderMessageCard = (message) => {
    var _messageConfig = getMessageConfig(message);
    return (
      <FormRow margin="0px" key={message.id}>
        <div
          style={{
            paddingTop: "10px",
            flex: 1,
          }}
        >
          <Card backgroundColor={_messageConfig.background}>
            <FormRow margin="0px">
              <div
                style={{ display: "flex", flexDirection: "row", color: _messageConfig.textColor }}
              >
                <div style={{ marginRight: 5 }}>
                  <Avatar
                    src={message.photoUrl}
                    size="medium-small"
                  />
                </div>
                <div style={{ flexDirection: "column" }}>
                  <div
                    style={{
                      flex: 1,
                      cursor: "pointer",
                    }}
                  >
                    <span
                      onClick={() =>
                        console.log("OpenPrivateModalHere")
                      }
                      style={{
                        fontSize: 12,
                        fontWeight: "900",
                      }}
                    >
                      {message.guestName} -{" "}
                      <span
                        style={{
                          color: "#bdbdbd",
                          fontSize: 12,
                        }}
                      >
                        {convertToTime(message.insertDate)}
                      </span>
                    </span>
                  </div>
                  <div style={{ flex: 1, marginTop: 8 }}>
                    {renderMessage(_messageConfig.text)}
                  </div>
                </div>
              </div>
            </FormRow>
          </Card>
        </div>
      </FormRow>
    );
  }

  const renderMessage = (messageText) => {
    var text = messageText;

    //Verifica se a mensagem possui um link
    if (text.includes("http://") || text.includes("https://")) {
      text = insertHyperLink(text);
    }

    return (
      <div>
        <span fontSize="14px">{text}</span>
      </div>
    );
  };

  const { height } = props;

  return (
    <div
      id="messages"
      style={{
        height: !props.enableSizeController
          ? height
          : props.enableSizeController
            ? `${props.modalContentHeight - 5}vh`
            : `${props.modalContentHeight - 5}vh`,
        backgroundColor: "transparent",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        id="chatComponent"
        style={{
          backgroundColor: "transparent",
          height: "100%",
          // overflow: "scroll"
          overflowX: "auto",
          overflowY: "auto",
        }}
        onScroll={() => scrollControl()}
      >
        {refMessages.current.length > 0 ? (
          <div>
            {renderOldMessagesButton()}
            {refMessages.current.map((message) => {
              return (
                <>
                  {renderMessageCard(message)}
                </>
              );
            })}
          </div>
        ) : (
          <TextMedium>{getDic("mensagens-nao-localizadas")}</TextMedium>
        )}
      </div>
      <div
        style={{
          backgroundColor: "transparent",
          justifyContent: "flex-end",
        }}
      >
        <div align="center">
          {sending ? (
            <ActivityIndicator />
          ) : (
            <div style={{ minHeight: 40 }}>
              <ChatInputFieldOutlined
                key="message"
                userAvatar={props.user.image}
                value={userNewMwssage}
                placeholder={getDic("mensagem")}
                onChange={(event) => onChangeHandler(event.target.value)}
                onKeyPress={(event) => onKeyPressHandler(event.key)}
                autoFocus={isMobile ? false : false}
                endAdornment={
                  <Link color="gray">
                    <SendIcon
                      style={{ fontSize: 33 }}
                      onClick={() => sendMessage()}
                    />
                  </Link>
                }
                startAdornment={
                  !isMobile ? (
                    !openModalEmoji ? (
                      <Link color="gray">
                        <EmojiIcon
                          style={{ fontSize: 27 }}
                          onClick={() => setOpenModalEmoji(true)}
                        />
                      </Link>
                    ) : (
                      <Link color="gray">
                        <CloseIcon onClick={() => setOpenModalEmoji(false)} />
                      </Link>
                    )
                  ) : null
                }
              />
            </div>
          )}

          {openModalEmoji ? <Picker onEmojiClick={onEmojiClick} /> : null}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

const mapDispatchToProps = {
  checkGlobalVlibras
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
