import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createEncryptor from 'redux-persist-transform-encrypt'
import rootReducer from './reducers';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const encryptor = createEncryptor({
    secretKey: 'inteegra@mice',
    onError: function (error) {
        console.log('Erro createEncryptor', error);
    }
});

const persistConfig = {
    key: 'root',
    storage: storage,
    transforms: [encryptor],
    stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);
