import { getDic } from '../assets/i18n/dictionary';
import { createFormControlModel } from '../models/formControlModel';
import { store } from '../store';

export function createCalendarModel(
    id = 0,
    eventId = 0,
    startDate = "",
    endDate = "",
    title = "",
    description = "",
    typeId = 0,
    type = "",
    location = null, // Objeto do tipo createLocationModel
) {
    const _obj = {
        id: id,
        eventId: eventId,
        startDate: startDate,
        endDate: endDate,
        title: title,
        description: description,
        typeId: typeId,
        type: type,
        location: location,
    }

    return _obj;
}

export function createLocationModel(
    idLocation = 0,
    description = "",
    zipCode = "",
    address = "",
    number = "",
    district = "",
    complement = "",
    city = "",
    state = "",
    country = "",
) {
    const _obj = {
        idLocation: idLocation,
        zipCode: zipCode,
        address: address,
        number: number,
        district: district,
        complement: complement,
        city: city,
        state: state,
        country: country,
        descriptionLocation: description,
    }

    return _obj;
}

export function convertCalendarObjectToForm(calendar) {
    const _form = [];

    var _index = 0;

    for (var key in calendar) {
        if (calendar.hasOwnProperty(key)) {

            var _hasForm = false;
            var _type = "text";
            var _options = null;
            var _required = true;
            var _name = "";
            var _value = calendar[key] ? calendar[key].toString() : null;

            switch (key.toString()) {
                case "id":
                    _hasForm = true;
                    _name = "id";
                    _required = true;
                    _value = parseInt(calendar[key]);
                    break;
                case "startDate":
                    _hasForm = true;
                    _name = `${getDic("data")} ${getDic("inicio")}`;
                    _required = true;
                    _type = "datetime-local";
                    break;
                case "endDate":
                    _hasForm = true;
                    _name = `${getDic("data")} ${getDic("inicio")}`;
                    _required = true;
                    _type = "datetime-local";
                    break;
                case "title":
                    _hasForm = true;
                    _name = getDic("titulo");
                    _required = true;
                    break;
                case "description":
                    _hasForm = true;
                    _name = getDic("descricao");
                    _required = false;
                    break;
                case "typeId":
                    _hasForm = true;
                    _name = getDic("tipo");
                    _required = false;
                    _value = parseInt(calendar[key]);
                    break;
                case "type":
                    _hasForm = true;
                    _name = getDic("categoria");
                    _required = false;
                    break;
                case "location":
                    // A localização irá gerar um novo formulário que será adicionado ao form principal
                    var _locationForm;
                    if (_value) {
                        _locationForm = convertLocationObjectToForm(calendar.location);
                    }
                    else {
                        _locationForm = convertLocationObjectToForm(createLocationModel());
                    }

                    _locationForm.forEach(item => {
                        _form.push(item);
                    });
                    break;
                default:
                    break;
            }

            if (_hasForm) {
                const _obj = createFormControlModel(
                    _index,
                    _name,
                    _value,
                    _type,
                    null,
                    null,
                    _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                    _required,
                    null,
                    null,
                    null,
                    key
                );
                _form.push(_obj);
                _index++;
            }
        }
    }
    return _form;
}

function convertLocationObjectToForm(location) {
    const _form = [];

    var _index = 0;

    for (var key in location) {
        if (location.hasOwnProperty(key)) {

            var _hasForm = false;
            var _type = "text";
            var _options = null;
            var _maxLength = null;
            var _required = true;
            var _name = "";
            var _value = location[key] ? location[key].toString() : null;

            switch (key.toString()) {
                case "idLocation":
                    _hasForm = true;
                    _name = "idLocation";
                    _required = false;
                    _value = parseInt(location[key]);
                    break;
                case "zipCode":
                    _hasForm = true;
                    _name = "CEP";
                    _required = false;
                    break;
                case "address":
                    _hasForm = true;
                    _name = getDic("endereco");
                    _required = false;
                    break;
                case "number":
                    _hasForm = true;
                    _name = getDic("numero");
                    _required = false;
                    break;
                case "district":
                    _hasForm = true;
                    _name = getDic("bairro");
                    _required = false;
                    break;
                case "complement":
                    _hasForm = true;
                    _name = getDic("complemento");
                    _required = false;
                    break;
                case "city":
                    _hasForm = true;
                    _name = getDic("cidade");
                    _required = false;
                    break;
                case "state":
                    _hasForm = true;
                    _name = getDic("estado");
                    _maxLength = 2;
                    _required = false;
                    break;
                case "country":
                    _hasForm = true;
                    _name = getDic("pais");
                    _required = false;
                    break;
                case "description":
                    _hasForm = true;
                    _name = `${getDic("descricao")} ${getDic("endereco")}`;
                    _required = false;
                    break;
                default:
                    break;
            }

            if (_hasForm) {
                const _obj = createFormControlModel(
                    _index + 10,
                    _name,
                    _value,
                    _type,
                    _maxLength,
                    null,
                    _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                    _required,
                    null,
                    null,
                    null,
                    key
                );
                _form.push(_obj);
                _index++;
            }
        }
    }
    return _form;
}

export function convertFormToCalendarObject(form) {
    var _calendar = createCalendarModel();
    form.forEach(item => {
        var _value;
        switch (item.dbReference) {
            case "id":
                _value = item.value ? parseInt(item.value) : null;
                break;
            case "eventId":
                _value = store.getState().event.id;
                break;
            case "startDate":
                _value = item.value ? item.value.toString() : null;
                break;
            case "endDate":
                _value = item.value ? item.value.toString() : null;
                break;
            case "title":
                _value = item.value ? item.value.toString() : null;
                break;
            case "description":
                _value = item.value ? item.value.toString() : null;
                break;
            case "typeId":
                _value = item.value ? parseInt(item.value) : null;
                break;
            case "type":
                _value = item.value ? item.value.toString() : null;
                break;
            case "title":
                _value = item.value ? item.value.toString() : null;
                break;
            default:
                break;
        }
        _calendar[item.dbReference] = _value;
    });

    var _location = createLocationModel();
    form.forEach(item => {
        var _value;
        switch (item.dbReference) {
            case "idLocation":
                _value = item.value ? parseInt(item.value) : null;
                break;
            case "descriptionLocation":
                _value = item.value ? item.value.toString() : "";
                break;
            case "zipCode":
                _value = item.value ? item.value.toString() : "";
                break;
            case "address":
                _value = item.value ? item.value.toString() : "";
                break;
            case "number":
                _value = item.value ? item.value.toString() : "";
                break;
            case "district":
                _value = item.value ? item.value.toString() : "";
                break;
            case "complement":
                _value = item.value ? item.value.toString() : "";
                break;
            case "city":
                _value = item.value ? item.value.toString() : "";
                break;
            case "state":
                _value = item.value ? item.value.toString() : "";
                break;
            case "country":
                _value = item.value ? item.value.toString() : "";
                break;
            default:
                break;
        }
        _location[item.dbReference] = _value;
    });
    _calendar.location = _location;

    return _calendar;
}
