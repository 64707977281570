import { SET_DATE, CLEAR_DATE } from '../actions';
import { createDateModel } from '../../models/dateModel';

const INITIAL_STATE = createDateModel();

export default function accessReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_DATE:
            return action.date;
        case CLEAR_DATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
