import axios from 'axios';
import { getToken, getUrl } from './apiService';
import { createWhatsappTemplateModel } from '../models/whatsappTemplateModel';

export async function getTemplateList() {
  var method = getUrl().whatsapp + "messages/templates";
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          var _templates = [];
          res.data.forEach(item => {
            _templates = addTemplateToList(_templates, item);
          });
          resolve(_templates);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro getTemplateList", err);
        reject(err);
      });
  });
}

export function getNumberOfTagsFromMessage(message) {
  var _numberOfTags = 0;
  try {
    const _messageArray = message.split('{{');
    _messageArray.forEach(item => {
      if (item.includes('}}')) {
        _numberOfTags++;
      }
    });
  } catch (error) {
    console.log('Erro getNumberOfTagsFromMessage', error);
  }

  return _numberOfTags;
}

function addTemplateToList(templateList = [], template) {
  const _numberOfTags = getNumberOfTagsFromMessage(template.text);

  var _obj = createWhatsappTemplateModel(
    template.name,
    template.name,
    template.namespace,
    template.text,
    _numberOfTags,
    template.format ? true : false,
    template.format ? template.format : null
  );

  const _templateIndex = templateList.findIndex(t => t.value === template.name);
  if (_templateIndex >= 0 && templateList.length > 0) {
    if (!templateList[_templateIndex].message || templateList[_templateIndex].message === '') {
      templateList[_templateIndex].message = _obj.message;
      templateList[_templateIndex].numberOfTags = _obj.numberOfTags;
    }
    if (!templateList[_templateIndex].urlType || templateList[_templateIndex].urlType === '') {
      templateList[_templateIndex].urlType = _obj.urlType;
    }
    templateList[_templateIndex].hasUrl = true;
  } else {
    templateList.push(_obj);
  }

  return templateList;
}

export async function addNewWhatsappApi(whatsapp, template) {

  var method = getUrl().whatsapp + "messages";
  const token = await getToken();

  const _data = [];
  for (let index = 1; index <= 10; index++) {
    const _fieldName = 'tag' + index;
    const _item = whatsapp[_fieldName];
    if (_item && _item !== "") {
      _data.push(_item);
    }
  }

  const _whatsapp = {
    templateName: whatsapp.templateName,
    namespace: "20ba1ffa_4b6a_4a4a_ab8d_561ff82e300f",
    mobileNumber: `+${whatsapp.countryNumber}${whatsapp.mobileNumber}`,
    url: whatsapp.url,
    data: _data,
    type: template.urlType ? template.urlType.toLowerCase() : template.urlType,
    filename: "Arquivo"
  }

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: _whatsapp,
    };
    axios(options)
      .then((res) => {
        resolve(null);
      })
      .catch((err) => {
        console.log("Erro addNewWhatsappApi", err);
        reject(err);
      });
  });
}
