import { firestore } from "../utils/firebase";
import { store } from '../store';
import moment from 'moment';
import * as raffleModel from '../models/raffleModel';
import * as guestModel from '../models/guestModel';
import * as guestService from './guestService';
import * as apiService from './apiService';

export async function getRafflesListFirebase() {
  try {
    const _eventId = store.getState().event.id;
    var _url = `rafflesControl/${_eventId}/raffles`;

    const _snapshot = await firestore.collection(_url).where("active", "==", true).orderBy('title').get();

    const _return = [];
    if (!_snapshot.empty) {
      _snapshot.forEach(doc => {
        let _doc = doc.data();
        let _obj = raffleModel.createRaffleModel();
        for (var key in _doc) {
          _obj[key] = _doc[key];
        }
        _obj.id = doc.ref.id;
        _return.push(_obj);
      });
    }

    return _return;
  } catch (error) {
    console.log('Error getRafflesListFirebase', error);
    throw error;
  }
}

export async function saveRaffleFirebase(raffle = raffleModel.createRaffleModel()) {
  try {
    let _raffle = { ...raffle };
    _raffle.numberOfWinners = parseInt(_raffle.numberOfWinners);
    const _eventId = store.getState().event.id;
    var _url = `rafflesControl/${_eventId}/raffles`;

    let _snapshot
    if (_raffle.id <= 0) {
      _raffle.createDate = new Date();
      _snapshot = await firestore.collection(_url).add(_raffle);
      _raffle.id = _snapshot.id;
    } else {
      _snapshot = await firestore.collection(_url).doc(_raffle.id).update(_raffle);
    }

    return _raffle;
  } catch (error) {
    console.log('Error saveRaffleFirebase', error);
    throw error;
  }
}

export async function getRaffleWinnersFirebase(raffleId = '') {
  try {
    const _eventId = store.getState().event.id;
    var _url = `rafflesControl/${_eventId}/winners`;

    const _snapshot = await firestore.collection(_url).where("raffleId", "==", raffleId).get();

    const _return = [];
    if (!_snapshot.empty) {
      _snapshot.forEach(doc => {
        let _doc = doc.data();
        let _obj = raffleModel.createWinnerModel();
        for (var key in _doc) {
          _obj[key] = _doc[key];
        }
        _obj.id = doc.ref.id;

        _return.push(_obj);
      });
    }

    return _return;
  } catch (error) {
    console.log('Error getRafflesListFirebase', error);
    throw error;
  }
}

export async function getAllRaffleWinnersFirebase() {
  try {
    const _eventId = store.getState().event.id;
    var _url = `rafflesControl/${_eventId}/winners`;

    const _snapshot = await firestore.collection(_url).orderBy('guestName').get();

    const _return = [];
    if (!_snapshot.empty) {
      _snapshot.forEach(doc => {
        let _doc = doc.data();
        let _obj = raffleModel.createWinnerModel();
        for (var key in _doc) {
          _obj[key] = _doc[key];
        }
        _obj.id = doc.ref.id;

        _return.push(_obj);
      });
    }

    return _return;
  } catch (error) {
    console.log('Error getAllRaffleWinnersFirebase', error);
    throw error;
  }
}

export async function deleteRaffleWinnersFirebase(raffleId = '') {
  try {
    const _eventId = store.getState().event.id;
    var _url = `rafflesControl/${_eventId}/winners`;

    const _snapshot = await firestore.collection(_url).where("raffleId", "==", raffleId).get();

    if (!_snapshot.empty) {
      _snapshot.forEach(doc => {
        firestore.collection(_url).doc(doc.ref.id).delete();
      });
    }

    return;
  } catch (error) {
    console.log('Error getRafflesListFirebase', error);
    throw error;
  }
}

export async function saveRaffleWinnerFirebase(winner = raffleModel.createWinnerModel()) {
  try {
    const _eventId = store.getState().event.id;
    var _url = `rafflesControl/${_eventId}/winners`;

    let _snapshot
    if (winner.id > 0) {
      _snapshot = await firestore.collection(_url).add(winner);
      winner.id = _snapshot.id;
    } else {
      _snapshot = await firestore.collection(_url).doc(winner.id).update(winner);
    }

    return winner;
  } catch (error) {
    console.log('Error saveRaffleFirebase', error);
    throw error;
  }
}

export async function realizeRaffle(raffle = raffleModel.createRaffleModel()) {
  let __blocklist = [];
  let __previousWinners = [];

  try {
    let _searchBy = getRaffleRules();
    let _winners = [];

    if (raffle.onlyNotAwarded) {
      __previousWinners = await getAllRaffleWinnersFirebase();
    }
    __blocklist = await getRafflesBlocklistFirebase();

    if (_searchBy === 'guests') {
      _winners = await getWinnersByGuestsList();
    } else if (_searchBy === 'ping') {
      _winners = await getWinnersByPing();
    }

    if (_winners.length <= 0) {
      return _winners;
    }

    await addWinnersListToFirebase(_winners);

    let _raffle = await setRaffleRealizedFirebase();

    return _raffle;
  } catch (error) {
    console.log('Error saveRaffleFirebase', error);
    throw error;
  }

  function getRaffleRules() {
    let _return = 'guests';
    if (raffle.onlyOnline || raffle.onlyPage) {
      _return = 'ping';
    }
    return _return;
  }

  function getRandomNumber(maxLimit = 1) {
    let _difference = maxLimit - 0;
    let _rand = Math.random();
    _rand = Math.floor(_rand * _difference);
    _rand = _rand + 0;
    return _rand;
  }

  function checkIfCanAddGuestToWinnersList(guest, winnersList = []) {
    try {
      let _hasGuest = winnersList.find(g => { return g.id === guest.id });
      if (_hasGuest) {
        return false;
      }

      if (raffle.onlyNotAwarded) {
        let _hasPrevious = __previousWinners.find(w => { return w.guestId === guest.id });
        if (_hasPrevious) {
          return false;
        }
      }

      if (__blocklist) {
        let _hasBlock = __blocklist.find(b => { return b.id === guest.id });
        if (_hasBlock) {
          return false;
        }
      }

      return true;
    } catch (error) {
      console.log('Error checkIfCanAddGuestToWinnersList', error);
      throw error;
    }
  }

  async function getWinnersByGuestsList() {
    try {
      let _res = await guestService.getGuestList(
        0,
        '',
        raffle.groupId,
      );
      let _totalPages = _res.totalPages;

      let _numberOfTries = 0;
      let _returnWinners = [];
      do {
        let _pageIndex = getRandomNumber(_totalPages);
        _res = await guestService.getGuestList(
          _pageIndex > _totalPages ? _totalPages : _pageIndex <= 0 ? 0 : _pageIndex,
          '',
          raffle.groupId,
        );

        let _count = 0;
        do {
          let _guestIndex = getRandomNumber(_res.guestList.length + 1);
          let _guest = _res.guestList[_guestIndex];
          if (_guest && _guest.id) {
            let _canAdd = checkIfCanAddGuestToWinnersList(_guest, _returnWinners);
            if (_canAdd) {
              _returnWinners.push(_guest);
              _count = _res.guestList.length;
            } else {
              _numberOfTries++;
            }
          } else {
            _numberOfTries++;
          }

          if (_numberOfTries > 20) {
            return _returnWinners;
          }

          _count++;
        } while (_count < _res.guestList.length);
      } while (_returnWinners.length < parseInt(raffle.numberOfWinners));

      return _returnWinners;
    } catch (error) {
      console.log('Error getWinnersByGuestsList', error);
      throw error;
    }
  }

  async function getWinnersByPing() {
    try {
      let _returnWinners = [];

      let _pingGuests = await getPingGestsFirebase();
      if (_pingGuests.length <= 0) {
        return _returnWinners;
      }

      let _numberOfTries = 0;
      do {
        let _pingIndex = getRandomNumber(_pingGuests.length + 1);
        let _ping = _pingGuests[_pingIndex];

        if (_ping) {
          let _res = await guestService.getGuestListV2(1, '', _ping.loginId);
          let _guest = _res.guestList[0];

          if (_guest && _guest.id) {
            let _canAdd = checkIfCanAddGuestToWinnersList(_guest, _returnWinners);
            if (_canAdd) {
              _returnWinners.push(_guest);
            }
          } else {
            _numberOfTries++;
          }
        } else {
          _numberOfTries++;
        }

        if (_numberOfTries > 20) {
          return _returnWinners;
        }
      } while (_returnWinners.length < parseInt(raffle.numberOfWinners));

      return _returnWinners;
    } catch (error) {
      console.log('Error getWinnersByPing', error);
      throw error;
    }

    async function getPingGestsFirebase() {
      try {
        let _date = await apiService.getDate();
        let _minDate = moment(_date).subtract(5, 'minutes').format();

        const _eventId = store.getState().event.id;
        var _url = `healthmap/${_eventId}/ping`;

        const _snapshot = await firestore.collection(_url)
          .where('accessDate', '>', new Date(_minDate))
          .where('accessDate', '<', new Date(_date))
          .get();

        const _return = [];
        if (!_snapshot.empty) {
          _snapshot.forEach(doc => {
            let _doc = doc.data();
            _doc.id = doc.ref.id;
            _return.push(_doc);
          });
        }

        return _return;
      } catch (error) {
        console.log('Error getPingGestsFirebase', error);
        throw error;
      }
    }
  }

  async function addWinnersListToFirebase(winners = []) {
    try {
      const _eventId = store.getState().event.id;
      var _url = `rafflesControl/${_eventId}/winners`;

      let _count = 0;
      do {
        let _guest = winners[_count];

        let _winner = raffleModel.createWinnerModel(
          '',
          _eventId,
          raffle.id,
          _guest.id,
          _guest.name,
          _guest.email,
          _guest.image
        )

        await firestore.collection(_url).add(_winner);

        _count++;
      } while (_count < winners.length);

      return;
    } catch (error) {
      console.log('Error addWinnersListToFirebase', error);
      throw error;
    }
  }

  async function setRaffleRealizedFirebase() {
    try {
      let _date = await apiService.getDate();

      let _raffle = { ...raffle };

      _raffle.raffleRealized = true;
      _raffle.raffleRealizedDate = new Date(_date);

      await saveRaffleFirebase(_raffle);

      return _raffle;
    } catch (error) {
      console.log('Error setRaffleRealizedFirebase', error);
      throw error;
    }
  }
}

export async function getRafflesBlocklistFirebase() {
  try {
    const _eventId = store.getState().event.id;
    var _url = `rafflesControl/${_eventId}/blocklist`;

    const _snapshot = await firestore.collection(_url).get();

    const _return = [];
    if (!_snapshot.empty) {
      _snapshot.forEach(doc => {
        let _doc = doc.data();
        _doc.docId = doc.ref.id;
        _return.push(_doc);
      });
    }

    return _return;
  } catch (error) {
    console.log('Error getRafflesBlocklistFirebase', error);
    throw error;
  }
}

export async function saveRaffleBlocklistFirebase(guest = guestModel.createGuestModel()) {
  try {
    let _guest = { ...guest };
    const _eventId = store.getState().event.id;
    var _url = `rafflesControl/${_eventId}/blocklist`;

    let _snapshot;
    if (!_guest.docId) {
      _snapshot = await firestore.collection(_url).add(_guest);
      _guest.docId = _snapshot.id;
    } else {
      _snapshot = await firestore.collection(_url).doc(_guest.docId).update(_guest);
    }

    return _guest;
  } catch (error) {
    console.log('Error saveRaffleBlocklistFirebase', error);
    throw error;
  }
}

export async function removeRaffleBlocklistFirebase(guest = guestModel.createGuestModel()) {
  try {
    let _guest = { ...guest };
    const _eventId = store.getState().event.id;
    var _url = `rafflesControl/${_eventId}/blocklist`;

    await firestore.collection(_url).doc(guest.docId).delete();

    return _guest;
  } catch (error) {
    console.log('Error removeRaffleBlocklistFirebase', error);
    throw error;
  }
}
