import axios from 'axios';
import { store } from "../store";
import { getUrl, getToken } from "./apiService";
import { createStandModel } from "../models/standModel";

export async function getStandsListApi() {

  const token = await getToken();
  const event = store.getState().event;
  var method = getUrl().hall + 'event/stand/' + event.id.toString();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token
      }
    };
    axios(options)
      .then((res) => {
        const _standsList = [];
        if (res.data && res.data.length > 0) {
          res.data.forEach(item => {
            if (item.active) {
              const _obj = createStandModel(
                item.id,
                item.name,
                item.order,
                item.pavillion,
                item.tooltip,
                item.coordinates,
              )
              _standsList.push(_obj);
            }
          });
        }
        resolve(_standsList);
      })
      .catch((err) => {
        console.log('Erro getStandsListApi', err);
        reject(err);
      });
  });
}

export async function addNewStandApi(stand) {

  const token = await getToken();
  const event = store.getState().event;
  var method = getUrl().hall + 'event/stand/register';

  const _data = [];
  const _stand = {
    standName: stand.name,
    tooltip: stand.tooltip,
    order: stand.order,
    pavillion: stand.pavilion,
    coordinates: stand.coordinatesString,
    event: event.id
  }
  _data.push(_stand);

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token
      },
      data: _data
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data[0] && res.data[0].success) {
          const _return = createStandModel(
            res.data[0].data.id,
            res.data[0].data.name,
            res.data[0].data.order,
            res.data[0].data.pavillion,
            res.data[0].data.tooltip,
            res.data[0].data.coordinates,
          )
          resolve(_return);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log('Erro addNewStandApi', err);
        reject(err);
      });
  });
}

export async function updateStandApi(stand) {

  const token = await getToken();
  var method = getUrl().hall + 'event/stand/update';

  const _data = [];
  const _stand = {
    id: stand.id,
    standName: stand.name,
    tooltip: stand.tooltip,
    order: stand.order,
    pavillion: stand.pavilion,
    coordinates: stand.coordinatesString,
  }
  _data.push(_stand);

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token
      },
      data: _data
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data[0] && res.data[0].success) {
          const _return = createStandModel(
            res.data[0].data.id,
            res.data[0].data.name,
            res.data[0].data.order,
            res.data[0].data.pavillion,
            res.data[0].data.tooltip,
            res.data[0].data.coordinates,
          )
          resolve(_return);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log('Erro updateStandApi', err);
        reject(err);
      });
  });
}

export async function deleteStandApi(stand) {

  const token = await getToken();
  var method = getUrl().hall + 'event/stand/inactivate/' + stand.id;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token
      },
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data.success) {
          const _return = createStandModel(
            res.data.data.id,
            res.data.data.name,
            res.data.data.order,
            res.data.data.pavillion,
            res.data.data.tooltip,
            res.data.data.coordinates,
          )
          resolve(_return);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log('Erro deleteStandApi', err);
        reject(err);
      });
  });
}
