import React from 'react';
import { TransmissionNotification } from "..";
import { isIOS } from "react-device-detect";
import RenderEmojiOnScreen from './RenderEmojiOnScreen';
import { FormRow } from '../';

const VideoContainer = props => {
  const {
    event,
    emojis,
    emojiValue,
    isVisibleEmoji,
    iframeControl,
    chanelControl,
    isVisiblePo,
    isVisibleEn,
    isVisibleEs,
    isVisibleRus,
    isVisibleFra,
    isVisibleGer,
    isVisibleIta,
    isVisibleAt,
    isVisibleAo,
    windowWidth,
  } = props;

  const setVideo = (language) => {
    if (language === "Po") {
      return chanelControl.current === 1
        ? isVisiblePo
          ? !chanelControl.urlLanguages.onePt.includes(".mp4") && !chanelControl.urlLanguages.onePt.includes(".m3u8")
            ? chanelControl.urlLanguages.onePt
            : chanelControl.urlLanguages.onePt.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.onePt}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.onePt}`
          : ""
        : chanelControl.current === 2
          ? isVisiblePo
            ? !chanelControl.urlLanguages.twoPt.includes(".mp4") && !chanelControl.urlLanguages.twoPt.includes(".m3u8")
              ? chanelControl.urlLanguages.twoPt
              : chanelControl.urlLanguages.twoPt.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.twoPt}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.twoPt}`
            : ""
          : chanelControl.current === 3
            ? isVisiblePo
              ? !chanelControl.urlLanguages.threePt.includes(".mp4") && !chanelControl.urlLanguages.threePt.includes(".m3u8")
                ? chanelControl.urlLanguages.threePt
                : chanelControl.urlLanguages.threePt.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.threePt}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.threePt}`
              : ""
            : "";
    }
    if (language === "En") {
      return chanelControl.current === 1
        ? isVisibleEn
          ? !chanelControl.urlLanguages.oneEn.includes(".mp4") && !chanelControl.urlLanguages.oneEn.includes(".m3u8")
            ? chanelControl.urlLanguages.oneEn
            : chanelControl.urlLanguages.oneEn.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.oneEn}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.oneEn}`
          : ""
        : chanelControl.current === 2
          ? isVisibleEn
            ? !chanelControl.urlLanguages.twoEn.includes(".mp4") && !chanelControl.urlLanguages.twoEn.includes(".m3u8")
              ? chanelControl.urlLanguages.twoEn
              : chanelControl.urlLanguages.twoEn.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.twoEn}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.twoEn}`
            : ""
          : chanelControl.current === 3
            ? isVisibleEn
              ? !chanelControl.urlLanguages.threeEn.includes(".mp4") && !chanelControl.urlLanguages.threeEn.includes(".m3u8")
                ? chanelControl.urlLanguages.threeEn
                : chanelControl.urlLanguages.threeEn.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.threeEn}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.threeEn}`
              : ""
            : "";
    }
    if (language === "Es") {
      return chanelControl.current === 1
        ? isVisibleEs
          ? !chanelControl.urlLanguages.oneEs.includes(".mp4") && !chanelControl.urlLanguages.oneEs.includes(".m3u8")
            ? chanelControl.urlLanguages.oneEs
            : chanelControl.urlLanguages.oneEs.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.oneEs}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.oneEs}`
          : ""
        : chanelControl.current === 2
          ? isVisibleEs
            ? !chanelControl.urlLanguages.twoEs.includes(".mp4") && !chanelControl.urlLanguages.twoEs.includes(".m3u8")
              ? chanelControl.urlLanguages.twoEs
              : chanelControl.urlLanguages.twoEs.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.twoEs}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.twoEs}`
            : ""
          : chanelControl.current === 3
            ? isVisibleEs
              ? !chanelControl.urlLanguages.threeEs.includes(".mp4") && !chanelControl.urlLanguages.threeEs.includes(".m3u8")
                ? chanelControl.urlLanguages.threeEs
                : chanelControl.urlLanguages.threeEs.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.threeEs}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.threeEs}`
              : ""
            : "";
    }
    if (language === "Rus") {
      return chanelControl.current === 1
        ? isVisibleRus
          ? !chanelControl.urlLanguages.oneRus.includes(".mp4") && !chanelControl.urlLanguages.oneRus.includes(".m3u8")
            ? chanelControl.urlLanguages.oneRus
            : chanelControl.urlLanguages.oneRus.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.oneRus}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.oneRus}`
          : ""
        : chanelControl.current === 2
          ? isVisibleRus
            ? !chanelControl.urlLanguages.twoRus.includes(".mp4") && !chanelControl.urlLanguages.twoRus.includes(".m3u8")
              ? chanelControl.urlLanguages.twoRus
              : chanelControl.urlLanguages.twoRus.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.twoRus}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.twoRus}`
            : ""
          : chanelControl.current === 3
            ? isVisibleRus
              ? !chanelControl.urlLanguages.threeRus.includes(".mp4") && !chanelControl.urlLanguages.threeRus.includes(".m3u8")
                ? chanelControl.urlLanguages.threeRus
                : chanelControl.urlLanguages.threeRus.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.threeRus}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.threeRus}`
              : ""
            : "";
    }
    if (language === "Fra") {
      return chanelControl.current === 1
        ? isVisibleFra
          ? !chanelControl.urlLanguages.oneFra.includes(".mp4") && !chanelControl.urlLanguages.oneFra.includes(".m3u8")
            ? chanelControl.urlLanguages.oneFra
            : chanelControl.urlLanguages.oneFra.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.oneFra}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.oneFra}`
          : ""
        : chanelControl.current === 2
          ? isVisibleFra
            ? !chanelControl.urlLanguages.twoFra.includes(".mp4") && !chanelControl.urlLanguages.twoFra.includes(".m3u8")
              ? chanelControl.urlLanguages.twoFra
              : chanelControl.urlLanguages.twoFra.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.twoFra}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.twoFra}`
            : ""
          : chanelControl.current === 3
            ? isVisibleFra
              ? !chanelControl.urlLanguages.threeFra.includes(".mp4") && !chanelControl.urlLanguages.threeFra.includes(".m3u8")
                ? chanelControl.urlLanguages.threeFra
                : chanelControl.urlLanguages.threeFra.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.threeFra}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.threeFra}`
              : ""
            : "";
    }

    if (language === "Ger") {
      return chanelControl.current === 1
        ? isVisibleGer
          ? !chanelControl.urlLanguages.oneGer.includes(".mp4") && !chanelControl.urlLanguages.oneGer.includes(".m3u8")
            ? chanelControl.urlLanguages.oneGer
            : chanelControl.urlLanguages.oneGer.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.oneGer}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.oneGer}`
          : ""
        : chanelControl.current === 2
          ? isVisibleGer
            ? !chanelControl.urlLanguages.twoGer.includes(".mp4") && !chanelControl.urlLanguages.twoGer.includes(".m3u8")
              ? chanelControl.urlLanguages.twoGer
              : chanelControl.urlLanguages.twoGer.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.twoGer}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.twoGer}`
            : ""
          : chanelControl.current === 3
            ? isVisibleGer
              ? !chanelControl.urlLanguages.threeGer.includes(".mp4") && !chanelControl.urlLanguages.threeGer.includes(".m3u8")
                ? chanelControl.urlLanguages.threeGer
                : chanelControl.urlLanguages.threeGer.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.threeGer}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.threeGer}`
              : ""
            : "";
    }
    if (language === "Ita") {
      return chanelControl.current === 1
        ? isVisibleIta
          ? !chanelControl.urlLanguages.oneIta.includes(".mp4") && !chanelControl.urlLanguages.oneIta.includes(".m3u8")
            ? chanelControl.urlLanguages.oneIta
            : chanelControl.urlLanguages.oneIta.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.oneIta}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.oneIta}`
          : ""
        : chanelControl.current === 2
          ? isVisibleIta
            ? !chanelControl.urlLanguages.twoIta.includes(".mp4") && !chanelControl.urlLanguages.twoIta.includes(".m3u8")
              ? chanelControl.urlLanguages.twoIta
              : chanelControl.urlLanguages.twoIta.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.twoIta}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.twoIta}`
            : ""
          : chanelControl.current === 3
            ? isVisibleIta
              ? !chanelControl.urlLanguages.threeIta.includes(".mp4") && !chanelControl.urlLanguages.threeIta.includes(".m3u8")
                ? chanelControl.urlLanguages.threeIta
                : chanelControl.urlLanguages.threeIta.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.threeIta}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.threeIta}`
              : ""
            : "";
    }
    if (language === "At") {
      return chanelControl.current === 1
        ? isVisibleAt
          ? !chanelControl.urlLanguages.oneAt.includes(".mp4") && !chanelControl.urlLanguages.oneAt.includes(".m3u8")
            ? chanelControl.urlLanguages.oneAt
            : chanelControl.urlLanguages.oneAt.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.oneAt}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.oneAt}`
          : ""
        : chanelControl.current === 2
          ? isVisibleAt
            ? !chanelControl.urlLanguages.twoAt.includes(".mp4") && !chanelControl.urlLanguages.twoAt.includes(".m3u8")
              ? chanelControl.urlLanguages.twoAt
              : chanelControl.urlLanguages.twoAt.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.twoAt}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.twoAt}`
            : ""
          : chanelControl.current === 3
            ? isVisibleAt
              ? !chanelControl.urlLanguages.threeAt.includes(".mp4") && !chanelControl.urlLanguages.threeAt.includes(".m3u8")
                ? chanelControl.urlLanguages.threeAt
                : chanelControl.urlLanguages.threeAt.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.threeAt}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.threeAt}`
              : ""
            : "";
    }
    if (language === "Ao") {
      let _src = '';

      _src = chanelControl.current === 1
        ? isVisibleAo
          ? !chanelControl.urlLanguages.oneAo.includes(".mp4") && !chanelControl.urlLanguages.oneAo.includes(".m3u8")
            ? chanelControl.urlLanguages.oneAo
            : chanelControl.urlLanguages.oneAo.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.oneAo}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.oneAo}`
          : ""
        : chanelControl.current === 2
          ? isVisibleAo
            ? !chanelControl.urlLanguages.twoAo.includes(".mp4") && !chanelControl.urlLanguages.twoAo.includes(".m3u8")
              ? chanelControl.urlLanguages.twoAo
              : chanelControl.urlLanguages.twoAo.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.twoAo}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.twoAo}`
            : ""
          : chanelControl.current === 3
            ? isVisibleAo
              ? !chanelControl.urlLanguages.threeAo.includes(".mp4") && !chanelControl.urlLanguages.threeAo.includes(".m3u8")
                ? chanelControl.urlLanguages.threeAo
                : chanelControl.urlLanguages.threeAo.includes(".mp4") ? `/${event.id}/videomp4&url=${chanelControl.urlLanguages.threeAo}` : `/${event.id}/videom3u8&url=${chanelControl.urlLanguages.threeAo}`
              : ""
            : "";

      if (isIOS && _src.includes(".m3u8")) {
        _src = _src.split('videom3u8&url=')[1];
      }

      return _src;
    }
  }

  const getKeyIframe = (lang) => {
    if (lang === "En") {
      return (chanelControl.current === 1
        ? isVisibleEn
          ? chanelControl.urlLanguages.oneEn
          : "transmissionIframe"
        : isVisibleEn
          ? chanelControl.urlLanguages.twoEn
          : isVisibleEn
            ? chanelControl.urlLanguages.threeEn
            : "transmissionIframe")
    }
    if (lang === "Es") {
      return (chanelControl.current === 1
        ? isVisibleEs
          ? chanelControl.urlLanguages.oneEs
          : "transmissionIframe"
        : isVisibleEs
          ? chanelControl.urlLanguages.twoEs
          : isVisibleEs
            ? chanelControl.urlLanguages.threeEs
            : "transmissionIframe")
    }
    if (lang === "Rus") {
      return (chanelControl.current === 1
        ? isVisibleRus
          ? chanelControl.urlLanguages.oneRus
          : "transmissionIframe"
        : isVisibleRus
          ? chanelControl.urlLanguages.twoRus
          : isVisibleRus
            ? chanelControl.urlLanguages.threeRus
            : "transmissionIframe")
    }
    if (lang === "Fra") {
      return (chanelControl.current === 1
        ? isVisibleFra
          ? chanelControl.urlLanguages.oneFra
          : "transmissionIframe"
        : isVisibleFra
          ? chanelControl.urlLanguages.twoFra
          : isVisibleFra
            ? chanelControl.urlLanguages.threeFra
            : "transmissionIframe")
    }
    if (lang === "Ger") {
      return (chanelControl.current === 1
        ? isVisibleGer
          ? chanelControl.urlLanguages.oneGer
          : "transmissionIframe"
        : isVisibleGer
          ? chanelControl.urlLanguages.twoGer
          : isVisibleGer
            ? chanelControl.urlLanguages.threeGer
            : "transmissionIframe")
    }
    if (lang === "Ita") {
      return (chanelControl.current === 1
        ? isVisibleIta
          ? chanelControl.urlLanguages.oneIta
          : "transmissionIframe"
        : isVisibleIta
          ? chanelControl.urlLanguages.twoIta
          : isVisibleIta
            ? chanelControl.urlLanguages.threeIta
            : "transmissionIframe")
    }
    if (lang === "At") {
      return (chanelControl.current === 1
        ? isVisibleAt
          ? chanelControl.urlLanguages.oneAt
          : "transmissionIframe"
        : isVisibleAt
          ? chanelControl.urlLanguages.twoAt
          : isVisibleAt
            ? chanelControl.urlLanguages.threeAt
            : "transmissionIframe")
    }
    if (lang === "Ao") {
      return (chanelControl.current === 1
        ? isVisibleAo
          ? chanelControl.urlLanguages.oneAo
          : "transmissionIframe"
        : isVisibleAo
          ? chanelControl.urlLanguages.twoAo
          : isVisibleAo
            ? chanelControl.urlLanguages.threeAo
            : "transmissionIframe")
    }
    return (chanelControl.current === 1
      ? isVisiblePo
        ? chanelControl.urlLanguages.onePt
        : "transmissionIframe"
      : isVisiblePo
        ? chanelControl.urlLanguages.twoPt
        : isVisiblePo
          ? chanelControl.urlLanguages.threePt
          : "transmissionIframe")
  }

  // if (isIOS) {
  //   return renderIos();
  // }

  return (
    <div className={`${!isIOS ? "video-container" : ''}`} style={{ marginLeft: windowWidth < 926 ? '0%' : '5%' }}>
      {renderIframeControl()}
    </div>
  );

  function renderIos() {
    return (
      <FormRow margin='0px' minHeigth='600px'>
        {renderIframeControl()}
      </FormRow>
    )
  }

  function renderIframeControl() {
    return (
      <>
        {/* <TrPushNotification currentPage={props.currentPage} /> */}
        <TransmissionNotification />
        {isVisibleEmoji && (
          <>
            <RenderEmojiOnScreen
              emojis={emojis}
              emojiValue={emojiValue}
            />
          </>
        )}

        {/* <img src={video} alt="video" /> */}
        {iframeControl.current === 'pt' ? (
          <> {renderIframe("Po")} </>
        ) : iframeControl.current === 'en' ? (
          <> {renderIframe("En")} </>
        ) : iframeControl.current === 'sp' ? (
          <> {renderIframe("Es")} </>
        ) : iframeControl.current === 'rus' ? (
          <> {renderIframe("Rus")} </>
        ) : iframeControl.current === 'fra' ? (
          <> {renderIframe("Fra")} </>
        ) : iframeControl.current === 'ger' ? (
          <> {renderIframe("Ger")} </>
        ) : iframeControl.current === 'ita' ? (
          <> {renderIframe("Ita")} </>
        ) : iframeControl.current === 'at' ? (
          <> {renderIframe("At")} </>
        ) : iframeControl.current === 'ao' && (
          <>
            {isIOS && setVideo("Ao").includes(".m3u8") ? (
              <video width="100%" controls>
                <source src={setVideo("Ao")} type="application/x-mpegURL" />
              </video>
            ) : (
              <iframe
                key={
                  chanelControl.current === 1
                    ? isVisibleAo
                      ? chanelControl.urlLanguages.oneAo
                      : "transmissionIframe"
                    : isVisibleAo
                      ? chanelControl.urlLanguages.twoAo
                      : isVisibleAo
                        ? chanelControl.urlLanguages.threeAo
                        : "transmissionIframe"
                }
                width={windowWidth < 560 ? `${windowWidth - 40}` : '560'}
                height="315"
                src={setVideo("Ao")}
                frameborder="0"
                allow="camera; microphone; accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                webkitAllowFullScreen
                mozallowfullscreen
              ></iframe>
            )}
          </>
        )}
      </>
    )
  }

  function renderIframe(lang) {
    let _src = setVideo(lang);

    if (isIOS && _src.includes(".m3u8")) {
      _src = _src.split('videom3u8&url=')[1];
      return (
        <video width={windowWidth < 560 ? `${windowWidth - 40}` : '560'} height="315" controls>
          <source src={_src} type="application/x-mpegURL" />
        </video>
      );
    }

    return (
      <iframe
        key={getKeyIframe(lang)}
        width={windowWidth < 560 ? `${windowWidth - 40}` : '560'}
        height="315"
        src={_src}
        frameborder="0"
        allow="camera; microphone; accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        webkitAllowFullScreen
        mozallowfullscreen
      ></iframe>
    )
  }
};

export default VideoContainer;
