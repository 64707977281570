import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';

import {
  Container,
  FormRow,
  FormColumn,
  InputFieldOutlined,
  Button,
  Loading
} from '../components';

const getPageId216Base = () => {
  return {
    orderBy: ["name", "order"],
    categoryOrder: ["Categoria 1", "Categoria 2"],
    sponsors: [
      {
        name: "Nome do Patrocinador",
        segment: "Segmento 1",
        tags: "obras, logística",
        text1: "Texto para adicionar ao card",
        image: "urlLogo",
        order: 1,
        category: "Categoria 1",
        standId: 1234
      }
    ]
  }
}

const Component = props => {
  const {
    pageId,
    jsonBase,
    standsList,
  } = props;

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("salvando"));
  const [openLoading, setOpenLoading] = React.useState(false);

  const [state, setState] = React.useState(false);
  const [jsonObject, setJsonObject] = React.useState();

  React.useEffect(() => {
    getComponentData();
    return () => {
    }
  }, []);

  const getComponentData = () => {
    const _json = convertJsonBaseInObject(jsonBase);
    setJsonObject(_json);
  }

  const convertJsonBaseInObject = (text) => {
    var _object;
    try {
      _object = JSON.parse(text);
    } catch (error) {
      console.log('Erro ao converter JSON');
    }

    if (!_object && pageId === 216) {
      _object = getPageId216Base();

      var _orderBy = '';
      _object.orderBy.forEach((item, i) => {
        _orderBy += i === 0 ? item : ', ' + item;
      });
      _object.orderBy = _orderBy;

      var _categoryOrder = '';
      _object.categoryOrder.forEach((item, i) => {
        _categoryOrder += i === 0 ? item : ', ' + item;
      });
      _object.categoryOrder = _categoryOrder;
    }

    return _object;
  }

  const addNewSponsor = () => {
    var _jsonObject = jsonObject;
    _jsonObject.sponsors.push(getPageId216Base().sponsors[0]);
    setJsonObject(_jsonObject);
    setState(!state);
  }

  const removeSponsor = (index) => {
    const _newArray = [];
    jsonObject.sponsors.forEach((item, i) => {
      if (i !== index) {
        _newArray.push(item);
      }
    });
    var _jsonObject = jsonObject;
    _jsonObject.sponsors = _newArray;
    setJsonObject(_jsonObject);
    setState(!state);
  }

  const save216Form = () => {
    setLoadingMessage(getDic("salvando"));
    setOpenLoading(true);

    var _jsonObject = getPageId216Base();

    if (!Array.isArray(jsonObject.orderBy)) {
      const _orderBy = jsonObject.orderBy.split(',');
      const _newOrderBy = [];
      _orderBy.forEach(item => {
        _newOrderBy.push(item.trim());
      });
      _jsonObject.orderBy = _newOrderBy;
    } else {
      _jsonObject.orderBy = jsonObject.orderBy;
    }

    if (!Array.isArray(jsonObject.categoryOrder)) {
      const _categoryOrder = jsonObject.categoryOrder.split(',');
      const _newCategoryOrder = [];
      _categoryOrder.forEach(item => {
        _newCategoryOrder.push(item.trim());
      });
      _jsonObject.categoryOrder = _newCategoryOrder;
    } else {
      _jsonObject.categoryOrder = jsonObject.categoryOrder;
    }

    _jsonObject.sponsors = jsonObject.sponsors;

    setOpenLoading(false);
    props.returnObject(_jsonObject);
  }

  const renderPage216Form = () => {
    return (
      <FormColumn padding="5px" margin="0px" background="#e8eced">
        <FormRow margin="0px" paddingTop="10px">
          <InputFieldOutlined
            key="orderBy"
            type="text"
            title={`${getDic("ordenacao")} ${getDic("patrocinadores")}`}
            value={jsonObject && jsonObject.orderBy ? jsonObject.orderBy : ''}
            onChange={e => {
              var _change = jsonObject;
              _change.orderBy = e.target.value;
              setJsonObject(_change);
              setState(!state);
            }}
          />
        </FormRow>
        <FormRow margin="0px" paddingTop="10px">
          <InputFieldOutlined
            key="categoryOrder"
            type="text"
            title={`${getDic("ordenacao")} ${getDic("categoria")}`}
            value={jsonObject && jsonObject.categoryOrder ? jsonObject.categoryOrder : ''}
            onChange={e => {
              var _change = jsonObject;
              _change.categoryOrder = e.target.value;
              setJsonObject(_change);
              setState(!state);
            }}
          />
        </FormRow>
        <FormRow margin="0px" paddingTop="10px">
          <div style={{ pading: 10, background: 'white', borderRadius: 5 }}>
            {jsonObject && jsonObject.sponsors ? jsonObject.sponsors.map((sponsor, i) => {
              const _isEven = i % 2 === 0 || i === 0 ? "#999999" : "#bfbfbf";
              return (
                <FormRow margin="0px" paddingTop="10px" background={_isEven}>
                  {renderSponsorForm(sponsor, i)}
                </FormRow>
              )
            }) : null}
            <div style={{ width: '200px', padding: 4 }}>
              <AddIcon fontSize="large" onClick={() => addNewSponsor()} />
            </div>
          </div>
        </FormRow>
        <FormRow margin="0px" paddingTop="10px" align="center">
          <Button
            text={getDic("salvar")}
            onClick={() => save216Form()}
          />
        </FormRow>
      </FormColumn>
    )
  }

  const renderSponsorForm = (sponsor, index) => {
    const _keys = [];
    for (var key in sponsor) {
      _keys.push(key);
    }

    return (
      <FormRow margin="0px">
        {_keys.map(key => {
          return (
            <>{renderSponsorField(key, sponsor, index)}</>
          )
        })}
        <div style={{ width: '200px', padding: 4 }}>
          <RemoveIcon fontSize="large" onClick={() => removeSponsor(index)} />
        </div>
      </FormRow>
    )
  }

  const renderSponsorField = (key, sponsor, index) => {
    return (
      <div style={{ width: '200px', padding: 4 }}>
        <InputFieldOutlined
          key={`${key}${index}`}
          type="text"
          title={key}
          value={sponsor[key]}
          onChange={e => {
            var _change = jsonObject;
            _change.sponsors[index][key] = e.target.value;
            setJsonObject(_change);
            setState(!state);
          }}
        />
      </div>
    )
  }

  return (
    <Container background="#e8eced" align="center" height="100%" >
      {pageId === 216 && (
        renderPage216Form()
      )}

      <Loading open={openLoading} message={loadingMessage} />
    </Container>
  )
};

export default Component;
