import React from "react";
import { connect } from "react-redux";
import { store } from '../store';
import { getDic } from "../assets/i18n/dictionary";
import { getConfigListByPage } from "../services/configService";
import { updateConfig } from "../store/actions";
import Icon from "@material-ui/icons/InfoOutlined";
import CalendarWidget from "../components/CalendarWidget";
import SearchIcon from '@material-ui/icons/Search';
import * as moment from "moment";
import 'moment/locale/es';
import "moment/locale/pt-br";
import { insertNewAccess, getSystemActions } from "../services/accessService";

//Imports de components
import {
  Container,
  Avatar,
  AvatarGroup,
  FormColumn,
  FormRow,
  ActivityIndicator,
  Button,
  Card,
  TextBig,
  TextMedium,
  TextSmall,
  MenuButton,
  Modal,
  BackButton,
  ElementTreatment,
  Link,

  InputFieldOutlined,
  SelectList
} from "../components";

class SchedulesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchSessions: "",
      isLoading: true,
      configList: [],
      executeElement: false,
      chosenConfig: null,
      anchorEl: null,
      imageURLBackground_1600x900: "",
      colorBackground: "#212121",
      openModalScheduleInfo: false,
      modalScheduleInfoText: "",
      event: this.props.event,
      schedules: this.props.schedules,
      urlDownloadFile:
        "https://s3.amazonaws.com/bucket.aws.public/sistema-inteegra-hall/files/ApresentacaoInteegra.pptx",

      // Controle de busca por categoria, data e hora
      categoriesList: [],
      categoriesListValue: "",
      datesList: [],
      datesListValue: "",
      hourList: [],
      hourListValue: "",
      speakersText: getDic("palestrantes"),
    };
  }

  async componentDidMount() {
    //Aplica configurações
    const _url = !this.props.isModal ? this.props.match.url : `/${this.props.event.id}/schedules&0`;
    const configList = await getConfigListByPage(_url);

    var configBackgroundImage = configList.find((item) => {
      return item.codeScreen === "IMAGE-001" && item.screenId === 3;
    });
    if (configBackgroundImage) {
      this.setState({
        imageBackgroundUrl: configBackgroundImage.url[0],
        colorBackground: "#ffffff00",
      });
    }

    var speakersText = configList.find((item) => {
      return item.codeScreen === "TITTLE-001" && item.screenId === 3;
    });
    if (speakersText) {
      this.setState({ speakersText: speakersText.tooltip });
    }

    var _openConfig = configList.find(item => {
      return item.codeScreen.includes("SCHEDULE-") && item.multiConfig.openWithPage;
    });
    if (_openConfig) {
      this.setState({
        executeElement: true,
        chosenConfig: _openConfig,
      });
      _openConfig.multiConfig.openWithPage = false;
      this.props.updateConfig(_openConfig);
    }

    // Monta lista de categorias e datas
    const _categories = [];
    const _dates = [];
    const _hourList = [];

    const _obj1 = {
      text: getDic("todos"),
      value: "",
    }
    _categories.push(_obj1);
    _dates.push(_obj1);
    _hourList.push(_obj1);

    this.props.schedules.forEach(item => {
      const _cat = item.multiConfig.category

      const _findCat = _categories.find(el => {
        return el.value === _cat;
      });
      if (!_findCat) {
        var _text = _cat.replace(/_/g, " ");
        const _obj = {
          value: _cat,
          text: _text,
        }
        _categories.push(_obj);
      }

      const _findDate = _dates.find(el => {
        return el.value === item.startDate;
      });
      if (!_findDate) {
        const _obj = {
          value: item.startDate,
          text: moment(item.startDate).locale(this.props.lang.lang).format('D MMMM YYYY'),
        }
        _dates.push(_obj);
      }

      const _findHour = _hourList.find(el => {
        return el.value === item.startTime;
      });
      if (!_findHour) {
        const _obj = {
          value: item.startTime,
          text: item.startTime,
        }
        _hourList.push(_obj);
      }
    });

    // Verifica se está sendo aberto via tela ou modal
    var _defaultCategory;
    if (this.props.chosenConfig && this.props.chosenConfig.url[0] && this.props.chosenConfig.url[0].includes("schedules&")) {
      const _urlArray = this.props.chosenConfig.url[0].split("&");
      _defaultCategory = _urlArray[1] && _urlArray[1] !== "0" ? _urlArray[1].replace("_", " ") : null;
    }
    else {
      _defaultCategory = this.props.match ? this.props.match.params.category === "0" ? null : this.props.match.params.category : null;
    }

    const _listFinalCategories = _categories.sort((a, b) => {
      return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)
    });
    const _listFinalDates = _dates.sort((a, b) => {
      return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)
    });
    const _listFinalHour = _hourList.sort((a, b) => {
      return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)
    });

    this.setState({
      isLoading: false,
      configList: configList,
      categoriesList: _listFinalCategories,
      categoriesListValue: _defaultCategory ? _defaultCategory : _categories[0].value,
      datesList: _listFinalDates,
      datesListValue: _dates[0].value,
      hourList: _listFinalHour,
      hourListValue: _hourList[0].value,
    });

    if (!this.props.isModal) {
      await insertNewAccess(
        this.props.match.url,
        "",
        getSystemActions().accessPage
      );
    }
  }

  async componentWillUnmount() {
    if (!this.props.isModal) {
      await insertNewAccess(
        this.props.match.url,
        "",
        getSystemActions().exitPage
      );
    }
  }

  componentDidCatch(e) {
    var error = e.toString();

    if (error.includes("Error: Maximum update depth exceeded")) {
      window.location.reload();
    }
  }

  downloadFile() {
    let a = document.createElement("a");
    a.href = this.state.urlDownloadFile;
    a.download = "employees.json";
    a.click();
  }

  async selectConfig(configId) {
    const _config = store.getState().config.find(conf => {
      return conf.id === parseInt(configId) && conf.codeScreen.includes("ELEMENT");
    });
    if (_config) {
      this.setState({ executeElement: true, chosenConfig: _config });
    }
  }

  async selectElement(element) {
    //Busca a configuração e executa instrução
    this.state.configList.find((item) => {
      if (item.scheduleId === element.id) {
        this.setState({ executeElement: true, chosenConfig: item });
      }
      return null;
    });
  }

  executeElement() {
    if (this.state.executeElement) {
      return (
        <ElementTreatment
          history={this.props.history}
          chosenConfig={this.state.chosenConfig}
          onClose={() => {
            this.setState({ executeElement: false, chosenConfig: null });
          }}
        />
      );
    }
  }

  renderSpeakers(item) {
    if (item.styles.speakerName && item.styles.speakerName.length > 0) {
      return (
        <div>
          <FormRow align="flex-start">
            <TextMedium color={item.styles.textColorCard} fontWeight="bold">
              {this.state.speakersText}
            </TextMedium>
          </FormRow>
          {item.styles.speakerName.map((speaker) => {
            var _speakerName = speaker.substring(0, 15);
            return (
              <FormRow align="flex-start">
                <TextMedium color={item.styles.textColorCard}>
                  {_speakerName}
                </TextMedium>
              </FormRow>
            );
          })}
        </div>
      );
    }
    else if (item.speakers.length > 0) {
      return (
        <div>
          <FormRow align="center">
            <TextMedium color={item.styles.textColorCard} fontWeight="bold">
              {this.state.speakersText}
            </TextMedium>
          </FormRow>
          <FormRow align="center">
            <AvatarGroup>
              {item.speakers.map((speaker) => {
                return (
                  <Avatar
                    key={speaker.id}
                    size="small"
                    src={speaker.image}
                    onClick={() => console.log("Hover")}
                  />
                );
              })}
            </AvatarGroup>
          </FormRow>
        </div>
      );
    }
    return null;
  }

  renderFiles(item) {
    if (item.files.length > 0) {
      return (
        <div>
          <FormRow>
            <TextMedium fontWeight="bold" color={item.styles.textColorCard}>
              Downloads
            </TextMedium>
          </FormRow>
          {item.files.map((file) => {
            return (
              <FormRow>
                <Link color={item.styles.textColorCard}>
                  <TextMedium
                    color={item.styles.textColorCard}
                    onClick={() => {
                      this.setState({ urlDownloadFile: file.url });
                      this.downloadFile();
                    }}
                  >
                    {file.name}
                  </TextMedium>
                </Link>
              </FormRow>
            );
          })}
        </div>
      );
    }
    return null;
  }

  renderMessage(schedule) {
    //Verifica se a programação está ocorrendo ou já ocorreu
    //Comparação de datas
    var startDate = moment(schedule.startDate).format("YYYY/MM/DD");
    var currentDate = moment().format("YYYY/MM/DD");
    if (startDate < currentDate)
      return (
        <TextSmall color={schedule.styles.textColorCard}>
          {getDic("disponivel").toUpperCase()}
        </TextSmall>
      );
    if (startDate > currentDate)
      return (
        <TextSmall color={schedule.styles.textColorCard}>
          {getDic("breve").toUpperCase()}
        </TextSmall>
      );

    //Comparação de horas
    var currentHour = moment().format("HH:mm");
    if (schedule.startTime <= currentHour && currentHour <= schedule.endTime)
      return (
        <TextSmall color="red" blink>
          {getDic("ao-vivo").toUpperCase()}
        </TextSmall>
      );
    if (schedule.endTime < currentHour)
      return (
        <TextSmall color={schedule.styles.textColorCard}>
          {getDic("disponivel").toUpperCase()}
        </TextSmall>
      );
    else
      return (
        <TextSmall color={schedule.styles.textColorCard}>
          {getDic("breve").toUpperCase()}
        </TextSmall>
      );
  }

  render() {
    if (this.state.isLoading)
      return (
        <Container background="#e8eced" align="center">
          <FormRow align="center">
            <ActivityIndicator />
          </FormRow>
        </Container>
      );
    if (this.state.schedules.length <= 0) {
      return (
        <Container background="#212121" align="center">
          <MenuButton history={this.props.history} />
          <BackButton history={this.props.history} color="#212121">
            {getDic("voltar").toUpperCase()}
          </BackButton>

          <FormRow align="center">
            <TextBig color="#e8eced">
              {getDic("programacao-mensagem-erro")}
            </TextBig>
          </FormRow>
        </Container>
      );
    }
    return (
      <Container
        align="center"
        background={this.state.colorBackground}
        height={this.props.isModal ? this.props.height : "100%"}
      >
        <FormColumn
          margin="0px"
          backgroundImage={this.state.imageBackgroundUrl}
          backgroundSize="100% 100%"
        >
          <div style={{ position: "relative", width: "100%", height: this.props.isModal ? this.props.height : "100vh", overflow: "auto" }}>
            {!this.props.isModal && (
              <>
                <MenuButton history={this.props.history} />
                <BackButton history={this.props.history} color="#212121">
                  {getDic("voltar").toUpperCase()}
                </BackButton>

              </>
            )}

            <FormRow
              paddingTop="50px"
              align="center"
              background={this.state.colorBackground}
            >
              <FormRow
                minHeight="60px"
                align="flex-end"
                background={this.state.colorBackground}
                margin="0px"
                paddingTop="5px"
              >
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  minHeight: "60px",
                  background: "white",
                  marginLeft: 10,
                  marginRight: 10,
                  paddingLeft: 10,
                  paddingTop: 6,
                  borderRadius: 5,
                  flexWrap: 'wrap-reverse',
                }}>
                  <div style={{ paddingRight: "10px", maxWidth: "400px", paddingBottom: 6 }}>
                    <InputFieldOutlined
                      placeholder={getDic("buscar") + " " + getDic("sala")}
                      value={this.state.searchSessions}
                      onChange={(e) => this.setState({ searchSessions: e.target.value })}
                      endAdornment={<SearchIcon />}
                    />
                  </div>
                  {/* <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
                <SelectList
                  title={getDic("categoria")}
                  width="160px"
                  maxWidth="160px"
                  value={this.state.categoriesListValue}
                  menuItens={this.state.categoriesList}
                  onChange={event => this.setState({ categoriesListValue: event.target.value })}
                />
              </div> */}
                  <div style={{ paddingRight: "10px", maxWidth: "400px", paddingBottom: 6 }}>
                    <SelectList
                      title={getDic("data")}
                      width="160px"
                      maxWidth="160px"
                      value={this.state.datesListValue}
                      menuItens={this.state.datesList}
                      onChange={event => this.setState({ datesListValue: event.target.value })}
                    />
                  </div>
                  <div style={{ paddingRight: "10px", maxWidth: "400px", paddingBottom: 6 }}>
                    <SelectList
                      title={getDic("hora") + " " + getDic("inicio")}
                      width="160px"
                      maxWidth="160px"
                      value={this.state.hourListValue}
                      menuItens={this.state.hourList}
                      onChange={event => this.setState({ hourListValue: event.target.value })}
                    />
                  </div>
                </div>
              </FormRow>
              {this.state.schedules
                .filter(
                  (e) =>
                    e.title
                      .toUpperCase()
                      .indexOf(this.state.searchSessions.toUpperCase()) > -1
                )
                .filter(
                  (e) =>
                    e.multiConfig.category
                      .indexOf(this.state.categoriesListValue) > -1
                )
                .filter(
                  (e) =>
                    e.startDate
                      .indexOf(this.state.datesListValue) > -1
                )
                .filter(
                  (e) =>
                    e.startTime
                      .indexOf(this.state.hourListValue) > -1
                )
                .map((item, index) => {
                  if (item.title.toUpperCase() === "CREDENCIAMENTO") return null;
                  if (
                    item.groupId &&
                    item.groupId.length > 0 &&
                    this.props.user &&
                    !item.groupId.includes(this.props.user.groupId)
                  )
                    return null;
                  const open = Boolean(this.state.anchorEl);
                  const id = open ? item.id : undefined;
                  const { anchorEl } = this.state;
                  //console.log(this.state);
                  return (
                    <div
                      key={item.id}
                      style={{
                        margin: "10px",
                        width: "100%",
                        maxWidth: "450px",
                        marginTop: 30,
                      }}
                    >
                      <Card backgroundColor={item.styles.backgroundCard}>
                        <div style={{ minHeight: item.styles.hideDate !== "true" ? "185px" : "140px" }}>
                          <FormRow align="center">
                            <FormColumn align="center" width="80%">
                              <TextMedium
                                color={item.styles.textColorCard}
                                fontWeight="bold"
                                wordBreak="break-word"
                              >
                                {item.styles.titleUpperCase === "true" ? item.title.toUpperCase() : item.title}
                              </TextMedium>
                            </FormColumn>
                            {item.styles.hideDate !== "true" && (
                              <CalendarWidget
                                id={item.id}
                                item={item}
                                configList={this.state.configList[index]}
                              />
                            )}
                            {(item.description && item.description !== "") || item.styles.descriptionConfigId ? (
                              <Link color={item.styles.textColorCard}>
                                <Icon
                                  style={{ color: item.styles.textColorCard }}
                                  onClick={() => {
                                    if (item.styles.descriptionConfigId) {
                                      this.selectConfig(item.styles.descriptionConfigId);
                                    }
                                    else {
                                      this.setState({
                                        openModalScheduleInfo: true,
                                        modalScheduleInfoText: item.description,
                                      });
                                    }
                                  }}
                                />
                              </Link>
                            ) : null}
                          </FormRow>
                          {item.styles.hideDate !== "true" && (
                            <FormRow align="center">
                              <TextMedium color={item.styles.textColorCard}>
                                {moment(item.startDate)
                                  .locale(this.props.lang.lang === "sp" ? "es" : this.props.lang.lang)
                                  .format("LL") +
                                  ", " +
                                  item.startTime +
                                  " - " +
                                  item.endTime}
                              </TextMedium>
                            </FormRow>
                          )}
                          <FormRow align="center">
                            <FormColumn width="35%">
                              {this.renderSpeakers(item)}
                            </FormColumn>
                            <FormColumn width="3%"></FormColumn>
                            <FormColumn width="32%">
                              {this.renderFiles(item)}
                            </FormColumn>
                            <FormColumn width="3%"></FormColumn>
                            <FormColumn width="27%" align="center">
                              <FormRow align="center">
                                <Button
                                  color={item.styles.backgroundButton}
                                  textColor={item.styles.textColorButton}
                                  text={getDic("entrar")}
                                  onClick={() => {
                                    if (item.styles.enterButtonConfigId) {
                                      this.selectConfig(item.styles.enterButtonConfigId);
                                    }
                                    else {
                                      this.selectElement(item);
                                    }
                                  }}
                                />
                              </FormRow>
                              {this.renderMessage(item)}
                            </FormColumn>
                          </FormRow>
                        </div>
                      </Card>
                    </div>
                  );
                })}
            </FormRow>

            {/* Modal de informações da sessão */}
            <Modal
              open={this.state.openModalScheduleInfo}
              closeButtonClick={(e) => {
                this.setState({ openModalScheduleInfo: false });
              }}
              onClose={(ret) => {
                this.setState({ openModalScheduleInfo: false });
                console.log(ret);
              }}
            >
              <Container background="#e8eced" align="left" height="100%">
                <TextMedium>
                  {getDic("descricao") + ": " + this.state.modalScheduleInfoText}
                </TextMedium>
              </Container>
            </Modal>

            {/* Modal de páginas */}
            {this.executeElement()}
          </div>
        </FormColumn>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  var _schedules = state.schedules.sort(function (a, b) {
    if (a.startDate === b.startDate) {
      if (a.startTime === b.startTime) {
        return (a.endTime < b.endTime) ? -1 : (a.endTime > b.endTime) ? 1 : 0;
      }
      else {
        return (a.startTime < b.startTime) ? -1 : (a.startTime > b.startTime) ? 1 : 0;
      }
    }
    else {
      return (a.startDate < b.startDate) ? -1 : 1;
    }
  });

  return {
    event: state.event,
    user: state.user,
    schedules: _schedules,
    lang: state.lang,
    chosenConfig: state.chosenConfig,
  };
}

const mapDispatchToProps = {
  updateConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesPage);
