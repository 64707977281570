import React from "react";

//Imports de páginas
import FeedPostPage from "../pages/FeedPostPage";
import RankingPage from "../pages/RankingPage";
import InteegraflixPage from "../pages/InteegraflixPage";
import ProfilePage from "../pages/ProfilePage";
import ChatHistoryPage from "../pages/ChatHistoryPage";
import NetworkChatV2 from "../components/NetworkChatV2";
import Transmission from "../pages/TransmissionPages/Transmission";
import Transmission2 from "../pages/TransmissionPages/Transmission2";
import Transmission3 from "../pages/TransmissionPages/Transmission3";
import Transmission4 from "../pages/TransmissionPages/Transmission4";
import Transmission5 from "../pages/TransmissionPages/Transmission5";
import Transmission6 from "../pages/TransmissionPages/Transmission6";
import Transmission7 from "../pages/TransmissionPages/Transmission7";
import Transmission8 from "../pages/TransmissionPages/Transmission8";
import Transmission9 from "../pages/TransmissionPages/Transmission9";
import Transmission10 from "../pages/TransmissionPages/Transmission10";
import Transmission11 from "../pages/TransmissionPages/Transmission11";
import Transmission12 from "../pages/TransmissionPages/Transmission12";
import Transmission13 from "../pages/TransmissionPages/Transmission13";
import Transmission14 from "../pages/TransmissionPages/Transmission14";
import Transmission15 from "../pages/TransmissionPages/Transmission15";
import Transmission16 from "../pages/TransmissionPages/Transmission16";
import Transmission17 from "../pages/TransmissionPages/Transmission17";
import Transmission18 from "../pages/TransmissionPages/Transmission18";
import Transmission19 from "../pages/TransmissionPages/Transmission19";
import Transmission20 from "../pages/TransmissionPages/Transmission20";
import Transmission21 from "../pages/TransmissionPages/Transmission21";
import Transmission22 from "../pages/TransmissionPages/Transmission22";
import Transmission23 from "../pages/TransmissionPages/Transmission23";
import Transmission24 from "../pages/TransmissionPages/Transmission24";
import Transmission25 from "../pages/TransmissionPages/Transmission25";
import Transmission26 from "../pages/TransmissionPages/Transmission26";
import Transmission27 from "../pages/TransmissionPages/Transmission27";
import Transmission28 from "../pages/TransmissionPages/Transmission28";
import Transmission29 from "../pages/TransmissionPages/Transmission29";
import Transmission30 from "../pages/TransmissionPages/Transmission30";
import Transmission31 from "../pages/TransmissionPages/Transmission31";
import Transmission32 from "../pages/TransmissionPages/Transmission32";
import Transmission33 from "../pages/TransmissionPages/Transmission33";
import Transmission34 from "../pages/TransmissionPages/Transmission34";
import Transmission35 from "../pages/TransmissionPages/Transmission35";
import Transmission36 from "../pages/TransmissionPages/Transmission36";
import Transmission37 from "../pages/TransmissionPages/Transmission37";
import Transmission38 from "../pages/TransmissionPages/Transmission38";
import Transmission39 from "../pages/TransmissionPages/Transmission39";
import Transmission40 from "../pages/TransmissionPages/Transmission40";
import Transmission41 from "../pages/TransmissionPages/Transmission41";
import Transmission42 from "../pages/TransmissionPages/Transmission42";
import Transmission43 from "../pages/TransmissionPages/Transmission43";
import Transmission44 from "../pages/TransmissionPages/Transmission44";
import Transmission45 from "../pages/TransmissionPages/Transmission45";
import Transmission46 from "../pages/TransmissionPages/Transmission46";
import Transmission47 from "../pages/TransmissionPages/Transmission47";
import Transmission48 from "../pages/TransmissionPages/Transmission48";
import Transmission49 from "../pages/TransmissionPages/Transmission49";
import Transmission50 from "../pages/TransmissionPages/Transmission50";
import Transmission51 from "../pages/TransmissionPages/Transmission51";
import Transmission52 from "../pages/TransmissionPages/Transmission52";
import Transmission53 from "../pages/TransmissionPages/Transmission53";
import Transmission54 from "../pages/TransmissionPages/Transmission54";
import Transmission55 from "../pages/TransmissionPages/Transmission55";
import Transmission56 from "../pages/TransmissionPages/Transmission56";
import Transmission57 from "../pages/TransmissionPages/Transmission57";
import Transmission58 from "../pages/TransmissionPages/Transmission58";
import Transmission59 from "../pages/TransmissionPages/Transmission59";
import Transmission60 from "../pages/TransmissionPages/Transmission60";
import Transmission61 from "../pages/TransmissionPages/Transmission61";
import Transmission62 from "../pages/TransmissionPages/Transmission62";
import Transmission63 from "../pages/TransmissionPages/Transmission63";
import Transmission64 from "../pages/TransmissionPages/Transmission64";
import Transmission65 from "../pages/TransmissionPages/Transmission65";
import Transmission66 from "../pages/TransmissionPages/Transmission66";
import Transmission67 from "../pages/TransmissionPages/Transmission67";
import Transmission68 from "../pages/TransmissionPages/Transmission68";
import Transmission69 from "../pages/TransmissionPages/Transmission69";
import Transmission70 from "../pages/TransmissionPages/Transmission70";
import Transmission71 from "../pages/TransmissionPages/Transmission71";
import Transmission72 from "../pages/TransmissionPages/Transmission72";
import Transmission73 from "../pages/TransmissionPages/Transmission73";
import Transmission74 from "../pages/TransmissionPages/Transmission74";
import Transmission75 from "../pages/TransmissionPages/Transmission75";
import Transmission76 from "../pages/TransmissionPages/Transmission76";
import Transmission77 from "../pages/TransmissionPages/Transmission77";
import Transmission78 from "../pages/TransmissionPages/Transmission78";
import Transmission79 from "../pages/TransmissionPages/Transmission79";
import Transmission80 from "../pages/TransmissionPages/Transmission80";
import Transmission81 from "../pages/TransmissionPages/Transmission81";
import Transmission82 from "../pages/TransmissionPages/Transmission82";
import Transmission83 from "../pages/TransmissionPages/Transmission83";
import Transmission84 from "../pages/TransmissionPages/Transmission84";
import Transmission85 from "../pages/TransmissionPages/Transmission85";
import Transmission86 from "../pages/TransmissionPages/Transmission86";
import Transmission87 from "../pages/TransmissionPages/Transmission87";
import Transmission88 from "../pages/TransmissionPages/Transmission88";
import Transmission89 from "../pages/TransmissionPages/Transmission89";
import Transmission90 from "../pages/TransmissionPages/Transmission90";
import Transmission91 from "../pages/TransmissionPages/Transmission91";
import Transmission92 from "../pages/TransmissionPages/Transmission92";
import Transmission93 from "../pages/TransmissionPages/Transmission93";
import Transmission94 from "../pages/TransmissionPages/Transmission94";
import Transmission95 from "../pages/TransmissionPages/Transmission95";
import Transmission96 from "../pages/TransmissionPages/Transmission96";
import Transmission97 from "../pages/TransmissionPages/Transmission97";
import Transmission98 from "../pages/TransmissionPages/Transmission98";
import Transmission99 from "../pages/TransmissionPages/Transmission99";
import Transmission100 from "../pages/TransmissionPages/Transmission100";

import GeneralPage01 from "../pages/GeneralPage/GeneralPage";
import GeneralPage02 from "../pages/GeneralPage/General2Page";
import GeneralPage03 from "../pages/GeneralPage/General3Page";
import GeneralPage04 from "../pages/GeneralPage/General4Page";
import GeneralPage05 from "../pages/GeneralPage/General5Page";
import GeneralPage06 from "../pages/GeneralPage/General6Page";
import GeneralPage07 from "../pages/GeneralPage/General7Page";
import GeneralPage08 from "../pages/GeneralPage/General8Page";
import GeneralPage09 from "../pages/GeneralPage/General9Page";
import GeneralPage10 from "../pages/GeneralPage/General10Page";
import GeneralPage11 from "../pages/GeneralPage/General11Page";
import GeneralPage12 from "../pages/GeneralPage/General12Page";
import GeneralPage13 from "../pages/GeneralPage/General13Page";
import GeneralPage14 from "../pages/GeneralPage/General14Page";
import GeneralPage15 from "../pages/GeneralPage/General15Page";
import GeneralPage16 from "../pages/GeneralPage/General16Page";
import GeneralPage17 from "../pages/GeneralPage/General17Page";
import GeneralPage18 from "../pages/GeneralPage/General18Page";
import GeneralPage19 from "../pages/GeneralPage/General19Page";
import GeneralPage20 from "../pages/GeneralPage/General20Page";
import GeneralPage21 from "../pages/GeneralPage/General21Page";
import GeneralPage22 from "../pages/GeneralPage/General22Page";
import GeneralPage23 from "../pages/GeneralPage/General23Page";
import GeneralPage24 from "../pages/GeneralPage/General24Page";
import GeneralPage25 from "../pages/GeneralPage/General25Page";
import GeneralPage26 from "../pages/GeneralPage/General26Page";
import GeneralPage27 from "../pages/GeneralPage/General27Page";
import GeneralPage28 from "../pages/GeneralPage/General28Page";
import GeneralPage29 from "../pages/GeneralPage/General29Page";
import GeneralPage30 from "../pages/GeneralPage/General30Page";
import GeneralPage31 from "../pages/GeneralPage/General31Page";
import GeneralPage32 from "../pages/GeneralPage/General32Page";
import GeneralPage33 from "../pages/GeneralPage/General33Page";
import GeneralPage34 from "../pages/GeneralPage/General34Page";
import GeneralPage35 from "../pages/GeneralPage/General35Page";
import GeneralPage36 from "../pages/GeneralPage/General36Page";
import GeneralPage37 from "../pages/GeneralPage/General37Page";
import GeneralPage38 from "../pages/GeneralPage/General38Page";
import GeneralPage39 from "../pages/GeneralPage/General39Page";
import GeneralPage40 from "../pages/GeneralPage/General40Page";
import GeneralPage41 from "../pages/GeneralPage/General41Page";
import GeneralPage42 from "../pages/GeneralPage/General42Page";
import GeneralPage43 from "../pages/GeneralPage/General43Page";
import GeneralPage44 from "../pages/GeneralPage/General44Page";
import GeneralPage45 from "../pages/GeneralPage/General45Page";
import GeneralPage46 from "../pages/GeneralPage/General46Page";
import GeneralPage47 from "../pages/GeneralPage/General47Page";
import GeneralPage48 from "../pages/GeneralPage/General48Page";
import GeneralPage49 from "../pages/GeneralPage/General49Page";
import GeneralPage50 from "../pages/GeneralPage/General50Page";
import GeneralPage51 from "../pages/GeneralPage/General51Page";
import GeneralPage52 from "../pages/GeneralPage/General52Page";
import GeneralPage53 from "../pages/GeneralPage/General53Page";
import GeneralPage54 from "../pages/GeneralPage/General54Page";
import GeneralPage55 from "../pages/GeneralPage/General55Page";
import GeneralPage56 from "../pages/GeneralPage/General56Page";
import GeneralPage57 from "../pages/GeneralPage/General57Page";
import GeneralPage58 from "../pages/GeneralPage/General58Page";
import GeneralPage59 from "../pages/GeneralPage/General59Page";
import GeneralPage60 from "../pages/GeneralPage/General60Page";
import GeneralPage61 from "../pages/GeneralPage/General61Page";
import GeneralPage62 from "../pages/GeneralPage/General62Page";
import GeneralPage63 from "../pages/GeneralPage/General63Page";
import GeneralPage64 from "../pages/GeneralPage/General64Page";
import GeneralPage65 from "../pages/GeneralPage/General65Page";
import GeneralPage66 from "../pages/GeneralPage/General66Page";
import GeneralPage67 from "../pages/GeneralPage/General67Page";
import GeneralPage68 from "../pages/GeneralPage/General68Page";
import GeneralPage69 from "../pages/GeneralPage/General69Page";
import GeneralPage70 from "../pages/GeneralPage/General70Page";
import GeneralPage71 from "../pages/GeneralPage/General71Page";
import GeneralPage72 from "../pages/GeneralPage/General72Page";
import GeneralPage73 from "../pages/GeneralPage/General73Page";
import GeneralPage74 from "../pages/GeneralPage/General74Page";
import GeneralPage75 from "../pages/GeneralPage/General75Page";
import GeneralPage76 from "../pages/GeneralPage/General76Page";
import GeneralPage77 from "../pages/GeneralPage/General77Page";
import GeneralPage78 from "../pages/GeneralPage/General78Page";
import GeneralPage79 from "../pages/GeneralPage/General79Page";
import GeneralPage80 from "../pages/GeneralPage/General80Page";
import GeneralPage81 from "../pages/GeneralPage/General81Page";
import GeneralPage82 from "../pages/GeneralPage/General82Page";
import GeneralPage83 from "../pages/GeneralPage/General83Page";
import GeneralPage84 from "../pages/GeneralPage/General84Page";
import GeneralPage85 from "../pages/GeneralPage/General85Page";
import GeneralPage86 from "../pages/GeneralPage/General86Page";
import GeneralPage87 from "../pages/GeneralPage/General87Page";
import GeneralPage88 from "../pages/GeneralPage/General88Page";
import GeneralPage89 from "../pages/GeneralPage/General89Page";
import GeneralPage90 from "../pages/GeneralPage/General90Page";
import GeneralPage91 from "../pages/GeneralPage/General91Page";
import GeneralPage92 from "../pages/GeneralPage/General92Page";
import GeneralPage93 from "../pages/GeneralPage/General93Page";
import GeneralPage94 from "../pages/GeneralPage/General94Page";
import GeneralPage95 from "../pages/GeneralPage/General95Page";
import GeneralPage96 from "../pages/GeneralPage/General96Page";
import GeneralPage97 from "../pages/GeneralPage/General97Page";
import GeneralPage98 from "../pages/GeneralPage/General98Page";
import GeneralPage99 from "../pages/GeneralPage/General99Page";
import GeneralPage100 from "../pages/GeneralPage/General100Page";

import SchedulesPage from "../pages/SchedulesPage";
import SponsorsPage from "../pages/SponsorsPage";

const ElementTreatmentModalRouter = (props) => {
  if (props.chosenConfig.url[0] === "ranking") {
    return <RankingPage isModal />;
  }
  if (props.chosenConfig.url[0].includes("inteegraflix")) {
    return (
      <InteegraflixPage
        isModal
        modalPath={props.chosenConfig.urlString}
        width={props.modalContentWidth}
      />
    );
  }
  if (props.chosenConfig.url[0] === "profile") {
    return <ProfilePage isModal />;
  }
  if (props.chosenConfig.url[0] === "chat") {
    return <ChatHistoryPage isModal />;
  }
  if (props.chosenConfig.url[0].indexOf("chatv2") > -1) {
    return (
      <NetworkChatV2
        isModal
        fullScreen={props.modalContentHeight}
        networkTargetId={props.chosenConfig}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission") {
    return (
      <Transmission
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission2") {
    return (
      <Transmission2
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission3") {
    return (
      <Transmission3
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission4") {
    return (
      <Transmission4
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission5") {
    return (
      <Transmission5
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission6") {
    return (
      <Transmission6
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission7") {
    return (
      <Transmission7
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission8") {
    return (
      <Transmission8
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission9") {
    return (
      <Transmission9
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission10") {
    return (
      <Transmission10
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission11") {
    return (
      <Transmission11
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission12") {
    return (
      <Transmission12
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission13") {
    return (
      <Transmission13
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission14") {
    return (
      <Transmission14
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission15") {
    return (
      <Transmission15
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission16") {
    return (
      <Transmission16
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission17") {
    return (
      <Transmission17
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission18") {
    return (
      <Transmission18
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission19") {
    return (
      <Transmission19
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission20") {
    return (
      <Transmission20
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission21") {
    return (
      <Transmission21
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission22") {
    return (
      <Transmission22
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission23") {
    return (
      <Transmission23
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission24") {
    return (
      <Transmission24
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission25") {
    return (
      <Transmission25
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission26") {
    return (
      <Transmission26
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission27") {
    return (
      <Transmission27
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission28") {
    return (
      <Transmission28
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission29") {
    return (
      <Transmission29
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission30") {
    return (
      <Transmission30
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission31") {
    return (
      <Transmission31
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission32") {
    return (
      <Transmission32
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission33") {
    return (
      <Transmission33
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission34") {
    return (
      <Transmission34
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission35") {
    return (
      <Transmission35
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission36") {
    return (
      <Transmission36
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission37") {
    return (
      <Transmission37
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission38") {
    return (
      <Transmission38
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission39") {
    return (
      <Transmission39
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission40") {
    return (
      <Transmission40
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission41") {
    return (
      <Transmission41
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission42") {
    return (
      <Transmission42
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission43") {
    return (
      <Transmission43
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission44") {
    return (
      <Transmission44
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission45") {
    return (
      <Transmission45
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission46") {
    return (
      <Transmission46
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission47") {
    return (
      <Transmission47
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission48") {
    return (
      <Transmission48
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission49") {
    return (
      <Transmission49
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission50") {
    return (
      <Transmission50
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission51") {
    return (
      <Transmission51
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission52") {
    return (
      <Transmission52
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission53") {
    return (
      <Transmission53
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission54") {
    return (
      <Transmission54
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission55") {
    return (
      <Transmission55
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission56") {
    return (
      <Transmission56
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission57") {
    return (
      <Transmission57
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission58") {
    return (
      <Transmission58
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission59") {
    return (
      <Transmission59
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission60") {
    return (
      <Transmission60
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission61") {
    return (
      <Transmission61
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission62") {
    return (
      <Transmission62
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission63") {
    return (
      <Transmission63
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission64") {
    return (
      <Transmission64
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission65") {
    return (
      <Transmission65
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission66") {
    return (
      <Transmission66
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission67") {
    return (
      <Transmission67
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission68") {
    return (
      <Transmission68
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission69") {
    return (
      <Transmission69
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission70") {
    return (
      <Transmission70
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission71") {
    return (
      <Transmission71
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission72") {
    return (
      <Transmission72
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission73") {
    return (
      <Transmission73
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission74") {
    return (
      <Transmission74
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission75") {
    return (
      <Transmission75
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission76") {
    return (
      <Transmission76
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission77") {
    return (
      <Transmission77
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission78") {
    return (
      <Transmission78
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission79") {
    return (
      <Transmission79
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission80") {
    return (
      <Transmission80
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission81") {
    return (
      <Transmission81
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission82") {
    return (
      <Transmission82
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission83") {
    return (
      <Transmission83
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission84") {
    return (
      <Transmission84
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission85") {
    return (
      <Transmission85
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission86") {
    return (
      <Transmission86
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission87") {
    return (
      <Transmission87
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission88") {
    return (
      <Transmission88
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission89") {
    return (
      <Transmission89
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission90") {
    return (
      <Transmission90
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission91") {
    return (
      <Transmission91
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission92") {
    return (
      <Transmission92
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission93") {
    return (
      <Transmission93
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission94") {
    return (
      <Transmission94
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission95") {
    return (
      <Transmission95
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission96") {
    return (
      <Transmission96
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission97") {
    return (
      <Transmission97
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission98") {
    return (
      <Transmission98
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission99") {
    return (
      <Transmission99
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "transmission100") {
    return (
      <Transmission100
        history={props.history}
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (props.chosenConfig.url[0] === "feed") {
    return (
      <FeedPostPage
        isModal
        width={props.modalContentWidth}
        height={props.modalContentHeight}
      />
    );
  }
  if (
    props.chosenConfig.url[0].includes("schedules") ||
    props.chosenConfig.url[0].includes("schedules&")
  ) {
    return <SchedulesPage isModal height={props.modalContentHeight} />;
  }
  if (props.chosenConfig.url[0] === "general") {
    return <GeneralPage01 isModal />;
  }
  if (props.chosenConfig.url[0] === "general2") {
    return <GeneralPage02 isModal />;
  }
  if (props.chosenConfig.url[0] === "general3") {
    return <GeneralPage03 isModal />;
  }
  if (props.chosenConfig.url[0] === "general4") {
    return <GeneralPage04 isModal />;
  }
  if (props.chosenConfig.url[0] === "general5") {
    return <GeneralPage05 isModal />;
  }
  if (props.chosenConfig.url[0] === "general6") {
    return <GeneralPage06 isModal />;
  }
  if (props.chosenConfig.url[0] === "general7") {
    return <GeneralPage07 isModal />;
  }
  if (props.chosenConfig.url[0] === "general8") {
    return <GeneralPage08 isModal />;
  }
  if (props.chosenConfig.url[0] === "general9") {
    return <GeneralPage09 isModal />;
  }
  if (props.chosenConfig.url[0] === "general10") {
    return <GeneralPage10 isModal />;
  }
  if (props.chosenConfig.url[0] === "general11") {
    return <GeneralPage11 isModal />;
  }
  if (props.chosenConfig.url[0] === "general12") {
    return <GeneralPage12 isModal />;
  }
  if (props.chosenConfig.url[0] === "general13") {
    return <GeneralPage13 isModal />;
  }
  if (props.chosenConfig.url[0] === "general14") {
    return <GeneralPage14 isModal />;
  }
  if (props.chosenConfig.url[0] === "general15") {
    return <GeneralPage15 isModal />;
  }
  if (props.chosenConfig.url[0] === "general16") {
    return <GeneralPage16 isModal />;
  }
  if (props.chosenConfig.url[0] === "general17") {
    return <GeneralPage17 isModal />;
  }
  if (props.chosenConfig.url[0] === "general18") {
    return <GeneralPage18 isModal />;
  }
  if (props.chosenConfig.url[0] === "general19") {
    return <GeneralPage19 isModal />;
  }
  if (props.chosenConfig.url[0] === "general20") {
    return <GeneralPage20 isModal />;
  }
  if (props.chosenConfig.url[0] === "general21") {
    return <GeneralPage21 isModal />;
  }
  if (props.chosenConfig.url[0] === "general22") {
    return <GeneralPage22 isModal />;
  }
  if (props.chosenConfig.url[0] === "general23") {
    return <GeneralPage23 isModal />;
  }
  if (props.chosenConfig.url[0] === "general24") {
    return <GeneralPage24 isModal />;
  }
  if (props.chosenConfig.url[0] === "general25") {
    return <GeneralPage25 isModal />;
  }
  if (props.chosenConfig.url[0] === "general26") {
    return <GeneralPage26 isModal />;
  }
  if (props.chosenConfig.url[0] === "general27") {
    return <GeneralPage27 isModal />;
  }
  if (props.chosenConfig.url[0] === "general28") {
    return <GeneralPage28 isModal />;
  }
  if (props.chosenConfig.url[0] === "general29") {
    return <GeneralPage29 isModal />;
  }
  if (props.chosenConfig.url[0] === "general30") {
    return <GeneralPage30 isModal />;
  }
  if (props.chosenConfig.url[0] === "general31") {
    return <GeneralPage31 isModal />;
  }
  if (props.chosenConfig.url[0] === "general32") {
    return <GeneralPage32 isModal />;
  }
  if (props.chosenConfig.url[0] === "general33") {
    return <GeneralPage33 isModal />;
  }
  if (props.chosenConfig.url[0] === "general34") {
    return <GeneralPage34 isModal />;
  }
  if (props.chosenConfig.url[0] === "general35") {
    return <GeneralPage35 isModal />;
  }
  if (props.chosenConfig.url[0] === "general36") {
    return <GeneralPage36 isModal />;
  }
  if (props.chosenConfig.url[0] === "general37") {
    return <GeneralPage37 isModal />;
  }
  if (props.chosenConfig.url[0] === "general38") {
    return <GeneralPage38 isModal />;
  }
  if (props.chosenConfig.url[0] === "general39") {
    return <GeneralPage39 isModal />;
  }
  if (props.chosenConfig.url[0] === "general40") {
    return <GeneralPage40 isModal />;
  }
  if (props.chosenConfig.url[0] === "general41") {
    return <GeneralPage41 isModal />;
  }
  if (props.chosenConfig.url[0] === "general42") {
    return <GeneralPage42 isModal />;
  }
  if (props.chosenConfig.url[0] === "general43") {
    return <GeneralPage43 isModal />;
  }
  if (props.chosenConfig.url[0] === "general44") {
    return <GeneralPage44 isModal />;
  }
  if (props.chosenConfig.url[0] === "general45") {
    return <GeneralPage45 isModal />;
  }
  if (props.chosenConfig.url[0] === "general46") {
    return <GeneralPage46 isModal />;
  }
  if (props.chosenConfig.url[0] === "general47") {
    return <GeneralPage47 isModal />;
  }
  if (props.chosenConfig.url[0] === "general48") {
    return <GeneralPage48 isModal />;
  }
  if (props.chosenConfig.url[0] === "general49") {
    return <GeneralPage49 isModal />;
  }
  if (props.chosenConfig.url[0] === "general50") {
    return <GeneralPage50 isModal />;
  }
  if (props.chosenConfig.url[0] === "general51") {
    return <GeneralPage51 isModal />;
  }
  if (props.chosenConfig.url[0] === "general52") {
    return <GeneralPage52 isModal />;
  }
  if (props.chosenConfig.url[0] === "general53") {
    return <GeneralPage53 isModal />;
  }
  if (props.chosenConfig.url[0] === "general54") {
    return <GeneralPage54 isModal />;
  }
  if (props.chosenConfig.url[0] === "general55") {
    return <GeneralPage55 isModal />;
  }
  if (props.chosenConfig.url[0] === "general56") {
    return <GeneralPage56 isModal />;
  }
  if (props.chosenConfig.url[0] === "general57") {
    return <GeneralPage57 isModal />;
  }
  if (props.chosenConfig.url[0] === "general58") {
    return <GeneralPage58 isModal />;
  }
  if (props.chosenConfig.url[0] === "general59") {
    return <GeneralPage59 isModal />;
  }
  if (props.chosenConfig.url[0] === "general60") {
    return <GeneralPage60 isModal />;
  }
  if (props.chosenConfig.url[0] === "general61") {
    return <GeneralPage61 isModal />;
  }
  if (props.chosenConfig.url[0] === "general62") {
    return <GeneralPage62 isModal />;
  }
  if (props.chosenConfig.url[0] === "general63") {
    return <GeneralPage63 isModal />;
  }
  if (props.chosenConfig.url[0] === "general64") {
    return <GeneralPage64 isModal />;
  }
  if (props.chosenConfig.url[0] === "general65") {
    return <GeneralPage65 isModal />;
  }
  if (props.chosenConfig.url[0] === "general66") {
    return <GeneralPage66 isModal />;
  }
  if (props.chosenConfig.url[0] === "general67") {
    return <GeneralPage67 isModal />;
  }
  if (props.chosenConfig.url[0] === "general68") {
    return <GeneralPage68 isModal />;
  }
  if (props.chosenConfig.url[0] === "general69") {
    return <GeneralPage69 isModal />;
  }
  if (props.chosenConfig.url[0] === "general70") {
    return <GeneralPage70 isModal />;
  }
  if (props.chosenConfig.url[0] === "general71") {
    return <GeneralPage71 isModal />;
  }
  if (props.chosenConfig.url[0] === "general72") {
    return <GeneralPage72 isModal />;
  }
  if (props.chosenConfig.url[0] === "general73") {
    return <GeneralPage73 isModal />;
  }
  if (props.chosenConfig.url[0] === "general74") {
    return <GeneralPage74 isModal />;
  }
  if (props.chosenConfig.url[0] === "general75") {
    return <GeneralPage75 isModal />;
  }
  if (props.chosenConfig.url[0] === "general76") {
    return <GeneralPage76 isModal />;
  }
  if (props.chosenConfig.url[0] === "general77") {
    return <GeneralPage77 isModal />;
  }
  if (props.chosenConfig.url[0] === "general78") {
    return <GeneralPage78 isModal />;
  }
  if (props.chosenConfig.url[0] === "general79") {
    return <GeneralPage79 isModal />;
  }
  if (props.chosenConfig.url[0] === "general80") {
    return <GeneralPage80 isModal />;
  }
  if (props.chosenConfig.url[0] === "general81") {
    return <GeneralPage81 isModal />;
  }
  if (props.chosenConfig.url[0] === "general82") {
    return <GeneralPage82 isModal />;
  }
  if (props.chosenConfig.url[0] === "general83") {
    return <GeneralPage83 isModal />;
  }
  if (props.chosenConfig.url[0] === "general84") {
    return <GeneralPage84 isModal />;
  }
  if (props.chosenConfig.url[0] === "general85") {
    return <GeneralPage85 isModal />;
  }
  if (props.chosenConfig.url[0] === "general86") {
    return <GeneralPage86 isModal />;
  }
  if (props.chosenConfig.url[0] === "general87") {
    return <GeneralPage87 isModal />;
  }
  if (props.chosenConfig.url[0] === "general88") {
    return <GeneralPage88 isModal />;
  }
  if (props.chosenConfig.url[0] === "general89") {
    return <GeneralPage89 isModal />;
  }
  if (props.chosenConfig.url[0] === "general90") {
    return <GeneralPage90 isModal />;
  }
  if (props.chosenConfig.url[0] === "general91") {
    return <GeneralPage91 isModal />;
  }
  if (props.chosenConfig.url[0] === "general92") {
    return <GeneralPage92 isModal />;
  }
  if (props.chosenConfig.url[0] === "general93") {
    return <GeneralPage93 isModal />;
  }
  if (props.chosenConfig.url[0] === "general94") {
    return <GeneralPage94 isModal />;
  }
  if (props.chosenConfig.url[0] === "general95") {
    return <GeneralPage95 isModal />;
  }
  if (props.chosenConfig.url[0] === "general96") {
    return <GeneralPage96 isModal />;
  }
  if (props.chosenConfig.url[0] === "general97") {
    return <GeneralPage97 isModal />;
  }
  if (props.chosenConfig.url[0] === "general98") {
    return <GeneralPage98 isModal />;
  }
  if (props.chosenConfig.url[0] === "general99") {
    return <GeneralPage99 isModal />;
  }
  if (props.chosenConfig.url[0] === "general100") {
    return <GeneralPage100 isModal />;
  }
  if (props.chosenConfig.url[0] === "sponsors") {
    return <SponsorsPage isModal />;
  }
};

export default ElementTreatmentModalRouter;
