import React from 'react';

const Link = props => {
    const {
        children,
        onClick,
        textDecoration = 'underline',
        color = "auto"
    } = props;

    const styles = {
        link: {
            cursor: 'pointer',
            textDecoration: textDecoration,
            textDecorationColor: color,
            color: color
        },

    };

    return (
        <div
            style={styles.link}
            onClick={onClick}
        >
            {children}
        </div>
    )
};

export default Link;
