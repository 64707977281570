import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { createQuestionModel, convertQuestionObjectToForm } from '../models/questionModel';
import { createExampleLine, createTableBase, createTableOptions, getBorderObject } from './ImporterUtils';
import { getTypeQuestionList } from '../services/questionsService';
import moment from 'moment';
import ReactExport from "react-data-export";
// https://www.npmjs.com/package/react-data-export
// Biblioteca para ler excel npm i read-excel-file

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelCell = ReactExport.ExcelFile.ExcelCell;
const border = getBorderObject();

const QuestionImporter = (props) => {
  const {
    exampleFile = false,
    data,
    button,
  } = props;
  //O component funciona tanto para exportar a planilha de exemplo, como para devolver a planilha preenchida com os erros
  //Data deve ser um array de objetos do tipo createFormControlModel, conforme conversão de objeto convertQuestionObjectToForm, ou uma lista de questions
  //Caso seja uma planilha de exemplo, devolver os values com os exemplos a serem seguidos

  const questionFormList = convertQuestionObjectToForm(createQuestionModel(), true);
  const schedulesList = props.schedules.map(s => { return { text: `${s.title} - ${s.startDate}`, value: s.id } });
  const configList = props.config.map(c => { return { text: `${c.codeScreen} - ${c.tooltip} - ${c.description}`, value: c.id } });

  function createTableInstructions() {
    var _multiDataSet = [
      {
        columns: [
          {
            title: getDic("coluna"),
            style: {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "9e9e9e" }
              },
              border: border
            }
          },
          {
            title: getDic("valor"),
            style: {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "9e9e9e" }
              },
              border: border
            }
          }
        ],
        data: []
      },
    ];

    const _rows = [];
    questionFormList.forEach(item => {
      const _row = [];
      const _obj1 = {
        value: `${item.name.toUpperCase()}${item.required ? " *" : ""}`,
        style: {
          border: border
        }
      }
      _row.push(_obj1);

      var _instruction = "";
      switch (item.dbReference) {
        case "id":
          _instruction = "Campo numérico, para cadastrar um registro novo, deixar em branco, caso esteja preenchido, o registro existente será atualizado";
          break;
        case "typeQuestion":
          _instruction = "Campo Obrigatório: Um único ID conforme a aba " + `${getDic("tipo")} ${getDic("pergunta")}`;
          break;
        case "category":
          _instruction = "Campo Obrigatório: Texto comum";
          break;
        case "question":
          _instruction = "Campo Obrigatório: Texto comum";
          break;
        case "configsId":
          _instruction = `Campo opcional, sendo um ou mais IDs, separados por vígula "," conforme a tabela ${getDic("configuracao")}`;
          break;
        case "schedulesId":
          _instruction = `Campo opcional, sendo um ou mais IDs, separados por vígula "," conforme a tabela ${getDic("programacao")}`;
          break;
        case "alternative1":
          _instruction = `Texto comum, sendo cada campo uma alternativa de resposta (Atençao ao preencher valores como "0" ou "FALSO" pois o excel atribui um tipo de valor diferente de texto, utilize o seguinte modelo '0 ou 'FALSO para evitar erros)`;
        default:
          break;
      }

      const _obj2 = {
        value: _instruction,
        style: {
          border: border
        }
      }
      _row.push(_obj2);
      _rows.push(_row);
    });

    _multiDataSet[0].data = _rows;

    return _multiDataSet;
  }

  function createDataList() {
    const _rows = [];
    data.forEach(item => {
      const _row = [];
      var _formObject;
      if (item[0] && item[0].dbReference) {
        _formObject = item;
      }
      else {
        _formObject = convertQuestionObjectToForm(item, true);
      }
      _formObject.forEach(obj => {
        var _value = obj.value ? obj.value.toString() : "";
        switch (obj.dbReference) {
          case "startDate":
            if (!obj.error) {
              _value = obj.value ? moment(obj.value).format("DD/MM/YYYY") : "";
            }
            else {
              _value = obj.value;
            }
            break;
          default:
            break;
        }

        const _obj = {
          value: _value + (obj.error ? ` => ${getDic("erro")}: ${obj.errorMessage}` : ""),
          style: {
            border: border,
            fill: {
              fgColor: { rgb: obj.error ? "ff0000" : '00FFFFFF' }
            },
          }
        };
        _row.push(_obj);
      });
      _rows.push(_row);
    });
    return _rows;
  }

  if (data && data.length > 0 || exampleFile) {
    const _instructions = createTableInstructions();
    const _typeQuestions = createTableOptions(getTypeQuestionList(), `${getDic("tipo")} ${getDic("pergunta")}`);;
    const _schedulesList = createTableOptions(schedulesList, getDic("programacao"));
    const _configList = createTableOptions(configList, getDic("configuracoes"));
    var _multiDataSet = createTableBase(`${getDic("importador")} ${getDic("perguntas")}`, questionFormList);
    var _rows = [];

    if (exampleFile) {
      _rows.push(createExampleLine(questionFormList));
    }
    else if (data.length > 0) {
      _rows = createDataList();
    }

    _multiDataSet[3].data = _rows;

    return (
      <ExcelFile filename={`${getDic("perguntas")} - ID ${props.event.id} - ${moment().format("DD-MM-YYYY HH-MM")}`} element={button}>
        <ExcelSheet dataSet={_multiDataSet} name={getDic("importador")} />
        <ExcelSheet dataSet={_typeQuestions} name={`${getDic("tipo")} ${getDic("pergunta")}`} />
        <ExcelSheet dataSet={_schedulesList} name={getDic("programacao")} />
        <ExcelSheet dataSet={_configList} name={getDic("configuracoes")} />
        <ExcelSheet dataSet={_instructions} name={getDic("instrucoes")} />
      </ExcelFile>
    );
  }
  return (
    <ExcelFile element={button} />
  );
}

function mapStateToProps(state) {
  return {
    event: state.event,
    schedules: state.schedules,
    config: state.config
  }
}

export default connect(mapStateToProps, null)(QuestionImporter);

