import React from "react";
import { store } from '../store';
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DateTimePicker, DatePicker, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const InputFieldOutlined = props => {
  const {
    key,
    value,
    title,
    placeholder = "",
    onChange,
    onKeyPress,
    onFocus,
    error = false,
    errorMessage = "",
    required = false,
    maxLength = -1,
    mask = "",
    backgroundColor = "white",
    disabled = false,
    onBlur,
    autoFocus = false,
    inputRef,
    startAdornment,
    endAdornment,
    type = "text",
    multiline = false,
    onClick,
  } = props;

  const [color, setColor] = React.useState("#3f51b5");
  const [borderRadius, setBorderRadius] = React.useState("5px");
  const [useTitleAsPlaceholder, setUseTitleAsPlaceholder] = React.useState(false);
  const [textControl, setTextControl] = React.useState({
    label: !useTitleAsPlaceholder ? title : '',
    placeholder: useTitleAsPlaceholder ? title : placeholder,
  });

  React.useEffect(() => {
    getInputConfig();
    getTextControl();
  }, []);

  React.useEffect(() => {
    getTextControl();
  }, [useTitleAsPlaceholder, title, placeholder, required]);

  const getTextControl = () => {
    let _textControl = {
      label: !useTitleAsPlaceholder ? title : '',
      placeholder: useTitleAsPlaceholder ? title : placeholder,
    }
    if (required) {
      _textControl.label = _textControl.label && required ? `${_textControl.label} *` : _textControl.label;
      _textControl.placeholder = _textControl.placeholder && required ? `${_textControl.placeholder} *` : _textControl.placeholder;
    }
    setTextControl(_textControl);
  }

  const getInputConfig = () => {
    var inputConfig = store.getState().config.find(item => {
      return item.codeScreen === "INPUT-CONFIG"
    });
    if (inputConfig) {
      inputConfig.url.forEach(item => {
        const _prop = item.split(":");
        switch (_prop[0]) {
          case "borderRounded":
            setBorderRadius(_prop[1] === "true" ? "90px" : "5px");
            break;
          case "useTitleAsPlaceholder":
            setUseTitleAsPlaceholder(_prop[1] === "true" ? true : false);
            break;
          case "color":
            setColor(_prop[1]);
            break;
          default:
            break;
        }
      });
    }
  }

  const onChangeDate = (date) => {
    var _date = {
      target: {
        value: moment(date).format()
      }
    };
    props.onChange(_date);
  }

  const styles = {
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    width: "100%",
  };

  if (mask && mask != "" && !disabled) {
    return (
      <InputMask
        mask={mask}
        value={value}
        onChange={onChange}
        maskChar=""
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {() => (
          <TextField
            style={styles}
            InputProps={{
              maxLength: maxLength,
              startAdornment: startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : null,
              endAdornment: endAdornment ? (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ) : null,
              style: {
                borderRadius: borderRadius
              }
            }}
            error={error}
            helperText={errorMessage}
            key={key}
            label={textControl.label}
            placeholder={textControl.placeholder}
            variant="outlined"
            fullWidth
            onKeyPress={onKeyPress}
            autoFocus={autoFocus}
            inputRef={inputRef}
            type={type}
            multiline={multiline}
            onClick={onClick}
          />
        )}
      </InputMask>
    );
    // } else if (type === "datetime-local") {
    //   return (
    //     <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //       <TextField
    //         InputProps={{
    //           maxLength: maxLength,
    //           startAdornment: startAdornment ? (
    //             <InputAdornment position="start">{startAdornment}</InputAdornment>
    //           ) : null,
    //           endAdornment: endAdornment ? (
    //             <InputAdornment position="end">{endAdornment}</InputAdornment>
    //           ) : null,
    //           style: {
    //             borderRadius: borderRadius
    //           }
    //         }}
    //         style={styles}
    //         error={error}
    //         helperText={errorMessage}
    //         key={key}
    //         label={textControl.label}
    //         placeholder={textControl.placeholder}
    //         variant="outlined"
    //         fullWidth
    //         value={value}
    //         onChange={onChange}
    //         onKeyPress={onKeyPress}
    //         disabled={disabled}
    //         onBlur={onBlur}
    //         onFocus={onFocus}
    //         autoFocus={autoFocus}
    //         inputRef={inputRef}
    //         type='datetime-local'
    //         multiline={multiline}
    //         onClick={onClick}
    //       />
    //       {/* <DateTimePicker
    //         style={styles}
    //         inputVariant="outlined"
    //         value={value}
    //         onChange={e => {
    //           onChangeDate(e);
    //         }}
    //         error={error}
    //         helperText={errorMessage}
    //         key={key}
    //         label={textControl.label}
    //         placeholder={textControl.placeholder}
    //         onKeyPress={onKeyPress}
    //         disabled={disabled}
    //         onBlur={onBlur}
    //         onFocus={onFocus}
    //         autoFocus={autoFocus}
    //         inputRef={inputRef}
    //         format="dd/MM/yyyy - HH:mm"
    //         InputProps={{
    //           style: {
    //             borderRadius: borderRadius
    //           }
    //         }}
    //         onClick={onClick}
    //       /> */}
    //     </MuiPickersUtilsProvider>
    //   );
    // } else if (type === "date") {
    //   return (
    //     <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //       <DatePicker
    //         style={styles}
    //         inputVariant="outlined"
    //         value={value}
    //         onChange={e => {
    //           onChangeDate(e);
    //         }}
    //         error={error}
    //         helperText={errorMessage}
    //         key={key}
    //         label={textControl.label}
    //         placeholder={textControl.placeholder}
    //         onKeyPress={onKeyPress}
    //         disabled={disabled}
    //         onBlur={onBlur}
    //         onFocus={onFocus}
    //         autoFocus={autoFocus}
    //         inputRef={inputRef}
    //         format="dd/MM/yyyy"
    //         InputProps={{
    //           style: {
    //             borderRadius: borderRadius
    //           }
    //         }}
    //         onClick={onClick}
    //       />
    //     </MuiPickersUtilsProvider>
    //   );
    // } else if (type === "time") {
    //   return (
    //     <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //       <TimePicker
    //         style={styles}
    //         inputVariant="outlined"
    //         value={value}
    //         onChange={e => {
    //           onChangeDate(e);
    //         }}
    //         error={error}
    //         helperText={errorMessage}
    //         key={key}
    //         label={textControl.label}
    //         placeholder={textControl.placeholder}
    //         onKeyPress={onKeyPress}
    //         disabled={disabled}
    //         onBlur={onBlur}
    //         onFocus={onFocus}
    //         autoFocus={autoFocus}
    //         inputRef={inputRef}
    //         format="hh:mm"
    //         InputProps={{
    //           style: {
    //             borderRadius: borderRadius
    //           }
    //         }}
    //         onClick={onClick}
    //       />
    //     </MuiPickersUtilsProvider>
    //   );
  } else {
    return (
      <TextField
        InputProps={{
          maxLength: maxLength,
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null,
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : null,
          style: {
            borderRadius: borderRadius
          }
        }}
        style={styles}
        error={error}
        helperText={errorMessage}
        key={key}
        label={textControl.label}
        placeholder={textControl.placeholder}
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
        inputRef={inputRef}
        type={type}
        multiline={multiline}
        onClick={onClick}
      />
    );
  }
};

export default InputFieldOutlined;
