import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 585,
    marginBottom: 10
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  textContent: {
    padding: 10
  },
  headerFix: {
    padding: 7
  }
}));

const LoadFeedCard = props => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  return <Card className={classes.root}>
    <Typography
      variant="body2"
      color="textSecondary"
      component="p"
      style={{ color: 'white', height: 0, overflow: "hidden" }}
    >
      This impressive paella is a perfect party dish and a fun meal to cook together with your
      guests. Add 1 cup of frozen peas along with the mussels, if you like.
            </Typography>
    <div className="preload_card">
      <div id="u_0_v">
        <div class="lightui1">
          <div class="lightui1-shimmer">
            <div class="_2iwr"></div>
            <div class="_2iws"></div>
            <div class="_2iwt"></div>
            <div class="_2iwu"></div>
            <div class="_2iwv"></div>
            <div class="_2iww"></div>
            <div class="_2iwx"></div>
            <div class="_2iwy"></div>
            <div class="_2iwz"></div>
            <div class="_2iw-"></div>
            <div class="_2iw_"></div>
            <div class="_2ix0"></div>
          </div>
        </div>
      </div>
    </div>
    <Typography variant="body2" component="p" className={classes.textContent}>
    </Typography>

    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Typography paragraph>Simple Text:</Typography>
        <Typography paragraph>
          Simple TextSimple TextSimple TextSimple TextSimple TextSimple TextSimple TextSimple
          TextSimple TextSimple TextSimple TextSimple Text
                </Typography>
      </CardContent>
    </Collapse>
  </Card>
}


export default LoadFeedCard;
