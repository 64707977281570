export function getLocalPageName() {
    var _returnPath = 'login';

    try {
        var _pathArray = window.location.pathname.split('/');

        if (_pathArray[2]) {
            if (_pathArray[2].includes('?')) {
                _returnPath = _pathArray[2].split('?')[0];
            }
            else if (_pathArray[2].includes('&')) {
                _returnPath = _pathArray[2].split('&')[0];
            }
            else {
                _returnPath = _pathArray[2];
            }
        }
    } catch (error) {
        console.log("Erro getLocalPageName", error);
    }

    return _returnPath;
}
