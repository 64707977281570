import React from "react"
import { store } from '../../store';
import { connect } from "react-redux";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import 'firebase/firestore';
import 'firebase/auth';
import { firestore, firebase } from "./../../utils/firebase";
import {
    PushNotificationV2,
    TransmissionChangeSchedule
} from '../';

const PushV2 = ({ event, user }) => {
    if (user && user.id > 0)
        return <RenderPushV2 event={event} user={user} />
    else
        return null
}

const RenderPushV2 = ({ event, user }) => {
    const [currentPush, setCurrentPush] = React.useState();
    // const openedPush = React.useRef(false);
    const pendingPushList = React.useRef([]);
    const pushRef = firestore.collection(`push/${event.id}/history`).orderBy("date", "desc");
    const pushReadRef = firestore.collection(`push/${event.id}/history_read`);

    const [pushData] = useCollectionData(pushRef, { idField: 'id' });

    const setRead = async (push) => {
        // openedPush.current = false;
        try {
            let _user = store.getState().user;
            await pushReadRef.doc(`${user && user.id ? user.id : _user.id}`).set({
                ids: firebase.firestore.FieldValue.arrayUnion(push.id),
            }, { merge: true });
        } catch (error) {
            console.log('Erro ao salvar push read', error);
        }
        executePush();
    }

    const reopenQueue = () => {
        // openedPush.current = false;
    }

    const executePush = async () => {
        // if (openedPush.current) { return };
        try {
            var _query = await pushReadRef.doc(`${user.id}`).get();
            var _idsRead = _query && _query.data() && _query.data().ids ? _query.data().ids : [];

            for (let index = 0; index < pendingPushList.current.length; index++) {
                const _push = pendingPushList.current[index];

                if (!_idsRead.includes(_push.id)) {
                    if (_push.active) {
                        setCurrentPush(_push);
                        // openedPush.current = true;
                        index = pendingPushList.current.length;
                    } else {
                        setRead(_push);
                    }
                }
            }

        } catch (error) {
            console.log('Erro ao executar push', error);
            // openedPush.current = false;
            executePush();
        }
    }

    React.useEffect(() => {
        if (window.self === window.top && user && user.id > 0 && pushData && pushData.length > 0) {
            pendingPushList.current = pushData;
            executePush();
        }
    }, [pushData]);

    return (
        <React.Fragment>
            <PushNotificationV2 notification={currentPush} pushExecuted={setRead} reopenQueue={reopenQueue} />
            <TransmissionChangeSchedule />
        </React.Fragment>
    )

}

function mapStateToProps(state) {
    return {
        event: state.event,
        user: state.user,
    };
}

export default connect(mapStateToProps, null)(PushV2);
