import { SET_CHOSEN_CONFIG, RESET_CHOSEN_CONFIG } from '../actions';

export default function chosenConfigReducer(state = null, action) {
    switch (action.type) {
        case SET_CHOSEN_CONFIG:
            return action.config;
        case RESET_CHOSEN_CONFIG:
            return null;
        default:
            return state;
    }
}
