import React from 'react';
import { getDic } from "../../assets/i18n/dictionary";
import {
  FormRow,
  Button,
  Modal,
  Container,
  FormColumn,
  GridRow,
  GridCell,
  TextMedium
} from "../../components";
import DeleteIcon from '@material-ui/icons/DeleteForever';

const ModalBlockList = props => {
  const {
    excelImporterControl,
    setExcelImporterControl,
    modalBlocklistControl,
    setModalBlocklistControl,
    setModalAddBlocklistControl,
    modalAddBlocklistControl,
    setDialogConfirmActionControl,
    dialogConfirmActionControl,
  } = props;

  const exportEventTable = () => {
    var _btn = document.getElementById("btnExportTable");
    if (_btn) {
      setTimeout(() => {
        _btn.click();
      }, 500);
    }
    setTimeout(() => {
      setExcelImporterControl({ ...excelImporterControl, exportTable: false });
    }, 1000);
  }

  return (
    <Modal
      isVideo
      height="100%"
      open={modalBlocklistControl.open}
      closeButtonClick={(e) => setModalBlocklistControl({ ...modalBlocklistControl, open: false })}
      onClose={(ret) => setModalBlocklistControl({ ...modalBlocklistControl, open: false })}
    >
      <Container background="#e8eced" align="left" height="100%">
        <FormColumn margin="0px" padding="5px">
          <FormRow
            paddingTop="50px"
            minHeight="50px"
            align="center"
            margin="0px"
          >
            <div style={{ padding: "5px", maxWidth: "400px" }}>
              <Button
                color="green"
                onClick={() => {
                  setModalAddBlocklistControl({
                    ...modalAddBlocklistControl,
                    open: true,
                    guestsList: [],
                  });
                }}
              >
                {`${getDic("cadastrar")} ${getDic("novo")}`}
              </Button>
            </div>
            {/* <div style={{ padding: "5px", maxWidth: "400px" }}>
              {excelImporterControl.exportTable && (
                <div hidden>
                  <RaffleBlockListExcel
                    exampleFile
                    onDownload={() => exportEventTable()}
                    button={<button id="btnExportTable">X</button>}
                  />
                </div>
              )}
              <Button
                color="blue"
                textColor="white"
                onClick={() => setExcelImporterControl({ ...excelImporterControl, exportTable: true })}
              >
                {getDic("exportar")} {getDic("planilha")}
              </Button>
            </div> */}
          </FormRow>
          {renderBlockList()}
        </FormColumn>
      </Container>
    </Modal>
  )

  function renderBlockList() {
    return (
      <div
        style={{
          height: "60vh",
          backgroundColor: "transparent",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "5px",
            backgroundColor: "transparent",
            height: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <GridRow backgroundColor="#d9d9d9">
            <GridCell width="10%" border top first>
              <TextMedium>ID</TextMedium>
            </GridCell>
            <GridCell width="35%" border top>
              <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="45%" border top>
              <TextMedium>{getDic("email").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="10%" border top>
              <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
            </GridCell>
          </GridRow>

          {modalBlocklistControl.blockList.length > 0 ? (
            modalBlocklistControl.blockList.map((guest, i) => {
              return (
                <GridRow
                  key={guest.id}
                  autoColor={i}
                >
                  <GridCell width="10%" border first>
                    <TextMedium>{guest.id}</TextMedium>
                  </GridCell>
                  <GridCell width="35%" border>
                    <TextMedium>{guest.name}</TextMedium>
                  </GridCell>
                  <GridCell width="45%" border>
                    <TextMedium>{guest.email}</TextMedium>
                  </GridCell>
                  <GridCell width="10%" border>
                    <DeleteIcon
                      onClick={() => setDialogConfirmActionControl({
                        ...dialogConfirmActionControl,
                        confirmKey: 'Blocklist',
                        confirmText: '',
                        guest: guest,
                        open: true
                      })}
                      style={{ cursor: "pointer" }}
                    />
                  </GridCell>
                </GridRow>
              );
            })
          ) : (
            <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
          )}
        </div>
      </div>
    )
  }
};

export default ModalBlockList;
