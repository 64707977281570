import { USER_LOGIN_SUCCESS, USER_LOGOUT, USER_UPDATE } from '../actions';
import * as userModel from '../../models/userModel';

const INITIAL_STATE = userModel.createUserModel();

export default function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER_LOGIN_SUCCESS:
            return { ...state, ...action.user };
        case USER_UPDATE:
            return { ...state, firstTerm: action.firstTerm, secondTerm: action.secondTerm }
        case USER_LOGOUT:
            return null;
        default:
            return state;
    }
}
