import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelCell = ReactExport.ExcelFile.ExcelCell;

// https://www.npmjs.com/package/react-data-export

const SurveyReport = (props) => {
    // data from createSurveyModel

    const {
        data = [],
        button,
    } = props;

    if (data && data.length > 0) {

        var multiDataSet = [
            {
                columns: [{ title: getDic("pergunta") + ": " + data[0].question }],
                data: [],
            },
            {
                ySteps: 1, // Pular uma linha
                columns: [
                    { title: getDic("resposta") },
                    { title: "Qtd" },
                ],
                data: []
            }
        ];

        const rows = [];
        data.forEach(item => {
            const row = [];

            const cell1 = {
                value: item.answer,
            }
            row.push(cell1);
            const cell2 = {
                value: item.quantity,
            }
            row.push(cell2);

            rows.push(row);
        });

        multiDataSet[1].data = rows;

        return (
            <ExcelFile element={button}>
                <ExcelSheet dataSet={multiDataSet} name={getDic("relatorio")} />
            </ExcelFile>
        );
    }

    return (
        <ExcelFile element={button} />
    );
}

export default SurveyReport;

