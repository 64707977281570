import React from 'react';
import Fab from '@material-ui/core/Fab';
import { store } from '../store';
import * as navigationHistoryService from '../services/navigationHistoryService';

const BackButtonProp = props => {
    const {
        history,
        children,
        color = "#3f51b5",
        size = null,
        backTo = null,
        textColor = 'white',
    } = props;

    const styles = {
        button: {
            zIndex: '11',
            display: 'flex',
            position: 'fixed',
            top: '5px',
            right: '5px',
            backgroundColor: color,
            color: textColor,
        }
    };

    const [showBackButton, setShowBackButton] = React.useState(true);

    React.useEffect(() => {
        let _noBackButton = store.getState().config.find(c => { return c.codeScreen === 'NO-BACK-BUTTON' });
        if (_noBackButton) {
            setShowBackButton(false);
        }
    }, []);

    const historyLenght = React.useRef(window.location.href);

    const checkIfIsAtSamePath = () => {
        setTimeout(() => {
            if (historyLenght.current === window.location.href) {
                history.goBack();
                checkIfIsAtSamePath();
            }
        }, 100);
    }

    if (!showBackButton) { return null }
    if (window.self !== window.top) return <div />
    else return (
        <Fab
            id="back_btn"
            class="MuiButtonBase-root MuiFab-root MuiFab-sizeMedium MuiFab-secondary MuiFab-extended"
            style={styles.button}
            variant="contained"
            color="secondary"
            size={size ? size : "medium"}
            onClick={() => {
                if (backTo) {
                    navigationHistoryService.setHistory(history, backTo);
                }
                else {
                    navigationHistoryService.goBackHistory(history);
                }

                // checkIfIsAtSamePath();
            }}
        >
            {children}
        </Fab>
    )
};

export default BackButtonProp;
