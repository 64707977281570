import React from 'react';
import { store } from '../store';
import { connect } from 'react-redux';
import { chosenStand } from '../store/actions';
import { getDic } from '../assets/i18n/dictionary';
import { setMobileBackgroundImage } from '../services/themeService';
import '../scss/MobileRender.scss';

//Imports de components
import {
    FormColumn,
    ListItem,
    ElementTreatment,
    TextMedium
} from '../components';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            completeConfigList: this.props.configList,
            internalLinksList: [],
            configList: [],
            executeElement: false,
            chosenConfig: null,
        }
    }

    componentDidMount() {
        //Filtrar a config list para exibir primeiro os links internos
        const internalLinksList = this.props.configList.filter(item => {
            return item.typeId === 6;
        });

        const configList = this.props.configList.filter(item => {
            return item.codeScreen && item.codeScreen.includes("ELEMENT-") && item.typeId !== 6;
        });

        const background = this.props.configList.find(item => {
            return item.codeScreen && item.codeScreen === "IMAGE-001";
        });

        if (background) {
            setMobileBackgroundImage(background.url[0]);
        }
        else {
            setMobileBackgroundImage(this.props.defaultImage);
        }

        this.setState({ internalLinksList: internalLinksList, configList: configList });
    }

    async selectElement(element) {
        //Busca a configuração e executa instrução
        const config = this.state.completeConfigList.find(item => {
            return item.codeScreen === element;
        });
        if (config) {
            if (config.standId && config.standId !== 0) {
                const stand = store.getState().stands.find(stand => {
                    return stand.id === config.standId
                });

                if (stand) {
                    this.props.chosenStand(stand)
                        .then(() => {
                            this.setState({ executeElement: true, chosenConfig: config });
                        });
                }
                else {
                    this.setState({ executeElement: true, chosenConfig: config });
                }
            }
            else {
                this.setState({ executeElement: true, chosenConfig: config });
            }
        }
    }

    executeElement() {
        if (this.state.executeElement) {
            return (
                <ElementTreatment
                    history={this.props.history}
                    chosenConfig={this.state.chosenConfig}
                    onClose={() => {
                        this.setState({ executeElement: false, chosenConfig: null });
                    }}
                />
            )
        }
    }

    render() {
        return (
            <div className="screen-bg">
                <FormColumn background="transparent" align="center" margin="0px" padding="0px">
                    {this.state.internalLinksList.length > 0 ? (
                        <FormColumn align="center" margin="0px" padding="10px">
                            <div style={{ background: "white", opacity: "0.8", padding: "5px" }}>
                                <TextMedium fontWeight="bold">
                                    {getDic("navegar").toUpperCase()}
                                </TextMedium>
                            </div>
                            {this.state.internalLinksList.map((item) => {
                                return (
                                    <div style={{ background: "white", opacity: "0.8" }}>
                                        <ListItem text={item.tooltip} onClick={() => this.selectElement(item.codeScreen)} />
                                    </div>
                                );
                            })}
                        </FormColumn>
                    ) : (null)}
                    {this.state.configList.length > 0 ? (
                        <FormColumn align="center" margin="0px" padding="10px">
                            <div style={{ background: "white", opacity: "0.8", padding: "5px" }}>
                                <TextMedium fontWeight="bold">
                                    {getDic("conteudos").toUpperCase()}
                                </TextMedium>
                            </div>
                            {this.state.configList.map((item) => {
                                return (
                                    <div style={{ background: "white", opacity: "0.8" }}>
                                        <ListItem text={item.tooltip} onClick={() => this.selectElement(item.codeScreen)} />
                                    </div>
                                );
                            })}
                        </FormColumn>
                    ) : (null)}
                </FormColumn>
                {this.executeElement()}
            </div>
        );
    };
}

const mapDispatchToProps = {
    chosenStand
}

export default connect(null, mapDispatchToProps)(Component);
