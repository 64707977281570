import React from "react";
import { store } from "../../store";
import MenuButton from "./MenuButton";
import MenuButtonV2 from "./MenuButtonV2";

const MenuButtonProp = (props) => {
  const [defaultProp, setDefaultProp] = React.useState(true);

  React.useEffect(() => {
    getPropData();
  }, []);

  const getPropData = () => {
    const _drawerConfig = store.getState().config.find((item) => {
      return item.codeScreen === "SIDE-MENU-CONFIG";
    });
    if (_drawerConfig) {
      setDefaultProp(false);
    }
  };

  if (defaultProp) {
    return <MenuButton history={props.history} />;
  }
  return <MenuButtonV2 history={props.history} />;
};

export default MenuButtonProp;
