export function createChatModel(
  id = 0,
  message = "",
  insertDate = "",
  guestId = 0,
  guestName = "",
  photoUrl = "",
  active = true,
  approved = true,
  scheduleId = 0,
  configId = 0,
  messageSaw = false,
) {
  const object = {
    id: id,
    message: message,
    insertDate: insertDate,
    guestId: guestId,
    guestName: guestName,
    photoUrl: photoUrl,
    active: active,
    approved: approved,
    scheduleId: scheduleId,
    configId: configId,
    messageSaw: messageSaw,

    //Atributo apenas para a página de moderação
    selected: false,
  };

  return object;
}
