import axios from "axios";
import { getUrl, getToken } from "./apiService";
import { store } from "../store";
import { createChatModel } from "../models/chatModel";
import {
  createChatPersonalModel,
  createChatPersonalGuestModel,
} from "../models/chatPersonalModel";

export function getChatSize() {
  return 30;
}

export async function getChatMessage(chatId, pageSize, pageIndex) {
  var method =
    getUrl().chat + `/ChatMessage?chatId=${chatId}&pageSize=${pageSize}&pageIndex=${pageIndex}`;
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      }
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          //console.log(res.data)
        }
        resolve({ status: true, data: res.data });
      })
      .catch((err) => {
        console.log("Erro getChatMessage", err);
        reject(err);
      });
  });
}

export async function setRawMessage(data) {
  var method =
    getUrl().chat + "/ChatMessage/saw";
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: data
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          //console.log(res.data)
        }
        resolve({ status: true, data: res.data.data });
      })
      .catch((err) => {
        console.log("Erro getMessagesByChatIdApi", err);
        reject(err);
      });
  });
}

export async function setNewChatMessage(data) {
  var method = getUrl().chat + "/ChatMessage";
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: data
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          //console.log(res.data)
        }
        resolve({ status: true, data: res.data.data });
      })
      .catch((err) => {
        console.log("Erro getMessagesByChatIdApi", err);
        reject(err);
      });
  });
}

export async function getAllChatGuests(guestId) {
  var method =
    getUrl().chat + "/Chat?guestId=" + guestId;
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      }
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          //console.log(res.data)
        }
        resolve({ status: true, data: res.data });
      })
      .catch((err) => {
        console.log("Erro getMessagesByChatIdApi", err);
        reject(err);
      });
  });
}

export async function createNewChatGuest(data) {
  var method =
    getUrl().chat + "/Chat";
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: data
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          //console.log(res.data)
        }
        resolve({ status: true, data: res.data });
      })
      .catch((err) => {
        console.log("Erro getMessagesByChatIdApi", err);
        reject(err);
      });
  });
}


export async function getMessagesByChatIdApi(chatId, pageIndex, search) {
  var method =
    getUrl().chat +
    "event/chat/guest/list/" +
    chatId.toString() +
    "?pageIndex=" +
    pageIndex +
    "&pageSize=" +
    getChatSize();
  const token = await getToken();

  if (search) {
    method = method + "&text=" + search;
  }

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        const messages = [];
        if (res.data) {
          res.data.result.forEach((item) => {
            if (item.activeMessage) {
              var message = createChatModel(
                item.messageId,
                item.message,
                item.dateSentMessage,
                item.guestId,
                item.name, //name
                item.urlPhoto,
                true,
                true,
                null,
                null
              );
              messages.push(message);
            }
          });
        }

        //Ordenar mensagens por data
        const ret = messages.sort(function (a, b) {
          return b.insertDate < a.insertDate;
        });

        resolve({ messages: ret, totalPages: res.data.totalPages });
      })
      .catch((err) => {
        console.log("Erro getMessagesByChatIdApi", err);
        reject(err);
      });
  });
}

export async function getOpenedChatsByUserApi() {
  const token = await getToken();
  const user = store.getState().user;

  var method =
    getUrl().chat +
    "event/chat/guest/hasGuest/message/lastMessages?guestId=" +
    user.id;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        const _chatsList = [];

        if (res.data) {
          res.data.forEach((item) => {
            const _guestsList = [];
            var _chatPhoto = "";
            var _chatGuestName = "";
            var _guestHasChatUser = 0;

            //Monta lista de possoas que participam do chat
            item.participantsOfTheChat.forEach((guest) => {
              const _guest = createChatPersonalGuestModel(
                guest.guestHasChatId,
                guest.guestId,
                guest.name,
                guest.urlPhoto
              );
              _guestsList.push(_guest);

              //Pega a foto da outra pessoa do chat
              if (guest.guestId !== user.id) {
                _chatPhoto = guest.urlPhoto;
                _chatGuestName = guest.name;
                _guestHasChatUser = guest.guestHasChatId;
              }
            });

            const _chat = createChatPersonalModel(
              item.id,
              _chatGuestName,
              _chatPhoto,
              item.message,
              item.dateCreated,
              _guestHasChatUser === item.guestHasChatId
                ? true
                : item.messageSaw, // Caso o id da ultima mensagem seja igual ao id do usuário, mascar como lido
              item.online,
              _guestsList
            );
            _chatsList.push(_chat);
          });
        }

        resolve({ chatsList: _chatsList });
      })
      .catch((err) => {
        console.log("Erro getMessagesByChatIdApi", err);
        reject(err);
      });
  });
}

export async function getLatestMessageByUserApi() {
  const token = await getToken();
  const user = store.getState().user;
  const event = store.getState().event;

  var method = getUrl().chat + `/Chat/new-messages?guestId=${user.id}&eventId=${event.id}`;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        //console.log(res);
        if (res.data) {
          resolve(res.data);
        }
        else {
          resolve(0);
        }
      })
      .catch((err) => {
        console.log("Erro getLatestMessageByUserApi", err);
        reject(err);
      });
  });
}

export async function createNewChat(guest) {
  //Primeiro criase um novo chat, e em seguida adiciona os participantes
  var _createNewChatUrl = getUrl().chat + "event/chat/guest";
  const event = store.getState().event;
  const user = store.getState().user;
  const token = await getToken();

  //personsList é um array de ids
  return new Promise((resolve, reject) => {
    const options = {
      url: _createNewChatUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: {
        eventId: event.id,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.data && res.data.success) {
          const _newChat = res.data.data;

          var _addUserUrl = getUrl().chat + "event/chat/guest/hasGuest";

          //Adiciona o usuário ao chat
          const options2 = {
            url: _addUserUrl,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + token,
            },
            data: {
              guestId: parseInt(user.id),
              chatId: _newChat.id,
            },
          };
          axios(options2)
            .then((res) => {
              if (res.data.data && res.data.success) {
                const _guestsList = [];
                const _guestUser = createChatPersonalGuestModel(
                  res.data.data.id,
                  user.id,
                  user.name,
                  user.image
                );
                _guestsList.push(_guestUser);

                //Adiciona o guest ao chat
                const options3 = {
                  url: _addUserUrl,
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "bearer " + token,
                  },
                  data: {
                    guestId: parseInt(guest.id),
                    chatId: _newChat.id,
                  },
                };
                axios(options3)
                  .then((res) => {
                    if (res.data.data && res.data.success) {
                      const _guestPax = createChatPersonalGuestModel(
                        res.data.data.id,
                        guest.id,
                        guest.name,
                        guest.image
                      );
                      _guestsList.push(_guestPax);

                      const _chat = createChatPersonalModel(
                        _newChat.id,
                        guest.name,
                        guest.image,
                        null,
                        null,
                        true,
                        null,
                        _guestsList
                      );

                      resolve({ success: true, newChat: _chat });
                    } else {
                      reject(res.data);
                    }
                  })
                  .catch((err) => {
                    console.log("Erro createNewChat", err);
                    reject(err);
                  });
              } else {
                reject(res.data);
              }
            })
            .catch((err) => {
              console.log("Erro createNewChat", err);
              reject(err);
            });
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro createNewChat", err);
        reject(err);
      });
  });
}

export async function createNewChatV2(guest) {
  //Primeiro criase um novo chat, e em seguida adiciona os participantes
  var method = getUrl().chat + "event/chat/guest/v2";
  const event = store.getState().event;
  const user = store.getState().user;
  const token = await getToken();

  const _chatMembers = [];
  _chatMembers.push(guest.id);
  _chatMembers.push(user.id);

  //personsList é um array de ids
  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: {
        eventId: event.id,
        isGroup: false,
        chatMembers: _chatMembers,
      },
    };
    console.log(options)
    axios(options)
      .then((res) => {
        if (res.data.data && res.data.success) {
          var _newChatId = 0;
          const _guestsList = [];

          res.data.data.forEach((item) => {
            _newChatId = item.chatId;
            const _guestPax = createChatPersonalGuestModel(
              item.id,
              item.guestId,
              item.guestId === guest.id ? guest.name : user.name,
              item.guestId === guest.id ? guest.image : user.image
            );
            _guestsList.push(_guestPax);
          });

          const _chat = createChatPersonalModel(
            _newChatId,
            guest.name,
            guest.image,
            null,
            null,
            true,
            null,
            _guestsList
          );

          resolve({ success: true, newChat: _chat });
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro createNewChat", err);
        reject(err);
      });
  });
}

export async function addNewChatPersonalMessageApi(message, guestHasChatId) {
  const token = await getToken();

  var method = getUrl().chat + "event/chat/guest/hasGuest/message";
  var data = {
    guestHasChatId: guestHasChatId,
    message: message,
  };

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: data,
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data.success) {
          resolve(true);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro addNewMessageApi", err);
        reject(err);
      });
  });
}

export async function setMessageSawApi(messageId, guestHasChatId) {
  const token = await getToken();

  var method = getUrl().chat + "event/chat/guest/message/saw";
  var data = [
    {
      guestHasChatId: guestHasChatId,
      messageId: messageId,
    },
  ];

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: data,
    };
    axios(options)
      .then((res) => {
        resolve(true);
      })
      .catch((err) => {
        console.log("Erro setMessageSawApi", err);
        reject(err);
      });
  });
}
