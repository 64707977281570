import React from "react";
import { Card, CardBody, CardTitle, Progress } from "reactstrap";

function groupBy(collection, property) {
  var i = 0,
    val,
    index,
    values = [],
    result = [];
  for (; i < collection.length; i++) {
    val = collection[i][property];
    index = values.indexOf(val);
    if (index > -1) result[index].push(collection[i]);
    else {
      values.push(val);
      result.push([collection[i]]);
    }
  }
  return result;
}

const UsersLocations = ({ listData }) => {
  const [allOnlineUsers, setAllOnlineUsers] = React.useState([]);
  const config_id = groupBy(
    listData.filter((c) => c.configId !== ""),
    "configId"
  );
  const schedule = groupBy(
    listData.filter((c) => c.scheduleId !== ""),
    "scheduleId"
  );
  const pages = groupBy(
    listData.filter((e) => e.scheduleId.length < 1 && e.configId.length < 1),
    "page"
  );

  React.useEffect(() => {
    let temp_ar = [...config_id, ...schedule, ...pages];
    setAllOnlineUsers(temp_ar);
    console.log("user location");
  }, []);
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Top Programações</CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-group text-primary display-4" />
            </div>
            <h3>{listData.length}</h3>
            <p>Usuários Online</p>
          </div>

          <div className="table-responsive mt-4">
            <table
              className="table table-responsive w-100 d-block d-md-table"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <tbody>
                {allOnlineUsers
                  .sort((a, b) => {
                    return b.length - a.length;
                  })
                  .map((e, i) => (
                    <tr key={i}>
                      <td style={{ width: "30%" }}>
                        <p className="mb-0">
                          {e[0].configId == "" && e[0].scheduleId == "" && (
                            <span>
                              {e[0].description ? e[0].description : "--"}
                              <br />
                              <sub style={{ bottom: 0 }}> ({e[0].page})</sub>
                            </span>
                          )}
                          {e[0].configId == "" && e[0].scheduleId && (
                            <span>
                              {e[0].description ? e[0].description : "--"}
                              <br />
                              <sub style={{ bottom: 0 }}>
                                {" "}
                                ({e[0].scheduleId})
                              </sub>
                            </span>
                          )}
                          {e[0].configId != "" && e[0].scheduleId == "" && (
                            <span>
                              {e[0].description ? e[0].description : "--"}{" "}
                              <br />
                              <sub style={{ bottom: 0 }}>
                                {" "}
                                ({e[0].configId})
                              </sub>
                            </span>
                          )}
                        </p>
                      </td>
                      <td style={{ width: "25%" }}>
                        <h5 className="mb-0">{e.length}</h5>
                      </td>
                      <td>
                        <Progress
                          value={(e.length / listData.length) * 100}
                          color="primary"
                          className="bg-transparent progress-sm"
                          size="sm"
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default UsersLocations;
