import React from 'react';
import { isMobile } from "react-device-detect";

const ChessGameRender = (props) => {
    const [iframWidth, setIframeWidth] = React.useState("100%");
    const [iframHeight, setIframeHeight] = React.useState("100%");

    React.useEffect(() => {
        resizeIframe();
        var frm = document.getElementById("gameChess");
        frm.submit();
    }, []);

    const resizeIframe = () => {
        //A dimensão perfeita é a largura corresponder a 83% da altura
        var _width = !isMobile ? window.innerWidth * 0.60 : window.innerWidth;
        var _height = !isMobile ? window.innerHeight * 0.80 : window.innerHeight;

        if ((_width * 100) / _height < 83) {
            while ((_width * 100) / _height < 83) {
                _width += 1;
            }
        }
        if ((_width * 100) / _height > 85) {
            while ((_width * 100) / _height > 85) {
                _width -= 1;
            }
        }

        setIframeHeight(_height);
        setIframeWidth(_width);
    }

    return <React.Fragment>
        <form action="https://xadrez.srv.br"
            method="post"
            target="output_frame"
            id="gameChess"
            style={{
                height: 0,
                width: 0,
                overflow: "hidden"
            }}
        >
            <input type="text" name="id" placeholder="ID do PAX, ex: 12345" value={props.userID} /> <br />
            <input type="text" name="pcd" placeholder="ex: true or false" /><br />
            <button>Jogar Xadrez</button>
        </form>
        <div style={{
            alignContent: "center",
            textAlign: "center",
            width: "100%",
            background: "red",
        }}>
            <iframe name="output_frame" src="" id="output_frame" frameborder="0" width={iframWidth} height={iframHeight} />
        </div>
    </React.Fragment>;
}


export default ChessGameRender;
