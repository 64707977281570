export function createCountdownModel(
  message = "",
  timer = false,
  config = 0,
  date = "",
  horizontal = 'center',
  vertical = 'top',
) {
  const object = {
    message: message,
    timer: timer,
    config: config,
    date: date,
    horizontal: horizontal,
    vertical: vertical
  };

  return object;
}
