import React from "react";
import { store } from "../store";

//Imports de components
import { ElementTreatment } from ".";

const TransmissionChangeSchedule = (props) => {
  const [executeElement, setExecuteElement] = React.useState(false);
  const [chosenConfig, setChosenConfig] = React.useState(null);
  const config = React.useRef(0);

  React.useEffect(() => {
    window.addEventListener("message", openModal);

    return () => {
      window.removeEventListener("message", openModal);
    };
  }, [config]);

  function openModal(e) {
    if (
      e.data &&
      e.data.message &&
      e.data.message === "transmissionChangeScheduleAndCloseModal"
    ) {
      if (
        e.data.scheduleId &&
        e.data.scheduleId > 0 &&
        config.current !== e.data.scheduleId
      ) {
        openSchedule(e.data.scheduleId);
        config.current = e.data.scheduleId;
      }
    }
  }
  const openSchedule = (scheduleId) => {
    var _modalButton = document.getElementById("modalCloseButton");
    if (!_modalButton) {
      return;
    }

    const _schedule = store.getState().config.find((s) => {
      return s.scheduleId === scheduleId;
    });
    if (!_schedule) {
      return;
    }

    _modalButton.click();

    setChosenConfig(_schedule);
    setExecuteElement(true);
  };

  return (
    <div>
      {executeElement && (
        <ElementTreatment
          chosenConfig={chosenConfig}
          onClose={() => {
            setExecuteElement(false);
            setChosenConfig(null);
          }}
        />
      )}
    </div>
  );
};

export default TransmissionChangeSchedule;
