import React from 'react';
import { connect } from 'react-redux';
import { getUrl } from '../../services/apiService';
import { getDic } from '../../assets/i18n/dictionary';
import { userUpdate, userLoginSuccess } from '../../store/actions';
import { getConfigListByPage } from '../../services/configService';
import * as userService from '../../services/userService';
import { insertNewAccess, getSystemActions } from '../../services/accessService';
import { addNewUserImageApi } from '../../services/fileService';
import { getFileExtension } from '../../utils';
import CameraIcon from '@material-ui/icons/AddAPhoto';
import FaceIcon from '@material-ui/icons/Face';
import * as navigationHistoryService from '../../services/navigationHistoryService';

//Imports de components
import {
	Container,
	FormColumn,
	FormRow,
	TextMedium,
	Card,
	Avatar,
	ActivityIndicator,
	TextBig,
	Button,
	Checkbox,
	Link,
	Modal,
	Alert,
	DefaultTerm,
	FabButton,
	InputFieldOutlined,
	Loading,
	TextSmall,
	IframePlayer,
	SelectList
} from '../../components';
import { store } from '../../store';

class BadgePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			alertOpen: false,
			alertTitle: '',
			alertMessage: '',
			renderButton: false,
			checkAuthorization: this.props.user.firstTerm,
			image: '',
			terms: null,
			IsComboboxTerms: false,
			isIframeTerms: false,
			hasAvatar: true,
			cardConfig: null,
			openModalNewPhoto: false,
			fileNewPhoto: null,
			openLoading: false,
			loadingMessage: '',
			imageBackgroundUrl: null,
			colorBackGround: '#e8eced',
			tittle001: {
				text: '',
				color: "black",
				size: "12px",
			},
			tittle002: {
				text: getDic('termos-mensagem'),
				color: "black",
				size: "14px",
			},
			tittle003: {
				text: getDic('ler') + ' ' + getDic('termos'),
				color: "black",
				size: "14px",
			},
			tittle004: {
				color: "black",
				size: "25px",
			},
			hasAvatarCreator: true,
			button001: {
				text: getDic('acessar'),
				background: "#3f51b5",
				textColor: "white"
			}
		};
	}

	async componentDidMount() {
		//Aplica configurações
		const configList = await getConfigListByPage(this.props.match.url);
		configList.find((item) => {
			if (item.codeScreen === 'IMAGE-001') {
				this.setState({ image: item.url[0] });
			}
			if (item.codeScreen === 'BACKGROUND-COLOR') {
				this.setState({ colorBackGround: item.url[0] });
			}
			if (item.codeScreen === 'IMAGE-002') {
				this.setState({
					imageBackgroundUrl: item.url[0],
					colorBackGround: '#ffffff00'
				});
			}
			if (item.codeScreen === 'CARD-SIMP') {
				this.setState({ cardConfig: 'simple' });
			}
			if (item.codeScreen === 'CARD-TRAN') {
				this.setState({ cardConfig: 'transparent' });
			}
			if (item.codeScreen === 'TITTLE-001') {
				var newObj = this.state.tittle001;
				newObj.text = item.tooltip && item.tooltip !== "" ? item.tooltip : this.state.tittle001.text;
				if (item.url.length > 0) {
					item.url.forEach((item) => {
						var conf = item.split(":");
						newObj[conf[0]] =
							conf[0] === "size" ? conf[1] + "px" : conf[1];
					});
				}
				this.setState({ tittle001: newObj });
			}
			if (item.codeScreen === 'TITTLE-002') {
				var newObj = this.state.tittle002;
				newObj.text = item.tooltip && item.tooltip !== "" ? item.tooltip : this.state.tittle002.text;
				if (item.url.length > 0) {
					item.url.forEach((item) => {
						var conf = item.split(":");
						newObj[conf[0]] =
							conf[0] === "size" ? conf[1] + "px" : conf[1];
					});
				}
				this.setState({ tittle002: newObj });
			}
			if (item.codeScreen === 'TITTLE-003') {
				var newObj = this.state.tittle003;
				newObj.text = item.tooltip && item.tooltip !== "" ? item.tooltip : this.state.tittle003.text;
				if (item.url.length > 0) {
					item.url.forEach((item) => {
						var conf = item.split(":");
						newObj[conf[0]] =
							conf[0] === "size" ? conf[1] + "px" : conf[1];
					});
				}
				this.setState({ tittle004: newObj });
			}
			if (item.codeScreen === 'TITTLE-004') {
				var newObj = this.state.tittle004;
				if (item.url.length > 0) {
					item.url.forEach((item) => {
						var conf = item.split(":");
						newObj[conf[0]] =
							conf[0] === "size" ? conf[1] + "px" : conf[1];
					});
				}
				this.setState({ tittle004: newObj });
			}
			if (item.codeScreen === 'TERM-001') {
				var isIframe = false;
				var terms = item.tooltip && item.tooltip !== "" ? item.tooltip : this.state.button001.text;
				if (item.typeId === 3) {
					isIframe = true;
					terms = item.url[0];
				}
				this.setState({ terms: terms, isIframeTerms: isIframe });
			}
			if (item.codeScreen === 'NO-AVATAR') {
				this.setState({ hasAvatar: false });
			}
			if (item.codeScreen === 'NO-AVATAR-CREATE') {
				this.setState({ hasAvatarCreator: false });
			}
			if (item.codeScreen === 'BUTTON-001') {
				var newObj = this.state.button001;
				newObj.text = item.tooltip && item.tooltip !== "" ? item.tooltip : this.state.button001.text;
				if (item.url.length > 0) {
					item.url.forEach((item) => {
						var conf = item.split(":");
						newObj[conf[0]] = conf[1];
					});
				}
				this.setState({ button001: newObj });
			}
			return null;
		});

		const _isComboboxTerms = this.props.config.find(c => {
			return c.codeScreen === "TERM-001-COMBOBOX";
		})
		if (_isComboboxTerms) {
			this.setState({ IsComboboxTerms: true });
		}

		await insertNewAccess(this.props.match.url, '', getSystemActions().accessPage);

		this.setState({ isLoading: false, renderButton: true });
	}

	async componentWillUnmount() {
		await insertNewAccess(this.props.match.url, '', getSystemActions().exitPage);
	}

	componentDidCatch(e) {
		var error = e.toString();

		if (error.includes('Error: Maximum update depth exceeded')) {
			window.location.reload();
		}
	}

	callNextPage() {
		let avatarRequired = this.props.config.filter((e) => e.codeScreen == 'IMAGE-REQUIRED').length < 1;
		if (!this.props.user.image && !avatarRequired) {
			this.setState({
				alertTitle: getDic('atencao'),
				alertMessage: getDic('badge-photo'),
				alertOpen: true
			});
		} else {
			const _termsNotRequired = this.props.config.find(c => {
				return c.codeScreen === 'TERMS-NOT-REQUIRED';
			});

			if (_termsNotRequired || this.state.checkAuthorization) {
				navigationHistoryService.setHistory(this.props.history, '/' + this.props.event.id + '/eventhall');
			} else {
				this.setState({
					alertTitle: getDic('atencao'),
					alertMessage: getDic('termos-necessita-aceite'),
					alertOpen: true
				});
			}
		}
	}

	async changeUserTerm(change) {
		this.setState({ checkAuthorization: change });

		await this.props.userUpdate(this.props.user, change, true);

		userService
			.changeUserTerms(change, true)
			.then(() => {
				console.log('Usuario atualizado');
			})
			.catch((err) => {
				console.log('Erro changeUserTerms: ', err);
			});
	}

	onChange(e) {
		this.setState({ fileNewPhoto: e.target.files[0] });
	}

	registerNewImage() {
		this.setState({ openLoading: true, loadingMessage: getDic('enviando') });

		if (this.state.fileNewPhoto) {
			//Verifica se há restrições para envio de arquivo, se sim, checar so o arquivo está de acordo
			var fileType = getFileExtension(this.state.fileNewPhoto);
			if (fileType !== 'IMG' && fileType !== 'JPEG' && fileType !== 'JPG' && fileType !== 'PNG') {
				this.setState({
					alertTitle: getDic('erro'),
					alertMessage: getDic('arquivo-erro-tipo'),
					alertOpen: true,
					openLoading: false
				});
			} else {
				console.log(this.state.fileNewPhoto, 'this.state.fileNewPhoto');
				addNewUserImageApi(this.state.fileNewPhoto)
					.then(async (res) => {
						var newUser = this.props.user;
						newUser.image = res;

						await this.props.userLoginSuccess(newUser);

						var test = store.getState().user;

						this.setState({
							alertTitle: getDic(''),
							alertMessage: getDic('enviado-sucesso'),
							alertOpen: true,
							fileNewPhoto: null,
							openModalNewPhoto: false
						});
					})
					.catch((err) => {
						this.setState({
							alertTitle: getDic('erro'),
							alertMessage: getDic('enviado-erro'),
							alertOpen: true
						});
					})
					.finally(() => {
						this.setState({ openLoading: false });
					});
			}
		} else {
			this.setState({
				alertTitle: getDic(''),
				alertMessage: getDic('arquivo-nao-selecionado-erro-upload'),
				alertOpen: true,
				openLoading: false
			});
		}
	}

	renderTerms() {
		if (this.state.terms) {
			if (this.state.isIframeTerms) {
				return <IframePlayer height="80vh" src={this.state.terms} />;
			} else {
				return (
					<div style={{ maxHeight: '500px' }} dangerouslySetInnerHTML={{ __html: this.state.terms }} />
				);
			}
		} else {
			return (
				<div style={{ maxHeight: '500px' }}>
					<DefaultTerm />
				</div>
			);
		}
	}

	renderButton() {
		if (this.state.renderButton) {
			return (
				<FormRow align="center" margin="0px">
					{this.state.IsComboboxTerms ? (
						<FormRow align="center" margin="0px">
							<div style={{ paddingTop: 12, paddingRight: 5 }}>
								<TextMedium
									fontWeight="bold"
									textColor={this.state.tittle002.color}
									fontSize={this.state.tittle002.size}
								>
									{this.state.tittle002.text}
								</TextMedium>
							</div>
							<SelectList
								width="100px"
								value={this.state.checkAuthorization}
								menuItens={[
									{ text: getDic("nao"), value: false },
									{ text: getDic("sim"), value: true }
								]}
								onChange={async (event) => {
									this.setState({ checkAuthorization: event.target.value });
								}}
							/>
						</FormRow>
					) : (
						<FormRow align="center" margin="0px">
							{/* <TextSmall text="Lí e concordo com os termos" /> */}
							<Checkbox
								text={this.state.tittle002.text}
								textColor={this.state.tittle002.color}
								fontSize={this.state.tittle002.size}
								checked={this.state.checkAuthorization}
								onChange={() => {
									var change = this.state.checkAuthorization === true ? false : true;
									this.changeUserTerm(change);
								}}
							/>
						</FormRow>
					)}
					<FormRow align="center">
						<Link color={this.state.tittle003.color}>
							<TextMedium
								color={this.state.tittle003.color}
								fontSize={this.state.tittle003.size}
								onClick={() => {
									this.setState({ openModalTerms: true });
								}}
							>
								{this.state.tittle003.text}
							</TextMedium>
						</Link>
					</FormRow>
					<FormRow align="center">
						<Button
							color={this.state.button001.background}
							textColor={this.state.button001.textColor}
							text={this.state.button001.text}
							onClick={() => this.callNextPage()}
						/>
					</FormRow>

					<Modal
						isVideo
						open={this.state.openModalTerms}
						closeButtonClick={(e) => {
							this.setState({ openModalTerms: false });
						}}
						onClose={(ret) => {
							this.setState({ openModalTerms: false });
						}}
					>
						<Container background="#e8eced" align="left" height="100%">
							{this.renderTerms()}
						</Container>
					</Modal>
				</FormRow>
			);
		} else {
			return (
				<FormRow align="center">
					<ActivityIndicator />
				</FormRow>
			);
		}
	}

	render() {
		if (this.state.isLoading)
			return (
				<Container background="#e8eced" align="center">
					<FormRow align="center">
						<ActivityIndicator />
					</FormRow>
				</Container>
			);
		return (
			<Container background={this.state.colorBackGround} align="center">
				<div className="admin-dashboard">
					<img src={this.state.imageBackgroundUrl} className="bg" />
					<FormRow align="center" background={this.state.colorBackGround} margin="0px">
						<div
							style={{
								width: '100%',
								maxWidth: '420px',
								textAlign: 'center',
								marginTop: '10px',
								marginBottom: '10px'
							}}
						>
							<Card cardType={this.state.cardConfig}>
								<FormColumn align="center" overflow="hidden">
									<FormRow align="center">
										<div style={{ width: '100%', maxWidth: '600px' }}>
											<img alt="" src={this.state.image} style={{ width: '100%' }} />
										</div>
									</FormRow>

									{this.state.hasAvatar ? (
										<div>
											<FormRow align="center">
												<Avatar src={this.props.user.image} size="big" />
											</FormRow>
											<div className="icons-profile" style={{ marginTop: '-45px' }}>
												{this.state.hasAvatarCreator && (
													<a
														className="link-create-avatar"
														color="#e0e0e0"
														textColor="black"
														onClick={() => navigationHistoryService.setHistory(this.props.history, '/' + this.props.event.id + '/createavatar')}
													>
														<FaceIcon />
													</a>
												)}
												<FabButton
													color="#e0e0e0"
													textColor="black"
													onClick={() => this.setState({ openModalNewPhoto: true })}
												>
													<CameraIcon />
												</FabButton>
											</div>
										</div>
									) : null}
									<br />
									<TextBig
										color={this.state.tittle004.color}
										fontSize={this.state.tittle004.size}
									>
										{this.props.user.name ? this.props.user.name.toUpperCase() : ''}
									</TextBig>
									{/* <TextMedium>
                    {this.props.user.position
                      ? this.props.user.position.toUpperCase()
					  : ""}
                  </TextMedium> */}
									<br />
									{this.renderButton()}
									<TextSmall
										fontSize={this.state.tittle001.size}
										color={this.state.tittle001.color}
									>
										{this.state.tittle001.text}
									</TextSmall>
								</FormColumn>
							</Card>
						</div>
					</FormRow>
				</div>

				<Alert
					open={this.state.alertOpen}
					onClose={() => this.setState({ alertOpen: false })}
					onClick={() => this.setState({ alertOpen: false })}
					title={this.state.alertTitle}
					message={this.state.alertMessage}
				/>

				<Modal
					disableFullScreen
					isVideo
					open={this.state.openModalNewPhoto}
					closeButtonClick={(e) => {
						this.setState({ openModalNewPhoto: false });
					}}
					onClose={(ret) => {
						this.setState({ openModalNewPhoto: false });
					}}
				>
					<Container background="#e8eced" align="left" height="100%">
						<FormColumn>
							<FormRow align="center">
								<TextBig>Upload</TextBig>
							</FormRow>
							<FormRow align="center">
								<div style={{ maxWidth: '400px', marginRight: '10px' }}>
									<InputFieldOutlined
										key="file"
										type="file"
										title={getDic('arquivo')}
										placeholder={getDic('arquivo')}
										onChange={(e) => this.onChange(e)}
									/>
								</div>
								<Button text={getDic('enviar')} onClick={() => this.registerNewImage()} />
							</FormRow>
							<FormRow align="center">
								<TextSmall>{getDic('formatos-permitidos')}: img, jpeg, jpg, png</TextSmall>
							</FormRow>
						</FormColumn>
					</Container>
				</Modal>

				<Loading open={this.state.openLoading} message={this.state.loadingMessage} />
			</Container>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		event: state.event,
		config: state.config
	};
}

const mapDispatchToProps = {
	userUpdate,
	userLoginSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgePage);
