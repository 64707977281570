import React from "react";
import RegistrationPageProvider from "./RegistrationPageProvider";
import RegistrationPageVisual from "./RegistrationPageVisual";

const RegistrationPage = (props) => {

    return (
        <RegistrationPageProvider history={props.history} params={props.match.params}>
            <RegistrationPageVisual />
        </RegistrationPageProvider>
    );
}

export default RegistrationPage;
