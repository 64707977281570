import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { getConfigListByPage } from "../services/configService";
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { getRankingApi } from '../services/gameficationService';
import { createRankingModel } from "../models/gameficationModel";
import NotificationsIcon from '@material-ui/icons/NotificationsActive';

//Imports de components
import {
    MenuButton,
    BackButton,

    Alert,
    Loading,
    Container,
    FormRow,
    FormColumn,
    GridRow,
    GridCell,
    TextMedium,
    TextBig,
    Avatar,
    FabButton,
} from '../components';

const RankingPage = (props) => {
    const [state, setState] = React.useState(true);

    //Alert control
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState("");
    const [alertMessage, setAlertMessage] = React.useState("");

    //Loading control
    const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
    const [openLoading, setOpenLoading] = React.useState(true);

    const [rankingList, setRankingList] = React.useState([]);
    const [imageBackgroundUrl, setImageBackgroundUrl] = React.useState(null);
    const [colorBackground, setColorBackground] = React.useState("#e8eced");
    const [primaryTitle, setPrimaryTitle] = React.useState({
        text: "Ranking",
        color: "black",
        size: "25px",
    });
    const [textFirstPlace, setTextFirstPlace] = React.useState({
        text: getDic("pontos"),
        color1: "black",
        color2: "black",
        size1: "25px",
        size2: "25px",
    });
    const [textSecondPlace, setTextSecondPlace] = React.useState({
        text: getDic("pontos"),
        color1: "black",
        color2: "black",
        size1: "25px",
        size2: "25px",
    });
    const [textThirdPlace, setTextThirdPlace] = React.useState({
        text: getDic("pontos"),
        color1: "black",
        color2: "black",
        size1: "25px",
        size2: "25px",
    });
    const [rankingConfig, setRankingConfig] = React.useState({
        text: getDic("pontos"),
        headerBackground: "#d9d9d9",
        textColorHeader: "black",
        bodyBackground: "transparent",
        textColorBody: "black",
        userBackground: "#e4e69e",
        textColorUser: "black",
    });

    React.useEffect(() => {
        getPageConfig();
        getRankingList();
        if (!props.isModal) {
            insertNewAccess(props.match.url, "", getSystemActions().accessPage);
        }

        return () => {
            if (!props.isModal) {
                insertNewAccess(props.match.url, "", getSystemActions().exitPage);
            }
        }
    }, []);

    const getPageConfig = async () => {
        //Aplica configurações
        const _url = !props.isModal ? props.match.url : `/${props.event.id}/ranking`;
        const configList = await getConfigListByPage(_url);

        configList.find((item) => {
            if (item.codeScreen === "IMAGE-001") {
                setImageBackgroundUrl(item.url[0]);
                setColorBackground("transparent");
            }
            if (item.codeScreen === "TITTLE-001") {
                var newObj = primaryTitle;
                newObj.text = item.tooltip;
                if (item.url.length > 0) {
                    item.url.forEach(url => {
                        var conf = url.split(":");
                        newObj[conf[0]] = conf[1];
                    });
                }
                setPrimaryTitle(newObj);
            }
            if (item.codeScreen === "ELEMENT-001") {
                var newObj = textFirstPlace;
                newObj.text = item.tooltip;
                if (item.url.length > 0) {
                    item.url.forEach(url => {
                        var conf = url.split(":");
                        newObj[conf[0]] = conf[1];
                    });
                }
                setTextFirstPlace(newObj);
            }
            if (item.codeScreen === "ELEMENT-002") {
                var newObj = textSecondPlace;
                newObj.text = item.tooltip;
                if (item.url.length > 0) {
                    item.url.forEach(url => {
                        var conf = url.split(":");
                        newObj[conf[0]] = conf[1];
                    });
                }
                setTextSecondPlace(newObj);
            }
            if (item.codeScreen === "ELEMENT-003") {
                var newObj = textThirdPlace;
                newObj.text = item.tooltip;
                if (item.url.length > 0) {
                    item.url.forEach(url => {
                        var conf = url.split(":");
                        newObj[conf[0]] = conf[1];
                    });
                }
                setTextThirdPlace(newObj);
            }
            if (item.codeScreen === "ELEMENT-004") {
                var newObj = rankingConfig;
                if (item.url.length > 0) {
                    item.url.forEach(url => {
                        var conf = url.split(":");
                        newObj[conf[0]] = conf[1];
                    });
                }
                setRankingConfig(newObj);
            }
            return null;
        });
    }

    const getRankingList = () => {
        setOpenLoading(true);

        getRankingApi()
            .then(res => {
                const _rank = insertMyPosition(res);
                setRankingList(_rank);
            })
            .catch(err => {
                var _hasRanking = true;
                if (err.toString().includes("status code 404")) {
                    _hasRanking = false;
                }
                setAlertOpen(true);
                setAlertTitle(_hasRanking ? getDic("erro") : "");
                setAlertMessage(_hasRanking ? getDic("sem-conexao") : getDic("pesquisa-sem-resultado"));
            })
            .finally(() => {
                setOpenLoading(false);
            })
    }

    const insertMyPosition = (ranking) => {
        var _userInRank = false;
        ranking.forEach(item => {
            if (item.guestId === props.user.id) {
                _userInRank = true;
            }
        });
        const _objReturn = ranking;
        if (!_userInRank) {
            const _myRecord = createRankingModel(
                11,
                props.user.id,
                props.user.name,
                props.user.points,
                props.user.levelId,
                props.user.levelName,
                props.user.image,
            )
            _objReturn.push(_myRecord);
        }
        return _objReturn;
    }

    return (
        <Container
            backgroundImage={imageBackgroundUrl}
            background={colorBackground}
            align="center"
            backgroundSize="100% 100%"
            height={props.isModal ? "100%" : "100vh"}
        >
            <FormRow align="center" background={colorBackground} margin="0px">
                {!props.isModal && (
                    <div>
                        <MenuButton history={props.history} />
                        <BackButton history={props.history} color="#212121">
                            {getDic("voltar").toUpperCase()}
                        </BackButton>

                    </div>
                )}

                <FormRow
                    minHeight="60px"
                    align="center"
                    background={colorBackground}
                    margin="0px"
                />

                <FormColumn align="center" background={colorBackground} margin="0px" padding="5px">
                    {rankingList.length > 0 ? (
                        <FormRow align="center" margin="0px">
                            <div style={{ width: "80%", background: "transparent", padding: "10px" }}>
                                <FormRow align="center">
                                    <TextBig
                                        fontSize={primaryTitle.size}
                                        color={primaryTitle.color}
                                    >
                                        {primaryTitle.text}
                                    </TextBig>
                                </FormRow>
                                <FormRow align="center">
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            {rankingList[1] && (
                                                <th style={{ width: "33.33%" }}>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <FormRow align="center">
                                                            <Avatar src={rankingList[1].image} size="medium" />
                                                            <div style={{
                                                                width: 30,
                                                                height: 30,
                                                                position: "absolute",
                                                                zIndex: 12,
                                                                borderRadius: 12,
                                                                color: "white",
                                                                cursor: "pointer",
                                                                marginRight: 100
                                                            }}>
                                                                <img
                                                                    src="https://s3.amazonaws.com/bucket.aws.public/2020/197219.PNG"
                                                                    style={{
                                                                        height: 30,
                                                                        width: 30
                                                                    }}
                                                                />
                                                            </div>
                                                        </FormRow>
                                                        <TextMedium
                                                            fontSize={textSecondPlace.size1}
                                                            color={textSecondPlace.color1}
                                                        >
                                                            {rankingList[1].guestName}
                                                        </TextMedium>
                                                        <TextBig
                                                            fontSize={textSecondPlace.size2}
                                                            color={textSecondPlace.color2}
                                                        >
                                                            {textSecondPlace.text} {rankingList[1].points}
                                                        </TextBig>
                                                    </div>
                                                </th>
                                            )}
                                            {rankingList[0] && (
                                                <th style={{ width: "33.33%" }}>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <FormRow align="center">
                                                            <Avatar src={rankingList[0].image} size="big" />
                                                            <div style={{
                                                                width: 30,
                                                                height: 30,
                                                                position: "absolute",
                                                                zIndex: 12,
                                                                borderRadius: 12,
                                                                color: "white",
                                                                cursor: "pointer",
                                                                marginRight: 100
                                                            }}>
                                                                <img
                                                                    src="https://s3.amazonaws.com/bucket.aws.public/2020/197218.PNG"
                                                                    style={{
                                                                        height: 30,
                                                                        width: 30
                                                                    }}
                                                                />
                                                            </div>
                                                        </FormRow>
                                                        <TextMedium
                                                            fontSize={textFirstPlace.size1}
                                                            color={textFirstPlace.color1}
                                                        >
                                                            {rankingList[0].guestName}
                                                        </TextMedium>
                                                        <TextBig
                                                            fontSize={textFirstPlace.size2}
                                                            color={textFirstPlace.color2}
                                                        >
                                                            {textFirstPlace.text} {rankingList[0].points}
                                                        </TextBig>
                                                    </div>
                                                </th>
                                            )}
                                            {rankingList[2] && (
                                                <th style={{ width: "33.33%" }}>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <FormRow align="center">
                                                            <Avatar src={rankingList[2].image} size="medium" />
                                                            <div style={{
                                                                width: 30,
                                                                height: 30,
                                                                position: "absolute",
                                                                zIndex: 12,
                                                                borderRadius: 12,
                                                                color: "white",
                                                                cursor: "pointer",
                                                                marginRight: 100,
                                                            }}>
                                                                <img
                                                                    src="https://s3.amazonaws.com/bucket.aws.public/2020/197220.PNG"
                                                                    style={{
                                                                        height: 30,
                                                                        width: 30
                                                                    }}
                                                                />
                                                            </div>
                                                        </FormRow>
                                                        <TextMedium
                                                            fontSize={textThirdPlace.size1}
                                                            color={textThirdPlace.color1}
                                                        >
                                                            {rankingList[2].guestName}
                                                        </TextMedium>
                                                        <TextBig
                                                            fontSize={textThirdPlace.size2}
                                                            color={textThirdPlace.color2}
                                                        >
                                                            {textThirdPlace.text} {rankingList[2].points}
                                                        </TextBig>
                                                    </div>
                                                </th>
                                            )}
                                        </tr>
                                    </table>
                                </FormRow>
                                <GridRow backgroundColor={rankingConfig.headerBackground}>
                                    <GridCell width="10%" top first>
                                        <TextMedium color={rankingConfig.textColorHeader}>
                                            {`${getDic("posicao").toUpperCase()}`}
                                        </TextMedium>
                                    </GridCell>
                                    <GridCell width="10%" top >
                                        <TextMedium color={rankingConfig.textColorHeader}>
                                            {`AVATAR`}
                                        </TextMedium>
                                    </GridCell>
                                    <GridCell width="40%" top >
                                        <TextMedium color={rankingConfig.textColorHeader}>
                                            {`${getDic("nome").toUpperCase()}`}
                                        </TextMedium>
                                    </GridCell>
                                    <GridCell width="20%" top>
                                        <TextMedium color={rankingConfig.textColorHeader}>
                                            {getDic("fase").toUpperCase()}
                                        </TextMedium>
                                    </GridCell>
                                    <GridCell width="20%" top>
                                        <TextMedium color={rankingConfig.textColorHeader}>
                                            {rankingConfig.text.toUpperCase()}
                                        </TextMedium>
                                    </GridCell>
                                </GridRow>

                                {rankingList.map((item, i) => {
                                    const _index = i + 1;
                                    if (_index > 3) {
                                        var _backgroundColor = rankingConfig.bodyBackground;
                                        var _textColor = rankingConfig.textColorBody;
                                        if (item.guestId === props.user.id) {
                                            _backgroundColor = rankingConfig.userBackground;
                                            _textColor = rankingConfig.textColorUser;
                                        }
                                        return (
                                            <GridRow
                                                key={item.id}
                                                backgroundColor={_backgroundColor}
                                            >
                                                <GridCell width="10%" first>
                                                    <TextMedium color={_textColor}>
                                                        {_index < 11 ? _index : ""}
                                                    </TextMedium>
                                                </GridCell>
                                                <GridCell width="10%" >
                                                    <FormRow align="center">
                                                        <Avatar src={item.image} size="smaller" />
                                                    </FormRow>
                                                </GridCell>
                                                <GridCell width="40%" >
                                                    <TextMedium color={_textColor}>
                                                        {item.guestName}
                                                    </TextMedium>
                                                </GridCell>
                                                <GridCell width="20%" >
                                                    <TextMedium color={_textColor}>
                                                        {item.levelName}
                                                    </TextMedium>
                                                </GridCell>
                                                <GridCell width="20%" >
                                                    <TextMedium color={_textColor}>
                                                        {item.points}
                                                    </TextMedium>
                                                </GridCell>
                                            </GridRow>
                                        );
                                    }
                                })}
                            </div>
                        </FormRow>
                    ) : (
                        <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
                    )}
                </FormColumn>

                <Alert
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    onClick={() => setAlertOpen(false)}
                    title={alertTitle}
                    message={alertMessage}
                />

                <Loading open={openLoading} message={loadingMessage} />
            </FormRow>
        </Container >
    )
}

function mapStateToProps(state) {
    return {
        event: state.event,
        user: state.user,
    }
}

export default connect(mapStateToProps, null)(RankingPage);
