import axios from 'axios';
import { store } from '../store';
import { getToken, getUrl } from './apiService';
import { createSpeakerModel } from '../models/speakerModel';

export async function getSpeakersApi() {

    const _event = store.getState().event;
    var method = getUrl().hall + "event/speaker/" + _event.id.toString();
    const token = await getToken();

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
        };
        axios(options)
            .then((res) => {
                if (res.data) {
                    const _speakers = [];

                    res.data.forEach(item => {
                        if (item.active === null || item.active === true) {

                            const _speak = createSpeakerModel(
                                item.id,
                                item.name,
                                item.company,
                                item.role,
                                item.curriculum,
                                item.photoUrl,
                                item.video,
                                item.facebook,
                                item.twitter,
                                item.linkedin,
                                item.webSite,
                                item.email,
                            );
                            _speakers.push(_speak);
                        }
                    });
                    resolve(_speakers);
                } else {
                    reject(res.data);
                }
            })
            .catch((err) => {
                if (err.toString().includes("status code 404")) {
                    resolve([]);
                }
                else {
                    console.log("Erro getSpeakersApi", err);
                    reject(err);
                }
            });
    });
}

export async function addNewSpeakerApi(speaker) {

    var method = getUrl().hall + "event/speaker";
    const token = await getToken();
    const _event = store.getState().event;

    const _speaker = {
        name: speaker.name,
        curriculum: speaker.biography,
        eventId: _event.id,
        role: speaker.role,
        company: speaker.company,
        email: speaker.email,
        webSite: speaker.site,
        facebook: speaker.facebook,
        twitter: speaker.twitter,
        linkedin: speaker.linkedin,
        video: speaker.video,
    }

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
            data: _speaker,
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    var _objReturn = speaker;
                    _objReturn.id = res.data.data.id;

                    resolve(_objReturn);
                } else {
                    reject(res);
                }
            })
            .catch((err) => {
                console.log("Erro addNewSpeakerApi", err);
                reject(err);
            });
    });
}

export async function updateSpeakerApi(speaker) {
    var method = getUrl().hall + "event/speaker";
    const token = await getToken();

    const _speaker = {
        id: speaker.id,
        name: speaker.name,
        curriculum: speaker.biography,
        role: speaker.role,
        company: speaker.company,
        email: speaker.email,
        webSite: speaker.site,
        facebook: speaker.facebook,
        twitter: speaker.twitter,
        linkedin: speaker.linkedin,
        video: speaker.video,
    }

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
            data: _speaker,
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    resolve(speaker);
                } else {
                    reject(res);
                }
            })
            .catch((err) => {
                console.log("Erro updateSpeakerApi", err);
                reject(err);
            });
    });
}

export async function deleteSpeakerApi(speakerId) {
    var method = getUrl().hall + "event/speaker/" + speakerId.toString();
    const token = await getToken();

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    resolve(true);
                } else {
                    reject(res);
                }
            })
            .catch((err) => {
                console.log("Erro deleteSpeakerApi", err);
                reject(err);
            });
    });
}

