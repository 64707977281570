import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { resetStand } from '../store/actions';
import { getConfigListByStand, getCoordinateList } from '../services/configService';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { isMobile } from "react-device-detect";

//Imports de components
import {
    Container,
    FormRow,
    ActivityIndicator,
    BackButton,

    MobileRender,
    ImageMapRender,
    MenuButton,
} from '../components';

class StandPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            configList: [],
            chosenStand: this.props.chosenStand,
            mobileRender: false,

            imageURLBackground_1600x900: "",
            map_1600x900: {
                name: "my-map",
                areas: [
                    { name: "ELEMENT-001", shape: "poly", coords: [476, 184, 969, 126, 971, 262, 476, 304], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "ELEMENT-002", shape: "poly", coords: [1132, 404, 1458, 407, 1396, 660, 1212, 664, 1150, 474], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "ELEMENT-003", shape: "rect", coords: [602, 407, 712, 542], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "ELEMENT-004", shape: "rect", coords: [770, 407, 880, 542], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    //{ name: "5", shape: "rect", coords: [291, 540, 559, 585], preFillColor: "transparent", fillColor: "transparent" }
                ]
            }
        }
    }

    async componentDidMount() {
        if (this.state.chosenStand && this.state.chosenStand.id) {

            //Aplica configurações
            const configList = await getConfigListByStand(this.state.chosenStand.id);

            configList.find(item => {
                if (item.codeScreen === "NO-MOBILE") {
                    this.setState({ mobileRender: true });
                }
                return null;
            });

            const coordinateList = await getCoordinateList(this.state.map_1600x900, configList);

            this.setState({ isLoading: false, configList: configList, map_1600x900: coordinateList });

            await insertNewAccess(
                this.props.match.url,
                this.state.chosenStand.id.toString(),
                getSystemActions().accessPage,
                "Stand",
                this.state.chosenStand.id.toString()
            );
        }
        else {
            this.props.history.goBack();
        }
    }

    componentWillUnmount() {
        this.props.resetStand()
            .then(async () => {
                if (this.state.chosenStand && this.state.chosenStand.id) {
                    await insertNewAccess(
                        this.props.match.url,
                        this.state.chosenStand.id.toString(),
                        getSystemActions().exitPage,
                        "Stand",
                        this.state.chosenStand.id.toString()
                    );

                    // insertNewAccess(this.props.match.url, this.state.chosenStand.name, getSystemActions().exitPage, this.state.chosenStand.name, this.state.chosenStand.id.toString())
                    //     .then(() => {
                    //         // window.location.reload();
                    //     })
                }
                else {
                    // window.location.reload();
                }
            });
    }

    componentDidCatch(e) {
        var error = e.toString();

        if (error.includes("Error: Maximum update depth exceeded")) {
            window.location.reload();
        }
    }

    render() {
        if (this.state.isLoading)
            return (
                <Container background="#e8eced" align="center" >
                    <FormRow align="center">
                        <ActivityIndicator />
                    </FormRow>
                </Container>
            );
        return (
            <div>
                {!this.props.isModal && (
                    <>
                        <MenuButton history={this.props.history} />
                        <BackButton history={this.props.history} color="#212121">
                            {getDic("voltar").toUpperCase()}
                        </BackButton>

                    </>
                )}

                {isMobile && this.state.mobileRender ? (
                    <MobileRender
                        configList={this.state.configList}
                        defaultImage={this.state.imageURLBackground_1600x900}
                        history={this.props.history}
                    />
                ) : (
                    <div>
                        <ImageMapRender
                            configList={this.state.configList}
                            history={this.props.history}
                            defaultImage={this.state.imageURLBackground_1600x900}
                            defaultMap={this.state.map_1600x900}
                        />
                    </div>
                )
                }
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        chosenStand: state.chosenStand
    }
}

const mapDispatchToProps = {
    resetStand
}

export default connect(mapStateToProps, mapDispatchToProps)(StandPage)
