import React, { useRef } from "react";
import { store } from "../../../../store";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import "react-perfect-scrollbar/dist/css/styles.css";
import "firebase/firestore";
import "firebase/auth";
import { firestore } from "../../../../utils/firebase";
import {
  useCollectionData,
  useCollectionDataOnce
} from "react-firebase-hooks/firestore";
import { animateScroll } from "react-scroll";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { insertHyperLink } from "../../../../utils/inputFieldMasks";

const messageTreatment = m => {
  var text = m;
  if (
    text.includes("http://") ||
    text.includes("https://") ||
    text.includes("HTTPS://") ||
    text.includes("HTTP://")
  ) {
    text = insertHyperLink(text);
  }
  return (
    <div>
      <span fontSize="14px">{text}</span>
    </div>
  );
};

const ScrollMessages = ({
  heightParent,
  currentTargetMsg,
  event,
  user,
  setCurrentTargetMsg,
  fullScreen
}) => {
  const dummys = useRef();

  const lastMsg = useRef();
  const anotherMsg = useRef();

  const messageRef = firestore
    .collection("network")
    .doc(`${event.id}`)
    .collection("messages")
    .doc(`${user.id}`)
    .collection("history")
    .doc(`${currentTargetMsg.id}`)
    .collection("msgs");
  // Collections Querys
  const queryPrivateChat = messageRef
    .orderBy("createdAt", "asc")
    .limitToLast(10);
  const [privateChat] = useCollectionData(queryPrivateChat, { idField: "id" });
  const [hasMore, setHasMore] = React.useState(true);
  const [lastItemTime, setLastItemTime] = React.useState();
  const [privateChatList, setPrivateChatList] = React.useState([]);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [firstLoading, setFirstLoading] = React.useState(true);

  const loadMoreMessages = props => {
    if (firstLoading) return;
    if (!lastItemTime) return;
    setLoadingMore(true);
    setTimeout(() => {
      const localQuery = messageRef
        .orderBy("createdAt", "desc")
        .startAfter(lastItemTime)
        .limitToLast(10);
      localQuery.get({ idField: "id" }).then(result => {
        let tempList = [];
        if (!result.empty) {
          setHasMore(true);
          result.forEach(item => {
            tempList.push({
              ...item.data(),
              id: item.id
            });
            setLastItemTime(item);
          });
          let tempArray = [...privateChatList, ...tempList];
          setPrivateChatList(
            tempArray.sort(function(a, b) {
              return a.createdAt.toDate() > b.createdAt.toDate()
                ? 1
                : b.createdAt.toDate() > a.createdAt.toDate()
                ? -1
                : 0;
            })
          );
          setLoadingMore(false);

          let liList = document.getElementsByClassName("list-li");
          liList[0].childNodes[
            parseInt(liList[0].childNodes.length / 2)
          ].scrollIntoView();
        } else {
          setHasMore(false);
          setLoadingMore(false);
        }
      });
    }, 1000);
  };

  const loadMessages = props => {
    const localQuery = messageRef.orderBy("createdAt", "asc").limitToLast(10);
    localQuery.get().then(result => {
      let tempList = [];
      let itens = [];
      result.forEach(item => {
        tempList.push({
          ...item.data(),
          id: item.id,
          item: item
        });
      });

      if (tempList.filter(e => e.uid != user.id).length > 0)
        setLastItemTime(tempList[0].item);
      setPrivateChatList([...tempList]);
      setHasMore(true);
      scrollToBottom();
      setFirstLoading(false);
    });
  };

  const scrollToBottom = () => {
    /* animateScroll.scrollToBottom({
            containerId: "ul_container",
            delay:0,
            duration: 0
        }); */
    /*         if(privateChat && privateChat.length > 5)
                    dummys.current && dummys.current.scrollIntoView({ behavior: 'smooth' }); */
    var target = document.getElementById("targetScroll");
    target.parentNode.scrollTop = target.offsetTop;
  };

  React.useEffect(() => {
    loadMessages();
    scrollToBottom();
    if (privateChat && privateChat.length > 0) {
      let msgFiltered = privateChat.filter(e => e.uid != user.id);
      if (msgFiltered.length > 0) {
        setCurrentTargetMsg({
          ...currentTargetMsg,
          lastMsg: msgFiltered[msgFiltered.length - 1].createdAt
        });
      }
    }
  }, [
    privateChat
  ]);
  const documentView = window.screen.height - 240;
  const statusBar = window.screen.height - window.innerHeight;
  return (
    <InfiniteScroll
      dataLength={privateChatList.length}
      next={() => loadMoreMessages()}
      hasMore={hasMore}
      height={
        fullScreen == "80vh" ? heightParent - 193 : documentView - statusBar
      }
      scrollThreshold="1px"
      style={{
        transition: "2s"
      }}
      inverse={true}
      className="list-li"
    >
      <li
        style={{
          height: 50,
          textAlign: "center",
          listStyle: "none"
        }}
      >
        <CircularProgress
          color="#337ab7"
          style={{
            width: loadingMore ? 20 : 0,
            height: loadingMore ? 20 : 0,
            transition: "0.5s"
          }}
        />
      </li>
      {privateChatList &&
        privateChatList.map((e, index) =>
          e.uid != user.id ? (
            <li
              ref={index == privateChatList.length - 1 ? lastMsg : anotherMsg}
              key={index}
              index={index}
              style={{ listStyle: "none" }}
            >
              <div className="conversation-list">
                <div className="dropdown">
                  <a
                    aria-haspopup="true"
                    className="dropdown-toggle"
                    aria-expanded="false"
                  >
                    <i className="bx bx-dots-vertical-rounded" />
                  </a>
                  <div
                    tabIndex={-1}
                    role="menu"
                    direction="right"
                    aria-hidden="true"
                    className="dropdown-menu"
                  >
                    <a
                      href="#"
                      tabIndex={0}
                      role="menuitem"
                      className="dropdown-item"
                    >
                      Copy
                    </a>
                    <a
                      href="#"
                      tabIndex={0}
                      role="menuitem"
                      className="dropdown-item"
                    >
                      Save
                    </a>
                    <a
                      href="#"
                      tabIndex={0}
                      role="menuitem"
                      className="dropdown-item"
                    >
                      Forward
                    </a>
                    <a
                      href="#"
                      tabIndex={0}
                      role="menuitem"
                      className="dropdown-item"
                    >
                      Delete
                    </a>
                  </div>
                </div>
                <div className="ctext-wrap">
                  <div className="conversation-name">{e.user}</div>
                  <p>{e.body}</p>
                  <p className="chat-time mb-0">
                    <i className="bx bx-time-five align-middle me-1" />
                    {e.createdAt &&
                      moment(e.createdAt.toDate())
                        .locale(
                          store.getState().lang.lang === "sp"
                            ? "es"
                            : store.getState().lang.lang
                        )
                        .fromNow()}
                  </p>
                </div>
              </div>
            </li>
          ) : (
            <li
              ref={index == privateChatList.length - 1 ? lastMsg : anotherMsg}
              key={index}
              index={index}
              className="right"
              style={{ listStyle: "none" }}
            >
              <div className="conversation-list">
                <div className="dropdown">
                  <a
                    aria-haspopup="true"
                    className="dropdown-toggle"
                    aria-expanded="false"
                  >
                    <i className="bx bx-dots-vertical-rounded" />
                  </a>
                  <div
                    tabIndex={-1}
                    role="menu"
                    direction="right"
                    aria-hidden="true"
                    className="dropdown-menu"
                  >
                    <a
                      href="#"
                      tabIndex={0}
                      role="menuitem"
                      className="dropdown-item"
                    >
                      Copy
                    </a>
                    <a
                      href="#"
                      tabIndex={0}
                      role="menuitem"
                      className="dropdown-item"
                    >
                      Save
                    </a>
                    <a
                      href="#"
                      tabIndex={0}
                      role="menuitem"
                      className="dropdown-item"
                    >
                      Forward
                    </a>
                    <a
                      href="#"
                      tabIndex={0}
                      role="menuitem"
                      className="dropdown-item"
                    >
                      Delete
                    </a>
                  </div>
                </div>
                <div className="ctext-wrap">
                  <div className="conversation-name">{e.user}</div>
                  <p>{messageTreatment(e.body)}</p>
                  <p className="chat-time mb-0">
                    <i className="bx bx-time-five align-middle me-1" />
                    {e.createdAt &&
                      moment(e.createdAt.toDate())
                        .locale(
                          store.getState().lang.lang === "sp"
                            ? "es"
                            : store.getState().lang.lang
                        )
                        .fromNow()}
                  </p>
                </div>
              </div>
            </li>
          )
        )}
      <li style={{ listStyle: "none" }} ref={dummys} id="targetScroll"></li>
    </InfiniteScroll>
  );
};

export default ScrollMessages;
