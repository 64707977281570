import { IS_LOADING, LOADING_COMPLETE } from '../actions';

const INITIAL_STATE = true;

export default function eventReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case IS_LOADING:
            return true;
        case LOADING_COMPLETE:
            return false;
        default:
            return state;
    }
}
