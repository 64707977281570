import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { bglist, facelist, eyeslist, faciallist, hairlist } from '../assets/images/templates/templatelist';
import { fabric } from 'fabric';

//Imports de components
import {
    FormRow,
    FormColumn,
    FabricCanvas,
    TemplateList,
} from '../components';

//Como criar avatar maker
// https://scotch.io/@alizohaib/how-to-create-an-avatar-maker-with-react-and-fabric-js

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function AvatarMaker() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const [activeProperty, setActiveProperty] = React.useState(null);

    const addToCanvas = (imgElement, property_type, z_Index) => {

        var imgInstance = new fabric.Image(imgElement, {
            width: 400,
            height: 400,
            the_type: property_type,
            zIndex: z_Index
        });

        setActiveProperty(imgInstance);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <FormRow>
                <FormColumn width="50%">
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Faces" {...a11yProps(0)} />
                            <Tab label="Eyes" {...a11yProps(1)} />
                            <Tab label="Beard" {...a11yProps(2)} />
                            <Tab label="Hair" {...a11yProps(3)} />
                            <Tab label="Background" {...a11yProps(4)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <TemplateList
                            data={facelist}
                            property_type="face"
                            zIndex={0}
                            addtocanvas={addToCanvas}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TemplateList
                            data={eyeslist}
                            property_type="eyes"
                            zIndex={2}
                            addtocanvas={addToCanvas}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TemplateList
                            data={faciallist}
                            property_type="beard"
                            zIndex={2}
                            addtocanvas={addToCanvas}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <TemplateList
                            data={hairlist}
                            property_type="hair"
                            zIndex={2}
                            addtocanvas={addToCanvas}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <TemplateList
                            data={bglist}
                            property_type="bg"
                            zIndex={-9999}
                            addtocanvas={addToCanvas}
                        />
                    </TabPanel>
                </FormColumn>

                <FormColumn width="50%">
                    <FabricCanvas
                        activeProperty={activeProperty}
                    />
                </FormColumn>
            </FormRow>
        </div>
    );
}
