import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { getFileExtension } from '../utils';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { getQuestionsApi, addNewQuestionApi, updateQuestionApi, deleteQuestionApi } from '../services/questionsService';
import { createQuestionModel, convertFormToQuestionObject, convertQuestionObjectToForm } from '../models/questionModel';
import { validateForm } from '../utils';
import QuestionImporterExcel from '../excel/QuestionImporterExcel';
import QuestionImporter from '../importers/questionImporter';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
//Imports de components
import {
  MenuButton,
  BackButton,
  Alert,
  Loading,
  Container,
  FormRow,
  FormColumn,
  GridRow,
  GridCell,
  TextMedium,
  Button,
  Dialog,
  InputFieldOutlined,
  TextSmall,
  InfiniteScroll,
  SelectList,
  Modal
} from '../components';

const QuestionsRegisterPage = (props) => {
  const [state, setState] = React.useState(true);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(true);

  //Dialog control
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [dialogConfirmKey, setDialogConfirmKey] = React.useState("");
  const [dialogConfirmKeyErrorMessage, setDialogConfirmKeyErrorMessage] = React.useState("");

  //Modal Control
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalImporter, setOpenModalImporter] = React.useState(false);

  const [importerTable, setImporterTable] = React.useState(null);
  const [importerReturnTable, setImporterReturnTable] = React.useState(null);
  const [executeQuestionImporter, setExecuteScheduleImporter] = React.useState(false);

  const [questionsList, setQuestionsList] = React.useState([]);
  const [chosenQuestion, setChosenQuestion] = React.useState(null);
  const [newQuestionForm, setNewQuestionForm] = React.useState([]);

  React.useEffect(() => {
    //Bloqueio caso o usuário não seja admin
    if (!props.user.isAdmin) {
      props.history.goBack();
    }

    var helpButton = document.getElementById("launcher");
    if (helpButton) {
      helpButton.style.visibility = 'hidden';
    }

    getQuestions();
    insertNewAccess(props.match.url, "", getSystemActions().accessPage);

    return () => {
      var helpButton = document.getElementById("launcher");
      if (helpButton) {
        helpButton.style.visibility = 'visible';
      }
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    }
  }, []);

  const getQuestions = () => {
    setOpenLoading(true);

    getQuestionsApi(props.event.id)
      .then(res => {
        setQuestionsList(res.questions);
      })
      .catch(() => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      })
      .finally(() => {
        setOpenLoading(false);
      })
  }

  const onChangeHandler = (index, value) => {
    var _newForm = newQuestionForm;
    _newForm[index].value = value;
    setNewQuestionForm(_newForm);
    setState(state === true ? false : true);
  }

  const resetQuestionForm = () => {
    const _newQuestion = createQuestionModel();
    const _newForm = convertQuestionObjectToForm(_newQuestion);
    setNewQuestionForm(_newForm);
  }

  const createNewQuestion = () => {
    const _newQuestion = createQuestionModel();
    const _newForm = convertQuestionObjectToForm(_newQuestion);
    setNewQuestionForm(_newForm);
    setOpenModal(true);
  }

  const editQuestion = (question) => {
    const _newForm = convertQuestionObjectToForm(question);
    setNewQuestionForm(_newForm);
    setOpenModal(true);
  }

  const inactiveQuestion = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("salvando"));

    deleteQuestionApi(chosenQuestion.id)
      .then(res => {
        const _newQuestionList = [];
        questionsList.forEach(item => {
          if (item.id !== chosenQuestion.id) {
            _newQuestionList.push(item);
          }
        });
        _newQuestionList.sort((a, b) => {
          return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
        });
        setQuestionsList(_newQuestionList);
        setAlertOpen(true);
        setAlertTitle("");
        setAlertMessage(getDic("enviado-sucesso"));
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setOpenLoading(false);
      })
      .finally(() => {
        setOpenLoading(false);
      })
  }

  const saveQuestion = async () => {
    var validation = validateForm(newQuestionForm);
    validation = validateJsonOptionsField(validation);

    if (validation.isValid) {

      var _newQuestion = convertFormToQuestionObject(newQuestionForm);

      setOpenLoading(true);
      setLoadingMessage(getDic("salvando"));

      _newQuestion.eventId = props.event.id;

      var _error = false;
      const _newQuestionList = [];

      if (_newQuestion.id > 0) {
        await updateQuestionApi(_newQuestion)
          .then(res => {
            _newQuestionList.push(res);
          })
          .catch(err => {
            _error = true;
          })
      }
      else {
        await addNewQuestionApi(_newQuestion)
          .then(res => {
            _newQuestion.id = res.id;
            _newQuestionList.push(_newQuestion);
          })
          .catch(err => {
            _error = true;
          })
      }

      if (_error) {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setOpenLoading(false);
      }
      else {
        questionsList.forEach(item => {
          if (item.id !== _newQuestion.id) {
            _newQuestionList.push(item);
          }
        });
        _newQuestionList.sort((a, b) => {
          return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
        });
        setQuestionsList(_newQuestionList);
        setAlertOpen(true);
        setAlertTitle("");
        setAlertMessage(getDic("enviado-sucesso"));
        resetQuestionForm();
        setOpenLoading(false);
        setOpenModal(false);
        setState(state === true ? false : true);
      }
    }
    else {
      setNewQuestionForm(validation.form);
    }
  }

  const validateJsonOptionsField = (validation) => {
    var _options = validation.form.findIndex(f => {
      return f.dbReference === 'valuesStr';
    });

    var _validation = validation;

    if (_validation.form[_options].value && _validation.form[_options].value !== '') {
      var _isValid = true;
      try {
        JSON.parse(_validation.form[_options].value);
      } catch (error) {
        _isValid = false;
      }

      if (!_isValid) {
        _validation.isValid = false;
        _validation.form[_options].error = true;
        _validation.form[_options].errorMessage = `JSON ${getDic("invalido")}`;
      }
    }

    return _validation;
  }

  const setTableFile = (file) => {
    if (file) {
      var _fileType = getFileExtension(file);
      if (_fileType === "XLSX") {
        setImporterTable(file);
      }
      else {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("arquivo-erro-tipo"));
        setImporterTable(null);
      }
    }
  }

  const returnImportFile = (res) => {
    if (!res.error) {
      setAlertOpen(true);
      setAlertTitle("");
      setAlertMessage(getDic("enviado-sucesso"));
      getQuestions();
    }
    else {
      setAlertOpen(true);
      setAlertTitle(`${getDic("erro")}`);
      setAlertMessage(res.errorMessage);
    }

    if (res.data && res.data.length > 0) {
      setImporterReturnTable(res.data);
    }

    setImporterTable(null);
    setExecuteScheduleImporter(false);
    var _btn = document.getElementById("btnImporterReturnTable");
    if (_btn) {
      setTimeout(() => {
        _btn.click();
      }, 500);
    }
    setTimeout(() => {
      setImporterReturnTable(null);
    }, 2000);
  }

  return (
    <Container background="#e8eced" align="center">
      <FormRow align="center" background="#e8eced" margin="0px">
        <div>
          <MenuButton history={props.history} />
          <BackButton history={props.history} color="#212121">
            {getDic("voltar").toUpperCase()}
          </BackButton>

        </div>

        <FormRow
          minHeight="60px"
          align="center"
          background="#e8eced"
          margin="0px"
        />
        <FormRow
          minHeight="60px"
          align="flex-end"
          background="#e8eced"
          margin="0px"
          paddingTop="5px"
        >
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <Button
              color="lightgray"
              textColor="black"
              onClick={() => setOpenModalImporter(true)}
            >
              {`${getDic("importador")}`}
            </Button>
          </div>
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <Button
              color="green"
              textColor="white"
              onClick={() => createNewQuestion()}
            >
              {`${getDic("cadastrar")} ${getDic("pergunta")}`}
            </Button>
          </div>
        </FormRow>

        <FormColumn align="center" background="#e8eced" margin="0px" padding="5px">
          {questionsList.length > 0 ? (
            <InfiniteScroll
              pageStart={0}
              dataLength={questionsList.length}
              height={"79vh"}
            >
              <GridRow backgroundColor="#d9d9d9">
                <GridCell width="10%" border top first>
                  <TextMedium>ID</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{`${getDic("tipo").toUpperCase()} ${getDic("pergunta").toUpperCase()}`}</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("categoria").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="30%" border top>
                  <TextMedium>{getDic("pergunta").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="30%" border top>
                  <TextMedium>{getDic("opcoes").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
                </GridCell>
              </GridRow>

              {questionsList.map((item, i) => {
                return (
                  <GridRow
                    key={item.id}
                    autoColor={i}
                  >
                    <GridCell width="10%" border first>
                      <TextMedium>{item.id}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border>
                      <TextMedium>{item.typeQuestion}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border>
                      <TextMedium>{item.category}</TextMedium>
                    </GridCell>
                    <GridCell width="30%" border>
                      <TextMedium>{item.question}</TextMedium>
                    </GridCell>
                    <GridCell width="30%" border>
                      <TextMedium>{item.valuesStr}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border>
                      <EditIcon onClick={() => editQuestion(item)} style={{ cursor: "pointer" }} />
                      <DeleteIcon
                        onClick={() => {
                          setChosenQuestion(item);
                          setDialogConfirmOpen(true);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </GridCell>
                  </GridRow>
                );
              })}
            </InfiniteScroll>
          ) : (
            <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
          )}
        </FormColumn>

        <Modal
          isVideo
          height="80vh"
          open={openModal}
          closeButtonClick={e => setOpenModal(false)}
          onClose={ret => {
            setOpenModal(false);
          }}
        >
          <Container background="#e8eced" align="left" height="100%" >
            <FormColumn padding="5px" margin="0px" background="#e8eced">
              {newQuestionForm.map((item, i) => {
                if (i === 0) {
                  return (
                    <>
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <TextMedium>
                          {item.value > 0 ? (
                            `${getDic("editar")} ${getDic("pergunta")}`
                          ) : (
                            `${getDic("nova")} ${getDic("pergunta")}`
                          )}
                        </TextMedium>
                      </FormRow>
                    </>
                  )
                }
                else {
                  if (item.type === "select" && item.values) {
                    return (
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <SelectList
                          required={item.required}
                          error={item.error}
                          errorMessage={item.errorMessage}
                          title={item.name}
                          width="100%"
                          value={item.value}
                          menuItens={item.values}
                          onChange={async (event) =>
                            onChangeHandler(i, event.target.value)
                          }
                          multiple={Array.isArray(item.value) ? true : false}
                        />
                      </FormRow>
                    );
                  }
                  else {
                    return (
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <InputFieldOutlined
                          key={item.id}
                          required={item.required}
                          maxLength={item.maxLength}
                          error={item.error}
                          errorMessage={item.errorMessage}
                          value={item.value}
                          title={item.name}
                          type={item.type}
                          onChange={(event) => {
                            onChangeHandler(i, event.target.value)
                          }}
                          multiline={item.name.toUpperCase() === "HTML" ? true : false}
                        />
                      </FormRow>
                    );
                  }
                }
              })}
              <FormRow align="center" paddingTop="7px">
                <Button
                  text={getDic("salvar")}
                  onClick={() => saveQuestion()}
                />
              </FormRow>
            </FormColumn>
          </Container>
        </Modal>

        <Modal
          isVideo
          height="80vh"
          open={openModalImporter}
          closeButtonClick={e => {
            setOpenModalImporter(false);
            setImporterTable(null);
          }}
          onClose={ret => {
            setOpenModalImporter(false);
            setImporterTable(null);
          }}
        >
          <Container background="#e8eced" align="center" height="100%" >
            <FormRow alignItems="center" direction="column">
              <div style={{ paddingTop: "10px", maxWidth: "400px" }}>
                <QuestionImporterExcel
                  exampleFile
                  button={
                    <Button color="green" textColor="white">
                      {getDic("gerar")} {getDic("planilha")} {getDic("importador")}
                    </Button>
                  }
                />
              </div>
              <div style={{ paddingTop: "10px", maxWidth: "400px" }}>
                <QuestionImporterExcel
                  data={questionsList}
                  button={
                    <Button color="blue" textColor="white">
                      {getDic("exportar")} {getDic("planilha")} {getDic("evento")}
                    </Button>
                  }
                />
              </div>
              <div style={{ maxWidth: "400px", paddingTop: "20px" }}>
                <InputFieldOutlined
                  id="uploadFile"
                  key="file"
                  type="file"
                  title={`${getDic("importar")} ${getDic("planilha")}`}
                  placeholder={getDic("planilha")}
                  onChange={e => setTableFile(e.target.files[0])}
                />
                <FormRow align="center">
                  <TextSmall>{getDic("formatos-permitidos")}: XLSX</TextSmall>
                </FormRow>
              </div>
              {importerTable && (
                <div style={{ maxWidth: "400px", paddingTop: "20px" }}>
                  <Button color="blue" textColor="white" onClick={() => setExecuteScheduleImporter(true)}>
                    {getDic("importar")} {getDic("planilha")}
                  </Button>
                </div>
              )}

              {/* Planilha de retorno do importador */}
              {importerReturnTable && (
                <div hidden>
                  <QuestionImporterExcel
                    hideElement
                    data={importerReturnTable}
                    button={
                      <button id="btnImporterReturnTable">X</button>
                    }
                  />
                </div>
              )}

              {executeQuestionImporter && (
                <QuestionImporter
                  file={importerTable}
                  questionsList={questionsList}
                  returnImportFile={res => {
                    returnImportFile(res);
                  }}
                />
              )}

            </FormRow>
          </Container>
        </Modal>

        <Alert
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          onClick={() => setAlertOpen(false)}
          title={alertTitle}
          message={alertMessage}
        />

        <Dialog
          open={dialogConfirmOpen}
          onClose={() => setDialogConfirmOpen(false)}
          title={getDic("atencao")}
          textButtonYes={getDic("inativar")}
          onClickYes={() => {
            if (dialogConfirmKey && dialogConfirmKey != "" && dialogConfirmKey.toUpperCase() === "SIM") {
              inactiveQuestion();
              setDialogConfirmOpen(false);
              setDialogConfirmKey("");
            }
            else {
              setDialogConfirmKeyErrorMessage(true);
              setTimeout(() => {
                setDialogConfirmKeyErrorMessage(false);
              }, 3000); // 1 minuto = 60000
            }
          }}
          textButtonNo={getDic("cancelar")}
          onClickNo={() => {
            setDialogConfirmOpen(false);
            setDialogConfirmKey("");
          }}
        >
          <FormRow>
            <TextSmall>
              {getDic("confirmacao-de-acao")}
            </TextSmall>
          </FormRow>
          <FormRow align="center" paddingTop="5px">
            <InputFieldOutlined
              key="password"
              value={dialogConfirmKey}
              onChange={event => setDialogConfirmKey(event.target.value)}
            />
          </FormRow>
          {dialogConfirmKeyErrorMessage ? (
            <TextSmall color="red">{getDic("erro")}</TextSmall>
          ) : (null)}
        </Dialog>

        <Loading open={openLoading} message={loadingMessage} />
      </FormRow>
    </Container>
  )
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(QuestionsRegisterPage);
