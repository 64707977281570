import React from 'react';

const GridCell = props => {
    const {
        children,
        width, //Valor int 1 a 12 correspondente ao espaço que a célula irá ocupar na linha
        border = false,
        first = false,
        top = false,
        align = "start",
    } = props;

    const styles = {
        width: width,
        borderLeft: border && first ? "1px solid gray" : "none",
        borderRight: border ? "1px solid gray" : "none",
        borderTop: border && top ? "1px solid gray" : "none",
        borderBottom: border ? "1px solid gray" : "none",
        textAlign: align,
    }

    return (
        <th style={styles}>
            {children}
        </th>
    )
};

export default GridCell;
