import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { getMessagesModeratorApi, setApprovedMessageApi, getChatSize } from '../services/chatService';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { animateScroll } from "react-scroll";
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

//Imports de components
import {
    Container,
    Loading,
    FormColumn,
    FormRow,
    ActivityIndicator,
    Link,
    MenuButton,
    TextMedium,
    BackButton,

    InputFieldOutlined,
    Checkbox,
    Alert,
    GridRow,
    GridCell,
    SelectList,
    Button
} from '../components';

var timeoutMessages;
var timeoutMessagesTime = 30000;
var timeout;

class ModeratorPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isScrolling: false,
            messages: [],
            chatConfigList: [],

            nameSearchValue: "",
            messageSearchValue: "",
            selectListValue: null,
            selectListOptions: [],
            approvedValue: null,
            approvedOptions: [],

            selectAll: false,

            pageIndex: 0,
            totalPageIndex: 0,
            noMoreOldMessages: false,

            //Alert control
            alertOpen: false,
            alertTitle: "",
            alertMessage: "",

            //Loading control
            loadingMessage: "",
            openLoading: false,
        }
    }

    async componentDidMount() {
        //Bloqueio caso o usuário não seja admin
        if (!this.props.user.isAdmin || !this.props.user.isStaff) {
            this.props.history.goBack();
        }

        var helpButton = document.getElementById("launcher");
        if (helpButton) {
            helpButton.style.visibility = 'hidden';
        }

        const chatConfigList = [];
        const selectListOptions = [];
        const def = {
            value: 0,
            text: getDic("todos"),
            isSchedule: false,
        }
        selectListOptions.push(def);

        this.props.config.forEach(item => {
            if (item.url && item.url.length > 1) {
                item.url.forEach(url => {
                    if (url === "CHAT") {
                        chatConfigList.push(item);

                        if (item.standId && item.standId > 0) {
                            const stand = this.props.stands.find(std => {
                                return std.id === item.standId;
                            });
                            const obj = {
                                value: item.id,
                                text: stand.name,
                                isSchedule: false,
                            }
                            selectListOptions.push(obj);
                        }
                        else if (item.scheduleId && item.scheduleId > 0) {
                            const schedule = this.props.schedules.find(scd => {
                                return scd.id === item.scheduleId;
                            });
                            const obj = {
                                value: item.id,
                                text: schedule.title,
                                isSchedule: true,
                            }
                            selectListOptions.push(obj);
                        }
                        else {
                            const obj = {
                                value: item.id,
                                text: item.tooltip,
                                isSchedule: false,
                            }
                            selectListOptions.push(obj);
                        }
                    }
                });
            }
        });

        const approvedOptions = [];
        const approved0 = {
            value: "all",
            text: getDic("todos")
        }
        approvedOptions.push(approved0);
        const approved1 = {
            value: "approved",
            text: getDic("aprovado")
        }
        approvedOptions.push(approved1);
        const approved2 = {
            value: "disapproved",
            text: getDic("desaprovado")
        }
        approvedOptions.push(approved2);

        this.setState({
            isLoading: false,
            chatConfigList: chatConfigList,
            selectListValue: selectListOptions[0].value,
            selectListOptions: selectListOptions,
            approvedValue: approvedOptions[0].value,
            approvedOptions: approvedOptions,
        });

        this.getMessages(true, true);

        await insertNewAccess(this.props.match.url, "", getSystemActions().accessPage);
    }

    async componentWillUnmount() {
        var helpButton = document.getElementById("launcher");
        if (helpButton) {
            helpButton.style.visibility = 'visible';
        }

        await insertNewAccess(this.props.match.url, "", getSystemActions().exitPage);
        // window.location.reload();
    }

    componentDidCatch(e) {
        var error = e.toString();

        if (error.includes("Error: Maximum update depth exceeded")) {
            window.location.reload();
        }
    }

    getMessages(newSearch = false, first = false, moreMessages = false) {

        this.setState({ openLoading: true, loadingMessage: getDic("carregando") });

        //Buscar mensagens a cada período de tempo
        clearTimeout(timeoutMessages);

        var { pageIndex, messages, nameSearchValue, messageSearchValue, selectListValue, noMoreOldMessages, approvedValue } = this.state;

        if (!moreMessages) {
            pageIndex = 0;
        }
        if (newSearch) {
            messages = [];
            noMoreOldMessages = false;
        }

        var searchObject = null;
        if (selectListValue !== 0) {
            searchObject = this.state.selectListOptions.find(opt => {
                return opt.value === selectListValue;
            });
        }

        var index = pageIndex + 1;
        var searchMoreMessages = false;
        var hasError = false;
        var messagesReceived = 0;
        var newMessages = 0;

        getMessagesModeratorApi(this.props.event.id, index, nameSearchValue, messageSearchValue, searchObject, approvedValue)
            .then(res => {
                if (res.messages) {

                    //Verifica quantas mensagens foram recebidas e quantas não estavam no array
                    //Caso o array inteiro não estiver na lista, buscar a próxima página até encontrar a ultima mensagem
                    messagesReceived = res.messages.length;

                    var finalMessages = messages;

                    res.messages.forEach(mes => {
                        //Verifica se a mensagem já existe, se não, inserir no array
                        const message = messages.find(mesState => {
                            return mesState.id === mes.id;
                        })
                        if (!message) {
                            newMessages++;
                            finalMessages.push(mes);
                        }
                    });

                    if (!first && messagesReceived === newMessages) {
                        searchMoreMessages = true;
                    }

                    //Ordenar mensagens
                    finalMessages = finalMessages.sort(function (a, b) {
                        return (a.insertDate > b.insertDate) ? 1 : ((b.insertDate > a.insertDate) ? -1 : 0);
                    });

                    this.setState({
                        messages: finalMessages,
                        pageIndex: index,
                        totalPageIndex: res.totalPages,
                        noMoreOldMessages: noMoreOldMessages,
                    });
                }
            })
            .catch(err => {
                console.log("Erro getMessagesApi", err);
                hasError = true;
            })
            .finally(() => {
                this.setState({ openLoading: false });

                if (!hasError && searchMoreMessages) {
                    this.getMessages(false, false, true);
                }
                else {
                    this.scrollToBottom();
                    timeoutMessages = setTimeout(() => {
                        this.getMessages();
                        // }, 10000); // 1 minuto = 60000
                    }, timeoutMessagesTime); // 1 minuto = 60000
                }
            })
    }

    getOldMessages(first = false) {

        this.setState({ openLoading: true, loadingMessage: getDic("carregando") });

        //Buscar mensagens a cada período de tempo
        clearTimeout(timeoutMessages);

        var { pageIndex, totalPageIndex, messages, nameSearchValue, messageSearchValue, selectListValue, approvedValue } = this.state;

        if (first) {
            pageIndex = 0;
        }

        var searchObject = null;
        if (selectListValue !== 0) {
            searchObject = this.state.selectListOptions.find(opt => {
                return opt.value === selectListValue;
            });
        }

        var index = pageIndex + 1;
        var searchMoreMessages = false;
        var hasError = false;
        var newMessages = 0;

        getMessagesModeratorApi(this.props.event.id, index, nameSearchValue, messageSearchValue, searchObject, approvedValue)
            .then(res => {
                if (res.messages) {

                    totalPageIndex = res.totalPages;

                    var finalMessages = messages;

                    res.messages.forEach(mes => {
                        //Verifica se a mensagem já existe, se não, inserir no array
                        const message = messages.find(mesState => {
                            return mesState.id === mes.id;
                        })
                        if (!message) {
                            newMessages++;
                            finalMessages.push(mes);
                        }
                    });

                    if (newMessages < getChatSize() && index < totalPageIndex) {
                        searchMoreMessages = true;
                    }

                    //Ordenar mensagens
                    finalMessages = finalMessages.sort(function (a, b) {
                        return (a.insertDate > b.insertDate) ? 1 : ((b.insertDate > a.insertDate) ? -1 : 0);
                    });

                    this.setState({ messages: finalMessages, pageIndex: index, totalPageIndex: res.totalPages });
                }
            })
            .catch(err => {
                console.log("Erro getMessagesApi", err);
                hasError = true;
            })
            .finally(() => {
                this.setState({ openLoading: false });

                if (index === totalPageIndex) {
                    this.setState({ noMoreOldMessages: true })
                }

                if (!hasError && searchMoreMessages) {
                    this.getOldMessages(false);
                }
                else {
                    timeoutMessages = setTimeout(() => {
                        this.getMessages();
                        // }, 10000); // 1 minuto = 60000
                    }, timeoutMessagesTime); // 1 minuto = 60000
                }
            })
    }

    async changeMessagesActive(messagesParam = []) {

        if (messagesParam.length > 0) {

            clearTimeout(timeoutMessages);

            this.setState({ openLoading: true, loadingMessage: getDic("salvando") });

            var messages = messagesParam;

            // messages.forEach((item, i) => {
            //     messages[i].approved = messages[i].approved === true ? false : true;
            //     messages[i].selected = false;
            // });

            var _count = 0;
            var _hasError = false;
            var list = this.state.messages;
            do {
                var _newMessage = messages[_count];
                if (!_hasError) {
                    _newMessage.approved = messages[_count].approved === true ? false : true;
                    this.setState({ loadingMessage: `${getDic("salvando")} ${_count + 1} de ${messages.length}` });
                    await setApprovedMessageApi(_newMessage)
                        .then(res => {
                            if (res.success) {
                                _newMessage.selected = false;

                                var index = list.indexOf(item => {
                                    return item.id === _newMessage.id;
                                });
                                list[index] = _newMessage;

                                this.setState({ messages: list });
                            }
                            else {
                                _hasError = true;
                            }
                        })
                        .catch(err => {
                            _hasError = true;
                        })
                        .finally(() => {
                            _count++;
                        });
                }
                else {
                    _count = messages.length;
                }
            } while (_count < messages.length);

            timeoutMessages = setTimeout(() => {
                this.getMessages();
                // }, 10000); // 1 minuto = 60000
            }, timeoutMessagesTime); // 1 minuto = 60000

            this.setState({ openLoading: false });

            if (_hasError) {
                this.setState({ alertTitle: getDic("erro"), alertMessage: getDic("enviado-erro"), alertOpen: true });
            }
        }
    }

    scrollToBottom() {
        if (!this.state.isScrolling) {
            animateScroll.scrollToBottom({
                containerId: "chatComponent"
            });
        }
    }

    scrollControl() {
        //Marca um período em que o scroll foi ativado
        //Para evitar que a página faça scroll enquanto o usuário estiver consultando mensagens anteriores
        clearTimeout(timeout);

        this.setState({ isScrolling: true });

        timeout = setTimeout(() => {
            this.setState({ isScrolling: false });
        }, 20000); // 1 minuto = 60000
    }

    onChangeHandler(field, value) {
        this.setState({
            [field]: value
        });
    }

    renderOldMessagesButton() {
        //Botão para carregar mais mensagens
        if (!this.state.noMoreOldMessages) {
            return (
                <div style={{ textAlign: "center" }}>
                    <Button
                        color="#e0e0e0"
                        textColor="black"
                        onClick={() => this.getOldMessages(true)}
                    >{getDic("mais")}</Button>
                </div>
            )
        }

    }

    render() {
        if (this.state.isLoading) {
            return (
                <Container background="#e8eced" align="center" >
                    <FormRow align="center">
                        <ActivityIndicator />
                    </FormRow>
                </Container>
            );
        }

        return (
            <Container align="center" background="#e8eced">
                <FormRow align="center" background="#e8eced" margin="0px">
                    <MenuButton history={this.props.history} />
                    <BackButton
                        history={this.props.history}
                        color="#212121"
                    >
                        {getDic("voltar").toUpperCase()}
                    </BackButton>


                    <table style={{ width: '100%' }}>
                        <tr>
                            <FormRow
                                minHeight="50px"
                                height="7vh"
                                align="center"
                                background="#e8eced"
                                margin="0px"
                            />
                            <FormRow
                                minHeight="60px"
                                height="9vh"
                                align="center"
                                background="#e8eced"
                                margin="0px"
                            >
                                <div style={{ paddingRight: "10px", maxWidth: "300px" }}>
                                    <InputFieldOutlined
                                        title={getDic("buscar") + " " + getDic("nome")}
                                        value={this.state.searchString}
                                        onChange={event => this.onChangeHandler("nameSearchValue", event.target.value)}
                                        onKeyPress={event => { if (event.key === 'Enter') { this.getMessages(true, true, false) } }}
                                        endAdornment={<Link color="gray"><SearchIcon /></Link>}
                                    />
                                </div>
                                <div style={{ paddingRight: "10px", maxWidth: "300px" }}>
                                    <InputFieldOutlined
                                        title={getDic("buscar") + " " + getDic("mensagem")}
                                        value={this.state.searchString}
                                        onChange={event => this.onChangeHandler("messageSearchValue", event.target.value)}
                                        onKeyPress={event => { if (event.key === 'Enter') { this.getMessages(true, true, false) } }}
                                        endAdornment={<Link color="gray"><SearchIcon /></Link>}
                                    />
                                </div>
                                <div style={{ paddingRight: "10px" }}>
                                    <SelectList
                                        title={getDic("buscar") + " " + getDic("programacao")}
                                        width="160px"
                                        maxWidth="160px"
                                        value={this.state.selectListValue}
                                        menuItens={this.state.selectListOptions}
                                        onChange={async event => {
                                            this.onChangeHandler("selectListValue", event.target.value)
                                        }}
                                    />
                                </div>
                            </FormRow>
                            <FormRow
                                minHeight="60px"
                                height="9vh"
                                align="center"
                                background="#e8eced"
                                margin="0px"
                                paddingTop="5px"
                                paddingBottom="5px"
                            >
                                <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
                                    <SelectList
                                        title={getDic("buscar") + " " + getDic("aprovado") + "/" + getDic("desaprovado")}
                                        width="240px"
                                        maxWidth="240px"
                                        value={this.state.approvedValue}
                                        menuItens={this.state.approvedOptions}
                                        onChange={async event => {
                                            this.onChangeHandler("approvedValue", event.target.value)
                                        }}
                                    />
                                </div>
                                <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
                                    <Button
                                        color="green"
                                        onClick={() => this.getMessages(true, true, false)}
                                    >
                                        {getDic("buscar")}
                                    </Button>
                                </div>
                                <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
                                    <Button
                                        onClick={() => {
                                            const messages = this.state.messages.filter(item => {
                                                return item.selected === true;
                                            });
                                            this.changeMessagesActive(messages);
                                        }}
                                    >
                                        {getDic("salvar")}
                                    </Button>
                                </div>
                            </FormRow>
                        </tr>
                        <tr>
                            <FormColumn align="center" background="#e8eced" margin="0px">
                                <div
                                    id="messages"
                                    ref={this.mesRef}
                                    style={{
                                        height: "71vh",
                                        backgroundColor: "transparent",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                >
                                    <div
                                        id={"chatComponent"}
                                        style={{
                                            padding: "5px",
                                            backgroundColor: "transparent",
                                            height: "100%",
                                            overflowX: "auto",
                                            overflowY: "auto"
                                        }}
                                        onScroll={() => this.scrollControl()}
                                    >
                                        {this.renderOldMessagesButton()}
                                        <GridRow backgroundColor="#d9d9d9">
                                            <GridCell width="5%" border top first >
                                                <Checkbox
                                                    checked={this.state.selectAll}
                                                    onChange={() => {
                                                        var { selectAll } = this.state;
                                                        //Caso o selectAll esteja true, a mudança será para false
                                                        if (selectAll) {
                                                            //Passando para false
                                                            var change = this.state.messages;
                                                            change.forEach((item, i) => {
                                                                change[i].selected = false;
                                                            });
                                                            this.setState({ messages: change, selectAll: false });
                                                        }
                                                        else {
                                                            //Passando para true
                                                            var change = this.state.messages;
                                                            change.forEach((item, i) => {
                                                                change[i].selected = true;
                                                            });
                                                            this.setState({ messages: change, selectAll: true });
                                                        }
                                                    }}
                                                />
                                            </GridCell>
                                            <GridCell width="10%" border top>
                                                <TextMedium>{getDic("data").toUpperCase()}</TextMedium>
                                            </GridCell>
                                            <GridCell width="15%" border top>
                                                <TextMedium>{getDic("programacao").toUpperCase()}</TextMedium>
                                            </GridCell>
                                            <GridCell width="20%" border top>
                                                <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                                            </GridCell>
                                            <GridCell width="40%" border top>
                                                <TextMedium>{getDic("mensagem").toUpperCase()}</TextMedium>
                                            </GridCell>
                                            <GridCell width="10%" border top>
                                                <TextMedium>STATUS</TextMedium>
                                            </GridCell>
                                        </GridRow>

                                        {this.state.messages.length ?
                                            (
                                                this.state.messages.map((message, i) => {

                                                    var configName = "";
                                                    if (message.scheduleId) {
                                                        var schedule = this.props.schedules.find(sched => sched.id === message.scheduleId);
                                                        if (schedule && schedule.title) {
                                                            configName = schedule.title;
                                                        }
                                                    }
                                                    else if (message.configId) {
                                                        var conf = this.props.config.find(conf => conf.id === message.configId);
                                                        if (conf && conf.standId) {
                                                            var stand = this.props.stands.find(stand => stand.id === conf.standId);
                                                            if (stand && stand.name) {
                                                                configName = stand.name;
                                                            }
                                                        }
                                                    }

                                                    return (
                                                        <GridRow
                                                            key={message.id}
                                                            backgroundColor={message.approved ? "white" : "#fcacac"}
                                                            autoColor={message.approved ? i : null}
                                                        >
                                                            <GridCell width="5%" border first>
                                                                <Checkbox
                                                                    checked={message.selected}
                                                                    onChange={() => {
                                                                        var change = this.state.messages;
                                                                        change[i].selected = change[i].selected === true ? false : true
                                                                        this.setState({ messages: change });
                                                                    }}
                                                                />
                                                            </GridCell>
                                                            <GridCell width="10%" border>
                                                                <TextMedium>{moment(message.insertDate).format('DD/MM/YYYY, HH:mm')}</TextMedium>
                                                            </GridCell>
                                                            <GridCell width="15%" border>
                                                                <TextMedium>{configName}</TextMedium>
                                                            </GridCell>
                                                            <GridCell width="20%" border>
                                                                <TextMedium>{message.guestName}</TextMedium>
                                                            </GridCell>
                                                            <GridCell width="40%" border>
                                                                <TextMedium>{message.message}</TextMedium>
                                                            </GridCell>
                                                            <GridCell width="10%" border>
                                                                {message.approved ? (
                                                                    <TextMedium>{getDic("aprovado")}</TextMedium>
                                                                ) : (
                                                                    <TextMedium>{getDic("desaprovado")}</TextMedium>
                                                                )}
                                                            </GridCell>
                                                        </GridRow>
                                                    )
                                                })
                                            ) : (
                                                <TextMedium>
                                                    {getDic("mensagens-nao-localizadas")}
                                                </TextMedium>
                                            )
                                        }
                                    </div>
                                </div>
                            </FormColumn>
                        </tr>
                    </table>

                    <Alert
                        open={this.state.alertOpen}
                        onClose={() => this.setState({ alertOpen: false })}
                        onClick={() => this.setState({ alertOpen: false })}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                    />

                    <Loading open={this.state.openLoading} message={this.state.loadingMessage} />
                </FormRow>
            </Container>
        )
    }
}

function mapStateToProps(state) {

    return {
        event: state.event,
        user: state.user,
        config: state.config,
        schedules: state.schedules,
        stands: state.stands,
    }
}

export default connect(mapStateToProps, null)(ModeratorPage)
