export const SET_PAVILIONS = 'SET_PAVILIONS';
const setPavilionsLocal = pavilions => ({
    type: SET_PAVILIONS,
    pavilions
});
export function setPavilions(pavilions) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setPavilionsLocal(pavilions));
            resolve(true);
        });
    };
}

export const RESET_PAVILIONS = 'RESET_PAVILIONS';
const resetPavilionsLocal = () => ({
    type: RESET_PAVILIONS
});
export function resetPavilions() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetPavilionsLocal());
            resolve(true);
        });
    };
}
