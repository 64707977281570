import React from 'react';
import Blink from 'react-blink-text';

const TextTitle = ({
    children,
    fontWeight = 'bold',
    color = "black",
    onClick,
    blink = false
}) => {

    const styles = {
        text: {
            fontFamily: "verdana",
            fontWeight: fontWeight,
            fontSize: "30px",
        }
    };

    if (!blink) {
        return (
            <div style={styles.text} onClick={onClick}>
                {children}
            </div>
        )
    }
    else {
        return (
            <div style={styles.text} onClick={onClick}>
                <Blink color={color} text={children} fontSize='30px'>
                </Blink>
            </div>
        );
    }
};

export default TextTitle;
