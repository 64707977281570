import { store } from '../store';
import { createFormControlModel } from '../models/formControlModel';
import { getDic } from '../assets/i18n/dictionary';

export function createLevelModel(
    id = 0,
    eventId = 0,
    name = "",
    initialValue = 0,
    finalValue = 0,
) {
    const object = {
        id: id,
        eventId: eventId,
        name: name,
        initialValue: initialValue,
        finalValue: finalValue,
    }

    return object;
}

export function convertLevelObjectToForm(object) {
    const _form = [];

    var _index = 0;

    for (var key in object) {
        if (object.hasOwnProperty(key)) {

            var _hasForm = false;
            var _type = "text";
            var _options = null;
            var _required = true;
            var _name = "";
            var _value = object[key] ? object[key].toString() : null;

            switch (key.toString()) {
                case "id":
                    _hasForm = true;
                    _name = "id";
                    _required = true;
                    _value = parseInt(object[key]);
                    break;
                case "name":
                    _hasForm = true;
                    _name = getDic("nome");
                    _required = true;
                    break;
                case "initialValue":
                    _hasForm = true;
                    _name = `${getDic("valor")} ${getDic("inicio")}`;
                    _required = true;
                    _type = "number";
                    break;
                case "finalValue":
                    _hasForm = true;
                    _name = `${getDic("valor")} ${getDic("fim")}`;
                    _required = true;
                    _type = "number";
                    break;
                default:
                    break;
            }

            if (_hasForm) {
                const _obj = createFormControlModel(
                    _index,
                    _name,
                    _value,
                    _type,
                    null,
                    null,
                    _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                    _required,
                    null,
                    null,
                    null,
                    key
                );
                _form.push(_obj);
                _index++;
            }
        }
    }
    return _form;
}

export function convertFormToLevelObject(form) {
    var _object = createLevelModel();
    form.forEach(item => {
        var _value;

        switch (item.dbReference) {
            case "id":
                _value = parseInt(item.value);
                break;
            case "eventId":
                _value = store.getState().event.id;
                break;
            case "name":
                _value = item.value ? item.value.toString() : null;
                break;
            case "initialValue":
                _value = item.value ? item.value.toString() : null;
                break;
            case "finalValue":
                _value = item.value ? item.value.toString() : null;
                break;
            default:
                break;
        }
        _object[item.dbReference] = _value;
    });
    return _object;
}
