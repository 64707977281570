import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { convertConfigUrlListInFilesList } from '../services/fileService';
import { getUrlFromFileObject } from "../models/fileModel";
import { getConfigListByPage } from "../services/configService";
import Download from '@material-ui/icons/GetApp';
import "../scss/BootStrap/index.scss";
import Masonry from "react-masonry-component";

//Imports de components
import {
  MenuButton,
  BackButton,
  PdfThumbnail,
  Alert,
  Loading,
  Container,
  FormRow,
  FormColumn,
  Card,
  TextMedium,
  InfiniteScroll,
  IframePlayer,
  SelectList,
  Modal,
  ActivityIndicator,
  FileIcons,
  Button
} from '../components';
import { getMessagesApi } from '../services/chatService';

const InteegraflixPage = (props) => {
  const [state, setState] = React.useState(true);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(true);

  const filesList = React.useRef([]);
  const externalFilesList = React.useRef([]);
  const filterFilesList = React.useRef([]);
  const [categoriesList, setCategoriesList] = React.useState([]);
  const [categoriesListValue, setCategoriesListValue] = React.useState(null);

  const itemIndex = React.useRef(0);
  const hasMore = React.useRef(true);

  const [imageBackgroundUrl, setImageBackgroundUrl] = React.useState(null);
  const [colorBackground, setColorBackground] = React.useState("#1c1b1a");
  const [image001, setImage001] = React.useState(null);
  const [tittle001, setTittle001] = React.useState({
    text: '',
    color: null,
    size: null,
  });
  const [cardConfig, setCardConfig] = React.useState({
    colorBackground: "white",
    textColor: "black",
  });
  const imageFormats = ["JPG", "JPEG", "GIF", "BMP", "PNG", "RAW", "WEBP", "JFIF"];
  const videoFormats = ["MP4", "WMV", "WMA", "ASF", "MOV", "FLV", "RM", "RMVB", "MKV", "MKS",];
  const formatsToShow = [
    //Vídeo / Audio
    "MP4",
    "WMV",
    "WMA",
    "ASF",
    "MOV",
    "FLV",
    "RM",
    "RMVB",
    "MKV",
    "MKS",
    "3GPP",
    // "PDF",
    "AVI",
    "JPG", //Imagens
    "JPEG",
    "GIF",
    "BMP",
    "PNG",
    "RAW",
    "WEBP",
    "JFIF",
  ];
  const [sizeObject, setSizeObject] = React.useState({
    width: window.innerWidth,
    cardWidth: 400,
    modalImageHeight: window.innerHeight * 0.8,
    modalImageWidth: window.innerWidth * 0.8,
  });
  const [openModalImage, setOpenModalImage] = React.useState(false);
  const [modalImage, setModalImage] = React.useState({
    img: null,
    height: null,
    width: null,
  });
  const [openModalPdf, setOpenModalPdf] = React.useState(false);
  const [modalPdfUrl, setModalPdfUrl] = React.useState();

  React.useEffect(() => {
    getPageData();

    if (!props.isModal) {
      insertNewAccess(props.match.url, "", getSystemActions().accessPage);
    }

    window.addEventListener("resize", function (event) {
      resizeList();
    })

    return () => {
      if (!props.isModal) {
        insertNewAccess(props.match.url, "", getSystemActions().exitPage);
      }
    }
  }, []);

  const getPageData = async () => {

    //Aplica configurações
    const _url = !props.isModal ? props.match.url : `/${props.event.id}/inteegraflix`;
    const configList = await getConfigListByPage(_url);

    var _categories = [];

    configList.find((item) => {
      if (item.codeScreen === "IMAGE-001") {
        setImageBackgroundUrl(item.url[0]);
        setColorBackground("transparent");
      }
      if (item.codeScreen === "IMAGE-002") {
        setImage001(item.url[0]);
      }
      if (item.codeScreen === "BACKGROUND-COLOR") {
        setColorBackground(item.url[0]);
      }
      if (item.codeScreen === "TITTLE-001") {
        var newObj = tittle001;
        newObj.text = item.tooltip;
        if (item.url.length > 0) {
          item.url.forEach(url => {
            var conf = url.split(":");
            newObj[conf[0]] = conf[1];
          });
        }
        setTittle001(newObj);
      }
      if (item.codeScreen === "ELEMENT-001") {
        var newObj = cardConfig;
        if (item.url.length > 0) {
          item.url.forEach(url => {
            var conf = url.split(":");
            newObj[conf[0]] = conf[1];
          });
        }
        setCardConfig(newObj);
      }
      if (item.codeScreen === "ELEMENT-002") {
        if (item.url.length > 0) {
          item.url.forEach(url => {
            const _cat = {
              text: url,
              value: url,
            }
            _categories.push(_cat);
          });
        }
        setCardConfig(newObj);
      }
      if (item.codeScreen === "ELEMENT-003") {
        var _files = [];
        convertConfigUrlListInFilesList(item)
          .then(res => {
            _files = res;
          })
          .finally(() => {
            externalFilesList.current = _files;
          });
      }
      return null;
    });

    //Verifica se possui categoria default
    var _category = null;
    if (props.modalPath && props.modalPath.includes("category=")) {
      var _modalPathArray = props.modalPath.split("?");
      if (_modalPathArray.length > 1) {
        var _value = _modalPathArray[1].split("=");
        _value = _value[1].replace(/%20/g, " "); //Replace all
        setCategoriesListValue(_value);
        _category = _value;
      }
    }
    else {
      if (_categories.length > 0) {
        setCategoriesListValue(_categories[0].value)
        _category = _categories[0].value;
      }
      setCategoriesList(_categories);
    }

    setTimeout(() => {
      getFilesList(true, _category);
    }, 1000);
  }

  const getFilesList = (newSearch = false, category) => {

    if (newSearch) {
      filesList.current = [];
      hasMore.current = true;
      itemIndex.current = 0;
    }
    //Fazer a busca apenas se houver mais dados
    if (hasMore.current && itemIndex.current < externalFilesList.current.length) {
      //Criar lista filtrada
      if (newSearch) {
        var _category = category ? category : categoriesListValue;
        if (_category && _category !== "") {
          filterFilesList.current = externalFilesList.current.filter(item => {
            return item.category === _category;
          });
        }
        else {
          filterFilesList.current = externalFilesList.current;
        }
      }

      const _filesList = filesList.current;
      for (let i = itemIndex.current; i < (itemIndex.current + 50); i++) {
        const _newItem = filterFilesList.current[i];
        if (_newItem) {
          if (_newItem.defaultUrl && _newItem.defaultUrl !== "") {
            _filesList.push(_newItem);
          }
        }
      }

      itemIndex.current = itemIndex.current + 51;
      filesList.current = _filesList;

      if (itemIndex.current > filterFilesList.current.length) {
        hasMore.current = false;
      }

      setOpenLoading(false);
      setIsLoading(false);

      resizeList();
    }
    else if (hasMore.current) {
      hasMore.current = false;
    }
    else {
      return;
    }
  }

  const onLoadFile = (index) => {
    var change = filesList.current;
    change[index].isLoaded = true;
    filesList.current = change;
    setState(state === true ? false : true);
  }

  const downloadFile = (file) => {
    let a = document.createElement('a');
    a.href = getUrlFromFileObject(file);
    a.download = file.name + "." + file.extension;
    a.target = "_blank";
    a.click();
  }

  const resizeList = () => {
    var _cardWidth = window.innerWidth < 400 ? window.innerWidth : 400;
    var _wallWidth = props.isModal ? window.innerWidth * 0.8 : window.innerWidth;
    setSizeObject({
      width: _wallWidth,
      cardWidth: _cardWidth,
      modalImageHeight: window.innerHeight * 0.8,
    });
  }

  const configOpenModalImage = (url) => {
    setModalImage({
      img: url,
      height: "auto",
      width: "auto",
    });
    setOpenModalImage(true);
  }

  const resizeOpenModalImage = (img, url) => {
    var _propWidth = img.target.offsetWidth * 100 / img.target.offsetHeight;

    var _obj = {
      img: url,
      height: img.target.offsetHeight >= sizeObject.modalImageHeight ? sizeObject.modalImageHeight : img.target.offsetHeight,
      width: img.target.offsetWidth >= (window.innerWidth * 0.8) ? (window.innerWidth * 0.8) : img.target.offsetWidth,
    }

    //Pegar qual a proporção da largura em relação a altura
    _obj.width = _obj.height * (_propWidth / 100);

    setModalImage(_obj);
  }

  const inserClickEventToAccess = (url) => {
    var page = window.location.pathname;

    insertNewAccess(
      page,
      props.chosenConfig && props.chosenConfig.id ? props.chosenConfig.id.toString() : "",
      getSystemActions().click,
      "Inteegraflix",
      url
    );
  }

  const renderCardContent = (item, index) => {
    const _url = getUrlFromFileObject(item);
    if (formatsToShow.includes(item.extension ? item.extension.toUpperCase() : "")) {
      if (imageFormats.includes(item.extension ? item.extension.toUpperCase() : "")) {
        return (
          <>
            <img
              src={_url}
              width="100%"
              height="auto"
              style={item.isLoaded ? {} : { display: 'none' }}
              onLoad={() => onLoadFile(index)}
              onClick={() => {
                configOpenModalImage(_url);
                inserClickEventToAccess(_url);
              }}
            />
            {!item.isLoaded && (<ActivityIndicator />)}
          </>
        )
      }
      else if (videoFormats.includes(item.extension ? item.extension.toUpperCase() : "")) {
        return (
          <video onPlay={() => inserClickEventToAccess(_url)} width="390" height="220" controls>
            <source src={_url} />
          </video>
        )
      }
      else {
        return (
          <IframePlayer
            autoplay={false}
            height="200px"
            src={_url}
            onClick={() => inserClickEventToAccess(_url)}
          />
        )
      }
    }
    else if (item.extension && item.extension.toUpperCase() === "PDF") {
      return (
        <PdfThumbnail
          url={_url}
          onLoadSuccess={() => setState(!state)}
          onClick={() => {
            setOpenModalPdf(true);
            setModalPdfUrl(_url);
            inserClickEventToAccess(_url);
          }}
        />
      )
    }
    else {
      return (
        <div style={{ paddingTop: 40, paddingBottom: 40, textAlign: "center", cursor: 'pointer' }}>
          <FileIcons
            extension={item.extension}
            onClick={() => {
              downloadFile(item);
              inserClickEventToAccess(_url);
            }}
          />
        </div>
      )
    }
  }

  if (isLoading) {
    return (
      <Container
        backgroundImage={imageBackgroundUrl}
        background={colorBackground}
        backgroundSize="100% 100%"
        height={props.isModal ? "100%" : "100vh"}
      >
        <FormRow align="center">
          <ActivityIndicator />
        </FormRow>
      </Container>
    );
  }
  return (
    <Container
      backgroundImage={imageBackgroundUrl}
      background={colorBackground}
      backgroundSize="100% 100%"
      height={props.isModal ? "100%" : "100vh"}
    >
      <div style={{ position: "relative", width: "100%", height: "100%", overflow: "auto" }}>
        <FormRow background={colorBackground} margin="0px" align="center">
          {!props.isModal && (
            <>
              <div>
                <MenuButton history={props.history} />
                <BackButton history={props.history} color="#212121">
                  {getDic("voltar").toUpperCase()}
                </BackButton>

              </div>
              <FormRow
                minHeight="60px"
                align="center"
                background={colorBackground}
                margin="0px"
              />
            </>
          )}

          <FormColumn align="center" background={colorBackground} margin="0px" padding="0px">
            <FormRow align="center" background={colorBackground} margin="0px">
              <img src={image001} width="200px" />
            </FormRow>
            {categoriesList.length > 1 && (
              <FormRow align="flex-end" background={colorBackground} margin="0px" minHeight="50px" paddingTop="5px">
                <div style={{ display: "flex", paddingRight: "22px", maxWidth: "400px" }}>
                  <SelectList
                    title={getDic("categoria")}
                    width="200px"
                    value={categoriesListValue}
                    menuItens={categoriesList}
                    onChange={async e => {
                      setCategoriesListValue(e.target.value);
                      getFilesList(true, e.target.value);
                    }}
                  />
                </div>
              </FormRow>
            )}
            <FormRow align="center" background={colorBackground} margin="0px">
              <TextMedium
                fontSize={tittle001.size}
                color={tittle001.color}
              >
                {tittle001.text}
              </TextMedium>
            </FormRow>
            {filesList.current.length > 0 ? (
              <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
                paddingBottom: "5px"
              }}>
                <FormRow align="center" margin="0px">
                  <Masonry
                    className={"grid"}
                    elementType={"div"}
                    options={{
                      fitWidth: true,
                      columnWidth: sizeObject.cardWidth,
                      gutter: 5,
                    }}
                    disableImagesLoaded={false}
                    updateOnEachImageLoad={false}
                  >
                    {filesList.current.map((item, i) => {
                      return (
                        <div
                          key={item.id}
                          style={{
                            width: "100%",
                            maxWidth: sizeObject.cardWidth,
                            paddingTop: 5,
                          }}
                        >

                          <Card backgroundColor={cardConfig.colorBackground}>
                            {renderCardContent(item, i)}
                            <FormRow align="center" paddingBottom="5px">
                              <TextMedium color={cardConfig.textColor}>
                                {item.name}
                              </TextMedium>
                            </FormRow>
                          </Card>

                        </div>
                      );
                    })}
                  </Masonry>
                </FormRow>
                {hasMore.current && (
                  <FormRow align="center" margin="0px" paddingTop="10px">
                    <Button
                      color="#e0e0e0"
                      textColor="black"
                      onClick={() => getFilesList()}
                    >{getDic("mais")}</Button>
                  </FormRow>
                )}
              </div>
            ) : (
              <FormRow align="center" margin="0px" paddingTop="10px">
                <TextMedium color="white">{getDic("pesquisa-sem-resultado")}</TextMedium>
              </FormRow>
            )}
          </FormColumn>

          <Modal
            isVideo
            height={sizeObject.modalImageHeight}
            open={openModalImage}
            closeButtonClick={(e) => {
              setOpenModalImage(false);
              setModalImage({ img: null, height: "auto", width: "auto" });
            }}
            onClose={(ret) => {
              setOpenModalImage(false);
              setModalImage({ img: null, height: "auto", width: "auto" });
            }}
          >
            <div style={{ height: sizeObject.modalImageHeight, width: sizeObject.modalImageWidth, textAlign: "center" }}>
              <img
                src={modalImage.img}
                width={modalImage.width}
                height={modalImage.height}
                onLoad={img => resizeOpenModalImage(img, modalImage.img)}
              />
            </div>
          </Modal>

          <Modal
            height={sizeObject.modalImageHeight}
            open={openModalPdf}
            closeButtonClick={(e) => {
              setOpenModalPdf(false);
            }}
            onClose={(ret) => {
              setOpenModalPdf(false);
            }}
          >
            <IframePlayer
              autoplay={false}
              src={modalPdfUrl}
            />
          </Modal>

          <Alert
            open={alertOpen}
            onClose={() => setAlertOpen(false)}
            onClick={() => setAlertOpen(false)}
            title={alertTitle}
            message={alertMessage}
          />

          <Loading open={openLoading} message={loadingMessage} />
        </FormRow>
      </div>
    </Container >
  )
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
    chosenConfig: state.chosenConfig,
  }
}

export default connect(mapStateToProps, null)(InteegraflixPage);
