import { getDic } from '../assets/i18n/dictionary';

export function createTableOptions(dataList, columnText) {

    var _multiDataSet = [
        {
            columns: [
                {
                    title: `ID`,
                    style: {
                        font: { bold: true },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: "9e9e9e" }
                        },
                        border: getBorderObject()
                    }
                },
                {
                    title: columnText,
                    style: {
                        font: { bold: true },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: "9e9e9e" }
                        },
                        border: getBorderObject()
                    }
                },
            ],
            data: [],
        }
    ];

    const _rows = [];
    if (dataList.length > 0) {
        dataList.forEach(item => {
            const _row = [];
            const _obj1 = {
                value: item.value.toString(),
                style: {
                    border: getBorderObject()
                }
            };
            _row.push(_obj1);

            const _obj2 = {
                value: item.text.toString(),
                style: {
                    border: getBorderObject()
                }
            };
            _row.push(_obj2);

            _rows.push(_row);
        });
    }
    else {
        const _row = [];
        const _obj1 = {
            value: "Não há dados cadastrados",
            style: {
                border: getBorderObject()
            }
        };
        _row.push(_obj1);

        const _obj2 = {
            value: "",
            style: {
                border: getBorderObject()
            }
        };
        _row.push(_obj2);

        _rows.push(_row);
    }

    _multiDataSet[0].data = _rows;

    return _multiDataSet;
}

export function createTableBase(tableName = "", formControlList) {
    var _multiDataSet = [
        {
            columns: [{ title: tableName }],
            data: [],
        },
        {
            ySteps: 1, // Pular uma linha
            columns: [{ title: `${getDic("linha")} ${getDic("controle")} - ${getDic("nao")} ${getDic("deletar")}` }],
            data: [],
        },
        {
            columns: [],
            data: []
        },
        {
            columns: [],
            data: []
        }
    ];

    const _columns1 = [];
    const _columns2 = [];
    formControlList.forEach(item => {
        const _obj1 = {
            title: `${item.dbReference}`,
            style: {
                font: { bold: true },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "9e9e9e" }
                },
                border: getBorderObject()
            }
        }
        _columns1.push(_obj1);

        const _obj2 = {
            title: `${item.name.toUpperCase()}${item.required ? " *" : ""}`,
            style: {
                font: { bold: true },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "dedede" }
                },
                border: getBorderObject()
            }
        }
        _columns2.push(_obj2);
    });

    _multiDataSet[2].columns = _columns1;
    _multiDataSet[3].columns = _columns2;

    return _multiDataSet;
}

export function createExampleLine(formControlList) {
    const _rowExample = [];
    if (formControlList) {
        formControlList.forEach(item => {
            const _data = {
                value: "",
                style: {
                    border: getBorderObject()
                }
            };
            _rowExample.push(_data);
        });
    }
    return _rowExample;
}

export function getBorderObject() {
    return {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" }
    }
}
