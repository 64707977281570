import React, { useStyles } from "react";
import Popover from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import EventNoteIcon from "@material-ui/icons/EventNote";
import Link from "@material-ui/core/Link";

import "../../scss/ScheduleStyle.scss";

const CalendarWidget = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const calendar = !props.configList
    ? false
    : props.configList.multiConfig.calendar;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return !calendar ? (
    <div />
  ) : (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Link>
        <EventNoteIcon
          id={props.id}
          style={{
            color: props.item.styles.textColorCard,
            marginRight: 10,
          }}
          onClick={handleClick}
        />
        <Popover id={props.id} open={open} anchorEl={anchorEl} transition>
          <div
            style={{
              background: "white",
              borderRadius: 2,
              border: "1px solid #b7b7b7",
            }}
          >
            <div
              className="calendar-item"
              style={{
                borderBottom: "1px solid #e2e2e2",
              }}
            >
              <div style={{ padding: 10 }}>
                <a
                  target="_blank"
                  style={{ textDecoration: "none", color: "#4a4a4a" }}
                  href={`http://www.google.com/calendar/render?action=TEMPLATE&text=${
                    props.item.title
                  }&dates=${
                    props.item.startDate.replace(/[-:]/g, "").split("T")[0]
                  }T${props.item.startTime.replace(":", "")}00/${
                    props.item.startDate.replace(/[-:]/g, "").split("T")[0]
                  }T${props.item.endTime.replace(
                    ":",
                    ""
                  )}00&details=&location=Online&output=xml`}
                >
                  <img src="./gmail.png" style={{ marginRight: 5 }} />
                  <span>Google Calendar</span>
                </a>
              </div>
            </div>
            <div
              className="calendar-item"
              style={{
                borderBottom: "1px solid #e2e2e2",
              }}
            >
              <div style={{ padding: 10 }}>
                <a
                  target="_blank"
                  style={{ textDecoration: "none", color: "#4a4a4a" }}
                  href={`https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&subject=${
                    props.item.title
                  }&startdt=${props.item.startDate.split("T")[0]}-${
                    props.item.startTime
                  }&enddt=${props.item.startDate.split("T")[0]}-${
                    props.item.endTime
                  }&body=&location=Online`}
                >
                  <img src="./outlook.png" style={{ marginRight: 5 }} />
                  <span>Outlook Calendar</span>
                </a>
              </div>
            </div>
            <div className="calendar-item">
              <div style={{ padding: 10 }}>
                <a
                  target="_blank"
                  style={{ textDecoration: "none", color: "#4a4a4a" }}
                  href={`https://calendar.yahoo.com/?v=60&view=d&type=20&title=${
                    props.item.title
                  }&st=${
                    props.item.startDate.replace(/[-:]/g, "").split("T")[0]
                  }T${props.item.startTime.replace(":", "")}00&et=${
                    props.item.startDate.replace(/[-:]/g, "").split("T")[0]
                  }T${props.item.endTime.replace(
                    ":",
                    ""
                  )}00&desc=&in_loc=Online`}
                >
                  <img src="./yahoo.png" style={{ marginRight: 5 }} />
                  <span>Yahoo! Calendar</span>
                </a>
              </div>
            </div>
          </div>
        </Popover>
      </Link>
    </ClickAwayListener>
  );
};

export default CalendarWidget;
