import React from "react";
import { store } from "../../../../store";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { firestore } from "../../../../utils/firebase";
import {
  useCollectionData,
  useCollectionDataOnce
} from "react-firebase-hooks/firestore";
import { animateScroll } from "react-scroll";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import OnlineChecker from "../OnlineChecker";

const ScrollRecents = props => {
  // Collections Querys
  const privateChatRef = firestore.collection(
    `network/${props.event.id}/messages/${props.user.id}/private`
  );
  const queryPrivateChat = privateChatRef.orderBy("tempTime", "desc").limit(10);
  const [privateChat] = useCollectionData(queryPrivateChat, { idField: "id" });

  const [privateChatList, setPrivateChatList] = React.useState([]);
  const [lastItemTime, setLastItemTime] = React.useState();
  const [hasMore, setHasMore] = React.useState(true);
  const [loadingMore, setLoadingMore] = React.useState(false);

  const loadMorePrivateList = ld => {
    setLoadingMore(true);
    setTimeout(() => {
      const localQuery = privateChatRef
        .orderBy("tempTime", "desc")
        .startAfter(lastItemTime)
        .limit(10);
      localQuery.get({ idField: "id" }).then(result => {
        let tempList = [];
        if (!result.empty) {
          setHasMore(true);
          result.forEach(item => {
            tempList.push({
              ...item.data(),
              id: item.id
            });
            setLastItemTime(item);
          });
          setPrivateChatList([...privateChatList, ...tempList]);
          setLoadingMore(false);
        } else {
          setHasMore(false);
          setLoadingMore(false);
        }
      });
    }, 3000);
  };

  const loadPrivateList = ld => {
    const localQuery = privateChatRef.orderBy("tempTime", "desc").limit(10);
    localQuery.get().then(result => {
      let tempList = [];
      result.forEach(item => {
        tempList.push({
          ...item.data(),
          id: item.id
        });
        setLastItemTime(item);
      });
      setPrivateChatList([...tempList]);
      setHasMore(true);
    });
  };

  const setAlreadyRead = async id => {
    console.log("removendo ", id, " do id ", props.user.id);
    await firestore
      .collection(`network/${props.event.id}/messages`)
      .doc(`${props.user.id}`)
      .update(
        {
          pendingMsgs: firebase.firestore.FieldValue.arrayRemove(parseInt(id))
        },
        { merge: true }
      );
  };

  React.useEffect(() => {
    loadPrivateList();
  }, [privateChat]);
  const documentView = window.screen.height - 223;
  const statusBar = window.screen.height - window.innerHeight;
  return (
    <InfiniteScroll
      dataLength={privateChatList.length}
      next={() => loadMorePrivateList()}
      hasMore={hasMore}
      height={
        props.fullScreen == "80vh"
          ? props.heightParent - 193
          : documentView - statusBar
      }
      scrollThreshold="1px"
      style={{
        transition: "2s"
      }}
    >
      {privateChatList &&
        privateChatList.map((e, index) => (
          <li
            className={props.currentTargetMsg.id == e.guestId ? "active" : ""}
            key={index}
            index={index}
          >
            <a
              onClick={() => {
                setAlreadyRead(e.id);
                props.setMessageArea(true);
                props.setCurrentTargetMsg({
                  id: e.id,
                  image: e.photoURL,
                  institution: "",
                  name: e.user,
                  position: "",
                  message: e.message,
                  lastMsg: e.lastMsg
                });
              }}
            >
              <div className="media">
                <div className="align-self-center me-3">
                  <OnlineChecker event={props.event} id={e.id} />
                  {/* {
                                e.lastMsg && e.lastMsg.toDate().setMinutes(
                                    e.lastMsg.toDate().getMinutes() + 10
                                    ) >= Date.now() ?
                                    <i className="mdi mdi-circle text-success font-size-10" />
                                    :
                                    <i className="mdi mdi-circle text-danger font-size-10" />

                            } */}
                </div>
                <div
                  className="align-self-center me-3"
                  style={{
                    height: 35,
                    width: 35,
                    marginRight: -7,
                    marginLeft: -11
                  }}
                >
                  {e.photoURL ? (
                    <img
                      src={e.photoURL}
                      className="rounded-circle avatar-xs"
                      alt
                    />
                  ) : (
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                      {e.user[0].toUpperCase()}
                    </span>
                  )}
                </div>
                <div className="overflow-hidden media-body">
                  <h5 className="text-truncate font-size-14 mb-1">{e.user}</h5>
                  <p
                    className="text-truncate mb-0"
                    style={{ color: "#9e9e9e", fontStyle: "italic" }}
                  >
                    {e.message.length > 20
                      ? `${e.message.substring(0, 20)}...`
                      : e.message}
                  </p>
                </div>
                <div className="font-size-11">
                  {e.lastMsg &&
                    moment(e.lastMsg.toDate())
                      .locale(
                        store.getState().lang.lang === "sp"
                          ? "es"
                          : store.getState().lang.lang
                      )
                      .fromNow()}
                </div>
              </div>
            </a>
          </li>
        ))}
      <li
        style={{
          height: 50,
          textAlign: "center"
        }}
      >
        <CircularProgress
          color="#337ab7"
          style={{
            width: loadingMore ? 20 : 0,
            height: loadingMore ? 20 : 0,
            transition: "0.5s"
          }}
        />
      </li>
    </InfiniteScroll>
  );
};

export default ScrollRecents;
