export const SET_CHOSEN_CAMPAIGN = 'SET_CHOSEN_CAMPAIGN';
const setChosenCampaignLocal = campaign => ({
    type: SET_CHOSEN_CAMPAIGN,
    campaign
});
export function setChosenCampaign(campaign) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setChosenCampaignLocal(campaign));
            resolve(true);
        });
    };
}

export const RESET_CHOSEN_CAMPAIGN = 'RESET_CHOSEN_CAMPAIGN';
const resetChosenCampaignLocal = () => ({
    type: RESET_CHOSEN_CAMPAIGN
});
export function resetChosenCampaign() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetChosenCampaignLocal());
            resolve(true);
        });
    };
}
