import { store } from "../store";

export function changeUrlTags(urls) {
  const urlArray = [];

  urls.forEach((url) => {
    var newUrl = url;

    if (url.includes("{EVENTOID}") || url.includes("{CONTRATOID}")) {
      const event = store.getState().event;
      newUrl = newUrl.replace(/{EVENTOID}/g, event.id.toString()); //Replace all
      newUrl = newUrl.replace(/{CONTRATOID}/g, event.contractId.toString());
    }
    if (
      url.includes("{PAXID}") ||
      url.includes("{GRUPOID}") ||
      url.includes("{EMAIL}") ||
      url.includes("{NOME}") ||
      url.includes("{CODINTERNO}") ||
      url.includes("{CODIGOINTERNO}") ||
      url.includes("{EMPRESA}") ||
      url.includes("{IDIOMA}")
    ) {
      const user = store.getState().user;
      try {
        newUrl = newUrl.replace(/{PAXID}/g, user.id.toString());
        newUrl = newUrl.replace(/{NOME}/g, user.name ? user.name.trim() : "");
        newUrl = newUrl.replace(
          /{GRUPOID}/g,
          user.groupId ? user.groupId.toString() : ""
        );
        newUrl = newUrl.replace(
          /{EMAIL}/g,
          user.email ? user.email.toString() : ""
        );
        newUrl = newUrl.replace(
          /{CODINTERNO}/g,
          user.codInternal ? user.codInternal.toString() : ""
        );
        newUrl = newUrl.replace(
          /{CODIGOINTERNO}/g,
          user.internalCode ? user.internalCode.toString() : ""
        );
        newUrl = newUrl.replace(
          /{EMPRESA}/g,
          user.company ? user.company.toString() : ""
        );
        newUrl = newUrl.replace(
          /{IDIOMA}/g,
          store.getState().lang.lang
        );
      } catch (error) {
        console.log("Erro ao modificar chaves de url changeUrlTags", error);
      }
    }
    urlArray.push(newUrl);
  });

  return urlArray;
}
