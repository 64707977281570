import * as accessThread from './accessThread';
import * as rankingThread from './rankingThread';
import * as pushNotificationThread from './pushNotificationThread';
import * as testThread from './testThread';

export function initThreads() {
    accessThread.sendAccessReport();
    accessThread.accessMessageReceiver();
    rankingThread.rankingInteegraPlusReceiver();
    // pushNotificationThread.getNewNotifications();
    // testThread.initTestThread();
}
