import React from 'react';
import Draggable from 'react-draggable';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MinimizeIcon from '@material-ui/icons/Minimize';
import MaximizeIcon from '@material-ui/icons/Maximize';

import {
    Card,
    FormRow,
    TextMedium
} from '../components';

const Component = props => {
    const {
        children,
        open,
        onClose,
        title = "",
    } = props;

    const [isMaximized, setIsMaximized] = React.useState(true);

    function localOnCLose() {
        props.onClose();
    }

    const styles = {
        modal: {
            button: {
                marginRight: "10px",
                color: "white",
                backgroundColor: "gray",
            },
        }
    }

    if (open) {
        return (
            <Draggable>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "move",
                        zIndex: 12
                    }}
                >
                    <Card>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <th style={{ textAlign: "left" }}>
                                    <TextMedium>
                                        {title}
                                    </TextMedium>
                                </th>
                                <th style={{ textAlign: "right" }}>
                                    <IconButton
                                        id="dialogDraggableMaximize"
                                        size="small"
                                        onClick={() => setIsMaximized(isMaximized === true ? false : true)}
                                        style={styles.button}
                                    >
                                        {isMaximized
                                            ? (<MinimizeIcon fontSize="small" />)
                                            : (<MaximizeIcon fontSize="small" />)
                                        }
                                    </IconButton>
                                    <IconButton
                                        id="dialogDraggableCloseButton"
                                        size="small"
                                        onClick={() => onClose({ close: true })}
                                        style={styles.button}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </th>
                            </tr>
                        </table>
                        {isMaximized && (
                            <FormRow margin="0px">
                                {children}
                            </FormRow>
                        )}
                    </Card>
                </div>
            </Draggable >
        )
    }
    return null;
};

export default Component;
