import { SET_STANDS, RESET_STANDS } from '../actions';

const INITIAL_STATE = [];

export default function standReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_STANDS:
            return [...state, ...action.stands];
        case RESET_STANDS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
