import { store } from '../store';
import { createFormControlModel } from '../models/formControlModel';
import { getDic } from '../assets/i18n/dictionary';
import { getTypeQuestionList } from '../services/questionsService';

export function createQuestionModel(
    id = 0,
    eventId = 0,
    typeQuestion = "", //SELECIONAR, ENQUETE e TEXTO
    category = "", //PERGUNTA e PESQUISA
    question = "",
    values = "", // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
    scheduleId = [], //objeto = {"id": 1, "scheduleId" ou "configId": 902, "groupId": 15996}
    ConfigId = [], //objeto = {"id": 1, "scheduleId" ou "configId": 902, "groupId": 15996}
) {
    //Transforma o values em array
    var valuesList = [];
    var typeQuestionStr = typeQuestion.replace(/ /g, ""); //Replace all
    if ((typeQuestionStr === "SELECIONAR" || typeQuestionStr === "ENQUETE") && values && values !== "" && values !== " ") {
        var valuesReturn;
        try {
            valuesReturn = JSON.parse(values);
        } catch (error) {
            console.log("Erro ao converter JSON - Pergunta ID ", id);
            valuesReturn = [];
        }

        valuesReturn.forEach(item => {
            var select = {
                value: item.Text,
                text: item.Text
            }
            valuesList.push(select);
        });
    }

    //Tratamento da informação de amarração
    const _configsAndSchedulesIds = [];
    const _schedulesIds = [];
    const _configsIds = [];
    if (scheduleId && scheduleId.length > 0) {
        scheduleId.forEach(item => {
            if (item.scheduleId) {
                _configsAndSchedulesIds.push(item.scheduleId);
                _schedulesIds.push(item.scheduleId);
            }
        });
    }
    if (ConfigId && ConfigId.length > 0) {
        ConfigId.forEach(item => {
            if (item.configId) {
                _configsAndSchedulesIds.push(item.configId);
                _configsIds.push(item.configId);
            }
        });
    }

    const object = {
        id: id,
        eventId: eventId,
        typeQuestion: typeQuestion.trim(), //Retira espaço em branco
        category: category,
        question: question,
        values: valuesList,
        valuesStr: values,
        scheduleOrConfigId: _configsAndSchedulesIds,
        configsId: _configsIds,
        schedulesId: _schedulesIds,

        //Parametro para página de cadastro
        selected: false
    }

    return object;
}

export function createAwnswerModel(
    questionId = 0,
    question = "",
    questionType = "",
    answer = "",
    eventId = 0,
    activeQuestion = true,
    activeAnswer = true,
    questionCategory = "",
    answerId = 0,
    userAnswer = "",
    answerDate = "",
    answerHour = "",
    answerLogin = "",
) {
    const object = {
        questionId: questionId,
        question: question,
        questionType: questionType,
        answer: answer,
        eventId: eventId,
        activeQuestion: activeQuestion,
        activeAnswer: activeAnswer,
        questionCategory: questionCategory,
        answerId: answerId,
        userAnswer: userAnswer,
        answerDate: answerDate,
        answerHour: answerHour,
        answerLogin: answerLogin,

        //Parametro para página de moderação
        selected: false
    }

    return object;
}

export function convertQuestionObjectToForm(question, isImporter = false) {
    const _form = [];

    var _index = 0;

    for (var key in question) {
        if (question.hasOwnProperty(key)) {

            var _hasForm = false;
            var _type = "text";
            var _options = null;
            var _required = true;
            var _name = "";
            var _value = question[key] ? question[key].toString() : null;

            switch (key.toString()) {
                case "id":
                    _hasForm = true;
                    _name = "id";
                    _required = true;
                    _value = parseInt(question[key]);
                    break;
                case "typeQuestion":
                    _hasForm = true;
                    _name = getDic("tipo");
                    _required = true;
                    _options = getTypeQuestionList();
                    _type = "select";
                    break;
                case "category":
                    _hasForm = true;
                    _name = getDic("categoria");
                    _required = true;
                    break;
                case "question":
                    _hasForm = true;
                    _name = getDic("pergunta");
                    _required = true;
                    break;
                case "valuesStr":
                    if (!isImporter) {
                        _hasForm = true;
                        _name = getDic("opcoes");
                        _required = false;
                    }
                    break;
                case "schedulesId":
                    const _schedules = [];
                    store.getState().schedules.forEach(item => {
                        var _sched = {
                            text: item.title,
                            value: item.id
                        }
                        _schedules.push(_sched);
                    });

                    _hasForm = true;
                    _name = getDic("programacao");
                    _required = false;
                    _options = _schedules;
                    _type = "select";
                    _value = question[key] ? question[key] : [];
                    break;
                case "configsId":
                    const _configList = [];
                    store.getState().config.forEach(item => {
                        if (item.codeScreen.includes("ELEMENT") && item.urlString.includes("QUESTIONS")) {
                            var _conf = {
                                text: `${item.codeScreen} - ${item.tooltip}`,
                                value: item.id
                            }
                            _configList.push(_conf);
                        }
                    });

                    _hasForm = true;
                    _name = getDic("configuracoes");
                    _required = false;
                    _options = _configList;
                    _type = "select";
                    _value = question[key] ? question[key] : [];
                    break;
                default:
                    break;
            }

            if (_hasForm) {
                const _obj = createFormControlModel(
                    _index,
                    _name,
                    _value,
                    _type,
                    null,
                    null,
                    _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                    _required,
                    null,
                    null,
                    null,
                    key
                );
                _form.push(_obj);
                _index++;
            }
        }
    }

    if (isImporter) {
        // No importador, adicionar 20 campos para as opções de resposta
        // As respostas cadastradas via json serão separadas por index
        _index++;
        var _options;
        try {
            if (question.valuesStr && question.valuesStr !== '') {
                _options = JSON.parse(question.valuesStr);
            }
        } catch (error) {
            console.log('Erro ao tentar converter JSON')
        }

        for (let i = 0; i < 20; i++) {
            const _obj = createFormControlModel(
                _index,
                `${getDic("alternativa")} ${i + 1}`,
                _options && _options[i] && _options[i].Text ? _options[i].Text : '',
                "text",
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                `alternative${i + 1}`
            );
            _form.push(_obj);
            _index++;
        }
    }

    return _form;
}

export function convertFormToQuestionObject(form, isImporter = false) {
    var _config = createQuestionModel();
    form.forEach(item => {
        var _value;

        switch (item.dbReference) {
            case "id":
                _value = parseInt(item.value);
                break;
            case "eventId":
                _value = store.getState().event.id;
                break;
            case "typeQuestion":
                _value = item.value ? item.value.toString() : null;
                break;
            case "category":
                _value = item.value ? item.value.toString() : null;
                break;
            case "question":
                _value = item.value ? item.value.toString() : null;
                break;
            case "valuesStr":
                _value = item.value ? item.value.toString() : null;
                break;
            case "coordinatesString":
                _value = item.value ? item.value.toString() : null;
                break;
            case "schedulesId":
                if (!isImporter) {
                    _value = item.value;
                } else {
                    _value = [];
                    if (item.value && item.value !== '') {
                        const _strArray = item.value.split(',');
                        _strArray.forEach(item => {
                            _value.push(parseInt(item));
                        });
                    }
                }
                break;
            case "configsId":
                if (!isImporter) {
                    _value = item.value;
                } else {
                    _value = [];
                    if (item.value && item.value !== '') {
                        const _strArray = item.value.split(',');
                        _strArray.forEach(item => {
                            _value.push(parseInt(item));
                        });
                    }
                }
                break;
            default:
                break;
        }
        _config[item.dbReference] = _value;
    });

    if (isImporter) {
        const _alternatives = [];

        for (let i = 0; i < 20; i++) {
            const _item = form.find(f => { return f.dbReference === `alternative${i + 1}` });
            if (_item && _item.value && _item.value !== '') {
                const _text = _item.value.replace(`"`, `'`);
                _alternatives.push({ "Text": _text });
            }
        }

        if (_alternatives.length > 0) {
            try {
                _config.valuesStr = JSON.stringify(_alternatives);
            } catch (error) {
                console.log('Erro ao tentar converter alternativas em JSON');
            }
        }
    }

    return _config;
}
