import React from 'react'
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { getStandsListApi, addNewStandApi, updateStandApi, deleteStandApi } from '../services/standService';
import { createStandModel, convertStandObjectToForm, convertFormToStandObject } from '../models/standModel';
import { validateForm } from '../utils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';

//Imports de components
import {
    MenuButton,
    BackButton,

    Container,
    FormRow,
    InputFieldOutlined,
    SelectList,
    Button,
    FormColumn,
    Checkbox,
    Alert,
    Loading,
    Modal,
    TextBig,
    TextSmall,
    GridRow,
    GridCell,
    TextMedium,
    Dialog
} from '../components';
import { FormatListNumbered } from '@material-ui/icons';

const StandsConfigPage = (props) => {
    const [state, setState] = React.useState(true);
    //Alert control
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState("");
    const [alertMessage, setAlertMessage] = React.useState("");

    //Dialog control
    const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
    const [dialogConfirmKey, setDialogConfirmKey] = React.useState("");
    const [dialogConfirmKeyErrorMessage, setDialogConfirmKeyErrorMessage] = React.useState("");

    //Loading control
    const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
    const [openLoading, setOpenLoading] = React.useState(true);

    //Modal Control
    const [openModal, setOpenModal] = React.useState(false);

    const [standsList, setStandsList] = React.useState([]);
    const [newStandForm, setNewStandForm] = React.useState([]);
    const [chosenStand, setChosenStand] = React.useState();

    React.useEffect(() => {
        //Bloqueio caso o usuário não seja admin
        if (!props.user.isAdmin) {
            props.history.goBack();
        }

        getPageData();

        insertNewAccess(props.match.url, "", getSystemActions().accessPage);

        return () => {
            insertNewAccess(props.match.url, "", getSystemActions().exitPage);
        }
    }, []);

    const getPageData = async () => {
        var _standsList = [];
        await getStandsListApi()
            .then(res => {
                _standsList = res;
            });
        setStandsList(_standsList);

        setOpenLoading(false);
    }

    const onChangeHandler = (index, value) => {
        var _newForm = newStandForm;
        _newForm[index].value = value;
        setNewStandForm(_newForm);
        setState(state === true ? false : true);
    }

    const resetStandForm = () => {
        const _newStand = createStandModel();
        const _newForm = convertStandObjectToForm(_newStand);
        setNewStandForm(_newForm);
    }

    const createNewStand = () => {
        const _newStand = createStandModel();
        const _newForm = convertStandObjectToForm(_newStand);
        setNewStandForm(_newForm);
        setOpenModal(true);
    }

    const editStand = (stand) => {
        const _newForm = convertStandObjectToForm(stand);
        setNewStandForm(_newForm);
        setOpenModal(true);
    }

    const saveStand = async () => {
        var validation = validateForm(newStandForm);
        if (validation.isValid) {
            setOpenLoading(true);
            setLoadingMessage(getDic("salvando"));

            var _newStand = convertFormToStandObject(newStandForm);
            var _error = false;
            const _newStandsList = [];

            if (_newStand.id > 0) {
                await updateStandApi(_newStand)
                    .then(res => {
                        _newStandsList.push(res);
                    })
                    .catch(err => {
                        _error = true;
                    })
            }
            else {
                await addNewStandApi(_newStand)
                    .then(res => {
                        _newStandsList.push(res);
                    })
                    .catch(err => {
                        _error = true;
                    })
            }

            if (_error) {
                setAlertOpen(true);
                setAlertTitle(getDic("erro"));
                setAlertMessage(getDic("enviado-erro"));
                setOpenLoading(false);
            }
            else {
                standsList.forEach(item => {
                    if (item.id !== _newStand.id) {
                        _newStandsList.push(item);
                    }
                });
                _newStandsList.sort((a, b) => {
                    return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
                });
                setStandsList(_newStandsList);
                setAlertOpen(true);
                setAlertTitle("");
                setAlertMessage(getDic("enviado-sucesso"));
                resetStandForm();
                setOpenLoading(false);
                setOpenModal(false);
            }
        }
        else {
            setNewStandForm(validation.form);
        }
    }

    const deleteStand = () => {
        setOpenLoading(true);
        setLoadingMessage(getDic("salvando"));

        deleteStandApi(chosenStand)
            .then(res => {
                const _newStandsList = [];
                standsList.forEach(item => {
                    if (item.id !== chosenStand.id) {
                        _newStandsList.push(item);
                    }
                });
                _newStandsList.sort((a, b) => {
                    return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
                });
                setStandsList(_newStandsList);
            })
            .catch(err => {
                setAlertOpen(true);
                setAlertTitle(getDic("erro"));
                setAlertMessage(getDic("enviado-erro"));
                setOpenLoading(false);
            })
            .finally(() => {
                setOpenLoading(false);
            })
    }

    return (
        <Container background="#e8eced" align="center">
            <MenuButton history={props.history} />
            <BackButton
                history={props.history}
                color="#212121"
            >
                {getDic("voltar").toUpperCase()}
            </BackButton>

            <FormColumn>
                <FormRow
                    minHeight="50px"
                    align="center"
                    background="#e8eced"
                    margin="0px"
                />
                <FormRow
                    minHeight="50px"
                    align="start"
                    background="#e8eced"
                    margin="0px"
                >
                    <div style={{ padding: "5px" }}>
                        <Button
                            color="green"
                            onClick={() => createNewStand()}
                        >
                            {getDic("cadastrar") + " Stand"}
                        </Button>
                    </div>
                </FormRow>
                <FormRow align="center" margin="0px">
                    <div style={{ padding: 5, width: "100%" }}>
                        <GridRow backgroundColor="#d9d9d9">
                            <GridCell width="10%" border top first >
                                <TextMedium>ID</TextMedium>
                            </GridCell>
                            <GridCell width="30%" border top>
                                <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                            </GridCell>
                            <GridCell width="30%" border top>
                                <TextMedium>TOOLTIP</TextMedium>
                            </GridCell>
                            <GridCell width="10%" border top>
                                <TextMedium>ORDER</TextMedium>
                            </GridCell>
                            <GridCell width="10%" border top>
                                <TextMedium>{getDic("pavilhao").toUpperCase()}</TextMedium>
                            </GridCell>
                            <GridCell width="10%" border top>
                                <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
                            </GridCell>
                        </GridRow>

                        {standsList.length > 0 ?
                            (
                                standsList.map((stand, i) => {
                                    return (
                                        <GridRow
                                            key={stand.id}
                                            autoColor={i}
                                        >
                                            <GridCell width="10%" border first>
                                                <TextSmall>{stand.id}</TextSmall>
                                            </GridCell>
                                            <GridCell width="30%" border>
                                                <TextSmall>{stand.name}</TextSmall>
                                            </GridCell>
                                            <GridCell width="30%" border>
                                                <TextSmall>{stand.tooltip}</TextSmall>
                                            </GridCell>
                                            <GridCell width="10%" border>
                                                <TextSmall>{stand.order}</TextSmall>
                                            </GridCell>
                                            <GridCell width="10%" border>
                                                <TextSmall>{stand.pavilion}</TextSmall>
                                            </GridCell>
                                            <GridCell width="10%" border>
                                                <EditIcon onClick={() => editStand(stand)} style={{ cursor: "pointer" }} />
                                                <DeleteIcon
                                                    onClick={() => {
                                                        setChosenStand(stand);
                                                        setDialogConfirmOpen(true);
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </GridCell>
                                        </GridRow>
                                    )
                                })
                            ) : (
                                <TextMedium>
                                    {getDic("pesquisa-sem-resultado")}
                                </TextMedium>
                            )
                        }
                    </div>
                </FormRow>
            </FormColumn>

            <Modal
                isVideo
                height="100%"
                open={openModal}
                closeButtonClick={e => setOpenModal(false)}
                onClose={ret => {
                    setOpenModal(false);
                }}
            >
                <Container background="#e8eced" align="left" height="100%" >
                    <FormColumn padding="5px">
                        {newStandForm.map((item, i) => {
                            if (i === 0) {
                                return (
                                    <FormRow key={item.id} align="center" paddingTop="7px">
                                        <TextMedium>
                                            {item.value > 0 ? (
                                                `${getDic("editar")} Stand`
                                            ) : (
                                                `${getDic("novo")} Stand`
                                            )}
                                        </TextMedium>
                                    </FormRow>
                                )
                            }
                            else {
                                if (item.type === "select" && item.values) {
                                    return (
                                        <FormRow key={item.id} align="center" paddingTop="7px">
                                            <SelectList
                                                required={item.required}
                                                error={item.error}
                                                errorMessage={item.errorMessage}
                                                title={item.name}
                                                width="100%"
                                                value={item.value}
                                                menuItens={item.values}
                                                onChange={async (event) =>
                                                    onChangeHandler(i, event.target.value)
                                                }
                                            />
                                        </FormRow>
                                    );
                                }
                                if (item.type === "text") {
                                    return (
                                        <FormRow key={item.id} align="center" paddingTop="7px">
                                            <InputFieldOutlined
                                                key={item.id}
                                                required={item.required}
                                                maxLength={item.maxLength}
                                                error={item.error}
                                                errorMessage={item.errorMessage}
                                                value={item.value}
                                                title={item.name}
                                                onChange={(event) =>
                                                    onChangeHandler(i, event.target.value)
                                                }
                                            />
                                        </FormRow>
                                    );
                                }
                            }
                        })}
                        <FormRow align="center" paddingTop="7px">
                            <Button
                                text={getDic("salvar")}
                                onClick={() => saveStand()}
                            />
                        </FormRow>
                    </FormColumn>
                </Container>
            </Modal>

            <Alert
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                onClick={() => setAlertOpen(false)}
                title={alertTitle}
                message={alertMessage}
            />

            <Dialog
                open={dialogConfirmOpen}
                onClose={() => setDialogConfirmOpen(false)}
                title={getDic("atencao")}
                textButtonYes={getDic("deletar")}
                onClickYes={() => {
                    if (dialogConfirmKey && dialogConfirmKey != "" && dialogConfirmKey.toUpperCase() === "SIM") {
                        deleteStand();
                        setDialogConfirmOpen(false);
                        setDialogConfirmKey("");
                    }
                    else {
                        setDialogConfirmKeyErrorMessage(true);
                        setTimeout(() => {
                            setDialogConfirmKeyErrorMessage(false);
                        }, 3000); // 1 minuto = 60000
                    }
                }}
                textButtonNo={getDic("cancelar")}
                onClickNo={() => {
                    setDialogConfirmOpen(false);
                    setDialogConfirmKey("");
                }}
            >
                <FormRow>
                    <TextSmall>
                        {getDic("confirmacao-de-acao")}
                    </TextSmall>
                </FormRow>
                <FormRow align="center" paddingTop="5px">
                    <InputFieldOutlined
                        key="password"
                        value={dialogConfirmKey}
                        onChange={event => setDialogConfirmKey(event.target.value)}
                    />
                </FormRow>
                {dialogConfirmKeyErrorMessage ? (
                    <TextSmall color="red">{getDic("erro")}</TextSmall>
                ) : (null)}
            </Dialog>

            <Loading open={openLoading} message={loadingMessage} />
        </Container>
    )
}

function mapStateToProps(state) {
    return {
        event: state.event,
        user: state.user,
    }
}

export default connect(mapStateToProps, null)(StandsConfigPage)
