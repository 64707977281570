import React, { Component } from "react";
import { store } from "../../store";
import PropTypes from "prop-types";
import isEqual from "react-fast-compare";
import { ConsoleView, isMobile } from "react-device-detect";
import { connect } from "react-redux";
import "../../scss/MobileRender.scss"
class ImageMapper extends Component {
  constructor(props) {
    super(props);
    [
      "drawrect",
      "drawcircle",
      "drawpoly",
      "initCanvas",
      "renderPrefilledAreas",
    ].forEach((f) => (this[f] = this[f].bind(this)));
    this.state = {
      configList: this.props.configList,
      one: 0
    };
    let absPos = { position: "absolute", top: 0, left: 0 };
    this.styles = {
      container: { position: "relative" },
      canvas: { ...absPos, pointerEvents: "none" },
      img: { ...absPos, userSelect: "none" },
    };
    // Props watched for changes to trigger update
    this.watchedProps = [
      "active",
      "fillColor",
      "height",
      "imgWidth",
      "lineWidth",
      "src",
      "strokeColor",
      "width",
    ];

  }

  shouldComponentUpdate(nextProps) {
    const propChanged = this.watchedProps.some(
      (prop) => this.props[prop] !== nextProps[prop]
    );
    return !isEqual(this.props.map, this.state.map) || !isEqual(this.props.width, this.state.width) || !isEqual(this.props.src, this.state.img) || propChanged; 
  }

  componentWillMount() { 
    this.updateCacheMap();
  }

  updateCacheMap() {
    this.setState(
      {
        width: this.props.width,
        img: this.props.src, 
        map: JSON.parse(JSON.stringify(this.props.map))
      },
      this.initCanvas
    );
  }

  componentDidUpdate() {
    this.updateCacheMap();
    this.initCanvas();
    this.listenerElementLibras()

  }

  drawrect(coords, fillColor, lineWidth, strokeColor) {
    let [left, top, right, bot] = coords;
    this.ctx.fillStyle = fillColor;
    this.ctx.lineWidth = lineWidth;
    this.ctx.strokeStyle = strokeColor;
    this.ctx.strokeRect(left, top, right - left, bot - top);
    this.ctx.fillRect(left, top, right - left, bot - top);
    this.ctx.fillStyle = this.props.fillColor;
  }

  drawcircle(coords, fillColor, lineWidth, strokeColor) {
    this.ctx.fillStyle = fillColor;
    this.ctx.beginPath();
    this.ctx.lineWidth = lineWidth;
    this.ctx.strokeStyle = strokeColor;
    this.ctx.arc(coords[0], coords[1], coords[2], 0, 2 * Math.PI);
    this.ctx.closePath();
    this.ctx.stroke();
    this.ctx.fill();
    this.ctx.fillStyle = this.props.fillColor;
  }

  drawpoly(coords, fillColor, lineWidth, strokeColor) {
    coords = coords.reduce(
      (a, v, i, s) => (i % 2 ? a : [...a, s.slice(i, i + 2)]),
      []
    );

    this.ctx.fillStyle = fillColor;
    this.ctx.beginPath();
    this.ctx.lineWidth = lineWidth;
    this.ctx.strokeStyle = strokeColor;
    let first = coords.unshift();
    this.ctx.moveTo(first[0], first[1]);
    coords.forEach((c) => this.ctx.lineTo(c[0], c[1]));
    this.ctx.closePath();
    this.ctx.stroke();
    this.ctx.fill();
    this.ctx.fillStyle = this.props.fillColor;
  }

  initCanvas() {
    if (this.props.width) this.img.width = this.props.width;
    if (this.props.height) this.img.height = this.props.height;
    this.canvas.width = this.props.width || this.img.clientWidth;
    this.canvas.height = this.props.height || this.img.clientHeight;
    this.container.style.width =
      (this.props.width || this.img.clientWidth) + "px";
    this.container.style.height =
      (this.props.height || this.img.clientHeight) + "px";
    this.ctx = this.canvas.getContext("2d");
    this.ctx.fillStyle = this.props.fillColor;
    //this.ctx.strokeStyle = this.props.strokeColor;

    if (this.props.onLoad) this.props.onLoad();

    this.renderPrefilledAreas();
  }

  hoverOn(area, index, event) {
    const shape = event.target.getAttribute("shape");

    if (this.props.active && this["draw" + shape]) {
      this["draw" + shape](
        event.target.getAttribute("coords").split(","),
        area.fillColor,
        area.lineWidth || this.props.lineWidth,
        area.strokeColor || this.props.strokeColor
      );
    }
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(area, index, event);
    }
  }

  hoverOff(area, index, event) {
    if (this.props.active) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.renderPrefilledAreas();
    }

    if (this.props.onMouseLeave) this.props.onMouseLeave(area, index, event);
  }

  click(area, index, event) {
    if (this.props.onClick) {
      event.preventDefault();
      this.props.onClick(area, index, event);
    }
  }

  imageClick(event) {
    if (this.props.onImageClick) {
      event.preventDefault();
      this.props.onImageClick(event);
    }
  }

  mouseMove(area, index, event) {
    if (this.props.onMouseMove) {
      this.props.onMouseMove(area, index, event);
    }
  }

  imageMouseMove(area, index, event) {
    if (this.props.onImageMouseMove) {
      this.props.onImageMouseMove(area, index, event);
    }
  }

  scaleCoords(coords) {
    const { imgWidth, width } = this.props;    
    // calculate scale based on current 'width' and the original 'imgWidth'
    const scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
    return coords.map((coord) => coord * scale);
  }

  renderPrefilledAreas() {
    this.state.map.areas.map((area) => {
      if (!area.preFillColor) return;
      this["draw" + area.shape](
        this.scaleCoords(area.coords),
        area.preFillColor,
        area.lineWidth || this.props.lineWidth,
        area.strokeColor || this.props.strokeColor
      );
    });
  }

  computeCenter(area) {
    if (!area) return [0, 0];
    const scaledCoords = this.scaleCoords(area.coords);

    switch (area.shape) {
      case "circle":
        return [scaledCoords[0], scaledCoords[1]];
      case "poly":
      case "rect":
      default: {
        // Calculate centroid
        const n = scaledCoords.length / 2;
        const { y, x } = scaledCoords.reduce(
          ({ y, x }, val, idx) => {
            return !(idx % 2) ? { y, x: x + val / n } : { y: y + val / n, x };
          },
          { y: 0, x: 0 }
        );
        return [x, y];
      }
    }
  }

  renderAreas() {
    
    return this.state.map.areas.map((area, index) => {
      const scaledCoords = this.scaleCoords(area.coords);
      const center = this.computeCenter(area);
      const extendedArea = { ...area, scaledCoords, center };   
      let gifConfig = this.props.config.filter(e => e.url.toString().indexOf("SET_GIF_MAP") > -1)
      let iframeConfig = this.props.config.filter(e => e.url.toString().indexOf("SET_IFRAME_MAP") > -1)
      if(iframeConfig.filter(e => e.codeScreen == extendedArea.name) > 0 || gifConfig.filter(e => e.codeScreen == extendedArea.name) > 0) return;

      return (
        <area
          data-vw-extended 
          data-vw-text={area.tooltip}
          key={area._id || index}
          shape={area.shape}
          coords={scaledCoords.join(",")}
          onMouseEnter={this.hoverOn.bind(this, extendedArea, index)}
          onMouseLeave={this.hoverOff.bind(this, extendedArea, index)}
          onMouseMove={this.mouseMove.bind(this, extendedArea, index)}
          onClick={this.click.bind(this, extendedArea, index)}
          onFocus={this.hoverOn.bind(this, extendedArea, index)}
          onBlur={this.hoverOff.bind(this, extendedArea, index)}
          target="_blank"
          href="#"
          alt={area._id}
          id={area.href}
          tabindex='0'            
        />
      );
    });
  }

  renderIframe(extendedArea, index, enabled){
    let gifConfig = this.props.configList.filter(e => e.url.toString().indexOf("SET_IFRAME_MAP") > -1)
    if(gifConfig.filter(e => e.codeScreen == extendedArea.name) < 1) return;

    return this.state.map.areas.filter(a => a.name == extendedArea.name).map((area, index) => {
    
    const url = gifConfig.filter(e=> e.codeScreen == area.name)[0].url.filter(e=> e.indexOf("SET_IFRAME_MAP") > -1);
    
    const scaledCoords = this.scaleCoords(area.coords);
    const center = this.computeCenter(area);
    const extendedArea = { ...area, scaledCoords, center };
    const image = document.querySelector(".ImageBackgroundHall img");
    if(!image) return;
    //address bar
    const addressBar = image.width - window.innerWidth;
    const heightStatic = window.screen.height - window.innerHeight;

    const top = ((center[1] * image.height) / 900);
    const left = ((center[0] * image.width) / 1600);

    const height = url[0].split("{")[1].replace("}","").split(",")[2] * image.height / 900;
    const width = url[0].split("{")[1].replace("}","").split(",")[1] * image.width / 1600;
    //this.props.updateMapRender()
    return <iframe style={{
                top: top - height * 0.5,
                left: (left - width * 0.5) - addressBar / 2,
                cursor: 'pointer',
                position: "fixed",
                height: height,
                width: width,
                zIndex: 1,
                //pointerEvents: "none"
              }}
              src={url[0].split("{")[1].replace("}","").split(",")[0]}
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allowfullscreen" 
              allowfullscreen="allowfullscreen"
              />
    })
  }

  renderGifAnimation(extendedArea, index){   
    let gifConfig = this.props.configList.filter(e => e.url.toString().indexOf("SET_GIF_MAP") > -1)
    if(gifConfig.filter(e => e.codeScreen == extendedArea.name) < 1) return;
    
    return this.state.map.areas.filter(a => a.name == extendedArea.name).map((area, index) => {
    const url = gifConfig.filter(e=> e.codeScreen == area.name)[0].url.filter(e=> e.indexOf("SET_GIF_MAP") > -1);
    
    const scaledCoords = this.scaleCoords(area.coords);
    const center = this.computeCenter(area);
    const extendedArea = { ...area, scaledCoords, center };
    const image = document.querySelector(".ImageBackgroundHall img");
    if(!image) return;
    //address bar
    const addressBar = image.width - window.innerWidth;
    const heightStatic = window.screen.height - window.innerHeight;

    const top = ((center[1] * image.height) / 900);
    const left = ((center[0] * image.width) / 1600);

    const height = url[0].split("{")[1].replace("}","").split(",")[2] * image.height / 900;
    const width = url[0].split("{")[1].replace("}","").split(",")[1] * image.width / 1600;
    
    return <a 
            onMouseEnter={this.hoverOn.bind(this, extendedArea, index)}
            onMouseLeave={this.hoverOff.bind(this, extendedArea, index)}
            onMouseMove={this.mouseMove.bind(this, extendedArea, index)}
            onClick={this.click.bind(this, extendedArea, index)}
            onFocus={this.hoverOn.bind(this, extendedArea, index)}
            onBlur={this.hoverOff.bind(this, extendedArea, index)}
            data-vw-extended 
            data-vw-text={area.tooltip}
            key={area._id || index}
            >
            <img
              src={url[0].split("{")[1].replace("}","").split(",")[0]}
              style={{
                top: top - height * 0.5,
                left: (left - width * 0.5) - addressBar / 2,
                cursor: 'pointer',
                position: "fixed",
                height: height,
                width: width,
                zIndex: 1,
                //pointerEvents: "none"
              }}
            />
           </a>
    })
  }
  

  renderToolTipeFixed(tool_tipe_mobile) {
    //console.log(window.document.body.clientHeight)
    //if (!isMobile) return;    
    if (!tool_tipe_mobile) return;
    return this.state.map.areas.map((area, index) => {
      const scaledCoords = this.scaleCoords(area.coords);
      const center = this.computeCenter(area);
      const extendedArea = { ...area, scaledCoords, center };

      const image = document.querySelector(".ImageBackgroundHall img");
      if(!image) return;
      //address bar
      const addressBar = image.width - window.innerWidth;
      const heightStatic = window.screen.height - window.innerHeight;

      const top = ((center[1] * image.height) / 900);
      const left = ((center[0] * image.width) / 1600);


      return (
        <span
          key={area._id || index}
          className="TooltipAreaDefault"
          style={{
            top: top,
            left: left,
            cursor: 'no-drop',
            position: "fixed"
          }}
        >
          <i class="fas fa-sign-language" 
            style={{
              marginRight: 3,
              fontSize: 17 
              }}></i>


          {area.tooltip}
        </span>
      );
    });
  }

  listenerElementLibras(){
    //Detecta o Elemento principal e pega o primeiro node dele;
    let elem = document.querySelector("#vlibras").childNodes;

    if(elem.length < 1) return;
    let elementCheck;
    let vLibrasEnabled;
    elem.forEach(element => {
      if(element instanceof HTMLDivElement){
        if(element.getAttributeNames().includes("vw-access-button")){
          elementCheck = element;
          if(element.className.indexOf("active") > -1){
            //Aqui o libras ta fechado
            vLibrasEnabled = false;
          } else {
            vLibrasEnabled = true;
          }
        }
      }
    });
    let toolTips = document.querySelectorAll(".TooltipAreaDefault")
    if(!toolTips) return;

    //Desativa os toolTips por default, se o libras tiver aberto, mantém aberto também
    if(!vLibrasEnabled) 
      {
        toolTips.forEach(element => element.style["display"] = "none" );
      }
    else {
      toolTips.forEach(element => element.style["display"] = "block" );
    }

    //Cria um listenner para checar se o vlibras abriu
    var lastClassName = elementCheck.className;
    let _this = this;
    this.intervalWindow = window.setInterval( function() {   
      var className = elementCheck.className;
       if (className !== lastClassName && elementCheck) {
           lastClassName = className;
           _this.setState({enableVLibraTipe: !_this.state.enableVLibraTipe})

           if(!toolTips) return;
           toolTips.forEach(element => {
            if(className.indexOf("active") > -1){
              element.style["display"] = "none";
            } else {
              element.style["display"] = "block";
            }            
          }); 
       }
   },10);
  }

  

  componentWillUnmount(){
    clearInterval(this.intervalWindow)
  }

  render() {
    return (
      <React.Fragment>
        {this.renderToolTipeFixed(
          this.props.config.filter(
            (e) => e.codeScreen == "ENABLE-MOBILE-TOOLTIP"
          ).length > 0
        )}        
        <div
          style={this.styles.container}
          ref={(node) => (this.container = node)}
        >        
          <img
            style={this.styles.img}
            src={this.props.src}
            useMap={`#${this.state.map.name}`}
            alt=""
            ref={(node) => (this.img = node)}
            onLoad={this.initCanvas}
            onClick={this.imageClick.bind(this)}
            onMouseMove={this.imageMouseMove.bind(this)}
          />
          <canvas
            ref={(node) => (this.canvas = node)}
            style={this.styles.canvas}
          />
          <map name={this.state.map.name} style={this.styles.map}>
            {this.renderAreas()}
          </map>    
          {
            this.state.map.areas.map((area, index) => {
              const scaledCoords = this.scaleCoords(area.coords);
              const center = this.computeCenter(area);
              const extendedArea = { ...area, scaledCoords, center };

              let gifConfig = this.props.config.filter(e => e.url.toString().indexOf("SET_GIF_MAP") > -1);

              //if(gifConfig.filter(f => f.codeScreen == extendedArea.name).length < 1) return;
              return this.renderGifAnimation(extendedArea, index);
            })
          }
          {
            this.state.map.areas.map((area, index) => {
              const scaledCoords = this.scaleCoords(area.coords);
              const center = this.computeCenter(area);
              const extendedArea = { ...area, scaledCoords, center };
        
              let iframeConfig = this.props.config.filter(e => e.url.toString().indexOf("SET_IFRAME_MAP") > -1);
              return this.renderIframe(extendedArea, index);
            })
          }  
        </div>
      </React.Fragment>
    );
  }
}

ImageMapper.defaultProps = {
  active: true,
  fillColor: "rgba(255, 255, 255, 0.5)",
  lineWidth: 1,
  map: {
    areas: [],
    name: "image-map-" + Math.random(),
  },
  strokeColor: "rgba(0, 0, 0, 0.5)",
};

ImageMapper.propTypes = {
  active: PropTypes.bool,
  fillColor: PropTypes.string,
  height: PropTypes.number,
  imgWidth: PropTypes.number,
  lineWidth: PropTypes.number,
  src: PropTypes.string.isRequired,
  strokeColor: PropTypes.string,
  width: PropTypes.number,

  onClick: PropTypes.func,
  onMouseMove: PropTypes.func,
  onImageClick: PropTypes.func,
  onImageMouseMove: PropTypes.func,
  onLoad: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,

  map: PropTypes.shape({
    areas: PropTypes.arrayOf(
      PropTypes.shape({
        area: PropTypes.shape({
          coords: PropTypes.arrayOf(PropTypes.number),
          href: PropTypes.string,
          shape: PropTypes.string,
          preFillColor: PropTypes.string,
          fillColor: PropTypes.string,
        }),
      })
    ),
    name: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
    config: state.config,
  };
}

export default connect(mapStateToProps)(ImageMapper);
