import React from 'react';
import Card from '@material-ui/core/Card';

const CardPerson = props => {
    const {
        children,
        cardType = "normal", // Outras opçoes: "simple", "transparent"
        backgroundColor = "auto",
        padding = '5px',
    } = props;

    const styles = {
        card: {
            padding: padding,
            background: backgroundColor
        },
        simple: {
            background: backgroundColor,
            padding: padding,
            borderRadius: "5px",
        },
        transparent: {
            background: "transparent",
            padding: padding,
            borderRadius: "5px"
        }
    };

    if (cardType === "simple") {
        return (
            <div style={styles.simple}>
                {children}
            </div>
        );
    }
    if (cardType === "transparent") {
        return (
            <div style={styles.transparent}>
                {children}
            </div>
        );
    }
    return (
        <Card style={styles.card}>
            {children}
        </Card>
    )

};

export default CardPerson;
