import { store } from '../../store';
import pt from './pt-br.json';
import en from './en.json';
import sp from './sp.json';

export function getDic(word) {
    const lang = store.getState().lang;

    switch (lang.lang) {
        case "pr-br":
            return pt[word];
        case "en":
            return en[word];
        case "sp":
            return sp[word];
        default:
            return pt[word];
    }
}

export function getLanguages() {
    const _lang = store.getState().config.find(conf => {
        return conf.codeScreen === 'ENABLE-CHANGE-LANGUAGE'
    });

    var languageEnable = {
        en: true,
        sp: true,
        pt: true,
    };

    if (_lang) {
        if (_lang.url && _lang.url.length > 0) {
            _lang.url.forEach(lang => {
                var _langArray = lang.split(':');
                switch (_langArray[0]) {
                    case "enableEn":
                        languageEnable.en = _langArray[1] && _langArray[1] === 'false' ? false : true;
                        break;
                    case "enableSp":
                        languageEnable.sp = _langArray[1] && _langArray[1] === 'false' ? false : true;
                        break;
                    case "enablePt":
                        languageEnable.pt = _langArray[1] && _langArray[1] === 'false' ? false : true;
                        break;
                    default:
                        break;
                }
            });
        }
    }

    const languages = [];

    if (languageEnable.pt) {
        languages.push({
            value: "pt-br",
            text: "Português",
        })
    }
    if (languageEnable.en) {
        languages.push({
            value: "en",
            text: "English",
        })
    }
    if (languageEnable.sp) {
        languages.push({
            value: "sp",
            text: "Español",
        })
    }

    return languages;
}
