import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { chosenStand } from "../store/actions";
import { getConfigListByPage } from "../services/configService";
import SearchIcon from '@material-ui/icons/Search';

//Imports de components
import {
  MenuButton,
  BackButton,
  Alert,
  Loading,
  Container,
  FormRow,
  FormColumn,
  InputFieldOutlined,
  SelectList,
  NetflixSlider,
  TextMedium,
  TextBig,
  ElementTreatment,
  Card,
  ActivityIndicator
} from '../components';

const getSponsorObject = () => {
  return {
    name: "",
    segment: "",
    tags: "",
    text1: "",
    image: "",
    order: 0,
    category: "",
    standId: 0,
    isLoaded: false
  }
}

const SponsorsPage = (props) => {
  const [state, setState] = React.useState(true);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(true);

  const [chosenConfig, setChosenConfig] = React.useState(null);
  const [executeElement, setExecuteElement] = React.useState(false);

  const [searchString, setSearchString] = React.useState('');
  const sponsorsList = React.useRef();
  const [categoriesList, setCategoriesList] = React.useState([]);
  const [segmentsList, setSegmentsList] = React.useState([]);
  const [chosenSegmentsList, setChosenSegmentsList] = React.useState(null);
  const [imageBackgroundUrl, setImageBackgroundUrl] = React.useState(null);
  const [colorBackground, setColorBackground] = React.useState("#e8eced");
  const [textCategoryConfig, setTextCategoryConfig] = React.useState({ textColor: "black" });

  React.useEffect(() => {
    getPageData();

    if (!props.isModal) {
      insertNewAccess(props.match.url, "", getSystemActions().accessPage);
    }

    return () => {
      if (!props.isModal) {
        insertNewAccess(props.match.url, "", getSystemActions().exitPage);
      }
    }
  }, []);

  const getPageData = async () => {

    //Aplica configurações
    const _url = !props.isModal ? props.match.url : `/${props.event.id}/sponsors`;
    const _configList = await getConfigListByPage(_url);

    _configList.find((item) => {
      if (item.codeScreen === "IMAGE-001") {
        setImageBackgroundUrl(item.url[0]);
        setColorBackground("#ffffff00");
      }
      if (item.codeScreen === "BACKGROUND-COLOR") {
        setColorBackground(item.url[0]);
      }
      if (item.codeScreen === "HTML-TO-PAGE") {
        setSponsorsListAndCategoriesList(item.html);
      }
      if (item.codeScreen === "TITTLE-001") {
        var _config = textCategoryConfig;
        try {
          item.url.forEach(el => {
            var _prop = el.split(':');
            _config[_prop[0]] = _prop[1];
          });
        } catch (error) {
          console.log('Erro ao setar configuração setTextCategoryConfig');
        }
        setTextCategoryConfig(item.html);
      }
      return null;
    });

    setOpenLoading(false);
  }

  const setSponsorsListAndCategoriesList = (textJson = '') => {
    var _finalList = {
      orderBy: [],
      categoryOrder: [],
      sponsors: []
    }

    var _categories = [];
    var _segments = [{
      text: getDic("todos"),
      value: ''
    }];

    try {
      const _incameJson = JSON.parse(textJson);

      if (_incameJson.orderBy && _incameJson.orderBy.length > 0) {
        _finalList.orderBy = _incameJson.orderBy;
      }
      if (_incameJson.categoryOrder && _incameJson.categoryOrder.length > 0) {
        _finalList.categoryOrder = _incameJson.categoryOrder;
      }

      if (_incameJson.sponsors && _incameJson.sponsors.length > 0) {
        _incameJson.sponsors.forEach(item => {
          var _sponsor = getSponsorObject();
          for (var key in item) {
            if (_sponsor.hasOwnProperty(key)) {
              var _value = item[key];
              if (key === 'order' || key === 'standId') {
                _value = parseInt(item[key]);
              }
              _sponsor[key] = _value;
            }
          }
          _finalList.sponsors.push(_sponsor);

          if (_sponsor.category && _sponsor.category !== '') {
            const _hasCat = _categories.find(c => c.value === _sponsor.category);

            if (!_hasCat) {
              _categories.push({
                text: _sponsor.category,
                value: _sponsor.category
              })
            }
          }

          if (_sponsor.segment && _sponsor.segment !== '') {
            const _hasSeg = _segments.find(c => c.value === _sponsor.segment);

            if (!_hasSeg) {
              _segments.push({
                text: _sponsor.segment,
                value: _sponsor.segment
              })
            }
          }
        });
      }
    } catch (error) {
      console.log('Erro ao carregar patrocinadores', error);
    }

    if (_finalList.orderBy.length > 0) {
      _finalList.sponsors = sortSponsorsList(_finalList.sponsors, _finalList.orderBy);
    }

    sponsorsList.current = _finalList;

    var _finalCategories = [{
      text: getDic("todos"),
      value: ''
    }];

    if (_finalList.categoryOrder.length > 0) {
      _finalList.categoryOrder.forEach(item => {
        const _category = _categories.find(c => c.value === item);
        if (_category) {
          _finalCategories.push(_category);
        }
      });

      _categories.forEach(item => {
        const _hasCat = _finalCategories.find(c => c.value === item.value);

        if (!_hasCat) {
          _finalCategories.push(item);
        }
      });
    } else {
      _categories.forEach(item => {
        _finalCategories.push(item);
      });
    }
    setCategoriesList(_finalCategories);

    _segments.sort((a, b) => {
      return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0);
    });
    setSegmentsList(_segments);

    setState(!state);
  }

  const sortSponsorsList = (sponsors = [], orderByList = []) => {

    return sponsors.sort((ob1, ob2) => {
      if (ob1[orderByList[0]] > ob2[orderByList[0]]) {
        return 1;
      } else if (ob1[orderByList[0]] < ob2[orderByList[0]]) {
        return -1;
      }

      // if (orderByList[1]) {
      //   if (ob1[orderByList[1]] > ob2[orderByList[1]]) {
      //     return 1;
      //   } else if (ob1[orderByList[1]] < ob2[orderByList[1]]) {
      //     return -1;
      //   }
      // }

      // if (orderByList[2]) {
      //   if (ob1[orderByList[2]] > ob2[orderByList[2]]) {
      //     return 1;
      //   } else if (ob1[orderByList[2]] < ob2[orderByList[2]]) {
      //     return -1;
      //   }
      // }

      return 0;
    })
  }

  const executeConfig = (sponsor) => {
    const _config = props.config.find(item => {
      return item.standId === parseInt(sponsor.standId);
    });

    if (_config) {
      const stand = props.stands.find((stand) => {
        return stand.id === parseInt(sponsor.standId);
      });

      if (stand) {
        props.chosenStand(stand).then(() => {
          setChosenConfig(_config);
          setExecuteElement(true);
        });
      }
    }
  }

  const onLoadFile = (index) => {
    var change = sponsorsList.current;
    change.sponsors[index].isLoaded = true;
    sponsorsList.current = change;
    setState(state === true ? false : true);
  }

  const renderCategoryList = (category = '') => {

    var _sponsorsList = [];

    if (sponsorsList.current && sponsorsList.current.sponsors.length > 0) {
      _sponsorsList = sponsorsList.current.sponsors
        .filter(
          (e) =>
            e.category
              .toUpperCase()
              .indexOf(category ? category.toUpperCase() : '') > -1
        )
        .filter(
          (e) => {
            return e.segment
              .toUpperCase()
              .indexOf(chosenSegmentsList ? chosenSegmentsList.toUpperCase() : '') > -1
          }
        )
        .filter(
          (e) => {
            return e.name
              .toUpperCase()
              .indexOf(searchString ? searchString.toUpperCase() : '') > -1 ||
              e.segment
                .toUpperCase()
                .indexOf(searchString ? searchString.toUpperCase() : '') > -1 ||
              e.tags
                .toUpperCase()
                .indexOf(searchString ? searchString.toUpperCase() : '') > -1
          }
        )
    }

    if (_sponsorsList.length <= 0) {
      return;
    }

    // _sponsorsList = sortSponsorsList(_sponsorsList, sponsorsList.current.orderBy);

    return (
      <>
        <FormRow margin="0px" align="center" paddingTop="10px">
          <TextBig fontWeight="bold" color={textCategoryConfig.textColor}>
            {category}
          </TextBig>
        </FormRow>
        <FormRow margin="0px" align="center">
          {_sponsorsList.map((item, i) => {
            return (
              <div
                key={item.standId}
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  padding: 5,
                  cursor: "pointer",
                }}
                onClick={() => executeConfig(item)}
              >
                <Card padding="0px">
                  <>
                    <img
                      style={{
                        height: '100%',
                        width: '100%',
                        verticalAlign: 'top'
                      }}
                      src={item.image}
                      alt=""
                    />
                  </>
                  {/* <FormRow align="center" margin="0px" paddingTop="5px" paddingBottom="5px">
                    <TextMedium>
                      {item.name}
                    </TextMedium>
                  </FormRow> */}
                </Card>
              </div>
            );
          })}

        </FormRow>
      </>
    )
  }

  return (
    <Container
      background={colorBackground}
      backgroundSize="100% 100%"
      height={props.isModal ? "100%" : "100vh"}
    >
      <FormColumn
        margin="0px"
        backgroundImage={imageBackgroundUrl}
        backgroundSize="100% 100%"
      >
        <div style={{ position: "relative", width: "100%", height: "100vh", overflow: "auto" }}>
          <FormRow background={colorBackground} margin="0px" align="center">
            {!props.isModal && (
              <>
                <div>
                  <MenuButton history={props.history} />
                  <BackButton history={props.history} color="#212121">
                    {getDic("voltar").toUpperCase()}
                  </BackButton>

                </div>
                <FormRow
                  minHeight="60px"
                  align="center"
                  background={colorBackground}
                  margin="0px"
                />
              </>
            )}

            <FormColumn align="center" margin="0px" padding="0px" paddingBottom="5px">

              <FormRow
                minHeight="60px"
                align="flex-end"
                margin="0px"
                paddingTop="5px"
              >
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  minHeight: "60px",
                  background: "white",
                  marginLeft: 10,
                  marginRight: 10,
                  paddingLeft: 10,
                  paddingTop: 6,
                  borderRadius: 5
                }}>
                  <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
                    <InputFieldOutlined
                      placeholder={getDic("buscar")}
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      endAdornment={<SearchIcon />}
                    />
                  </div>
                  {segmentsList.length > 1 && (
                    <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
                      <SelectList
                        title={getDic("segmento")}
                        width="160px"
                        maxWidth="160px"
                        value={chosenSegmentsList}
                        menuItens={segmentsList}
                        onChange={(e) => setChosenSegmentsList(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </FormRow>

              {categoriesList.length > 0 ? (
                <>
                  {categoriesList.map(cat => {
                    if (cat.value === '') { return; }

                    return renderCategoryList(cat.value);
                  })}
                </>
              ) : renderCategoryList('')}
            </FormColumn>

            <Alert
              open={alertOpen}
              onClose={() => setAlertOpen(false)}
              onClick={() => setAlertOpen(false)}
              title={alertTitle}
              message={alertMessage}
            />

            <Loading open={openLoading} message={loadingMessage} />
          </FormRow>
        </div>

        {executeElement && (
          <ElementTreatment
            history={props.history}
            chosenConfig={chosenConfig}
            onClose={() => {
              setExecuteElement(false);
              setChosenConfig(null);
            }}
          />
        )}
      </FormColumn>
    </Container>
  )
}

function mapStateToProps(state) {

  return {
    event: state.event,
    user: state.user,
    config: state.config,
    stands: state.stands,
  }
}

const mapDispatchToProps = {
  chosenStand,
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsorsPage);
