import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import { connect } from 'react-redux';
import { getModalTypeList, getConfigListByPageIdApi } from '../services/configService';
import { createConfigModel, convertConfigObjectToForm } from '../models/configModel';
import { createExampleLine, createTableBase, createTableOptions, getBorderObject } from './ImporterUtils';
import moment from 'moment';
import ReactExport from "react-data-export";

//Imports de components
import {
    Alert,
    Loading,
} from '../components';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelCell = ReactExport.ExcelFile.ExcelCell;
const border = getBorderObject()

const ConfigImporter = (props) => {
    const {
        exampleFile = false,
        exportTable = false,
        data,
        button,
        groupList,
        pagesList,
        screenCodeList,
        standsList,
        pavilionsList,
    } = props;

    //Alert control
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState("");
    const [alertMessage, setAlertMessage] = React.useState("");

    //Loading control
    const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
    const [openLoading, setOpenLoading] = React.useState(false);

    const [isTableComplete, setIsTableComplete] = React.useState(false);
    const [multiDataSet, setMultiDataSet] = React.useState(null);
    const [instructionsTable, setInstructionsTable] = React.useState(null);
    const [groupTable, setGroupTable] = React.useState(null);
    const [pagesTable, setPagesTable] = React.useState(null);
    const [meetingTypeTable, setMeetingTypeTable] = React.useState(null);
    const [screenCodeTable, setScreenCodeTable] = React.useState(null);
    const [standTable, setStandTable] = React.useState(null);
    const [pavilionTable, setPavilionTable] = React.useState(null);
    const eventConfigList = React.useRef();

    //Método de retorno para sinalizar que a planilha foi renderizada
    function onDownload() {
        if (props.onDownload) {
            setTimeout(() => {
                props.onDownload();
            }, 1000);
        }
    }

    React.useEffect(() => {
        getTableData();

        return () => { }
    }, []);

    //O component funciona tanto para exportar a planilha de exemplo, como para devolver a planilha preenchida com os erros
    //Data deve ser um array de objetos do tipo createFormControlModel, conforme conversão de objeto convertConfigObjectToForm, ou uma lista de configs
    //Caso seja uma planilha de exemplo, devolver os values com os exemplos a serem seguidos

    const configFormList = convertConfigObjectToForm(createConfigModel(), null, null, null, true);
    const meetingTypes = getModalTypeList();

    function createTableInstructions() {
        var _multiDataSet = [
            {
                columns: [
                    {
                        title: getDic("coluna"),
                        style: {
                            font: { bold: true },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "9e9e9e" }
                            },
                            border: border
                        }
                    },
                    {
                        title: getDic("valor"),
                        style: {
                            font: { bold: true },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "9e9e9e" }
                            },
                            border: border
                        }
                    }
                ],
                data: []
            },
        ];

        const _rows = [];
        configFormList.forEach(item => {
            const _row = [];
            const _obj1 = {
                value: `${item.name ? item.name.toUpperCase() : ''}${item.required ? " *" : ""}`,
                style: {
                    border: border
                }
            }
            _row.push(_obj1);

            var _instruction = "";
            switch (item.dbReference) {
                case "id":
                    _instruction = "Campo numérico, para cadastrar um registro novo, deixar em branco, caso esteja preenchido, o registro existente será atualizado";
                    break;
                case "screenId":
                    _instruction = "Campo Obrigatório: Um único dado podendo ser o ID ou o Texto conforme a aba " + getDic("tela");
                    break;
                case "typeId":
                    _instruction = "Campo Obrigatório: Um único dado podendo ser o ID ou o Texto conforme a aba " + getDic("tipo");
                    break;
                case "codeScreen":
                    _instruction = "Campo Obrigatório: Um único dado podendo ser o ID ou o Texto conforme a aba " + `${getDic("codigo")} ${getDic("tela")}`;
                    break;
                case "urlString":
                    _instruction = "Campo Obrigatório: Texto comum";
                    break;
                case "tooltip":
                    _instruction = "Texto comum";
                    break;
                case "coordinatesString":
                    _instruction = "Texto comum";
                    break;
                case "standId":
                    _instruction = "Apenas para a tela Stand - Um único dado podendo ser o ID ou o Texto conforme a aba Stand";
                    break;
                case "pavilion":
                    _instruction = "Apenas para as telas Exposição e Stand - Um único dado podendo ser o ID ou o Texto conforme a aba " + getDic("pavilhao");
                    break;
                case "password":
                    _instruction = "Texto comum";
                    break;
                case "groupId":
                    _instruction = "Um ou mais dados (Caso seja mais de um, separar por vírgula ',') podendo ser o ID ou o Texto conforme a aba " + getDic("grupo");
                    break;
                case "hasFullScreen":
                    _instruction = "Campo de escolha fechado (Sim ou Não), sendo o valor 'true' para 'Sim' e 'false' para 'Não'";
                    break;
                case "initialDate":
                    _instruction = "Data no formato numérico DD/MM/AAAA-HH:MM";
                    break;
                case "endingDate":
                    _instruction = "Data no formato numérico DD/MM/AAAA-HH:MM";
                    break;
                case "description":
                    _instruction = "Texto comum";
                    break;
                case "html":
                    _instruction = "Texto comum";
                    break;
                case "levelList":
                    _instruction = "Texto comum";
                    break;
                default:
                    break;
            }

            const _obj2 = {
                value: _instruction,
                style: {
                    border: border
                }
            }
            _row.push(_obj2);
            _rows.push(_row);
        });

        _multiDataSet[0].data = _rows;

        setInstructionsTable(_multiDataSet);
    }

    function createDataList() {
        const _rows = [];
        const _data = exportTable ? eventConfigList.current : data;

        _data.forEach(item => {
            const _row = [];
            var _formObject;
            if (item[0] && item[0].dbReference) {
                _formObject = item;
            }
            else {
                _formObject = convertConfigObjectToForm(item, screenCodeList, groupList, null, true);
            }
            _formObject.forEach(obj => {
                var _value = obj.value ? obj.value.toString() : "";
                switch (obj.dbReference) {
                    case "initialDate":
                        if (!obj.error) {
                            _value = obj.value ? moment(obj.value).format("DD/MM/YYYY-HH:mm") : "";
                        }
                        else {
                            _value = obj.value;
                        }
                        break;
                    case "endingDate":
                        if (!obj.error) {
                            _value = obj.value ? moment(obj.value).format("DD/MM/YYYY-HH:mm") : "";
                        }
                        else {
                            _value = obj.value;
                        }
                        break;
                    case "hasFullScreen":
                        _value = obj.value === true ? "true" : "";
                        break;
                    default:
                        break;
                }

                const _obj = {
                    value: _value + (obj.error ? ` => ${getDic("erro")}: ${obj.errorMessage}` : ""),
                    style: {
                        border: border,
                        fill: {
                            fgColor: { rgb: obj.error ? "ff0000" : '00FFFFFF' }
                        },
                    }
                };
                _row.push(_obj);
            });
            _rows.push(_row);
        });
        return _rows;
    }

    function getTableData() {
        if (exportTable) {
            setOpenLoading(true);

            getConfigListByPageIdApi()
                .then(res => {
                    eventConfigList.current = res;
                })
                .catch(err => {
                    setAlertOpen(true);
                    setAlertTitle(getDic("erro"));
                    setAlertMessage(getDic("enviado-erro"));
                })
                .finally(() => {
                    setTableData();
                });
        }
        else {
            setTableData();
        }
    }

    function setTableData() {
        const _pagesList = createTableOptions(pagesList, getDic("tela"));
        setPagesTable(_pagesList);
        const _meetingTypeList = createTableOptions(meetingTypes, getDic("tipo"));
        setMeetingTypeTable(_meetingTypeList);
        const _screenCodeList = createTableOptions(screenCodeList, `${getDic("codigo")} ${getDic("tela")}`);
        setScreenCodeTable(_screenCodeList);
        const _standsList = createTableOptions(standsList, `Stand`);
        setStandTable(_standsList);
        const _pavilionList = createTableOptions(pavilionsList, getDic("pavilhao"));
        setPavilionTable(_pavilionList);
        const _groupList = createTableOptions(groupList, getDic("grupo"));
        setGroupTable(_groupList);
        createTableInstructions();

        var _multiDataSet = createTableBase(`${getDic("importador")} ${getDic("configuracoes")}`, configFormList);
        var _rows = [];

        if (exampleFile) {
            _rows.push(createExampleLine(configFormList));
        }
        else if (exportTable || (data && data.length > 0)) {
            _rows = createDataList();
        }

        _multiDataSet[3].data = _rows;

        setMultiDataSet(_multiDataSet);
        setIsTableComplete(true);
        setOpenLoading(false);

        onDownload();
    }

    if (data && data.length > 0 || exampleFile || exportTable) {
        return (
            <>
                {isTableComplete && (
                    <ExcelFile filename={`${getDic("configuracao")} - ID ${props.event.id} - ${moment().format("DD-MM-YYYY HH-MM")}`} element={button}>
                        <ExcelSheet dataSet={multiDataSet} name={getDic("importador")} />
                        <ExcelSheet dataSet={pagesTable} name={getDic("tela")} />
                        <ExcelSheet dataSet={meetingTypeTable} name={getDic("tipo")} />
                        <ExcelSheet dataSet={screenCodeTable} name={`${getDic("codigo")} ${getDic("tela")}`} />
                        <ExcelSheet dataSet={standTable} name={`Stand`} />
                        <ExcelSheet dataSet={pavilionTable} name={getDic("pavilhao")} />
                        <ExcelSheet dataSet={groupTable} name={getDic("grupo")} />
                        <ExcelSheet dataSet={instructionsTable} name={getDic("instrucoes")} />
                    </ExcelFile>
                )}

                <Alert
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    onClick={() => setAlertOpen(false)}
                    title={alertTitle}
                    message={alertMessage}
                />

                <Loading open={openLoading} message={loadingMessage} />
            </>
        );
    }
    return (
        <ExcelFile element={button} />
    );
}

function mapStateToProps(state) {
    return {
        event: state.event,
    }
}

export default connect(mapStateToProps, null)(ConfigImporter);
