import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const Component = props => {
    const {
        children,
        text = "",
        onClick,
        button = true
    } = props;

    return (
        <ListItem button={button} onClick={onClick}>
            <ListItemText primary={text} />
            <ListItemIcon>{children ? children : <ArrowForwardIosIcon />}</ListItemIcon>
        </ListItem>
    )
};

export default Component;
