import { CHOSEN_STAND, RESET_STAND } from '../actions';

export default function chosenStandReducer(state = null, action) {
    switch (action.type) {
        case CHOSEN_STAND:
            return action.stand;
        case RESET_STAND:
            return null;
        default:
            return state;
    }
}
