import React, { useEffect, useState } from "react";
import { getDic } from "../../assets/i18n/dictionary";
import "./assets_network/scss/theme.scss";
import { MenuButton, BackButton } from "../../components";
import { Container, Row, Col } from "reactstrap";
//Import Components
import MiniWidget from "./mini-widget";
import ListView from "./list-view";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { firestore } from "./../../utils/firebase";
import { connect } from "react-redux";
import {
  useCollectionData,
  useCollectionDataOnce,
} from "react-firebase-hooks/firestore";

//Imports de components
import ContainerProp from "../../components/Container";
import FormColumn from "../../components/FormColumn";

const Statistics = (props) => {
  const [pingUsers, setPingUsers] = React.useState([]);
  const [time, timeController] = React.useState(0);
  const pingRef = firestore.collection(`healthmap/${props.event.id}/ping`);
  //const [pingUsers] = useCollectionDataOnce(pingRef, { idField: "id" });
  React.useEffect(() => {
    pingRef.get().then((r) => {
      let f = [];
      r.docs.map((e) => {
        f.push(e.data());
      });
      setPingUsers(f);
    });
    let _i = setInterval(() => timeController(new Date()), 60000);
    return () => clearInterval(_i);
  }, [time]);
  return (
    <ContainerProp background="#e8eced" align="center">
      <FormColumn margin="0px">
        <MenuButton history={props.history} />
        <BackButton history={props.history} color="#212121">
          {getDic("voltar").toUpperCase()}
        </BackButton>

        <React.Fragment>
          <div
            className="statistic-container page-content"
            style={{ paddingTop: 40, height: "calc(100vh - 40px)" }}
          >
            <Container>
              <Row>
                <Col xl="12" style={{ padding: 0 }}>
                  <MiniWidget listData={pingUsers} />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <ListView listData={pingUsers} />
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      </FormColumn>
    </ContainerProp>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(Statistics);
