/* eslint-disable no-undef */
import React from "react";
import { getDic } from '../assets/i18n/dictionary';
import { store } from "../store";
import { connect } from "react-redux";
import { chosenStand, updateConfig } from "../store/actions";
import "../scss/EventHallPage.scss";
import Masonry from "react-masonry-component";

//Imports de components
import {
  ElementTreatment,
  ImageMapper,
  Container,
  FormRow,
  Card,
  TextMedium,
  Button
} from "../components";
import { AvVideoLabel } from "material-ui/svg-icons";

const ToolTipe = (props) => {
  return props.state.hoveredArea && props.state.tooltip !== "" ? (
    <span
      className="TooltipArea"
      style={
        {
          ...props.getTipPosition(props.state.hoveredArea),
          pointerEvents: "none",
          position: "absolute"
        }}
    >
      {props.state.tooltip}
    </span>
  ) : null;
};

class ImageMapRender extends React.Component {
  constructor(props) {
    super(props);
    const mapAreas = this.props.defaultMap;
    mapAreas.areas.forEach((area) => {
      area.fillColor = "rgba(255, 255, 255, 0.3)";
    });

    const _openConfig = this.props.configList.find(item => {
      return item.codeScreen && item.codeScreen.includes("ELEMENT-") && item.multiConfig.openWithPage;
    });
    const _renderHtml = this.props.configList.find(item => {
      return item.codeScreen === "HTML-TO-PAGE";
    });
    const _renderAsCardList = this.props.configList.find(item => {
      return item.codeScreen === "LIST-CONFIGS-ON-MAP";
    });

    this.state = {
      configList: this.props.configList,
      executeElement: _openConfig ? _openConfig : false,
      chosenConfig: _openConfig ? _openConfig : null,
      hoveredArea: null,
      tooltip: "",
      imageURLBackground_1600x900: this.props.defaultImage,
      imageSize: { width: 1600, height: 900 },
      map_1600x900: mapAreas,
      imageClass: "default",
      resizedImage: false,
      fullScreen: false,
      imgDOM: null,
      heightBody: 0,
      renderHtml: _renderHtml ? true : false,
      renderHtmlOnly: _renderHtml && _renderHtml.url[0] && _renderHtml.url[0].toUpperCase() === "TRUE" ? true : false,
      html: _renderHtml ? _renderHtml.html : null,
      renderAsCardList: _renderAsCardList ? true : false,
      elementsList: [],
      disableOnClick: this.props.disableOnClick ? true : false,
    };

  }

  async componentDidMount() {
    let _this = this;

    this.state.configList.find((item) => {
      if (item.codeScreen === "IMAGE-001") {
        var _fullScreen = false;
        if (item.hasFullScreen) {
          _fullScreen = true;
        }
        this.setState({
          imageURLBackground_1600x900: item.url[0],
          fullScreen: _fullScreen,
        });
      }
      return null;
    });

    if (this.state.chosenConfig) {
      var _newConfig = this.state.chosenConfig
      _newConfig.multiConfig.openWithPage = false;
      this.props.updateConfig(_newConfig);
    }

    let oldWidth = window.innerWidth;
    let newWidth = window.innerWidth;
    window.addEventListener("resize", function (event) {
      oldWidth = _this.state.newWidth;
      newWidth = window.innerWidth;
      if (newWidth != oldWidth) _this.setState({
        imageURLBackground_1600x900: _this.state.imageURLBackground_1600x900 + "?"
      });
      _this.setState({ newWidth: newWidth, oldWidth: oldWidth });
    });
    /* document.addEventListener('touchmove', function(event) {
      event = event.originalEvent || event;
      if (event.scale != 1) {
        window.scrollTo(0, 0);
        event.preventDefault();
      }
    }, false); */
    const image = document.querySelector(".ImageBackgroundHall img");
    _this.setState({ imgWidth: image && image.width ? image.width : null });

    if (this.state.renderAsCardList) {
      const _elements = this.state.configList.filter(conf => {
        return conf.codeScreen.includes('ELEMENT-');
      });
      _elements.sort((a, b) => (a.codeScreen > b.codeScreen) ? 1 : -1)
      this.setState({ elementsList: _elements });
    }

    if (!this.state.renderAsCardList) {
      const poweredBy = document.getElementById("poweredBy");
      if (poweredBy) poweredBy.style.position = 'absolute';
    }
  }

  componentWillUnmount() {
    const poweredBy = document.getElementById("poweredBy");
    if (poweredBy) poweredBy.style.position = 'absolute';
  }

  isOdd(num) {
    return num % 2;
  }

  checkImageSize() {
    const image = document.querySelector(".ImageBackgroundHall img");
    if (image) {
      var fullScreen = this.state.fullScreen;
      const canvas = document.querySelector(".ImageBackgroundHall canvas");
      const map = document.querySelector(".ImageBackgroundHall map");
      const initImageSize = {
        width: image.width,
        height: image.height,
      };
      var viewportSize = {
        width: document.documentElement.clientWidth,//window.innerWidth,
        height: document.documentElement.clientHeight, //window.innerHeight,
      };

      //Tratamento para monitor ultrawide ou estreitos
      //Caso a largura da imagem esteja em uma proporção abaixo de 56% em relação a altura
      //Será removido o fullscreen e estabelecido um limte máximo para a imagem não distorcer
      //Esta condição só será aplicada se o dispositivo não for mobile
      if (
        !fullScreen &&
        (viewportSize.height * 100) / viewportSize.width < 56
      ) {
        while ((viewportSize.height * 100) / viewportSize.width < 56) {
          viewportSize.width = viewportSize.width - 1;
        }
      }
      if (
        !fullScreen &&
        (viewportSize.height * 100) / viewportSize.width > 57
      ) {
        while ((viewportSize.height * 100) / viewportSize.width > 57) {
          viewportSize.height = viewportSize.height - 1;
        }
      }

      if (initImageSize.width && initImageSize.height) {
        // image.width = fullScreen ?
        //     viewportSize.width :
        //     image.width >= viewportSize.width || image.width < viewportSize.width / 1.1 ?
        //         viewportSize.width / 1.1 : image.width;
        // image.height = viewportSize.height;
        // if (canvas) {
        //     canvas.width = fullScreen ?
        //         viewportSize.width :
        //         canvas.width >= viewportSize.width || canvas.width < viewportSize.width / 1.1 ?
        //             viewportSize.width / 1.1 : canvas.width;
        //     canvas.height = viewportSize.height;
        // }



        image.width = viewportSize.width;
        image.height = viewportSize.height;
        if (canvas) {
          canvas.width = viewportSize.width;
          canvas.height = viewportSize.height;
        }
      }
      if (
        map &&
        ((initImageSize.width && image.width != initImageSize.width) ||
          (initImageSize.height && image.height != initImageSize.height))
      ) {
        this.setState({
          imageSize: {
            width: image.width,
            height: image.height
          },
        });
        for (let i = 0; i < map.children.length; i++) {
          let newCoords = [];
          if (!map || !map.children[i] || !map.children[i].coords) return;
          map.children[i].coords.split(",").forEach((value, key) => {
            let direction = !this.isOdd(key) ? "width" : "height";
            let newValue = value;
            if (!Number.isNaN(value)) {
              newValue = value * (image[direction] / initImageSize[direction]);
            }
            newCoords.push(newValue);
          });
          map.children[i].coords = newCoords.toString();
        }
        this.setState({
          resizedImage: true
        });

      }
    }
  }

  checkLibras() {
    let elem = document.querySelector("#vlibras").childNodes;
    let vLibrasEnabled;
    let elementCheck;
    if (elem.length < 1) return true;

    elem.forEach(element => {
      if (element instanceof HTMLDivElement) {
        if (element.getAttributeNames().includes("vw-access-button")) {
          elementCheck = element;
          if (element.className.indexOf("active") > -1) {
            //Aqui o libras ta fechado
            vLibrasEnabled = false;
          } else {
            vLibrasEnabled = true;
          }
        }
      }
    });


    return elementCheck.className.indexOf("active") > -1;
  }

  load() {
    let _this = this;
    this.checkImageSize();
    window.addEventListener("resize", () => {
      _this.checkImageSize.bind(_this)
    });
  }

  clicked(a) {
    if (!this.state.disableOnClick) {
      this.selectElement(a.name);
    }
  }
  changeBG(p) {
    const config = this.state.configList.find((item) => {
      return item.codeScreen === p.name;
    });
    if (config && config.url.length > 1 && config.url[1].indexOf("BACKGROUND_CHANGE") > -1) {
      let background_replace = config.url[1].split("{")[1].replace('}', "");
      this.setState({ imageURLBackground_1600x900: background_replace });
    }
  }
  enterArea(a) {
    if (!this.checkLibras()) return;
    this.setState({ hoveredArea: a, tooltip: a.tooltip });
    this.changeBG(a)
  }
  leaveArea(a) {
    if (!this.checkLibras()) return;
    this.setState({ hoveredArea: null, tooltip: null });

    this.state.configList.find((item) => {
      if (item.codeScreen === "IMAGE-001") {
        var _fullScreen = false;
        if (item.hasFullScreen) {
          _fullScreen = true;
        }
        this.setState({
          imageURLBackground_1600x900: item.url[0],
          fullScreen: _fullScreen,
        });
      }
      return null;
    });

  }
  moveOnArea(a, e) { }
  clickedOutside(e) { }
  moveOnImage(e) { }
  getTipPosition(a) {
    const image = document.querySelector(".ImageBackgroundHall img");
    const top = (a.center[1] * image.height) / 900;
    const left = (a.center[0] * image.width) / 1600;
    return { top: `${top}px`, left: `${left}px` };
  }

  async selectElement(element) {
    //Busca a configuração e executa instrução
    const config = this.state.configList.find((item) => {
      return item.codeScreen === element;
    });
    if (config) {
      if (config.standId && config.standId !== 0) {
        const stand = store.getState().stands.find((stand) => {
          return stand.id === config.standId;
        });

        if (stand) {
          this.props.chosenStand(stand).then(() => {
            this.setState({ executeElement: true, chosenConfig: config });
          });
        } else {
          this.setState({ executeElement: true, chosenConfig: config });
        }
      } else {
        this.setState({ executeElement: true, chosenConfig: config });
      }
    }
  }

  executeElement() {
    if (this.state.executeElement) {
      return (
        <ElementTreatment
          history={this.props.history}
          chosenConfig={this.state.chosenConfig}
          onClose={() => {
            this.setState({ executeElement: false, chosenConfig: null });
          }}
        />
      );
    }
  }

  generalFunction(e) {
    if (e.target.id.includes("ELEMENT")) {
      this.selectElement(e.target.id);
    }
  }

  renderCard(item, i) {
    return (
      <div
        key={item.id}
        style={{
          width: "100%",
          maxWidth: 450,
          paddingTop: 5,
        }}
      >
        {item.multiConfig.cardBackgroundImage !== '' ? (
          <>
            <img
              src={item.multiConfig.cardBackgroundImage}
              style={{ borderRadius: 5, cursor: 'pointer' }}
              width="100%"
              height="100%"
              onClick={() => this.selectElement(item.codeScreen)}
            />
          </>
        ) : (
          <Card backgroundColor='white'>
            <FormRow align="center" paddingBottom="5px">
              <TextMedium>
                {item.tooltip}
              </TextMedium>
            </FormRow>
            <FormRow align="center" paddingBottom="5px">
              <Button
                text={getDic("entrar")}
                onClick={() => this.selectElement(item.codeScreen)}
              />
            </FormRow>
          </Card>
        )}
      </div>
    );
  }

  render() {
    const mapImg = {
      ...this.state.map_1600x900,
      areas: this.state.map_1600x900.areas.filter(e => e.name.indexOf("ELEMENT-") > -1 || e.name.indexOf("STAND-") > -1)
    }

    return (
      <>
        {this.state.renderAsCardList && (
          <Container
            backgroundImage={this.state.imageURLBackground_1600x900}
            backgroundSize="100% 100%"
            height={this.props.isModal ? "100%" : "100vh"}
          >
            <div style={{
              paddingTop: 60,
              position: "relative",
              width: "100%",
              height: "100vh",
              overflow: "auto",
              paddingLeft: 10,
              paddingRight: 10
            }}>
              {this.state.elementsList.length > 0 && (
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  textAlign: "center",
                  paddingBottom: "5px"
                }}>
                  <FormRow align="center" margin="0px">
                    <Masonry
                      className={"grid"}
                      elementType={"div"}
                      options={{
                        fitWidth: true,
                        columnWidth: 450,
                        gutter: 5,
                      }}
                      disableImagesLoaded={false}
                      updateOnEachImageLoad={false}
                    >
                      {this.state.elementsList.map((item, i) => {
                        return (
                          <>
                            {this.renderCard(item, i)}
                          </>
                        )
                      })}
                    </Masonry>
                  </FormRow>
                </div>
              )}
            </div>
          </Container>
        )}
        {this.state.renderHtml && (
          <div
            onClick={e => this.generalFunction(e)}
            dangerouslySetInnerHTML={{ __html: this.state.html }}
            style={{
              position: "absolute",
              zIndex: "1"
            }}
          />
        )}
        {!this.state.renderHtmlOnly && !this.state.renderAsCardList && (
          <div className="wrapperBackgroundHall">
            <div
              className={`ImageBackgroundHall ${this.state.fullScreen ? "fullscreen" : ""
                }`}
            >
              <ImageMapper
                src={this.state.imageURLBackground_1600x900}
                map={mapImg}
                onLoad={() => this.load()}
                fillColor="rgba(255, 255, 255, 0.5)"
                onClick={(area) => this.clicked(area)}
                width={this.state.imageSize.width}
                height={this.state.imageSize.height}
                onMouseEnter={(area) => this.enterArea(area)}
                onMouseLeave={(area) => this.leaveArea(area)}
                onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
                onImageClick={(evt) => this.clickedOutside(evt)}
                onImageMouseMove={(evt) => this.moveOnImage(evt)}
                configList={this.state.configList}
              />
              <ToolTipe
                state={this.state}
                config={this.props.config}
                getTipPosition={this.getTipPosition}
              />
            </div>
          </div>
        )}
        {this.executeElement()}

      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

const mapDispatchToProps = {
  chosenStand,
  updateConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageMapRender);
