import React from 'react';

const GridRow = props => {
    const {
        children,
        backgroundColor = "white",
        autoColor //O auto color deve receber o index da lista
    } = props;

    const styles = {
        width: "100%",
        backgroundColor: autoColor ? autoColor % 2 === 0 || autoColor === 0 ? "white" : "#f2f2f2" : backgroundColor,
    }

    return (
        <table style={styles} cellspacing="0" cellpadding="0">
            <tr>
                {children}
            </tr>
        </table>
    )
};

export default GridRow;
