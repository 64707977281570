import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

const AudioPlayer = ({
    src = '',
    autoPlay = false,
}) => {
    if (!src) {
        return null;
    }
    return (
        <ReactAudioPlayer
            src={src}
            autoPlay={autoPlay}
            controls
        />
    )
};

export default AudioPlayer;
