import React from 'react';
//Imports de components
import {
    TextMedium
} from '../components';

const Terms = () => {

    return (
        <TextMedium>

        </TextMedium>
    )
};

export default Terms;
