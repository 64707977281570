export const SET_PRIVATE_CHAT_CONTROL = 'SET_PRIVATE_CHAT_CONTROL';
const setPrivateChatControlLocal = obj => ({
    type: SET_PRIVATE_CHAT_CONTROL,
    obj
});
export function setPrivateChatControl(obj) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setPrivateChatControlLocal(obj));
            resolve(true);
        });
    };
}

export const RESET_PRIVATE_CHAT_CONTROL = 'RESET_PRIVATE_CHAT_CONTROL';
const resetPrivateChatControlLocal = () => ({
    type: RESET_PRIVATE_CHAT_CONTROL,
});
export function resetPrivateChatControl() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetPrivateChatControlLocal());
            resolve(true);
        });
    };
}
