import React from 'react';
import { connect } from 'react-redux';
import { getConfigListByPage, getCoordinateList } from '../services/configService';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { isMobile } from 'react-device-detect';

//Imports de components
import {
	Container,
	FormRow,
	ActivityIndicator,

	MobileRender,
	ImageMapRender,
	ExitButton,
	MenuButton,
	Hall3D
} from '../components';

class EventHallPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			event: this.props.event,
			configList: [],
			mobileRender: false,

			//Configurações default
			imageURLBackground_1600x900: '',
			map_1600x900: {
				name: 'my-map',
				areas: [
					{
						name: 'ELEMENT-001',
						shape: 'rect',
						coords: [406, 130, 604, 249],
						tooltip: ''
					}, //, preFillColor: "transparent", fillColor: "transparent" },
					{
						name: 'ELEMENT-002',
						shape: 'rect',
						coords: [987, 130, 1185, 250],
						tooltip: ''
					}, //, preFillColor: "transparent", fillColor: "transparent" },
					{
						name: 'ELEMENT-003',
						shape: 'rect',
						coords: [638, 102, 916, 266],
						tooltip: ''
					}, //, preFillColor: "transparent", fillColor: "transparent" },
					{
						name: 'ELEMENT-004',
						shape: 'rect',
						coords: [627, 496, 487, 451],
						tooltip: ''
					}, // preFillColor: "transparent", fillColor: "transparent" },
					{
						name: 'ELEMENT-005',
						shape: 'rect',
						coords: [1089, 499, 949, 454],
						tooltip: ''
					}, //, preFillColor: "transparent", fillColor: "transparent" },
					{
						name: 'ELEMENT-006',
						shape: 'poly',
						coords: [121, 465, 430, 464, 432, 595, 237, 674, 119, 674],
						tooltip: ''
					}, //, preFillColor: "transparent", fillColor: "transparent" },
					{
						name: 'ELEMENT-007',
						shape: 'poly',
						coords: [1205, 461, 1493, 467, 1499, 674, 1407, 674, 1202, 602],
						tooltip: ''
					}, //, preFillColor: "transparent", fillColor: "transparent" },
					{
						name: 'ELEMENT-008',
						shape: 'poly',
						coords: [
							639,
							486,
							953,
							487,
							952,
							520,
							928,
							521,
							926,
							573,
							946,
							587,
							945,
							625,
							656,
							628,
							655,
							586,
							665,
							580,
							664,
							521,
							641,
							520
						],
						tooltip: ''
					}, //, preFillColor: "transparent", fillColor: "transparent" },
					{
						name: 'ELEMENT-009',
						shape: 'poly',
						coords: [544, 508, 596, 505, 595, 628, 545, 638],
						tooltip: ''
					}, //, preFillColor: "transparent", fillColor: "transparent" },
					{
						name: 'ELEMENT-010',
						shape: 'poly',
						coords: [972, 505, 1021, 508, 1019, 635, 973, 625],
						tooltip: ''
					} //, preFillColor: "transparent", fillColor: "transparent" },
				]
			}
		};
	}

	async componentDidMount() {
		//Aplica configurações
		const configList = await getConfigListByPage(this.props.match.url);

		configList.find((item) => {
			if (item.codeScreen === 'NO-MOBILE') {
				this.setState({ mobileRender: true });
			}
			return null;
		});

		const coordinateList = await getCoordinateList(this.state.map_1600x900, configList);

		this.setState({
			isLoading: false,
			configList: configList,
			map_1600x900: coordinateList
		});
		await insertNewAccess(this.props.match.url, '', getSystemActions().accessPage);
	}


	async componentWillUnmount() {
		await insertNewAccess(this.props.match.url, '', getSystemActions().exitPage);
		// window.location.reload();
	}

	componentDidCatch(e) {
		var error = e.toString();

		if (error.includes('Error: Maximum update depth exceeded')) {
			window.location.reload();
		}
	}

	render() {
		if (this.state.isLoading)
			return (
				<Container background="#e8eced" align="center">
					<FormRow align="center">
						<ActivityIndicator />
					</FormRow>
				</Container>
			);
		if (this.state.configList.find(e => e.codeScreen == 'ENABLE_HALL_3D'))
			return (
				<React.Fragment>
					{/* <MenuButton history={this.props.history} /> */}
					<MenuButton history={this.props.history} />
					<ExitButton history={this.props.history} />

					<Hall3D
						history={this.props.history}
						hall_360_url={this.state.configList.find(e => e.codeScreen == 'ENABLE_HALL_3D').url[0]}
						configList={this.state.configList}
					/>
				</React.Fragment>
			)
		return (
			<div>
				{/* <MenuButton history={this.props.history} /> */}
				<MenuButton history={this.props.history} />
				<ExitButton history={this.props.history} />

				{isMobile && this.state.mobileRender ? (
					<MobileRender
						configList={this.state.configList}
						defaultImage={this.state.imageURLBackground_1600x900}
						history={this.props.history}
					/>
				) : (
					<ImageMapRender
						configList={this.state.configList}
						history={this.props.history}
						defaultImage={this.state.imageURLBackground_1600x900}
						defaultMap={this.state.map_1600x900}
					/>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		event: state.event,
		config: state.config
	};
}

export default connect(mapStateToProps, null)(EventHallPage);
