import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const Component = props => {
    const {
        data,
        keys,
        idexedBy,
        color = 'category10',
        legendLeft = "",
        legendBottom = "",
    } = props;

    var styles = {
        barFontSize: 30,
        axisLeftFontSize: 20,
    }

    if (data.length > 5 && data.length <= 7) {
        styles.barFontSize = 25;
        styles.axisLeftFontSize = 17;
    }
    else if (data.length > 7 && data.length <= 10) {
        styles.barFontSize = 20;
        styles.axisLeftFontSize = 15;
    }
    else if (data.length > 10 && data.length <= 13) {
        styles.barFontSize = 15;
        styles.axisLeftFontSize = 12;
    }
    else if (data.length > 15 && data.length <= 17) {
        styles.barFontSize = 12;
        styles.axisLeftFontSize = 12;
    }
    else if (data.length > 17) {
        styles.barFontSize = "auto";
        styles.axisLeftFontSize = 12;
    }

    return (
        <ResponsiveBar
            data={data}
            keys={keys}
            indexBy={idexedBy}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            layout="horizontal"
            reverse={true}
            colors={{ scheme: color }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: legendBottom,
                legendPosition: 'middle',
                legendOffset: 32,
                renderTick: ({
                    opacity,
                    textAnchor,
                    textBaseline,
                    textX,
                    textY,
                    value,
                    x,
                    y
                }) => {
                    return (
                        <g
                            transform={`translate(${x},${y})`}
                            style={{ opacity }}
                        >
                            <text
                                alignmentBaseline={textBaseline}
                                textAnchor={textAnchor}
                                transform={`translate(${textX},${textY})`}
                                style={{
                                    fontSize: 20,
                                }}
                            >
                                {value}
                            </text>
                        </g>
                    )
                }
            }}
            axisLeft={{
                tickSize: 10,
                tickPadding: 2,
                tickRotation: 42,
                legend: legendLeft,
                legendPosition: 'middle',
                legendOffset: -40,
                renderTick: ({
                    opacity,
                    textAnchor,
                    textBaseline,
                    textX,
                    textY,
                    value,
                    x,
                    y
                }) => {
                    var _text = value.substring(0, 7);
                    return (
                        <g
                            transform={`translate(${x},${y})`}
                            style={{ width: 100 }}
                        >
                            <text
                                alignmentBaseline={textBaseline}
                                textAnchor={textAnchor}
                                transform={`translate(${textX},${textY})`}
                                style={{
                                    fontSize: styles.axisLeftFontSize,
                                    transform: `rotate(42deg)`,
                                }}
                            >
                                {_text}
                            </text>
                        </g>
                    )
                }

            }}
            label={function (e) {
                return (
                    <React.Fragment>
                        <tspan style={{ fontSize: styles.barFontSize, color: "white" }}>{`${e.data.quantity} (${e.data.percentage})`}</tspan>
                    </React.Fragment>
                );
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="#f7fdfb"
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ],
                }
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
        />
    )
};

export default Component;

// const data = [
//     {
//         "country": "AD",
//         "hot dog": 30,
//     },
//     {
//         "country": "AE",
//         "hot dog": 66,
//     },
//     {
//         "country": "AF",
//         "hot dog": 178,
//     },
//     {
//         "country": "AG",
//         "hot dog": 64,
//     },
//     {
//         "country": "AI",
//         "hot dog": 180,
//     },
//     {
//         "country": "AL",
//         "hot dog": 84,
//     },
//     {
//         "country": "AM",
//         "hot dog": 103,
//     }
// ]
