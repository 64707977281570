import { SET_PAVILIONS, RESET_PAVILIONS } from '../actions';

const INITIAL_STATE = [];

export default function pavilionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_PAVILIONS:
            return [...state, ...action.pavilions];
        case RESET_PAVILIONS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
