import React from "react";
import { connect } from "react-redux";
import { getDic } from "../assets/i18n/dictionary";
import { insertNewAccess, getSystemActions } from "../services/accessService";
import { createEventModel } from '../models/eventModel';
import { createConfigModel } from '../models/configModel';
import { getUserByEmailOrCpfOrInternalCode } from '../services/userService';
import {
    userLoginSuccess,
    setEvent,
} from "../store/actions";

//Imports de components
import {
    MenuButton,
    BackButton,

    Alert,
    Loading,
    Container,
    FormRow,
    Chat
} from "../components";

const ChatPublicPage = (props) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isInternal, setIsInternal] = React.useState(
        parseInt(props.match.params.internal) > 0 ? true : false
    );
    const [chosenConfig, setChosenConfig] = React.useState(null);

    //Alert control
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState("");
    const [alertMessage, setAlertMessage] = React.useState("");

    //Loading control
    const [loadingMessage, setLoadingMessage] = React.useState(
        getDic("carregando")
    );
    const [openLoading, setOpenLoading] = React.useState(true);

    React.useEffect(() => {
        //Trava para impedir que acesso remoto possa visualizar recursos do sistema
        if (isInternal) {
            if (
                !props.user ||
                props.user.id === 0 ||
                !props.event ||
                props.event.id === 0
            ) {
                props.history.replace("/");
            }
            else {
                setOpenLoading(false);
                setIsLoading(false);
            }
        }
        else {
            getUserInfo();
        }

        insertNewAccess(props.match.url, "", getSystemActions().accessPage);

        return () => {
            insertNewAccess(props.match.url, "", getSystemActions().exitPage);
        };
    }, []);

    const getUserInfo = () => {
        setOpenLoading(true);

        getUserByEmailOrCpfOrInternalCode(
            parseInt(props.match.params.id),
            7,
            "",
            null,
            null,
            null,
            null,
            props.match.params.userid
        )
            .then(async res => {
                if (res && res.id && res.id > 0) {
                    await props.userLoginSuccess(res);

                    const _event = createEventModel(
                        parseInt(props.match.params.id),
                    );
                    await props.setEvent(_event);

                    const _isConfig = props.match.params.configtype.toUpperCase() === "CONFIG" ? true : false;
                    const _config = createConfigModel(
                        _isConfig ? parseInt(props.match.params.configid) : 0,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        parseInt(props.match.params.id),
                        null,
                        null,
                        null,
                        null,
                        !_isConfig ? parseInt(props.match.params.configid) : 0,
                    );
                    setChosenConfig(_config);

                    setIsLoading(false);
                }
                else {
                    setAlertTitle(getDic("erro"));
                    setAlertMessage(getDic("usuario-nao-localizado"));
                    setAlertOpen(true);
                }
            })
            .catch(err => {
                setAlertTitle(getDic("erro"));
                setAlertMessage(getDic("usuario-nao-localizado"));
                setAlertOpen(true);
            })
            .finally(() => {
                setOpenLoading(false);
            });
    }

    return (
        <Container background="#e8eced" align="center">
            <FormRow align="center" background="white" margin="0px">
                {isInternal ? (
                    <div>
                        <MenuButton history={props.history} />
                        <BackButton history={props.history} color="#212121">
                            {getDic("voltar").toUpperCase()}
                        </BackButton>


                        <FormRow
                            minHeight="60px"
                            height="5vh"
                            align="center"
                            background="#e8eced"
                            margin="0px"
                        />
                    </div>
                ) : null}
                <FormRow margin="0px">
                    {!isLoading && (
                        <Chat
                            chosenConfig={chosenConfig}
                            height={isInternal ? "80vh" : "100vh"}
                        />
                    )}
                </FormRow>
            </FormRow>

            <Alert
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                onClick={() => setAlertOpen(false)}
                title={alertTitle}
                message={alertMessage}
            />

            <Loading open={openLoading} message={loadingMessage} />
        </Container>
    );
};

function mapStateToProps(state) {
    return {
        event: state.event,
        user: state.user,
    };
}

const mapDispatchToProps = {
    userLoginSuccess,
    setEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatPublicPage);
