import axios from "axios";
import { store } from "../store";
import { getUrl, getToken } from "./apiService";
import { createLevelModel } from "../models/levelModel";

export async function getLevelsApi() {

  var method = getUrl().hall + "event/gamification/level?eventId=" + store.getState().event.id.toString();
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    console.log(options)
    axios(options)
      .then((res) => {
        if (res && res.data) {
          const _levels = [];

          res.data.forEach(item => {

            if (item.active) {
              const _level = createLevelModel(
                item.id,
                item.eventId,
                item.name,
                item.initialValue,
                item.finalValue,
              );
              _levels.push(_level);
            }
          });

          resolve(_levels);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getLevelsApi", err);
        if (err.toString().includes("status code 404")) {
          resolve([]);
        }
        else {
          reject(err);
        }
      });
  });
}

export async function addNewLevelApi(level) {
  var method = getUrl().hall + "event/gamification/level";
  const token = await getToken();
  const _event = store.getState().event;

  const _level = {
    eventId: _event.id,
    name: level.name,
    initialValue: parseInt(level.initialValue),
    finalValue: parseInt(level.finalValue),
  };

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: _level,
    };
    axios(options)
      .then((res) => {
        if (res && res.data.success) {
          const _objReturn = createLevelModel(
            res.data.data.id,
            _event.id,
            res.data.data.name,
            res.data.data.initialValue,
            res.data.data.finalValue,
          );

          resolve(_objReturn);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro addNewLevelApi", err);
        reject(err);
      });
  });
}

export async function updateLevelApi(level) {
  var method = getUrl().hall + "event/gamification/level";
  const token = await getToken();
  const _event = store.getState().event;

  const _level = {
    id: level.id,
    eventId: _event.id,
    name: level.name,
    initialValue: parseInt(level.initialValue),
    finalValue: parseInt(level.finalValue),
  };

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: _level,
    };
    axios(options)
      .then((res) => {
        if (res && res.data.success) {
          const _objReturn = createLevelModel(
            res.data.data.id,
            _event.id,
            res.data.data.name,
            res.data.data.initialValue,
            res.data.data.finalValue,
          );

          resolve(_objReturn);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro updateLevelApi", err);
        reject(err);
      });
  });
}

export async function deleteLevelApi(level) {
  var method = getUrl().hall + "event/gamification/level/" + level.id;
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      }
    };
    axios(options)
      .then((res) => {
        if (res && res.data) {
          resolve(true);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log("Erro deleteLevelApi", err);
        reject(err);
      });
  });
}
