import React from 'react';
import { getDic } from "../../assets/i18n/dictionary";
import {
  FormRow,
  TextMedium,
  GridCell,
  GridRow,
  Modal,
  Container,
  FormColumn
} from "../../components";

const ModalWinners = props => {
  const {
    modalWinnersControl,
    setModalWinnersControl,
  } = props;

  return (
    <Modal
      isVideo
      height="100%"
      open={modalWinnersControl.open}
      closeButtonClick={(e) => setModalWinnersControl({ ...modalWinnersControl, open: false })}
      onClose={(ret) => setModalWinnersControl({ ...modalWinnersControl, open: false })}
    >
      <Container background="#e8eced" align="left" height="100%">
        <FormColumn align="center" margin="0px" padding="5px">
          <FormRow align="center" margin="0px">
            <TextMedium fontWeight="bold">{getDic("vencedores")}</TextMedium>
          </FormRow>
          {renderGuestsList()}
        </FormColumn>
      </Container>
    </Modal>
  )

  function renderGuestsList() {
    return (
      <div
        style={{
          height: "70vh",
          backgroundColor: "transparent",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "5px",
            backgroundColor: "transparent",
            height: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <GridRow backgroundColor="#d9d9d9">
            <GridCell width="10%" border top first>
              <TextMedium>ID</TextMedium>
            </GridCell>
            <GridCell width="45%" border top>
              <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="45%" border top>
              <TextMedium>{getDic("email").toUpperCase()}</TextMedium>
            </GridCell>
          </GridRow>

          {modalWinnersControl.guestsList.length > 0 ? (
            modalWinnersControl.guestsList.map((guest, i) => {
              return (
                <GridRow
                  key={guest.id}
                  autoColor={i}
                >
                  <GridCell width="10%" border first>
                    <TextMedium>{guest.guestId}</TextMedium>
                  </GridCell>
                  <GridCell width="45%" border>
                    <TextMedium>{guest.guestName}</TextMedium>
                  </GridCell>
                  <GridCell width="45%" border>
                    <TextMedium>{guest.guestEmail}</TextMedium>
                  </GridCell>
                </GridRow>
              );
            })
          ) : (
            <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
          )}
        </div>
      </div>
    )
  }
};

export default ModalWinners;
