import React from 'react';

const VideoPlayer = ({
    extension = null,
    onClick,
}) => {

    const _fileIcon = "https://s3.amazonaws.com/bucket.aws.public/2021/212319.PNG";
    const _excelIcon = "https://s3.amazonaws.com/bucket.aws.public/2021/244600.PNG";
    const _exeIcon = "https://s3.amazonaws.com/bucket.aws.public/2021/212324.PNG";
    const _pdfIcon = "https://s3.amazonaws.com/bucket.aws.public/2021/238014.PNG";
    const _pptIcon = "https://s3.amazonaws.com/bucket.aws.public/2021/238016.PNG";
    const _jpegIcon = "https://s3.amazonaws.com/bucket.aws.public/2021/238013.PNG";
    const _zipIcon = "https://s3.amazonaws.com/bucket.aws.public/2021/238017.PNG";
    const _docIcon = "https://s3.amazonaws.com/bucket.aws.public/2021/244596.PNG";
    const _csvIcon = "https://s3.amazonaws.com/bucket.aws.public/2021/244595.PNG";
    const _mp3Icon = "https://s3.amazonaws.com/bucket.aws.public/2021/275338.PNG";
    const _txtIcons = "https://s3.amazonaws.com/bucket.aws.public/2021/244599.PNG";

    var _icon = _fileIcon;

    var _extension = extension ? extension.toUpperCase() : extension;
    switch (_extension) {
        case "PPT":
            _icon = _pptIcon;
            break;
        case "PPTX":
            _icon = _pptIcon;
            break;
        case "XLS":
            _icon = _excelIcon;
            break;
        case "XLSX":
            _icon = _excelIcon;
            break;
        case "EXE":
            _icon = _exeIcon;
            break;
        case "PDF":
            _icon = _pdfIcon;
            break;
        case "JPEG":
            _icon = _jpegIcon;
            break;
        case "JPG":
            _icon = _jpegIcon;
            break;
        case "ZIP":
            _icon = _zipIcon;
            break;
        case "DOC":
            _icon = _docIcon;
            break;
        case "DOCX":
            _icon = _docIcon;
            break;
        case "CSV":
            _icon = _csvIcon;
            break;
        case "MP3":
            _icon = _mp3Icon;
            break;
        case "TXT":
            _icon = _txtIcons;
            break;
        default:
            break;
    }

    return (
        <img
            src={_icon}
            width="100px"
            height="100px"
            onClick={onClick}
            style={{ cursor: "pointer" }}
        />
    )
};

export default VideoPlayer;
