import axios from 'axios';
import { store } from '../store';
import qs from 'qs';
import * as cred from '../assets/files';

export async function initEngage(chosenConfig, onSuccess, onError) {
    return new Promise(async (resolve, reject) => {
        try {
            const event = store.getState().event;
            const user = store.getState().user;
            var studentId = user.email;
            var studentName = user.name;

            if (!studentId) throw new Error('student_id is undefined');
            if (!studentName) throw new Error('student_name is undefined');

            // var credentials = await loadCredentials();
            var credentials = cred[event.id.toString()] ? cred[event.id.toString()] : cred.credentials;

            if (credentials) {
                var codes = user.engageCode ? user.engageCode.split("_") : [];

                //Verifica se a config possui tracks ou grupos
                const _groupsList = [];
                const _tracksList = [];
                if (chosenConfig &&
                    chosenConfig.urlString &&
                    (chosenConfig.urlString.includes("trackId") || chosenConfig.urlString.includes("groupId"))
                ) {
                    const _trackArray = chosenConfig.urlString.split(",");
                    _trackArray.forEach(item => {
                        var _track = item.split(":");
                        if (_track[0] === "trackId") {
                            const _obj = {
                                trackId: parseInt(_track[1]),
                                gameProfileId: 2
                            }
                            _tracksList.push(_obj);
                        }
                        if (_track[0] === "groupId") {
                            _groupsList.push(parseInt(_track[1]));
                        }
                    });
                }

                if (_groupsList.length > 0) {
                    credentials.groupIds = _groupsList;
                }
                else {
                    const _groups = codes[0] ? codes[0].split(";") : [];
                    _groups.forEach(item => {
                        _groupsList.push(parseInt(item));
                    });
                    credentials.groupIds = _groupsList;
                }

                if (_tracksList.length > 0) {
                    credentials.tracks = _tracksList;
                }
                else {
                    const _tracks = codes[1] ? codes[1].split(";") : [];
                    _tracks.forEach(item => {
                        const _obj = {
                            trackId: parseInt(item),
                            gameProfileId: 2
                        }
                        _tracksList.push(_obj);
                    });
                    credentials.tracks = _tracksList;
                }

                studentName = sanitizeName(studentName);

                var adminToken = await getAccessTokenEngage(
                    credentials.userNameAdm,
                    credentials.pswrdAdm,
                    credentials
                );
                //console.log('ADMINTOKEN: ' + adminToken);

                var _user = getUserObject(studentName, studentId, credentials);

                //console.log('USER: ' + adminToken);

                //Faz merge do cadastro do usuário (Se não existir cria, se existir, atualiza)
                var mergeUserDataValue = await mergeUserData(
                    adminToken,
                    _user,
                    credentials
                );
                //console.log('MERGEUSERDataValue: ' + mergeUserDataValue);

                var authKey = await getAuthKeyEngage(
                    _user[0].login,
                    credentials.defaultPswrd,
                    credentials
                );
                //console.log('49- USER Key: ' + authKey);

                // e loga!
                var urlIframe = accessEngage(authKey, credentials, onSuccess);
                resolve(urlIframe);
                //console.log('URL IFRAME: ' + urlIframe);
            } else {
                reject("Credenciais não cadastradas");
            }
        } catch (err) {
            console.error(err);
            reject(err);
        }
    });
}

function sanitizeName(_name) {
    var name = '';
    if (_name.indexOf(',')) {
        for (var i = 0; i < _name.split(',').length; i++) {
            name += _name.split(',').reverse()[i];
            if (i < _name.split(',').length - 1) name += ' ';
        }
    }
    return name;
}

function loadCredentials() {

    return new Promise((resolve, reject) => {

        const options = {
            url: 'https://s3.amazonaws.com/bucket.aws.public/hall_denis/credentials.json',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        axios(options)
            .then(async json => {
                resolve(json);
            })
            .catch(err => {
                console.error(err);
                reject(new Error('Credentials not found'));
            });
    });
}

function getAccessTokenEngage(_username, _password, credentials) {

    return new Promise((resolve, reject) => {

        const options = {
            url: credentials.ENGAGE_API_URL + 'auth',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: qs.stringify({
                client_id: credentials.client_id,
                client_secret: credentials.client_secret,
                customer_id: credentials.customer_id,
                username: _username,
                password: _password,
                grant_type: 'password',
            }),
        };
        axios(options)
            .then(_result => {
                if (_result.data && _result.data.access_token) {
                    resolve(_result.data.access_token);
                }
                else {
                    reject(new Error('Não foi possível obter o access token'));
                }
            })
            .catch(err => {
                console.error(err);
                reject(new Error('Falha ao obter access token'));
            });
    });
}

function getAuthKeyEngage(_username, _password, credentials) {

    return new Promise((resolve, reject) => {

        const options = {
            url: credentials.ENGAGE_API_URL + 'auth',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: qs.stringify({
                client_id: credentials.client_id,
                client_secret: credentials.client_secret,
                customer_id: credentials.customer_id,
                username: _username,
                password: _password,
                grant_type: 'password',
            }),
        };
        axios(options)
            .then(_result => {
                if (_result.data && _result.data.key) {
                    resolve(_result.data.key);
                }
                else {
                    reject(new Error('Não foi possível obter a key'));
                }
            })
            .catch(err => {
                console.error(err);
                reject(new Error('Falha ao obter a key'));
            });
    });
}


function mergeUserData(_accessToken, _user, credentials) {
    return new Promise((resolve, reject) => {
        var _authorization = 'Bearer ' + _accessToken;

        const options = {
            url: credentials.ENGAGE_API_URL + credentials.customer_id + '/users/?customerToken=' + credentials.customerToken,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": _authorization,
            },
            data: JSON.stringify(_user),
        };

        axios(options)
            .then(async result => {

                if (
                    result &&
                    Array.isArray(result.results) &&
                    result.results[0] &&
                    result.results[0].success === false
                ) {
                    return reject(new Error('Ocorreu um erro ao criar o usuário'));
                } else {
                    return resolve('ok');
                }
                return resolve(result);
            })
            .catch(result => {
                var msg =
                    result &&
                        result.responseJSON &&
                        result.responseJSON.errors &&
                        result.responseJSON.errors[0] &&
                        result.responseJSON.errors[0].message
                        ? result.responseJSON.errors[0].message
                        : '';
                reject(
                    new Error('Ocorreu um erro ao tentar criar o usuário: ' + msg)
                );
            });
    });
}

function getUserObject(_name, _login, credentials) {
    var userTracks = new Array();
    var userGroups = new Array();

    for (var i = 0; i < credentials.tracks.length; i++) {
        userTracks.push({
            track_id: credentials.tracks[i].trackId,
            game_profile_id: credentials.tracks[i].gameProfileId,
            is_main: i == 0 ? true : false,
        });
    }

    for (var i = 0; i < credentials.groupIds.length; i++) {
        userGroups.push(credentials.groupIds[i]);
    }

    return [
        {
            name: _name,
            email: null,
            login: _login,
            status: true,
            manager_login: '',
            external_code: null,
            document: null,
            tracks: userTracks,
            groups: userGroups,
        },
    ];
}


function accessEngage(_authkey, credentials, _callback) {
    // var engageBaseUrl = credentials.ENGAGE_API_URL.replace('/api/v1', '');
    //var engageUrl = engageBaseUrl + 'EngageCloudPass/CustomSSO/?token=' + _accessToken;
    //if (_callback) _callback(engageUrl, credentials.openNewWindow);

    var engageUrl = credentials.ENGAGE_WEBAPP_URL + '#/login?key=' + _authkey;

    return engageUrl;
}
