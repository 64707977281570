import React from 'react';
import { isIOS } from "react-device-detect";
import { getDic } from "../../assets/i18n/dictionary";
import { FormRow } from '../';
import research0 from "../../assets/images/research/research-0.svg";
import research1 from "../../assets/images/research/research-1.svg";
import research2 from "../../assets/images/research/research-2.svg";
import research3 from "../../assets/images/research/research-3.svg";
import research4 from "../../assets/images/research/research-4.svg";
import research5 from "../../assets/images/research/research-5.svg";
import research6 from "../../assets/images/research/research-6.svg";
import research7 from "../../assets/images/research/research-7.svg";
import research8 from "../../assets/images/research/research-8.svg";
import research9 from "../../assets/images/research/research-9.svg";
import research10 from "../../assets/images/research/research-10.svg";
import whatsapp from "../../assets/images/transmission/contact/whatsapp.svg";
import whatsappHighlight from "../../assets/images/transmission/contact/whatsapp-highlight.svg";
import operator from "../../assets/images/transmission/contact/video.svg";
import phone from "../../assets/images/transmission/contact/fone.svg";

const WrapperFooter = props => {
  const {
    emojis,
    emojiBox,
    downloadBox,
    downloadInstructionsText,
    downloadIcon,
    downloadButtonColor,
    researchBox,
    idResearch,
    downloadFile,
    sendAnswers,
    msgResearch,
    questionBox,
    propsQuestionBox,
    questions,
    lang,
    makeYourQuestionText,
    contractImgOn,
    textAreaRef,
    handleChange,
    phoneImgOn,
    phoneHighlight,
    roomPhoneZoom,
    zoomPhoneNumber,
    operatorImgOn,
    operatorHighlight,
    roomZoom,
    zoomNumber,
    zoomPassword,
    whatsappImgOn,
    roomWhatsapp,
    whatsappPhoneNumber,
    zoomUrl,
    zoomPhoneUrl,
    whatsappUrl,
    getContractHighlight,
    getContract,
    setItemContatAtive,
    msgQuestion,
    idPergunta,
    msgPergunta,
    idEmoji,
    setEmojiConfig,
    buttonSendColor,
    windowWidth
  } = props;

  // if (isIOS) {
  //   return renderIos();
  // }
  return (
    <div className="wrapper-footer">
      {renderControl()}
    </div>
  );

  function renderIos() {
    return (
      <FormRow margin='0px' minHeigth='600px'>
        {renderControl()}
      </FormRow>
    )
  }

  function renderControl() {
    return (
      <>
        {(emojiBox || downloadBox) && emojis.count.length > 0 && (
          <div className="wrapper-footer-first-column">
            {emojiBox && (
              <div className="emoji-container">
                {renderEmojisList()}
              </div>
            )}
            {downloadBox && (
              <div className="download-container">
                <img src={downloadIcon} />
                {downloadInstructionsText ? (
                  <article>
                    {downloadInstructionsText}
                  </article>
                ) : (
                  <article>
                    <strong>{getDic("baixa-aqui").toUpperCase()}</strong>{" "}
                    {getDic("apresentacao-completa").toUpperCase()}
                  </article>
                )}
                <button onClick={downloadFile} style={downloadButtonColor !== '' ? { background: downloadButtonColor } : {}}>
                  <span>Download</span>
                </button>
              </div>
            )}
          </div>
        )}

        {researchBox && (
          <div className="research-container">
            <span>
              {`${getDic("sua-avaliacao").toUpperCase()}`}
            </span>
            <div className="research-container-icon">
              {/* <img
                src={research0}
                alt="research0"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 0 }],
                    "research"
                  )
                }
              /> */}
              <img
                src={research1}
                alt="research1"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 1 }],
                    "research"
                  )
                }
              />
              <img
                src={research2}
                alt="research2"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 2 }],
                    "research"
                  )
                }
              />
              <img
                src={research3}
                alt="research3"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 3 }],
                    "research"
                  )
                }
              />
              <img
                src={research4}
                alt="research4"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 4 }],
                    "research"
                  )
                }
              />
              <img
                src={research5}
                alt="research5"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 5 }],
                    "research"
                  )
                }
              />
              <img
                src={research6}
                alt="research6"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 6 }],
                    "research"
                  )
                }
              />
              <img
                src={research7}
                alt="research7"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 7 }],
                    "research"
                  )
                }
              />
              <img
                src={research8}
                alt="research8"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 8 }],
                    "research"
                  )
                }
              />
              <img
                src={research9}
                alt="research9"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 9 }],
                    "research"
                  )
                }
              />
              <img
                src={research10}
                alt="research10"
                onClick={() =>
                  sendAnswers(
                    [{ attributeId: idResearch, value: 10 }],
                    "research"
                  )
                }
              />
            </div>
            {msgResearch !== "" && (
              <div className="research-container-msg">
                <span>{msgResearch}</span>
              </div>
            )}
          </div>
        )}

        {propsQuestionBox && questionBox && (
          <div className={questionBox ? "anottations-container" : "anottations-container-hidden"}>
            {propsQuestionBox && questionBox && <div className="anottations-title">
              <img src={questions} />
              <article>
                {
                  lang.lang === "sp" ? makeYourQuestionText.sp :
                    lang.lang === "en" ? makeYourQuestionText.en :
                      makeYourQuestionText.pt
                }
              </article>
            </div>}

            {contractImgOn &&
              <>
                <div style={{ paddingTop: 5 }}></div>
                <div className="anottations-chat" style={{ paddingTop: 5 }}>
                  <textarea
                    ref={textAreaRef}
                    onBlur={(event) => handleChange(event)}
                  />
                </div>
              </>
            }
            {phoneImgOn && (<div className="phone-call-container" >
              <div className="phone-call" >
                <img src={phoneHighlight} />
                <div className="phone-right-text">
                  <div><span onClick={() => roomPhoneZoom()}>Clique</span>{` para ligar`}</div>
                  <div>{zoomPhoneNumber}</div>
                </div>
              </div>
            </div>)}
            {operatorImgOn &&
              (<div className="video-class-container">
                <div className="phone-call" >
                  <img src={operatorHighlight} />
                  <div className="video-right-text">
                    <div><span onClick={() => roomZoom()}>{getDic("click")}</span>{` ${getDic("para")} ${getDic("acessar")} ${getDic("o")} ${getDic("video")}`}</div>
                    <div>{`ID ${getDic("reuniao")}: ${zoomNumber}`}</div>
                    <div>{`${getDic("senha")}: ${zoomPassword}`}</div>
                  </div>
                </div>
              </div>)
            }
            {whatsappImgOn &&
              <div className="whatsapp-container">
                <div className="whatsapp" >
                  <img src={whatsappHighlight} />
                  <div className="whatsapp-right-text">
                    <div><span onClick={() => roomWhatsapp()}>Clique</span>{` para ligar`}</div>
                    <div>{whatsappPhoneNumber}</div>
                  </div>
                </div>
              </div>
            }
            <div className="container-button-send">
              <div className={questionBox ? "contact-container" : "contact-container-hidden"}>
                {((zoomUrl && zoomNumber && zoomPassword) || (zoomPhoneUrl && zoomPhoneNumber) || (whatsappUrl && whatsappPhoneNumber)) && <img src={contractImgOn ? getContractHighlight() : getContract()} onClick={() => setItemContatAtive('contract')} />}
                {zoomPhoneUrl && zoomPhoneNumber && <img src={phoneImgOn ? phoneHighlight : phone} onClick={() => setItemContatAtive('phone')} />}
                {zoomUrl && zoomNumber && zoomPassword && <img src={operatorImgOn ? operatorHighlight : operator} onClick={() => setItemContatAtive('operator')} />}
                {whatsappUrl && whatsappPhoneNumber && <img src={whatsappImgOn ? whatsappHighlight : whatsapp} onClick={() => setItemContatAtive('whatsapp')} />}
              </div>

              {contractImgOn && <div className="question-container-msg">
                <span>{msgQuestion}</span>
              </div>}

              {contractImgOn && <button
                className="button-send"
                style={buttonSendColor !== '' ? { background: buttonSendColor } : {}}
                onClick={(e) => {
                  sendAnswers(
                    [{ attributeId: idPergunta, value: msgPergunta }],
                    "question"
                  );
                  textAreaRef.current.value = "";
                }}
              >
                {getDic("enviar").toUpperCase()}
              </button>}
            </div>
          </div>
        )}
      </>
    )
  }

  function renderEmojisList() {
    return (
      <>
        {emojis.count.map(c => {
          let _key = `em${c < 10 ? '0' : ''}${c}`;
          if (emojis.list[_key] === 'REMOVE') {
            return null;
          }
          return (
            <button
              onClick={() => {
                sendAnswers(
                  [{ attributeId: idEmoji, value: c }],
                  c > 1 ? "emoji" : null
                );
                setEmojiConfig(c);
              }}
            >
              <img src={emojis.list[_key]} />
            </button>
          )
        })}
      </>
    )
  }
};

export default WrapperFooter;
