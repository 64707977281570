import { SET_TOKEN } from '../actions';
import * as tokenModel from '../../models/tokenModel';

const INITIAL_STATE = tokenModel.createTokenModel();

export default function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_TOKEN:
            return { ...state, ...action.token };
        default:
            return state;
    }
}
