import { getDic } from '../assets/i18n/dictionary';
import { getModalTypeList } from '../services/configService';
import { createFormControlModel } from '../models/formControlModel';
import { store } from '../store';
import moment from 'moment';

export function createScheduleModel(
    id = 0,
    eventId = 0,
    title = "",
    description = "",
    startDate = "",
    maximumNumberOfGuets = 0,
    numberOfGuests = 0,
    startTime = null,
    endTime = null,
    status = "",
    meetingCode = "",
    meetingType = 0,
    hasMeeting = false,
    speakers = [], //SpeakerChild
    files = [], //FilesChild
    scheduleXGroup = [], //objeto = {"id": 1, "scheduleId": 902, "groupId": 15996}
    styles = "",
    multiConfig = null, // <== Igual ao do configModel
    restrictAccess = false,
    password = "",
    scheduleType = [], // Evento = "E", Participante = "P", Operacional = "O"
) {
    //Tratamento da informação dos grupos para setar apenas um array de ids
    var groups = [];
    if (scheduleXGroup && scheduleXGroup.length > 0) {
        scheduleXGroup.forEach(item => {
            if (item.groupId) {
                groups.push(item.groupId);
            }
            else {
                groups.push(item);
            }
        });
    }

    //Cria os estilos para o cartão
    var defaultStyles = createCardStyles();

    if (styles && styles !== "") {
        var stylesStrList = styles.replace(/ /g, "").split(",");
        stylesStrList.forEach(item => {
            var prop = item.split(":");
            defaultStyles[prop[0]] = prop[1];
        });
    }

    //Tratamento específico para speakerName
    if (styles && styles.includes("speakerName")) {
        try {
            var _speakerNameList = styles.split("speakerName:[");
            _speakerNameList = _speakerNameList[1].split("]");
            _speakerNameList = _speakerNameList[0].split(";");
            const _speakers = [];
            _speakerNameList.forEach(speaker => {
                _speakers.push(speaker);
            });
            defaultStyles.speakerName = _speakers;
        } catch (error) {
            console.log("Erro ao montar lista de palestrantes", error);
            defaultStyles.speakerName = [];
        }
    }

    //Cria string para as horas
    var _startTimeStr = "";
    var _endTimeStr = "";
    if (startTime && startTime.hasValue) {
        _startTimeStr = (startTime.value.hours < 10 ? "0" + startTime.value.hours.toString() : startTime.value.hours.toString()) + ":"
            + (startTime.value.minutes < 10 ? "0" + startTime.value.minutes.toString() : startTime.value.minutes.toString());
    }
    else {
        _startTimeStr = startTime ? startTime.substring(0, 5) : "";
    }
    if (endTime && endTime.hasValue) {
        _endTimeStr = (endTime.value.hours < 10 ? "0" + endTime.value.hours.toString() : endTime.value.hours.toString()) + ":"
            + (endTime.value.minutes < 10 ? "0" + endTime.value.minutes.toString() : endTime.value.minutes.toString());
    }
    else {
        _endTimeStr = endTime ? endTime.substring(0, 5) : "";
    }

    const res = {
        id: id,
        eventId: eventId,
        title: title,
        description: description,
        scheduleType: scheduleType,
        startDate: startDate,
        startTime: _startTimeStr,
        endTime: _endTimeStr,
        maximumNumberOfGuets: maximumNumberOfGuets,
        numberOfGuests: numberOfGuests,
        status: status,
        meetingCode: meetingCode,
        meetingType: meetingType,
        hasMeeting: hasMeeting,
        speakers: speakers,
        files: files,
        groupId: groups,
        styles: defaultStyles,
        stylesString: styles,
        multiConfig: multiConfig,
        restrictAccess: restrictAccess,
        password: password,
    }

    return res;
}

export function createSpeakerChildModel(
    id = 0,
    name = "",
    image = "",
) {
    const res = {
        id: id,
        name: name,
        image: image
    }

    return res;
}

export function createFileChildModel(
    id = 0,
    name = "",
    url = "",
) {
    const res = {
        id: id,
        name: name,
        url: url
    }

    return res;
}

function createCardStyles() {
    const obj = {
        backgroundCard: "white",
        textColorCard: "black",
        backgroundButton: "#3f51b5",
        textColorButton: "white",
        titleUpperCase: "false",
        hideDate: "false",
        descriptionConfigId: null,
        enterButtonConfigId: null,
        speakerName: [],
    }
    return obj;
}

function getRestrictAccessList() {
    const _obj = [
        {
            text: getDic("livre"),
            value: false
        },
        {
            text: getDic("acesso-restrito"),
            value: true
        },
    ]
    return _obj;
}

export function getTimeObject() {
    const _time = {
        hasValue: true,
        value: {
            ticks: 0,
            days: 0,
            hours: 0,
            milliseconds: 0,
            minutes: 0,
            seconds: 0,
            totalDays: 0,
            totalHours: 0,
            totalMilliseconds: 0,
            totalMinutes: 0,
            totalSeconds: 0
        }
    }
    return _time;
}

export function convertScheduleObjectToForm(schedule, groupList, speakersList) {
    const _form = [];

    var _index = 0;

    for (var key in schedule) {
        if (schedule.hasOwnProperty(key)) {

            var _hasForm = false;
            var _type = "text";
            var _options = null;
            var _required = true;
            var _name = "";
            var _value = schedule[key] ? schedule[key].toString() : null;

            switch (key.toString()) {
                case "id":
                    _hasForm = true;
                    _name = "id";
                    _required = true;
                    _value = parseInt(schedule[key]);
                    break;
                case "title":
                    _hasForm = true;
                    _name = getDic("titulo");
                    _required = true;
                    break;
                case "description":
                    _hasForm = true;
                    _name = getDic("descricao");
                    _required = false;
                    break;
                case "startDate":
                    _hasForm = true;
                    _name = getDic("data");
                    _required = true;
                    _type = "date";
                    break;
                case "startTime":
                    var _date = moment().format().split("T");
                    _value = `${_date[0]}T${_value ? _value : "00:00"}:00-03:00`;
                    _hasForm = true;
                    _name = `${getDic("hora")} ${getDic("inicio")}`;
                    _required = true;
                    _type = "time";
                    break;
                case "endTime":
                    var _date = moment().format().split("T");
                    _value = `${_date[0]}T${_value ? _value : "00:00"}:00-03:00`;
                    _hasForm = true;
                    _name = `${getDic("hora")} ${getDic("fim")}`;
                    _required = true;
                    _type = "time";
                    break;
                case "maximumNumberOfGuets":
                    _hasForm = true;
                    _name = getDic("capacidade-maxima");
                    _required = false;
                    _type = "number";
                    break;
                case "restrictAccess":
                    _hasForm = true;
                    _name = getDic("acesso");
                    _required = false;
                    _options = getRestrictAccessList();
                    _type = "select";
                    _value = _value === "true" ? true : false;
                    break;
                case "meetingType":
                    _hasForm = true;
                    _name = `${getDic("tipo")} ${getDic("conferencia")}`;
                    _required = true;
                    _options = getModalTypeList(true);
                    _type = "select";
                    _value = schedule[key] ? schedule[key] : null;
                    break;
                case "meetingCode":
                    _hasForm = true;
                    _name = `${getDic("codigo")} ${getDic("conferencia")} (URL)`;
                    _required = false;
                    break;
                case "password":
                    _hasForm = true;
                    _name = `${getDic("senha")}`;
                    _required = false;
                    break;
                case "stylesString":
                    _hasForm = true;
                    _name = `CSS Style`;
                    _required = false;
                    break;
                case "groupId":
                    _hasForm = true;
                    _name = getDic("grupo");
                    _required = false;
                    _options = groupList;
                    _type = "select";
                    _value = schedule[key] ? schedule[key] : [];
                    break;
                case "speakers":
                    _hasForm = true;
                    _name = getDic("palestrantes");
                    _required = false;
                    _options = speakersList;
                    _type = "select";
                    _value = schedule[key] ? schedule[key] : [];
                    break;
                default:
                    break;
            }

            if (_hasForm) {
                const _obj = createFormControlModel(
                    _index,
                    _name,
                    _value,
                    _type,
                    null,
                    null,
                    _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                    _required,
                    null,
                    null,
                    null,
                    key
                );
                _form.push(_obj);
                _index++;
            }
        }
    }
    return _form;
}

export function convertFormToScheduleObject(form) {
    var _config = createScheduleModel();
    form.forEach(item => {
        var _value;

        switch (item.dbReference) {
            case "id":
                _value = item.value ? parseInt(item.value) : null;
                break;
            case "eventId":
                _value = store.getState().event.id;
                break;
            case "title":
                _value = item.value ? item.value.toString() : null;
                break;
            case "description":
                _value = item.value ? item.value.toString() : null;
                break;
            case "startDate":
                _value = item.value ? item.value.toString() : null;
                break;
            case "startTime":
                if (item.value && item.value !== "") {
                    var _date = moment(item.value).format().split("T");
                    var _time = _date[1].split(":");
                    _value = `${_time[0]}:${_time[1]}`;
                }
                else {
                    _value = "";
                }
                break;
            case "endTime":
                if (item.value && item.value !== "") {
                    var _date = moment(item.value).format().split("T");
                    var _time = _date[1].split(":");
                    _value = `${_time[0]}:${_time[1]}`;
                }
                else {
                    _value = "";
                }
                break;
            case "maximumNumberOfGuets":
                _value = item.value;
                break;
            case "restrictAccess":
                _value = item.value;
                break;
            case "meetingType":
                _value = item.value;
                break;
            case "meetingCode":
                _value = item.value;
                break;
            case "password":
                _value = item.value;
                break;
            case "stylesString":
                _value = item.value;
                break;
            case "groupId":
                _value = item.value;
                break;
            case "speakers":
                _value = item.value;
                break;
            default:
                break;
        }
        _config[item.dbReference] = _value;
    });
    return _config;
}
