import React from "react";
import { connect } from "react-redux";
import { getDic } from "../assets/i18n/dictionary";
import { insertNewAccess, getSystemActions } from "../services/accessService";
import {
  getGuestCheckinsCountByDate,
  getOnlineGuests,
  getCountOnlineGuests,
  getGuestList,
} from "../services/guestService";
import { convertApiReturnInChartLineObject } from "../services/chartService";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

//Imports de components
import {
  Container,
  MenuButton,
  FormColumn,
  FormRow,
  ActivityIndicator,
  Card,
  TextBig,
  TextMedium,
  BackButton,

  ChartLine,
  SelectList,
  ListItem,
  Divider,
  InputFieldOutlined,
  Link,
  Button,
  Loading,
  InfiniteScroll,
  Avatar,
  ExpansionPanel
} from "../components";

var timeout;

class ModeratorPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      //Contadores
      totalPax: 0,
      totalPaxCheckin: 0,
      totalPaxOnline: 0,

      //Lista de pax online
      guestsList: [],
      pageIndex: 0,
      totalPageIndex: 0,
      hasMore: true,
      noParticipants: false,
      searchString: "",
      lastSearchValue: "",
      onlineGuestsId: [],

      //Dados para gráfico
      chartDay: "",
      chartDays: [],
      chartData: [],
      chartSelectedData: [],

      //Controle de loading
      loadingMessage: getDic("carregando"),
      openLoading: false,
    };
  }

  async componentDidMount() {
    //Bloqueio caso o usuário não seja admin
    var _blockUser = true;
    if (this.props.user.isAdmin || this.props.user.isStaff) {
      _blockUser = false;
    }
    if (_blockUser) {
      this.props.history.goBack();
    }

    var helpButton = document.getElementById("launcher");
    if (helpButton) {
      helpButton.style.visibility = "hidden";
    }

    this.getData();

    this.setState({ isLoading: false });

    await insertNewAccess(
      this.props.match.url,
      "",
      getSystemActions().accessPage
    );
  }

  async componentWillUnmount() {
    var helpButton = document.getElementById("launcher");
    if (helpButton) {
      helpButton.style.visibility = "visible";
    }

    await insertNewAccess(
      this.props.match.url,
      "",
      getSystemActions().exitPage
    );
    // window.location.reload();

    clearTimeout(timeout);
  }

  componentDidCatch(e) {
    var error = e.toString();

    if (error.includes("Error: Maximum update depth exceeded")) {
      window.location.reload();
    }
  }

  onChangeHandler(value) {
    this.setState({ searchString: value });
  }

  getData() {
    clearTimeout(timeout);

    this.setState({ openLoading: true });

    getCountOnlineGuests()
      .then((res) => {
        this.setState({
          totalPax: res.total,
          totalPaxCheckin: res.checked,
          totalPaxOnline: res.online,
          onlineGuestsId: res.onlineGuestsId,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        timeout = setTimeout(() => {
          this.getData();
        }, 60000 * 10);

        this.setState({ openLoading: false });
      });

    this.getGuests(true);
  }

  getGuests(newSearch = false) {

    var { hasMore, pageIndex, totalPageIndex, lastSearchValue } = this.state;

    if (newSearch) {
      this.setState({
        guestsList: [],
        pageIndex: 0,
        totalPageIndex: 0,
        hasMore: true,
        noParticipants: false,
        lastSearchValue: this.state.searchString,
      });
      hasMore = true;
      pageIndex = 0;
      totalPageIndex = 0;
      lastSearchValue = this.state.searchString;
    }
    //Fazer a busca apenas se houver mais dados
    if (hasMore && pageIndex <= totalPageIndex) {
      var index = pageIndex + 1;
      getGuestList(index, lastSearchValue)
        .then(res => {

          var complete = this.state.guestsList.concat(res.guestList);

          var noParticipants = false;
          if (index === 1 && res.guestList.length === 0) {
            noParticipants = true
          }

          this.setState({ guestsList: complete, pageIndex: index, totalPageIndex: res.totalPages, noParticipants: noParticipants });
        })
        .catch(err => {
          console.log("Erro getGuestList", err);
          this.setState({ guestsList: [], pageIndex: 0, totalPageIndex: 0, noParticipants: true });
        })
    }
    else if (this.state.hasMore) {
      this.setState({ hasMore: false });
    }
    else {
      return;
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Container background="#e8eced" align="center">
          <FormRow align="center">
            <ActivityIndicator />
          </FormRow>
        </Container>
      );
    }

    return (
      <Container align="center" background="#e8eced">
        <MenuButton history={this.props.history} />
        <BackButton history={this.props.history} color="#212121">
          {getDic("voltar").toUpperCase()}
        </BackButton>


        <FormColumn align="center" background="#e8eced" margin="0px">
          {/* Contadores */}
          <FormRow align="center">
            <div
              style={{
                width: "100%",
                maxWidth: "120px",
                textAlign: "center",
                margin: "10px",
              }}
            >
              <Card>
                <FormRow align="center">
                  <TextMedium wordBreak="none">Qtd Cadastrados</TextMedium>
                </FormRow>
                <FormRow align="center">
                  <TextBig wordBreak="none">{this.state.totalPax}</TextBig>
                </FormRow>
              </Card>
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "120px",
                textAlign: "center",
                margin: "10px",
              }}
            >
              <Card>
                <FormRow align="center">
                  <TextMedium wordBreak="none">Qtd Checkins</TextMedium>
                </FormRow>
                <FormRow align="center">
                  <TextBig wordBreak="none">
                    {this.state.totalPaxCheckin}
                  </TextBig>
                </FormRow>
              </Card>
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "120px",
                textAlign: "center",
                margin: "10px",
              }}
            >
              <Card>
                <FormRow align="center">
                  <TextMedium wordBreak="none">
                    Qtd <br />
                    Online
                  </TextMedium>
                </FormRow>
                <FormRow align="center">
                  <TextBig wordBreak="none">
                    {this.state.totalPaxOnline}
                  </TextBig>
                </FormRow>
              </Card>
            </div>
            <div style={{ margin: "10px", width: "15px" }}>
              <Button
                color="#e0e0e0"
                textColor="black"
                onClick={() => this.getData()}
              >
                <RefreshIcon />
              </Button>
            </div>
          </FormRow>
          {/* Gráfico de pico de acesso */}
          {/* <FormRow align="left">
                        <div style={{ width: "100%", marginLeft: '10px', marginRight: '10px' }}>
                            <Card>
                                <div style={{ margin: '10px', textAlign: "left", overflow: "hidden", height: "65px" }}>
                                    <div style={{ float: "left" }}>
                                        <TextMedium>{getDic("primeiro")} {getDic("acesso")}</TextMedium>
                                    </div>
                                    <div style={{ float: "right", marginTop: "5px" }}>
                                        <SelectList
                                            title={getDic("dia")}
                                            width="120px"
                                            value={this.state.chartDay}
                                            menuItens={this.state.chartDays}
                                            onChange={async e => {
                                                const data = [];
                                                data.push(this.state.chartData.find(i => i.date === e.target.value).checkins);
                                                this.setState({ chartDay: e.target.value, chartSelectedData: data })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ height: "250px", width: "100%" }}>
                                    <ChartLine
                                        data={this.state.chartSelectedData}
                                        legendLeft={getDic("primeiro") + " " + getDic("acesso")}
                                    />
                                </div>
                            </Card>
                        </div>
                    </FormRow> */}
          {/* Lista de participantes */}
          <table style={{ width: '100%' }}>
            <tr>
              <FormRow paddingTop="5px" paddingBottom="5px" height="9vh" align="flex-end">
                <div style={{ paddingRight: "22px", maxWidth: "400px" }}>
                  <InputFieldOutlined
                    key="search"
                    value={this.state.searchString}
                    placeholder={getDic("buscar")}
                    onChange={event => this.onChangeHandler(event.target.value)}
                    onKeyPress={event => { if (event.key === 'Enter') { this.getGuests(true) } }}
                    endAdornment={<Link color="gray"><SearchIcon onClick={() => this.getGuests(true)} /></Link>}
                  />
                </div>
              </FormRow>
            </tr>
            <tr>
              <FormColumn align="center" background={this.state.colorBackground} margin="0px" width="100%">
                {this.state.noParticipants ? (
                  <div style={{ color: 'black' }}>
                    <ListItem text={getDic("pesquisa-sem-resultado")} />
                  </div>
                ) : (
                  <Card>
                    <InfiniteScroll
                      pageStart={0}
                      dataLength={this.state.guestsList.length}
                      height={"63vh"}
                      next={() => {
                        this.getGuests();
                      }}
                      hasMore={this.state.hasMore}
                      loader={this.state.hasMore ? <ActivityIndicator /> : null}
                    >
                      {this.state.guestsList.map((item) => {
                        return (
                          <div key={item.id}>
                            <ListItem text={item.name} >
                              <RadioButtonCheckedIcon style={{ color: (this.state.onlineGuestsId.includes(item.id) ? "green" : "red") }} />
                            </ListItem>
                          </div>
                        );
                      })}
                    </InfiniteScroll>
                  </Card>
                )}
              </FormColumn>
            </tr>
          </table>
        </FormColumn>

        <Loading
          open={this.state.openLoading}
          message={this.state.loadingMessage}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(ModeratorPage);
