import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import { getAnswersQuantityByQuestionApi } from "../services/questionsService";

import {
    DialogDraggable,
    WordCloud,
    ChartBar,
    ChartPie,
    FormRow,
} from '../components';

const Component = props => {
    const {
        questionId = 0,
        onClose,
        title = "",
        chartType = "WordClound", //Tipos: WordClound, Bar, Pie
    } = props;

    const [dialogDraggableOpen, setDialogDraggableOpen] = React.useState(false);
    const [chartPieData, setChartPieData] = React.useState([]);
    const [chartBarData, setChartBarData] = React.useState([]);
    const [chartBarKeys, setChartBarKeys] = React.useState([]);
    const [chartCloudData, setChartCloudData] = React.useState([]);
    const [modalSize, setModalSize] = React.useState({
        width: window.innerWidth * 0.4,
        height: window.innerHeight * 0.4
    });

    React.useEffect(() => {

        getAnswersQuantity();

        return () => {
        }
    }, []);

    const getAnswersQuantity = () => {
        if (questionId && questionId > 0) {
            getAnswersQuantityByQuestionApi(questionId)
                .then((res) => {
                    const _pieData = [];
                    const _barData = [];
                    const _cloudData = [];
                    const _chartBarKeys = [];
                    const _chartCloudKeys = [];

                    var _total = 0;
                    res.forEach((item) => {
                        _total += item.quantity;
                    });

                    res.forEach((item) => {
                        var _ret = (item.quantity * 100) / _total;

                        const _objPie = {
                            id: item.answer,
                            label: item.answer,
                            value: item.quantity,
                            percentage: _ret.toFixed(2) + "%",
                        };
                        _pieData.push(_objPie);

                        const _objBar = {
                            [getDic("quantidade")]: item.answer,
                            [item.answer]: item.quantity,
                            quantity: item.quantity,
                            percentage: _ret.toFixed(2) + "%",
                        };

                        _barData.push(_objBar);
                        _chartBarKeys.push(item.answer);

                        _cloudData.push(item.answer);
                        _chartCloudKeys.push(item.answer);
                    });

                    setChartPieData(_pieData);
                    setChartBarData(_barData);

                    setChartCloudData(_cloudData);
                    setChartBarKeys(_chartBarKeys);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setDialogDraggableOpen(true);
                })
        }
    };

    return (
        <DialogDraggable
            open={dialogDraggableOpen}
            onClose={() => setDialogDraggableOpen(false)}
            title={title}
        >
            <div style={{ width: modalSize.width, height: modalSize.height }}>
                {/* <WordCloud
                    wordsArray={["teste 1", "teste 2", "teste 3", "teste 4", "teste 5"]} // Array de strings
                    largerWordSize={30}
                    minimalWordSize={10}
                    padding={12}
                // hueColor="red"
                /> */}
                <FormRow margin="0px">
                    {/* Gráfico de respostas Pie */}
                    {chartType === "Pie" && (
                        <div style={{ width: modalSize.width, height: modalSize.height }}>
                            <ChartPie data={chartPieData} />
                        </div>
                    )}

                    {/* Gráfico de respostas Bar */}
                    {chartType === "Bar" && (
                        <div style={{ width: modalSize.width, height: modalSize.height }}>
                            <ChartBar
                                keys={chartBarKeys}
                                idexedBy={getDic("quantidade")}
                                data={chartBarData}
                            />
                        </div>
                    )}
                    {/* Gráfico de respostas Nuvem */}

                    {chartType === "WordClound" && (
                        <div style={{ width: modalSize.width, height: modalSize.height }}>
                            <WordCloud
                                width={modalSize.width + "px"}
                                wordsArray={chartCloudData}
                                height={modalSize.height - 30}
                                largerWordSize={30}
                                minimalWordSize={10}
                            />
                        </div>
                    )}
                </FormRow>
            </div>
        </DialogDraggable>
    );
};

export default Component;
