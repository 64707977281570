import React from "react";
import RegistrationPageContext from './RegistratioPageContext';
import { store } from "../../store";
import { getDic, getLanguages } from "../../assets/i18n/dictionary";
import { connect } from "react-redux";
import {
  userLoginSuccess,
  setLanguage,
  resetAllAccess,
  resetNotifications,
} from "../../store/actions";
import { getAttributes } from "../../services/attributesService";
import { getDate } from '../../services/apiService';
import { createFormControlModel } from "../../models/formControlModel";
import * as checkinService from "../../services/checkinService";
import {
  addNewUserApi,
  getUserFromParticipantBankByCpf,
  getTotalRegisteredUsers,
  validateCrmApi,
} from "../../services/userService";
import { forceSendAccessReport } from "../../threads/accessThread";
import ShowZendesk from '../../services/zendeskService';
import "../../scss/Login.scss";
import { validateForm } from "../../utils";
import { initGoogleAnalytics } from '../../services/eventService';
import moment from 'moment';
import { isMobile } from "react-device-detect";
import * as navigationHistoryService from '../../services/navigationHistoryService';
import { getCountOnlineGuests } from "../../services/guestService";
import * as emailService from '../../services/emailService';

const RegistrationPageProvider = (props) => {

  var params = [];
  var eventID = null;
  if (eventID) {
    params.push(eventID);
  } else if (props.params && props.params.id) {
    params.push(props.params.id.split("&"));
  }
  else {
    params.push(process.env.REACT_APP_EVENT_ID);
  }

  const [state, setState] = React.useState(true);

  const [eventId, setEventId] = React.useState(params && params[0] ? params[0].toString() : 0);
  const [isAdmin, setIsAdmin] = React.useState(window.location.href.indexOf("?admin") > -1 ? true : false);
  const [isStaff, setIsStaff] = React.useState(window.location.href.indexOf("?staff") > -1 ? true : false);
  const [isDeveloper, setIsDeveloper] = React.useState(window.location.href.indexOf("?dev") > -1 ? true : false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [hasEvent, setHasEvent] = React.useState(true);
  const [useRecaptcha, setUseRecaptcha] = React.useState(false);
  const [recaptchaVerify, setRecaptchaVerify] = React.useState();
  const [possibleGuestRequired, setPossibleGuestRequired] = React.useState(false);

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(false);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  const [image001, setImage001] = React.useState(null);
  const [image003, setImage003] = React.useState(null);
  const [imageBackgroundUrl, setImageBackgroundUrl] = React.useState(null);
  const [colorBackGround, setColorBackGround] = React.useState("#e8eced");
  const [attributesList, setAttributesList] = React.useState([]);
  const [attributesValueList, setAttributesValueList] = React.useState([]);
  const [cardConfig, setCardConfig] = React.useState(null);
  const [primaryTitle, setPrimaryTitle] = React.useState({
    text: getDic("novo").toUpperCase() + " " + getDic("participante").toUpperCase(),
    color: "black",
    size: "25px",
  });
  const [secondaryTitle, setSecondaryTitle] = React.useState({
    text: "",
    color: "black",
    size: "12px",
  });
  const [buttonText, setButtonText] = React.useState(getDic("cadastrar"));
  const [buttonColor, setButtonColor] = React.useState("#3f51b5");
  const [checkAuthorization, setCheckAuthorization] = React.useState(false);
  const [terms, setTerms] = React.useState(null);
  const [isComboboxTerms, setIsComboboxTerms] = React.useState(false);
  const [isIframeTerms, setIsIframeTerms] = React.useState(false);
  const [autoOpenTerms, setAutoOpenTerms] = React.useState(false);
  const [openModalTerms, setOpenModalTerms] = React.useState();
  const [termsCheckboxDisabled, setTermsCheckboxDisabled] = React.useState(false);
  const [tittle004, setTittle004] = React.useState({
    text: getDic('termos-mensagem'),
    color: "black",
    size: "16px",
  });
  const [tittle003, setTittle003] = React.useState({
    text: getDic('ler') + ' ' + getDic('termos'),
    color: "black",
    size: "16px",
  });
  const [tittle005, setTittle005] = React.useState({
    text: `* ${getDic("campos-obrigatorios")}`,
    color: 'black',
    size: 12
  });
  const [validateCrm, setValidateCrm] = React.useState(false);
  const [updateUserOnRegister, setUpdateUserOnRegister] = React.useState(false);
  const [blockUserOnRegister, setBlockUserOnRegister] = React.useState(false);
  const [maxUserLimitEnabled, setMaxUserLimitEnabled] = React.useState();
  const [maxUserLimite, setMaxUserLimite] = React.useState();
  const [enableTwoFactorLogin, setEnableTwoFactorLogin] = React.useState(false);
  const [isLoginByNameAndEmailFromParams, setIsLoginByNameAndEmailFromParams] = React.useState(false);
  const [hasLang, setHasLang] = React.useState(false);
  const [language, setLanguage] = React.useState(null);
  const [languages, setLanguages] = React.useState(null);
  const [hasPassword, setHasPassword] = React.useState(false);
  const [passwordControl1, setPasswordControl1] = React.useState({
    value: '',
    error: false,
    errorMessage: ''
  });
  const [passwordControl2, setPasswordControl2] = React.useState({
    value: '',
    error: false,
    errorMessage: ''
  });
  const [loginAccessLimit, setLoginAccessLimit] = React.useState(0);

  React.useEffect(() => {
    getPageData();
    addEventListeners();
    return () => {
    }
  }, []);

  const addEventListeners = () => {
    window.addEventListener('resize', () => {
      changeBackgroundImageMobile();
    });
  }

  const changeBackgroundImageMobile = () => {
    const _backgroundImage = store.getState().config.find(c => {
      return c.codeScreen === 'IMAGE-002' && c.screenId === 9;
    });
    const _backgroundImageMobile = store.getState().config.find(c => {
      return c.codeScreen === 'IMAGE-002-MOBILE' && c.screenId === 9;
    });
    if ((_backgroundImage && _backgroundImage.url) || (_backgroundImageMobile && _backgroundImageMobile.url[0])) {
      if (window.innerWidth < window.innerHeight && _backgroundImageMobile && _backgroundImageMobile.url[0]) {
        setImageBackgroundUrl(_backgroundImageMobile.url[0]);
        setColorBackGround("#ffffff00");
      } else if (_backgroundImage && _backgroundImage.url) {
        setImageBackgroundUrl(_backgroundImage.url[0]);
        setColorBackGround("#ffffff00");
      }
    }
  }

  const getPageData = async () => {
    //Verifica se possui evento
    var _eventUrl = parseInt(eventId);
    var _eventId = store.getState().event.id;
    const _event = store.getState().event;
    const _currentDate = await getDate();
    var _endDate = moment(_event.endDate).add(1, "days").format();
    var _hasEvent = _eventId !== 0 && _eventId === _eventUrl ? true : false;
    setHasEvent(_hasEvent);
    //Verifica se o evento permite cadastro, se não, voltar para tela de login
    var configList = store.getState().config;

    var hasRegister = configList.find((item) => {
      return (
        item.codeScreen === "NO-REGISTER" || item.codeScreen === "BLOCK-ACCESS"
      );
    });

    if (!hasRegister && _hasEvent &&
      (_currentDate < _endDate || isAdmin || isDeveloper)
    ) {
      if (checkIfLoginByNameAndEmailFromParams()) {
        executeLoginByNameAndEmailFromParams();
        return;
      }

      var configListPage = configList.find((item) => {
        return item.codeScreen === "IMAGE-001" && item.screenId === 9;
      });
      if (configListPage) {
        setImage001(configListPage.url);
      }

      changeBackgroundImageMobile();

      var image003 = configList.find((item) => {
        return item.codeScreen === "IMAGE-003" && item.screenId === 9;
      });
      if (image003) {
        setImage003(image003.url);
      }

      var configPrimaryTitle = configList.find((item) => {
        return item.codeScreen === "TITTLE-001" && item.screenId === 9;
      });
      if (configPrimaryTitle) {
        var newObj = primaryTitle;
        newObj.text = configPrimaryTitle.tooltip;
        if (configPrimaryTitle.url.length > 0) {
          configPrimaryTitle.url.forEach((item) => {
            var conf = item.split(":");
            newObj[conf[0]] = conf[0] === "size" ? conf[1] + "px" : conf[1];
          });
        }
        setPrimaryTitle(newObj);
      }

      var configSecondaryTitle = configList.find((item) => {
        return item.codeScreen === "TITTLE-002" && item.screenId === 9;
      });
      if (configSecondaryTitle) {
        var newObj = secondaryTitle;
        newObj.text = configSecondaryTitle.tooltip;
        if (configSecondaryTitle.url.length > 0) {
          configSecondaryTitle.url.forEach((item) => {
            var conf = item.split(":");
            newObj[conf[0]] = conf[0] === "size" ? conf[1] + "px" : conf[1];
          });
        }
        setSecondaryTitle(newObj);
      }

      var title3 = configList.find((item) => {
        return item.codeScreen === "TITTLE-003" && (item.screenId === 9 || item.screenId === 4);
      });
      if (title3) {
        var newObj = tittle003;
        newObj.text = title3.tooltip && title3.tooltip !== "" ? title3.tooltip : tittle003.text;
        if (title3.url.length > 0) {
          title3.url.forEach((u) => {
            var conf = u.split(":");
            newObj[conf[0]] =
              conf[0] === "size" ? conf[1] + "px" : conf[1];
          });
        }
        setTittle003(newObj);
      }

      var title4 = configList.find((item) => {
        return item.codeScreen === "TITTLE-004" && (item.screenId === 9 || item.screenId === 4);
      });
      if (title4) {
        var newObj = tittle004;
        newObj.text = title4.tooltip && title4.tooltip !== "" ? title4.tooltip : tittle004.text;
        if (title4.url.length > 0) {
          title4.url.forEach((u) => {
            var conf = u.split(":");
            newObj[conf[0]] =
              conf[0] === "size" ? conf[1] + "px" : conf[1];
          });
        }
        setTittle004(newObj);
      }

      var title5 = configList.find((item) => {
        return item.codeScreen === "TITTLE-005" && (item.screenId === 9);
      });
      if (title5) {
        var newObj = tittle005;
        newObj.text = title5.tooltip && title5.tooltip !== "" ? title5.tooltip : tittle005.text;
        if (title5.url.length > 0) {
          title5.url.forEach((u) => {
            var conf = u.split(":");
            newObj[conf[0]] =
              conf[0] === "size" ? conf[1] + "px" : conf[1];
          });
        }
        setTittle005(newObj);
      }

      var buttonConfig = configList.find((item) => {
        return item.codeScreen === "BUTTON-001" && item.screenId === 9
      });
      if (buttonConfig) {
        const color = buttonConfig.url === "#" ? buttonColor : buttonConfig.url;
        setButtonText(buttonConfig.tooltip);
        setButtonColor(color);
      }

      var cardType = configList.find((item) => {
        return (
          item.codeScreen === "CARD-SIMP" ||
          (item.codeScreen === "CARD-TRAN" && item.screenId === 9)
        );
      });
      if (cardType) {
        setCardConfig(cardType.codeScreen);
      }

      var terms = configList.find((item) => {
        return item.codeScreen === "TERM-001" && item.screenId === 9;
      });
      if (terms) {
        var _isIframe = false;
        var _terms = terms.tooltip && terms.tooltip !== "" ? terms.tooltip : "";
        if (terms.typeId === 3) {
          _isIframe = true;
          _terms = terms.url[0];
        }
        setTerms(_terms);
        setIsIframeTerms(_isIframe);
      }

      var termsCombobox = configList.find((item) => {
        return item.codeScreen === "TERM-001-COMBOBOX";
      });
      if (termsCombobox) {
        setIsComboboxTerms(true);
        setCheckAuthorization(true);
      }

      var autoOpenTerms = configList.find((item) => {
        return item.codeScreen === "AUTO-OPEN-TERMS";
      });
      if (autoOpenTerms) {
        setAutoOpenTerms(true);
      }

      var readTermsRequired = configList.find((item) => {
        return item.codeScreen === "READ-TERMS-REQUIRED";
      });
      if (readTermsRequired) {
        setTermsCheckboxDisabled(true);
      }

      var useRecaptcha = configList.find((item) => {
        return item.codeScreen === "USE-RECAPTCHA";
      });
      if (useRecaptcha) {
        setUseRecaptcha(true);
      }

      var useRecaptcha = configList.find((item) => {
        return item.codeScreen === "POSSIBLE-GUEST-REQUIRED";
      });
      if (useRecaptcha) {
        setPossibleGuestRequired(true);
      }

      var _blockUserOnRegister = configList.find((item) => {
        return item.codeScreen === "BLOCK-USER-ON-REGISTER" && item.screenId === 9;
      });
      if (_blockUserOnRegister) {
        setBlockUserOnRegister(true);
      }

      var _updateUserOnRegister = configList.find((item) => {
        return item.codeScreen === "UPDATE-USER-ON-REGISTER" && item.screenId === 9;
      });
      if (_updateUserOnRegister) {
        setUpdateUserOnRegister(true);
      }

      var _enableTwoFactorLogin = configList.find((item) => {
        return item.codeScreen === "ENABLE-TWO-FACTOR-LOGIN" && item.screenId === 4;
      });
      if (_enableTwoFactorLogin) {
        setEnableTwoFactorLogin(true);
      }

      var _enableChangeLanguage = configList.find((item) => {
        return item.codeScreen === "ENABLE-CHANGE-LANGUAGE";
      });
      if (_enableChangeLanguage) {
        setHasLang(true);
        setLanguages(getLanguages());
      }

      var _hasPassword = configList.find((item) => {
        return item.codeScreen === 'USE-ACCESS-BY-LOGIN-PASSWORD';
      });
      if (_hasPassword) {
        setHasPassword(true);
      }

      var _maxNumberOfLogins = configList.find((item) => {
        return item.codeScreen === 'LOGIN-ACCESS-LIMIT';
      });
      if (_maxNumberOfLogins) {
        setLoginAccessLimit(parseInt(_maxNumberOfLogins.urlString));
      }

      getAttributes(parseInt(eventId))
        .then((res) => {
          //Cria lista de campos para preencher
          const _attributesValueList = [];

          res.forEach((item) => {
            let _mask = item.mask;
            if (item.dbReference === "CodigoInterno") {
              var _internalCode = configList.find((item) => { return item.codeScreen === "USE-INTERNAL-CODE"; });
              if (_internalCode) {
                _internalCode.url.forEach(element => {
                  if (element.includes('mask:')) {
                    let _m = element.split(':');
                    _mask = _m[1] || '';
                  }
                });
              }
            }

            var field = createFormControlModel(
              item.id,
              item.name,
              "",
              item.type === "Selecionar" ? "select" : "text",
              item.maxLength,
              _mask,
              item.values,
              item.required,
              null,
              null,
              null,
              item.dbReference
            );

            _attributesValueList.push(field);
          });

          setIsLoading(false);
          setAttributesList(res);
          setAttributesValueList(_attributesValueList);

          const hasCpf = _attributesValueList.find((item) => {
            return item.dbReference === "CPF";
          });

          //Caso o cadastro possua CPF, desabilitar campos
          if (hasCpf) disableFields(true, _attributesValueList);

          //Caso possua validação de CRM, verificar se o form possui os campos necessários
          configList.find((item) => {
            if (item.codeScreen === "VALIDATE-CRM-API") {
              const _verifyCrm = _attributesValueList.filter(item => {
                return (item.dbReference === "CRM" && item.required)
                  || (item.dbReference === "Estado" && item.required)
                  || (item.dbReference === "Perfil" && item.required)
              });
              if (_verifyCrm.length === 3) {
                setValidateCrm(true);
              }

              return null;
            }
          });

          //Checa se tem limitador de cadastros
          configList.find((item) => {
            if (item.codeScreen === "SET_MAX_USER_REGISTERED") {
              setMaxUserLimitEnabled(true);
              setMaxUserLimite(parseInt(item.url[0]));
            }
          });

        })
        .catch((err) => {
          console.log('Erro getPageData', err);
          setIsLoading(false);
          setAlertTitle(getDic("erro"));
          setAlertMessage(getDic("enviado-erro"));
          setAlertOpen(true);
        });
    } else {
      navigationHistoryService.setHistory(props.history, "/" + eventId);
    }
  }

  const checkIfLoginByNameAndEmailFromParams = () => {
    var _isCheckin = false;
    if (
      window.location.href.includes('?') &&
      window.location.href.includes('name=') &&
      window.location.href.includes('email=') &&
      (eventId === "67710" || eventId === "73292")
    ) {
      _isCheckin = true;
    }
    return _isCheckin;
  }

  const executeLoginByNameAndEmailFromParams = () => {
    var _params = window.location.href.substring(window.location.href.indexOf('?') + 1);
    _params = _params.split('&');

    var _name = '';
    var _email = '';
    _params.forEach(item => {
      const _obj = item.split('=');
      if (_obj[0] && _obj[0] === 'name') {
        _name = decodeURI(_obj[1]);
      }
      else if (_obj[0] && _obj[0] === 'email') {
        _email = decodeURI(_obj[1]);
      }
    });

    getAttributes(parseInt(eventId))
      .then((res) => {
        //Cria lista de campos para preencher
        const _attributesValueList = [];
        const _attributesList = [];

        res.forEach((item) => {
          //Montar uma lista apenas com nome e email
          if (item.dbReference === 'Nome' || item.dbReference === 'Email') {
            const _field = createFormControlModel(
              item.id,
              item.name,
              item.dbReference === 'Nome' ? _name : _email,
              item.type === "Selecionar" ? "select" : "text",
              item.maxLength,
              item.mask,
              item.values,
              item.required,
              null,
              null,
              null,
              item.dbReference
            );

            _attributesValueList.push(_field);
            _attributesList.push(item);
          }
        });

        if (_attributesValueList.length < 2) {
          navigationHistoryService.setHistory(props.history, "/" + eventId);
        }

        setAttributesList(_attributesList);
        setAttributesValueList(_attributesValueList);
        setIsLoginByNameAndEmailFromParams(true);
      })
      .catch((err) => {
        console.log("Erro executeLoginByNameAndEmailFromParams", err);
        navigationHistoryService.setHistory(props.history, "/" + eventId);
      });
  }
  // Callback chamado somente após executeLoginByNameAndEmailFromParams
  React.useEffect(() => {
    if (attributesList.length === 2 && attributesValueList.length === 2 && isLoginByNameAndEmailFromParams) {
      tryRegister();
    }
  }, [attributesList, attributesValueList, isLoginByNameAndEmailFromParams]);

  const disableFields = (disable = false, fieldsList = []) => {
    const _list = [];
    const _attributesValueList = fieldsList.length > 0 ? fieldsList : attributesValueList;
    _attributesValueList.forEach(item => {
      //O único campo que não será desabilitado é o CPF
      if (item.dbReference !== "CPF") {
        var newField = item;
        newField.disabled = disable;
        _list.push(newField);
      } else {
        _list.push(item);
      }
    });
    setAttributesValueList(_list);
    setState(state === true ? false : true);
  }

  //Método para auxiliar preenchimento de formulário
  const searchUser = (cpf) => {
    if (cpf.length >= 14) {
      var _hasUser = true;
      setOpenLoading(true);
      getUserFromParticipantBankByCpf(cpf, eventId)
        .then((res) => {
          //Caso exista cadastro, preencher os campos com os dados
          const list = attributesValueList.map((item) => {
            if (item.dbReference === "Nome") {
              var newField = item;
              newField.value =
                (res.name ? res.name : "") +
                " " +
                (res.middleName ? res.middleName : "") +
                " " +
                (res.lastName ? res.lastName : "");
              return newField;
            } else if (item.dbReference === "Email") {
              var newField = item;
              newField.value = res.email ? res.email.trim() : "";
              return newField;
            } else if (item.dbReference === "CRM") {
              var newField = item;
              newField.value = res.crm ? res.crm.trim() : "";
              return newField;
            } else if (item.dbReference === "Estado") {
              var newField = item;
              newField.value = res.state ? res.state.trim() : "";
              return newField;
            } else if (item.dbReference === "Especialidade") {
              var newField = item;
              newField.value = res.specialty ? res.specialty.trim() : "";
              return newField;
            } else {
              return item;
            }
          });

          setAttributesValueList(list);
        })
        .catch((err) => {
          _hasUser = false;
          console.log(err);
        })
        .finally(() => {
          //Caso o pré cadastro seja exigido, exibir mensagem de erro
          if (possibleGuestRequired && !_hasUser) {
            setOpenLoading(false);
            setAlertTitle(getDic("erro"));
            setAlertMessage(getDic("cpf-erro-pre-cadastro"));
            setAlertOpen(true);
          } else {
            setOpenLoading(false);
            disableFields(false);
          }
        });
    }
  }

  const onChangeHandler = (fieldId, value) => {
    var newField;
    const list = attributesValueList.map((item) => {
      if (item.id === fieldId) {
        newField = item;
        newField.value = value;
        return newField;
      } else {
        return item;
      }
    });

    setAttributesValueList(list);
    setState(state === true ? false : true);

    //Verifica se o cpf está preenchido para buscar usuario
    if (newField.dbReference === "CPF" && newField.value.length > 13) {
      searchUser(newField.value);
    }
  }

  const onKeyPressHandler = (value) => {
    if (value === "Enter") {
      tryRegister();
    }
  }

  const tryRegister = async () => {
    const _validateEmail = isLoginByNameAndEmailFromParams ? false : true;
    var validation = validateForm(attributesValueList, _validateEmail);

    let _useInternalCode = store.getState().config.find(c => { return c.codeScreen === 'USE-INTERNAL-CODE' });
    if (_useInternalCode) {
      _useInternalCode.url.forEach(url => {
        if (url.includes('minLength:')) {
          let _m = url.split(':');
          let _minLength = _m[1] ? parseInt(_m[1]) : 0;
          let _internalCode = validation.form.find(f => { return f.dbReference === "CodigoInterno" });
          let _internalCodeIndex = validation.form.findIndex(f => { return f.dbReference === "CodigoInterno" });
          if (_internalCode && _minLength > 0 && _internalCode.value.length < _minLength) {
            validation.isValid = false;
            validation.form[_internalCodeIndex].error = true;
            validation.form[_internalCodeIndex].errorMessage = `${getDic("minimo-de-caracters")} ${_minLength} ${getDic("caracteres")}`;
          }
        }
      });
    }

    if (validation.isValid) {
      if (hasEvent) {
        setIsLoading(true);
        var _hasError = false;

        if (terms) {
          const _termsNotRequired = store.getState().config.find(c => {
            return c.codeScreen === 'TERMS-NOT-REQUIRED';
          });

          if (!checkAuthorization && !_termsNotRequired) {
            setIsLoading(false);
            setAlertTitle(getDic("atencao"));
            setAlertMessage(getDic('termos-necessita-aceite'));
            setAlertOpen(true);
            _hasError = true;
          }
        }

        if (maxUserLimitEnabled) {

          let totalUsers = await getTotalRegisteredUsers(eventId);

          if (totalUsers > maxUserLimite) {
            window.scrollTo(0, 0);
            setIsLoading(false);
            setAlertTitle(getDic("atencao"));
            setAlertMessage(getDic("event-full"));
            setAlertOpen(true);
            _hasError = true;
          }
        }

        if (useRecaptcha && !recaptchaVerify) {
          setIsLoading(false);
          setAlertTitle(getDic("atencao"));
          setAlertMessage(getDic("recaptcha-erro"));
          setAlertOpen(true);
          _hasError = true;
        }

        try {
          await checkMaxNumberOfLogins();
        } catch (error) {
          return;
        }

        // Verifica se o evento deve validar CRM ou CRE
        if (validateCrm) {
          const _perfil = attributesValueList.find(item => {
            return item.dbReference === "Perfil";
          });
          const _crm = attributesValueList.find(item => {
            return item.dbReference === "CRM";
          });
          const _estado = attributesValueList.find(item => {
            return item.dbReference === "Estado";
          });
          await validateCrmApi(_perfil.value, _crm.value, _estado.value)
            .then(res => {
              if (!res) {
                setIsLoading(false);
                setAlertTitle(getDic("erro"));
                setAlertMessage(getDic("crm-erro-verificacao"));
                setAlertOpen(true);
                _hasError = true;
              }
            })
            .catch(err => {
              setIsLoading(false);
              setAlertTitle(getDic("erro"));
              setAlertMessage(getDic("crm-erro-verificacao"));
              setAlertOpen(true);
              _hasError = true;
            });
        }

        if (hasPassword) {
          if (!passwordControl1.value) {
            setPasswordControl1({ ...passwordControl1, error: true, errorMessage: getDic("preenchimento-obrigatorio") });
            _hasError = true;
          } else {
            setPasswordControl1({ ...passwordControl1, error: false, errorMessage: '' });
          }

          if (!passwordControl2.value) {
            setPasswordControl2({ ...passwordControl2, error: true, errorMessage: getDic("preenchimento-obrigatorio") });
            _hasError = true;
          } else {
            setPasswordControl2({ ...passwordControl2, error: false, errorMessage: '' });
          }

          if (!_hasError && passwordControl1.value !== passwordControl2.value) {
            setPasswordControl2({ ...passwordControl2, error: true, errorMessage: getDic("senha-confirmacao-erro") });
            _hasError = true;
          } else if (!_hasError) {
            setPasswordControl2({ ...passwordControl2, error: false, errorMessage: '' });
          }
        }

        if (!_hasError) {
          registerUser(validation.form);
        } else {
          setIsLoading(false);
        }
      }
    } else {
      setAttributesValueList(validation.form);
    }


    async function checkMaxNumberOfLogins() {
      try {
        if (loginAccessLimit <= 0 || isAdmin || isStaff) {
          return;
        }

        let _count = await getCountOnlineGuests();
        if (_count.online >= loginAccessLimit) {
          throw null;
        }
      } catch (error) {
        console.log('Error checkMaxNumberOfLogins');
        setIsLoading(false);
        setAlertTitle(getDic("erro"));
        setAlertMessage(
          !error ? getDic("capacidade-maxima-erro") : getDic("enviado-erro")
        );
        setAlertOpen(true);
        throw error;
      }
    }
  }

  const registerUser = async (list) => {
    try {
      let _res = await addNewUserApi(attributesList, list, store.getState().config, recaptchaVerify, passwordControl1.value);

      if (_res.isTwoFactosLogin) {
        setIsLoading(false);
        setAlertTitle("");
        setAlertMessage(getDic("usuario-ja-cadastrado"));
        setAlertOpen(true);
        return;
      }

      await props.userLoginSuccess(_res.user);

      // Verifica se o evento possui Google Analytics
      if (store.getState().event.hasGoogleAnalytics) {
        initGoogleAnalytics(store.getState().event.googleAnalyticsId);
      }

      //Verifica a programação de credenciamento e salva na api
      var schedule = store.getState().schedules.find((sched) => {
        return sched.title.toUpperCase() === "CREDENCIAMENTO";
      });

      let _sendEmailOnRegister = store.getState().config.find(c => {
        return c.codeScreen === "SEND-EMAIL-ON-REGISTER" && c.screenId === 9;
      });
      if (_sendEmailOnRegister) {
        try {
          await sendEmailOnRegister(_sendEmailOnRegister);
        } catch (error) {
          console.log('Erro ao enviar email');
        }
      }

      if (schedule && schedule.id) {
        checkinService
          .insertNewCheckin(schedule.id)
          .then((check) => {
            navigatePage();
          })
          .catch((err) => {
            console.log("Erro insertNewCheckin: ", err);

            navigatePage();
          });
      } else {
        navigatePage();
      }

    } catch (error) {
      console.log("Erro registerUser", error);
      let _message = getDic("enviado-erro");
      if (error && error.toString() === 'Email inválido') {
        _message = `${getDic("email")} ${getDic("invalido")}`;
      }
      setIsLoading(false);
      setAlertTitle(getDic("erro"));
      setAlertMessage(_message);
      setAlertOpen(true);
    }

    async function sendEmailOnRegister(config) {
      try {
        let _template = getTemplateWithTags(config.html);
        let _subject = getTemplateWithTags(config.tooltip);

        await emailService.sendEmailApi(
          store.getState().user.email,
          _subject,
          _template
        )
      } catch (error) {
        console.log('Error sendEmailOnRegister', error);
        throw error;
      }

      function getTemplateWithTags(template = '') {
        try {
          if (template.includes('{userName}')) {
            template = template.replace(/{userName}/g, store.getState().user.name);
          }
          if (template.includes('{userEmail}')) {
            template = template.replace(/{userEmail}/g, store.getState().user.email);
          }
          if (template.includes('{eventName}')) {
            template = template.replace(/{eventName}/g, store.getState().event.name);
          }
          return template;
        } catch (error) {
          console.log('Error getTemplateWithTags', error);
          throw error;
        }
      }
    }
  }

  const navigatePage = () => {
    //Enviar dados de acesso
    forceSendAccessReport();

    var noBadge = store.getState().config.find((item) => {
      return item.codeScreen === "NO-BADGE";
    });
    if (noBadge) {
      navigationHistoryService.setHistory(props.history, "/" + eventId + "/eventhall");
    } else {
      //Navega para a página seguinte
      navigationHistoryService.setHistory(props.history, "/" + eventId + "/badge");
    }
  }

  const changeLanguage = async (lang) => {
    await props.setLanguage({ lang: lang });
    setLanguage(lang);
  }

  return (
    <RegistrationPageContext.Provider value={{
      state: {
        state: state, setState,
        eventId: eventId, setEventId,
        isAdmin: isAdmin, setIsAdmin,
        isStaff: isStaff, setIsStaff,
        isDeveloper: isDeveloper, setIsDeveloper,
        isLoading: isLoading, setIsLoading,
        hasEvent: hasEvent, setHasEvent,
        useRecaptcha: useRecaptcha, setUseRecaptcha,
        recaptchaVerify: recaptchaVerify, setRecaptchaVerify,
        possibleGuestRequired: possibleGuestRequired, setPossibleGuestRequired,
        loadingMessage: loadingMessage, setLoadingMessage,
        openLoading: openLoading, setOpenLoading,
        alertOpen: alertOpen, setAlertOpen,
        alertTitle: alertTitle, setAlertTitle,
        alertMessage: alertMessage, setAlertMessage,
        image001: image001, setImage001,
        image003: image003, setImage003,
        imageBackgroundUrl: imageBackgroundUrl, setImageBackgroundUrl,
        colorBackGround: colorBackGround, setColorBackGround,
        attributesList: attributesList, setAttributesList,
        attributesValueList: attributesValueList, setAttributesValueList,
        cardConfig: cardConfig, setCardConfig,
        primaryTitle: primaryTitle, setPrimaryTitle,
        secondaryTitle: secondaryTitle, setSecondaryTitle,
        buttonText: buttonText, setButtonText,
        buttonColor: buttonColor, setButtonColor,
        checkAuthorization: checkAuthorization, setCheckAuthorization,
        terms: terms, setTerms,
        isComboboxTerms: isComboboxTerms, setIsComboboxTerms,
        isIframeTerms: isIframeTerms, setIsIframeTerms,
        autoOpenTerms: autoOpenTerms, setAutoOpenTerms,
        openModalTerms: openModalTerms, setOpenModalTerms,
        termsCheckboxDisabled: termsCheckboxDisabled, setTermsCheckboxDisabled,
        tittle004: tittle004, setTittle004,
        tittle003: tittle003, setTittle003,
        tittle005: tittle005, setTittle005,
        validateCrm: validateCrm, setValidateCrm,
        updateUserOnRegister: updateUserOnRegister, setUpdateUserOnRegister,
        blockUserOnRegister: blockUserOnRegister, setBlockUserOnRegister,
        enableTwoFactorLogin: enableTwoFactorLogin, setEnableTwoFactorLogin,
        isLoginByNameAndEmailFromParams: isLoginByNameAndEmailFromParams, setIsLoginByNameAndEmailFromParams,
        hasLang: hasLang, setHasLang,
        language: language, setLanguage,
        languages: languages, setLanguages,
        hasPassword: hasPassword, setHasPassword,
        passwordControl1: passwordControl1, setPasswordControl1,
        passwordControl2: passwordControl2, setPasswordControl2
      },
      functions: {
        getPageData,
        disableFields,
        searchUser,
        onChangeHandler,
        onKeyPressHandler,
        tryRegister,
        registerUser,
        navigatePage,
        changeLanguage
      }
    }}>
      {props.children}
    </RegistrationPageContext.Provider>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  userLoginSuccess,
  setLanguage,
  resetAllAccess,
  resetNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPageProvider);
