import { createFormControlModel } from '../models/formControlModel';
import { getDic } from '../assets/i18n/dictionary';

export function createStandModel(
    id = 0,
    name = "",
    order = 1,
    pavilion = 1,
    tooltip = "",
    coordinatesString = "",
) {
    const stand = {
        id: id,
        name: name,
        order: order,
        pavilion: pavilion && pavilion !== 0 ? pavilion : 1,
        tooltip: tooltip,
        coordinatesString: coordinatesString,
    }

    return stand;
}

export function convertStandObjectToForm(stand) {
    const _form = [];
    const _numberList = getPavilionsAndOrderList();
    var _index = 0;
    for (var key in stand) {
        if (stand.hasOwnProperty(key)) {
            var _type = "text";
            if (key.toString() === "order" || key.toString() === "pavilion") {
                _type = "select";
            }

            var _required = true;
            var _name = "";
            switch (key.toString()) {
                case "id":
                    _name = "id";
                    _required = true;
                    break;
                case "name":
                    _name = getDic("nome");
                    _required = true;
                    break;
                case "order":
                    _name = getDic("ordem");
                    _required = true;
                    break;
                case "pavilion":
                    _name = getDic("pavilhao");
                    _required = true;
                    break;
                case "tooltip":
                    _name = "Tooltip";
                    _required = true;
                    break;
                case "coordinatesString":
                    _name = getDic("coordenadas");
                    _required = false;
                    break;
                default:
                    break;
            }

            const _obj = createFormControlModel(
                _index,
                _name,
                stand[key].toString(),
                _type,
                null,
                null,
                _type === "select" ? _numberList : null, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                _required,
                null,
                null,
                null,
                key
            );
            _form.push(_obj);
            _index++;
        }
    }
    return _form;
}

export function convertFormToStandObject(form) {
    var _stand = createStandModel();
    form.forEach(item => {
        var _value;
        switch (item.dbReference) {
            case "id":
                _value = parseInt(item.value);
                break;
            case "name":
                _value = item.value.toString();
                break;
            case "order":
                _value = parseInt(item.value);
                break;
            case "pavilion":
                _value = parseInt(item.value);
                break;
            case "tooltip":
                _value = item.value.toString();
                break;
            case "coordinatesString":
                _value = item.value.toString();
                break;
            default:
                break;
        }
        _stand[item.dbReference] = _value;
    });
    return _stand;
}

export function getPavilionsAndOrderList() {
    const _numberList = [];
    for (let index = 1; index < 121; index++) {
        const _obj = {
            text: index.toString(),
            value: index.toString(),
        }
        _numberList.push(_obj);
    }
    return _numberList;
}

