import { store } from './store';
import { getDate } from './services/apiService';
import moment from 'moment';

//Classe para autenticação
//Retorna true ou false a partir de um token de acesso, que pode vir da api ou local store

export const authenticated = (page) => {

    const params = page.match.params.id.split("&");
    const eventId = parseInt(params[0]);
    const state = store.getState();

    //Verifica se possui state
    if (!state)
        return false;

    //Verifica se possui evento cadastrado e se a url está correta
    if (!state.event || state.event.id === 0 || state.event.id !== eventId)
        return false;

    //Verifica se houve login
    if (
        !state.user ||
        state.user.eventId !== eventId ||
        state.user.id === 0 ||
        state.user.name === '' ||
        !state.user.name
        // ||
        // (page.match.path !== "/:id/badge" && state.user.firstTerm === false) ||
        // (page.match.path !== "/:id/badge" && state.user.secondTerm === false)
    )
        return false;

    if (state.user.eventId !== state.event.id)
        return false;

    //Verificar se pode ficar no evento
    if (!state.user.isAdmin && !state.user.isStaff) {
        getDate()
            .then(date => {
                const _endEventDate = moment(store.getState().event.endDate).add(1, 'days').format();
                if (date > _endEventDate) {
                    window.location.replace(`/${eventId}`);
                }
            });
    }

    return true;
}
