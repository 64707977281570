import { SET_HISTORY, GO_BACK_HISTORY, RESET_HISTORY } from '../actions';

export default function chosenStandReducer(state = [], action) {
  switch (action.type) {
    case SET_HISTORY:
      return [...state, action.history];
    case GO_BACK_HISTORY:
      let _state = state.filter((h, i) => {
        if (i < (state.length - 1)) {
          return h;
        }
      });
      return [..._state];
    case RESET_HISTORY:
      return [];
    default:
      return state;
  }
}
