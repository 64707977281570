import React from "react";
import { connect } from "react-redux";
import { getDic } from "../../assets/i18n/dictionary";
import { insertNewAccess, getSystemActions } from "../../services/accessService";
import * as guestModel from "../../models/guestModel";
import * as raffleModel from "../../models/raffleModel";
import * as guestService from "../../services/guestService";
import * as raffleService from "../../services/raffleService";
import * as groupService from "../../services/groupService";
import RaffleWinnersListExcel from '../../excel/RaffleWinnersListExcel';

//Imports de components
import {
  Container,
  FormColumn,
  Alert,
  Loading,
} from "../../components";
import NavigationButtons from './NavigationButtons';
import CommandButtons from './CommandButtons';
import RaffleList from './RaffleList';
import ModalRaffleRegister from './ModalRaffleRegister';
import DialogConfirmAction from './DialogConfirmAction';
import ModalBlockList from './ModalBlockList';
import ModalAddBlocklist from './ModalAddBlocklist';
import ModalWinners from './ModalWinners';

const RafflePage = (props) => {
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: ''
  });
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [rafflesList, setRafflesList] = React.useState([]);
  const [modalRaffleRegisterControl, setModalRaffleRegisterControl] = React.useState({
    open: false,
    raffle: raffleModel.createRaffleModel(),
    groups: []
  });
  const [modalBlocklistControl, setModalBlocklistControl] = React.useState({
    open: false,
    blockList: [],
  });
  const [modalAddBlocklistControl, setModalAddBlocklistControl] = React.useState({
    open: false,
    guestsList: [],
    search: ''
  });
  const [modalWinnersControl, setModalWinnersControl] = React.useState({
    open: false,
    guestsList: [],
    raffle: raffleModel.createRaffleModel(),
  });
  const [dialogConfirmActionControl, setDialogConfirmActionControl] = React.useState({
    open: false,
    raffle: raffleModel.createRaffleModel(),
    guest: guestModel.createGuestModel(),
    confirmKey: '',
    confirmText: '',
    error: false,
  });
  const [excelImporterControl, setExcelImporterControl] = React.useState({
    exportTable: false,
  });
  const [winnersExcelExportControl, setWinnersExcelExportControl] = React.useState({
    exportTable: false,
    winnersList: [],
  });

  React.useEffect(() => {
    //Bloqueio caso o usuário não seja admin
    if (!props.user.isAdmin) {
      props.history.goBack();
    }

    getPageData();

    insertNewAccess(props.match.url, "", getSystemActions().accessPage);
    return () => {
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    };
  }, []);

  const getPageData = async () => {
    try {
      let _raffles = await raffleService.getRafflesListFirebase();
      setRafflesList(_raffles);

      let _groupsRet = await groupService.getGroupsApi();
      let _groups = [];
      _groupsRet.forEach(item => {
        _groups.push({
          text: item.name,
          value: item.id
        });
      });
      setModalRaffleRegisterControl({ ...modalRaffleRegisterControl, groups: _groups });

      setLoadingControl({ ...loadingControl, open: false });
    } catch (error) {
      console.log("Error getPageData", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }
  }

  const openModalBlocklist = async () => {
    try {
      setLoadingControl({ open: true, message: getDic("carregando") });

      let _blocklist = await raffleService.getRafflesBlocklistFirebase();
      setModalBlocklistControl({
        ...modalBlocklistControl,
        open: true,
        blockList: _blocklist
      });

      setLoadingControl({ ...loadingControl, open: false });
    } catch (error) {
      console.log("Error openModalBlocklist", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }
  }

  const searchAddBocklistGuests = async () => {
    try {
      setLoadingControl({ open: true, message: getDic("carregando") });

      let _res = await guestService.getGuestList(1, modalAddBlocklistControl.search);

      let _guests = [];
      _res.guestList.forEach(item => {
        let _hasGuest = modalBlocklistControl.blockList.find(g => { return g.id === item.id });
        if (!_hasGuest) {
          _guests.push(item);
        }
      });

      setModalAddBlocklistControl({
        ...modalAddBlocklistControl,
        guestsList: _guests
      });

      setLoadingControl({ ...loadingControl, open: false });
    } catch (error) {
      console.log("Error openModalBlocklist", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }
  }

  const addGuestToBlocklist = async (guest = guestModel.createGuestModel()) => {
    try {
      setLoadingControl({ open: true, message: getDic("carregando") });

      let _guest = await raffleService.saveRaffleBlocklistFirebase(guest);

      let _blockList = [...modalBlocklistControl.blockList];
      _blockList.push(_guest);
      setModalBlocklistControl({ ...modalBlocklistControl, blockList: _blockList });

      let _guestsList = [];
      modalAddBlocklistControl.guestsList.forEach(item => {
        if (item.id !== _guest.id) {
          _guestsList.push(item);
        }
      });

      setModalAddBlocklistControl({
        ...modalAddBlocklistControl,
        guestsList: _guestsList
      });

      setLoadingControl({ ...loadingControl, open: false });
    } catch (error) {
      console.log("Error addGuestToBlocklist", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }
  }

  const removeGuestFromBlocklist = async () => {
    try {
      setLoadingControl({ open: true, message: getDic("carregando") });

      let _guest = { ...dialogConfirmActionControl.guest };

      await raffleService.removeRaffleBlocklistFirebase(_guest);

      let _guestsList = [];
      modalBlocklistControl.blockList.forEach(item => {
        if (item.id !== _guest.id) {
          _guestsList.push(item);
        }
      });

      setModalBlocklistControl({
        ...modalBlocklistControl,
        blockList: _guestsList
      });

      setLoadingControl({ ...loadingControl, open: false });
    } catch (error) {
      console.log("Error removeGuestFromBlocklist", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }
  }

  const saveRaffle = async () => {
    try {
      let _errorMessage = verifyForm();
      if (_errorMessage) {
        setAlertControl({
          title: `${getDic("preenchimento")} ${getDic("invalido")}`,
          message: _errorMessage,
          open: true
        });
        return;
      }
      setLoadingControl({ message: getDic("salvando"), open: true });

      let _raffle = await raffleService.saveRaffleFirebase(modalRaffleRegisterControl.raffle);

      let _rafflesList = [...rafflesList];
      if (!modalRaffleRegisterControl.raffle.id) {
        _rafflesList = [...rafflesList, _raffle];
      } else {
        let _index = _rafflesList.findIndex(r => { return r.id === _raffle.id });
        _rafflesList[_index] = _raffle;
      }
      setRafflesList(_rafflesList);

      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: "",
        message: getDic("enviado-sucesso"),
        open: true
      });
      setModalRaffleRegisterControl({ ...modalRaffleRegisterControl, raffle: raffleModel.createRaffleModel(), open: false });
    } catch (error) {
      console.log("Error saveRaffle", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }

    function verifyForm() {
      let _return = '';
      let _form = modalRaffleRegisterControl.raffle;
      if (!_form.title) {
        _return = `${getDic("campo")} "${getDic("titulo")}" ${getDic("nao")} ${getDic("preenchido")}`;
      } else if (!_form.numberOfWinners || _form.numberOfWinners <= 0) {
        _return = `${getDic("campo")} "${getDic("numero")} ${getDic("de")} ${getDic("vencedores")}" ${getDic("invalido")}`;
      }
      return _return;
    }
  }

  const inactiveRaffle = async () => {
    try {
      setLoadingControl({ message: getDic("salvando"), open: true });

      let _raffle = { ...dialogConfirmActionControl.raffle };
      _raffle.active = false;
      await raffleService.saveRaffleFirebase(_raffle);

      await raffleService.deleteRaffleWinnersFirebase(_raffle.id);

      let _rafflesList = [...rafflesList];
      let _index = _rafflesList.findIndex(r => { return r.id === _raffle.id });
      _rafflesList[_index].active = false;
      setRafflesList(_rafflesList);

      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: "",
        message: getDic("enviado-sucesso"),
        open: true
      });
    } catch (error) {
      console.log("Error saveRaffle", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }
  }

  const copyRaffle = (raffle = raffleModel.createRaffleModel()) => {
    let _raffle = { ...raffle };
    _raffle.id = '';
    _raffle.date = '';
    _raffle.raffleRealized = false;
    _raffle.raffleRealizedDate = '';
    setModalRaffleRegisterControl({ ...modalRaffleRegisterControl, raffle: _raffle, open: true })
  }

  const clearWinnersDataAndRedoRaffle = async (raffle = raffleModel.createRaffleModel()) => {
    try {
      setLoadingControl({ message: getDic("salvando"), open: true });

      await raffleService.deleteRaffleWinnersFirebase(raffle.id);

      let _raffle = { ...raffle };
      _raffle.raffleRealized = false;
      _raffle.raffleRealizedDate = '';
      let _rafflesList = [...rafflesList];
      let _index = _rafflesList.findIndex(r => { return r.id === raffle.id });
      _rafflesList[_index] = _raffle;
      setRafflesList(_rafflesList);

      realizeRaffle(raffle);

    } catch (error) {
      console.log("Error realizeRaffle", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }
  }

  const realizeRaffle = async (raffle = raffleModel.createRaffleModel()) => {
    try {
      setLoadingControl({ message: getDic("salvando"), open: true });

      let _raffle = await raffleService.realizeRaffle(raffle);

      let _rafflesList = [...rafflesList];
      let _index = _rafflesList.findIndex(r => { return r.id === raffle.id });
      _rafflesList[_index] = _raffle;
      setRafflesList(_rafflesList);

      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: "",
        message: getDic("enviado-sucesso"),
        open: true
      });
    } catch (error) {
      console.log("Error realizeRaffle", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }
  }

  const getWinnersListAndOpenModal = async (raffle = raffleModel.createRaffleModel()) => {
    try {
      setLoadingControl({ message: getDic("carregando"), open: true });

      let _winners = await raffleService.getRaffleWinnersFirebase(raffle.id);
      let _finalWinners = [];
      _winners.forEach((w, i) => {
        if (i < raffle.numberOfWinners) {
          _finalWinners.push(w);
        };
      });

      setModalWinnersControl({
        ...modalWinnersControl,
        open: true,
        guestsList: _finalWinners,
        raffle: raffle
      });

      setLoadingControl({ ...loadingControl, open: false });
    } catch (error) {
      console.log("Error realizeRaffle", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }
  }

  const exportExcelAllRafflesWinnersList = async () => {
    try {
      setLoadingControl({ message: getDic("carregando"), open: true });

      let _winners = await getWinnersInformation();

      setWinnersExcelExportControl({
        ...winnersExcelExportControl,
        exportTable: true,
        winnersList: _winners
      });
      exportWinnersTable();

      setLoadingControl({ ...loadingControl, open: false });
    } catch (error) {
      console.log("Error exportExcelAllRafflesWinnersList", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }

    async function getWinnersInformation() {
      try {
        let _guestsList = [];

        let _winners = await raffleService.getAllRaffleWinnersFirebase();

        let _count = 0;
        do {
          let _res = await guestService.getGuestListV2(1, '', _winners[_count].guestId);
          if (_res.guestList[0]) {
            let _group = '';
            if (_res.guestList[0].groupId) {
              _group = modalRaffleRegisterControl.groups.find(g => { return g.value === _res.guestList[0].groupId }).text;
            }

            let _raffle = rafflesList.find(r => { return r.id === _winners[_count].raffleId });

            _guestsList.push({
              ..._res.guestList[0],
              group: _group,
              raffleId: _raffle.id,
              raffleTitle: _raffle.title,
              raffleRealizedDate: _raffle.raffleRealizedDate
            });
          }

          _count++;
        } while (_count < _winners.length);

        return _guestsList;
      } catch (error) {
        console.log("Error getWinnersInformation", error);
        throw error;
      }
    }
  }

  const exportWinnersTable = () => {
    var _btn = document.getElementById("btnExportWinnersTable");
    if (_btn) {
      setTimeout(() => {
        _btn.click();
      }, 500);
    }
    setTimeout(() => {
      setExcelImporterControl({ ...excelImporterControl, exportTable: false });
    }, 1000);
  }

  return (
    <Container background="#e8eced" align="center">
      <FormColumn align="center" margin="0px">
        <NavigationButtons history={props.history} />
        <CommandButtons
          modalRaffleRegisterControl={modalRaffleRegisterControl}
          setModalRaffleRegisterControl={setModalRaffleRegisterControl}
          openModalBlocklist={openModalBlocklist}
          history={props.history}
          event={props.event}
          exportExcelAllRafflesWinnersList={exportExcelAllRafflesWinnersList}
        />
        <RaffleList
          rafflesList={rafflesList}
          copyRaffle={copyRaffle}
          setModalRaffleRegisterControl={setModalRaffleRegisterControl}
          modalRaffleRegisterControl={modalRaffleRegisterControl}
          setDialogConfirmActionControl={setDialogConfirmActionControl}
          dialogConfirmActionControl={dialogConfirmActionControl}
          getWinnersListAndOpenModal={getWinnersListAndOpenModal}
        />
      </FormColumn>

      <ModalRaffleRegister
        modalRaffleRegisterControl={modalRaffleRegisterControl}
        setModalRaffleRegisterControl={setModalRaffleRegisterControl}
        saveRaffle={saveRaffle}
      />
      <DialogConfirmAction
        dialogConfirmActionControl={dialogConfirmActionControl}
        setDialogConfirmActionControl={setDialogConfirmActionControl}
        inactiveRaffle={inactiveRaffle}
        realizeRaffle={realizeRaffle}
        removeGuestFromBlocklist={removeGuestFromBlocklist}
        clearWinnersDataAndRedoRaffle={clearWinnersDataAndRedoRaffle}
      />
      <ModalBlockList
        excelImporterControl={excelImporterControl}
        setExcelImporterControl={setExcelImporterControl}
        modalBlocklistControl={modalBlocklistControl}
        setModalBlocklistControl={setModalBlocklistControl}
        setModalAddBlocklistControl={setModalAddBlocklistControl}
        modalAddBlocklistControl={modalAddBlocklistControl}
        setDialogConfirmActionControl={setDialogConfirmActionControl}
        dialogConfirmActionControl={dialogConfirmActionControl}
      />
      <ModalAddBlocklist
        modalAddBlocklistControl={modalAddBlocklistControl}
        setModalAddBlocklistControl={setModalAddBlocklistControl}
        searchAddBocklistGuests={searchAddBocklistGuests}
        addGuestToBlocklist={addGuestToBlocklist}
      />
      <ModalWinners
        modalWinnersControl={modalWinnersControl}
        setModalWinnersControl={setModalWinnersControl}
      />
      {winnersExcelExportControl.exportTable && (
        <div hidden>
          <RaffleWinnersListExcel
            event={props.event}
            data={winnersExcelExportControl.winnersList}
            onDownload={() => exportWinnersTable()}
            button={<button id="btnExportWinnersTable">X</button>}
          />
        </div>
      )}

      <Alert
        open={alertControl.open}
        onClose={() => setAlertControl({ ...alertControl, open: false })}
        onClick={() => setAlertControl({ ...alertControl, open: false })}
        title={alertControl.title}
        message={alertControl.message}
      />

      <Loading open={loadingControl.open} message={loadingControl.message} />
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(RafflePage);
