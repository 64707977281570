import { getModalTypeList } from '../services/configService';
import { createFormControlModel } from '../models/formControlModel';
import { getDic } from '../assets/i18n/dictionary';
// import {getHtml} from '../assets/files/acheflix';

export function createConfigModel(
    id = 0,
    templateId = 0,
    screenId = 0,
    typeId = 0,
    codeScreen = "",
    url = "",
    tooltip = "",
    eventId = 0,
    coordinates = [],
    coordinatesString = "",
    standId = 0,
    pavilion = 0,
    scheduleId = 0,
    password = "",
    configXGroup = [], //objeto = {"id": 1, "scheduleId" ou "configId": 902, "groupId": 15996}
    hasFullScreen = false,
    initialDate = "",
    endingDate = "",
    description = "",
    html = "",
    levelList = [],
) {
    //A url será transformada em array de urls para renderizar mais de um conteúdo
    var urls = [];
    if (url && url !== "") {
        var urlLocal = url.replace(/ /g, "");
        urls = urlLocal.split(";");
    }

    //A url[0] pode ter mais configurações no bloco style, elas serão separadas e inseridas em um objeto específico
    //A url[0] também possui recursos de multilinguagem, estas serão separadas em um objeto apartado
    //Isto vale apenas para ELEMENT e SCHEDULE
    var _config = createMulticonfigModel();
    var _multiLanguage = false;
    var _urlObject = createUrlMultilanguageObject();

    if (
        urls && urls.length > 0 &&
        codeScreen &&
        (codeScreen.includes("ELEMENT") || codeScreen.includes("SCHEDULE") || codeScreen.includes("BLOCK-ACCESS"))
    ) {

        var _url = "";
        // Monta lista de configurações
        if (urls[0].includes("style{")) {
            const _incame = urls[0].split(",style{");
            _url = _incame[0];

            var _array = _incame[1] ? _incame[1]
                .replace(/style{/g, "")
                .replace(/}/g, "")
                .split(",") : [];

            _array.forEach((conf) => {
                var _conf = conf.split(/:(.+)/);
                const _value = _conf[1] === "true" ? true : _conf[1] === "false" ? false : _conf[1];
                _config[_conf[0]] = _value;
            });
        }
        else {
            _url = urls[0];
        }

        //Monta lista de conteúdos
        if (urls[0].substring(0, 3).includes("en{")
            || urls[0].substring(0, 3).includes("sp{")
            || urls[0].substring(0, 3).includes("pt{")
        ) {
            const _incame = urls[0].split(",");
            _incame.forEach(item => {
                var _strLang = item.substring(0, 3);
                if (_strLang === "en{") {
                    _multiLanguage = true;
                    _urlObject.en = item.replace(/en{/g, "").replace(/.$/, "");
                }
                if (_strLang === "sp{") {
                    _multiLanguage = true;
                    _urlObject.sp = item.replace(/sp{/g, "").replace(/.$/, "");
                }
                if (_strLang === "pt{") {
                    _multiLanguage = true;
                    _urlObject.pt = item.replace(/pt{/g, "").replace(/.$/, "");
                }

                if (_strLang === "def" && item.includes("default{")) {
                    const _default = item.replace(/default{/g, "").replace(/.$/, "");
                    _urlObject.default = _default;
                }
            });
        }

        urls[0] = _url;
    }

    //Tratamento da informação dos grupos para setar apenas um array de ids
    var groups = [];
    if (configXGroup && configXGroup.length > 0) {
        configXGroup.forEach(item => {
            groups.push(item.groupId);
        });
    }

    //Verifica se a configuração possui data de início ou final
    var _hasRangeDate = false;
    if ((initialDate && initialDate !== "") || (endingDate && endingDate !== "")) {
        _hasRangeDate = true;
    }

    //Verifica se a config possui fases de gamefication
    const _levelListObject = [];
    if (levelList && levelList.length > 0) {
        levelList.forEach(item => {
            if (item.active && item.level) {
                const _level = createLevelObject(
                    item.level.id,
                    item.level.name,
                    item.level.urlImage,
                    item.level.initialValue,
                    item.level.finalValue,
                );
                _levelListObject.push(_level);
            }
        });
    }

    const config = {
        id: id,
        templateId: templateId,
        screenId: screenId,
        typeId: typeId,
        codeScreen: codeScreen,
        url: urls,
        urlString: url,
        tooltip: tooltip,
        eventId: eventId,
        coordinates: coordinates ? coordinates : [],
        coordinatesString: coordinatesString,
        standId: standId ? standId : 0,
        pavilion: pavilion && pavilion !== 0 ? pavilion : 1,
        scheduleId: scheduleId ? scheduleId : 0,
        hasPassword: password && password !== "" ? true : false,
        password: password,
        groupId: groups,
        hasFullScreen: hasFullScreen ? hasFullScreen : false,
        multiConfig: _config,
        hasRangeDate: _hasRangeDate,
        initialDate: initialDate,
        endingDate: endingDate,
        description: description,
        html: html,
        multiLanguage: _multiLanguage,
        urlObjct: _urlObject,
        levelList: _levelListObject,
    }

    return config;
}

export function createCoordinateModel(
    name = "",
    shape = "",
    coords = [],
    tooltip = "",
) {
    const config = {
        name: name,
        shape: shape,
        coords: coords,
        tooltip: tooltip ? tooltip : ""
    }

    return config;
}

export function createMulticonfigModel(
    iframeBackgroundColor = "transparent",
    iframeWidth = "70%",
    interactBackgroundColor = "transparent",
    interactWidth = "30%",
    calendar = false, // <== Comando para adicionar calendário a programação
    category = "",
    backgroundButton = "#3f51b5",
    textColorButton = "white",
    openWithPage = false, // <== Fazer a config abrir no didMount
    openModalFull = false,
    cardBackgroundImage = "",
    blockButtonCloseModal = false,
    rankingCode = '',
    rankingCodeSubtract = false,
) {
    const config = {
        iframeBackgroundColor: iframeBackgroundColor,
        iframeWidth: iframeWidth,
        interactBackgroundColor: interactBackgroundColor,
        interactWidth: interactWidth,
        calendar: calendar,
        category: category,
        backgroundButton: backgroundButton,
        textColorButton: textColorButton,
        openWithPage: openWithPage,
        openModalFull: openModalFull,
        cardBackgroundImage: cardBackgroundImage,
        blockButtonCloseModal: blockButtonCloseModal,
        rankingCode: rankingCode,
        rankingCodeSubtract: rankingCodeSubtract,
    }

    return config;
}

function createUrlMultilanguageObject(
    pt = "",
    en = "",
    sp = "",
    def = "",
) {
    const _obj = {
        pt: pt && pt !== "" ? pt : null,
        en: en && en !== "" ? en : null,
        sp: sp && sp !== "" ? sp : null,
        default: def && def !== "" ? def : null,
    }
    return _obj;
}

export function createLevelObject(
    id = 0,
    name = "",
    urlImage = "",
    initialValue = 0,
    finalValue = 0,
) {
    const _obj = {
        id: id,
        name: name,
        urlImage: urlImage,
        initialValue: initialValue,
        finalValue: finalValue,
    }
    return _obj;
}

export function convertConfigObjectToForm(config, codeScreenList, groupList, levelList, isImporter = false) {
    const _form = [];

    var _index = 0;

    for (var key in config) {
        if (config.hasOwnProperty(key)) {

            var _hasForm = false;
            var _type = "text";
            var _options = null;
            var _required = true;
            var _name = "";
            var _value = config[key] ? config[key].toString() : null;

            switch (key.toString()) {
                case "id":
                    _hasForm = true;
                    _name = "id";
                    _required = true;
                    _value = parseInt(config[key]);
                    break;

                //Campo apenas para planilha de importador
                case "screenId":
                    if (isImporter) {
                        _hasForm = true;
                        _name = getDic("tela");
                        _required = true;
                        _type = "select";
                    }
                    break;
                case "standId":
                    if (isImporter) {
                        _hasForm = true;
                        _name = "Stand";
                        _required = false;
                        _type = "select";
                    }
                    break;
                case "pavilion":
                    if (isImporter) {
                        _hasForm = true;
                        _name = getDic("pavilhao");
                        _required = false;
                        _type = "select";
                    }
                    break;

                case "typeId":
                    const _modalTypeList = [];
                    const _modalTypeBase = getModalTypeList();
                    _modalTypeBase.forEach(item => {
                        const _obj = {
                            text: item.text,
                            value: item.id,
                        }
                        _modalTypeList.push(_obj);
                    });
                    _hasForm = true;
                    _name = getDic("tipo");
                    _required = true;
                    _options = _modalTypeList;
                    _type = "select";
                    if (_value && isImporter) {
                        _modalTypeList.find(conf => {
                            if (conf.value.toString() === _value) {
                                _value = conf.text;
                            }
                            return null;
                        });
                    }
                    break;
                case "codeScreen":
                    _hasForm = true;
                    _name = `${getDic("codigo")} ${getDic("tela")}`;
                    _required = true;
                    _options = codeScreenList;
                    _type = "select";
                    if (_value) {
                        codeScreenList.find(conf => {
                            if (conf.text === _value) {
                                _value = isImporter ? conf.text : conf.value;
                            }
                            return null;
                        });
                    }
                    break;
                case "urlString":
                    _hasForm = true;
                    _name = `URL`;
                    _required = true;
                    break;
                case "tooltip":
                    _hasForm = true;
                    _name = `Tooltip`;
                    _required = false;
                    break;
                case "coordinatesString":
                    _hasForm = true;
                    _name = getDic("coordenadas");
                    _required = false;
                    break;
                case "password":
                    _hasForm = true;
                    _name = getDic("senha");
                    _required = false;
                    break;
                case "groupId":
                    _hasForm = true;
                    _name = getDic("grupo");
                    _required = false;
                    _options = groupList;
                    _type = "select";
                    _value = config[key] ? config[key] : [];
                    break;
                case "hasFullScreen":
                    const _optionsLocal = [];
                    const _optTrue = {
                        text: getDic("sim"),
                        value: true,
                    }
                    _optionsLocal.push(_optTrue);
                    const _optFalse = {
                        text: getDic("nao"),
                        value: false,
                    }
                    _optionsLocal.push(_optFalse);
                    _hasForm = true;
                    _name = "Fullscreen";
                    _required = false;
                    _options = _optionsLocal;
                    _type = "select";
                    _value = config[key] ? config[key] : false;
                    break;
                case "initialDate":
                    _hasForm = true;
                    _name = `${getDic("data")} ${getDic("inicio")}`;
                    _required = false;
                    _type = "datetime-local";
                    break;
                case "endingDate":
                    _hasForm = true;
                    _name = `${getDic("data")} ${getDic("fim")}`;
                    _required = false;
                    _type = "datetime-local";
                    break;
                case "description":
                    _hasForm = true;
                    _name = getDic("descricao");
                    _required = false;
                    break;
                case "html":
                    _hasForm = true;
                    _name = "HTML";
                    _required = false;
                    break;
                case "levelList":
                    _hasForm = true;
                    _name = getDic("fase");
                    _required = false;
                    _options = levelList;
                    _type = "select";
                    _value = config[key] ? config[key] : [];
                    break;
                default:
                    break;
            }

            if (_hasForm) {
                const _obj = createFormControlModel(
                    _index,
                    _name,
                    _value,
                    _type,
                    null,
                    null,
                    _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                    _required,
                    null,
                    null,
                    null,
                    key
                );
                _form.push(_obj);
                _index++;
            }
        }
    }
    return _form;
}

export function convertFormToConfigObject(form) {
    var _config = createConfigModel();
    form.forEach(item => {
        var _value;

        switch (item.dbReference) {
            case "id":
                _value = parseInt(item.value);
                break;
            case "screenId":
                _value = parseInt(item.value);
                break;
            case "typeId":
                _value = parseInt(item.value);
                break;
            case "codeScreen":
                _value = item.value ? item.value.toString() : null;
                break;
            case "urlString":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tooltip":
                _value = item.value ? item.value.toString() : null;
                break;
            case "coordinatesString":
                _value = item.value ? item.value.toString() : null;
                break;
            case "standId":
                _value = parseInt(item.value);
                break;
            case "pavilion":
                _value = item.value ? item.value.toString() : null;
                break;
            case "scheduleId":
                _value = parseInt(item.value);
                break;
            case "password":
                _value = item.value ? item.value.toString() : null;
                break;
            case "groupId":
                _value = item.value;
                break;
            case "hasFullScreen":
                _value = item.value;
                break;
            case "initialDate":
                _value = item.value ? item.value.toString() : null;
                break;
            case "endingDate":
                _value = item.value ? item.value.toString() : null;
                break;
            case "description":
                _value = item.value ? item.value.toString() : null;
                break;
            case "html":
                _value = item.value ? item.value.toString() : null;
                break;
            case "levelList":
                _value = item.value;
                break;
            default:
                break;
        }
        _config[item.dbReference] = _value;
    });
    return _config;
}
