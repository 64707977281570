import React from 'react';
import { createWhatsappModel, convertWhatsappObjectToForm } from '../models/whatsappModel';
import { createExampleLine, createTableBase, createTableOptions, getBorderObject } from './ImporterUtils';
import moment from 'moment';
import ReactExport from "react-data-export";
// https://www.npmjs.com/package/react-data-export
// Biblioteca para ler excel npm i read-excel-file

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const border = getBorderObject();

const WhatsappImporterExcel = (props) => {
  const {
    exampleFile = false,
    data,
    button,
  } = props;
  //O component funciona tanto para exportar a planilha de exemplo, como para devolver a planilha preenchida com os erros
  //Data deve ser um array de objetos do tipo createFormControlModel, conforme conversão de objeto convertWhatsappObjectToForm, ou uma lista de whatsapps
  //Caso seja uma planilha de exemplo, devolver os values com os exemplos a serem seguidos

  const whatsappFormList = convertWhatsappObjectToForm(createWhatsappModel());
  const templatesList = props.templatesList;

  function createTableInstructions() {
    var _multiDataSet = [
      {
        columns: [
          {
            title: "Coluna",
            style: {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "9e9e9e" }
              },
              border: border
            }
          },
          {
            title: "Valor",
            style: {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "9e9e9e" }
              },
              border: border
            }
          }
        ],
        data: []
      },
    ];

    const _rows = [];
    whatsappFormList.forEach(item => {
      const _row = [];
      const _obj1 = {
        value: `${item.name.toUpperCase()}${item.required ? " *" : ""}`,
        style: {
          border: border
        }
      }
      _row.push(_obj1);

      var _instruction = "";
      switch (item.dbReference) {
        case "id":
          _instruction = "Campo de referência para identificar se um registro foi salvo";
          break;
        case "templateName":
          _instruction = "Campo Obrigatório: Um único dado correspondente ao Código do Template conforme a aba Template";
          break;
        case "countryNumber":
          _instruction = "Campo Obrigatório: Obs => Apenas números. Número correspondente a discagem internacional (55 para Brasil)";
          break;
        case "mobileNumber":
          _instruction = "Campo Obrigatório: Obs => Apenas números. Número do telefone com DDD";
          break;
        case "url":
          _instruction = "Preencher apenas se o template possuir imagem, vídeo ou arquivo";
          break;
        default:
          _instruction = "Campo Obrigatório conforme template selecionado: Texto para adicionar a tag correspondente";
          break;
      }

      const _obj2 = {
        value: _instruction,
        style: {
          border: border
        }
      }
      _row.push(_obj2);
      _rows.push(_row);
    });

    _multiDataSet[0].data = _rows;

    return _multiDataSet;
  }

  function createTableTemplates(dataList) {

    var _multiDataSet = [
      {
        columns: [
          {
            title: `Cod Template`,
            style: {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "9e9e9e" }
              },
              border: getBorderObject()
            }
          },
          {
            title: "Template",
            style: {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "9e9e9e" }
              },
              border: getBorderObject()
            }
          },
          {
            title: "Mensagem",
            style: {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "9e9e9e" }
              },
              border: getBorderObject()
            }
          },
          {
            title: "Qtd Tags",
            style: {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "9e9e9e" }
              },
              border: getBorderObject()
            }
          },
          {
            title: "Possui Imagem/Arquivo",
            style: {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "9e9e9e" }
              },
              border: getBorderObject()
            }
          },
          {
            title: "Tipo URL",
            style: {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "9e9e9e" }
              },
              border: getBorderObject()
            }
          },
        ],
        data: [],
      }
    ];

    const _rows = [];
    if (dataList.length > 0) {
      dataList.forEach(item => {
        const _row = [];
        const _obj1 = {
          value: item.value.toString(),
          style: {
            border: getBorderObject()
          }
        };
        _row.push(_obj1);

        const _obj2 = {
          value: item.text.toString(),
          style: {
            border: getBorderObject()
          }
        };
        _row.push(_obj2);

        const _obj3 = {
          value: item.message ? item.message.toString() : '',
          style: {
            border: getBorderObject()
          }
        };
        _row.push(_obj3);

        const _obj4 = {
          value: item.numberOfTags.toString(),
          style: {
            border: getBorderObject()
          }
        };
        _row.push(_obj4);

        const _obj5 = {
          value: item.hasUrl ? "Sim" : "Não",
          style: {
            border: getBorderObject()
          }
        };
        _row.push(_obj5);

        const _obj6 = {
          value: item.urlType ? item.urlType.toString() : '',
          style: {
            border: getBorderObject()
          }
        };
        _row.push(_obj6);

        _rows.push(_row);
      });
    }
    else {
      const _row = [];
      const _obj1 = {
        value: "Não há dados cadastrados",
        style: {
          border: getBorderObject()
        }
      };
      _row.push(_obj1);

      const _obj2 = {
        value: "",
        style: {
          border: getBorderObject()
        }
      };
      _row.push(_obj2);

      _rows.push(_row);
    }

    _multiDataSet[0].data = _rows;

    return _multiDataSet;
  }

  function createDataList() {
    const _rows = [];
    data.forEach(item => {
      const _row = [];
      var _formObject;
      if (item[0] && item[0].dbReference) {
        _formObject = item;
      }
      else {
        _formObject = convertWhatsappObjectToForm(item);
      }
      _formObject.forEach(obj => {
        var _value = obj.value ? obj.value.toString() : "";
        const _obj = {
          value: _value + (obj.error ? ` => Erro: ${obj.errorMessage}` : ""),
          style: {
            border: border,
            fill: {
              fgColor: { rgb: obj.error ? "ff0000" : '00FFFFFF' }
            },
          }
        };
        _row.push(_obj);
      });
      _rows.push(_row);
    });
    return _rows;
  }

  if (data && data.length > 0 || exampleFile) {

    const _instructions = createTableInstructions();
    const _templates = createTableTemplates(templatesList);
    var _multiDataSet = createTableBase(`Importador Whatsapp`, whatsappFormList);
    var _rows = [];

    if (exampleFile) {
      const _row = createExampleLine(whatsappFormList);
      _row[2].value = "55";
      _rows.push(_row);
    }
    else if (data.length > 0) {
      _rows = createDataList();
    }

    _multiDataSet[3].data = _rows;

    return (
      <ExcelFile filename={`Importador Whatsapp - ${moment().format("DD-MM-YYYY HH-MM")}`} element={button}>
        <ExcelSheet dataSet={_multiDataSet} name={"Importador"} />
        <ExcelSheet dataSet={_templates} name={`Templates`} />
        <ExcelSheet dataSet={_instructions} name={"Instruções"} />
      </ExcelFile>
    );
  }
  return (
    <ExcelFile element={button} />
  );
}

export default WhatsappImporterExcel;

