import React from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { firestore } from "../../../../utils/firebase";

const ChatArea = (props) => {
  const [onlineUser, setOnlineuser] = React.useState(false);

  const onlineUserQuery = firestore
    .collection(`healthmap/${props.event.id}/ping`)
    .doc(`${props.currentTargetMsg.id}`);

  React.useEffect(() => {
    onlineUserQuery.get().then((s) => {
      if (s.data() && s.data().accessDate) {
        const dt = new Date();
        dt.setMinutes(dt.getMinutes() - 5);
        const expirationDate = s.data().accessDate.toDate();
        if (dt > new Date(expirationDate)) setOnlineuser(false);
        else setOnlineuser(true);
      } else setOnlineuser(false);
    });
  }, [props.currentTargetMsg.id]);

  return (
    <React.Fragment>
      {onlineUser ? (
        <React.Fragment>
          <i className="mdi mdi-circle text-success align-middle me-1" /> online
        </React.Fragment>
      ) : (
        <React.Fragment>
          <i className="mdi mdi-circle text-danger align-middle me-1" /> offline
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ChatArea;
