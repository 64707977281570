import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { getConfigListByPage } from '../services/configService';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { getGuestList } from '../services/guestService';
import { isMobile } from "react-device-detect";
import SearchIcon from '@material-ui/icons/Search';

//Imports de components
import {
    Container,
    Avatar,
    FormColumn,
    FormRow,
    ActivityIndicator,
    InputFieldOutlined,
    TextBig,
    TextMedium,
    MenuButton,
    BackButton,

    ExpansionPanel,
    Link,
    ListItem,
    InfiniteScroll
} from '../components';

class GuestsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            configList: [],
            guestsList: [],
            pageIndex: 0,
            totalPageIndex: 0,
            hasMore: true,
            noParticipants: false,
            imageURLBackground_1600x900: "",
            colorBackground: "#212121",
            searchString: "",
            lastSearchValue: "",
        }
    }

    async componentDidMount() {
        //Aplica configurações
        const configList = await getConfigListByPage(this.props.match.url);

        this.getGuests(true);

        var configBackgroundImage = configList.find(item => {
            return item.codeScreen === "IMAGE-001" && item.screenId === 14;
        });
        if (configBackgroundImage) {
            this.setState({ imageBackgroundUrl: configBackgroundImage.url[0], colorBackground: "#ffffff00" });
        }

        this.setState({ isLoading: false, configList: configList });

        await insertNewAccess(this.props.match.url, "", getSystemActions().accessPage);
    }

    async componentWillUnmount() {
        await insertNewAccess(this.props.match.url, "", getSystemActions().exitPage);
        // window.location.reload();
    }

    componentDidCatch(e) {
        var error = e.toString();

        if (error.includes("Error: Maximum update depth exceeded")) {
            window.location.reload();
        }
    }

    onChangeHandler(value) {
        this.setState({ searchString: value });
    }

    getGuests(newSearch = false) {

        var { hasMore, pageIndex, totalPageIndex, lastSearchValue } = this.state;

        if (newSearch) {
            this.setState({
                guestsList: [],
                pageIndex: 0,
                totalPageIndex: 0,
                hasMore: true,
                noParticipants: false,
                lastSearchValue: this.state.searchString,
            });
            hasMore = true;
            pageIndex = 0;
            totalPageIndex = 0;
            lastSearchValue = this.state.searchString;
        }
        //Fazer a busca apenas se houver mais dados
        if (hasMore && pageIndex <= totalPageIndex) {
            var index = pageIndex + 1;
            getGuestList(index, lastSearchValue)
                .then(res => {

                    var complete = this.state.guestsList.concat(res.guestList);

                    var noParticipants = false;
                    if (index === 1 && res.guestList.length === 0) {
                        noParticipants = true
                    }

                    this.setState({ guestsList: complete, pageIndex: index, totalPageIndex: res.totalPages, noParticipants: noParticipants });
                })
                .catch(err => {
                    console.log("Erro getGuestList", err);
                    this.setState({ guestsList: [], pageIndex: 0, totalPageIndex: 0, noParticipants: true });
                })
        }
        else if (this.state.hasMore) {
            this.setState({ hasMore: false });
        }
        else {
            return;
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Container background="#e8eced" align="center" >
                    <FormRow align="center">
                        <ActivityIndicator />
                    </FormRow>
                </Container>
            );
        }

        return (
            <Container align="center" background={this.state.colorBackground}>
                {!this.props.isModal && (
                    <>
                        <MenuButton history={this.props.history} />
                        <BackButton history={this.props.history} color="#212121">
                            {getDic("voltar").toUpperCase()}
                        </BackButton>

                    </>
                )}

                <img src={this.state.imageBackgroundUrl} className="bg" style={{ width: "100%", height: "100%" }} />
                <table style={{ width: '100%' }}>
                    <tr>
                        <FormRow height="6.5vh" align="flex-end" margin="0px" />
                    </tr>
                    <tr>
                        <FormRow paddingTop="5px" paddingBottom="5px" height="9vh" align="flex-end">
                            <div style={{ paddingRight: "22px", maxWidth: "400px" }}>
                                <InputFieldOutlined
                                    key="search"
                                    value={this.state.searchString}
                                    placeholder={getDic("buscar")}
                                    onChange={event => this.onChangeHandler(event.target.value)}
                                    onKeyPress={event => { if (event.key === 'Enter') { this.getGuests(true) } }}
                                    endAdornment={<Link color="gray"><SearchIcon onClick={() => this.getGuests(true)} /></Link>}
                                />
                            </div>
                        </FormRow>
                    </tr>
                    <tr>
                        <FormColumn align="center" background={this.state.colorBackground} margin="0px" width="100%">
                            {this.state.noParticipants ? (
                                <div style={{ color: 'white' }}>
                                    <ListItem text={getDic("pesquisa-sem-resultado")} />
                                </div>
                            ) : (
                                <InfiniteScroll
                                    pageStart={0}
                                    dataLength={this.state.guestsList.length}
                                    height={"80vh"}
                                    next={() => {
                                        this.getGuests();
                                    }}
                                    hasMore={this.state.hasMore}
                                    loader={<ActivityIndicator />}
                                >
                                    {this.state.guestsList.map((item) => {
                                        return (
                                            <div
                                                key={item.id}
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "10px",
                                                    marginLeft: "1%",
                                                    marginRight: "1%",
                                                    width: '98%',
                                                }}>
                                                <ExpansionPanel
                                                    headerContent={
                                                        <div style={{ width: "79vw" }}>
                                                            <FormRow align="left">
                                                                <div style={{ width: '70px' }}>
                                                                    <FormRow align="left" padding="0px">
                                                                        <Avatar
                                                                            size="small"
                                                                            src={item.image}
                                                                        // status="inactive"
                                                                        />
                                                                    </FormRow>
                                                                </div>
                                                                <div style={{ flexGrow: 1, position: 'relative' }}>
                                                                    <FormRow align="left" padding="0px">
                                                                        <TextBig fontWeight="bold">
                                                                            {item.name.toUpperCase()}
                                                                        </TextBig>
                                                                    </FormRow>
                                                                    <FormRow align="left" padding="0px">
                                                                        <TextBig>
                                                                            {item.institution.toUpperCase()}
                                                                        </TextBig>
                                                                    </FormRow>
                                                                    <FormRow align="left" padding="0px">
                                                                        <TextMedium>
                                                                            {item.position}
                                                                        </TextMedium>
                                                                    </FormRow>
                                                                </div>
                                                            </FormRow>
                                                        </div>
                                                    }>
                                                    {/* Conteúdo */}

                                                </ExpansionPanel>
                                            </div>
                                        );
                                    })}
                                </InfiniteScroll>
                            )}
                        </FormColumn>
                    </tr>
                </table>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        event: state.event,
    }
}

export default connect(mapStateToProps, null)(GuestsPage)
