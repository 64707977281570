import React from "react";
// import ZoomVideo from '@zoom/videosdk';

const ZoomTransmission = props => {
    // const client = ZoomVideo.createClient();

    // const [loading, setLoading] = React.useState(true);

    // React.useEffect(() => {
    //     getPageData();
    //     return () => {
    //     }
    // }, []);

    // const getPageData = () => {
    //     client.init('pt-PT', 'http://127.0.0.1:9999/')
    //         .then(res => console.log(res))
    // }

    return null;

}

export default ZoomTransmission;
