import React from 'react';
import { getDic } from "../../assets/i18n/dictionary";
import * as raffleModel from "../../models/raffleModel";
import {
  GridRow,
  GridCell,
  TextMedium,
} from "../../components";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import SendIcon from '@material-ui/icons/Send';
import CopyIcon from '@material-ui/icons/FileCopy';
import WinnerIcon from '@material-ui/icons/EmojiEvents';

const RaffleList = props => {
  const {
    rafflesList,
    copyRaffle,
    setModalRaffleRegisterControl,
    modalRaffleRegisterControl,
    setDialogConfirmActionControl,
    dialogConfirmActionControl,
    getWinnersListAndOpenModal,
  } = props;

  const getRulesText = (raffle = raffleModel.createRaffleModel()) => {
    let _return = '';

    if (raffle.numberOfWinners > 0) {
      _return += `${getDic("numero")} ${getDic("de")} ${getDic("vencedores")}: ${raffle.numberOfWinners}`;
    }
    if (raffle.onlyNotAwarded) {
      _return += `, ${getDic("apenas")} ${getDic("nao")} ${getDic("sorteados")}`;
    }
    if (raffle.groupId > 0) {
      let _text = '';
      raffle.groupId.forEach(groupId => {
        let _gText = modalRaffleRegisterControl.groups.find(g => { return g.value === groupId });
        if (_gText) {
          _text = !_text ? _gText.text : `, ${_gText.text}`;
        }
      });
      _return += `, ${getDic("apenas")} ${raffle.groupId.length <= 1 ? getDic("grupo") : `${getDic("grupo")}s`}: ${_text}`;
    }
    if (raffle.onlyOnline > 0) {
      _return += `, ${getDic("apenas")} ${getDic("usuarios")} Online`;
    }
    if (raffle.onlyPage) {
      _return += `, ${getDic("apenas")} ${getDic("usuarios")} ${getDic("pagina")} ${raffle.onlyPage}`;
    }

    return _return;
  }

  return (
    <div style={{ padding: 5 }}>
      <GridRow backgroundColor="#d9d9d9">
        <GridCell width="10%" border top first>
          <TextMedium>ID</TextMedium>
        </GridCell>
        <GridCell width="35%" border top>
          <TextMedium>{getDic("titulo").toUpperCase()}</TextMedium>
        </GridCell>
        <GridCell width="45%" border top>
          <TextMedium>{getDic("regras").toUpperCase()}</TextMedium>
        </GridCell>
        <GridCell width="10%" border top>
          <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
        </GridCell>
      </GridRow>

      {rafflesList.length > 0 ? (
        rafflesList.map((raffle, i) => {
          if (!raffle.active) {
            return (null);
          }

          return (
            <GridRow
              key={raffle.id}
              autoColor={i}
            >
              <GridCell width="10%" border first>
                <TextMedium>{raffle.id}</TextMedium>
              </GridCell>
              <GridCell width="35%" border>
                <TextMedium>{raffle.title}</TextMedium>
              </GridCell>
              <GridCell width="45%" border>
                <TextMedium>{getRulesText(raffle)}</TextMedium>
              </GridCell>
              <GridCell width="10%" border>
                <CopyIcon onClick={() => copyRaffle(raffle)} style={{ cursor: "pointer" }} />
                <EditIcon onClick={() => setModalRaffleRegisterControl({ ...modalRaffleRegisterControl, raffle: raffle, open: true })} style={{ cursor: "pointer" }} />
                <SendIcon
                  onClick={() => setDialogConfirmActionControl({
                    ...dialogConfirmActionControl,
                    confirmKey: raffle.raffleRealized ? `${getDic("refazer")} ${getDic("sorteio")}` : getDic("sorteio"),
                    confirmText: '',
                    raffle: raffle,
                    open: true
                  })}
                  style={{ cursor: "pointer" }}
                />
                <DeleteIcon
                  onClick={() => setDialogConfirmActionControl({
                    ...dialogConfirmActionControl,
                    confirmKey: getDic("deletar"),
                    confirmText: '',
                    raffle: raffle,
                    open: true
                  })}
                  style={{ cursor: "pointer" }}
                />
                {raffle.raffleRealized && (
                  <WinnerIcon onClick={() => getWinnersListAndOpenModal(raffle)} style={{ cursor: "pointer" }} />
                )}
              </GridCell>
            </GridRow>
          );
        })
      ) : (
        <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
      )}
    </div>
  )
};

export default RaffleList;
