import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { getSpeakersApi, addNewSpeakerApi, updateSpeakerApi, deleteSpeakerApi } from '../services/speakerService';
import { createSpeakerModel, convertFormToSpeakerObject, convertSpeakerObjectToForm } from '../models/speakerModel';
import { validateForm } from '../utils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
//Imports de components
import {
  MenuButton,
  BackButton,

  Alert,
  Loading,
  Container,
  FormRow,
  FormColumn,
  GridRow,
  GridCell,
  TextMedium,
  Button,
  Dialog,
  InputFieldOutlined,
  TextSmall,
  InfiniteScroll,
  SelectList,
  Modal,
  Avatar
} from '../components';

const SpeakersRegisterPage = (props) => {
  const [state, setState] = React.useState(true);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(true);

  //Dialog control
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [dialogConfirmKey, setDialogConfirmKey] = React.useState("");
  const [dialogConfirmKeyErrorMessage, setDialogConfirmKeyErrorMessage] = React.useState("");

  //Modal Control
  const [openModal, setOpenModal] = React.useState(false);

  const [speakersList, setSpeakersList] = React.useState([]);
  const [chosenSpeaker, setChosenSpeaker] = React.useState(null);
  const [newSpeakerForm, setNewSpeakerForm] = React.useState([]);

  React.useEffect(() => {
    //Bloqueio caso o usuário não seja admin
    if (!props.user.isAdmin) {
      props.history.goBack();
    }

    var helpButton = document.getElementById("launcher");
    if (helpButton) {
      helpButton.style.visibility = 'hidden';
    }

    getPageData();
    insertNewAccess(props.match.url, "", getSystemActions().accessPage);

    return () => {
      var helpButton = document.getElementById("launcher");
      if (helpButton) {
        helpButton.style.visibility = 'visible';
      }
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    }
  }, []);

  const getPageData = async () => {
    setOpenLoading(true);

    getSpeakersApi(props.event.id)
      .then(res => {
        setSpeakersList(res);
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  const onChangeHandler = (index, value) => {
    var _newForm = newSpeakerForm;
    _newForm[index].value = value;
    setNewSpeakerForm(_newForm);
    setState(state === true ? false : true);
  }

  const resetSpeakerForm = () => {
    const _newSpeaker = createSpeakerModel();
    const _newForm = convertSpeakerObjectToForm(_newSpeaker);
    setNewSpeakerForm(_newForm);
  }

  const createNewSpeaker = () => {
    const _newSpeaker = createSpeakerModel();
    const _newForm = convertSpeakerObjectToForm(_newSpeaker);
    setNewSpeakerForm(_newForm);
    setOpenModal(true);
  }

  const editSpeaker = (speaker) => {
    const _newForm = convertSpeakerObjectToForm(speaker);
    setNewSpeakerForm(_newForm);
    setOpenModal(true);
  }

  const inactiveSpeaker = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("salvando"));

    deleteSpeakerApi(chosenSpeaker.id)
      .then(res => {
        const _newSpeakerList = [];
        speakersList.forEach(item => {
          if (item.id !== chosenSpeaker.id) {
            _newSpeakerList.push(item);
          }
        });
        _newSpeakerList.sort((a, b) => {
          return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
        });
        setSpeakersList(_newSpeakerList);
        setAlertOpen(true);
        setAlertTitle("");
        setAlertMessage(getDic("enviado-sucesso"));
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setOpenLoading(false);
      })
      .finally(() => {
        setOpenLoading(false);
      })
  }

  const saveSpeaker = async () => {
    var validation = validateForm(newSpeakerForm);
    if (validation.isValid) {

      var _newSpeaker = convertFormToSpeakerObject(newSpeakerForm);

      setOpenLoading(true);
      setLoadingMessage(getDic("salvando"));

      var _error = false;
      const _newSpeakerList = [];

      if (_newSpeaker.id > 0) {
        await updateSpeakerApi(_newSpeaker)
          .then(res => {
            _newSpeakerList.push(res);
          })
          .catch(err => {
            _error = true;
          })
      }
      else {
        await addNewSpeakerApi(_newSpeaker)
          .then(res => {
            _newSpeaker.id = res.id;
            _newSpeakerList.push(_newSpeaker);
          })
          .catch(err => {
            _error = true;
          })
      }

      if (_error) {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setOpenLoading(false);
      }
      else {
        speakersList.forEach(item => {
          if (item.id !== _newSpeaker.id) {
            _newSpeakerList.push(item);
          }
        });
        _newSpeakerList.sort((a, b) => {
          return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
        });
        setSpeakersList(_newSpeakerList);
        setAlertOpen(true);
        setAlertTitle("");
        setAlertMessage(getDic("enviado-sucesso"));
        resetSpeakerForm();
        setOpenLoading(false);
        setOpenModal(false);
        setState(state === true ? false : true);
      }
    }
    else {
      setNewSpeakerForm(validation.form);
    }
  }

  return (
    <Container background="#e8eced" align="center">
      <FormRow align="center" background="#e8eced" margin="0px">
        <div>
          <MenuButton history={props.history} />
          <BackButton history={props.history} color="#212121">
            {getDic("voltar").toUpperCase()}
          </BackButton>

        </div>

        <FormRow
          minHeight="60px"
          align="center"
          background="#e8eced"
          margin="0px"
        />
        <FormRow
          minHeight="60px"
          align="flex-end"
          background="#e8eced"
          margin="0px"
          paddingTop="5px"
        >
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <Button
              color="green"
              textColor="white"
              onClick={() => createNewSpeaker()}
            >
              {`${getDic("cadastrar")} ${getDic("palestrante")}`}
            </Button>
          </div>
        </FormRow>

        <FormColumn align="center" background="#e8eced" margin="0px" padding="5px">
          {speakersList.length > 0 ? (
            <InfiniteScroll
              pageStart={0}
              dataLength={speakersList.length}
              height={"79vh"}
            >
              <GridRow backgroundColor="#d9d9d9">
                <GridCell width="10%" border top first>
                  <TextMedium>ID</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("foto").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="30%" border top>
                  <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="20%" border top>
                  <TextMedium>{getDic("empresa").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="20%" border top>
                  <TextMedium>{getDic("cargo").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
                </GridCell>
              </GridRow>

              {speakersList.map((item, i) => {
                return (
                  <GridRow
                    key={item.id}
                    autoColor={i}
                  >
                    <GridCell width="10%" border first>
                      <TextMedium>{item.id}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border>
                      <FormRow align="center">
                        <Avatar src={item.image} size="smaller" />
                      </FormRow>
                    </GridCell>
                    <GridCell width="30%" border>
                      <TextMedium>{item.name}</TextMedium>
                    </GridCell>
                    <GridCell width="20%" border>
                      <TextMedium>{item.company}</TextMedium>
                    </GridCell>
                    <GridCell width="20%" border>
                      <TextMedium>{item.role}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border>
                      <EditIcon onClick={() => editSpeaker(item)} style={{ cursor: "pointer" }} />
                      <DeleteIcon
                        onClick={() => {
                          setChosenSpeaker(item);
                          setDialogConfirmOpen(true);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </GridCell>
                  </GridRow>
                );
              })}
            </InfiniteScroll>
          ) : (
            <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
          )}
        </FormColumn>

        <Modal
          isVideo
          height="80vh"
          open={openModal}
          closeButtonClick={e => setOpenModal(false)}
          onClose={ret => {
            setOpenModal(false);
          }}
        >
          <Container background="#e8eced" align="left" height="100%" >
            <FormColumn padding="5px" margin="0px" background="#e8eced">
              {newSpeakerForm.map((item, i) => {
                if (i === 0) {
                  return (
                    <>
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <TextMedium>
                          {item.value > 0 ? (
                            `${getDic("editar")} ${getDic("programacao")}`
                          ) : (
                            `${getDic("nova")} ${getDic("programacao")}`
                          )}
                        </TextMedium>
                      </FormRow>
                    </>
                  )
                }
                else {
                  if (item.type === "select" && item.values) {
                    return (
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <SelectList
                          required={item.required}
                          error={item.error}
                          errorMessage={item.errorMessage}
                          title={item.name}
                          width="100%"
                          value={item.value}
                          menuItens={item.values}
                          onChange={async (event) =>
                            onChangeHandler(i, event.target.value)
                          }
                          multiple={Array.isArray(item.value) ? true : false}
                        />
                      </FormRow>
                    );
                  }
                  else {
                    return (
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <InputFieldOutlined
                          key={item.id}
                          required={item.required}
                          maxLength={item.maxLength}
                          error={item.error}
                          errorMessage={item.errorMessage}
                          value={item.value}
                          title={item.name}
                          type={item.type}
                          onChange={(event) => {
                            onChangeHandler(i, event.target.value)
                          }}
                          multiline={item.dbReference === "speakerType" ||
                            item.dbReference === "groupId" ? true : false}
                        />
                      </FormRow>
                    );
                  }
                }
              })}
              <FormRow align="center" paddingTop="7px">
                <Button
                  text={getDic("salvar")}
                  onClick={() => saveSpeaker()}
                />
              </FormRow>
            </FormColumn>
          </Container>
        </Modal>

        <Alert
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          onClick={() => setAlertOpen(false)}
          title={alertTitle}
          message={alertMessage}
        />

        <Dialog
          open={dialogConfirmOpen}
          onClose={() => setDialogConfirmOpen(false)}
          title={getDic("atencao")}
          textButtonYes={getDic("inativar")}
          onClickYes={() => {
            if (dialogConfirmKey && dialogConfirmKey != "" && dialogConfirmKey.toUpperCase() === "SIM") {
              inactiveSpeaker();
              setDialogConfirmOpen(false);
              setDialogConfirmKey("");
            }
            else {
              setDialogConfirmKeyErrorMessage(true);
              setTimeout(() => {
                setDialogConfirmKeyErrorMessage(false);
              }, 3000); // 1 minuto = 60000
            }
          }}
          textButtonNo={getDic("cancelar")}
          onClickNo={() => {
            setDialogConfirmOpen(false);
            setDialogConfirmKey("");
          }}
        >
          <FormRow>
            <TextSmall>
              {getDic("confirmacao-de-acao")}
            </TextSmall>
          </FormRow>
          <FormRow align="center" paddingTop="5px">
            <InputFieldOutlined
              key="password"
              value={dialogConfirmKey}
              onChange={event => setDialogConfirmKey(event.target.value)}
            />
          </FormRow>
          {dialogConfirmKeyErrorMessage ? (
            <TextSmall color="red">{getDic("erro")}</TextSmall>
          ) : (null)}
        </Dialog>

        <Loading open={openLoading} message={loadingMessage} />
      </FormRow>
    </Container>
  )
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(SpeakersRegisterPage);
