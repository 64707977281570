import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getDic } from "../../assets/i18n/dictionary";
import { isMobile } from "react-device-detect";
import FeedCard from "./FeedCard";
import FeedNewPost from "./FeedNewPost";
import LoadFeedCard from "./LoadFeedCard";
import {
  FeedData
} from "../../components";
import feedImgBackground from "../../assets/images/social-media.jpg";

const PostFeed = props => {
  //Context
  const ContextRef = React.useContext(FeedData);
  const [postsData, setPostsData] = React.useState([]);
  const [currentSize, setNewSize] = React.useState(5);
  const [hasMore, setHasMore] = React.useState(true);
  //
  const getMorePosts = _m => {
    ContextRef._getPosts({ pageSize: currentSize + 5, pageIndex: 1 }).then(result => {
      setNewSize(currentSize + 5);

      if (result.data.totalItens < result.data.pagesSize) {
        setHasMore(false);
      }
    })
  }
  React.useEffect(() => {
    setPostsData([...ContextRef._posts]);
  }, [ContextRef._posts]);

  return (<div style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: 'auto',
    height: isMobile ? '100vh' : 'auto',
    minHeight: '100%',
    backgroundImage: `url(${ContextRef._feed_image_background !== '#' ? ContextRef._feed_image_background : feedImgBackground})`,
    // backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',

  }}>
    <div>
      <FeedNewPost openTextArea={ContextRef._posts.length} />
      {!ContextRef._post_loaded ?
        <React.Fragment>
          <LoadFeedCard />
          <LoadFeedCard />
          <LoadFeedCard />
          <LoadFeedCard />
        </React.Fragment>
        :
        ContextRef._posts.length > 0 ?
          <InfiniteScroll
            dataLength={postsData.length}
            next={() => getMorePosts()}
            hasMore={hasMore}
            loader={
              <div
                style={{ height: "auto", textAlign: "center", padding: 33 }}
              >
                <CircularProgress color="#337ab7" />
              </div>
            }
          >
            {ContextRef._posts.map((e, index) => <FeedCard key={index} postContent={e} />)}
          </InfiniteScroll> :
          <span style={{
            background: "white",
            color: "#484646",
            padding: 15,
            borderRadius: 4
          }}>
            {getDic("nao-ha-postagens")}
          </span>
      }
    </div>
  </div>)
}


export default PostFeed;
