import React from "react";
import { connect } from "react-redux";
import { store } from '../store';
import { getDate } from '../services/apiService';
import { createCountdownModel } from '../models/countdownModel';
import moment from 'moment';

//Imports de components
import {
    Snackbar,
    ElementTreatment
} from '../components';
import { getDic } from '../assets/i18n/dictionary';

var timeout;

const CountdownToExecute = (props) => {

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [executeThread, setExecuteThread] = React.useState(false);
    const [chosenConfig, setChosenConfig] = React.useState(null);
    const [executeElement, setExecuteElement] = React.useState(false);
    const [countdownList, setCountdownList] = React.useState([]);
    const [stopwatch, setStopwatch] = React.useState("");

    React.useEffect(() => {

        getCountdownList();

        return () => {
        }
    }, []);

    const getCountdownList = () => {
        const _listCount = [];

        // Monta lista de objetos do countdown
        props.countdownList.forEach(async item => {
            var _strList = item.url[0].split(",");
            var _count = createCountdownModel();

            _strList.forEach(obj => {
                var _value = obj.split(/:(.+)/);
                _count[_value[0]] = _value[1];
            });
            _count.message = item.tooltip;

            const _currentDate = await getDate();
            // Inserir apenas os que forem acontecer
            if (_count.date > _currentDate) {
                _listCount.push(_count);
            }
        });

        const _listFinal = _listCount.sort((a, b) => {
            return (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)
        });

        setCountdownList(_listFinal);
        setExecuteThread(true);
    }

    const getStopwatch = async () => {

        if (executeThread) {
            const _currentDate = await getDate();

            // Pega a diferença entre as datas
            const _diff = moment(countdownList[0].date).diff(_currentDate);

            //Converte em duração
            const _diffDuration = moment.duration(_diff);

            //Caso o timer passe do período, executar a config, se não, retorna cronômetro
            if (_diffDuration && _diffDuration._data.seconds > -2) {
                if (_diffDuration._data.seconds < 0) {
                    setOpenSnackbar(false);
                    setCountdownList([]);
                    setExecuteThread(false);
                    executeConfig();
                }
                else {
                    // Concatena em um formato para exibir
                    var _ret = "";
                    _ret += (_diffDuration._data.days > 0 ? _diffDuration._data.days + " " + getDic("dia") + "(s), " : "");
                    _ret += (_diffDuration._data.hours > 0 ? _diffDuration._data.hours + ":" : "");
                    _ret += (_diffDuration._data.minutes > 9 ? _diffDuration._data.minutes : "0" + _diffDuration._data.minutes) + ":";
                    _ret += (_diffDuration._data.seconds > 9 ? _diffDuration._data.seconds : "0" + _diffDuration._data.seconds);

                    setStopwatch(_ret);
                    setOpenSnackbar(true);
                    stopwatchThread();
                }
            }
            else {
                clearTimeout(timeout);
            }
        }
    }

    const stopwatchThread = () => {
        if (executeThread) {
            timeout = setTimeout(async () => {
                await getStopwatch();
            }, 1000);
        }
    }

    const executeConfig = () => {
        const _config = store.getState().config.find(item => {
            return (item.codeScreen.includes("ELEMENT") || item.codeScreen.includes("SCHEDULE"))
                && (item.id === parseInt(countdownList[0].config) || item.scheduleId === parseInt(countdownList[0].config));
        });

        if (_config) {
            setChosenConfig(_config);
            setExecuteElement(true);
        }
    }

    return (
        <div>
            {countdownList.length > 0 && (
                <div>
                    {stopwatchThread()}
                    {countdownList[0].timer && (
                        <Snackbar
                            open={openSnackbar}
                            horizontal={countdownList[0].horizontal}
                            vertical={countdownList[0].vertical}
                        >
                            {countdownList[0].message} {stopwatch}
                        </Snackbar>
                    )}
                </div>
            )}

            {executeElement && (
                <ElementTreatment
                    history={props.history}
                    chosenConfig={chosenConfig}
                    onClose={() => {
                        setExecuteElement(false);
                        setChosenConfig(null);
                        getCountdownList();
                    }}
                />
            )}
        </div>
    );
}

function mapStateToProps(state) {
    const countdownList = state.config.filter((item) => {
        return item.codeScreen === "COUNTDOWN-TO-EXECUTE";
    });

    return {
        countdownList: countdownList,
    };
}

export default connect(mapStateToProps, null)(CountdownToExecute);
