import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { isMobile } from "react-device-detect";

import { domtoimage } from "../../components";
import { getBase64ByURL } from "../../services/crachaService";

const UserCracha = (props) => {
  const inputName = React.useRef();
  const pdfRef = React.createRef();

  const [editName, startEditName] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [firstLoading, setLoadingEnd] = React.useState(false);
  // const [profileCracha, setProfileCracha] = React.useState("");
  const [backgroundCracha, setBackgroundCracha] = React.useState();
  const styles = {
    ContainerConfig: {
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      width: "100%",
      maxWidth: 269,
      height: 393,
      maxHeight: 393,
      marginTop: 14,
    },
    floatedImg: {
      height: 114,
      borderRadius: 80,
      border: "0px solid grey",
    },
    containerImageFloated: {
      position: "relative",
      top: "36%",
      left: "30%",
    },
    nameLabel: {
      color: "#000",
      textAlign: "center",
      fontSize: 20,
      fontWeight: 500,
      position: "relative",
      top: 300,
    },
    btnLabel: {
      color: props.chosenConfig.multiConfig.textColorButton,
      backgroundColor: props.chosenConfig.multiConfig.backgroundButton,
      position: "relative",
      top: "350px",
      left: "20%",
      marginTop: 10,
    },
  };
  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      startEditName(!editName);
      userName == "" && setUserName(props.user.name);
    }
  };

  const iOS = () => {
    const toMatch = [
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
    ];

    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
      // android or ETC
      toMatch.some((toMatchItem) => { return navigator.userAgent.match(toMatchItem); })
    );
  };

  const _downloadToPdf = (dom) => {
    const element = ReactDOM.findDOMNode(pdfRef.current);
    var options = {
      quality: 1,
    };

    if (iOS()) {
      return domtoimage.toSvg(element, options).then(function (dataUrl) {
        const doc = new jsPDF();
        var svgimg = document.createElementNS("http://www.w3.org/2000/svg", "image");
        svgimg.setAttributeNS("http://www.w3.org/1999/xlink", 'xlink:href', dataUrl);
        document.getElementById("cracha_svg").appendChild(svgimg);
        doc.addSvgAsImage(svgimg, 0, 0);
        doc.save("cracha.pdf");

      })
    } else {
      return domtoimage.toPng(element, options).then(function (dataUrl) {
        const doc = new jsPDF();
        doc.addImage(dataUrl, "PNG", 70, 10);
        doc.save("cracha.pdf");
      });
    }
  };

  const _downloadToPng = (dom) => {
    const element = ReactDOM.findDOMNode(pdfRef.current);
    var options = {
      quality: 1,
    };
    if (iOS()) {
      return domtoimage.toSvg(element, options).then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "cracha.svg";
        link.target = "_blank";
        link.href = dataUrl;
        link.click();
      });
    } else {
      return domtoimage.toPng(element, options).then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "cracha.png";
        link.target = "_blank";
        link.href = dataUrl;
        link.click();
      });
    }
  };

  React.useEffect(() => {

    if (!firstLoading) setUserName(props.user.name);
    setLoadingEnd(true);
    //setBackgroundCracha(props.chosenConfig.url[0]);
    //console.log(props.chosenConfig.url[0])
    getBase64ByURL(props.chosenConfig.url[0]).then((result) => {
      setBackgroundCracha(`data:image/png;base64,${result}`);
    });
    // getBase64ByURL(props.user.image).then((result) => {
    //   setProfileCracha(`data:image/png;base64,${result}`);
    // });
    inputName.current.focus();
  }, [editName, userName]);

  return (
    <React.Fragment>
      <div
        style={{
          ...styles.ContainerConfig,
          paddingBottom: isMobile ? 150 : 0
        }}
      >
        <div
          id="DOM_CURRENT"
          ref={pdfRef}
          style={{
            backgroundImage: `url(${backgroundCracha})`,
            backgroundSize: "100%",
            height: "100%",
            marginBottom: "-130%",
          }}
        >
          {/* <div style={styles.containerImageFloated}>
            <img
              src={profileCracha}
              style={props.config.find(item => {
                return (item.codeScreen == "CRACHA-ENABLE-AVATAR");
              }) ? { width: 114, ...styles.floatedImg } : { width: 0, ...styles.floatedImg }}
            />
          </div> */}
          <div style={{ position: "relative" }}>
            <h1
              style={{
                display: !editName ? "block" : "none",
                ...styles.nameLabel,
              }}
            >
              {userName}
            </h1>
          </div>
          <div
            style={{ position: "relative", top: "77%", textAlign: "center" }}
          >
            <input
              ref={inputName}
              type="text"
              placeholder="Digite o seu nome"
              style={{
                textAlign: "center",
                border: 0,
                fontSize: 20,
                display: editName ? "initial" : "none",
                marginBottom: 22,
              }}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              onKeyDown={_handleKeyDown}
            />
          </div>
        </div>
        {/* <div style={{
          position: "relative",
          top: "350px",
          textAlign: "center",
          marginTop: 10
        }}>
          <span style={{
            color: "#fd0000",
            fontSize: 14
          }}>Para editar a foto do crachá <a style={{color:'red'}}href={`${props.event.id}/profile`}>Clique Aqui</a></span>
        </div>         */}
        {!iOS() &&
          <Button
            variant="contained"
            style={styles.btnLabel}
            size={"medium"}
            onClick={() => _downloadToPdf(pdfRef)}
          >
            <i className="fa fa-file-pdf-o" style={{ marginRight: 5 }}></i> PDF
        </Button>
        }
        <Button
          variant="contained"
          style={{ ...styles.btnLabel, marginLeft: 5 }}
          size={"medium"}
          onClick={() => _downloadToPng(pdfRef)}
        >
          <i className="fa fa-file-image-o" style={{ marginRight: 5 }}></i> {!iOS() ? "PNG" : "PNG"}
        </Button>
        <br />
        <Button
          variant="contained"
          style={styles.btnLabel}
          size={"medium"}
          onClick={() => {
            startEditName(!editName);
            userName == "" && setUserName(props.user.name);
          }}
        >
          <i
            className={!editName ? "fa fa-edit" : "fa fa-save"}
            style={{ marginRight: 5 }}
          ></i>{" "}
          {!editName ? "Alterar Nome" : "Salvar Edição"}
        </Button>
      </div>
      <div style={{
        position: "absolute"
      }}>
        <svg id="cracha_svg" xmlns="http://www.w3.org/2000/svg" />
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
    config: state.config
  };
}

export default connect(mapStateToProps, null)(UserCracha);
