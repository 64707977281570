import React, { useState, useRef, useMemo } from "react";
import { store } from '../../store';

import { connect } from "react-redux";
import { isMobile, isIOS } from "react-device-detect";

import { setChosenConfig, resetChosenConfig } from "../../store/actions";
import { sendAnswersApi } from "../../services/questionsService";
import { createConfigModel } from "../../models/configModel";
import { getAnswersQuantityByQuestionApi } from "../../services/questionsService";

import downloadIcon from "../../assets/images/transmission/download-icon.svg";
import questions from "../../assets/images/transmission/questions.svg";

import contractPt from "../../assets/images/transmission/contact/escrever-pt.svg";
import contractEn from "../../assets/images/transmission/contact/escrever-en.svg";
import contractSp from "../../assets/images/transmission/contact/escrever-sp.svg";
import contractHighlightPt from "../../assets/images/transmission/contact/escrever-highlight-pt.svg";
import contractHighlightEn from "../../assets/images/transmission/contact/escrever-highlight-en.svg";
import contractHighlightSp from "../../assets/images/transmission/contact/escrever-highlight-sp.svg";
import phoneHighlight from "../../assets/images/transmission/contact/fone-highlight.svg";

import { getDic } from "../../assets/i18n/dictionary";

import * as emojisList from '../../assets/images/emoji/index';

import { FormColumn, FormRow, ElementTreatment } from "..";
import { Watermark } from '@hirohe/react-watermark';

import HeaderBox from './HeaderBox';
import RightContainer from './RightContainer';
import VideoContainer from './VideoContainer';
import WrapperFooter from './WrapperFooter';

let timeout;
let timeoutMsgQuestion;
let timeoutMsgResearch;

const TransmissionShowBox = (props) => {

  const [chanelControl, setChanelControl] = useState({
    current: 1,
    chanelList: [1, 2, 3],
    urlLanguages: {
      onePt: props.channels["canal1-pt"]?.includes("http") ? props.channels["canal1-pt"] ?? "" : "",
      twoPt: props.channels["canal2-pt"]?.includes("http") ? props.channels["canal2-pt"] ?? "" : "",
      threePt: props.channels["canal3-pt"]?.includes("http") ? props.channels["canal3-pt"] ?? "" : "",
      oneEn: props.channels["canal1-en"]?.includes("http") ? props.channels["canal1-en"] ?? "" : "",
      twoEn: props.channels["canal2-en"]?.includes("http") ? props.channels["canal2-en"] ?? "" : "",
      threeEn: props.channels["canal3-en"]?.includes("http") ? props.channels["canal3-en"] ?? "" : "",
      oneEs: props.channels["canal1-es"]?.includes("http") ? props.channels["canal1-es"] ?? "" : "",
      twoEs: props.channels["canal2-es"]?.includes("http") ? props.channels["canal2-es"] ?? "" : "",
      threeEs: props.channels["canal3-es"]?.includes("http") ? props.channels["canal3-es"] ?? "" : "",
      oneRus: props.channels["canal1-rus"]?.includes("http") ? props.channels["canal1-rus"] ?? "" : "",
      twoRus: props.channels["canal2-rus"]?.includes("http") ? props.channels["canal2-rus"] ?? "" : "",
      threeRus: props.channels["canal3-rus"]?.includes("http") ? props.channels["canal3-rus"] ?? "" : "",
      oneFra: props.channels["canal1-fra"]?.includes("http") ? props.channels["canal1-fra"] ?? "" : "",
      twoFra: props.channels["canal2-fra"]?.includes("http") ? props.channels["canal2-fra"] ?? "" : "",
      threeFra: props.channels["canal3-fra"]?.includes("http") ? props.channels["canal3-fra"] ?? "" : "",
      oneGer: props.channels["canal1-ger"]?.includes("http") ? props.channels["canal1-ger"] ?? "" : "",
      twoGer: props.channels["canal2-ger"]?.includes("http") ? props.channels["canal2-ger"] ?? "" : "",
      threeGer: props.channels["canal3-ger"]?.includes("http") ? props.channels["canal3-ger"] ?? "" : "",
      oneIta: props.channels["canal1-ita"]?.includes("http") ? props.channels["canal1-ita"] ?? "" : "",
      twoIta: props.channels["canal2-ita"]?.includes("http") ? props.channels["canal2-ita"] ?? "" : "",
      threeIta: props.channels["canal3-ita"]?.includes("http") ? props.channels["canal3-ita"] ?? "" : "",
      oneAt: props.channels["canal1-at"]?.includes("http") ? props.channels["canal1-at"] ?? "" : "",
      twoAt: props.channels["canal2-at"]?.includes("http") ? props.channels["canal2-at"] ?? "" : "",
      threeAt: props.channels["canal3-at"]?.includes("http") ? props.channels["canal3-at"] ?? "" : "",
      oneAo: props.channels["canal1-ao"]?.includes("http") ? props.channels["canal1-ao"] ?? "" : "",
      twoAo: props.channels["canal2-ao"]?.includes("http") ? props.channels["canal2-ao"] ?? "" : "",
      threeAo: props.channels["canal3-ao"]?.includes("http") ? props.channels["canal3-ao"] ?? "" : "",
    },
    buttonText: {
      one: props.channels["text-canal1"]?.length > 0 ? props.channels["text-canal1"] ?? "" : "",
      two: props.channels["text-canal2"]?.length > 0 ? props.channels["text-canal2"] ?? "" : "",
      three: props.channels["text-canal3"]?.length > 0 ? props.channels["text-canal3"] ?? "" : ""
    },
    buttonColor: props.channels["button-channel-color"]?.length > 0 ? props.channels["button-channel-color"] ?? "" : "",
  });
  const [iframeControl, setIframeControl] = useState({
    current: 'pt',
    list: ['pt', 'en', 'sp', 'rus', 'fra', 'ger', 'ita', 'at', 'ao']
  });

  const [msgPergunta, setMsgPergunta] = useState("");
  const [msgResearch, setMsgResearch] = useState("");
  const [msgQuestion, setMsgQuestion] = useState("");
  const [wordsArray, setWordsArray] = useState([]);
  const textAreaRef = useRef(null);
  const [isVisibleChat, setIsVisibleChat] = useState((props?.isVisibleSchedule && props?.scheduleOrderFirst) ? false : props?.chatBox);
  const [isVisibleProgramacao, setIsVisibleProgramacao] = useState((props?.isVisibleSchedule && props?.scheduleOrderFirst) ? true : (props?.isVisibleSchedule && !isVisibleChat) ? true : false);
  const [isVisibleLiveCommerce, setIsVisibleLiveCommerce] = useState(false);

  const [isVisiblePo, setIsVisiblePo] = useState(getIsVisible("Po"));
  const [isVisibleEn, setIsVisibleEn] = useState(getIsVisible("En"));
  const [isVisibleEs, setIsVisibleEs] = useState(getIsVisible("Es"));
  const [isVisibleRus, setIsVisibleRus] = useState(getIsVisible("Rus"));
  const [isVisibleFra, setIsVisibleFra] = useState(getIsVisible("Fra"));
  const [isVisibleGer, setIsVisibleGer] = useState(getIsVisible("Ger"));
  const [isVisibleIta, setIsVisibleIta] = useState(getIsVisible("Ita"));
  const [isVisibleAt, setIsVisibleAt] = useState(getIsVisible("At"));
  const [isVisibleAo, setIsVisibleAo] = useState(getIsVisible("Ao"));
  const [isVisibleChannelOne,] = useState(getIsVisibleChannelOne());
  const [isVisibleChannelTwo,] = useState(getIsVisibleChannelTwo());
  const [isVisibleChannelThree,] = useState(getIsVisibleChannelThree());

  const [isVisibleEmoji, setIsVisibleEmoji] = useState(false);
  const [emojiValue, setEmojiValue] = useState("");
  const chosenConfig = createConfigModel(props.configChat);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [timeWordCloud, setTimeWordCloud] = useState(new Date());
  const [questionBox,] = useState(props?.questionBox);
  const [contractImgOn, setContractImgOn] = useState(true)
  const [phoneImgOn, setPhoneImgOn] = useState(false)
  const [operatorImgOn, setOperatorImgOn] = useState(false)
  const [whatsappImgOn, setWhatsappImgOn] = useState(false)
  const [zoomUrl,] = useState(props?.zoomUrl);
  const [zoomNumber,] = useState(props?.zoomNumber);
  const [zoomPassword,] = useState(props?.zoomPassword);
  const [whatsappUrl,] = useState(props?.whatsappUrl);
  const [zoomPhoneNumber,] = useState(props?.zoomPhoneNumber);
  const [zoomPhoneUrl,] = useState(props?.zoomPhoneUrl);
  const [whatsappPhoneNumber,] = useState(props?.whatsappPhoneNumber);
  const [schedule, setSchedule] = useState(props?.schedules);

  const [schedulesHide,] = useState(props?.scheduleHide?.split(',').map(Number));

  const [chosenConfigElement, setChosenConfigElement] = React.useState(null);
  const [executeElement, setExecuteElement] = React.useState(false);
  const [emojis, setEmojis] = React.useState({ list: emojisList.default, count: [] });
  const [makeYourQuestionText, setMakeYourQuestionText] = React.useState({
    pt: `${getDic("faca-aqui").toUpperCase()} ${getDic("suas-perguntas").toUpperCase()}`,
    en: `${getDic("faca-aqui").toUpperCase()} ${getDic("suas-perguntas").toUpperCase()}`,
    sp: `${getDic("faca-aqui").toUpperCase()} ${getDic("suas-perguntas").toUpperCase()}`,
  });
  const [backgroundColor, setBackgroundColor] = React.useState('white');
  const [watermarkContainerControl, setWatermarkContainerControl] = React.useState({
    open: false,
  });

  React.useEffect(() => {
    getBackgroundColor();
    getWordsList();
    setInitChannelConfig();
    getEmojisList();
    getMakeYourQuestionText();
    getWatermarkContainer();
    return () => { };
  }, []);

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  React.useEffect(() => {
    let timerUpdateWordCloud = setTimeout(() => {
      if (props.wordCloud) {
        getWordsList();
      }
      setTimeWordCloud(new Date());
    }, 120000);
    const poweredBy = document.getElementById("poweredBy");
    if (poweredBy) poweredBy.style.display = 'none';
    return () => {
      const poweredBy = document.getElementById("poweredBy");
      if (poweredBy) poweredBy.style.display = 'block';
      clearTimeout(timerUpdateWordCloud);
    };
  }, [timeWordCloud]);

  function getIsVisibleChannelOne() {
    return (
      chanelControl.urlLanguages.onePt !== "" ||
      chanelControl.urlLanguages.oneEn !== "" ||
      chanelControl.urlLanguages.oneEs !== "" !== "" ||
      chanelControl.urlLanguages.oneAo !== "" ||
      chanelControl.urlLanguages.oneRus !== "" ||
      chanelControl.urlLanguages.oneFra !== "" ||
      chanelControl.urlLanguages.oneGer !== "" ||
      chanelControl.urlLanguages.oneIta !== "" ||
      chanelControl.urlLanguages.oneAt !== ""
    )
  }

  function getIsVisibleChannelTwo() {
    return (
      chanelControl.urlLanguages.twoPt !== "" ||
      chanelControl.urlLanguages.twoEn !== "" ||
      chanelControl.urlLanguages.twoEs !== "" ||
      chanelControl.urlLanguages.twoAo !== "" ||
      chanelControl.urlLanguages.twoRus !== "" ||
      chanelControl.urlLanguages.twoFra !== "" ||
      chanelControl.urlLanguages.twoGer !== "" ||
      chanelControl.urlLanguages.twoIta !== "" ||
      chanelControl.urlLanguages.twoAt !== ""
    )
  }

  function getIsVisibleChannelThree() {
    return (
      chanelControl.urlLanguages.threePt !== "" ||
      chanelControl.urlLanguages.threeEn !== "" ||
      chanelControl.urlLanguages.threeEs !== "" ||
      chanelControl.urlLanguages.threeAo !== "" ||
      chanelControl.urlLanguages.threeRus !== "" ||
      chanelControl.urlLanguages.threeFra !== "" ||
      chanelControl.urlLanguages.threeGer !== "" ||
      chanelControl.urlLanguages.threeIta !== "" ||
      chanelControl.urlLanguages.threeAt !== ""
    )
  }
  function getIsVisible(lang) {
    if (lang === "Po") {
      return (chanelControl.urlLanguages.onePt !== "" || chanelControl.urlLanguages.twoPt !== "" || chanelControl.urlLanguages.threePt !== "")
    }
    if (lang === "En") {
      return (chanelControl.urlLanguages.oneEn !== "" || chanelControl.urlLanguages.twoEn !== "" || chanelControl.urlLanguages.threeEn !== "")
    }
    if (lang === "Es") {
      return (chanelControl.urlLanguages.oneEs !== "" || chanelControl.urlLanguages.oneEs !== "" || chanelControl.urlLanguages.oneEs !== "")
    }
    if (lang === "Rus") {
      return (chanelControl.urlLanguages.oneRus !== "" || chanelControl.urlLanguages.oneRus !== "" || chanelControl.urlLanguages.oneRus !== "")
    }
    if (lang === "Fra") {
      return (chanelControl.urlLanguages.oneFra !== "" || chanelControl.urlLanguages.twoFra !== "" || chanelControl.urlLanguages.threeFra !== "")
    }
    if (lang === "Ger") {
      return (chanelControl.urlLanguages.oneGer !== "" || chanelControl.urlLanguages.twoGer !== "" || chanelControl.urlLanguages.twoGer !== "")
    }
    if (lang === "Ita") {
      return (chanelControl.urlLanguages.oneIta !== "" || chanelControl.urlLanguages.twoIta !== "" || chanelControl.urlLanguages.threeIta !== "")
    }
    if (lang === "At") {
      return (chanelControl.urlLanguages.oneAt !== "" || chanelControl.urlLanguages.twoAt !== "" || chanelControl.urlLanguages.threeAt !== "")
    }
    if (lang === "Ao") {
      return (chanelControl.urlLanguages.oneAo !== "" || chanelControl.urlLanguages.twoAo !== "" || chanelControl.urlLanguages.threeAo !== "")
    }

  }

  function setInitChannelConfig() {
    if (isVisibleChannelOne) {
      setChanelControl({ ...chanelControl, current: 1 });
      setVisibleIdioms(1);
    }
    if (isVisibleChannelTwo && !isVisibleChannelOne) {
      setChanelControl({ ...chanelControl, current: 2 });
      setVisibleIdioms(2);
    }

    if (isVisibleChannelTwo && !isVisibleChannelOne && !isVisibleChannelTwo) {
      setChanelControl({ ...chanelControl, current: 3 });
      setVisibleIdioms(3);
    }
    setIframeVisible();
  }

  function getBackgroundColor() {

    const _config = props.configList.find(c => { return c.codeScreen === 'BACKGROUND-COLOR' });

    if (_config && _config.urlString) {
      setBackgroundColor(_config.urlString);
    }
  }

  function getWatermarkContainer() {
    let _watermarkBackground = props.configList.find(c => { return c.codeScreen === 'WATERMARK-BACKGROUND' });
    if (_watermarkBackground) {
      setWatermarkContainerControl({ ...watermarkContainerControl, open: true });
    }
  }

  async function getEmojisList() {
    const _config = props.configList.find(c => { return c.codeScreen === 'ELEMENT-010' });
    const _count = [];
    let _emojis = emojisList.default;
    for (let index = 1; index < 23; index++) {
      if (_config && _config.url && _config.url.length > 0) {
        let _key = `em${index < 10 ? '0' : ''}${index}`;
        _config.url.forEach(u => {
          const _command = u.split(/:(.+)/);
          if (_command[0] === _key) {
            _emojis = {
              ..._emojis,
              [_key]: _command[1]
            }
          }
        });
      }
      _count.push(index);
    }
    setEmojis({ list: _emojis, count: _count });
  }

  function getMakeYourQuestionText() {
    try {
      const _config = props.configList.find(c => { return c.codeScreen === 'ELEMENT-003' });
      let _languages = makeYourQuestionText;
      if (_config && _config.urlString) {
        const _url = _config.urlString.split(';');
        _url.forEach(item => {
          let _item = item.trim().split(/:(.+)/);
          if (_item[0] && (_item[0].trim() === 'text-pt' || _item[0].trim() === 'text-en' || _item[0].trim() === 'text-sp')) {
            let _prop = _item[0].split('-');
            _languages[_prop[1]] = _item[1].trim();
          }
        });
      }
      setMakeYourQuestionText(_languages);
    } catch (error) {
      console.log('Erro getMakeYourQuestionText', error);
    }
  }

  async function setVisibleIdioms(channelChoice) {
    if (channelChoice === 1) {
      chanelControl.urlLanguages.onePt !== "" ? setIsVisiblePo(true) : setIsVisiblePo(false);
      chanelControl.urlLanguages.oneEn !== "" ? setIsVisibleEn(true) : setIsVisibleEn(false);
      chanelControl.urlLanguages.oneEs !== "" ? setIsVisibleEs(true) : setIsVisibleEs(false);
      chanelControl.urlLanguages.oneRus !== "" ? setIsVisibleRus(true) : setIsVisibleRus(false);
      chanelControl.urlLanguages.oneFra !== "" ? setIsVisibleFra(true) : setIsVisibleFra(false);
      chanelControl.urlLanguages.oneGer !== "" ? setIsVisibleGer(true) : setIsVisibleGer(false);
      chanelControl.urlLanguages.oneIta !== "" ? setIsVisibleIta(true) : setIsVisibleIta(false);
      chanelControl.urlLanguages.oneAt !== "" ? setIsVisibleAt(true) : setIsVisibleAt(false);
      chanelControl.urlLanguages.oneAo !== "" ? setIsVisibleAo(true) : setIsVisibleAo(false);
    }
    if (channelChoice === 2) {
      chanelControl.urlLanguages.twoPt !== "" ? setIsVisiblePo(true) : setIsVisiblePo(false);
      chanelControl.urlLanguages.twoEn !== "" ? setIsVisibleEn(true) : setIsVisibleEn(false);
      chanelControl.urlLanguages.twoEs !== "" ? setIsVisibleEs(true) : setIsVisibleEs(false);
      chanelControl.urlLanguages.twoRus !== "" ? setIsVisibleRus(true) : setIsVisibleRus(false);
      chanelControl.urlLanguages.twoFra !== "" ? setIsVisibleFra(true) : setIsVisibleFra(false);
      chanelControl.urlLanguages.twoGer !== "" ? setIsVisibleGer(true) : setIsVisibleGer(false);
      chanelControl.urlLanguages.twoIta !== "" ? setIsVisibleIta(true) : setIsVisibleIta(false);
      chanelControl.urlLanguages.twoAt !== "" ? setIsVisibleAt(true) : setIsVisibleAt(false);
      chanelControl.urlLanguages.twoAo !== "" ? setIsVisibleAo(true) : setIsVisibleAo(false);
    }
    if (channelChoice === 3) {
      chanelControl.urlLanguages.threePt !== "" ? setIsVisiblePo(true) : setIsVisiblePo(false);
      chanelControl.urlLanguages.threeEn !== "" ? setIsVisibleEn(true) : setIsVisibleEn(false);
      chanelControl.urlLanguages.threeEs !== "" ? setIsVisibleEs(true) : setIsVisibleEs(false);
      chanelControl.urlLanguages.threeRus !== "" ? setIsVisibleRus(true) : setIsVisibleRus(false);
      chanelControl.urlLanguages.threeFra !== "" ? setIsVisibleFra(true) : setIsVisibleFra(false);
      chanelControl.urlLanguages.threeGer !== "" ? setIsVisibleGer(true) : setIsVisibleGer(false);
      chanelControl.urlLanguages.threeIta !== "" ? setIsVisibleIta(true) : setIsVisibleIta(false);
      chanelControl.urlLanguages.threeAt !== "" ? setIsVisibleAt(true) : setIsVisibleAt(false);
      chanelControl.urlLanguages.threeAo !== "" ? setIsVisibleAo(true) : setIsVisibleAo(false);
    }
  }

  function setIframeVisible(channelChoice = 1) {
    if (channelChoice == 1) {
      if (chanelControl.urlLanguages.onePt !== "") {
        setIframeControl({ ...iframeControl, current: 'pt' });
        return;
      }
      if (chanelControl.urlLanguages.oneEn !== "") {
        setIframeControl({ ...iframeControl, current: 'en' });
        return;
      }
      if (chanelControl.urlLanguages.oneEs !== "") {
        setIframeControl({ ...iframeControl, current: 'sp' });
        return;
      }
      if (chanelControl.urlLanguages.oneRus !== "") {
        setIframeControl({ ...iframeControl, current: 'rus' });
        return;
      }
      if (chanelControl.urlLanguages.oneFra !== "") {
        setIframeControl({ ...iframeControl, current: 'fra' });
        return;
      }
      if (chanelControl.urlLanguages.oneGer !== "") {
        setIframeControl({ ...iframeControl, current: 'ger' });
        return;
      } if (chanelControl.urlLanguages.oneIta !== "") {
        setIframeControl({ ...iframeControl, current: 'ita' });
        return;
      } if (chanelControl.urlLanguages.oneAt !== "") {
        setIframeControl({ ...iframeControl, current: 'at' });
        return;
      }
      if (chanelControl.urlLanguages.oneAo !== "") {
        setIframeControl({ ...iframeControl, current: 'ao' });
        return;
      }
    }

    if (channelChoice == 2) {
      if (chanelControl.urlLanguages.twoPt !== "") {
        setIframeControl({ ...iframeControl, current: 'pt' });
        return;
      }
      if (chanelControl.urlLanguages.twoEn !== "") {
        setIframeControl({ ...iframeControl, current: 'en' });
        return;
      }
      if (chanelControl.urlLanguages.twoEs !== "") {
        setIframeControl({ ...iframeControl, current: 'sp' });
        return;
      }
      if (chanelControl.urlLanguages.twoRus !== "") {
        setIframeControl({ ...iframeControl, current: 'rus' });
        return;
      }
      if (chanelControl.urlLanguages.twoFra !== "") {
        setIframeControl({ ...iframeControl, current: 'fra' });
        return;
      }
      if (chanelControl.urlLanguages.twoGer !== "") {
        setIframeControl({ ...iframeControl, current: 'ger' });
        return;
      } if (chanelControl.urlLanguages.twoIta !== "") {
        setIframeControl({ ...iframeControl, current: 'ita' });
        return;
      } if (chanelControl.urlLanguages.twoAt !== "") {
        setIframeControl({ ...iframeControl, current: 'at' });
        return;
      }
      if (chanelControl.urlLanguages.twoAo !== "") {
        setIframeControl({ ...iframeControl, current: 'ao' });
        return;
      }
    }

    if (channelChoice == 3) {
      if (chanelControl.urlLanguages.threePt !== "") {
        setIframeControl({ ...iframeControl, current: 'pt' });
        return;
      }
      if (chanelControl.urlLanguages.threeEn !== "") {
        setIframeControl({ ...iframeControl, current: 'en' });
        return;
      }
      if (chanelControl.urlLanguages.threeEs !== "") {
        setIframeControl({ ...iframeControl, current: 'sp' });
        return;
      }
      if (chanelControl.urlLanguages.threeRus !== "") {
        setIframeControl({ ...iframeControl, current: 'rus' });
        return;
      }
      if (chanelControl.urlLanguages.threeFra !== "") {
        setIframeControl({ ...iframeControl, current: 'fra' });
        return;
      }
      if (chanelControl.urlLanguages.threeGer !== "") {
        setIframeControl({ ...iframeControl, current: 'ger' });
        return;
      } if (chanelControl.urlLanguages.threeGer !== "") {
        setIframeControl({ ...iframeControl, current: 'ita' });
        return;
      } if (chanelControl.urlLanguages.threeGer !== "") {
        setIframeControl({ ...iframeControl, current: 'at' });
        return;
      }
      if (chanelControl.urlLanguages.threeAo !== "") {
        setIframeControl({ ...iframeControl, current: 'ao' });
        return;
      }
    }
  }

  function sendAnswers(item, tipo) {
    if (
      item[0].attributeId != "" &&
      item[0].attributeId !== undefined &&
      item[0].value !== undefined &&
      item[0].value !== ""
    ) {
      sendAnswersApi(item)
        .then((res) => {
          if (tipo == "research") {
            clearTimeout(timeoutMsgResearch);
            setMsgResearch("Avaliação enviada.");
            timeoutMsgResearch = setTimeout(() => {
              setMsgResearch("");
            }, 4000);
          }
          if (tipo == "question") {
            clearTimeout(timeoutMsgQuestion);
            setMsgQuestion("Pergunta enviada.");
            timeoutMsgQuestion = setTimeout(() => {
              setMsgQuestion("");
            }, 4000);
          }
        })
        .catch((err) => {
          console.log("Erro sendAnswersApi", err);
        });
    }
  }

  function getWordsList() {
    if (props.idPerguntaWordCloud) {
      getAnswersQuantityByQuestionApi(parseInt(props.idPerguntaWordCloud)).then(
        (res) => {
          var _total = 0;
          res.forEach((item) => {
            _total += item.quantity;
          });

          const _cloudData = [];
          res.forEach((item) => {
            var _ret = (item.quantity * 100) / _total;
            const _objNuvem = {
              text: item.answer,
              value: Math.round(_ret.toFixed(2)),
            };
            _cloudData.push(_objNuvem);
          });

          const newData = _cloudData.map((item) => ({
            text: item.text.split(" ")[0],
            value: (item.value + 1) * 4,
          }));

          function compared(a, b) {
            if (a.value < b.value) return 1;
            if (a.value > b.value) return -1;
            return 0;
          }
          const _newDataOrder = newData.sort(compared);
          const _finalData = [];
          _newDataOrder.forEach((data, index) => {
            if (index <= 79) {
              _finalData.push(data.text.substring(0, 15));
            }
          });
          setWordsArray(_finalData);
        }
      );
    }
  }

  function setEmojiConfig(value) {
    clearTimeout(timeout);
    setEmojiValue(value);
    setIsVisibleEmoji(true);
    timeout = setTimeout(() => {
      setIsVisibleEmoji(false);
    }, 5000);
  }
  function handleChange(event) {
    setMsgPergunta(event.target.value);
  }

  function downloadFile() {
    if (props.urlDownloadFile !== undefined && props.urlDownloadFile !== "") {
      window.location.href = props.urlDownloadFile;
    }
  }
  function roomPhoneZoom() {
    if (zoomPhoneUrl !== undefined && zoomPhoneUrl !== "") {
      window.open(zoomPhoneUrl, '_blank');
    }
  }

  function roomZoom() {
    if (zoomUrl !== undefined && zoomUrl !== "") {
      window.open(zoomUrl, '_blank');
    }
  }

  function roomWhatsapp() {
    if (whatsappUrl !== undefined && whatsappUrl !== "") {
      window.open(whatsappUrl, '_blank');
    }
  }

  function setItemContatAtive(itemContact) {
    setContractImgOn(false)
    setPhoneImgOn(false)
    setOperatorImgOn(false)
    setWhatsappImgOn(false)

    if (itemContact === 'contract') {
      setContractImgOn(true)
    }
    if (itemContact === 'phone') {
      setPhoneImgOn(true)
    }
    if (itemContact === 'operator') {
      setOperatorImgOn(true)
    }
    if (itemContact === 'whatsapp') {
      setWhatsappImgOn(true)
    }
  }

  function getContractHighlight() {
    const _lang = store.getState().lang;

    switch (_lang.lang) {
      case "en":
        return contractHighlightEn
        break;
      case "sp":
        return contractHighlightSp
        break;
      default:
        return contractHighlightPt;
        break;
    }
  }

  function getContract() {
    const _lang = store.getState().lang;

    switch (_lang.lang) {
      case "en":
        return contractEn;
        break;
      case "sp":
        return contractSp;
        break;
      default:
        return contractPt;
        break;
    }
  }

  function executeConfig(lcObject) {
    const _config = props.config.find(item => {
      return item.id === parseInt(lcObject.configId);
    });

    if (_config) {
      setChosenConfigElement(_config);
      setExecuteElement(true);
    }
  }

  function getChatScheduleBoxHeight() {
    return props.logoRight === true
      ? props.wordCloud === true
        ? !props.isModal
          ? "49vh"
          : props.height === "80vh"
            ? "40vh"
            : "48vh"
        : props.height === "80vh"
          ? "58vh"
          : isMobile
            ? "63vh"
            : "70vh"
      : props.wordCloud === true
        ? props.height === "80vh"
          ? "62vh"
          : "78vh"
        : props.height === "80vh"
          ? "72vh"
          : isMobile
            ? "78vh"
            : "88vh"
  }

  return (
    <>
      {watermarkContainerControl.open ? renderWaterMarkContainer() : renderMainContainer()}

      {executeElement && (
        <ElementTreatment
          history={props.history}
          chosenConfig={chosenConfigElement}
          onClose={() => {
            setExecuteElement(false);
            setChosenConfig(null);
          }}
        />
      )}
    </>
  );

  function renderMainContainer() {
    // if (isIOS) {
    //   return renderIosMainContainer();
    // }
    return (
      <div
        id={`pageId=${props.currentPage}`}
        className="main-container ios-modal-fix"
        style={{
          width: props.isModal ? props.width : "100vw",
          height: props.isModal ? props.height : "100vh",
          backgroundColor: backgroundColor
        }}
      >
        <div id='isTransmissionPage' className="left-container">
          {props.headerBox && (
            <HeaderBox
              isVisibleChannelOne={isVisibleChannelOne}
              isVisibleChannelTwo={isVisibleChannelTwo}
              isVisibleChannelThree={isVisibleChannelThree}
              chanelControl={chanelControl}
              setChanelControl={setChanelControl}
              setVisibleIdioms={setVisibleIdioms}
              iframeControl={iframeControl}
              setIframeControl={setIframeControl}
              setIframeVisible={setIframeVisible}
              isVisiblePo={isVisiblePo}
              isVisibleEn={isVisibleEn}
              isVisibleEs={isVisibleEs}
              isVisibleRus={isVisibleRus}
              isVisibleFra={isVisibleFra}
              isVisibleGer={isVisibleGer}
              isVisibleIta={isVisibleIta}
              isVisibleAt={isVisibleAt}
              isVisibleAo={isVisibleAo}
              translationButtonsText={props.translationButtonsText}
              windowWidth={windowWidth}
            />
          )}
          <VideoContainer
            event={props.event}
            emojis={emojis}
            emojiValue={emojiValue}
            isVisibleEmoji={isVisibleEmoji}
            iframeControl={iframeControl}
            chanelControl={chanelControl}
            isVisiblePo={isVisiblePo}
            isVisibleEn={isVisibleEn}
            isVisibleEs={isVisibleEs}
            isVisibleRus={isVisibleRus}
            isVisibleFra={isVisibleFra}
            isVisibleGer={isVisibleGer}
            isVisibleIta={isVisibleIta}
            isVisibleAt={isVisibleAt}
            isVisibleAo={isVisibleAo}
            windowWidth={windowWidth}
          />
          <WrapperFooter
            emojis={emojis}
            emojiBox={props.emojiBox}
            downloadBox={props.downloadBox}
            downloadInstructionsText={props.downloadInstructionsText}
            downloadIcon={downloadIcon}
            downloadButtonColor={props.downloadButtonColor}
            researchBox={props.researchBox}
            idResearch={props.idResearch}
            downloadFile={downloadFile}
            sendAnswers={sendAnswers}
            msgResearch={msgResearch}
            questionBox={questionBox}
            propsQuestionBox={props.questionBox}
            questions={questions}
            lang={props.lang}
            makeYourQuestionText={makeYourQuestionText}
            contractImgOn={contractImgOn}
            textAreaRef={textAreaRef}
            handleChange={handleChange}
            phoneImgOn={phoneImgOn}
            phoneHighlight={phoneHighlight}
            roomPhoneZoom={roomPhoneZoom}
            zoomPhoneNumber={zoomPhoneNumber}
            operatorImgOn={operatorImgOn}
            roomZoom={roomZoom}
            zoomNumber={zoomNumber}
            zoomPassword={zoomPassword}
            whatsappImgOn={whatsappImgOn}
            roomWhatsapp={roomWhatsapp}
            whatsappPhoneNumber={whatsappPhoneNumber}
            zoomUrl={zoomUrl}
            zoomPhoneUrl={zoomPhoneUrl}
            whatsappUrl={whatsappUrl}
            getContractHighlight={getContractHighlight}
            getContract={getContract}
            setItemContatAtive={setItemContatAtive}
            msgQuestion={msgQuestion}
            idPergunta={props.idPergunta}
            msgPergunta={msgPergunta}
            idEmoji={props.idEmoji}
            setEmojiConfig={setEmojiConfig}
            buttonSendColor={props.buttonSendColor}
            windowWidth={windowWidth}
          />
        </div>
        {props.sideRight && (
          <RightContainer
            {...props}
            setIsVisibleChat={setIsVisibleChat}
            isVisibleProgramacao={isVisibleProgramacao}
            setIsVisibleProgramacao={setIsVisibleProgramacao}
            setIsVisibleLiveCommerce={setIsVisibleLiveCommerce}
            windowWidth={windowWidth}
            getChatScheduleBoxHeight={getChatScheduleBoxHeight}
            schedule={schedule}
            schedulesHide={schedulesHide}
            isVisibleChat={isVisibleChat}
            chosenConfig={chosenConfig}
            isVisibleLiveCommerce={isVisibleLiveCommerce}
            executeConfig={executeConfig}
            wordsArray={wordsArray}
            timeWordCloud={timeWordCloud}
          />
        )}
      </div >
    );
  }

  function renderIosMainContainer() {
    return (
      <FormColumn margin='0px'>
        {props.headerBox && (
          <HeaderBox
            isVisibleChannelOne={isVisibleChannelOne}
            isVisibleChannelTwo={isVisibleChannelTwo}
            isVisibleChannelThree={isVisibleChannelThree}
            chanelControl={chanelControl}
            setChanelControl={setChanelControl}
            setVisibleIdioms={setVisibleIdioms}
            iframeControl={iframeControl}
            setIframeControl={setIframeControl}
            setIframeVisible={setIframeVisible}
            isVisiblePo={isVisiblePo}
            isVisibleEn={isVisibleEn}
            isVisibleEs={isVisibleEs}
            isVisibleRus={isVisibleRus}
            isVisibleFra={isVisibleFra}
            isVisibleGer={isVisibleGer}
            isVisibleIta={isVisibleIta}
            isVisibleAt={isVisibleAt}
            isVisibleAo={isVisibleAo}
          />
        )}
        <VideoContainer
          event={props.event}
          emojis={emojis}
          emojiValue={emojiValue}
          isVisibleEmoji={isVisibleEmoji}
          iframeControl={iframeControl}
          chanelControl={chanelControl}
          isVisiblePo={isVisiblePo}
          isVisibleEn={isVisibleEn}
          isVisibleEs={isVisibleEs}
          isVisibleRus={isVisibleRus}
          isVisibleFra={isVisibleFra}
          isVisibleGer={isVisibleGer}
          isVisibleIta={isVisibleIta}
          isVisibleAt={isVisibleAt}
          isVisibleAo={isVisibleAo}
          windowWidth={windowWidth}
        />
        <WrapperFooter
          emojis={emojis}
          emojiBox={props.emojiBox}
          downloadBox={props.downloadBox}
          downloadInstructionsText={props.downloadInstructionsText}
          downloadIcon={downloadIcon}
          downloadButtonColor={props.downloadButtonColor}
          researchBox={props.researchBox}
          idResearch={props.idResearch}
          downloadFile={downloadFile}
          sendAnswers={sendAnswers}
          msgResearch={msgResearch}
          questionBox={questionBox}
          propsQuestionBox={props.questionBox}
          questions={questions}
          lang={props.lang}
          makeYourQuestionText={makeYourQuestionText}
          contractImgOn={contractImgOn}
          textAreaRef={textAreaRef}
          handleChange={handleChange}
          phoneImgOn={phoneImgOn}
          phoneHighlight={phoneHighlight}
          roomPhoneZoom={roomPhoneZoom}
          zoomPhoneNumber={zoomPhoneNumber}
          operatorImgOn={operatorImgOn}
          roomZoom={roomZoom}
          zoomNumber={zoomNumber}
          zoomPassword={zoomPassword}
          whatsappImgOn={whatsappImgOn}
          roomWhatsapp={roomWhatsapp}
          whatsappPhoneNumber={whatsappPhoneNumber}
          zoomUrl={zoomUrl}
          zoomPhoneUrl={zoomPhoneUrl}
          whatsappUrl={whatsappUrl}
          getContractHighlight={getContractHighlight}
          getContract={getContract}
          setItemContatAtive={setItemContatAtive}
          msgQuestion={msgQuestion}
          idPergunta={props.idPergunta}
          msgPergunta={msgPergunta}
          idEmoji={props.idEmoji}
          setEmojiConfig={setEmojiConfig}
        />
        {
          props.sideRight && (
            <RightContainer
              {...props}
              setIsVisibleChat={setIsVisibleChat}
              isVisibleProgramacao={isVisibleProgramacao}
              setIsVisibleProgramacao={setIsVisibleProgramacao}
              setIsVisibleLiveCommerce={setIsVisibleLiveCommerce}
              windowWidth={windowWidth}
              getChatScheduleBoxHeight={getChatScheduleBoxHeight}
              schedule={schedule}
              schedulesHide={schedulesHide}
              isVisibleChat={isVisibleChat}
              chosenConfig={chosenConfig}
              isVisibleLiveCommerce={isVisibleLiveCommerce}
              executeConfig={executeConfig}
              wordsArray={wordsArray}
              timeWordCloud={timeWordCloud}
            />
          )
        }
      </FormColumn>
    )
  }

  function renderWaterMarkContainer() {
    return (
      <Watermark text={props.user.email} textSize={15} rotate={45}>
        {renderMainContainer()}
      </Watermark>
    )
  }
};

function mapStateToProps(state) {
  const message = state.config.find((item) => {
    return item.codeScreen === "PASSWORD-MESSAGE";
  });

  return {
    schedules: state.schedules,
    user: state.user,
    config: state.config,
    stands: state.stands,
    event: state.event,
    lang: state.lang,
    passwordMessage: message ? message.tooltip : "senha-digite",
  };
}

const mapDispatchToProps = {
  setChosenConfig,
  resetChosenConfig,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransmissionShowBox);
