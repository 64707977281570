import React from "react";
import LoginPageProvider from "./LoginPageProvider";
import LoginPageVisual from "./LoginPageVisual";

const LoginPage = (props) => {

    return (
        <LoginPageProvider history={props.history} params={props.match.params}>
            <LoginPageVisual />
        </LoginPageProvider>
    );
}

export default LoginPage;
