import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const ScrollGroups = props => {
  const documentView = window.screen.height - 223;
  const statusBar = window.screen.height - window.innerHeight;
  return (
    <PerfectScrollbar style={{ height: documentView - statusBar }}>
      <li>
        <a href="/chat">
          <div className="align-items-center media">
            <div className="avatar-xs me-3">
              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                G
              </span>
            </div>
            <div className="media-body">
              <h5 className="font-size-14 mb-0">General</h5>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a href="/chat">
          <div className="align-items-center media">
            <div className="avatar-xs me-3">
              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                R
              </span>
            </div>
            <div className="media-body">
              <h5 className="font-size-14 mb-0">Reporting</h5>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a href="/chat">
          <div className="align-items-center media">
            <div className="avatar-xs me-3">
              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                M
              </span>
            </div>
            <div className="media-body">
              <h5 className="font-size-14 mb-0">Meeting</h5>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a href="/chat">
          <div className="align-items-center media">
            <div className="avatar-xs me-3">
              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                A
              </span>
            </div>
            <div className="media-body">
              <h5 className="font-size-14 mb-0">Project A</h5>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a href="/chat">
          <div className="align-items-center media">
            <div className="avatar-xs me-3">
              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                B
              </span>
            </div>
            <div className="media-body">
              <h5 className="font-size-14 mb-0">Project B</h5>
            </div>
          </div>
        </a>
      </li>
    </PerfectScrollbar>
  );
};

export default ScrollGroups;
