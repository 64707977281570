import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import { getBorderObject } from './ImporterUtils';
import moment from 'moment';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelCell = ReactExport.ExcelFile.ExcelCell;
const border = getBorderObject()

const RaffleWinnersListExcel = (props) => {
  const {
    data,
    button,
    event
  } = props;

  const [isTableComplete, setIsTableComplete] = React.useState(false);
  const [winnersTable, setWinnersTable] = React.useState([]);

  //Método de retorno para sinalizar que a planilha foi renderizada
  const onDownload = () => {
    if (props.onDownload) {
      setTimeout(() => {
        props.onDownload();
      }, 1000);
    }
  }

  React.useEffect(() => {
    setTableData();

    return () => { }
  }, []);

  const createTableWinners = () => {
    try {
      var _multiDataSet = getTableTitle();
      _multiDataSet[0].data = getTableRows();

      setWinnersTable(_multiDataSet);
    } catch (error) {
      console.log('Error createTableWinners', error);
      throw error;
    }

    function getTableTitle() {
      return [
        {
          columns: [
            {
              title: `ID ${getDic("sorteio")}`,
              style: {
                font: { bold: true },
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "9e9e9e" }
                },
                border: border
              }
            },
            {
              title: `${getDic("sorteio")}`,
              style: {
                font: { bold: true },
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "9e9e9e" }
                },
                border: border
              }
            },
            {
              title: `${getDic("data")} ${getDic("sorteio")}`,
              style: {
                font: { bold: true },
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "9e9e9e" }
                },
                border: border
              }
            },
            {
              title: `ID ${getDic("vencedor")}`,
              style: {
                font: { bold: true },
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "9e9e9e" }
                },
                border: border
              }
            },
            {
              title: `${getDic("nome")} ${getDic("vencedor")}`,
              style: {
                font: { bold: true },
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "9e9e9e" }
                },
                border: border
              }
            },
            {
              title: `${getDic("email")} ${getDic("vencedor")}`,
              style: {
                font: { bold: true },
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "9e9e9e" }
                },
                border: border
              }
            },
            {
              title: `${getDic("grupo")} ${getDic("vencedor")}`,
              style: {
                font: { bold: true },
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "9e9e9e" }
                },
                border: border
              }
            },
          ],
          data: []
        },
      ]
    }

    function getTableRows() {
      try {
        let _rows = [];

        data.forEach(item => {
          let _date = '';
          if (item.raffleRealizedDate) {
            _date = getDate(item.raffleRealizedDate);
          }

          const _row = [
            {
              value: `${item.raffleId}`,
              style: {
                border: border
              }
            },
            {
              value: `${item.raffleTitle}`,
              style: {
                border: border
              }
            },
            {
              value: _date,
              style: {
                border: border
              }
            },
            {
              value: item.id,
              style: {
                border: border
              }
            },
            {
              value: item.name,
              style: {
                border: border
              }
            },
            {
              value: item.email,
              style: {
                border: border
              }
            },
            {
              value: item.group,
              style: {
                border: border
              }
            },
          ];

          _rows.push(_row);
        });

        return _rows;
      } catch (error) {
        console.log('Error getTableRows', error);
        throw error;
      }
    }

    function getDate(date) {
      const _milliseconds = date.seconds * 1000 // 1575909015000

      const _dateObject = new Date(_milliseconds);

      let _return = moment(_dateObject).format('DD/MM/YYYY, HH:mm:ss');

      return _return;
    }
  }

  function setTableData() {
    createTableWinners();

    setIsTableComplete(true);

    onDownload();
  }

  if (data && data.length > 0) {
    return (
      <>
        {isTableComplete && (
          <ExcelFile filename={`${getDic("vencedores")} - ID ${event.id}`} element={button}>
            <ExcelSheet dataSet={winnersTable} name={getDic("vencedores")} />
          </ExcelFile>
        )}
      </>
    );
  }
  return (
    <ExcelFile element={button} />
  );
}

export default RaffleWinnersListExcel;
