import axios from "axios";
import { getUrl, getToken } from "./apiService";
import { store } from "../store";
import { createChatModel } from "../models/chatModel";

export function getChatSize() {
  return 30;
}

export async function getMessagesApi(eventId, scheduleId, configId, pageIndex) {

  var method = getUrl().transmission + "chat/" + eventId.toString() + "?";
  const token = await getToken();

  if (scheduleId && scheduleId !== 0) {
    method = method + "&scheduleId=" + scheduleId.toString();
  } else if (configId && configId !== 0) {
    method = method + "&configId=" + configId.toString();
  }
  method +=
    "&pageIndex=" +
    pageIndex +
    "&pageSize=" +
    getChatSize() +
    "&active=true&approved=true";

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token
      }
    };
    axios(options)
      .then(res => {
        const messages = [];
        if (res.data) {
          res.data.messages.forEach(item => {
            //Trava caso apareçam mensagens de outro chat
            var isCorrect = true;
            if (
              item.scheduleId &&
              item.scheduleId !== 0 &&
              scheduleId &&
              scheduleId !== 0 &&
              item.scheduleId !== scheduleId
            ) {
              isCorrect = false;
            }
            if (
              item.configId &&
              item.configId !== 0 &&
              configId &&
              configId !== 0 &&
              item.configId !== configId
            ) {
              isCorrect = false;
            }

            if (item.active && item.approved && isCorrect) {
              var message = createChatModel(
                item.id,
                item.message,
                item.dateCreated,
                item.guestId,
                item.guestName, //name
                item.photoUrl,
                true,
                true,
                item.scheduleId,
                item.configId,
                null
              );
              messages.push(message);
            }
          });
        }

        //Ordenar mensagens por data
        const ret = messages.sort(function (a, b) {
          return b.insertDate < a.insertDate;
        });

        resolve({ messages: ret, totalPages: res.data.totalPages });
      })
      .catch(err => {
        console.log("Erro getMessagesApi", err);
        reject(err);
      });
  });
}

export async function getParticularMessagesApi(senderId, recipientId) {

  const token = await getToken();
  const event = store.getState().event;

  var method = getUrl().hall + `event/chatguest/list?eventId=${event.id}&sender=${senderId}&recipient=${recipientId}`;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token
      }
    };
    axios(options)
      .then(res => {
        const messages = [];
        if (res.data && res.data.length > 0) {
          res.data.forEach(item => {

            if (item.active) {
              var message = createChatModel(
                item.id,
                item.message,
                item.insertDate,
                item.senderGuest.id,
                item.senderGuest.name, //name
                item.senderGuest.avatarProfileLink,
                true,
                true,
                null,
                null,
                null,
              );
              messages.push(message);
            }
          });
        }

        //Ordenar mensagens por data
        const ret = messages.sort(function (a, b) {
          return b.insertDate < a.insertDate;
        });

        resolve({ messages: ret });
      })
      .catch(err => {
        console.log("Erro getParticularMessagesApi", err);
        reject(err);
      });
  });
}

export async function getDeletedMessagesApi(
  eventId,
  scheduleId,
  configId,
  pageIndex
) {
  var method =
    getUrl().hall +
    "event/chat/" +
    eventId.toString() +
    "?pageIndex=" +
    pageIndex +
    "&pageSize=100&approved=false";
  const token = await getToken();

  if (!pageIndex) return;

  if (scheduleId && scheduleId !== 0) {
    method = method + "&scheduleId=" + scheduleId.toString();
  } else if (configId && configId !== 0) {
    method = method + "&configId=" + configId.toString();
  }

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token
      }
    };
    axios(options)
      .then(res => {
        console.log("getDeletedMessagesApi Ok");
        const messages = [];

        if (res.data.data && res.data.success) {
          res.data.data.chat.forEach(item => {
            var message = createChatModel(
              item.id,
              item.message,
              item.insertDate,
              item.guestId,
              item.guestName, //name
              item.photoUrl,
              item.active,
              item.approved,
              item.scheduleId,
              item.configId,
              null,
            );
            messages.push(message);
          });
        }

        //Ordenar mensagens por data
        const ret = messages.sort(function (a, b) {
          return b.insertDate < a.insertDate;
        });

        resolve({ messages: ret, totalPages: res.data.data.totalPages });
      })
      .catch(err => {
        console.log("Erro getMessagesModeratorApi", err);
        reject(err);
      });
  });
}

export async function getMessagesModeratorApi(
  eventId,
  pageIndex,
  guestName,
  messageText,
  searchOption,
  approved
) {
  // searchOption = {
  //     value: item.id, Configuração ou stant
  //     text: item.tooltip,
  //     isSchedule: false, Se for stand, este atributo será true
  // }
  var method = getUrl().transmission + "chat/" + eventId.toString() + "?pageIndex=" + pageIndex + "&pageSize=" + getChatSize();
  if (messageText && messageText !== "") {
    method += "&textOnMessage=" + messageText;
  }
  if (guestName && guestName !== "") {
    method += "&guestName=" + guestName;
  }
  if (searchOption && searchOption.value !== 0) {
    if (searchOption.isSchedule) {
      method += "&scheduleId=" + searchOption.value;
    } else {
      method += "&configId=" + searchOption.value;
    }
  }
  if (approved && approved !== "all") {
    if (approved === "approved") {
      method += "&approved=true";
    } else {
      method += "&approved=false";
    }
  }

  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token
      }
    };
    axios(options)
      .then(res => {
        const messages = [];
        if (res.data.messages) {
          res.data.messages.forEach(item => {
            var message = createChatModel(
              item.id,
              item.message,
              item.dateCreated,
              item.guestId,
              item.guestName, //name
              item.photoUrl,
              item.active,
              item.approved,
              item.scheduleId,
              item.configId,
              null,
            );
            messages.push(message);
          });
        }

        //Ordenar mensagens por data
        const ret = messages.sort(function (a, b) {
          return b.insertDate < a.insertDate;
        });

        resolve({ messages: ret, totalPages: res.data.totalPages });
      })
      .catch(err => {
        console.log("Erro getMessagesModeratorApi", err);
        reject(err);
      });
  });
}

export async function setApprovedMessageApi(message) {
  var method = getUrl().transmission + "chat";
  const token = await getToken();

  const data = {
    Id: message.id.toString(),
    Aproved: message.approved
  };

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token
      },
      data: data
    };
    axios(options)
      .then(res => {
        if (res.data.success) {
          resolve({ success: true });
        } else {
          reject({ success: false, data: res.data });
        }
      })
      .catch(err => {
        console.log("Erro setApprovedMessage", err);
        reject({ success: false, data: err });
      });
  });
}


export async function addNewMessageApi(message, scheduleId, configId) {

  const token = await getToken();
  const event = store.getState().event;
  const user = store.getState().user;

  var method = getUrl().transmission + "chat";
  var data = {
    eventId: parseInt(event.id),
    message: message,
    guestId: parseInt(user.id),
    photoUrl: user.image,
    scheduleId: scheduleId && scheduleId !== 0 ? scheduleId : null,
    configId: scheduleId && scheduleId !== 0 ? null : configId,
    guestName: user.name
  }

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token
      },
      data: data
    };

    axios(options)
      .then(res => {
        if (res.data.success) {
          const _message = createChatModel(
            res.data.data.id,
            res.data.data.message,
            res.data.data.dateCreated,
            res.data.data.guestId,
            user.name, //name
            res.data.data.photoUrl,
            res.data.data.active,
            res.data.data.approved,
            res.data.data.scheduleId,
            res.data.data.configId,
            null,
          );
          resolve(_message);
        } else {
          reject(res.data);
        }
      })
      .catch(err => {
        console.log("Erro addNewMessageApi", err);
        reject(err);
      });
  });
}

//Converte o timestamp para um formato humano
export const convertToTime = UNIX_timestamp => {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate() > 9 ? a.getDate() : "0" + a.getDate();
  var hour = a.getHours() > 9 ? a.getHours() : "0" + a.getHours();
  var min = a.getMinutes() > 9 ? a.getMinutes() : "0" + a.getMinutes();
  var sec = a.getSeconds() > 9 ? a.getSeconds() : "0" + a.getSeconds();
  var time =
    date + "  " + month + "  " + year + " - " + hour + ":" + min + ":" + sec;
  return time;
};
