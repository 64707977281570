import React from 'react';
import { getDic } from "../../assets/i18n/dictionary";
import LiveCommerceIcon from '@material-ui/icons/ShoppingCart';
import { Chat, FormRow } from "..";
import { isMobile, isIOS } from "react-device-detect";
import logotipo from "../../assets/images/transmission/logotipo.png";
import chat from "../../assets/images/transmission/chat.svg";
import calendar from "../../assets/images/transmission/calendar.png";
import cloud from "../../assets/images/transmission/cloud.svg";
import imageBackgroundRight from "../../assets/images/transmission/Bg-lateral.jpg";
import image2BackgroundRight from "../../assets/images/transmission/Bg-lateral2.jpg";

import CardProgramacao from './CardProgramacao';
import WordCloudC from './WordCloudC';

const RightContainer = props => {
  const {
    setIsVisibleChat,
    isVisibleProgramacao,
    setIsVisibleProgramacao,
    setIsVisibleLiveCommerce,
    windowWidth,
    getChatScheduleBoxHeight,
    schedule,
    schedulesHide,
    isVisibleChat,
    chosenConfig,
    isVisibleLiveCommerce,
    executeConfig,
    wordsArray,
    timeWordCloud,
  } = props;

  // if (isIOS) {
  //   return renderIos();
  // }
  return (
    // <div className="right-container">
    <div
      style={rightContainerStyle(
        windowWidth,
        props.sideRightImg01,
        props.sideRightImg02,
        props.chatBox,
      )}
    >
      {props.logoRight && (
        <div className="logo-right">
          <img
            src={
              props.sideRightLogo !== "" &&
                props.sideRightLogo !== undefined
                ? props.sideRightLogo
                : logotipo
            }
          />
        </div>
      )}
      {renderRightContainerHeaderButtons()}
      {renderSchedulesContainer()}
      {renderChatContainer()}
      {renderLiveCommerceContainer()}
      {renderWordCloud()}
    </div>
  )

  function renderIos() {
    return (
      <FormRow margin='0px' minHeigth='600px'>
        <FormRow margin='0px'>
          {renderRightContainerHeaderButtons()}
        </FormRow>
        <FormRow margin='0px'>
          {renderSchedulesContainer()}
          {renderChatContainer()}
          {renderLiveCommerceContainer()}
          {renderWordCloud()}
        </FormRow>
      </FormRow>
    )
  }

  function renderRightContainerHeaderButtons() {
    return (
      <>
        {(props?.scheduleOrderFirst && props?.isVisibleSchedule) ?
          <div className="header-chat-programacao">
            {props?.isVisibleSchedule && <img src={calendar} onClick={() => {
              setIsVisibleChat(false)
              setIsVisibleProgramacao(true)
              setIsVisibleLiveCommerce(false)
            }} />}
            {props?.isVisibleSchedule && <article onClick={() => {
              setIsVisibleChat(false)
              setIsVisibleProgramacao(true)
              setIsVisibleLiveCommerce(false)
            }}>{getDic("programacao").toUpperCase()}</article>}

            {props?.chatBox && <img src={chat} onClick={() => {
              setIsVisibleProgramacao(false)
              setIsVisibleChat(true)
              setIsVisibleLiveCommerce(false)
            }} />}
            {props?.chatBox && <article onClick={() => {
              setIsVisibleProgramacao(false)
              setIsVisibleChat(true)
              setIsVisibleLiveCommerce(false)
            }
            }>CHAT</article>}

            {props?.liveCommerceList && props?.liveCommerceList.length > 0 && <LiveCommerceIcon fontSize='small' style={{ color: 'white' }} onClick={() => {
              setIsVisibleProgramacao(false)
              setIsVisibleChat(false)
              setIsVisibleLiveCommerce(true)
            }} />}
            {props?.liveCommerceList && props?.liveCommerceList.length > 0 && <article onClick={() => {
              setIsVisibleProgramacao(false)
              setIsVisibleChat(false)
              setIsVisibleLiveCommerce(true)
            }
            }>LIVE COMMERCE</article>}
          </div>
          :
          <div className="header-chat-programacao">
            {props?.chatBox && <img src={chat} onClick={() => {
              setIsVisibleProgramacao(false)
              setIsVisibleChat(true)
              setIsVisibleLiveCommerce(false)
            }} />}
            {props?.chatBox && <article onClick={() => {
              setIsVisibleProgramacao(false)
              setIsVisibleChat(true)
              setIsVisibleLiveCommerce(false)
            }
            }>CHAT</article>}
            {props?.isVisibleSchedule && <img src={calendar} onClick={() => {
              setIsVisibleChat(false)
              setIsVisibleProgramacao(true)
              setIsVisibleLiveCommerce(false)
            }} />}
            {props?.isVisibleSchedule && <article onClick={() => {
              setIsVisibleChat(false)
              setIsVisibleProgramacao(true)
              setIsVisibleLiveCommerce(false)
            }}>{getDic("programacao").toUpperCase()}</article>}
            {props?.liveCommerceList && props?.liveCommerceList.length > 0 && <LiveCommerceIcon fontSize='small' style={{ color: 'white' }} onClick={() => {
              setIsVisibleProgramacao(false)
              setIsVisibleChat(false)
              setIsVisibleLiveCommerce(true)
            }} />}
            {props?.liveCommerceList && props?.liveCommerceList.length > 0 && <article onClick={() => {
              setIsVisibleProgramacao(false)
              setIsVisibleChat(false)
              setIsVisibleLiveCommerce(true)
            }
            }>LIVE COMMERCE</article>}
          </div>
        }</>
    )
  }

  function renderSchedulesContainer() {
    return (
      <>
        {isVisibleProgramacao && props?.isVisibleSchedule && <div className="chat-container" >
          <div className="programacao-body" style={isMobile ? { height: 460 } : { height: getChatScheduleBoxHeight(), backgroundColor: 'transparent' }}>
            {schedule.filter(item => !schedulesHide.includes(item.id)).map(e =>
              <CardProgramacao inicio={e.startTime} fim={e.endTime} descricao={e.title} id={e.id} color={props?.scheduleColor ? props?.scheduleColor : "#9ac134"} />
            )}
          </div>
        </div>}
        {isVisibleProgramacao && props?.isVisibleSchedule && <div className="chat-container" >
          <div className="programacao-body" style={isMobile ? { height: 460 } : { height: getChatScheduleBoxHeight(), backgroundColor: 'transparent' }}>
            {schedule.filter(item => !schedulesHide.includes(item.id)).map(e =>
              <CardProgramacao inicio={e.startTime} fim={e.endTime} descricao={e.title} id={e.id} color={props?.scheduleColor ? props?.scheduleColor : "#9ac134"} />
            )}
          </div>
        </div>}
      </>
    )
  }

  function renderChatContainer() {
    // if (isIOS) {
    //   return (
    //     <>
    //       {props.chatBox && isVisibleChat && (
    //         <div
    //           className="programacao-body"
    //           style={isMobile ? { height: 460, minHeight: 400 } : {}}
    //         >
    //           {props.configChat > 0 && (
    //             <Chat
    //               chosenConfig={{ ...chosenConfig }}
    //               height={getChatScheduleBoxHeight()}
    //             />
    //           )}
    //         </div>
    //       )}
    //     </>
    //   )
    // }
    return (
      <>
        {props.chatBox && isVisibleChat && (
          <div className="chat-container">
            <div
              className="chat-body"
              style={isMobile ? { height: 460, minHeight: 400 } : {}}
            >
              {props.configChat > 0 && (
                <Chat
                  chosenConfig={{ ...chosenConfig }}
                  height={getChatScheduleBoxHeight()}
                />
              )}
            </div>
          </div>
        )}
      </>
    )
  }

  function renderLiveCommerceContainer() {
    return (
      <>
        {isVisibleLiveCommerce && <div className="chat-container" >
          <div className="programacao-body" style={isMobile ? { height: 460 } : { height: getChatScheduleBoxHeight(), backgroundColor: 'transparent' }}>
            {/* {props.liveCommerceList.map(e => { renderCardLiveCommerce(e) })} */}
            {props.liveCommerceList.map(e => {
              return (
                <div
                  key={e.configId}
                  style={{
                    width: "100%",
                    height: "100px",
                    padding: 5,
                    cursor: "pointer",
                    borderRadius: 5
                  }}
                  onClick={() => executeConfig(e)}
                >
                  <img
                    style={{
                      height: '100px',
                      width: '100%',
                      verticalAlign: 'top',
                      borderRadius: 5
                    }}
                    src={e.image}
                    alt=""
                  />
                </div>
              )
            })}
          </div>
        </div>}
      </>
    )
  }

  function renderWordCloud() {
    return (
      <>
        {props.wordCloud && (
          <div className="word-clouds">
            <div className="word-clouds-title" style={props.isVisibleWordCloudTitle ? { visibility: 'visible' } : { visibility: 'hidden' }} >
              <img src={cloud} alt="cloud" />
              <article>{getDic("nuvem-de-palavras").toUpperCase()}</article>
            </div>
            <div className="word-clouds-body">
              {WordCloudC(wordsArray, timeWordCloud, windowWidth)}
            </div>
          </div>
        )}
      </>
    )
  }

  function rightContainerStyle(windowWidth, img01, img02, chatBox) {
    return {
      display: "flex",
      flexDirection: "column",
      width: windowWidth > 925 ? (isMobile && !chatBox) ? "39%" : "31%" : "100%",
      height: "auto",
      backgroundImage: windowWidth > 925
        ? `url(${img01 === "#" || img01 === "" ? imageBackgroundRight : img01})`
        : `url(${img02 === "#" || img02 === "" ? image2BackgroundRight : img02})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: windowWidth < 926 ? '0%' : '5%',
      paddingBottom: 15
    };
  };
};

export default RightContainer;
