import React from 'react';
import { getDic } from "../../assets/i18n/dictionary";
import {
  FormRow,
  Button,
  Modal,
  Container,
  FormColumn,
  TextBig,
  InputFieldOutlined,
  SelectList
} from "../../components";

const ModalRaffleRegister = props => {
  const {
    modalRaffleRegisterControl,
    setModalRaffleRegisterControl,
    saveRaffle
  } = props;

  const onChangeHandler = (field, value) => {
    let _raffle = modalRaffleRegisterControl.raffle;
    _raffle[field] = value;
    setModalRaffleRegisterControl({ ...modalRaffleRegisterControl, raffle: _raffle });
  }

  return (
    <Modal
      isVideo
      height="100%"
      open={modalRaffleRegisterControl.open}
      closeButtonClick={(e) => setModalRaffleRegisterControl({ ...modalRaffleRegisterControl, open: false })}
      onClose={(ret) => setModalRaffleRegisterControl({ ...modalRaffleRegisterControl, open: false })}
    >
      <Container background="#e8eced" align="left" height="100%">
        <FormColumn margin="0px" padding="5px">
          <FormRow align="center" margin="0px">
            <TextBig>{modalRaffleRegisterControl.raffle.id ? getDic("editar") : getDic("novo")} {getDic("sorteio")}</TextBig>
          </FormRow>
          <FormRow align="center" margin="0px" paddingTop="12px">
            <InputFieldOutlined
              value={modalRaffleRegisterControl.raffle.title}
              title={getDic("titulo")}
              onChange={(e) => onChangeHandler('title', e.target.value)}
            />
          </FormRow>
          <FormRow align="center" margin="0px" paddingTop="12px">
            <InputFieldOutlined
              type="number"
              value={modalRaffleRegisterControl.raffle.numberOfWinners}
              title={`${getDic("numero")} ${getDic("de")} ${getDic("vencedores")}`}
              onChange={(e) => onChangeHandler('numberOfWinners', e.target.value)}
            />
          </FormRow>
          <FormRow align="center" margin="0px" paddingTop="12px">
            <SelectList
              width="100%"
              title={`${getDic("apenas")} ${getDic("usuarios")} Online`}
              value={modalRaffleRegisterControl.raffle.onlyOnline}
              onChange={(e) => onChangeHandler('onlyOnline', e.target.value)}
              menuItens={[
                { value: false, text: getDic("nao") },
                { value: true, text: getDic("sim") },
              ]}
            />
          </FormRow>
          <FormRow align="center" margin="0px" paddingTop="12px">
            <SelectList
              width="100%"
              title={`${getDic("apenas")} ${getDic("nao")} ${getDic("sorteados")}`}
              value={modalRaffleRegisterControl.raffle.onlyNotAwarded}
              onChange={(e) => onChangeHandler('onlyNotAwarded', e.target.value)}
              menuItens={[
                { value: false, text: getDic("nao") },
                { value: true, text: getDic("sim") },
              ]}
            />
          </FormRow>
          {modalRaffleRegisterControl.groups.length > 0 && (
            <FormRow align="center" margin="0px" paddingTop="12px">
              <SelectList
                width="100%"
                title={`${getDic("grupo")}`}
                value={modalRaffleRegisterControl.raffle.groupId}
                onChange={(e) => onChangeHandler('groupId', e.target.value)}
                menuItens={modalRaffleRegisterControl.groups}
                multiple
              />
            </FormRow>
          )}
          <FormRow align="center" margin="0px" paddingTop="12px">
            <Button onClick={() => saveRaffle()}>
              {getDic("salvar")}
            </Button>
          </FormRow>
        </FormColumn>
      </Container>
    </Modal>
  )
};

export default ModalRaffleRegister;
