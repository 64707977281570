export function createThemeModel(
    contentColorBackground = "#212121",
    backgroundImage = "",
) {
    const object = {
        contentColorBackground: contentColorBackground,
        backgroundImage: backgroundImage,
    }

    return object;
}
