import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';

const SimpleSnackbar = (props) => {

    const {
        children,
        open,
        onClose,
        horizontal = 'center',
        vertical = 'top',
        backgroundColor = '#424242',
        textColor = 'white',
        closeButton = false,
        action = null,
        autoHideDuration = null,
        onClick,
        cursor = null
    } = props;

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: vertical,
                    horizontal: horizontal,
                }}
                open={open}
                onClose={onClose}
                autoHideDuration={autoHideDuration}
                onClick={onClick}
                style={{
                    cursor: cursor
                }}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: backgroundColor,
                        color: textColor,
                        borderRadius: 90
                    }}
                    message={
                        <React.Fragment>
                            <div style={{ alignItems: "center", width: "100%", height: "100%" }}>
                                {children}
                            </div>
                        </React.Fragment>
                    }
                    action={action ? action : closeButton ? (
                        <Button color="secondary" size="small" onClick={() => onClose({ close: true })}>
                            X
                        </Button>
                    ) : null}
                />
            </Snackbar>
        </div>
    );
}

export default SimpleSnackbar;
