import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = props => {
    const {
        onChange,
    } = props;

    return (
        <ReCAPTCHA
            sitekey="6Lcj77QUAAAAALq8HRPFs-tEagWfhWqg7AfYJ8JP"
            onChange={onChange}
        />
    )
};

export default Recaptcha;
