import React, { useContext } from "react";
import TestPageContext from './TestPageContext';

const TestPageVisual = (props) => {
    // Busca o state e os métodos e instancia em uma variável context
    const { state, functions } = useContext(TestPageContext);

    React.useEffect(() => {

        return () => {
        }
    }, []);

    return (
        <div style={{ backgroundColor: 'white', height: '100vh' }}>
            Teste
            <button onClick={() => functions.testFunction()}>Altera via function</button>
        </div>
    );
}

export default TestPageVisual;
