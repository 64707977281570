export function createGuestModel(
    id = 0,
    name = '',
    institution = '',
    position = '',
    image = '',
    email = '',
    groupId = 0,
) {
    const user = {
        id: id,
        name: name ? name : '',
        institution: institution ? institution : '',
        position: position ? position : '',
        image: image ? image : '',
        email,
        groupId,

        //Parametro para a página de controle
        selected: false,
    }

    return user;
}
