import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';

//Imports de components
import { Container, FormRow, TextBig, ActivityIndicator } from '../components';

class NotFoundPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }
    }

    async componentDidMount() {
        this.setState({ isLoading: false });
        await insertNewAccess(this.props.match.url, "", getSystemActions().accessPage);
    }

    async componentWillUnmount() {
        await insertNewAccess(this.props.match.url, "", getSystemActions().exitPage);
        // window.location.reload();
    }

    componentDidCatch(e) {
        var error = e.toString();

        if (error.includes("Error: Maximum update depth exceeded")) {
            window.location.reload();
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Container background="#e8eced" align="center">
                    <FormRow align="center">
                        <ActivityIndicator />
                    </FormRow>
                </Container>
            );
        }
        return (
            <Container background="#e8eced" align="center">
                <FormRow align="center">
                    <TextBig>
                        {"Ops! " + getDic("pagina-nao-encontrada")}
                    </TextBig>
                </FormRow>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage)
