import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { ThemeProvider } from '@material-ui/core/styles';
import * as theme from '../utils/themeGenerator';

const AppBarProp = props => {
    const { children, color = "#212121" } = props;

    const styles = {
        bar: {
            height: '7vh',
            minHeight: '50px'
        },
        toolBar: {
            width: '97%'
        },
        line: {
            height: '7vh',
            minHeight: '50px'
        }
    };

    const colorProp = theme.createPrimaryTheme(color);

    return (
        <ThemeProvider theme={colorProp}>
            <AppBar
                position="fixed"
                style={styles.bar}
            >
                <Toolbar style={styles.toolBar}>
                    {children}
                </Toolbar>

            </AppBar>
            <div style={styles.bar}></div>
        </ThemeProvider>
    )
};

export default AppBarProp;
