import React from 'react';

const FormColumn = props => {
    const {
        children,
        id = "",
        align = null,
        width = null,
        background = null,
        backgroundImage = null,
        margin = '5px',
        marginLeft = "0px",
        marginRight = "0px",
        minWidth = 'auto',
        padding = "0px",
        paddingTop = padding && padding !== "0px" ? padding : '0px',
        paddingBottom = padding && padding !== "0px" ? padding : '0px',
        paddingLeft = padding && padding !== "0px" ? padding : '0px',
        paddingRight = padding && padding !== "0px" ? padding : '0px',
        height = "auto",
        minHeight = "auto",
        overflow = "auto",
        alignItems = null,
        backgroundSize = null,
    } = props;

    const styles = {
        container: {
            backgroundColor: background ? background : 'transparent',
            backgroundImage: `url(${backgroundImage})`,
            marginTop: margin,
            marginBottom: margin,
            marginLeft: marginLeft,
            marginRight: marginRight,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            flexGrow: 1,
            width: width ? width : '100%',
            minWidth: minWidth,
            justifyContent: align ? align : 'left',
            alignItems: alignItems ? alignItems : 'left',
            textAlign: align ? align : 'left',
            paddingTop: paddingTop,
            paddingBottom: paddingBottom,
            paddingLeft: paddingLeft,
            paddingRight: paddingRight,
            height: height,
            minHeight: minHeight,
            overflow: overflow,
            backgroundSize: backgroundSize ? backgroundSize : null,
        }
    };

    return (
        <div id={id} style={
            styles.container
        }>
            {children}
        </div>
    )
};

export default FormColumn;
