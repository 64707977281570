import React from "react";
import { getDic } from "../../assets/i18n/dictionary";
import AddToCalendar from "react-add-to-calendar";
import AppleIcon from '@material-ui/icons/Apple';
import DraftsIcon from '@material-ui/icons/Drafts';

//Imports de components
import {
  Container,
  SelectList,
  Button,
  FormRow,
  TextSmall
} from "../../components";

function createCalendarTypeList() {
  const _calendarList = [
    {
      value: "google",
      text: "Google"
    },
    {
      value: "outlook",
      text: "Outlook"
    },
    {
      value: "apple",
      text: "Apple"
    },
  ];
  return _calendarList;
}

const CalendarPage = (props) => {
  const [state, setState] = React.useState(true);
  const [calendarTypeList, setCalendarTypeList] = React.useState(createCalendarTypeList());
  const [calendarTypeListValue, setCalendarTypeListValue] = React.useState("google");
  const [configObject, setConfigObject] = React.useState({
    imageBackgroundUrl: null,
    colorBackground: "#6e54ff",
    colorButton: "#8bff26",
    colorTextButton: "#020063",
    textButton: getDic("adicionar"),
    height: "100vh",
    positionContent: "center",
  });

  const event = {
    title: "Evento Inteegra",
    description: "Acesse o link https://hall.inteegra.com.br/67710",
    location: "São Paulo, SP",
    startTime: "2021-03-10T10:00:00-03:00",
    endTime: "2021-03-10T16:00:00-03:00",
  }

  React.useEffect(() => {
    convertConfigParamToObject();
    return () => {
    };
  }, []);

  const convertConfigParamToObject = () => {
    if (props.match.params.config) {
      var _newConfig = configObject;
      var _dataArray = props.match.params.config.split(",");
      _dataArray.forEach(item => {
        var _data = item.split(":");
        _newConfig[_data[0]] = _data[1] ? _data[1].trim() : _newConfig[_data[0]];

        if (_data[0] === "colorBackground" || _data[0] === "colorButton" || _data[0] === "colorTextButton") {
          _newConfig[_data[0]] = _data[1] ? `#${_data[1].trim()}` : _newConfig[_data[0]];
        }
      });
      setConfigObject(_newConfig);
      setState(state === true ? false : true);
    }
  }

  const accessCalendar = (calendarType) => {
    var _link = document.getElementsByClassName("react-add-to-calendar__button");
    _link[0].click();

    setTimeout(() => {
      if (calendarType === "apple" || calendarType === "outlook") {
        var _appleLink = document.getElementsByClassName("apple-link");
        _appleLink[0].click();
      }
      else if (calendarType === "google") {
        var _appleLink = document.getElementsByClassName("google-link");
        _appleLink[0].click();
      }
    }, 200);
  }

  return (
    <div>
      <img src={configObject.imageBackgroundUrl} style={{ position: "fixed", width: "100%", height: "100%", zIndex: "-2" }} />
      <Container background={configObject.imageBackgroundUrl ? "transparent" : configObject.colorBackground} align="center" height={configObject.height}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: configObject.positionContent === "top" ? "10px"
              : configObject.positionContent === "center" ? "30vh"
                : configObject.positionContent === "bottom" ? "auto" : "10px",
            bottom: configObject.positionContent === "bottom" ? "0px" : "auto"
          }}
        >
          <FormRow align="center" margin="0px">
            <img src="https://www.inteegra.com.br/wp-content/uploads/2019/09/sobre-a-Inteegra-01-01.jpg" style={{ width: "600px", height: "250px" }} />
          </FormRow>
          <FormRow align="center" margin="0px" paddingTop="10px">
            <div style={{ padding: "5px", maxWidth: "150px" }}>
              <Button
                color={configObject.colorButton}
                textColor={configObject.colorTextButton}
                onClick={() => accessCalendar("apple")}
              >
                <AppleIcon />
                <TextSmall color="transparent">.</TextSmall>
                {" ICalendar"}
              </Button>
            </div>
            <div style={{ padding: "5px", maxWidth: "150px" }}>
              <Button
                color={configObject.colorButton}
                textColor={configObject.colorTextButton}
                onClick={() => accessCalendar("outlook")}
              >
                <i class="fa fa-windows" aria-hidden="true"></i><br />
                <TextSmall color="transparent">.</TextSmall>
                {" Outlook"}
              </Button>
            </div>
            <div style={{ padding: "5px", maxWidth: "150px" }}>
              <Button
                color={configObject.colorButton}
                textColor={configObject.colorTextButton}
                onClick={() => accessCalendar("google")}
              >
                <i class="fa fa-google" aria-hidden="true"></i><br />
                <TextSmall color="transparent">.</TextSmall>
                {" Google"}
              </Button>
            </div>
          </FormRow>
        </div>
        <div hidden>
          <AddToCalendar event={event} />
        </div>
      </Container>
    </div>
  );
}

export default CalendarPage;
