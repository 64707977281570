export function createWhatsappTemplateModel(
    text = '',
    value = '',
    nameSpace = '',
    message = '',
    numberOfTags = 0,
    hasUrl = false,
    urlType = '', // "DOCUMENT", "VIDEO", "IMAGE"
) {
    const _obj = {
        text: text,
        value: value,
        nameSpace: nameSpace,
        message: message,
        numberOfTags: numberOfTags,
        hasUrl: hasUrl,
        urlType: urlType,
    };

    return _obj;
}
