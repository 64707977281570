import React from "react";
import { connect } from "react-redux";
import { getDic } from "../../assets/i18n/dictionary";
import "../../scss/MenuButtonV2.scss";
import { syncData } from "../../services/syncDataService";
import ShowZendesk from "../../services/zendeskService";
import * as navigationHistoryService from "../../services/navigationHistoryService";
import {
  setLanguage,
  checkGlobalVlibras,
  setNotification,
} from "../../store/actions";
import { isIOS, isMacOs } from "react-device-detect";
import flagBrazil from "../../assets/icon/FlagBrasil.png";
import flagEngland from "../../assets/icon/FlagEngland.jpg";
import flagSpain from "../../assets/icon/FlagSpain.jpg";
import Fab from "@material-ui/core/Fab";
// import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import BusinessIcon from "@material-ui/icons/Business";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PeopleIcon from "@material-ui/icons/People";
import SyncIcon from "@material-ui/icons/Sync";
import Moderator from "@material-ui/icons/HowToReg";
import Reports from "@material-ui/icons/AssignmentInd";
import ReportsSurvey from "@material-ui/icons/PieChart";
import Upload from "@material-ui/icons/Publish";
import Files from "@material-ui/icons/AttachFile";
import Push from "@material-ui/icons/Announcement";
import Questions from "@material-ui/icons/QuestionAnswer";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import ArrowUp from "@material-ui/icons/ArrowDropUp";
import ChatIcon from "@material-ui/icons/Chat";
import AdminReportsIcon from "@material-ui/icons/FindInPage";
import ConfigIcon from "@material-ui/icons/Settings";
import GenericIcon from "@material-ui/icons/ChevronRight";
import QuestionsIcon from "@material-ui/icons/ContactSupport";
import GameIcon from "@material-ui/icons/SportsEsports";
import RankingIcon from "@material-ui/icons/Storage";
import SchedulesRegisterIcon from "@material-ui/icons/MenuBook";
import SpeakerRegisterIcon from "@material-ui/icons/SpeakerNotes";
import InteegraflixIcon from "@material-ui/icons/LocalMovies";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import CampaignIcon from "@material-ui/icons/MailOutline";
import SurveyIcon from "@material-ui/icons/QuestionAnswer";
import Backdrop from "@material-ui/core/Backdrop";
import ArrowMenuIcon from '@material-ui/icons/ArrowForwardIos';
import RaffleIcon from "@material-ui/icons/EmojiEvents";

import {
  Loading,
  Divider,
  Avatar,
  FormRow,
  TextMedium,
  TextSmall,
  CountdownToExecute,
  Dialog,
  PrivateChatControl,
  MobileOrientation,
  ElementTreatment,
  AudioPlayerComp,
  AlertNetwork,
} from "../../components";

const VLibrasListenner = (props) => {
  React.useEffect(() => {
    props.checkGlobalVlibras();
  });
  return <div />;
};

const Drawer = ({
  isOpen,
  onBackdropClick,
  backgroundColor,
  backgroundImage,
  textColor,
  children
}) => {
  if (isOpen) {
    return (
      <>
        <div
          style={{
            color: textColor,
            backgroundColor: backgroundColor,
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundSize: `300px ${window.innerHeight >= 500 ? '100%' : '500px'}`
          }}
          className={`Drawer__Container ${isOpen ? "Drawer__Container--isOpen" : ""}`}
        >
          {children}
        </div>
        <Backdrop
          open={isOpen}
          style={{ zIndex: 12, color: "#fff" }}
          onClick={onBackdropClick}
        />
      </>
    )
  } else {
    return (
      <div
        style={{
          color: textColor,
          backgroundColor: backgroundColor,
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: `300px ${window.innerHeight >= 500 ? '100%' : '500px'}`
        }}
        className={`Drawer__Container ${isOpen ? "Drawer__Container--isOpen" : ""}`}
      >
        {children}
      </div>
    )
  }
}

class AppBarMenuButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openMenu: false,
      loadingMessage: "",
      openLoading: false,
      adminGroup: false,
      reportsGroup: false,
      moderatorGroup: false,
      filesGroup: false,
      configGroup: false,
      dialogChatOpen: false,
      chosenConfig: null,
      executeElement: false,
      drawerConfig: {
        backgroundColor: '#e8eced',
        backgroundImage: null,
        textColor: '#4a4a4a'
      },
      menuButtonConfig: {
        backgroundColor: 'black',
        textColor: 'white'
      },
      buttonsConfig: null
    };
  }

  async imageCacheInit() {
    var configList = this.props.config;
    //Aqui, pega todas as configs que possua URL de imagem PNG ou JPG...
    let allImages = configList.filter(
      (e) =>
        e.url.toString().toUpperCase().indexOf("JPG") > -1 ||
        e.url.toString().toUpperCase().indexOf("JPEG") > -1 ||
        e.url.toString().toUpperCase().indexOf("PNG") > -1 ||
        e.url.toString().toUpperCase().indexOf("WEBP") > -1
    );
    let _img = [];
    allImages.forEach((element) => {
      if (element.url.length == 1) {
        _img.push(element.url[0]);
      } else if (element.url.length && element.url.length > 1) {
        element.url.forEach((e) => {
          if (e.indexOf("{") > -1) {
            let _url = e.split("{")[1].replace("}", "");
            _img.push(_url);
          } else if (
            e.toUpperCase().indexOf("JPEG") > -1 ||
            e.toUpperCase().indexOf("JPG") > -1 ||
            e.toUpperCase().indexOf("PNG") > -1 ||
            e.toUpperCase().indexOf("WEBP") > -1
          ) {
            _img.push(e);
          }
        });
      }
    });
    this.preloadImages(_img, () => {
      console.log("All images were loaded");
    });
  }
  preloadImages(urls, allImagesLoadedCallback) {
    var loadedCounter = 0;
    var toBeLoadedNumber = urls.length;
    urls.forEach(function (url) {
      preloadImage(url, function () {
        loadedCounter++;
        // console.log('Number of loaded images: ' + loadedCounter);
        if (loadedCounter == toBeLoadedNumber) {
          allImagesLoadedCallback();
        }
      });
    });
    function preloadImage(url, anImageLoadedCallback) {
      var img = new Image();
      img.onload = anImageLoadedCallback;
      img.src = url;
      var elem = document.createElement("img");
      elem.setAttribute("src", url);
      elem.setAttribute("height", "0");
      elem.setAttribute("width", "0");
      elem.setAttribute("alt", "Flower");
      elem.setAttribute("hidden", true);
      document.getElementById("cache_img").appendChild(elem);
    }
  }

  componentDidMount() {
    //Checa se o cache se encontra vazio
    if (document.getElementById("cache_img").childElementCount < 1) {
      this.imageCacheInit();
    }
    //showZendesk();
    this.props.checkGlobalVlibras();
    const poweredBy = document.getElementById("poweredBy");
    if (poweredBy) {
      const enablePowered = this.props.config.find((item) => {
        return item.codeScreen === "POWEREDBY-TEXT";
      });
      if (enablePowered) {
        const labelConfigs = enablePowered.url[0].split("style")[1];
        if (poweredBy) {
          if (labelConfigs.indexOf(",") > -1) {
            let configSet = labelConfigs
              .replace("{", "")
              .replace("}", "")
              .split(",");
            poweredBy.style.color = configSet[0].replace("fontColor:", "");
            poweredBy.style.fontSize =
              configSet[1].replace("fontSize:", "") + "px";
          } else {
            if (labelConfigs.indexOf("fontColor") > -1) {
              poweredBy.style.color = labelConfigs
                .replace("fontColor:", "")
                .replace("{", "")
                .replace("}", "");
            } else {
              poweredBy.style.fontSize =
                labelConfigs
                  .replace("fontSize:", "")
                  .replace("{", "")
                  .replace("}", "") + "px";
            }
          }
        }
      }
    }

    this.setSideMenuConfig();
  }

  setSideMenuConfig() {
    const _drawerConfig = this.props.config.find((item) => {
      return item.codeScreen === "SIDE-MENU-CONFIG";
    });
    if (_drawerConfig) {
      var _newDrawerConfig = this.state.drawerConfig;
      _drawerConfig.url.forEach(item => {
        var _prop = item.split(/:(.+)/);
        if (_newDrawerConfig.hasOwnProperty(_prop[0])) {
          _newDrawerConfig[_prop[0]] = _prop[1];
        }
      });

      if (_newDrawerConfig.backgroundImage !== null) {
        _newDrawerConfig.backgroundColor = 'transparent';
        var _image = new Image();
        _image.src = _newDrawerConfig.backgroundImage;
      }
      this.setState({ drawerConfig: _newDrawerConfig });
    }

    const _buttonsConfig = this.props.config.find((item) => {
      return item.codeScreen === "SIDE-MENU-CONFIG-BUTTONS";
    });
    if (_buttonsConfig) {
      const _configArray = _buttonsConfig.urlString.split(';');
      const _newButtonsConfig = [];
      _configArray.forEach(item => {
        var _config = {
          default: null,
          text: '',
          config: 0,
          schedule: 0,
        }

        var _itemArray = item.trim().slice(1).slice(0, -1).split(',');
        _itemArray.forEach(prop => {
          try {
            var _propArray = prop.split(/:(.+)/);
            if (_propArray[0].trim() === 'sync') {
              _config.text = getDic("sincronizar") + " " + getDic("dados");
              _config.default = 'sync';
            } else if (_propArray[0].trim() === 'language') {
              _config.default = 'language';
            } if (_propArray[0].trim() === 'profile') {
              _config.default = 'profile';
            } else {
              if (_propArray[0].trim() === 'text') {
                _config.text = _propArray[1].trim();
              } else if (_propArray[0].trim() === 'config') {
                _config.config = parseInt(_propArray[1].trim());
              } else if (_propArray[0].trim() === 'schedule') {
                _config.schedule = parseInt(_propArray[1].trim());
              }
            }
          } catch (error) {
            console.log('Erro ao aplicar configuração no menu lateral', error);
          }
        });

        var _include = true;
        if (_config.config > 0 || _config.schedule > 0) {
          const _conf = this.props.config.find((item) => {
            return item.id === _config.config || (item.scheduleId === _config.schedule && item.codeScreen.includes('-ELEMENT'));
          });
          if (_conf && _conf.groupId && _conf.groupId.length > 0) {
            if (!_conf.groupId.includes(this.props.user.groupId)) {
              _include = false;
            }
          }
        }

        if (_include) {
          _newButtonsConfig.push(_config);
        }
      });
      this.setState({ buttonsConfig: _newButtonsConfig });
    }
  }

  async syncDataLocal() {
    await syncData(this.props.event.id).then(() => {
      setTimeout(() => {
        this.setState({ openLoading: false });
        window.location.reload();
      }, 3000);
    });
  }

  executeElement() {
    if (this.state.executeElement) {
      return (
        <ElementTreatment
          history={this.props.history}
          chosenConfig={this.state.chosenConfig}
          onClose={() => {
            this.setState({ executeElement: false, chosenConfig: null });
          }}
        />
      );
    }
  }

  render() {
    const { color = "#212121", size = null, textColor = "white" } = this.props;
    const styles = {
      button: {
        zIndex: "11",
        display: "flex",
        position: "fixed",
        top: "45%",
        left: "0px",
        backgroundColor: color,
        color: textColor,
      },
    };
    if (window.self !== window.top) return <div />;
    else
      return (
        <MobileOrientation
          history={this.props.history}
          isHall360={this.props.isHall360}
        >
          <div id="menu_btn">
            <AudioPlayerComp />
            <ShowZendesk />
            {this.props.hasMenu ? (
              <div className="ios-container">
                <div>
                  {isIOS || isMacOs ? (
                    <Fab
                      class="MuiButtonBase-root MuiFab-root MuiFab-sizeMedium MuiFab-secondary MuiFab-extended"
                      style={styles.button}
                      variant="contained"
                      color="secondary"
                      size={size ? size : "medium"}
                      onClick={() => this.setState({ openMenu: true })}
                    >
                      <ArrowMenuIcon />
                    </Fab>
                  ) : (
                    <button
                      style={{ background: this.state.menuButtonConfig.backgroundColor, color: this.state.menuButtonConfig.textColor }}
                      className={`Menu_Button ${this.state.openMenu ? "Menu_Button--isOpen" : ""}`}
                      onClick={() => this.setState({ openMenu: !this.state.openMenu })}
                    >
                      <ArrowMenuIcon />
                    </button>
                  )}
                  <AlertNetwork />
                </div>

                {/* {this.props.hasChat &&
								this.props.user && <PrivateChatControl history={this.props.history} />} */}
              </div>
            ) : null}


            <Loading
              open={this.state.openLoading}
              message={this.state.loadingMessage}
            />

            <Drawer
              isOpen={this.state.openMenu}
              onBackdropClick={() => this.setState({ openMenu: false })}
              backgroundColor={this.state.drawerConfig.backgroundColor}
              backgroundImage={this.state.drawerConfig.backgroundImage}
              textColor={this.state.drawerConfig.textColor}
            >
              <VLibrasListenner
                checkGlobalVlibras={this.props.checkGlobalVlibras}
              />
              <List className="ios-menus">
                {!this.state.buttonsConfig ? (
                  <>{this.renderDefaultButtons()}</>
                ) : (
                  <>{this.renderCustomButtons()}</>
                )}
                {(this.props.isAdmin || this.props.isStaff) &&
                  this.state.adminGroup && (
                    <>
                      {this.renderReportsGroup()}
                      {this.renderModeratorGroup()}
                      {this.renderFilesGroup()}
                      {this.renderConfigGroup()}
                    </>
                  )
                }
              </List>
            </Drawer>

            <CountdownToExecute history={this.props.history} />

            {this.executeElement()}
          </div>
        </MobileOrientation>
      );
  }

  renderDefaultButtons() {
    return (
      <>
        {this.rendeLanguageButton()}
        {this.renderProfileButton()}
        {this.renderSyncDataButton()}
        <ListItem
          button
          key="eventhall"
          onClick={() =>
            navigationHistoryService.setHistory(this.props.history,
              "/" + this.props.event.id + "/eventhall"
            )
          }
        >
          <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={getDic("hall-evento")} />
        </ListItem>
        {/* {this.props.hasChat ? (
                  <ListItem
                    button
                    key="chat"
                    onClick={() => navigationHistoryService.setHistory(this.props.history,'/' + this.props.event.id + '/chat')}
                  >
                    <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
                      <ChatIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Network'} />
                  </ListItem>
                ) : null} */}
        {this.props.hasSchedules &&
          !this.props.config.find(
            (e) =>
              e.codeScreen && e.codeScreen.indexOf("NO-MENU-SESSION") > -1
          ) ? (
          <ListItem
            button
            key="schedules"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/schedules&0"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <ScheduleIcon />
            </ListItemIcon>
            <ListItemText primary={getDic("sessoes")} />
          </ListItem>
        ) : null}
        {/* {this.props.hasSpeakers ? (
          <ListItem
            button
            key="speakers"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/speakers"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <RecordVoiceOverIcon />
            </ListItemIcon>
            <ListItemText primary={getDic("palestrantes")} />
          </ListItem>
        ) : null} */}
        {this.props.hasStands &&
          !this.props.config.find(
            (e) =>
              e.codeScreen && e.codeScreen.indexOf("NO-MENU-STAND") > -1
          ) ? (
          <ListItem
            button
            key="stands"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/stands"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary={getDic("exposicao")} />
          </ListItem>
        ) : null}
        {this.props.useSideMenu &&
          this.props.configList.map((item) => {
            return (
              <ListItem
                button
                key={item.id}
                onClick={() =>
                  this.setState({
                    executeElement: true,
                    chosenConfig: item,
                  })
                }
              >
                <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
                  <GenericIcon />
                </ListItemIcon>
                <ListItemText primary={item.tooltip} />
              </ListItem>
            );
          })}

        {this.renderAdminButtons()}
      </>
    )
  }

  renderCustomButtons() {
    return (
      <>
        {this.state.buttonsConfig.map(item => {
          return (
            <>
              {
                item.default && item.default === 'language' && (
                  <>{this.rendeLanguageButton()}</>
                )
              }
              {
                item.default && item.default === 'profile' && (
                  <>{this.renderProfileButton()}</>
                )
              }
              {
                item.default && item.default === 'sync' && (
                  <>{this.renderSyncDataButton(true)}</>
                )
              }
              {
                !item.default && item.text && (
                  <>{this.renderCustomConfigButton(item)}</>
                )
              }
            </>
          )
        })}
        {this.renderAdminButtons()}
      </>
    )
  }

  renderProfileButton() {
    return (
      <div>
        <ListItem
          button
          key="profile"
          onClick={() => {
            !this.props.config.find(
              (e) => e.codeScreen.indexOf("NO-MENU-EDIT-PROFILE") > -1
            ) &&
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/profile"
              );
          }}
        >
          <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
            <Avatar
              src={
                this.props.user && this.props.user.image
                  ? this.props.user.image
                  : null
              }
              size="small"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              this.props.user && this.props.user.name
                ? this.props.user.name.toUpperCase()
                : ""
            }
            secondary={
              !this.props.config.find(
                (e) =>
                  e.codeScreen &&
                  e.codeScreen.indexOf("NO-MENU-EDIT-PROFILE") > -1
              ) && <div style={{ color: this.state.drawerConfig.textColor }}>{`${getDic("editar")} ${getDic("perfil")}`}</div>
            }
          />
        </ListItem>
        <Divider />
      </div>
    )
  }

  rendeLanguageButton() {
    return (
      <>
        {this.props.hasChangeLanguage ? (
          <FormRow align="center">
            {this.props.enableLang.pt && (
              <div
                style={{ paddingRight: "5px", paddingLeft: "5px" }}
                title="Português"
              >
                <Avatar
                  size="smaller"
                  src={flagBrazil}
                  onClick={() => {
                    this.props.setLanguage({ lang: "pt-br" });
                    window.location.reload();
                  }}
                />
              </div>
            )}
            {this.props.enableLang.en && (
              <div
                style={{ paddingRight: "5px", paddingLeft: "5px" }}
                title="English"
              >
                <Avatar
                  size="smaller"
                  src={flagEngland}
                  onClick={() => {
                    this.props.setLanguage({ lang: "en" });
                    window.location.reload();
                  }}
                />
              </div>
            )}
            {this.props.enableLang.sp && (
              <div
                style={{ paddingRight: "5px", paddingLeft: "5px" }}
                title="Español"
              >
                <Avatar
                  size="smaller"
                  src={flagSpain}
                  onClick={() => {
                    this.props.setLanguage({ lang: "sp" });
                    window.location.reload();
                  }}
                />
              </div>
            )}
          </FormRow>
        ) : null}
      </>
    )
  }

  renderSyncDataButton(custom = false) {
    return (
      <>
        {this.props.hasSync ? (
          <ListItem
            button
            key="sync"
            onClick={() => {
              this.setState({
                openLoading: true,
                loadingMessage: getDic("sincronizando"),
              });
              this.syncDataLocal();
            }}
          >
            {!custom && (
              <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
                <SyncIcon />
              </ListItemIcon>
            )}
            <ListItemText
              primary={getDic("sincronizar") + " " + getDic("dados")}
            />
          </ListItem>
        ) : null}
      </>
    )
  }

  renderCustomConfigButton(buttonConfig) {
    return (
      <>
        <ListItem
          button
          key={buttonConfig.text}
          onClick={() => {
            var _config;
            if (buttonConfig.config > 0) {
              _config = this.props.config.find(c => c.id === buttonConfig.config);
            } else if (buttonConfig.schedule > 0) {
              _config = this.props.config.find(c => c.scheduleId === buttonConfig.schedule);
            }

            this.setState({
              executeElement: true,
              chosenConfig: _config,
              openMenu: false
            });
          }}
        >
          <ListItemText primary={buttonConfig.text} />
        </ListItem>
      </>
    )
  }

  renderAdminButtons() {
    return (
      <>
        <Divider />
        {this.props.isAdmin || this.props.isStaff ? (
          <ListItem
            button
            key="adminGroup"
            onClick={() => {
              var open = this.state.adminGroup === true ? false : true;
              this.setState({ adminGroup: open });
            }}
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              {this.state.adminGroup ? <ArrowDown /> : <ArrowUp />}
            </ListItemIcon>
            <ListItemText primary={getDic("administrador")} />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.adminGroup ? (
          <ListItem
            button
            key="notifications"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/notifications"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <Push />
            </ListItemIcon>
            <ListItemText primary="Push Notification" />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.adminGroup ? (
          <ListItem
            button
            key="campaignregister"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/campaignregister"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <CampaignIcon />
            </ListItemIcon>
            <ListItemText primary={getDic("campanha")} />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.adminGroup ? (
          <ListItem
            button
            key="survey"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/survey"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <SurveyIcon />
            </ListItemIcon>
            <ListItemText primary={`${getDic("cadastro")} ${getDic("enquete")}`} />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.adminGroup ? (
          <ListItem
            button
            key="ranking"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/ranking"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <RankingIcon />
            </ListItemIcon>
            <ListItemText primary={`Ranking`} />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.adminGroup ? (
          <ListItem
            button
            key="raffle"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/raffle"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <RaffleIcon />
            </ListItemIcon>
            <ListItemText primary={`${getDic("cadastro")} ${getDic("sorteio")}`} />
          </ListItem>
        ) : null}
      </>
    )
  }

  renderReportsGroup() {
    return (
      <>
        {this.props.isAdmin || this.props.isStaff ? (
          <ListItem
            button
            key="reportsGroup"
            onClick={() => {
              var open = this.state.reportsGroup === true ? false : true;
              this.setState({ reportsGroup: open });
            }}
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              {this.state.reportsGroup ? <ArrowDown /> : <ArrowUp />}
            </ListItemIcon>
            <ListItemText primary={getDic("relatorios")} />
          </ListItem>
        ) : null}

        {(this.props.isAdmin || this.props.isStaff) &&
          this.state.reportsGroup ? (
          <ListItem
            button
            key="reports"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history, "/" + this.props.event.id + "/statistics")
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <Reports />
            </ListItemIcon>
            <ListItemText
              primary={`${getDic("relatorio")} ${getDic("participantes")}`}
            />
          </ListItem>
        ) : null}
        {(this.props.isAdmin || this.props.isStaff) &&
          this.state.reportsGroup ? (
          <ListItem
            button
            key="surveyreport"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history, `/${this.props.event.id}/surveyreport&1`)
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <ReportsSurvey />
            </ListItemIcon>
            <ListItemText
              primary={`${getDic("relatorio")} ${getDic("perguntas")}`}
            />
          </ListItem>
        ) : null}
        {(this.props.isAdmin || this.props.isStaff) &&
          this.state.reportsGroup ? (
          <ListItem
            button
            key="adminreports"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/adminreports"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <AdminReportsIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${getDic("relatorios")} ${getDic("administrador")}`}
            />
          </ListItem>
        ) : null}
      </>
    );
  }

  renderModeratorGroup() {
    return (
      <>
        {this.props.isAdmin || this.props.isStaff ? (
          <ListItem
            button
            key="moderatorGroup"
            onClick={() => {
              var open = this.state.moderatorGroup === true ? false : true;
              this.setState({ moderatorGroup: open });
            }}
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              {this.state.moderatorGroup ? <ArrowDown /> : <ArrowUp />}
            </ListItemIcon>
            <ListItemText primary={getDic("moderador")} />
          </ListItem>
        ) : null}
        {(this.props.isAdmin || this.props.isStaff) &&
          this.state.moderatorGroup ? (
          <ListItem
            button
            key="moderatorv2"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/moderatorv2"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <Moderator />
            </ListItemIcon>
            <ListItemText primary={`${getDic("moderador")} Chat`} />
          </ListItem>
        ) : null}
        {(this.props.isAdmin || this.props.isStaff) &&
          this.state.moderatorGroup ? (
          <ListItem
            button
            key="questions"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history, `/${this.props.event.id}/questions&1`)
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <Questions />
            </ListItemIcon>
            <ListItemText
              primary={`${getDic("moderador")} ${getDic("perguntas")}`}
            />
          </ListItem>
        ) : null}
      </>
    );
  }

  renderFilesGroup() {
    return (
      <>
        {this.props.isAdmin || this.props.isStaff ? (
          <ListItem
            button
            key="filesGroup"
            onClick={() => {
              var open = this.state.filesGroup === true ? false : true;
              this.setState({ filesGroup: open });
            }}
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              {this.state.filesGroup ? <ArrowDown /> : <ArrowUp />}
            </ListItemIcon>
            <ListItemText primary={getDic("arquivos")} />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.filesGroup ? (
          <ListItem
            button
            key="upload"
            onClick={() => {
              navigationHistoryService.setHistory(this.props.history,
                `/${this.props.event.id}/upload/${this.props.user.id}&INTEEGRA&${this.props.event.contractId}&1`
              );
            }}
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <Upload />
            </ListItemIcon>
            <ListItemText primary="Upload" />
          </ListItem>
        ) : null}
        {(this.props.isAdmin || this.props.isStaff) && this.state.filesGroup ? (
          <ListItem
            button
            key="files"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history, "/" + this.props.event.id + "/files")
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <Files />
            </ListItemIcon>
            <ListItemText primary={getDic("arquivos")} />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.filesGroup ? (
          <ListItem
            button
            key="inteegraflix"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/inteegraflix"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <InteegraflixIcon />
            </ListItemIcon>
            <ListItemText primary={`Inteegraflix`} />
          </ListItem>
        ) : null}
      </>
    );
  }

  renderConfigGroup() {
    return (
      <>
        {this.props.isAdmin ? (
          <ListItem
            button
            key="configGroup"
            onClick={() => {
              var open = this.state.configGroup === true ? false : true;
              this.setState({ configGroup: open });
            }}
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              {this.state.configGroup ? <ArrowDown /> : <ArrowUp />}
            </ListItemIcon>
            <ListItemText primary={getDic("configuracoes")} />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.configGroup ? (
          <ListItem
            button
            key="calendarregister"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/calendarregister"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${getDic("cadastro")} ${getDic("calendario")}`}
            />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.configGroup ? (
          <ListItem
            button
            key="questionsregister"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/questionsregister"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <QuestionsIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${getDic("cadastro")} ${getDic("perguntas")}`}
            />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.configGroup ? (
          <ListItem
            button
            key="levelregister"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/levelregister"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <GameIcon />
            </ListItemIcon>
            <ListItemText primary={`${getDic("cadastro")} ${getDic("fase")}`} />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.configGroup ? (
          <ListItem
            button
            key="groupregister"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/groupregister"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <GroupAddIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${getDic("cadastro")} ${getDic("grupo")}`}
            />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.configGroup ? (
          <ListItem
            button
            key="speakersregister"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/speakersregister"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <SpeakerRegisterIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${getDic("cadastro")} ${getDic("palestrante")}`}
            />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.configGroup ? (
          <ListItem
            button
            key="schedulesregister"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history,
                "/" + this.props.event.id + "/schedulesregister"
              )
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <SchedulesRegisterIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${getDic("cadastro")} ${getDic("programacao")}`}
            />
          </ListItem>
        ) : null}
        {this.props.isAdmin && this.state.configGroup ? (
          <ListItem
            button
            key="config"
            onClick={() =>
              navigationHistoryService.setHistory(this.props.history, "/" + this.props.event.id + "/config")
            }
          >
            <ListItemIcon style={{ color: this.state.drawerConfig.textColor }}>
              <ConfigIcon />
            </ListItemIcon>
            <ListItemText primary={`${getDic("configuracoes")}`} />
          </ListItem>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  var noMenu;
  var noSync;
  var noParticipant;
  var hasChat;
  var hasChangeLanguage;
  var languageEnable = {
    en: true,
    sp: true,
    pt: true,
  };
  var useSideMenu;
  var _configList = [];
  state.config.find((item) => {
    if (item.codeScreen === "NO-MENU") {
      noMenu = item;
    }
    if (item.codeScreen === "NO-SYNC-DATA") {
      noSync = item;
    }
    if (item.codeScreen === "NO-PARTICIPANT") {
      noParticipant = item;
    }
    if (item.codeScreen === "ENABLE-CHAT") {
      hasChat = item;
    }
    if (item.codeScreen === "ENABLE-CHANGE-LANGUAGE") {
      hasChangeLanguage = item;
      if (item.url && item.url.length > 0) {
        item.url.forEach((lang) => {
          var _langArray = lang.split(":");
          switch (_langArray[0]) {
            case "enableEn":
              languageEnable.en =
                _langArray[1] && _langArray[1] === "false" ? false : true;
              break;
            case "enableSp":
              languageEnable.sp =
                _langArray[1] && _langArray[1] === "false" ? false : true;
              break;
            case "enablePt":
              languageEnable.pt =
                _langArray[1] && _langArray[1] === "false" ? false : true;
              break;
            default:
              break;
          }
        });
      }
    }
    if (item.codeScreen === "USE-SIDE-MENU") {
      useSideMenu = item;
      _configList = state.config.filter((conf) => {
        return conf.screenId === 1 && conf.codeScreen.includes("ELEMENT-");
      });
    }
  });

  const GetHallModel = state.config.find((item) => {
    return item.codeScreen === "ENABLE_HALL_3D";
  });

  return {
    event: state.event,
    user: state.user,
    config: state.config,
    isHall360: GetHallModel ? true : false,
    isAdmin: state.user && state.user.isAdmin ? state.user.isAdmin : false,
    isDeveloper:
      state.user && state.user.isDeveloper ? state.user.isDeveloper : false,
    isStaff: state.user && state.user.isStaff ? state.user.isStaff : false,
    hasStands: state.stands.length > 0 ? true : false,
    hasChat: hasChat ? true : false,
    hasSchedules: state.schedules.length > 0 ? true : false,
    hasMenu: noMenu ? false : true,
    hasSync: noSync ? false : true,
    hasParticipant: noParticipant ? false : true,
    hasSpeakers: state.speakers.length > 0 ? true : false,
    hasChangeLanguage: hasChangeLanguage ? true : false,
    enableLang: languageEnable,
    useSideMenu: useSideMenu ? true : false,
    configList: _configList,
    notification: state.notification,
  };
}

const mapDispatchToProps = {
  setLanguage,
  setNotification,
  checkGlobalVlibras,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBarMenuButton);
