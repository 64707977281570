import React from "react";
import { connect } from "react-redux";
import { getDic } from "../../assets/i18n/dictionary";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets_network/scss/theme.scss";
import {
  ScrollMessages,
  ScrollContacts,
  ScrollGroups,
  ScrollRecents,
  ChatAreaHeader,
} from "./components";
import { Container, FormRow, TextBig, TextMedium } from "../../components";
import { getGuestListV2 } from "./../../services/guestService";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { firestore } from "./../../utils/firebase";
import "./emoji-mart/emoji-mart.css";
import { Picker } from "emoji-mart";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import ScreenRotationIcon from "@material-ui/icons/ScreenRotation";
import { isMobile, isIOS } from "react-device-detect";
import MobileOrientationInstructionsPage from "../../pages/MobileOrientationInstructionsPage";

const NetworkChatV2 = (props) => {
  const [clientId, setClientId] = React.useState(0);
  const [tabPane, setTabPane] = React.useState(0);
  const [inputMsgValue, setInputMsgValue] = React.useState("");
  const [currentTargetMsg, setCurrentTargetMsg] = React.useState({
    id: 0,
    image: "",
    institution: "",
    name: "",
    position: "",
    message: "",
  });

  // Collections Ref
  const messagesRef = firestore.collection(
    `network/${props.event.id}/messages/${props.user.id}/history`
  );
  const targetMessagesRef = firestore.collection(
    `network/${props.event.id}/messages/${currentTargetMsg.id}/history`
  );
  const privateChatRef = firestore.collection(
    `network/${props.event.id}/messages/${props.user.id}/private`
  );
  const privateUserChatRef = firestore.collection(
    `network/${props.event.id}/messages/${currentTargetMsg.id}/private`
  );
  const [openModalEmoji, setModalEmoji] = React.useState(false);
  const [reloadPage, setReloadPage] = React.useState(0);

  const [showMessageArea, setMessageArea] = React.useState(0);

  const sendMessage = async (e) => {
    if (e) e.preventDefault();
    if (e) e.preventDefault();
    if (inputMsgValue == "") return;
    if (inputMsgValue.length > 500) return;
    if (inputMsgValue.indexOf("�") > -1) return;
    if (inputMsgValue.indexOf("◕") > -1) return;
    if (inputMsgValue.indexOf("╔") > -1) return;
    if (inputMsgValue.indexOf("☆") > -1) return;
    if (inputMsgValue.indexOf("█") > -1) return;
    if (inputMsgValue.indexOf("¶") > -1) return;
    if (inputMsgValue.indexOf("▒") > -1) return;
    if (inputMsgValue.indexOf("♥") > -1) return;

    if (!currentTargetMsg.id) return;
    setInputMsgValue("");

    await messagesRef.doc(`${currentTargetMsg.id}`).set(
      {
        enabled: true,
      },
      { merge: true }
    );

    await messagesRef.doc(`${currentTargetMsg.id}`).collection("msgs").add({
      user: props.user.name,
      target: currentTargetMsg.name,
      body: inputMsgValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid: props.user.id,
      photoURL: props.user.image,
      disabled: false,
    });

    await targetMessagesRef.doc(`${props.user.id}`).set(
      {
        enabled: true,
      },
      { merge: true }
    );

    await targetMessagesRef.doc(`${props.user.id}`).collection("msgs").add({
      user: props.user.name,
      target: currentTargetMsg.name,
      body: inputMsgValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid: props.user.id,
      photoURL: props.user.image,
      disabled: false,
    });

    await privateChatRef.doc(`${currentTargetMsg.id}`).set(
      {
        guestId: currentTargetMsg.id,
        photoURL: currentTargetMsg.image,
        user: currentTargetMsg.name,
        target: props.user.name,
        tempTime: firebase.firestore.FieldValue.serverTimestamp(),
        message: inputMsgValue,
      },
      {
        merge: true,
      }
    );

    await privateUserChatRef.doc(`${props.user.id}`).set(
      {
        guestId: props.user.id,
        photoURL: props.user.image,
        user: props.user.name,
        target: currentTargetMsg.name,
        lastMsg: firebase.firestore.FieldValue.serverTimestamp(),
        tempTime: firebase.firestore.FieldValue.serverTimestamp(),
        message: inputMsgValue,
      },
      {
        merge: true,
      }
    );
    //set pending view msg
    await firestore
      .collection(`network/${props.event.id}/messages`)
      .doc(`${currentTargetMsg.id}`)
      .set(
        {
          enabled: true,
          pendingMsgs: firebase.firestore.FieldValue.arrayUnion(props.user.id),
        },
        { merge: true }
      );
    await firestore
      .collection(`network/${props.event.id}/messages`)
      .doc(`${props.user.id}`)
      .set(
        {
          enabled: true,
        },
        { merge: true }
      );
  };

  const onKeyPressHandler = (value) => {
    if (value === "Enter") {
      sendMessage();
    }
  };

  const onEmojiClick = (emojiObject, props) => {
    let sym = emojiObject.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);

    var message = `${inputMsgValue}${emoji}`;
    setInputMsgValue(message);
    setModalEmoji(false);
  };

  React.useEffect(() => {
    if (props.networkTargetId) {
      const targetId = props.networkTargetId.url[0].split("=");
      if (targetId.length > 1) {
        setMessageArea(true);
        getGuestListV2(1, "", targetId[1]).then((res) => {
          const { id, image, name } = res.guestList[0];
          setCurrentTargetMsg({
            id: id,
            image: image,
            name: name,
          });
        });
      }
    }

    const intervalId = setInterval(() => {
      setReloadPage(reloadPage + 1);
    }, 120000);
    const timer = setTimeout(() => {
      if (!document.getElementById("isChatV2Component")) return;
      let ch = document.getElementById("isChatV2Component").clientHeight;
      setClientId(ch);
    }, 500);

    return () => {
      clearTimeout(timer);
      clearInterval(intervalId);
    };
  }, [reloadPage]);

  return (
    <React.Fragment>
      <DeviceOrientation lockOrientation={"landscape"}>
        <Orientation
          orientation={isMobile ? "landscape" : "portrait"}
          alwaysRender={false}
        >
          <Container
            background="#e8eced"
            align="center"
            centralize={true}
            id="isChatV2Component"
          >
            <FormRow align="center">
              <ScreenRotationIcon style={{ fontSize: 150 }} />
            </FormRow>
            <FormRow align="center">
              <TextBig>{getDic("posicione-device-vertical")}..</TextBig>
            </FormRow>
            <FormRow align="center">
              <TextMedium>
                {getDic("posicione-device-horizontal-mensagem")}.
              </TextMedium>
            </FormRow>
          </Container>
        </Orientation>
        <Orientation
          orientation={isMobile ? "portrait" : "landscape"}
          alwaysRender={false}
        >
          <div
            id="isChatV2Component"
            className="network-container page-content"
            style={{
              padding: 0,
              paddingTop: 10,
              overflow: "hidden",
              display: "flex",
              height: "98%",
            }}
          >
            <div className="container-fluid" style={{ width: "100%" }}>
              <div className="row">
                <div className="col-lg-4" style={{ zIndex: 99 }}>
                  <div
                    className="chat-leftsidebar"
                    style={{
                      padding: 15,
                      display: isMobile
                        ? showMessageArea
                          ? "none"
                          : "block"
                        : "block",
                    }}
                  >
                    <div className>
                      <div className="py-4 border-bottom">
                        <div className="media">
                          <div
                            className="align-self-center me-3"
                            style={{
                              height: 35,
                              width: 35,
                            }}
                          >
                            {props.user.image ? (
                              <img
                                src={props.user.image}
                                className="avatar-xs rounded-circle"
                                alt
                              />
                            ) : (
                              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                {props.user.name[0]}
                              </span>
                            )}
                          </div>
                          <div className="media-body">
                            <h5 className="font-size-15 mt-0 mb-1">
                              {props.user.name}
                            </h5>
                            <p className="text-muted mb-0">
                              <i className="mdi mdi-circle text-success align-middle me-1" />
                              Online
                            </p>
                          </div>
                          {/* <div>
                                                    <div className="chat-noti-dropdown active dropdown">
                                                        <i aria-haspopup="true" className="btn" aria-expanded="false"><i className="bx bx-bell bx-tada" /></i>
                                                        <div tabIndex={-1} role="menu" aria-hidden="true" className="dropdown-menu-end dropdown-menu"><a href="#" tabIndex={0} role="menuitem" className="dropdown-item">Action</a><a href="#" tabIndex={0} role="menuitem" className="dropdown-item">Another action</a><a href="#" tabIndex={0} role="menuitem" className="dropdown-item">Something else here</a></div>
                                                    </div>
                                                </div> */}
                        </div>
                      </div>
                      <div className="chat-leftsidebar-nav">
                        <ul className="nav nav-pills nav-justified">
                          <li className="nav-item">
                            <a
                              className={`${tabPane == 0 && "active"} nav-link`}
                              onClick={() => setTabPane(0)}
                            >
                              <i className="bx bx-chat font-size-20 d-sm-none" />
                              <span className="d-none d-sm-block">Chat</span>
                            </a>
                          </li>
                          <li className="nav-item" style={{ display: "none" }}>
                            <a
                              className={`${tabPane == 1 && "active"} nav-link`}
                              onClick={() => setTabPane(1)}
                            >
                              <i className="bx bx-group font-size-20 d-sm-none" />
                              <span className="d-none d-sm-block">
                                {getDic("grupo")}
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`${tabPane == 2 && "active"} nav-link`}
                              onClick={() => setTabPane(2)}
                            >
                              <i className="bx bx-book-content font-size-20 d-sm-none" />
                              <span className="d-none d-sm-block">
                                {getDic("contatos")}
                              </span>
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content py-4">
                          <div
                            className={`tab-pane ${tabPane == 0 && "active"}`}
                          >
                            <div>
                              <h5 className="font-size-14 mb-3">{}</h5>
                              <ul className="list-unstyled chat-list">
                                {clientId > 0 && (
                                  <ScrollRecents
                                    fullScreen={props.fullScreen}
                                    event={props.event}
                                    user={props.user}
                                    setCurrentTargetMsg={setCurrentTargetMsg}
                                    currentTargetMsg={currentTargetMsg}
                                    setMessageArea={setMessageArea}
                                    heightParent={clientId}
                                  />
                                )}
                              </ul>
                            </div>
                          </div>
                          <div
                            className={`tab-pane ${tabPane == 1 && "active"}`}
                          >
                            <h5 className="font-size-14 mb-3">
                              {getDic("grupo")}
                            </h5>
                            <ul className="list-unstyled chat-list">
                              <ScrollGroups fullScreen={props.fullScreen} />
                            </ul>
                          </div>
                          <div
                            className={`tab-pane ${tabPane == 2 && "active"}`}
                          >
                            <h5 className="font-size-14 mb-3">
                              {getDic("contato")}
                            </h5>
                            <div>
                              {clientId > 0 && (
                                <ScrollContacts
                                  fullScreen={props.fullScreen}
                                  setCurrentTargetMsg={setCurrentTargetMsg}
                                  currentTargetMsg={currentTargetMsg}
                                  setMessageArea={setMessageArea}
                                  user={props.user}
                                  heightParent={clientId}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  */}
                <div
                  className="col-lg-8"
                  style={{
                    zIndex: 11,
                  }}
                >
                  <div
                    className="w-100 user-chat"
                    style={{
                      display: isMobile
                        ? showMessageArea
                          ? "block"
                          : "none"
                        : "block",
                      paddingLeft: isMobile ? 0 : 29,
                    }}
                  >
                    {currentTargetMsg.id ? (
                      <div
                        className="card"
                        style={{ marginBottom: 0, height: "100vh" }}
                      >
                        <div className="p-4 border-bottom ">
                          <div className="row">
                            <div className="col-12 col-md-12 row">
                              <div
                                className="col-1 col-md-1"
                                style={{
                                  display: isMobile ? "block" : "none",
                                  marginRight: 14,
                                  padding: 0,
                                }}
                              >
                                <i
                                  className="mdi mdi-arrow-left"
                                  onClick={() => setMessageArea(false)}
                                  style={{
                                    fontSize: 29,
                                    color: "#9ea2b1",
                                    display: isMobile ? "block" : "none",
                                  }}
                                />
                              </div>
                              <div
                                className="col-10 col-md-10"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <h5 className="font-size-15 mb-1">
                                  {currentTargetMsg.name}
                                </h5>
                                <p className="text-muted mb-0">
                                  {/* <OnlineChecker
                                text
                                event={props.event}
                                id={currentTargetMsg.id}
                              /> */}
                                  <ChatAreaHeader
                                    event={props.event}
                                    user={props.user}
                                    currentTargetMsg={currentTargetMsg}
                                    setCurrentTargetMsg={setCurrentTargetMsg}
                                  />
                                </p>
                              </div>
                            </div>
                            <div
                              className="col-3 col-md-8"
                              style={{ display: "none" }}
                            >
                              <ul className="list-inline user-chat-nav text-end mb-0">
                                <li className="list-inline-item d-sm-inline-block">
                                  <div className="dropdown">
                                    <div
                                      tabIndex={-1}
                                      role="menu"
                                      direction="right"
                                      aria-hidden="true"
                                      className="dropdown-menu-md dropdown-menu-end dropdown-menu"
                                    >
                                      <form className="p-3">
                                        <div className="m-0 form-group">
                                          <div className="input-group">
                                            <input
                                              placeholder={
                                                getDic("buscar") + "..."
                                              }
                                              aria-label="Recipient's username"
                                              type="text"
                                              className="form-control form-control"
                                            />
                                            <div className="input-group-append">
                                              <button
                                                type="submit"
                                                className="btn btn-primary"
                                              >
                                                <i className="mdi mdi-magnify" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="chat-conversation p-3">
                            {currentTargetMsg.id > 0 && clientId > 0 && (
                              <ScrollMessages
                                event={props.event}
                                user={props.user}
                                currentTargetMsg={currentTargetMsg}
                                setCurrentTargetMsg={setCurrentTargetMsg}
                                fullScreen={props.fullScreen}
                                heightParent={clientId}
                              />
                            )}
                          </div>
                          <div className="p-3 chat-input-section">
                            <div className="row">
                              <div className="col">
                                <div className="position-relative">
                                  <div
                                    style={{
                                      display: openModalEmoji
                                        ? "block"
                                        : "none",
                                      position: "absolute",
                                      bottom: 0,
                                      right: 55,
                                      width: 260,
                                      transition: "0.5s",
                                    }}
                                  >
                                    <Picker
                                      showPreview={false}
                                      showSkinTones={false}
                                      style={{
                                        position: "relative",
                                        zIndex: 199999,
                                        height: "auto",
                                        width: "100%",
                                      }}
                                      i18n={{
                                        search: "Recherche",
                                        categories: {
                                          smileys: "Emojis",
                                          people: getDic("outros"),
                                          recent: getDic("recente"),
                                        },
                                      }}
                                      onClick={(emoji, props) => {
                                        onEmojiClick(emoji, props);
                                      }}
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    style={{ border: 0 }}
                                    className="form-control chat-input"
                                    placeholder={`${getDic(
                                      "digite-mensagem"
                                    )}...`}
                                    value={inputMsgValue}
                                    onChange={(e) =>
                                      setInputMsgValue(e.target.value)
                                    }
                                    onKeyPress={(event) =>
                                      onKeyPressHandler(event.key)
                                    }
                                    maxlength="500"
                                  />
                                  {inputMsgValue.length > 499 && (
                                    <sub
                                      style={{
                                        color: "#9094a6",
                                        position: "absolute",
                                        top: 46,
                                      }}
                                    >
                                      {getDic("limite-erro")} 500{" "}
                                      {getDic("caracteres")}!
                                    </sub>
                                  )}
                                  <div className="chat-input-links">
                                    <ul className="list-inline mb-0">
                                      {/*
                                                                        <li className="list-inline-item"><a href="/chat"><i className="mdi mdi-emoticon-happy-outline" id="Filetooltip" /></a></li>
                                                                        <li className="list-inline-item"><a href="/chat"><i className="mdi mdi-file-image-outline" id="Imagetooltip" /></a></li>
                                                                    */}
                                      <li className="list-inline-item">
                                        <a>
                                          {openModalEmoji ? (
                                            <i
                                              className="mdi mdi-close-circle-outline"
                                              id="Emojitooltip"
                                              onClick={() =>
                                                setModalEmoji(false)
                                              }
                                              style={{
                                                fontSize: 22,
                                                color: "red",
                                              }}
                                            />
                                          ) : (
                                            <i
                                              className="mdi mdi-emoticon-happy-outline"
                                              id="Emojitooltip"
                                              onClick={() =>
                                                setModalEmoji(true)
                                              }
                                              style={{
                                                fontSize: 22,
                                              }}
                                            />
                                          )}
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto col">
                                <button
                                  type="button"
                                  className="btn-rounded chat-send w-md waves-effect waves-light btn btn-primary"
                                  style={{
                                    borderRadius: 26,
                                    height: 34,
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 15,
                                    minWidth: 60,
                                  }}
                                  onClick={() => sendMessage()}
                                >
                                  <span
                                    className="d-none d-sm-inline-block me-2"
                                    style={{ paddingLeft: 10 }}
                                  >
                                    {getDic("enviar")}
                                  </span>
                                  <i
                                    className="mdi mdi-send"
                                    style={{ marginRight: 4 }}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p style={{ textAlign: "center" }}>
                          <img
                            style={{
                              borderRadius: 200,
                              width: 250,
                              marginTop: 100,
                            }}
                            src="./icon-chat.png"
                          />
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            color: "#525252",
                            fontSize: 32,
                          }}
                        >
                          {getDic("conectese-em-sua-rede-de-contatos")}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            color: "#00000073",
                            fontSize: 14,
                          }}
                        >
                          {getDic("escolha-um-contato-para-network")}.<br />
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
          </div>
        </Orientation>
      </DeviceOrientation>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(NetworkChatV2);
