import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { getFilesListApi, getCategoriesListApi } from '../services/fileService';
import { getUrl } from '../services/apiService';
import Download from '@material-ui/icons/GetApp';

//Imports de components
import {
    Container,
    FormColumn,
    FormRow,
    ActivityIndicator,
    TextMedium,

    SelectList,
    ListItem,
    Divider,
    Link,
    Loading,
    MenuButton,
    BackButton,
    GridCell,
    GridRow,
    InfiniteScroll
} from '../components';

class ModeratorPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,

            //Controle de loading
            loadingMessage: getDic("carregando"),
            openLoading: false,

            //Lista de arquivos
            filesList: [],
            pageIndex: 0,
            totalPageIndex: 0,
            hasMore: true,
            category: "",
            categories: [],
        }
    }

    async componentDidMount() {
        //Bloqueio caso o usuário não seja admin
        var _blockUser = true;
        if (this.props.user.isAdmin || this.props.user.isStaff) {
            _blockUser = false;
        }
        if (_blockUser) {
            this.props.history.goBack();
        }

        var helpButton = document.getElementById("launcher");
        if (helpButton) {
            helpButton.style.visibility = 'hidden';
        }

        var categories = [];
        await getCategoriesListApi()
            .then(ret => {
                categories = ret;
            })
            .catch(err => {
                const defaultItem = {
                    text: getDic("selecione"),
                    value: null
                }
                categories.push(defaultItem);
            });

        this.getFilesList(true, categories[0].value);

        this.setState({ isLoading: false, categories: categories, category: categories[0].value });

        if (!this.props.isModal) {
            await insertNewAccess(this.props.match.url, "", getSystemActions().accessPage);
        }
    }

    async componentWillUnmount() {
        var helpButton = document.getElementById("launcher");
        if (helpButton) {
            helpButton.style.visibility = 'visible';
        }
        if (!this.props.isModal) {
            await insertNewAccess(this.props.match.url, "", getSystemActions().exitPage);
        }
        // window.location.reload();
    }

    componentDidCatch(e) {
        var error = e.toString();

        if (error.includes("Error: Maximum update depth exceeded")) {
            window.location.reload();
        }
    }

    getFilesList(newSearch = false, category) {
        var { hasMore, pageIndex, totalPageIndex } = this.state;

        if (newSearch) {
            this.setState({
                filesList: [],
                pageIndex: 0,
                totalPageIndex: 0,
                hasMore: true,
            });
            hasMore = true;
            pageIndex = 0;
            totalPageIndex = 0;
        }
        //Fazer a busca apenas se houver mais dados
        if (hasMore && pageIndex <= totalPageIndex) {
            var index = pageIndex + 1;
            var category = category === "default" ? null : category;

            getFilesListApi(index, category)
                .then(res => {

                    var complete = this.state.filesList.concat(res.filesList);

                    this.setState({ filesList: complete, pageIndex: index, totalPageIndex: res.totalPages });
                })
                .catch(err => {
                    console.log("Erro getFilesListApi", err);
                });
        }
        else if (this.state.hasMore) {
            this.setState({ hasMore: false });
        }
        else {
            return;
        }
    }

    downloadFile(file) {
        var date = file.dateCreated.split("-");

        let a = document.createElement('a');
        a.href = getUrl().files + date[0] + "/" + file.id + "." + file.extension;
        a.download = file.name + "." + file.extension;
        a.target = "_blank";
        a.click();
    }

    getUrlFile(file) {
        var date = file.dateCreated.split("-");
        return getUrl().files + date[0] + "/" + file.id + "." + file.extension;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Container background="#e8eced" align="center" >
                    <FormRow align="center">
                        <ActivityIndicator />
                    </FormRow>
                </Container>
            );
        }

        return (
            <Container align="center" background="#e8eced">
                {!this.props.isModal && (
                    <>
                        <MenuButton history={this.props.history} />
                        <BackButton
                            history={this.props.history}
                            color="#212121"
                        >
                            {getDic("voltar").toUpperCase()}
                        </BackButton>

                    </>
                )}

                <FormRow align="center" background="#e8eced" margin="0px">
                    <table style={{ width: '100%' }}>
                        <tr>
                            <FormRow minHeight="50px" align="flex-end" margin="0px" />
                        </tr>
                        <tr>
                            <FormRow paddingTop="5px" paddingBottom="5px" minHeight="60px" align="flex-end" margin="0px">
                                <div style={{ paddingRight: "22px", maxWidth: "400px" }}>
                                    <SelectList
                                        title={getDic("categoria")}
                                        width="200px"
                                        value={this.state.category}
                                        menuItens={this.state.categories}
                                        onChange={async e => {
                                            this.setState({ category: e.target.value });
                                            this.getFilesList(true, e.target.value);
                                        }}
                                    />
                                </div>
                            </FormRow>
                        </tr>
                        <tr>
                            <FormColumn align="center" background="#e8eced" margin="0px">
                                {this.state.filesList.length > 0 ? (
                                    <InfiniteScroll
                                        pageStart={0}
                                        dataLength={this.state.filesList.length}
                                        height={"80vh"}
                                        next={() => {
                                            this.getFilesList();
                                        }}
                                        hasMore={this.state.hasMore}
                                    // loader={this.state.hasMore ? (<ActivityIndicator />) : (null)}
                                    >
                                        <GridRow backgroundColor="#d9d9d9">
                                            <GridCell width="15%" border top first>
                                                <TextMedium>ID</TextMedium>
                                            </GridCell>
                                            <GridCell width="15%" border top>
                                                <TextMedium>PAX ID</TextMedium>
                                            </GridCell>
                                            <GridCell width="25%" border top>
                                                <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                                            </GridCell>
                                            <GridCell width="35%" border top>
                                                <TextMedium>URL</TextMedium>
                                            </GridCell>
                                            <GridCell width="10%" border top>
                                                <TextMedium>FUNC</TextMedium>
                                            </GridCell>
                                        </GridRow>

                                        {this.state.filesList.map((item, i) => {
                                            return (
                                                <GridRow key={item.id} autoColor={i}>
                                                    <GridCell width="15%" border first>
                                                        <TextMedium>{item.id}</TextMedium>
                                                    </GridCell>
                                                    <GridCell width="15%" border>
                                                        <TextMedium>{item.tableId}</TextMedium>
                                                    </GridCell>
                                                    <GridCell width="25%" border>
                                                        <TextMedium>{item.name}</TextMedium>
                                                    </GridCell>
                                                    <GridCell width="35%" border>
                                                        <TextMedium>{this.getUrlFile(item)}</TextMedium>
                                                    </GridCell>
                                                    <GridCell width="10%" border>
                                                        <Download
                                                            style={{ marginLeft: "5px", marginRight: "5px", cursor: 'pointer' }}
                                                            onClick={() => this.downloadFile(item)}
                                                        />
                                                    </GridCell>
                                                </GridRow>
                                            );
                                        })}
                                    </InfiniteScroll>
                                ) : (
                                    <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
                                )}
                            </FormColumn>
                        </tr>
                    </table>
                </FormRow>

                <Loading open={this.state.openLoading} message={this.state.loadingMessage} />
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        event: state.event,
        user: state.user,
    }
}

export default connect(mapStateToProps, null)(ModeratorPage)
