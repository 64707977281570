import React from 'react';
import Fab from '@material-ui/core/Fab';

const FabButton = props => {
    const {
        children,
        size = null,
        onClick,
        color = "#3f51b5",
        textColor = 'white',
        absolute = false,
        disabled = false
    } = props;

    const styles = {
        button: {
            backgroundColor: color,
            color: textColor,
            opacity: disabled ? "0.6" : "1"
        },
        buttonAbsolute: {
            backgroundColor: color,
            color: textColor,
            display: "block",
            position: "fixed",
            overflow: "visible",
            zIndex: "999998",
            right: "0px",
            top: "0px",
            opacity: disabled ? "0.5" : "1"
        }
    };

    return (
        <Fab
            class={`MuiButtonBase-root MuiFab-root MuiFab-size${size ? size : "Medium"} MuiFab-extended`}
            style={absolute ? styles.buttonAbsolute : styles.button}
            variant="contained"
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Fab>
    )
};

export default FabButton;
