import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import {
    Button,
    FormRow,
    FormColumn
} from '../components';

const defaultConfigButton = {
    colorButton: '#6169AA',
    textColorButton: 'white',
    text: getDic('responder')
}

const TransmissionNotification = () => {

    const [dimensions, setDimensions] = React.useState({
        width: window.innerWidth,
    });
    const [openMessage, setOpenMessage] = React.useState(false);
    const [enableButton, setEnableButton] = React.useState(false);
    const [configButton, setConfigButton] = React.useState(defaultConfigButton);
    // const [notification, setNotification] = React.useState(null);
    const [notification, setNotification] = React.useState(null);
    const [notificationDefault, setNotificationDefault] = React.useState(null);

    React.useEffect(() => {
        const updateWindowDimensions = () => {
            setDimensions({
                width: window.innerWidth,
            });
        };
        addEventListeners();
        window.addEventListener("resize", updateWindowDimensions);
        return () => {
            reopenQueue();
            window.removeEventListener("message", null);
            window.removeEventListener("resize", updateWindowDimensions);
        }
    }, []);

    const styles = {
        _container: {
            position: "absolute",
            background: "white",
            borderRadius: 5,
            paddingTop: 10,
            paddingBottom: 10,
            width: dimensions.width < 500 ? dimensions.width - 50 : 500,
            transition: "all 2s cubic-bezier(0.21, 1.26, 0.21, 1.26) 0s",
            marginTop: 10,
            marginRight: 10
        },
        _icon: {
            fontSize: 45,
            color: "#a1bb5b"
        },
        _label_title: {
            color: "#6068a9",
            fontSize: 20,
            fontWeight: 600,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        _label_desc: {
            color: "#44486b",
            fontSize: 13,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        _icon_close: {
            color: '#888888',
            cursor: 'pointer'
        }
    }

    const addEventListeners = () => {
        window.addEventListener("message", function (e) {
            if (e.data && e.data.message && e.data.message === 'transmissionNotification' && e.data.notification) {
                executeNotification(e.data.notification);
            }
        });
    }

    const executeNotification = (notificationProp) => {
        setNotificationDefault(notificationProp);

        let _notification = substringMessages(notificationProp);

        setNotification(_notification);
        if (
            _notification.command === 'quiz' ||
            _notification.command === 'openConfig' ||
            _notification.command === 'openSchedule'
        ) {
            setEnableButton(true);
            getButtonStyle(_notification);
        } else {
            setOpenMessage(true);
        }
    }

    const substringMessages = (notificationProp) => {
        let _notification = { ...notificationProp };

        if (_notification.text.length > 26) {
            _notification.text = _notification.text.substring(0, 26) + '...';
        }

        if (_notification.text2.length > 40) {
            _notification.text2 = _notification.text2.substring(0, 40) + '...';
        }

        return _notification;
    }

    const getButtonStyle = (notificationProp) => {
        if (notificationProp.style) {
            const _styleProp = notificationProp.style;
            var _style = configButton;
            _style.colorButton = _styleProp.colorButton1 !== '' ? _styleProp.colorButton1 : configButton.colorButton;
            _style.textColorButton = _styleProp.textColorButton1 !== '' ? _styleProp.textColorButton1 : configButton.textColorButton;
            _style.text = _styleProp.textButton1 !== '' ? _styleProp.textButton1 : configButton.text;
            setConfigButton(_style);
        }
        setOpenMessage(true);
    }

    const reopenQueue = () => {
        window.postMessage({
            message: 'pushNotificationReopenQueue',
        }, '*');
        clearConfig();
    }

    const closeNotification = () => {
        window.postMessage({
            message: 'pushNotificationV2CloseNotification',
            notification: notificationDefault
        }, '*');
        clearConfig();
    }

    const closeNotificationAndExecuteNotification = () => {
        if (notification.command === 'quiz') {
            window.postMessage({
                message: 'pushNotificationV2ExecuteQuiz',
                notification: notificationDefault
            }, '*');
        }
        else if (notification.command === 'openConfig') {
            window.postMessage({
                message: 'pushNotificationV2OpenConfig',
                notification: notificationDefault
            }, '*');
        }
        else if (notification.command === 'openSchedule') {
            window.postMessage({
                message: 'pushNotificationV2OpenSchedule',
                notification: notificationDefault
            }, '*');
        } else {
            closeNotification();
        }

        clearConfig();
    }

    const clearConfig = () => {
        setOpenMessage(false);
        setEnableButton(false);
        setNotification(null);
        setConfigButton(defaultConfigButton);
    }

    if (!openMessage) {
        return (<div></div>);
    }
    return (
        <div
            class="parent parent-notifi container"
            style={
                {
                    ...styles._container,
                    right: openMessage ? 0 : -5000
                }
            }
        >
            <FormRow margin="0px" >
                <FormColumn margin="0px" width="50px" overflow="hidden">
                    <i class="fas fa-poll-h" style={styles._icon}></i>
                </FormColumn>
                <FormColumn margin="0px" width="300px" overflow="hidden">
                    <p style={styles._label_title}>{notification.text}</p>
                    <p style={styles._label_desc}>{notification.text2}</p>
                </FormColumn>
                <FormColumn margin="0px" width="100px" overflow="hidden" alignItems="flex-end">
                    {enableButton && notification.command === 'quiz' ?
                        <Button
                            borderRadius={10}
                            color={configButton.colorButton}
                            textColor={configButton.textColorButton}
                            onClick={() => closeNotificationAndExecuteNotification()}
                        >
                            {configButton.text}
                        </Button>
                        :
                        <i
                            class="far fa-times-circle"
                            style={styles._icon_close}
                            onClick={() => closeNotificationAndExecuteNotification()}
                        />
                    }
                </FormColumn>
            </FormRow>
        </div >
    )
};

export default TransmissionNotification;
