import React from "react";
import { connect } from "react-redux";
import { getDic } from "../assets/i18n/dictionary";
import { insertNewAccess, getSystemActions } from "../services/accessService";
import { getDate } from '../services/apiService';
import { getQuestionsApi } from '../services/questionsService';
import { getGroupListApi } from '../services/attributesService';
import {
  getNotificationsByEventAdmin,
  inactiveNotificationById,
  addNewNotification,
  addNewNotificationV2Firebase,
  getNotificationsV2Firebase,
  getScheduledNotificationsV2Firebase,
  updateScheduledNotificationV2Firebase,
  deleteScheduledNotificationV2Firebase,
  deleteNotificationV2Firebase,
  inactiveNotificationV2Firebase,
  getTrashNotificationsV2Firebase
} from "../services/pushNotificationService";
import { createFormControlModel } from "../models/formControlModel";
import {
  convertPushV2ObjectToForm,
  convertFormToPushV2Object,
  createPushObjectV2,
  getServerDateOfPushV2,
} from "../models/pushNotificationModel";
import { validateForm } from "../utils";
import moment from "moment";
import CalendarIcon from '@material-ui/icons/CalendarToday';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import SendIcon from '@material-ui/icons/Send';
import CopyIcon from '@material-ui/icons/FileCopy';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//Imports de components
import {
  MenuButton,
  BackButton,
  Container,
  FormRow,
  InputFieldOutlined,
  GridCell,
  GridRow,
  TextMedium,
  Button,
  FormColumn,
  Checkbox,
  Alert,
  Loading,
  Modal,
  TextBig,
  TextSmall,
  PushNotificationV2,
  SelectList,
  Dialog,
  ActivityIndicator
} from "../components";

const PushNotificationPage = (props) => {
  const [state, setState] = React.useState(true);
  const [selectAll, setSelectAll] = React.useState(false);
  const [pushNotificationsList, setPushNotificationsList] = React.useState([]);
  const [pushNotificationsV2List, setPushNotificationsV2List] = React.useState([]);
  const [scheduledPushNotificationsV2List, setScheduledPushNotificationsV2List] = React.useState([]);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [focused, setFocused] = React.useState(false);
  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(
    getDic("carregando")
  );
  const [openLoading, setOpenLoading] = React.useState(true);

  //Dialog control
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [dialogConfirmKey, setDialogConfirmKey] = React.useState("");
  const [dialogConfirmKeyErrorMessage, setDialogConfirmKeyErrorMessage] = React.useState("");
  const [chosenPushV2, setChosenPushV2] = React.useState(null);
  const [dialogSendPushV2, setDialogSendPushV2] = React.useState(false);

  //Modal Control
  const [openModalNewNotification, setOpenModalNewNotification] = React.useState(getDic(false));
  const [openModalNewNotificationV2, setOpenModalNewNotificationV2] = React.useState(getDic(false));
  const [modalTrashControl, setModalTrashControl] = React.useState({
    open: false,
    isLoading: true,
    trashData: []
  });
  const [groupList, setGroupList] = React.useState([]);
  const [questionsList, setQuestionsList] = React.useState([]);
  const [questionsCompleteList, setQuestionsCompleteList] = React.useState([]);
  const [questionsCategoriesList, setQuestionsCategoriesList] = React.useState([]);
  const [questionsCategoriesListSelected, setQuestionsCategoriesListSelected] = React.useState(null);
  const [confirmationWord, setConfirmationWord] = React.useState('');

  const [testNotification, setTestNotification] = React.useState('');

  //Monta formulário de cadastro
  const form = [];
  const text = createFormControlModel(
    0,
    getDic("mensagem"),
    "",
    "text",
    null,
    null,
    null,
    true
  );
  form.push(text);
  const date = createFormControlModel(
    1,
    getDic("data"),
    "",
    "datetime-local",
    null,
    null,
    null,
    true
  );
  form.push(date);
  const [newNotification, setNewNotification] = React.useState(form);
  const [newNotificationV2Form, setNewNotificationV2Form] = React.useState([]);
  const [tabIndex, setTabIndex] = React.useState(1);

  React.useEffect(() => {
    //Bloqueio caso o usuário não seja admin
    if (!props.user.isAdmin) {
      props.history.goBack();
    }

    getPageData();
    getNotifications();

    insertNewAccess(props.match.url, "", getSystemActions().accessPage);

    return () => {
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    };
  }, []);

  const getPageData = async () => {
    try {
      let _groups = await getGroupListApi();
      setGroupList(_groups);

      let _res = await getQuestionsApi(props.event.id);
      const _questions = [];
      const _categories = [{
        text: getDic("todos"),
        value: null
      }];
      _res.questions.map(quest => {
        let _question = {
          text: `${quest.id} ${quest.question}`,
          value: quest.id,
          category: quest.category
        }
        _questions.push(_question);

        let _cat = _categories.find(c => { return c.value === quest.category });
        if (!_cat) {
          let _category = {
            text: quest.category,
            value: quest.category
          }
          _categories.push(_category);
        }

      });
      setQuestionsList(_questions);
      setQuestionsCompleteList(_res.questions);
      setQuestionsCategoriesList(_categories);

    } catch (error) {
      console.log("Error getPageData", error);
    }

    getNotificationsV2();
  }

  const getNotifications = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("carregando"));

    getNotificationsByEventAdmin()
      .then((res) => {
        setPushNotificationsList(res);
      })
      .catch((err) => {
        console.log("Erro getNotificationsByEvent", err);
      })
      .finally(() => {
        setOpenLoading(false);
      });
  };

  const changeNotificationActive = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("salvando"));

    var notifications = pushNotificationsList.filter((item) => {
      return item.selected === true;
    });

    if (notifications.length > 0) {
      var count = 0;
      var hasError = false;
      notifications.forEach((notification) => {
        if (hasError) {
          return;
        } else {
          inactiveNotificationById(notification.id)
            .then(() => {
              count++;
              if (notifications.length === count) {
                var change = pushNotificationsList;
                change.forEach((item, i) => {
                  if (change[i].selected) {
                    change[i].active = false;
                  }
                  change[i].selected = false;
                });
                setState(state === true ? false : true);
                setPushNotificationsList(change);
                setSelectAll(false);

                setOpenLoading(false);
              }
            })
            .catch((err) => {
              hasError = true;

              setAlertOpen(true);
              setAlertTitle(getDic("erro"));
              setAlertMessage(getDic("enviado-erro"));
              setOpenLoading(false);
            });
        }
      });
    } else {
      setOpenLoading(false);
    }
  };

  const insertNewNotification = () => {
    var validation = validateForm(newNotification);

    if (validation.isValid) {
      setOpenLoading(true);
      setLoadingMessage(getDic("salvando"));

      addNewNotification(newNotification[0].value, newNotification[1].value)
        .then((res) => {
          resetForm();
          setOpenModalNewNotification(false);

          const _newList = pushNotificationsList;
          _newList.push(res);
          setPushNotificationsList(_newList);

          setAlertOpen(true);
          setAlertTitle("");
          setAlertMessage(getDic("enviado-sucesso"));
        })
        .catch((err) => {
          setAlertOpen(true);
          setAlertTitle(getDic("erro"));
          setAlertMessage(getDic("enviado-erro"));
        })
        .finally(() => {
          setOpenLoading(false);
        });
    } else {
      setNewNotification(validation.form);
    }
  };

  const resetForm = () => {
    setNewNotification(form);
  };

  const onChangeHandler = (index, value) => {
    var data = newNotification;
    data[index].value = value;
    setNewNotification(data);
    setState(state === true ? false : true);
  };

  const onKeyPressHandler = (value) => {
    if (value === "Enter") {
      insertNewNotification();
    }
  };

  const onFocusHandler = (value) => {
    setFocused(!focused);
  };

  const getNotificationsV2 = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("carregando"));

    getNotificationsV2Firebase()
      .then((res) => {
        setPushNotificationsV2List(res);
      })
      .catch((err) => {
        console.log("Erro getNotificationsByEvent", err);
      })
      .finally(() => {
        setOpenLoading(false);
      });

    getScheduledNotificationsV2Firebase()
      .then((res) => {
        setScheduledPushNotificationsV2List(res);
      })
      .catch((err) => {
        console.log("Erro getScheduledNotificationsV2Firebase", err);
      });
  }

  const onChangeHandlerV2 = (index, value) => {
    var _newForm = newNotificationV2Form;
    _newForm[index].value = value;
    setNewNotificationV2Form(_newForm);
    setState(state === true ? false : true);
  }

  const resetPushV2Form = () => {
    const _newPush = createPushObjectV2();
    const _newForm = convertPushV2ObjectToForm(_newPush, groupList, questionsList);
    setNewNotificationV2Form(_newForm);
  }

  const createNewPushV2 = () => {
    const _newPush = createPushObjectV2();
    const _newForm = convertPushV2ObjectToForm(_newPush, groupList, questionsList);
    setNewNotificationV2Form(_newForm);
    setOpenModalNewNotificationV2(true);
  }

  const editPushV2 = (pushV2) => {
    const _newForm = convertPushV2ObjectToForm(pushV2, groupList, questionsList);
    setNewNotificationV2Form(_newForm);
    setOpenModalNewNotificationV2(true);
  }

  const prepareToSavePushV2 = () => {
    const _command = newNotificationV2Form.find(f => { return f.dbReference === 'command' }).value;
    var _newNotificationV2Form = newNotificationV2Form;
    if (_command === 'forceRefresh') {
      const _textIndex = newNotificationV2Form.findIndex(f => { return f.dbReference === 'text' });
      _newNotificationV2Form[_textIndex].value = _newNotificationV2Form[_textIndex].value ? _newNotificationV2Form[_textIndex].value : '#'
    }
    var validation = validateForm(_newNotificationV2Form);

    if (_command === 'quiz') {
      const _questions = newNotificationV2Form.find(f => { return f.dbReference === 'questions' });
      if (!_questions.value || _questions.value.length <= 0) {
        validation.isValid = false;
        validation.form.find(f => { return f.dbReference === 'questions' }).error = true;
        validation.form.find(f => { return f.dbReference === 'questions' }).errorMessage = getDic("preenchimento-obrigatorio");
      }
    }

    if (validation.isValid) {
      setQuestionsCategoriesListSelected(null);
      var _newPushV2 = convertFormToPushV2Object(_newNotificationV2Form);
      const _currentDate = moment().format();
      if (_newPushV2.date <= _currentDate) {
        setDialogSendPushV2(true);
      } else {
        savePushV2();
      }
    }
    else {
      setNewNotificationV2Form(validation.form);
    }
  }

  const savePushV2 = async () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("salvando"));

    const _command = newNotificationV2Form.find(f => { return f.dbReference === 'command' }).value;
    var _newNotificationV2Form = newNotificationV2Form;
    if (_command === 'forceRefresh') {
      const _textIndex = newNotificationV2Form.findIndex(f => { return f.dbReference === 'text' });
      _newNotificationV2Form[_textIndex].value = _newNotificationV2Form[_textIndex].value ? _newNotificationV2Form[_textIndex].value : '#'
    }

    var _newPushV2 = convertFormToPushV2Object(_newNotificationV2Form);
    _newPushV2 = addQuestionsObjectToPushV2(_newPushV2);

    if (_newPushV2.id && _newPushV2.id !== '') {
      updateScheduledNotificationV2Firebase(_newPushV2)
        .then(async res => {
          setAlertOpen(true);
          setAlertTitle("");
          setAlertMessage(getDic("enviado-sucesso"));
          resetPushV2Form();
          setOpenModalNewNotificationV2(false);

          const _newList = scheduledPushNotificationsV2List.map(p => {
            if (p.id === res.id) {
              return res;
            }
            return p;
          });

          _newList.sort(function (a, b) {
            return a.date > b.date
              ? 1
              : b.date > a.date
                ? -1
                : 0;
          });
          setScheduledPushNotificationsV2List(_newList);
        })
        .catch(err => {
          console.log('Erro savePushV2', err);
          setAlertOpen(true);
          setAlertTitle(getDic("erro"));
          setAlertMessage(getDic("enviado-erro"));
        })
        .finally(() => {
          setOpenLoading(false);
        });
    }
    else {
      try {
        let res = await addNewNotificationV2Firebase(_newPushV2);

        setAlertOpen(true);
        setAlertTitle("");
        setAlertMessage(getDic("enviado-sucesso"));
        resetPushV2Form();
        setOpenModalNewNotificationV2(false);

        const _currentDate = await getDate();
        if (res.date > _currentDate) {
          const _newList = scheduledPushNotificationsV2List;
          _newList.push(res);
          _newList.sort(function (a, b) {
            return a.date > b.date
              ? 1
              : b.date > a.date
                ? -1
                : 0;
          });
          setScheduledPushNotificationsV2List(_newList);
        }
        else {
          let _pushNotificationsV2List = await inactiveNotificationsAndReturnList(res.page);

          const _newList = _pushNotificationsV2List;
          _newList.push(res);
          _newList.sort(function (a, b) {
            return b.date > a.date
              ? 1
              : a.date > b.date
                ? -1
                : 0;
          });
          setPushNotificationsV2List(_newList);
        }

        setOpenLoading(false);
      } catch (error) {
        console.log('Erro savePushV2', error);
        setOpenLoading(false);
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
      }
    }

    function addQuestionsObjectToPushV2(pushV2) {
      let _questions = [];
      if (pushV2.questions.length > 0) {
        pushV2.questions.forEach(questionId => {
          let _question = questionsCompleteList.find(q => { return q.id === questionId });
          if (_question) {
            _questions.push(_question);
          }
        });
      }
      pushV2.questionsObject = _questions;
      return pushV2;
    }
  }

  async function inactiveNotificationsAndReturnList(page = '') {
    try {
      if (pushNotificationsV2List.length <= 0) { return [] };

      let _pushNotificationsV2List = [];
      var _count = 0;
      do {
        let _item = pushNotificationsV2List[_count];

        if (page && _item.active && _item.page && _item.page.toString() === page.toString()) {
          _item.active = false;
          await inactiveNotificationV2Firebase(_item);
        }

        _pushNotificationsV2List.push(_item);
        _count++;
      } while (_count < pushNotificationsV2List.length);

      return _pushNotificationsV2List;
    } catch (error) {
      console.log('Error inactiveNotifications', error);
      throw error;
    }
  }

  const showExamplePushV2 = () => {
    var validation = validateForm(newNotificationV2Form);
    if (validation.isValid) {
      var _newPushV2 = convertFormToPushV2Object(newNotificationV2Form);
      setTestNotification(_newPushV2);
    }
    else {
      setNewNotificationV2Form(validation.form);
    }
  }

  const deletePushV2 = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("salvando"));

    const isScheduled = scheduledPushNotificationsV2List.find(n => n.id === chosenPushV2.id);

    if (isScheduled) {
      deleteScheduledNotificationV2Firebase(chosenPushV2)
        .then(res => {
          const _newList = [];
          scheduledPushNotificationsV2List.forEach(item => {
            if (item.id !== chosenPushV2.id) {
              _newList.push(item);
            }
          });

          _newList.sort(function (a, b) {
            return a.date > b.date
              ? 1
              : b.date > a.date
                ? -1
                : 0;
          });

          setScheduledPushNotificationsV2List(_newList);
          setAlertOpen(true);
          setAlertTitle("");
          setAlertMessage(getDic("enviado-sucesso"));
        })
        .catch(err => {
          setAlertOpen(true);
          setAlertTitle(getDic("erro"));
          setAlertMessage(getDic("enviado-erro"));
          setOpenLoading(false);
        })
        .finally(() => {
          setOpenLoading(false);
        })
    }
    else {
      deleteNotificationV2Firebase(chosenPushV2)
        .then(res => {
          const _newList = [];
          pushNotificationsV2List.forEach(item => {
            if (item.id !== chosenPushV2.id) {
              _newList.push(item);
            }
          });

          _newList.sort(function (a, b) {
            return a.date > b.date
              ? 1
              : b.date > a.date
                ? -1
                : 0;
          });

          setPushNotificationsV2List(_newList);
          setAlertOpen(true);
          setAlertTitle("");
          setAlertMessage(getDic("enviado-sucesso"));
        })
        .catch(err => {
          setAlertOpen(true);
          setAlertTitle(getDic("erro"));
          setAlertMessage(getDic("enviado-erro"));
          setOpenLoading(false);
        })
        .finally(() => {
          setOpenLoading(false);
        })
    }
  }

  const sendScheduledPushV2Now = async () => {
    try {
      setOpenLoading(true);
      setLoadingMessage(getDic("salvando"));

      var _newPushV2 = chosenPushV2;
      _newPushV2.date = await getDate();
      let res = await addNewNotificationV2Firebase(_newPushV2, _newPushV2.id);
      const _newList = await inactiveNotificationsAndReturnList(_newPushV2.page);
      _newList.push(res);
      _newList.sort(function (a, b) {
        return b.date > a.date
          ? 1
          : a.date > b.date
            ? -1
            : 0;
      });
      setPushNotificationsV2List(_newList);

      await deleteScheduledNotificationV2Firebase(_newPushV2);

      const _newList2 = [];
      scheduledPushNotificationsV2List.forEach(item => {
        if (item.id !== _newPushV2.id) {
          _newList2.push(item);
        }
      });

      _newList2.sort(function (a, b) {
        return a.date > b.date
          ? 1
          : b.date > a.date
            ? -1
            : 0;
      });

      setScheduledPushNotificationsV2List(_newList2);
      setAlertOpen(true);
      setAlertTitle("");
      setAlertMessage(getDic("enviado-sucesso"));
      setOpenLoading(false);
    } catch (error) {
      console.log("Error sendScheduledPushV2Now", error);
      setAlertOpen(true);
      setAlertTitle(getDic("erro"));
      setAlertMessage(getDic("enviado-erro"));
      setOpenLoading(false);
    }
  }

  const copyPushV2 = (pushV2) => {
    const _style = {
      colorButton1: pushV2.style.colorButton1,
      textColorButton1: pushV2.style.textColorButton1,
      textButton1: pushV2.style.textButton1,
    };

    const _newPushV2 = createPushObjectV2(
      pushV2.version,
      pushV2.id,
      pushV2.text,
      pushV2.text2,
      pushV2.date,
      pushV2.command,
      pushV2.page,
      pushV2.openConfigId,
      pushV2.openScheduleId,
      pushV2.replaceOpenConfig,
      pushV2.groups,
      pushV2.configs,
      pushV2.schedules,
      pushV2.questions,
      _style,
      pushV2.questionsObject,
      true
    );
    var _newForm = convertPushV2ObjectToForm(_newPushV2, groupList, questionsList);
    const _idIndex = _newForm.findIndex(f => f.dbReference === 'id');
    const _dateIndex = _newForm.findIndex(f => f.dbReference === 'date');
    _newForm[_idIndex].value = '';
    _newForm[_dateIndex].value = null;
    setNewNotificationV2Form(_newForm);
    setOpenModalNewNotificationV2(true);
  }

  const loadTrashData = async () => {
    try {
      let _trashData = await getTrashNotificationsV2Firebase();

      setModalTrashControl({ ...modalTrashControl, trashData: _trashData, isLoading: false, open: true });
    } catch (error) {
      console.log('Erro savePushV2', error);
      setAlertOpen(true);
      setAlertTitle(getDic("erro"));
      setAlertMessage(getDic("enviado-erro"));
    }
  }

  return (
    <Container background="#e8eced" align="center">
      {renderNavigationButtons()}

      <table style={{ width: "100%" }}>
        {renderTabsButtons()}
        <tr>
          <div
            role="tabpanel"
            hidden={tabIndex === 1 ? false : true}
          >
            <FormColumn align="center" margin="0px">
              {renderCommandButtons()}
              <FormRow margin="0px">
                <FormColumn align="center" margin="0px">
                  {renderSentMessagesGrid()}
                  {renderScheduledMessagesGrid()}
                </FormColumn>
              </FormRow>
            </FormColumn>
          </div>
        </tr>
      </table>

      {renderModalNewNotification()}

      {renderModalNewNotificationV2()}

      <PushNotificationV2
        isExample
        notification={testNotification}
        pushExecuted={() => {
          setTestNotification(null);
        }}
      />

      {renderDialogConfirmAction()}

      {renderDialogSendPushConfirmation()}
      {renderModalTrashPush()}

      <Alert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={() => setAlertOpen(false)}
        title={alertTitle}
        message={alertMessage}
      />

      <Loading open={openLoading} message={loadingMessage} />
    </Container>
  );

  function renderNavigationButtons() {
    return (
      <>
        <MenuButton history={props.history} />
        <BackButton history={props.history} color="#212121">
          {getDic("voltar").toUpperCase()}
        </BackButton>
      </>
    )
  }

  function renderTabsButtons() {
    return (
      <tr>
        <FormRow
          minHeight="60px"
          height="5vh"
          align="center"
          background="#e8eced"
          margin="0px"
        />
        <Tabs
          value={tabIndex}
          aria-label="simple tabs example"
        >
          {/* <Tab label="PUSH V1" onClick={() => setTabIndex(0)} /> */}
          <Tab label="PUSH V2" onClick={() => setTabIndex(1)} />
        </Tabs>
      </tr>
    )
  }

  function renderCommandButtons() {
    return (
      <FormRow
        minHeight="50px"
        height="5vh"
        align="center"
        margin="0px"
      >
        <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
          <Button
            color="green"
            onClick={() => {
              createNewPushV2();
            }}
          >
            {`${getDic("cadastrar")} ${getDic("novo")}`}
          </Button>
        </div>
        <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
          <Button
            onClick={() => {
              setModalTrashControl({ ...modalTrashControl, open: true, isLoading: true, });
              loadTrashData();
            }}
          >
            {`${getDic("consultar")} Pushs ${getDic("deletados")}`}
          </Button>
        </div>
      </FormRow>
    )
  }

  function renderSentMessagesGrid() {
    return (
      <div
        id="messagesV2"
        style={{
          height: "300px",
          backgroundColor: "transparent",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "5px",
            backgroundColor: "transparent",
            height: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <TextMedium fontWeight="bold">
            {getDic("enviado")}
          </TextMedium>
          <GridRow backgroundColor="#d9d9d9">
            <GridCell width="15%" border top first>
              <TextMedium>{getDic("data").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="15%" border top>
              <TextMedium>{getDic("mensagem").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="30%" border top>
              <TextMedium>{getDic("perguntas").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="20%" border top>
              <TextMedium>{getDic("categoria").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="10%" border top>
              <TextMedium>{getDic("comando").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="5%" border top>
              <TextMedium>ID</TextMedium>
            </GridCell>
            <GridCell width="5%" border top>
              <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
            </GridCell>
          </GridRow>

          {pushNotificationsV2List.length > 0 ? (
            pushNotificationsV2List.map((message, i) => {
              return (
                <GridRow
                  key={message.id}
                  autoColor={i}
                >
                  <GridCell width="15%" border first>
                    <TextMedium>
                      {moment(message.date).format(
                        "DD/MM/YYYY, HH:mm"
                      )}
                    </TextMedium>
                  </GridCell>
                  <GridCell width="15%" border>
                    <TextMedium>{message.text}</TextMedium>
                  </GridCell>
                  <GridCell width="30%" border>
                    <TextMedium>
                      {message.questions.length > 0 && (
                        message.questions.map((q, i) => {
                          const _question = questionsList.find(qst => { return parseInt(qst.value) === parseInt(q) });
                          if (!_question) { return (<></>) }
                          if (i > 0) {
                            return (<>, <b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.text}</>);
                          } else {
                            return (<><b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.text}</>);
                          }
                        })
                      )}
                    </TextMedium>
                  </GridCell>
                  <GridCell width="20%" border>
                    <TextMedium>
                      {message.questions.length > 0 && (
                        message.questions.map((q, i) => {
                          const _question = questionsList.find(qst => { return parseInt(qst.value) === parseInt(q) });
                          if (!_question) { return (<></>) }
                          if (i > 0) {
                            return (<>, <b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.category}</>);
                          } else {
                            return (<><b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.category}</>);
                          }
                        })
                      )}
                    </TextMedium>
                  </GridCell>
                  <GridCell width="10%" border>
                    <TextMedium>{message.command}</TextMedium>
                  </GridCell>
                  <GridCell width="5%" border>
                    <TextMedium>{message.id}</TextMedium>
                  </GridCell>
                  <GridCell width="5%" border>
                    <CopyIcon onClick={() => copyPushV2(message)} style={{ cursor: "pointer" }} />
                    <DeleteIcon
                      onClick={() => {
                        setChosenPushV2(message);
                        setConfirmationWord('deletar');
                        setDialogConfirmOpen(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </GridCell>
                </GridRow>
              );
            })
          ) : (
            <TextMedium>{getDic("mensagens-nao-localizadas")}</TextMedium>
          )}
        </div>
      </div>
    )
  }

  function renderScheduledMessagesGrid() {
    return (
      <div
        id="scheduledMessagesV2"
        style={{
          height: "300px",
          backgroundColor: "transparent",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "5px",
            backgroundColor: "transparent",
            height: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <TextMedium fontWeight="bold">
            {getDic("programado")}
          </TextMedium>
          <GridRow backgroundColor="#d9d9d9">
            <GridCell width="15%" border top first>
              <TextMedium>{getDic("data").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="15%" border top>
              <TextMedium>{getDic("mensagem").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="30%" border top>
              <TextMedium>{getDic("perguntas").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="20%" border top>
              <TextMedium>{getDic("categoria").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="10%" border top>
              <TextMedium>{getDic("comando").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="5%" border top>
              <TextMedium>ID</TextMedium>
            </GridCell>
            <GridCell width="5%" border top>
              <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
            </GridCell>
          </GridRow>

          {scheduledPushNotificationsV2List.length > 0 ? (
            scheduledPushNotificationsV2List.map((message, i) => {
              return (
                <GridRow
                  key={message.id}
                  autoColor={i}
                >
                  <GridCell width="15%" border first>
                    <TextMedium>
                      {moment(message.date).format(
                        "DD/MM/YYYY, HH:mm"
                      )}
                    </TextMedium>
                  </GridCell>
                  <GridCell width="15%" border>
                    <TextMedium>{message.text}</TextMedium>
                  </GridCell>
                  <GridCell width="30%" border>
                    <TextMedium>
                      {message.questions.length > 0 && (
                        message.questions.map((q, i) => {
                          const _question = questionsList.find(qst => { return parseInt(qst.value) === parseInt(q) });
                          if (!_question) { return (<></>) }
                          if (i > 0) {
                            return (<>, <b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.text}</>);
                          } else {
                            return (<><b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.text}</>);
                          }
                        })
                      )}
                    </TextMedium>
                  </GridCell>
                  <GridCell width="20%" border>
                    <TextMedium>
                      {message.questions.length > 0 && (
                        message.questions.map((q, i) => {
                          const _question = questionsList.find(qst => { return parseInt(qst.value) === parseInt(q) });
                          if (!_question) { return (<></>) }
                          if (i > 0) {
                            return (<>, <b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.category}</>);
                          } else {
                            return (<><b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.category}</>);
                          }
                        })
                      )}
                    </TextMedium>
                  </GridCell>
                  <GridCell width="10%" border>
                    <TextMedium>{message.command}</TextMedium>
                  </GridCell>
                  <GridCell width="5%" border>
                    <TextMedium>{message.id}</TextMedium>
                  </GridCell>
                  <GridCell width="5%" border>
                    <CopyIcon onClick={() => copyPushV2(message)} style={{ cursor: "pointer" }} />
                    <EditIcon onClick={() => editPushV2(message)} style={{ cursor: "pointer" }} />
                    <DeleteIcon
                      onClick={() => {
                        setChosenPushV2(message);
                        setConfirmationWord('deletar');
                        setDialogConfirmOpen(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <SendIcon
                      onClick={() => {
                        setChosenPushV2(message);
                        setConfirmationWord('enviar');
                        setDialogConfirmOpen(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </GridCell>
                </GridRow>
              );
            })
          ) : (
            <TextMedium>{getDic("mensagens-nao-localizadas")}</TextMedium>
          )}
        </div>
      </div>
    )
  }

  function renderModalNewNotification() {
    return (
      <Modal
        isVideo
        height="100%"
        open={openModalNewNotification}
        closeButtonClick={(e) => setOpenModalNewNotification(false)}
        onClose={(ret) => {
          setOpenModalNewNotification(false);
        }}
      >
        <Container background="#e8eced" align="left" height="100%">
          <FormColumn>
            <FormRow align="center" margin="0px">
              <TextBig>{getDic("novo")}</TextBig>
            </FormRow>
            <FormRow align="center" margin="0px" paddingTop="5px">
              <div
                style={{
                  width: "100%",
                  maxWidth: "500px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <InputFieldOutlined
                  key={newNotification[0].id}
                  type={newNotification[0].type}
                  value={newNotification[0].value}
                  title={newNotification[0].name}
                  placeholder={newNotification[0].name}
                  required={newNotification[0].required}
                  error={newNotification[0].error}
                  errorMessage={newNotification[0].errorMessage}
                  onChange={(e) => onChangeHandler(0, e.target.value)}
                  onKeyPress={(event) => onKeyPressHandler(event.key)}
                />
              </div>
            </FormRow>
            <FormRow align="center" margin="0px" paddingTop="5px">
              <div
                style={{
                  width: "100%",
                  maxWidth: "429px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  display: "-ms-flexbox",
                  display: "flex",
                }}
              >
                {/* <div style={{ flex: 1 }} class="date-form">
                  <DateTime
                    inputProps={{ placeholder: "dd/mm/aaaa --:--" }}
                    dateFormat="DD/MM/YYYY"
                    onChange={(e) => onChangeHandlerData(1, e)}
                  />
                </div>
                <i
                  class="fa fa-calendar"
                  aria-hidden="true"
                  style={{
                    zIndex: 9,
                    fontSize: 23,
                    color: "#757575",
                    position: "relative",
                    left: -33,
                    top: 17,
                  }}
                ></i> */}
                <InputFieldOutlined
                  key={newNotification[1].id}
                  type={newNotification[1].type}
                  value={newNotification[1].value}
                  title={focused ? newNotification[1].name : ""}
                  placeholder={newNotification[1].name}
                  required={newNotification[1].required}
                  error={newNotification[1].error}
                  errorMessage={newNotification[1].errorMessage}
                  onChange={e => onChangeHandler(1, e.target.value)}
                  onKeyPress={event => onKeyPressHandler(event.key)}
                  onBlur={event => onFocusHandler(event)}
                  onFocus={event => onFocusHandler(event)}
                  endAdornment={<CalendarIcon />}
                />
              </div>
              <Button
                text={getDic("enviar")}
                onClick={() => insertNewNotification()}
              />
            </FormRow>
            <FormRow align="center">
              <TextSmall>
                {
                  "Comando para forçar refresh para os usuários: ${FORCE-REFRESH}"
                }
              </TextSmall>
            </FormRow>
            <FormRow align="center">
              <TextSmall>
                {
                  "Comando para inserir Quiz: ${QUIZ[IdPergunta,idPergunta...];TITLE:Titulo do quiz}"
                }
              </TextSmall>
            </FormRow>
            <FormRow align="center">
              <TextSmall>
                {
                  "Caso queira que um Quiz apareça apenas se uma configuração ou programação específica estiver aberta:"
                }
              </TextSmall>
              <TextSmall>
                {
                  "Opção 1: ${QUIZ[IdPergunta,idPergunta...];TITLE:Titulo do quiz;CONFIG[idConfig, idConfig...]}"
                }
              </TextSmall>
              <TextSmall>
                {
                  "Opção 2: ${QUIZ[IdPergunta,idPergunta...];TITLE:Titulo do quiz;SCHEDULE[idProgramacao, idProgramacao...]}"
                }
              </TextSmall>
            </FormRow>
            <FormRow align="center">
              <TextSmall>
                {
                  "Comando para sugerir a abertura de uma config caso o usuário não esteja nela: ${OPENCONFIG[idConfig];TITLE:Veja este conteúdo}"
                }
              </TextSmall>
              <TextSmall>
                {
                  "Comando para sugerir a abertura de uma programação caso o usuário não esteja nela: ${OPENSCHEDULE[idSchedule];TITLE:Veja este conteúdo}"
                }
              </TextSmall>
            </FormRow>
            <FormRow align="center">
              <TextSmall>
                {
                  "Comando para abrir um modal arrastável e exibir um relatório de perguntas ou nuvem de palavras: ${OPENDRAGGABLEMODAL[questionId];TITLE:Veja este conteúdo;TYPE:[WordsCloud]}"
                }
              </TextSmall>
              <TextSmall>
                {
                  "Caso queira que o modal abra apenas para uma config ou programação, inserir o id da config ou programação: ${OPENDRAGGABLEMODAL[questionId];TITLE:Veja este conteúdo;SCHEDULE[idProgramacao, idProgramacao...];TYPE:[WordsCloud]} ou ${OPENDRAGGABLEMODAL[questionId];TITLE:Veja este conteúdo;CONFIG[idConfig, idConfig...];TYPE:[WordsCloud]}"
                }
              </TextSmall>
              <TextSmall>
                {
                  "Os tipos de relatório são Nuvem de Palavras 'WordsCloud', Pizza 'Pie' e Barras 'Bar'"
                }
              </TextSmall>
            </FormRow>
            <FormRow align="center">
              <TextSmall>
                {
                  "Para que a mensagem apareça apenas para o usuário de um ou mais grupos específicos, os grupos devem ser adicionados ao fim da mensagem na tag GROUPS. Exemplos: ${OPENSCHEDULE[idSchedule];TITLE:Veja este conteúdo;GROUPS[123,456]} ou ${QUIZ[IdPergunta,idPergunta...];TITLE:Titulo do quiz;CONFIG[idConfig, idConfig...];GROUPS[123,456]} ou ${FORCE-REFRESH;GROUPS[123]}"
                }
              </TextSmall>
            </FormRow>
            <FormRow align="center">
              <TextSmall>
                {
                  "Para abrir uma config na tela de transmissão, utilizar o seguinte push: ${TRANSMISSION1;TITLE:Minha mensagem;OPENCONFIG[854];STYLE[colorButton:blue,textColorButton:#ffffff]}. As configurações colorButton e textColorButton modificam as cores do botão de acesso, a configuração text altera o texto do botão."
                }
              </TextSmall>
            </FormRow>
          </FormColumn>
        </Container>
      </Modal>
    )
  }

  function renderModalNewNotificationV2() {
    const showFieldByCommand = (field) => {
      const _command = newNotificationV2Form.find(n => { return n.dbReference === 'command' }).value;
      var _show = false;

      switch (field.dbReference) {
        case "text":
          if (_command !== 'forceRefresh') {
            _show = true;
          }
          break;
        case "text2":
          if (_command !== 'forceRefresh') {
            _show = true;
          }
          break;
        case "openConfigId":
          if (_command === 'openConfig') {
            _show = true;
          }
          break;
        case "openScheduleId":
          if (_command === 'openSchedule') {
            _show = true;
          }
          break;
        case "replaceOpenConfig":
          if (_command === 'openSchedule' || _command === 'openConfig') {
            _show = true;
          }
          break;
        case "questions":
          if (_command === 'quiz') {
            _show = true;
          }
          break;
        case "colorButton1":
          if (_command === 'openSchedule' || _command === 'openConfig' || _command === 'quiz') {
            _show = true;
          }
          break;
        case "textColorButton1":
          if (_command === 'openSchedule' || _command === 'openConfig' || _command === 'quiz') {
            _show = true;
          }
          break;
        case "textButton1":
          if (_command === 'openSchedule' || _command === 'openConfig' || _command === 'quiz') {
            _show = true;
          }
          break;
        default:
          _show = true;
          break;
      }

      return _show;
    }

    return (
      <Modal
        isVideo
        height="80vh"
        open={openModalNewNotificationV2}
        closeButtonClick={(e) => {
          setOpenModalNewNotificationV2(false);
          setQuestionsCategoriesListSelected(null);
        }}
        onClose={(ret) => {
          setOpenModalNewNotificationV2(false);
          setQuestionsCategoriesListSelected(null);
        }}
      >
        <Container background="#e8eced" align="left" height="100%" >
          <FormColumn padding="5px" margin="0px" background="#e8eced">
            {newNotificationV2Form.map((item, i) => {
              if (!showFieldByCommand(item)) { return (<></>) }
              if (i === 0) {
                return (
                  <>
                    <FormRow key={item.id} align="center" paddingTop="7px">
                      <TextMedium>
                        {`${getDic("novo")} Push`}
                      </TextMedium>
                    </FormRow>
                  </>
                )
              }
              else {
                if (item.dbReference === "questions") {
                  let _filteredQuestions = item.values;
                  if (questionsCategoriesListSelected) {
                    _filteredQuestions = item.values.filter(q => { return q.category === questionsCategoriesListSelected });
                  }
                  return (
                    <>
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <SelectList
                          title={`${getDic("categoria")} ${getDic("para")} ${getDic("filtro")} ${getDic("de")} ${getDic("perguntas")}`}
                          width="100%"
                          value={questionsCategoriesListSelected}
                          menuItens={questionsCategoriesList}
                          onChange={async (event) => {
                            setQuestionsCategoriesListSelected(event.target.value);
                            onChangeHandlerV2(i, []);
                          }}
                        />
                      </FormRow>
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <SelectList
                          required={item.required}
                          error={item.error}
                          errorMessage={item.errorMessage}
                          title={item.name}
                          width="100%"
                          value={item.value}
                          menuItens={_filteredQuestions}
                          onChange={async (event) =>
                            onChangeHandlerV2(i, event.target.value)
                          }
                          multiple={Array.isArray(item.value) ? true : false}
                        />
                      </FormRow>
                    </>
                  )
                }

                if (item.type === "select" && item.values) {
                  return (
                    <FormRow key={item.id} align="center" paddingTop="7px">
                      <SelectList
                        required={item.required}
                        error={item.error}
                        errorMessage={item.errorMessage}
                        title={item.name}
                        width="100%"
                        value={item.value}
                        menuItens={item.values}
                        onChange={async (event) =>
                          onChangeHandlerV2(i, event.target.value)
                        }
                        multiple={Array.isArray(item.value) ? true : false}
                      />
                    </FormRow>
                  );
                }
                else {
                  return (
                    <FormRow key={item.id} align="center" paddingTop="7px">
                      <InputFieldOutlined
                        key={item.id}
                        required={item.required}
                        maxLength={item.maxLength}
                        error={item.error}
                        errorMessage={item.errorMessage}
                        value={item.value}
                        title={item.name}
                        type={item.type}
                        onChange={(event) => {
                          onChangeHandlerV2(i, event.target.value)
                        }}
                      />
                    </FormRow>
                  );
                }
              }
            })}
            <FormRow align="center" paddingTop="7px">
              <div style={{ padding: 5 }}>
                <Button
                  text={getDic("salvar")}
                  onClick={() => prepareToSavePushV2()}
                />
              </div>
              <div style={{ padding: 5 }}>
                <Button
                  color='green'
                  text={getDic("exemplo")}
                  onClick={() => showExamplePushV2()}
                />
              </div>
            </FormRow>
          </FormColumn>
        </Container>
      </Modal>
    )
  }

  function renderDialogConfirmAction() {
    return (
      <Dialog
        open={dialogConfirmOpen}
        onClose={() => setDialogConfirmOpen(false)}
        title={getDic("atencao")}
        onClickYes={() => {
          if (dialogConfirmKey && dialogConfirmKey != "" && dialogConfirmKey === confirmationWord) {
            if (confirmationWord === 'deletar') {
              deletePushV2();
            }
            if (confirmationWord === 'enviar') {
              sendScheduledPushV2Now();
            }

            setDialogConfirmOpen(false);
            setDialogConfirmKey("");
          }
          else {
            setDialogConfirmKeyErrorMessage(true);
            setTimeout(() => {
              setDialogConfirmKeyErrorMessage(false);
            }, 3000); // 1 minuto = 60000
          }
        }}
        onClickNo={() => {
          setDialogConfirmOpen(false);
          setDialogConfirmKey("");
        }}
      >
        <FormRow>
          <TextSmall>
            {`${getDic("confirmacao-de-acao-dinamica")} '${confirmationWord}'`}
          </TextSmall>
        </FormRow>
        <FormRow align="center" paddingTop="5px">
          <InputFieldOutlined
            key="password"
            value={dialogConfirmKey}
            onChange={event => setDialogConfirmKey(event.target.value)}
          />
        </FormRow>
        {dialogConfirmKeyErrorMessage ? (
          <TextSmall color="red">{getDic("erro")}</TextSmall>
        ) : (null)}
      </Dialog>
    )
  }

  function renderDialogSendPushConfirmation() {
    return (
      <Dialog
        open={dialogSendPushV2}
        onClose={() => setDialogSendPushV2(false)}
        title={getDic("atencao")}
        onClickYes={() => {
          savePushV2();
          setDialogSendPushV2(false);
        }}
        onClickNo={() => {
          setDialogSendPushV2(false);
        }}
      >
        <FormRow>
          <TextMedium>
            {getDic("confirmar-envio-push")}
          </TextMedium>
        </FormRow>
      </Dialog>
    )
  }

  function renderModalTrashPush() {
    return (
      <Modal
        isVideo
        height="100%"
        open={modalTrashControl.open}
        closeButtonClick={(e) => setModalTrashControl({ ...modalTrashControl, open: false })}
        onClose={(ret) => setModalTrashControl({ ...modalTrashControl, open: false })}
      >
        <Container background="#e8eced" align="left" height="100%">
          <FormColumn>
            <FormRow align="center" margin="0px">
              <TextMedium>Pushs {getDic("deletados")}</TextMedium>
            </FormRow>
            {modalTrashControl.isLoading ? (
              <FormRow align="center" margin="0px">
                <ActivityIndicator />
              </FormRow>
            ) : (
              renderTrashData()
            )}
          </FormColumn>
        </Container>
      </Modal>
    )

    function renderTrashData() {
      return (
        <div
          style={{
            padding: "5px",
            backgroundColor: "transparent",
            height: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <GridRow backgroundColor="#d9d9d9">
            <GridCell width="15%" border top first>
              <TextMedium>{getDic("data").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="15%" border top>
              <TextMedium>{getDic("mensagem").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="35%" border top>
              <TextMedium>{getDic("perguntas").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="20%" border top>
              <TextMedium>{getDic("categoria").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="10%" border top>
              <TextMedium>{getDic("comando").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="5%" border top>
              <TextMedium>ID</TextMedium>
            </GridCell>
          </GridRow>

          {modalTrashControl.trashData.length > 0 ? (
            modalTrashControl.trashData.map((message, i) => {
              return (
                <GridRow
                  key={message.id}
                  autoColor={i}
                >
                  <GridCell width="15%" border first>
                    <TextMedium>
                      {moment(message.date).format(
                        "DD/MM/YYYY, HH:mm"
                      )}
                    </TextMedium>
                  </GridCell>
                  <GridCell width="15%" border>
                    <TextMedium>{message.text}</TextMedium>
                  </GridCell>
                  <GridCell width="35%" border>
                    <TextMedium>
                      {message.questions.length > 0 && (
                        message.questions.map((q, i) => {
                          const _question = questionsList.find(qst => { return parseInt(qst.value) === parseInt(q) });
                          if (!_question) { return (<></>) }
                          if (i > 0) {
                            return (<>, <b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.text}</>);
                          } else {
                            return (<><b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.text}</>);
                          }
                        })
                      )}
                    </TextMedium>
                  </GridCell>
                  <GridCell width="20%" border>
                    <TextMedium>
                      {message.questions.length > 0 && (
                        message.questions.map((q, i) => {
                          const _question = questionsList.find(qst => { return parseInt(qst.value) === parseInt(q) });
                          if (!_question) { return (<></>) }
                          if (i > 0) {
                            return (<>, <b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.category}</>);
                          } else {
                            return (<><b>{`${getDic("pergunta").toUpperCase()} ${i + 1}`}</b>: {_question.category}</>);
                          }
                        })
                      )}
                    </TextMedium>
                  </GridCell>
                  <GridCell width="10%" border>
                    <TextMedium>{message.command}</TextMedium>
                  </GridCell>
                  <GridCell width="5%" border>
                    <TextMedium>{message.id}</TextMedium>
                  </GridCell>
                </GridRow>
              );
            })
          ) : (
            <TextMedium>{getDic("mensagens-nao-localizadas")}</TextMedium>
          )}
        </div>
      )
    }
  }
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(PushNotificationPage);
