export const IS_LOADING = 'IS_LOADING';
const isLoadingLocal = () => ({
    type: IS_LOADING,
});
export function isLoading() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(isLoadingLocal());
            resolve(true);
        });
    };
}

export const LOADING_COMPLETE = 'LOADING_COMPLETE';
const loadingCompleteLocal = () => ({
    type: LOADING_COMPLETE,
});
export function loadingComplete() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(loadingCompleteLocal());
            resolve(true);
        });
    };
}
