import React from 'react';
import { getDic } from "../../../assets/i18n/dictionary";
import {
  FormRow,
  TextSmall,
  Modal,
  Container,
  FormColumn,
  Button,
} from "../../../components";
import { useAudioRecorder } from '@baxibaba/react-audio-recorder';
import moment from 'moment';

const ModalRecordAudio = props => {
  const {
    modalRecordAudioControl,
    setModalRecordAudioControl,
    setTextarea,
    setFileToServer,
  } = props;

  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    status,
  } = useAudioRecorder();

  const saveData = React.useCallback(async () => {
    try {
      setTextarea(true);
      // let file = audioBufferToWav(audioResult)
      const res = await fetch(audioResult);
      const buf = await res.arrayBuffer();
      const file = new File([buf], `audio-feed-${moment().format()}.wav`, { type: 'audio/wav' });
      setFileToServer(file);
      setModalRecordAudioControl({ ...modalRecordAudioControl, open: false });

    } catch (error) {
      console.log('Error saveData', error);
      throw error;
    }

    // function audioBufferToWav(blobURL) {
    //   var file = {};
    //   var xhr = new XMLHttpRequest();
    //   xhr.open('GET', blobURL, true);
    //   xhr.responseType = 'blob';
    //   xhr.onload = function (e) {
    //     if (this.status == 200) {
    //       file.file = this.response;
    //       file.name = `audio-feed-${moment().format()}.mp3`;
    //       file.size = file.file.size;
    //       file.type = "audio/mpeg";
    //     }
    //   };
    //   xhr.send();

    //   return file;
    // }
  });

  return (
    <Modal
      isVideo
      height="100%"
      open={modalRecordAudioControl.open}
      closeButtonClick={(e) => {
        setModalRecordAudioControl({ ...modalRecordAudioControl, open: false });
        stopRecording();
      }}
      onClose={(ret) => {
        setModalRecordAudioControl({ ...modalRecordAudioControl, open: false });
        stopRecording();
      }}
    >
      <Container background="#e8eced" align="left" height="100%">
        <FormColumn align="center" margin="0px" padding="5px">
          <FormRow align="center" margin="0px">
            <TextSmall>{getDic("gravar")}</TextSmall>
          </FormRow>
          <FormRow align="center" margin="0px">
            <div>
              <audio controls src={audioResult} />
              <div>
                <p>{new Date(timer * 1000).toISOString().substr(11, 8)}</p>
                <div>
                  <FormRow align="center" margin="0px">
                    {status !== 'recording' && (
                      <div style={{ padding: 5 }}>
                        <Button onClick={startRecording}>{getDic("gravar")}</Button>
                      </div>
                    )}
                    {status === 'recording' && (
                      <div style={{ padding: 5 }}>
                        <Button color='red' onClick={stopRecording}>{getDic("parar")}</Button>
                      </div>
                    )}
                    {audioResult && status !== 'recording' && (
                      <div style={{ padding: 5 }}>
                        <Button color='green' onClick={() => saveData()}>{getDic("salvar")}</Button>
                      </div>
                    )}
                  </FormRow>
                </div>
              </div>
            </div>
          </FormRow>
        </FormColumn>
      </Container>
    </Modal>
  )
};

export default ModalRecordAudio;
