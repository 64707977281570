import { SET_PRIVATE_CHAT_CONTROL, RESET_PRIVATE_CHAT_CONTROL } from '../actions';
import { createChatPrivateControlModel } from '../../models/chatPrivateControlModel'

const INITIAL_STATE = createChatPrivateControlModel();

export default function accessReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_PRIVATE_CHAT_CONTROL:
            return action.obj;
        case RESET_PRIVATE_CHAT_CONTROL:
            return INITIAL_STATE;
        default:
            return state;
    }
}
