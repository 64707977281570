export const SET_SPEAKERS = 'SET_SPEAKERS';
const setSpeakersLocal = speakers => ({
    type: SET_SPEAKERS,
    speakers,
});
export function setSpeakers(speakers) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setSpeakersLocal(speakers));
            resolve(true);
        });
    };
}
