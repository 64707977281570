import React from 'react';
import { store } from '../store';
import { connect } from "react-redux";
import { getDic } from '../assets/i18n/dictionary';
import { getDate } from '../services/apiService';
import { syncData } from '../services/syncDataService';
import { createFormControlModel } from '../models/formControlModel';
import { sendAnswersApi, getQuestionsApi } from '../services/questionsService';
import { validateForm, getLocalPageName } from '../utils';
import { convertPushNotificationObjectToNotificationV2 } from '../models/pushNotificationModel';
import { getPagesList } from '../services/configService';
import moment from 'moment';

//Imports de components
import {
  Alert,
  Loading,
  Dialog,
  FormRow,
  TextSmall,
  SelectList,
  Button,
  InputFieldOutlined,
  Snackbar,
  Link,
  TextMedium,
  ElementTreatment,
} from '.';

const PushNotification = (props) => {

  const [state, setState] = React.useState(true);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Snackbar control
  const [executeElement, setExecuteElement] = React.useState(false);
  const [chosenConfig, setChosenConfig] = React.useState(null);
  const [openSnackbarRoot, setOpenSnackbarRoot] = React.useState(false);
  const snackbarRootStructure = React.useRef({
    configId: 0,
    scheduleId: 0,
    title: "",
    replace: false,
  });

  //Dialog control
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [questionsForm, setQuestionsForm] = React.useState([]);
  const [quizTitle, setQuizTitle] = React.useState("");
  const [quizMessage, setQuizMessage] = React.useState("");
  const [quizRequired, setQuizRequired] = React.useState(true);
  const [quizSent, setQuizSent] = React.useState(false);
  const [isQuizTransmission, setIsQuizTransmission] = React.useState(false);

  //Controle de tentativas do quiz, caso de erro por três vezes, ignorar push
  const queryError = React.useRef(0);

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("sincronizando"));
  const [openLoading, setOpenLoading] = React.useState(false);
  const messagePushReceived = React.useRef();

  React.useEffect(() => {
    addEventListeners();
    return () => {
      window.removeEventListener("message", null);
    }
  }, []);

  React.useEffect(() => {
    if (!props.notification) { return }

    executeNotification(props.notification);
  }, [props.notification]);

  const addEventListeners = () => {
    window.addEventListener("message", function (e) {
      if (e.data && e.data.message && e.data.message === 'pushNotificationReopenQueue') {
        reopenQueue();
      }

      if (e.data && e.data.message && e.data.message === 'pushNotificationV2CloseNotification') {
        messagePushReceived.current = e.data.notification;
        pushExecuted();
      }

      if (e.data && e.data.message && e.data.message === 'pushNotificationV2ExecuteQuiz' && e.data.notification) {
        messagePushReceived.current = e.data.notification;
        executeQuizMessage(e.data.notification);
      }

      if (
        e.data &&
        e.data.message &&
        (e.data.message === 'pushNotificationV2OpenConfig' || e.data.message === 'pushNotificationV2OpenSchedule') &&
        e.data.notification
      ) {
        snackbarRootStructure.current = {
          configId: e.data.notification.openConfigId ? parseInt(e.data.notification.openConfigId) : 0,
          scheduleId: e.data.notification.openScheduleId ? parseInt(e.data.notification.openScheduleId) : 0,
          title: '',
          replace: e.data.notification.replaceOpenConfig,
        };
        messagePushReceived.current = e.data.notification;
        pushExecuted();
        searchConfig();
      }
    });
  }

  const pushExecuted = (success = true) => {
    try {
      setIsQuizTransmission(false);
      if (props.pushExecuted) {
        let _notification = props.notification || messagePushReceived.current;
        props.pushExecuted(_notification);
      }
    } catch (error) {
      console.log('Erro pushExecuted', error);
    }

  }

  const reopenQueue = () => {
    setIsQuizTransmission(false);
    if (props.reopenQueue) {
      props.reopenQueue();
    }
  }

  const executeNotification = async (notificationProp) => {
    try {
      var _notification;
      if (typeof notificationProp === 'object' && notificationProp !== null) {
        _notification = notificationProp;
      } else {
        await convertPushNotificationObjectToNotificationV2(notificationProp)
          .then(notification => {
            _notification = notification;
          })
          .catch(err => {
            console.log('Erro executeNotifications', err);
            pushExecuted(false);
          });
      }

      if (!_notification) {
        pushExecuted(false);
        return;
      }
      const _verifyIfShowMessage = await verifyIfShowMessage(_notification);
      if (!_verifyIfShowMessage) {
        pushExecuted();
        return;
      }

      switch (_notification.command) {
        case 'forceRefresh':
          executeForceRefreshMessage();
          break;
        case 'openConfig':
          executeOpenConfigMessage(_notification);
          break;
        case 'openSchedule':
          executeOpenScheduleMessage(_notification);
          break;
        case 'quiz':
          if (verifyIfIsTransmission(_notification)) {
            sendTransmissionNotification(_notification);
            reopenQueue();
          } else {
            executeQuizMessage(_notification);
          }
          break;
        default:
          if (verifyIfIsTransmission(_notification)) {
            sendTransmissionNotification(_notification);
            reopenQueue();
          } else {
            executeSimplePush(_notification);
          }
          break;
      }
    } catch (error) {
      console.log('Erro executeNotification', error);
      pushExecuted(false);
    }
  }

  const verifyIfShowMessage = async (notification) => {
    try {
      if (props.isExample) { return true; }
      if (window.location.href.includes("/notifications")) { return false; }

      var _notificationDate = notification.date.toDate();
      _notificationDate = moment(_notificationDate);
      var _currentDate = await getDate();
      _currentDate = moment(_currentDate);
      var _diffMinutes = _currentDate.diff(_notificationDate, "minutes");
      if (_diffMinutes && _diffMinutes > 30) {
        return false;
      }

      const _chosenConfig = store.getState().chosenConfig;

      var _showGroup = false;
      if (notification.groups.length <= 0) {
        _showGroup = true;
      } else {
        notification.groups.forEach(group => {
          if (parseInt(group) === props.user.groupId) {
            _showGroup = true;
          }
        });
      }
      if (!_showGroup) {
        return false;
      }

      var _showPage = false;
      if (!notification.page || notification.page === '') {
        _showPage = true;
      } else {
        const _path = getLocalPageName();
        const _notificationPage = getPagesList().find(p => p.id === parseInt(notification.page)).path;
        if (_notificationPage) {
          if (_path && _path === _notificationPage) {
            _showPage = true;
          } else if (_chosenConfig && _chosenConfig.urlString && _chosenConfig.urlString.includes(_notificationPage) && !_chosenConfig.urlString.includes("transmission")) {
            _showPage = true;
          } else if (_notificationPage.includes('transmission')) {
            const _transmissionId = `pageId=${_notificationPage.toUpperCase()}${_notificationPage === 'transmission' ? '1' : ''}`;
            const _component = document.getElementById(_transmissionId);
            if (_component) {
              _showPage = true;
            }
          }
        }
      }
      if (!_showPage) {
        return false;
      }

      var _showConfig = false;
      if (notification.configs.length <= 0) {
        _showConfig = true;
      } else {
        const _chosenConfigId = _chosenConfig && _chosenConfig.id ? _chosenConfig.id : 0;
        _showConfig = notification.configs.find(configId => {
          return parseInt(configId) === _chosenConfigId;
        }) ? true : false;
      }

      var _showSchedule = false;
      if (notification.schedules.length <= 0) {
        _showSchedule = true;
      } else {
        const _scheduleId = _chosenConfig && _chosenConfig.scheduleId ? _chosenConfig.scheduleId : 0;
        _showSchedule = notification.schedules.find(schedId => {
          return parseInt(schedId) === _scheduleId;
        }) ? true : false;
      }

      if (!_showConfig && !_showSchedule) {
        return false;
      }

      if (notification.command === 'quiz' && notification.questions.length <= 0) {
        return false;
      }

      return true;
    } catch (error) {
      console.log('Erro verifyIfShowMessage', error);
      throw error;
    }
  }

  const verifyIfIsTransmission = (notification) => {
    try {
      if (notification.command !== 'quiz' && notification.text.length > 26) {
        return false;
      }

      const _component = document.getElementById('isTransmissionPage');
      if (_component) {
        setIsQuizTransmission(true);
        return true;
      }
      return false;
    } catch (error) {
      console.log('Erro verifyIfIsTransmission', error);
    }
  }

  const sendTransmissionNotification = (notification) => {
    window.postMessage({
      message: 'transmissionNotification',
      notification: notification
    }, '*');
  }

  const executeSimplePush = (notification) => {
    try {
      if (notification.text2 && notification.text2 !== '') {
        setAlertTitle(notification.text);
        setAlertMessage(notification.text2);
        setAlertOpen(true);
      } else {
        setAlertTitle("");
        setAlertMessage(notification.text);
        setAlertOpen(true);
      }
    } catch (error) {
      console.log('Erro executeSimplePush', error);
    }
  }

  const executeForceRefreshMessage = () => {
    try {
      const event = store.getState().event;

      setOpenLoading(true);
      setLoadingMessage(getDic("sincronizando"));

      if (props.isExample) {
        setTimeout(() => {
          setOpenLoading(false);
          return;
        }, 1000);
      }
      else {
        syncData(event.id)
          .then(() => {
            pushExecuted();
            setTimeout(() => {
              setOpenLoading(false);
              window.location.reload();
            }, 2000);
          });
      }
    } catch (error) {
      console.log('Erro executeForceRefreshMessage', error);
    }
  }

  const executeOpenConfigMessage = (notification) => {
    try {
      const _configId = notification.openConfigId;

      const _chosenConfig = store.getState().chosenConfig;
      if (_configId && _configId > 0 && (!_chosenConfig || _chosenConfig.id !== _configId)) {
        snackbarRootStructure.current = {
          configId: _configId,
          scheduleId: 0,
          title: notification.text,
          replace: notification.replaceOpenConfig,
        };
        setOpenSnackbarRoot(true);
      }
    } catch (error) {
      console.log('Erro executeOpenConfigMessage', error);
    }
  }

  const executeOpenScheduleMessage = (notification) => {
    try {
      const _scheduleId = notification.openScheduleId;

      const _chosenConfig = store.getState().chosenConfig;
      if (_scheduleId && _scheduleId > 0 && (!_chosenConfig || _chosenConfig.scheduleId !== _scheduleId)) {
        snackbarRootStructure.current = {
          configId: 0,
          scheduleId: _scheduleId,
          title: notification.text,
          replace: notification.replaceOpenConfig,
        };
        setOpenSnackbarRoot(true);
      }
    } catch (error) {
      console.log('Erro executeOpenScheduleMessage', error);
    }
  }

  const executeQuizMessage = (notification) => {
    try {
      if (notification.questions.length <= 0) { return; }

      setQuizTitle(notification.text);
      if (notification.text2 && notification.text2 !== '') {
        setQuizMessage(notification.text2);
      } else {
        setQuizMessage('');
      }

      //Criar formulário de perguntas
      const form = [];
      notification.questionsObject.forEach(quest => {
        let input = questionsForm.find(q => { return q.id === quest.id });

        if (!input) {
          input = createFormControlModel(
            quest.id,
            quest.question,
            "",
            quest.typeQuestion === "TEXTO" ? "text" : "select",
            null,
            null,
            quest.values, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
            true,
          );
        }

        form.push(input);
      });
      setQuestionsForm(form);
      setQuizSent(false);
      setDialogOpen(true);

      // getQuestionsApi(store.getState().event.id, null, notification.questions)
      //   .then(res => {
      //     queryError.current = 0;
      //     _questions = res.questions;
      //   })
      //   .catch(err => {
      //     console.log("Erro getQuestionsApi", err);
      //     var _newError = queryError + 1;
      //     queryError.current = _newError;

      //     if (queryError.current < 3) {
      //       executeQuizMessage(notification);
      //     }
      //   })
      //   .finally(() => {

      //     if (_questions.length > 0) {
      //       //Criar formulário de perguntas
      //       const form = [];
      //       _questions.forEach(quest => {
      //         let input = questionsForm.find(q => { return q.id === quest.id });

      //         if (!input) {
      //           input = createFormControlModel(
      //             quest.id,
      //             quest.question,
      //             "",
      //             quest.typeQuestion === "TEXTO" ? "text" : "select",
      //             null,
      //             null,
      //             quest.values, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
      //             true,
      //           );
      //         }

      //         form.push(input);
      //       });
      //       setQuestionsForm(form);
      //       setQuizSent(false);
      //       setDialogOpen(true);
      //     }

      //     if (queryError.current >= 3) {
      //       queryError.current = 0;
      //     }
      //   });
    } catch (error) {
      console.log('Erro executeQuizMessage', error);
    }
  }

  const insertNewQuiz = () => {
    try {
      var validation = validateForm(questionsForm);

      if (validation.isValid) {
        setOpenLoading(true);
        setLoadingMessage(getDic("salvando"));

        if (props.isExample) {
          setTimeout(() => {
            setDialogOpen(false);
            setOpenLoading(false);
            return;
          }, 1000);
        }
        else {
          sendAnswersApi(questionsForm)
            .then(res => {
              setQuestionsForm([]);
              setQuizSent(true);

              setAlertOpen(true);
              setAlertTitle("");
              setAlertMessage(getDic("enviado-sucesso"));
              setDialogOpen(false);
            })
            .catch(err => {
              setAlertOpen(true);
              setAlertTitle(getDic("erro"));
              setAlertMessage(getDic("enviado-erro"));
            })
            .finally(() => {
              setOpenLoading(false);
              pushExecuted();
            })
        }
      }
      else {
        setQuestionsForm(validation.form);
      }
    } catch (error) {
      console.log('Erro insertNewQuiz', error);
    }
  }

  const verifyIfRequired = () => {
    try {
      if (quizRequired && !quizSent) {
        setDialogOpen(true);
      }
      else {
        setDialogOpen(false);
        pushExecuted();
      }
    } catch (error) {
      console.log('Erro verifyIfRequired', error);
    }
  }

  const searchConfig = () => {
    try {
      var _config;
      if (snackbarRootStructure.current.configId > 0) {
        _config = store.getState().config.find(conf => {
          return conf.id === snackbarRootStructure.current.configId;
        });
      }
      else if (snackbarRootStructure.current.scheduleId > 0) {
        _config = store.getState().config.find(conf => {
          return conf.scheduleId === snackbarRootStructure.current.scheduleId;
        });
      }

      if (_config) {
        //Fecha um modal que esteja aberto
        if (snackbarRootStructure.current.replace) {
          var _modalButton = document.getElementById("modalCloseButton");
          if (_modalButton && _modalButton.click()) {
            _modalButton.click();
          }
        }

        setChosenConfig(_config);
        setExecuteElement(true);
      }
      setOpenSnackbarRoot(false);
    } catch (error) {
      console.log('Erro searchConfig', error);
    }
  }

  return (
    <div>
      <Alert
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
          pushExecuted();
        }}
        onClick={() => {
          setAlertOpen(false);
          pushExecuted();
        }}
        title={alertTitle}
        message={alertMessage}
      />

      <Dialog
        open={dialogOpen}
        onClose={() => { verifyIfRequired() }}
        title={quizTitle}
        disableBackdropClick={quizRequired}
      >
        {quizMessage && quizMessage !== '' && (
          <FormRow paddingTop="0px" paddingBottom="25px">
            <TextSmall>{quizMessage}</TextSmall>
          </FormRow>
        )}
        {questionsForm.map((item, i) => {
          if (item.type === "text") {
            return (
              <FormRow key={item.id} minHeight="85px" paddingTop="5px">
                <TextSmall>{item.name}</TextSmall>
                <InputFieldOutlined
                  error={item.error}
                  errorMessage={item.errorMessage}
                  value={item.value}
                  onChange={async event => {
                    var data = questionsForm;
                    data[i].value = event.target.value;
                    setQuestionsForm(data);
                    setState(state === true ? false : true);
                  }}
                />
              </FormRow>
            );
          }
          return (
            <>
              <FormRow key={item.id} minHeight="10px" paddingTop="5px">
                <TextSmall>{item.name}</TextSmall>
              </FormRow>
              <FormRow key={item.id} minHeight="85px" paddingTop="5px">
                <SelectList
                  error={item.error}
                  errorMessage={item.errorMessage}
                  width="100%"
                  value={item.value}
                  menuItens={item.values}
                  onChange={async (event, value) => {
                    var data = questionsForm;
                    data[i].value = event ? event.target.value : value;
                    setQuestionsForm(data);
                    setState(state === true ? false : true);
                  }}
                // isQuizTransmission={isIOS && isQuizTransmission ? true : false}
                />
              </FormRow>
            </>
          )
        })}
        <FormRow align="center" paddingTop="5px">
          <Button
            text={getDic("enviar")}
            onClick={() => insertNewQuiz()}
          />
        </FormRow>
      </Dialog>

      <Snackbar
        open={openSnackbarRoot}
        onClose={(obj) => {
          if (obj === null || obj.close) {
            setOpenSnackbarRoot(false);
            pushExecuted();
          }
        }}
        vertical={"bottom"}
        backgroundColor="white"
        textColor="black"
        closeButton
        autoHideDuration={60000}
      >
        <FormRow margin="0px" cursor="pointer" onClick={() => searchConfig()}>
          <Link textDecoration="none">
            <TextMedium color="red" blink>
              {getDic("ao-vivo").toUpperCase()}
            </TextMedium>
          </Link>
          <div style={{ paddingLeft: 10 }}>
            <TextMedium>
              {snackbarRootStructure.current.title}
            </TextMedium>
          </div>
        </FormRow>
      </Snackbar>

      {executeElement && (
        <ElementTreatment
          history={props.history}
          chosenConfig={chosenConfig}
          onClose={() => {
            setExecuteElement(false);
            setChosenConfig(null);
          }}
        />
      )}

      <Loading open={openLoading} message={loadingMessage} />
    </div>
  );
}


function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user
  };
}

export default connect(mapStateToProps, null)(PushNotification);
