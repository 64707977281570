import React from 'react';
import Divider from '@material-ui/core/Divider';

const Component = props => {
    // const {
    //     children
    // } = props;

    return (
        <Divider />
    )
};

export default Component;
