export const CHOSEN_STAND = 'CHOSEN_STAND';
const chosenStandLocal = stand => ({
    type: CHOSEN_STAND,
    stand
});
export function chosenStand(stand) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(chosenStandLocal(stand));

            if (getState().chosenStand && getState().chosenStand.id === stand.id) {
                resolve(true);
            }
            else {
                this.chosenStand(stand);
            }
        });
    };
}

export const RESET_STAND = 'RESET_STAND';
const resetStandLocal = () => ({
    type: RESET_STAND
});
export function resetStand() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetStandLocal());
            resolve(true);
        });
    };
}
