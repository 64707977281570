import { combineReducers } from 'redux';

import userReducer from './userReducer';
import tokenReducer from './tokenReducer';
import eventReducer from './eventReducer';
import scheduleReducer from './scheduleReducer';
import speakerReducer from './speakerReducer';
import accessReducer from './accessReducer';
import languageReducer from './languageReducer';
import configReducer from './configReducer';
import standReducer from './standReducer';
import pavilionReducer from './pavilionReducer';
import chosenPavilionReducer from './chosenPavilionReducer';
import chosenStandReducer from './chosenStandReducer';
import pushNotificationReducer from './pushNotificationReducer';
import loadingReducer from './loadingReducer';
import navigationHistoryReducer from './navigationHistoryReducer';
import dateReducer from './dateReducer';
import chosenConfigReducer from './chosenConfigReducer';
import chosenCampaignReducer from './chosenCampaignReducer';
import chatPrivateControlReducer from './chatPrivateControlReducer';
import notificationReducer from './notificationReducer';

export default combineReducers({
    user: userReducer,
    token: tokenReducer,
    event: eventReducer,
    schedules: scheduleReducer,
    speakers: speakerReducer,
    access: accessReducer,
    lang: languageReducer,
    config: configReducer,
    stands: standReducer,
    pavilions: pavilionReducer,
    chosenPavilion: chosenPavilionReducer,
    chosenStand: chosenStandReducer,
    pushNotifications: pushNotificationReducer,
    loading: loadingReducer,
    history: navigationHistoryReducer,
    date: dateReducer,
    chosenConfig: chosenConfigReducer,
    chosenCampaign: chosenCampaignReducer,
    chatPrivateControl: chatPrivateControlReducer,
    notification: notificationReducer
});
