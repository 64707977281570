import React from 'react';
import Button from '@material-ui/core/Button';

const AppBarButton = props => {
    const {
        text = "",
        color = null,
        size = null,
        onClick
    } = props;

    const styles = {
        button: {
        }
    };

    return (
        <Button
            style={styles.button}
            color={color ? color : "inherit"}
            size={size ? size : "medium"}
            onClick={onClick}
        >
            {text}
        </Button>
    )
};

export default AppBarButton;
