import React from "react";
import moment from "moment";
import { Button } from "reactstrap";
import ModalSurveyList from "./ModalRows";

const App = ({ id, getSurveyForModal, modalBG, searchUserAnswer, survey }) => {
  const [modal, setModal] = React.useState(false);
  const [surveyData, setSurveyData] = React.useState([]);
  const [rank, setRank] = React.useState(3);
  const toggle = () => setModal(!modal);

  React.useEffect(() => {
    console.log("gerando lista de surveys");
    getSurveyForModal(id).then((r) => {
      //let arraySorted = r.sort((a, b) => b.score - a.score);
      if (!r) return;
      setSurveyData(
        r
          .sort((a, b) => b.score - a.score || a.currentData - b.currentData)
          //.sort((a, b) => b.score - a.score && a.currentData - b.currentData)
          /* .sort((a, b) => a.currentData - b.currentData) */
          /* .splice(0, 100)
           */
          .splice(0, rank)
      );
    });
  }, [rank, modal]);
  return (
    <React.Fragment>
      <i
        data-toggle="tooltip"
        data-placement="top"
        title="Abrir Janela de Resultados"
        class="fas fa-table"
        onClick={toggle}
        style={{
          cursor: "pointer",
          marginLeft: 10,
        }}
      ></i>

      <div
        className={`modal ${!modal && "fade"} show`}
        style={{ display: modal ? "block" : "none", background: "#bbb6b65c" }}
        role="dialog"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-fullscreen" role="document">
          <div
            className="modal-content"
            style={{
              backgroundImage: `url(${modalBG})`,
              backgroundSize: "cover",
            }}
          >
            <div className="modal-header">
              <h4 className="modal-title">
                Rank de Usuários{" "}
                <i class="fas fa-medal" style={{ color: "black" }}></i>
              </h4>
              <select
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                defaultValue=""
                placeholder="#RANK"
                style={{ width: 70, marginRight: 50 }}
                onChange={(e) => setRank(e.target.value)}
              >
                <option value="">#RANK</option>
                <option value={3} key={1}>
                  #3
                </option>
                <option value={5} key={1}>
                  #5
                </option>
                <option value={10} key={1}>
                  #10
                </option>
                <option value={50} key={1}>
                  #50
                </option>
                <option value={100} key={1}>
                  #100
                </option>
              </select>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={toggle}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div data-test="datatable-table" className="col-sm-12">
                  <div data-test="table" className="table-responsive">
                    <table
                      entries={2}
                      className=""
                      style={{ width: "100%", borderTop: "1px solid #eff2f7" }}
                    >
                      <thead data-test="datatable-head">
                        <tr>
                          <th className="sorting" style={{ textAlign: "left" }}>
                            #
                          </th>
                          <th className="sorting" style={{ textAlign: "left" }}>
                            Usuário
                          </th>
                          <th className="sorting" style={{ textAlign: "left" }}>
                            Milisegundos
                          </th>
                          <th className="sorting" style={{ textAlign: "left" }}>
                            Pontuação
                          </th>
                          <th
                            className="sorting"
                            style={{ textAlign: "left" }}
                          ></th>

                          {/* <th
                            className="sorting"
                            style={{ textAlign: "center" }}
                          >
                            Data
                          </th>
                          <th
                            className="sorting"
                            style={{ textAlign: "center" }}
                          >
                            Hora
                          </th> */}
                        </tr>
                      </thead>
                      <tbody data-test="table-body">
                        {surveyData.map((e, i) => (
                          <ModalSurveyList
                            survey={survey}
                            e={e}
                            i={i}
                            searchUserAnswer={searchUserAnswer}
                            id={id}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default App;
