import { store } from "../store";
import { deleteAccess, userLogout } from "../store/actions";
import {
  insertNewAccessListApi,
  checkUserAlreadyLogged,
  addGatewayAccess,
} from "../services/accessService";
import { createAccessModel } from "../models/accessModel";
import { insertNewAccess, getSystemActions } from "../services/accessService";
import { getDate } from "../services/apiService";
import moment from "moment";

async function logoutApp(event_id) {
  await insertNewAccess("system", "", getSystemActions().exitPage);

  const access = store.getState().access;
  insertNewAccessListApi(access)
    .then((ret) => {
      console.log("Ok");
    })
    .catch((err) => {
      console.log("Erro insertNewAccessListApi", err);
    })
    .finally(async () => {
      await userLogout();
      window.location.href = "/" + event_id;
      //props.history.push("/" + props.event.id);
    });
}

export function sendAccessReport() {
  var timeout = setTimeout(() => {
    execute();
    //Reiniciar thread
    clearTimeout(timeout);
    sendAccessReport();
  }, 120000); // 1 minuto = 60000
}

export function forceSendAccessReport() {
  execute();
}

async function execute() {
  const user = store.getState().user;
  const eventId = store.getState().event.id;
  const config = store.getState().config;
  const accessList = store.getState().access;
  const date = await getDate();
  var _localDate = moment(date);

  //Caso o horário seja entre 3:00 e 3:05 da manhã, deslogar usuário
  if (_localDate._a[3] === 3 && _localDate._a[4] > 0 && _localDate._a[4] < 5) {
    window.location.href = "/" + eventId;
  }
  else if (user && user.id !== 0) {
    var access = createAccessModel(
      getSystemActions().ping.id,
      "ping",
      date,
      "",
      user.id.toString(),
      user.email,
      "",
      "ping",
      "ping",
      eventId
    );
    accessList.push(access);

    if (accessList.length > 0) {
      insertNewAccessListApi(accessList)
        .then(() => {
          console.log("Acessos inseridos com sucesso...");

          accessList.forEach((access) => {
            store.dispatch(deleteAccess(access));
          });
          let _hasSingleUser = config.find((e) => { return e.codeScreen == "ENABLE_SINGLE_USER_LOGIN" });
          if (_hasSingleUser) {
            setTimeout(function () {
              checkUserAlreadyLogged(eventId, user.id, user.user_token).then((result) => {
                if (!result.active) {
                  //kicka o usuário                
                  logoutApp(eventId);
                }
              });
            }, 60000);
          }
        })
        .catch((err) => {
          console.log("Erro sendAccessReport: ", err);

          // const error = err.toString();
          // if (error.includes("Request failed with status code 429")) {
          //     execute();
          // }
        });
    }
  }
}

export function accessMessageReceiver() {
  window.addEventListener("message", function (e) {
    if (
      e.data &&
      e.data.message &&
      e.data.message === "setHallHeatMap" &&
      e.data.configId
    ) {
      addGatewayAccess(e.data.configId);
    }
  });
}
