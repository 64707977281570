import React from 'react';

const RenderEmojiOnScreen = props => {
  const {
    emojis,
    emojiValue,
  } = props;

  let _key = `em${emojiValue < 10 ? '0' : ''}${emojiValue}`;
  let _emoji = emojis.list[_key];
  return (
    <div>
      <div
        className='emoji-anime'
        style={{
          transform: 'scale(0.9)',
          opacity: 0.9,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 3s linear infinite, sideWays 4s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          left: '200px',
          transform: 'scale(0.6)',
          opacity: 0.9,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 7s linear infinite, sideWays 5s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          left: '350px',
          transform: 'scale(0.8)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 5s linear infinite, sideWays 4s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          left: '470px',
          transform: 'scale(0.75)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 4s linear infinite, sideWays 2s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          left: '150px',
          transform: 'scale(0.8)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 7s linear infinite, sideWays 1s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          left: '440px',
          transform: 'scale(0.85)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 6s linear infinite, sideWays 2s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          right: "10px",
          transform: 'scale(0.75)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 4s linear infinite, sideWays 2s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          right: "10px",
          transform: 'scale(0.55)',
          opacity: 0.5,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 7s linear infinite, sideWays 14s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          right: "150px",
          transform: 'scale(0.55)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 6s linear infinite, sideWaysMiddle 4s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          right: "90px",
          transform: 'scale(0.55)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 9s linear infinite, sideWaysMiddle 6s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          right: "120px",
          transform: 'scale(0.55)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 3s linear infinite, sideWaysMiddle 4s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          right: "110px",
          transform: 'scale(0.55)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 3s linear infinite, sideWaysEnd 7s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          right: "160px",
          transform: 'scale(0.55)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 6s linear infinite, sideWaysEnd 12s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          right: "160px",
          transform: 'scale(0.55)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 5s linear infinite, sideWaysEnd 14s ease-in-out infinite alternate'
        }}
      />
      <div
        className='emoji-anime'
        style={{
          right: "90px",
          transform: 'scale(0.55)',
          opacity: 0.8,
          backgroundImage: `url(${_emoji})`,
          backgroundSize: '100% 100%',
          animation: 'moveclouds 5s linear infinite, sideWaysEnd 14s ease-in-out infinite alternate'
        }}
      />
    </div>
  )
};

export default RenderEmojiOnScreen;
