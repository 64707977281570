import React from 'react';
import { getDic } from "../../assets/i18n/dictionary";
import {
  FormRow,
  Button,
  Modal,
  Container,
  FormColumn,
  InputFieldOutlined,
  TextMedium,
  GridCell,
  GridRow
} from "../../components";
import AddIcon from '@material-ui/icons/Add';

const ModalAddBlocklist = props => {
  const {
    modalAddBlocklistControl,
    setModalAddBlocklistControl,
    searchAddBocklistGuests,
    addGuestToBlocklist,
  } = props;

  return (
    <Modal
      isVideo
      height="100%"
      open={modalAddBlocklistControl.open}
      closeButtonClick={(e) => setModalAddBlocklistControl({ ...modalAddBlocklistControl, open: false })}
      onClose={(ret) => setModalAddBlocklistControl({ ...modalAddBlocklistControl, open: false })}
    >
      <Container background="#e8eced" align="left" height="100%">
        <FormColumn align="center" margin="0px" padding="5px">
          <FormRow align="center" margin="0px">
            <div style={{ width: '70%' }}>
              <InputFieldOutlined
                value={modalAddBlocklistControl.search}
                title={getDic("buscar")}
                onChange={(e) => setModalAddBlocklistControl({ ...modalAddBlocklistControl, search: e.target.value })}
              />
            </div>
            <div style={{ width: '30%' }}>
              <Button onClick={() => searchAddBocklistGuests()}>
                {getDic("buscar")}
              </Button>
            </div>
          </FormRow>
          {renderGuestsList()}
        </FormColumn>
      </Container>
    </Modal>
  )

  function renderGuestsList() {
    return (
      <div
        style={{
          height: "70vh",
          backgroundColor: "transparent",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "5px",
            backgroundColor: "transparent",
            height: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <GridRow backgroundColor="#d9d9d9">
            <GridCell width="10%" border top first>
              <TextMedium>ID</TextMedium>
            </GridCell>
            <GridCell width="35%" border top>
              <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="45%" border top>
              <TextMedium>{getDic("email").toUpperCase()}</TextMedium>
            </GridCell>
            <GridCell width="10%" border top>
              <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
            </GridCell>
          </GridRow>

          {modalAddBlocklistControl.guestsList.length > 0 ? (
            modalAddBlocklistControl.guestsList.map((guest, i) => {
              return (
                <GridRow
                  key={guest.id}
                  autoColor={i}
                >
                  <GridCell width="10%" border first>
                    <TextMedium>{guest.id}</TextMedium>
                  </GridCell>
                  <GridCell width="35%" border>
                    <TextMedium>{guest.name}</TextMedium>
                  </GridCell>
                  <GridCell width="45%" border>
                    <TextMedium>{guest.email}</TextMedium>
                  </GridCell>
                  <GridCell width="10%" border>
                    <AddIcon onClick={() => addGuestToBlocklist(guest)} style={{ cursor: "pointer" }} />
                  </GridCell>
                </GridRow>
              );
            })
          ) : (
            <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
          )}
        </div>
      </div>
    )
  }
};

export default ModalAddBlocklist;
