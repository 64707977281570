export const CHOSEN_PAVILION = 'CHOSEN_PAVILION';
const setChosenPavilionLocal = pavilion => ({
    type: CHOSEN_PAVILION,
    pavilion
});
export function setChosenPavilion(pavilion) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setChosenPavilionLocal(pavilion));
            resolve(true);
        });
    };
}

export const RESET_PAVILION = 'RESET_PAVILION';
const resetChosenPavilionLocal = () => ({
    type: RESET_PAVILION
});
export function resetChosenPavilion() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetChosenPavilionLocal());
            resolve(true);
        });
    };
}
