import React from "react";
import { connect } from "react-redux";
import { insertNewCheckin } from "../services/checkinService";
import { setChosenConfig, resetChosenConfig } from "../store/actions";
import { getDic } from "../assets/i18n/dictionary";
import { insertNewAccess, getSystemActions } from "../services/accessService";
import "../scss/VideoReact.scss";
import { isMobile } from "react-device-detect";
import { changeUrlTags } from "../utils";
import { initEngage } from "../services/engageService";
import { getUrl } from "../services/apiService";
import * as rankingService from "../services/rankingService";
import * as navigationHistoryService from "../services/navigationHistoryService";

//Imports de components
import {
  Container,
  FormRow,
  FormColumn,
  Modal,
  ModalVimeo,
  VideoPlayer,
  IframePlayer,
  InputFieldOutlined,
  TextSmall,
  Button,
  Dialog,
  QuestionsPanel,
  Chat,
  ExpansionPanel,
  Loading,
  UserCracha,
  ChessGameRender,
  UserCertificate,
} from "../components";

import ElementTreatmentModalRouter from "./ElementTreatmentModalRouter";

class ElementTreatment extends React.Component {
  constructor(props) {
    super(props);

    var _chosenLang = "pt";

    if (this.props.chosenConfig.multiLanguage) {
      if (this.props.chosenConfig.urlObjct.default) {
        _chosenLang = this.props.chosenConfig.urlObjct.default;
      } else {
        if (this.props.lang.lang === "en") {
          _chosenLang = "en";
        }
        if (this.props.lang.lang === "sp") {
          _chosenLang = "sp";
        }
      }
    }

    this.state = {
      chosenConfig: this.props.chosenConfig,
      openModal: false,
      urls: changeUrlTags(this.props.chosenConfig.url),

      // Multilinguagem
      multilanguage: this.props.chosenConfig.multiLanguage,
      chosenLanguage: _chosenLang,
      multilanguageUrlObj: this.props.chosenConfig.urlObjct,
      showBrFlag:
        this.props.chosenConfig.urlObjct.pt &&
          this.props.chosenConfig.urlObjct.pt !== ""
          ? true
          : false,
      showEnFlag:
        this.props.chosenConfig.urlObjct.en &&
          this.props.chosenConfig.urlObjct.en !== ""
          ? true
          : false,
      showSpFlag:
        this.props.chosenConfig.urlObjct.sp &&
          this.props.chosenConfig.urlObjct.sp !== ""
          ? true
          : false,
      chosenUrl: null,

      //Tratativa de senha
      dialogOpen: false,
      accessGranted: false,
      password: "",
      errorPassword: false,
      modalContentHeight: isMobile ? "96vh" : "80vh",
      modalContentWidth: "100%",

      //URL Engage
      engageUrl: "",

      //Loading control
      loadingMessage: getDic("carregando"),
      openLoading: false,
    };
  }

  // Método para retornar parametro ao elemento pai
  onClose = () => {
    this.props.resetChosenConfig();
    this.props.onClose();
  };

  componentDidMount() {
    if (this.state.chosenConfig) {
      let ids = ["launcher", "menu_btn", "back_btn", "exit_btn"];
      ids.forEach((element) => {
        let _e = document.getElementById(element);
        if (_e) {
          _e.style.display = "none";
        }
      });

      //Ao acessar armazena qual config está rodando
      this.props.setChosenConfig(this.state.chosenConfig);
      //Verifica se é uma sessão para credenciar o usuário
      if (this.state.chosenConfig.scheduleId > 0) {
        insertNewAccess(
          "/" + this.props.event.id.toString() + "/roomlive",
          this.state.chosenConfig.scheduleId.toString(),
          getSystemActions().accessPage,
          "TB_ProgramacaoEvento",
          this.state.chosenConfig.scheduleId.toString()
        );

        insertNewCheckin(this.state.chosenConfig.scheduleId)
          .then((res) => {
            console.log("Credenciamento realizado com sucesso");
          })
          .catch((err) => {
            console.log("Erro insertNewCheckin: ", err);
          });
      } else {
        var page = window.location.pathname;

        insertNewAccess(
          page,
          this.state.chosenConfig.id.toString(),
          getSystemActions().click,
          "TB_HallConfig",
          this.state.chosenConfig.id.toString()
        );
      }

      this.checkIfHasRankingInteegraEventos();

      this.executeElement();
    } else {
      console.log("Parametro chosenConfig não enviado");
    }
  }

  async checkIfHasRankingInteegraEventos() {
    try {
      if (!this.props.user.internalCode || !this.state.chosenConfig?.multiConfig?.rankingCode) { return }

      let _useRankingInteegraEventos = this.props.config.find(c => { return c.codeScreen === 'USE-RANKING-INTEEGRA-EVENTOS-PLUS' });
      if (!_useRankingInteegraEventos || !_useRankingInteegraEventos?.urlString) { return }

      await rankingService.setAdminPlusRankingPoints(
        this.state.chosenConfig?.multiConfig?.rankingCode,
        this.state.chosenConfig?.multiConfig?.rankingCodeSubtract || false,
        this.props.user.internalCode,
        _useRankingInteegraEventos.urlString.trim(),
      )

    } catch (error) {
      console.log('Error checkIfHasRankingInteegraEventos', error);
    }
  }

  componentWillUnmount() {
    let ids = ["launcher", "menu_btn", "back_btn", "exit_btn"];
    ids.forEach((element) => {
      let _e = document.getElementById(element);
      if (_e) {
        _e.style.display = "block";
      }
    });
  }
  async toDataURL(url) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }
  async executeElement() {
    //Verifica se o config possui senha e se está preenchida
    if (
      this.state.chosenConfig.hasPassword &&
      this.state.chosenConfig.password !== this.state.password
    ) {
      this.setState({ dialogOpen: true });
    }
    //Busca a configuração e executa instrução
    else if (this.state.chosenConfig.typeId === 2) {
      if (this.state.urls.length > 0) {
        let currentUrl = this.state.urls[0];
        let searchFormat = ".pdf";
        if (
          currentUrl.substring(currentUrl.lastIndexOf(".")).toLowerCase() ==
          searchFormat.toLowerCase()
        ) {
          this.renderModalIframeContent();
        } else {
          let a = document.createElement("a");
          a.href = this.state.urls[0];
          a.target = "_blank";
          a.download = this.state.chosenConfig.name;
          a.click();
          //const a = document.createElement("a");
          //a.href = await this.toDataURL(this.state.urls[0]);
          //a.download = this.state.chosenConfig.name;
          //document.body.appendChild(a);
          //a.click();
          //document.body.removeChild(a);
        }
      }
      this.onClose();
    } else if (this.state.chosenConfig.typeId === 6) {
      navigationHistoryService.setHistory(this.props.history, this.state.urls[0]);
      this.onClose();
      // } else if (this.state.chosenConfig.typeId === 7) {
      //   window.open(this.state.urls[0], "_blank");
      //   this.onClose();
    } else if (this.state.chosenConfig.typeId === 8) {
      // window.open(this.state.urls[0], "_blank");
      const a = document.createElement("a");
      a.href = this.state.urls[0];
      a.setAttribute('target', '_blank');
      document.body.appendChild(a);
      a.click();
      this.onClose();
    }
    //Caso seja engage, buscar url para conexão
    else if (this.state.chosenConfig.typeId === 11) {
      this.setState({ openLoading: true });

      const engageUrl = await initEngage(this.state.chosenConfig);

      this.setState({
        openModal: true,
        accessGranted: true,
        dialogOpen: false,
        engageUrl: engageUrl,
        openLoading: false,
      });
    } else {
      this.selectUrl();
      this.setState({
        openModal: true,
        accessGranted: true,
        dialogOpen: false,
      });
    }
  }

  selectUrl(lang) {
    var _urlIframe = this.state.multilanguage
      ? this.state.multilanguageUrlObj[lang]
        ? this.state.multilanguageUrlObj[lang]
        : this.state.multilanguageUrlObj[this.state.multilanguageUrlObj.default]
      : this.state.urls[0];
    this.setState({ chosenUrl: _urlIframe });
    if (lang) {
      this.setState({ openModal: true, chosenLanguage: lang });
    }
  }

  getZoomSdkUrl() {
    var _params = {
      meetingId: "",
      meetingPassword: "",
    };
    this.state.urls.forEach((element) => {
      var _prop = element.split(":");
      if (_params.hasOwnProperty(_prop[0])) {
        _params[_prop[0]] = _prop[1];
      }
    });
    const _url = `${getUrl().zoomSdk}?name=${this.props.user.name}&meetingId=${_params.meetingId
      }&meetingPassword=${_params.meetingPassword}`;
    return _url;
  }

  renderModalIframeContent() {
    //Verifica se a url possui configurações específicas
    var _config = this.state.chosenConfig.multiConfig;
    //Verifica se a url 2 é um config para o chat ou perguntas
    if (
      this.state.urls[1] &&
      (this.state.urls[1] === "CHAT" || this.state.urls[1] === "QUESTIONS")
    ) {
      return (
        <Container background="transparent" align="center" height="100%">
          <FormRow align="center" background="transparent" margin="0px">
            {/*<ChatModalFloated />*/}
            <FormColumn
              width={_config.iframeWidth}
              background={_config.iframeBackgroundColor}
              margin="0px"
              minWidth="200px"
            >
              <IframePlayer
                height={this.state.modalContentHeight}
                src={this.state.chosenUrl}
                history={this.props.history}
              />
            </FormColumn>
            <FormColumn
              width={_config.interactWidth}
              background={_config.interactBackgroundColor}
              margin="0px"
              minWidth="250px"
            >
              {this.state.urls.length === 2 && this.state.urls[1] === "CHAT" ? (
                <div
                  style={{
                    height: this.state.modalContentHeight,
                    backgroundColor: "transparent",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      padding: "5px",
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <ExpansionPanel headerText="CHAT" expanded>
                      <div style={{ width: "100%" }}>
                        <Chat
                          chosenConfig={this.state.chosenConfig}
                          height="65vh"
                        />
                      </div>
                    </ExpansionPanel>
                  </div>
                </div>
              ) : this.state.urls.length === 2 &&
                this.state.urls[1] === "QUESTIONS" ? (
                <QuestionsPanel
                  chosenConfig={this.state.chosenConfig}
                  height={this.state.modalContentHeight}
                />
              ) : this.state.urls[1] === "CHAT" ? (
                <QuestionsPanel
                  chosenConfig={this.state.chosenConfig}
                  height={this.state.modalContentHeight}
                  hasChat
                  chatOrder={1}
                />
              ) : this.state.urls[2] === "CHAT" ? (
                <QuestionsPanel
                  chosenConfig={this.state.chosenConfig}
                  height={this.state.modalContentHeight}
                  hasChat={true}
                  chatOrder={2}
                />
              ) : (
                <QuestionsPanel
                  chosenConfig={this.state.chosenConfig}
                  height={this.state.modalContentHeight}
                  hasChat={false}
                  chatOrder={2}
                />
              )}
            </FormColumn>
          </FormRow>
        </Container>
      );
    } else if (
      this.state.urls.length <= 1 ||
      (this.state.urls[1] && this.state.urls[1].includes("SET_GIF_MAP")) ||
      (this.state.urls[1] && this.state.urls[1].includes("SET_IFRAME_MAP"))
    ) {
      return (
        <Container background="transparent" align="center" height="100%">
          <FormRow
            align="center"
            background={_config.iframeBackgroundColor}
            margin="0px"
          >
            <IframePlayer
              height={this.state.modalContentHeight}
              src={this.state.chosenUrl}
              history={this.props.history}
            />
          </FormRow>
        </Container>
      );
    } else if (
      this.state.urls.length === 2 &&
      this.state.urls[1] !== "FULL" &&
      !this.state.urls[1].includes("BACKGROUND_CHANGE")
    ) {
      return (
        <Container background="transparent" align="center" height="100%">
          <FormRow align="center" background="transparent" margin="0px">
            <FormColumn
              width={_config.iframeWidth}
              background={_config.iframeBackgroundColor}
              margin="0px"
              minWidth="200px"
            >
              <IframePlayer
                height={this.state.modalContentHeight}
                src={this.state.chosenUrl}
                history={this.props.history}
              />
            </FormColumn>
            <FormColumn
              width={_config.interactWidth}
              background={_config.interactBackgroundColor}
              margin="0px"
              minWidth="200px"
            >
              <IframePlayer
                height={this.state.modalContentHeight}
                src={this.state.urls[1]}
                history={this.props.history}
              />
            </FormColumn>
          </FormRow>
        </Container>
      );
    } else if (this.state.urls.length === 2 && this.state.urls[1] === "FULL") {
      return (
        <Container background="transparent" align="center" height="100%">
          <FormRow
            align="center"
            background={_config.iframeBackgroundColor}
            margin="0px"
          >
            <IframePlayer
              height={this.state.modalContentHeight}
              src={this.state.chosenUrl}
              history={this.props.history}
            />
          </FormRow>
        </Container>
      );
    } else {
      return (
        <Container background="transparent" align="center" height="100%">
          <FormRow
            align="center"
            background={_config.iframeBackgroundColor}
            margin="0px"
          >
            <IframePlayer
              height={this.state.modalContentHeight}
              src={this.state.urls[0]}
              history={this.props.history}
            />
          </FormRow>
        </Container>
      );
    }
  }

  renderModalContent() {
    if (this.state.chosenConfig.typeId === 1) {
      // Vídeo
      return (
        <Container background="transparent" align="center" height="100%">
          <FormRow align="center" background="transparent" margin="0px">
            <FormColumn
              width={
                this.state.urls[1] && this.state.urls[1] === "CHAT"
                  ? "70%"
                  : "100%"
              }
              margin="0px"
              minWidth="200px"
            >
              <VideoPlayer src={this.state.urls[0]} autoPlay />
            </FormColumn>
            {this.state.urls[1] && this.state.urls[1] === "CHAT" ? (
              <FormColumn width="30%" margin="0px" minWidth="200px">
                <div
                  style={{
                    height: this.state.modalContentHeight,
                    backgroundColor: "transparent",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      padding: "5px",
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <ExpansionPanel headerText="CHAT" expanded>
                      <div style={{ width: "100%" }}>
                        <Chat
                          chosenConfig={this.state.chosenConfig}
                          height="500px"
                        />
                      </div>
                    </ExpansionPanel>
                  </div>
                </div>
              </FormColumn>
            ) : null}
          </FormRow>
        </Container>
      );
    }
    if (this.state.chosenConfig.typeId === 3) {
      // Iframe
      if (this.state.urls.length > 0) {
        let currentUrl = this.state.urls[0];
        let searchFormat = ".pdf";
        if (
          currentUrl.substring(currentUrl.lastIndexOf(".")).toLowerCase() ==
          searchFormat.toLowerCase() &&
          isMobile
        ) {
          this.setState({ dialogOpen: false });
          window.open(this.state.urls[0], "_blank");
          this.onClose();
          return;
        } else {
          return this.renderModalIframeContent();
        }
      }
    }
    if (this.state.chosenConfig.typeId === 4) {
      // Imagem
      return (
        <Container background="transparent" align="center" height="auto">
          <img
            alt=""
            src={this.state.urls[0]}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Container>
      );
    }
    if (this.state.chosenConfig.typeId === 7) {
      // Zoom
      return (
        <Container background="transparent" align="center" height="auto">
          <IframePlayer
            height={this.state.modalContentHeight}
            src={this.getZoomSdkUrl()}
            history={this.props.history}
          />
        </Container>
      );
    }
    if (this.state.chosenConfig.typeId === 10) {
      // Iframe/Perguntas
      return (
        <Container background="transparent" align="center" height="100%">
          <FormRow align="center" background="transparent" margin="0px">
            <FormColumn width="70%" margin="0px" minWidth="200px">
              <IframePlayer
                height={this.state.modalContentHeight}
                src={this.state.urls[0]}
                history={this.props.history}
              />
            </FormColumn>
            <FormColumn
              width="30%"
              margin="0px"
              minWidth="200px"
              background="transparent"
            >
              <QuestionsPanel
                chosenConfig={this.state.chosenConfig}
                height={this.state.modalContentHeight}
              />
            </FormColumn>
          </FormRow>
        </Container>
      );
    }
    if (this.state.chosenConfig.typeId === 11) {
      // Engage
      return (
        <Container background="transparent" align="center" height="100%">
          <FormRow align="center" background="transparent" margin="0px">
            <IframePlayer
              height={this.state.modalContentHeight}
              src={this.state.engageUrl}
              history={this.props.history}
            />
          </FormRow>
        </Container>
      );
    }
    if (this.state.chosenConfig.typeId == 12) {
      // Chat Publico
      return (
        <Container background="white" align="center" height="100%">
          <div style={{ width: "100%", display: "flex" }}>
            <Chat
              chosenConfig={this.props.config.find(
                (e) => e.id == this.state.chosenConfig.url[0]
              )}
              enableSizeController={true}
              height="75vh"
              modalContentHeight={this.state.modalContentHeight.replace(
                "vh",
                ""
              )}
            />
          </div>
        </Container>
      );
    }
    if (this.state.chosenConfig.typeId == 13) {
      //Crachá
      return (
        <Container background="transparent" align="center" height="100%">
          <UserCracha chosenConfig={this.state.chosenConfig} />
        </Container>
      );
    }
    if (this.state.chosenConfig.typeId == 14) {
      //Jogo Xadrez
      return (
        <Container height="100%">
          <ChessGameRender userID={this.props.user.id} />
        </Container>
      );
    }
    if (this.state.chosenConfig.typeId == 17) {
      // Link interno modal
      return (
        <ElementTreatmentModalRouter
          history={this.props.history}
          modalContentWidth={this.state.modalContentWidth}
          modalContentHeight={this.state.modalContentHeight}
          chosenConfig={this.state.chosenConfig}
        />
      );
    }
    if (this.state.chosenConfig.typeId == 18) {
      //Certificado
      return <UserCertificate
        chosenConfig={this.state.chosenConfig}
        modalContentWidth={this.state.modalContentWidth}
      />;
    }
  }

  render() {
    if (this.state.chosenConfig) {
      //Verifica se o config possui senha
      if (this.state.chosenConfig.hasPassword && !this.state.accessGranted) {
        return (
          <Dialog
            open={this.state.dialogOpen}
            onClose={() => {
              this.setState({ dialogOpen: false });
              this.onClose();
            }}
            title={this.props.passwordMessage}
          >
            <FormRow align="center">
              <InputFieldOutlined
                key="password"
                value={this.state.password}
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
              />
            </FormRow>
            <br />
            <Button
              text={getDic("acessar")}
              onClick={() => {
                if (this.state.password === this.state.chosenConfig.password) {
                  this.executeElement();
                } else {
                  this.setState({ errorPassword: true });
                  setTimeout(() => {
                    this.setState({ errorPassword: false });
                  }, 3000); // 1 minuto = 60000
                }
              }}
            />
            {this.state.errorPassword ? (
              <TextSmall color="red">{getDic("senha-erro")}</TextSmall>
            ) : null}
          </Dialog>
        );
      }
      if (this.state.chosenConfig.typeId === 9) {
        // Vimeo
        return (
          <ModalVimeo
            open={this.state.openModal}
            closeButtonClick={() => {
              this.setState({ openModal: false });
              this.onClose();
            }}
            onClose={() => {
              this.setState({ openModal: false });
              this.onClose();
            }}
            url={this.state.urls[0]}
          />
        );
      }

      //Caso seja iframe, verificar se é para abrir full screen
      var openModalFullScreen =
        this.state.chosenConfig.multiConfig.openModalFull;
      if (
        !openModalFullScreen &&
        (this.state.chosenConfig.typeId === 3 ||
          this.state.chosenConfig.typeId === 7 ||
          this.state.chosenConfig.typeId === 10)
      ) {
        this.state.chosenConfig.url.find((url) => {
          if (url === "FULL") {
            openModalFullScreen = true;
          }
        });
      }

      return (
        <div>
          <Modal
            isVideo={
              this.state.chosenConfig.typeId === 1 ||
                this.state.chosenConfig.typeId === 14 ||
                this.state.chosenConfig.typeId === 4
                ? true
                : false
            }
            height={this.state.chosenConfig.typeId === 1 ? "100%" : "80vh"}
            modalContentHeight={(height) =>
              this.setState({ modalContentHeight: height })
            }
            modalContentWidth={(width) =>
              this.setState({ modalContentWidth: width })
            }
            open={this.state.openModal}
            openModalFullScreen={openModalFullScreen}
            closeButtonClick={() => {
              this.setState({ openModal: false });
              this.onClose();
            }}
            onClose={(newLang) => {
              this.setState({ openModal: false });
              if (newLang) {
                setTimeout(() => {
                  this.selectUrl(newLang);
                }, 100);
              } else {
                this.onClose();
              }
            }}
            multilanguage={this.state.multilanguage}
            showBrFlag={this.state.showBrFlag}
            showEnFlag={this.state.showEnFlag}
            showSpFlag={this.state.showSpFlag}
            chosenLanguage={this.state.chosenLanguage}
            modalChildUrls={this.state.chosenConfig.urlString}
            blockButtonCloseModal={
              this.state.chosenConfig.multiConfig.blockButtonCloseModal
            }
          >
            {this.renderModalContent()}
          </Modal>

          <Loading
            open={this.state.openLoading}
            message={this.state.loadingMessage}
          />
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  const message = state.config.find((item) => {
    return item.codeScreen === "PASSWORD-MESSAGE";
  });

  return {
    user: state.user,
    config: state.config,
    event: state.event,
    passwordMessage: message ? message.tooltip : getDic("senha-digite"),
    lang: state.lang,
  };
}

const mapDispatchToProps = {
  setChosenConfig,
  resetChosenConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(ElementTreatment);
