import moment from 'moment';

export function createTokenModel(
    token = "",
    createDate = moment().locale('pt-br').format()
) {
    const res = {
        token: token,
        createDate: createDate,
    }

    return res;
}
