import React from "react"
import 'firebase/firestore';
import 'firebase/auth';
import { firestore } from "../../../utils/firebase";
import { useCollectionData, useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import {
    TextMedium,
    GridCell,
    GridRow
} from '../../../components';
import { getDic } from '../../../assets/i18n/dictionary';



const AllChats = props => {

    const messagesRef = firestore.collection(`messages/${props.eventId}/${props.currentSelected}/public/msgs`);
    const query = messagesRef.orderBy('createdAt', 'asc').limitToLast(props.paginationIndex);
    const [messages] = useCollectionData(query, {idField: 'id'});
    
    const editMsg = async (d, id) => {

        firestore.collection(`messages/${props.eventId}/${props.currentSelected}/public/msgs`)
            .doc(id).set({
            disabled: d
        },{
            merge: true
        }).then(result=>{
            console.log(result)
        })
    }

    return (
        <div>
            {
                messages && messages.reverse().map((e,index)=>
                        <GridRow
                            key={index}
                            style={e.disabled ? {backgroundColor : "#fcacac"} : {backgroundColor: "white"}}
                            autoColor={true ? 'red' : null}
                        >
                                <GridRowCustom editMsg={editMsg} disabled={e.disabled} e={e}/>
                        </GridRow>                
                )
            }
        </div>
    )
}

const GridRowCustom = props => {
    const [disabled, setDisable ] = React.useState()

    React.useEffect(()=>{
        setDisable(props.disabled);
    }, [props.disabled])
    return (
        <React.Fragment>
            <GridCell width="5%" border first>
                <button  
                    onClick={()=> {
                        setDisable(!disabled);
                        props.editMsg(disabled ? false : true, props.e.id)
                    }}
                    style={{...styles.btn_red, display: !disabled ? 'block' : 'none'}}
                    ><span>reprovar</span></button>
                <button 
                    onClick={()=> {
                        setDisable(!disabled);
                        props.editMsg(disabled ? false : true, props.e.id)
                    }}
                    style={{...styles.btn_green, display: disabled ? 'block' : 'none'}}
                    ><span>aprovar</span></button>
                </GridCell>
                <GridCell width="11%" border>
                    <TextMedium>{props.e.createdAt && getCurrentTime(props.e.createdAt.toDate())}</TextMedium>
                </GridCell>
                <GridCell width="15%" border>
                    <TextMedium>{props.e.config}</TextMedium>
                </GridCell>
                <GridCell width="20%" border>
                    <TextMedium>{props.e.user}</TextMedium>
                </GridCell>
                <GridCell width="40%" border>
                    <TextMedium>{props.e.body}</TextMedium>
                </GridCell>
                <GridCell width="10%" border>
                    {disabled ? (
                        <TextMedium>{getDic("desaprovado")}</TextMedium>
                    ) : (
                            <TextMedium>{getDic("aprovado")}</TextMedium>
                        )}
                </GridCell>
            </React.Fragment>
    )
}


const getCurrentTime = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = a.getMonth() > 9 ? a.getMonth() : "0" + a.getMonth();
    var date = a.getDate() > 9 ? a.getDate() : "0" + a.getDate();
    var hour = a.getHours() > 9 ? a.getHours() : "0" + a.getHours();
    var min = a.getMinutes() > 9 ? a.getMinutes() : "0" + a.getMinutes();
    var sec = a.getSeconds() > 9 ? a.getSeconds() : "0" + a.getSeconds();
    var time =
      date + "/" + month + "/" + year + ",\n" + hour + ":" + min;
    return time;
  };

  const styles = {
    field: {
        fontFamily: "verdana",
        fontSize: "25px",
        minWidth: 160,
        maxWidth: 160,
        background: "white",
        textAlign: "left",
        borderRadius: 5,
    },
    btn_red: {
        color: "white",
        background: "#f44336",
        padding: 5,
        borderRadius: 6,
        border: 0,
        fontSize: 13,
        cursor: "pointer",
        display: "block",
        textAlign: "center",
        position: "relative",
        paddingRight: 22,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: 57,
        margin: 3
    },
    btn_green: {
        color: "white",
        background: "#4caf50",
        padding: 5,
        borderRadius: 6,
        border: 0,
        fontSize: 13,
        cursor: "pointer",
        display: "block",
        textAlign: "center",
        position: "relative",
        paddingRight: 22,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: 57,
        margin: 3
    }
};
export default AllChats;