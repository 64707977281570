import React from 'react';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const AvatarProp = props => {
    const {
        children,
        max = 4,
    } = props;

    return (
        <AvatarGroup max={max}>
            {children}
        </AvatarGroup>
    )
};

export default AvatarProp;
