import React from "react";
import { store } from "../store";
import Zendesk from "react-zendesk";

const ShowZendesk = props => {
    const _noZendesk = store.getState().config.find(item => {
        return item.codeScreen === "NO-ZENDESK";
    });
    const _customZendesk = store.getState().config.find(item => {
        return item.codeScreen === "CUSTOM-ZENDESK";
    });

    const _btnColor = store.getState().config.find(item => {
        return item.codeScreen === "ZENDESK-CHAT-COLOR";
    });

    const _btnLabel = store.getState().config.find(item => {
        return item.codeScreen === "ZENDESK-CHAT-ONLINE-TEXT";
    });

    const _chTitle = store.getState().config.find(item => {
        return item.codeScreen === "ZENDESK-CHAT-TEXT";
    });
    const _departments = store.getState().config.find(item => {
        return item.codeScreen == "ZENDESK-CHAT-DEPARTAMENT";
    });

    let _key = "";
    // Suporte IN, TI - Infra
    const _departmentName = _departments ? _departments.urlString.split(";") : [""];
    const _buttonColor = _btnColor ? _btnColor.urlString : '';
    const _buttonLabelOnline = _btnLabel ? 'nda#' + _btnLabel.urlString : 'nda#Chat';
    const _buttonLabelOffline = _btnLabel ? 'nda#' + _btnLabel.urlString : 'nda#Suporte';
    const _chatTitle = _chTitle ? 'nda#' + _chTitle.urlString : 'nda#Help Center';
    var setting = {
        contactOptions: {
            enabled: false,
            contactButton: {
                '*': 'Get in Touch'
            },
            // The Chat Label on the Contact Options window
            chatLabelOnline: {
                '*': 'Enjoy Live Chat'
            },
            chatLabelOffline: {
                '*': 'Chat is Offline'
            },
            // The Contact Form Label
            contactFormLabel: {
                '*': 'Leave us a message'
            }
        },
        // The Widget Color
        color: {
            theme: _buttonColor
        },
        launcher: {
            // The Web Widget button title (HC/Contact Form are On)
            label: {
                '*': _buttonLabelOffline.replace("nda#", "")
            },
            // The Web Widget button title (HC is Off)
            chatLabel: {
                '*': _buttonLabelOnline.replace("nda#", "")
            },
        },
        helpCenter: {
            // Sets the title of the Help Center Window
            title: {
                '*': 'ACME SUPPORT'
            }
        },
        contactForm: {
            title: {
                '*': _chatTitle.replace("nda#", "")
            }
        },
        chat: {
            title: {
                '*': _chatTitle.replace("nda#", "")
            },
            departments: {
                // enabled: ['']
                enabled: _departmentName,
                //select: _departmentName[0].el
            },
        },
        talk: {
            title: {
                '*': _chatTitle.replace("nda#", "")
            }
        },
    };

    if (_customZendesk) {
        _key = _customZendesk.url[0];
    } else {
        _key = "ad96244e-0613-4d87-b305-16390cf3addd";
    }
    let zendeskDOM;
    if (_noZendesk) {
        let helpButton = document.getElementById("launcher");
        if (helpButton) {
            helpButton.style.display = "none";
        }
        return null;
    }
    else {
        let helpButton = document.getElementById("launcher");
        if (helpButton) {
            setTimeout(() => {
                helpButton.style.visibility = 'visible';
            }, 1000);
            return null;
        }
        return <Zendesk zendeskKey={_key} {...setting} onLoaded={() => console.log('Welcome to zendesk!')} />;
    }

}

export default ShowZendesk;
/*
export function showZendesk() {

    const _noZendesk = store.getState().config.find(item => {
        return item.codeScreen === "NO-ZENDESK";
    });
    const _customZendesk = store.getState().config.find(item => {
        return item.codeScreen === "CUSTOM-ZENDESK";
    });

    const _btnColor = store.getState().config.find(item => {
        return item.codeScreen === "ZENDESK-CHAT-COLOR";
    });

    const _btnLabel = store.getState().config.find(item => {
        return item.codeScreen === "ZENDESK-CHAT-ONLINE-TEXT";
    });

    const _chTitle = store.getState().config.find(item => {
        return item.codeScreen === "ZENDESK-CHAT-TEXT";
    });
    const _departments = store.getState().config.find(item => {
        return item.codeScreen == "ZENDESK-CHAT-DEPARTAMENT";
    })


    let _key = "";
    // Suporte IN, TI - Infra
    const _departmentName = _departments ? _departments.urlString.split(";") : [""];
    const _buttonColor = _btnColor ? _btnColor.urlString : '';
    const _buttonLabel = _btnLabel ? 'nda#'+_btnLabel.urlString : 'nda#Chat';
    const _chatTitle = _chTitle ? 'nda#'+_chTitle.urlString : 'nda#Help Center';

    if (_customZendesk) {
        _key = _customZendesk.url[0];
    } else {
        _key = "d39d03a9-c2b6-4379-9210-67500b9d2f2e";
    }

    if (_noZendesk) {
        let helpButton = document.getElementById("launcher");
        if (helpButton) {
            helpButton.style.display = "none";
        }
    }
    else {
        //Esconder botão para acesso anonimo da página de upload e relatório
        if (
            !window.location.pathname.includes("upload") &&
            !window.location.pathname.includes("surveyreport") &&
            !window.location.pathname.includes("chat")
        ) {
            let helpButton = document.getElementById("launcher");
            if (helpButton) {
                helpButton.style.display = "block";
            }
            else {
                var tag = document.createElement("script");
                tag.async = false;
                tag.id = "ze-snippet";
                tag.src =
                    `https://static.zdassets.com/ekr/snippet.js?key=${_key}`;
                document.getElementById("root").appendChild(tag);

                window.runWidgetLogic = function(){
                    'use strict';
                    // Global Widget configuration
                    var globalConfig = {
                        webWidget: {
                            contactOptions: {
                                enabled: false,
                                contactButton: {
                                    '*': 'Get in Touch'
                                },
                                // The Chat Label on the Contact Options window
                                chatLabelOnline: {
                                    '*': 'Enjoy Live Chat'
                                },
                                chatLabelOffline: {
                                    '*': 'Chat is Offline'
                                },
                                // The Contact Form Label
                                contactFormLabel: {
                                    '*': 'Leave us a message'
                                }
                            },
                            // The Widget Color
                            color: {
                                theme: _buttonColor
                            },
                            launcher: {
                                // The Web Widget button title (HC/Contact Form are On)
                                label: {
                                    '*': _buttonLabel.replace("nda#","")
                                },
                                // The Web Widget button title (HC is Off)
                                chatLabel: {
                                    '*': _buttonLabel.replace("nda#","")
                                },
                            },
                            helpCenter: {
                                // Sets the title of the Help Center Window
                                title: {
                                    '*': 'ACME SUPPORT'
                                }
                            },
                            contactForm: {
                                title: {
                                  '*': _chatTitle.replace("nda#","")
                                }
                              },
                            chat: {
                                title: {
                                    '*': _chatTitle.replace("nda#","")
                                },
                                departments: {
                                    // enabled: ['']
                                    enabled: _departmentName,
                                    //select: _departmentName[0].el
                                },
                            },
                            talk: {
                                title: {
                                  '*': _chatTitle.replace("nda#","")
                                }
                            },
                        }
                    };

                    var ze_module = {};

                    ze_module.init = function(HCSearch, chat, contactForm) { // init Widget logic
                        if (window.zE) {
                            window.onload = _applyWidgetConfig(HCSearch, chat, contactForm);
                        } else {
                            console.log("ERROR: No Web Widget running on the page");
                            return;
                        }
                    };
                    function _applyWidgetConfig(HCSearch, chat, contactForm) { // execute Widget logic
                        _setLocale();
                        _updatePath()
                        _updateGlobalSettings(HCSearch, chat, contactForm);
                        _updateChatSettings();
                    }
                    function _setLocale(locale) {  // set the Widget language
                        window.zE('webWidget', 'setLocale', locale || 'en');
                    }
                    function _updatePath(pathObject) {  // update the chat visitor’s webpath.
                        window.zE('webWidget', 'updatePath', pathObject);
                    }
                    function _setSurpressSettingProp(prop, val) { // extend config with surpress logic
                        if (window.zESettings && window.zESettings.webWidget) {
                            var ww = window.zESettings.webWidget;
                            if (ww[prop]) ww[prop].suppress = val; else ww[prop] = { "suppress": val }
                        } else console.log("Widget is missing window.zESettings object.")
                    }
                    function _updateGlobalSettings(HCSearch, chat, contactForm) { // update global Widget settings
                        window.zESettings = globalConfig;
                        _setSurpressSettingProp('helpCenter', !HCSearch);
                        _setSurpressSettingProp('contactForm', !contactForm);
                        _setSurpressSettingProp('chat', !chat);
                        _adjustChatLabel(HCSearch, chat, contactForm)
                    }
                    function _adjustChatLabel(HCSearch, chat, contactForm) { // change launcher label if contact options and chat are enabled
                        // when contact options and chat are enabled Widget shows "Chat now" from webWidget.launcher.chatLabel,
                        // however, it opens contact options instead of chat.
                        // this function displays "Need Help?" from webWidget.launcher.label so label will make more sense
                        var wwConf = window.zESettings.webWidget;
                        if (wwConf.contactOptions && wwConf.contactOptions.enabled && wwConf.launcher) {
                            if (!(chat && !HCSearch && !contactForm)) wwConf.launcher.chatLabel = wwConf.launcher.label;
                        }
                    }
                    function _updateChatSettings() { // update Chat settings when chat is connected
                        window.zE('webWidget:on', 'chat:connected', function() {
                            window.zE('webWidget:on', 'chat:status', function(status) {
                            //var department_status = window.zE('webWidget:get', 'chat:department', departmentName);
                            //if (department_status && department_status.status === 'online') {
                                 // ONLINE LOGIC
                                 // window.zE('webWidget', 'updateSettings', onlineChatConfig);
                            //} else {
                                // OFFLINE & UNDEFINED LOGIC (undefined = a department with this name doesn't exist OR the department has been disabled)
                                // suppress the Chat channel as the targeted department is offline
                                //window.zE('webWidget', 'updateSettings', { webWidget: { chat: { suppress: true }} });
                            //}
                          });
                        });
                    }
                    return ze_module;
                };
                let a = 'a';
                var search = setInterval(()=>{
                    if(window.zE && !window.zESettings && _buttonLabel.indexOf("nda#") > -1 && _chatTitle.indexOf("nda#") > -1){
                        window.runWidgetLogic().init(false, true, true);
                        clearInterval(search);
                    }
                },1)
            }
        }
    }
}
*/
