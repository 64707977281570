import axios from 'axios';
import { store } from "../store";
import { getUrl, getToken } from './apiService';
import { createAttributeModel } from '../models/attributeModel';

export async function getAttributes(eventId) {

    var method = getUrl().hall + 'event/attribute/list/' + eventId.toString();
    const token = await getToken();

    return new Promise((resolve, reject) => {

        const options = {
            url: method,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + token
            },
        };
        axios(options)
            .then(res => {
                if (res.data.success) {
                    const attributes = [];

                    res.data.data.forEach(item => {
                        const attribute = createAttributeModel(
                            item.id,
                            item.eventId,
                            item.name,
                            item.tgMandatory === 1 ? true : false,
                            item.maxLenght ? item.maxLenght : -1,
                            item.dbReference === "CPF" ? 0 : item.order,
                            item.mask,
                            item.type,
                            item.value,
                            item.dbReference
                        )
                        attributes.push(attribute);
                    });

                    //Ordenar atributos
                    attributes.sort(function (a, b) {
                        return a.order - b.order
                    });

                    resolve(attributes);
                }
                else {
                    reject(res.data);
                }
            })
            .catch(err => {
                console.log("Erro getEvent", err);
                reject(err);
            })
    });
}

export async function getGroupListApi() {

    const token = await getToken();
    const event = store.getState().event;
    var method = getUrl().hall + 'event/attribute/group/' + event.id.toString();

    return new Promise((resolve, reject) => {

        const options = {
            url: method,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + token
            },
        };
        axios(options)
            .then(res => {
                if (res.data && res.data.success) {
                    const _groupList = [];

                    res.data.data.forEach(item => {
                        var _obj = {
                            text: item.nameGroup,
                            value: item.id,
                        }
                        _groupList.push(_obj)
                    });

                    resolve(_groupList);
                }
                else {
                    reject(res.data);
                }
            })
            .catch(err => {
                console.log("Erro getGroupListApi", err);
                reject(err);
            })
    });
}
