export const SET_ACCESS = 'SET_ACCESS';
const setAccessLocal = access => ({
    type: SET_ACCESS,
    access
});
export function setAccess(access) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setAccessLocal(access));
            resolve(true);
        });
    };
}

export const DELETE_ACCESS = 'DELETE_ACCESS';
const deleteAccessLocal = access => ({
    type: DELETE_ACCESS,
    access
});
export function deleteAccess(access) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(deleteAccessLocal(access));
            resolve(true);
        });
    };
}

export const RESET_ALL_ACCESS = 'RESET_ALL_ACCESS';
const resetAllAccessLocal = () => ({
    type: RESET_ALL_ACCESS
});
export function resetAllAccess() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetAllAccessLocal());
            resolve(true);
        });
    };
}
