import React from "react";
import { Router } from "react-router-dom";
import { getDic } from "./assets/i18n/dictionary";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import Routes from "./routes";
import history from "./services/history";
import { persistor, store } from "./store";
import { initThreads } from "./threads";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import ScreenRotationIcon from "@material-ui/icons/ScreenRotation";
import { isMobile } from "react-device-detect";

//Imports de components
import { Container, FormRow, TextBig, PushV2 } from "./components";

export default function App() {

  //Impedir que a página seja aberta como iframe
  var inteegraHost = true;

  // try {
  //   if (window.parent && window.parent.location && window.parent.location.hostname &&
  //     window.parent.location.hostname !== "localhost" &&
  //     window.parent.location.hostname !== "hall.inteegra.com.br"
  //   ) {
  //     inteegraHost = false
  //     throw new Error();
  //   }
  // }
  // catch (e) {
  //   alert("Para visualizar este conteúdo acesse https://hall.inteegra.com.br" + window.parent.location.pathname);
  // }

  if (inteegraHost) {
    //Iniciar threads
    initThreads();

    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <PushV2 />
          <Router history={history}>
            <Routes />
          </Router>
        </PersistGate>
      </Provider>
    );
  } else {
    return null;
  }
}
