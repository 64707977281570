import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeProp = props => {
    const { value = "" } = props;

    return (
        <QRCode value={value} />
    )
};

export default QRCodeProp;
