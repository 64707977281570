import React from "react";
import { connect } from "react-redux";
import { getDic } from "../assets/i18n/dictionary";
import { insertNewAccess, getSystemActions } from "../services/accessService";
import { getAnswersByQuestionAndGuestApi } from "../services/questionsService";
import {
  getFirstAccessExcelReport,
  getScheduleFirstAccessExcelReport,
  getStandFirstAccessExcelReport,
  getChatExcelReport,
  getClicableScheduleExcelReport,
  getQuestionsAndAnswersExcelReport
} from "../services/excelReportsService";
import { createFormControlModel } from '../models/formControlModel';
import { validateForm } from '../utils';
import moment from 'moment';

import {
  Container,
  MenuButton,
  BackButton,

  FormRow,
  Button,
  FormColumn,
  Loading,
  Alert,
  InputFieldOutlined,
  SelectList,
  TextSmall
} from "../components";

const AdminReportsPage = (props) => {
  const [state, setState] = React.useState(true);
  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("salvando"));
  const [openLoading, setOpenLoading] = React.useState(false);

  //Monta lista de relatórios
  const [reportsList, setReportsList] = React.useState([
    { value: 0, text: `${getDic("primeiro")} ${getDic("acesso")} ${getDic("evento")}` },
    { value: 1, text: `${getDic("primeiro")} ${getDic("acesso")} ${getDic("programacao")}` },
    { value: 2, text: `${getDic("primeiro")} ${getDic("acesso")} Stand` },
    { value: 3, text: `Chat` },
    { value: 4, text: `Click ${getDic("configuracoes")}` },
    { value: 5, text: `${getDic("perguntas")} ${getDic("e")} ${getDic("respostas")}` },
    { value: 6, text: `${getDic("respostas")} ${getDic("trilha-do-conhecimento")}` },
  ]);
  const [reportsListValue, setReportsListValue] = React.useState(0);

  //Monta formulário de busca
  const form = [];
  const initialDate = createFormControlModel(0, `${getDic("data")} ${getDic("inicio")}`, moment().format(), "datetime-local");
  form.push(initialDate);
  const endDate = createFormControlModel(1, `${getDic("data")} ${getDic("fim")}`, moment().format(), "datetime-local");
  form.push(endDate);
  const [formParams, setFormParams] = React.useState(form);

  React.useEffect(() => {

    insertNewAccess(props.match.url, "", getSystemActions().accessPage);

    return () => {
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    };
  }, []);

  const getReport = () => {
    setLoadingMessage(getDic("carregando"));
    setOpenLoading(true);

    var validation = validateForm(formParams);

    if (validation.isValid) {
      // Verifica se a data de início não é maior que a data final
      const _diff = moment(formParams[1].value).diff(formParams[0].value);
      const _diffDuration = moment.duration(_diff);

      if (_diffDuration._milliseconds < 0) {
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("data-erro-inicio-maior"));
        setAlertOpen(true);
        setOpenLoading(false);
      }
      else if (_diffDuration._data.days > 10) {
        setAlertTitle(getDic("erro"));
        setAlertMessage(`${getDic("data-erro-limite-maior")} 10 ${getDic("dia")}s`);
        setAlertOpen(true);
        setOpenLoading(false);
      }
      else {
        if (reportsListValue === 0) {
          getFirstAccessReport();
        }
        if (reportsListValue === 1) {
          getScheduleFirstAccessReport();
        }
        if (reportsListValue === 2) {
          getStandFirstAccessReport();
        }
        if (reportsListValue === 3) {
          getChatReport();
        }
        if (reportsListValue === 4) {
          getClicableSchedule();
        }
        if (reportsListValue === 5) {
          getQuestionsAndAnswers();
        }
        if (reportsListValue === 6) {
          getAnswersByQuestionAndGuest();
        }
      }
    }
    else {
      setFormParams(validation.form);
      setOpenLoading(false);
    }
  }
  const getQuestionsAndAnswers = () => {
    getQuestionsAndAnswersExcelReport(formParams[0].value, formParams[1].value)
      .then(res => {
        downloadFile(res);
      })
      .catch(err => {
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setAlertOpen(true);
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  const getClicableSchedule = () => {
    getClicableScheduleExcelReport(formParams[0].value, formParams[1].value)
      .then(res => {
        downloadFile(res);
      })
      .catch(err => {
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setAlertOpen(true);
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  const getChatReport = () => {
    getChatExcelReport(formParams[0].value, formParams[1].value)
      .then(res => {
        downloadFile(res);
      })
      .catch(err => {
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setAlertOpen(true);
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  const getFirstAccessReport = () => {
    getFirstAccessExcelReport(formParams[0].value, formParams[1].value)
      .then(res => {
        downloadFile(res);
      })
      .catch(err => {
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setAlertOpen(true);
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  const getScheduleFirstAccessReport = () => {
    getScheduleFirstAccessExcelReport(formParams[0].value, formParams[1].value)
      .then(res => {
        downloadFile(res);
      })
      .catch(err => {
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setAlertOpen(true);
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  const getStandFirstAccessReport = () => {
    getStandFirstAccessExcelReport(formParams[0].value, formParams[1].value)
      .then(res => {
        downloadFile(res);
      })
      .catch(err => {
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setAlertOpen(true);
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  const getAnswersByQuestionAndGuest = () => {
    getAnswersByQuestionAndGuestApi(formParams[0].value, formParams[1].value)
      .then(res => {
        downloadFile(res);
      })
      .catch(err => {
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setAlertOpen(true);
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  const downloadFile = (data) => {
    var _data = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data;
    let a = document.createElement("a");
    a.href = _data;
    a.download = reportsList[reportsListValue].text + ".xlsx";
    a.click();
  }

  const onChangeHandler = (index, value) => {
    var data = formParams;
    data[index].value = value
    setFormParams(data);
    setState(state === true ? false : true);
  }

  return (
    <Container background="#e8eced" align="center">
      <MenuButton history={props.history} />
      <BackButton
        history={props.history}
        color="#212121"
      >
        {getDic("voltar").toUpperCase()}
      </BackButton>


      <FormColumn>
        <FormRow
          minHeight="60px"
          height="5vh"
          align="center"
          background="#e8eced"
          margin="0px"
        />
        <FormRow margin="0px" minHeight="60px" align="center" paddingTop="5px">
          <SelectList
            title={getDic("relatorio")}
            width="600px"
            value={reportsListValue}
            menuItens={reportsList}
            onChange={async e => setReportsListValue(e.target.value)}
          />
        </FormRow>
        <FormRow margin="0px" minHeight="60px" align="center">
          <div style={{ maxWidth: "300px", padding: 5 }}>
            <InputFieldOutlined
              key={formParams[0].id}
              type={formParams[0].type}
              value={formParams[0].value}
              title={formParams[0].name}
              placeholder={formParams[0].name}
              required={formParams[0].required}
              error={formParams[0].error}
              errorMessage={formParams[0].errorMessage}
              onChange={e => onChangeHandler(0, e.target.value)}
            />
          </div>
          <div style={{ maxWidth: "300px", padding: 5 }}>
            <InputFieldOutlined
              key={formParams[1].id}
              type={formParams[1].type}
              value={formParams[1].value}
              title={formParams[1].name}
              placeholder={formParams[1].name}
              required={formParams[1].required}
              error={formParams[1].error}
              errorMessage={formParams[1].errorMessage}
              onChange={e => onChangeHandler(1, e.target.value)}
            />
          </div>
          <div style={{ maxWidth: "300px", padding: 5 }}>
            <Button
              text={getDic("buscar")}
              onClick={() => getReport()}
            />
          </div>
        </FormRow>
        <FormRow margin="0px" minHeight="60px" align="center" paddingTop="5px">
          <TextSmall>
            {getDic("periodo-maximo")} 10 {getDic("dia")}s
          </TextSmall>
        </FormRow>
      </FormColumn>

      <Alert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={() => setAlertOpen(false)}
        title={alertTitle}
        message={alertMessage}
      />

      <Loading open={openLoading} message={loadingMessage} />
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(AdminReportsPage);
