import React from "react";
import exportFromJSON from "export-from-json";
import { Row, Button, Col, Card, CardBody, CardTitle, Alert } from "reactstrap";
import moment from "moment";
import PieChart from "./PieChart";
import UsersLocations from "./UsersLocations";

const getStatusUser = (date) => {
  const dt = new Date();
  dt.setMinutes(dt.getMinutes() - 5);
  if (new Date(date) > dt) return true;
  else return false;
};

const ChartOnlineUsers = ({ modalType, setOpenModal, openModal, listData }) => {
  const toggle = () => setOpenModal(!openModal);

  React.useEffect(() => {
    console.log("atualizou");
  }, []);
  return (
    <React.Fragment>
      <div
        className={`modal ${!openModal && "fade"} show`}
        style={{
          display: openModal ? "block" : "none",
          background: "#bbb6b65c",
        }}
        role="dialog"
        tabIndex={-1}
      >
        {modalType == "OPEN_USERS_ONLINE_EXCEL" && (
          <ConfirmExcellUserOnline
            toggle={toggle}
            setOpenModal={setOpenModal}
            listData={listData.filter((e) =>
              getStatusUser(
                e.accessDate && e.accessDate && e.accessDate != undefined
                  ? e.accessDate.toDate()
                  : 0
              )
            )}
          />
        )}
        {modalType == "OPEN_USERS_ONLINE_CHART" && (
          <GraphOnlineUsers
            toggle={toggle}
            setOpenModal={setOpenModal}
            listData={listData.filter((e) =>
              getStatusUser(
                e.accessDate && e.accessDate != undefined
                  ? e.accessDate.toDate()
                  : 0
              )
            )}
          />
        )}
        {modalType == "OPEN_TOTAL_ACCESS_EXCEL" && (
          <ConfirmExcellAllUser
            toggle={toggle}
            setOpenModal={setOpenModal}
            listData={listData}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const GraphOnlineUsers = ({ toggle, listData, setOpenModal }) => {
  return (
    <div className="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">#Estatisticas de Usuários Online</h4>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={toggle}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Gráfico </CardTitle>
                  <PieChart listData={listData} />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <UsersLocations listData={listData} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const ConfirmExcellAllUser = ({ toggle, listData, setOpenModal }) => {
  const [loading, setLoading] = React.useState(false);
  const [csvData, setCsvData] = React.useState(null);
  const generateExcell = () => {
    setLoading(true);
    const newStructure = listData.map((e, i) => {
      return {
        loginId: e.loginId,
        login: e.login,
        configId: e.configId,
        page: e.page,
        scheduleId: e.scheduleId,
        description: e.description,
        accessDate: moment(e.accessDate.toDate())
          .zone("-0300")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
    });
    const fileName = `relatório-${new Date().getDate()}-${new Date().getMonth() + 1
      }-${new Date().getFullYear()}-${new Date().getHours()}:${new Date().getMinutes()}`;
    const exportType = "xls";
    exportFromJSON({ data: newStructure, fileName, exportType });
    setLoading(false);
    setCsvData(newStructure);
  };
  React.useEffect(() => {
    console.log("quantas vezes");
  }, []);
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Atenção</h4>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={toggle}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <div className="text-center" style={{ paddingBottom: 50 }}>
              Deseja gerar o relatório de todos os usuarios?
            </div>
          </Row>
          <div className="text-center" style={{ paddingBottom: 10 }}>
            <React.Fragment>
              <Alert color="primary" style={{ opacity: 1 }}>
                <span>
                  Relatório gerado com sucesso! <br />
                  <span className="btn alert-link" onClick={generateExcell}>
                    Clique aqui para baixar.
                  </span>
                </span>
              </Alert>
            </React.Fragment>
          </div>
          <div className="text-center">
            {/* {!loading && (
              <Button
                type="button"
                color="primary"
                className="w-md"
                onClick={() => generateExcell()}
              >
                GERAR
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

const ConfirmExcellUserOnline = ({ toggle, listData, setOpenModal }) => {
  const [loading, setLoading] = React.useState(false);
  const [csvData, setCsvData] = React.useState(null);
  const generateExcell = () => {
    setLoading(true);
    const newStructure = listData.map((e, i) => {
      return {
        loginId: e.loginId,
        login: e.login,
        configId: e.configId,
        page: e.page,
        scheduleId: e.scheduleId,
        description: e.description,
        accessDate: moment(e.accessDate.toDate())
          .zone("-0300")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
    });
    const fileName = `relatório-${new Date().getDate()}-${new Date().getMonth() + 1
      }-${new Date().getFullYear()}-${new Date().getHours()}:${new Date().getMinutes()}`;
    const exportType = "xls";
    exportFromJSON({ data: newStructure, fileName, exportType });
    setLoading(false);
    setCsvData(newStructure);
  };
  React.useEffect(() => {
    console.log("quantas vezes");
  }, []);
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Atenção</h4>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={toggle}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <div className="text-center" style={{ paddingBottom: 50 }}>
              Deseja gerar o relatório de usuarios online?
            </div>
          </Row>
          <div className="text-center" style={{ paddingBottom: 10 }}>
            <React.Fragment>
              <Alert color="primary" style={{ opacity: 1 }}>
                <span>
                  Relatório gerado com sucesso! <br />
                  <span className="btn alert-link" onClick={generateExcell}>
                    Clique aqui para baixar.
                  </span>
                </span>
              </Alert>
            </React.Fragment>
          </div>
          <div className="text-center">
            {/* {!loading && (
              <Button
                type="button"
                color="primary"
                className="w-md"
                onClick={() => generateExcell()}
              >
                GERAR
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartOnlineUsers;
