import React from 'react';
import Typography from '@material-ui/core/Typography';

const AppBarText = props => {
    const {
        text = "",
        align = "center"
    } = props;

    const styles = {
        title: {
            flexGrow: '1',
            textAlign: align,
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    };

    return (
        <Typography variant="h6" style={styles.title}>
            {text}
        </Typography>
    )
};

export default AppBarText;
