import React from "react";
import {
  FeedData,
  VideoPlayer,
  FormRow,
  AudioPlayer
} from "../../../components";
import { getDic } from "../../../assets/i18n/dictionary";
import { store } from '../../../store';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ChatIcon from '@material-ui/icons/Chat';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CommentElement from "./CommentElement"
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setLike } from "../../../services/feedService";
import Badge from '@material-ui/core/Badge';
import moment from 'moment';
import 'moment/locale/es';
import * as fileService from '../../../services/fileService';

const convertToTime = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate() > 9 ? a.getDate() : "0" + a.getDate();
  var hour = a.getHours() > 9 ? a.getHours() : "0" + a.getHours();
  var min = a.getMinutes() > 9 ? a.getMinutes() : "0" + a.getMinutes();
  var sec = a.getSeconds() > 9 ? a.getSeconds() : "0" + a.getSeconds();
  var dateNow = new Date();

  var time1 = `${getDic("postado")} ${getDic("hoje")} ${getDic("as")} ${hour}:${min}`;
  const language = store.getState().lang.lang === "sp" ? "es" : store.getState().lang.lang;
  var _date = `${moment(a).locale(language).format('dddd, LL ')} ${getDic("as")} ${moment(a).locale(language).format(' LT')}`;
  var time2 = `${getDic("postado")} ${_date}`;

  return UNIX_timestamp
    ? dateNow.getDate() == a.getDate()
      ? time1
      : time2
    : "";
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 785,
    marginBottom: 10,
    background: "rgba(232,236,237,0.96)"
  },
  media: {
    height: 'auto',
    width: "100%",
    background: "rgba(232,236,237,1)"
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  textContent: {
    padding: 10
  },
  headerFix: {
    padding: 7,
    // position: 'relative'
  },
  spanPost: {
    outline: "0px!important",
    background: "#f0f2f5",
    borderRadius: 19,
    padding: 11,
    color: '#65676b',
    marginLeft: 10,
    width: "100%",
    border: 0,
    resize: "none",
    fontFamily: "inherit",
    transition: "0.15s cubic-bezier(0, 0, 0.66, 1.08)"
  },
  alignRow: {
    display: "flex",
    flexDirection: "row"
  },
  avatarSizeB: {
    width: 36,
    height: 36
  }
}));

const FeedCard = props => {
  const classes = useStyles();
  const [firstLoading, setFirstLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const inputTextAreaComment = React.useRef(null)
  const [commentContent, setCommentContent] = React.useState("");
  const [loadingNewPosts, setLoadingNewPosts] = React.useState(false);
  const ContextRef = React.useContext(FeedData);
  const [deleteOpen, setdeleteOpen] = React.useState(false);
  const [likes, setLikes] = React.useState([]);
  const [tempLikeCount, setTempLikeCount] = React.useState(0);
  const popUpMenu = React.useRef(null);
  const handleClickOutside = event => {
    if (popUpMenu && !popUpMenu.current.contains(event.target)) {
      setdeleteOpen(false);
    }
  }

  const checkAlreadyLiked = () => {
    const alreadyLike = props.postContent.likes.filter(e => e.guestId == ContextRef._user.id);
    const localLikesTemp = likes.filter(e => e.guestId == ContextRef._user.id);
    return alreadyLike.length < 1 || localLikesTemp.length < 1 ? false : true;
  }
  const setLikePost = _p => {
    const alreadyLike = props.postContent.likes.filter(e => e.guestId == ContextRef._user.id);
    const localLikesTemp = likes.filter(e => e.guestId == ContextRef._user.id)
    //console.log(alreadyLike, localLikesTemp);
    if (localLikesTemp.length < 1 && tempLikeCount < 1) {
      setTempLikeCount(1);
      ContextRef._sendLikeToPost({
        postId: props.postContent.id
      }).then(result => {
        setTempLikeCount(0);
        setLikes([...likes, { id: result.data, guestId: ContextRef._user.id }]);
        //console.log("like registrado", {id: result.data, guestId: ContextRef._user.id})
      })
    } else {
      if (localLikesTemp.length > 0) {
        localLikesTemp.forEach(l => {
          //console.log("Deletando...", l)
          ContextRef._removeLike({
            likeId: l.id
          })
        });
      } else {
        alreadyLike.forEach(l => {
          //console.log("Deletando...", l)
          ContextRef._removeLike({
            likeId: l.id
          })
        });
      }
      const otherLikes = props.postContent.likes.filter(e => e.guestId != ContextRef._user.id);
      setLikes(otherLikes);
      setTempLikeCount(0);
    }
  }

  const sendPostComment = _c => {
    if (commentContent.length > 1 && !loadingNewPosts) {
      setLoadingNewPosts(true);
      ContextRef._commentPost({ postId: props.postContent.id, text: commentContent });
      ContextRef._getPosts({ pageSize: 11, pageIndex: 1 }).then(() => {
        setCommentContent("");
        setLoadingNewPosts(false);
      })
    }
  }
  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendPostComment();
    }
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    setLikes(props.postContent.likes);
    return (() => {
      document.removeEventListener('mousedown', handleClickOutside);
    })
  }, [props.postContent]);

  return <Card className={classes.root}>
    <Typography
      variant="body2"
      color="textSecondary"
      component="p"
      style={{ color: 'white', height: 0, overflow: "hidden" }}
    >
      This impressive paella is a perfect party dish and a fun meal to cook together with your
      guests. Add 1 cup of frozen peas along with the mussels, if you like.
    </Typography>
    <CardHeader
      className={classes.headerFix}
      avatar={
        <Avatar
          aria-label="recipe"

          src={props.postContent.guest.urlImage}
        >
          {props.postContent.guest.name[0]}
        </Avatar>
      }
      action={
        <React.Fragment>
          {
            props.postContent.guestId == ContextRef._user.id &&
            <IconButton aria-label="settings" onClick={() => setdeleteOpen(!deleteOpen)}>
              <MoreVertIcon />
            </IconButton>
          }
          <div ref={popUpMenu} style={{ position: 'relative', top: -45 }}>
            {deleteOpen && <div className="baloon-card"> <span onClick={() => { setdeleteOpen(!deleteOpen); ContextRef._removePostNow(props.postContent.id) }}>{getDic("deletar")}</span> </div>}
          </div>
        </React.Fragment>
      }
      title={props.postContent.guest.name}
      subheader={convertToTime(props.postContent.createdDate)}
    />
    <Typography variant="body2" component="p" className={classes.textContent}>
      {props.postContent.text}
    </Typography>
    {props.postContent.url && fileService.checkFileFormat(props.postContent.url) === 'video' &&
      <FormRow margin='0px' align='center'>
        <VideoPlayer
          src={props.postContent.url}
          width={'230px'}
          heigth={'230px'}
        />
      </FormRow>
    }
    {props.postContent.url && fileService.checkFileFormat(props.postContent.url) === 'audio' && (
      <FormRow margin='0px' align='center'>
        <AudioPlayer
          src={props.postContent.url}
        />
      </FormRow>
    )}
    {props.postContent.url && fileService.checkFileFormat(props.postContent.url) === 'image' &&
      <img
        className={classes.media}
        src={props.postContent.url}
      />}
    <CardActions disableSpacing>
      <IconButton
        style={{ color: likes.filter(e => e.guestId == ContextRef._user.id).length > 0 || tempLikeCount > 0 ? '#d35454' : '#868686' }}
        onClick={() => setLikePost()}
      >
        <FavoriteIcon />
      </IconButton>
      <span style={{
        fontWeight: 600,
        color: "#6f6f6f",
        fontSize: 13
      }}>{likes.length + tempLikeCount} {getDic("curtidas")}</span>

      <IconButton
        className={classes.expand}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <Badge badgeContent={props.postContent.comments.length} color="primary">
          <ChatIcon />
        </Badge>
      </IconButton>
    </CardActions>
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent style={{ borderTop: "1px solid #f0f2f5" }}>
        <div className={classes.alignRow} style={{ width: "100%" }}>
          <Avatar
            aria-label="recipe"
            className={classes.avatarSizeB}
            src={ContextRef._user.image}
          >
            {props.postContent.guest.name[0]}
          </Avatar>
          <div style={{ position: 'relative', width: "100%", display: "flex" }}>
            <textarea
              ref={inputTextAreaComment}
              rows="1"
              cols="50"
              className={classes.spanPost}
              placeholder={`${getDic("comente")} ${getDic("esta")} ${getDic("postagem")}`}
              onChange={e => setCommentContent(e.target.value)}
              value={commentContent}
              onKeyDown={_handleKeyDown}
            />
            {commentContent.length > 1 &&
              <React.Fragment>
                {
                  loadingNewPosts
                    ?
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: 7,
                        right: 15,
                        cursor: "pointer",
                        width: 22,
                        height: 22
                      }} />
                    :
                    <SendIcon style={{
                      position: "absolute",
                      top: 7,
                      right: 15,
                      cursor: "pointer",
                      color: "#757575"
                    }}
                      onClick={() => sendPostComment()} />
                }
              </React.Fragment>
            }
          </div>
        </div>
        {props.postContent.comments.map(e =>
          <CommentElement
            _comment={e}
            guestId={ContextRef._user.id}
            deleteComment={ContextRef._removeComment}
          />
        )}
      </CardContent>
    </Collapse>
  </Card>
}


export default FeedCard;
