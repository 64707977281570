export function createFormControlModel(
    id = 0,
    name = "",
    value = "",
    type = "text", //Formatos padrão HTML5 + email e select
    maxLength = -1,
    mask = "",
    values = "", // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
    required = false,
    error = false,
    errorMessage = "",
    disabled = false,
    dbReference = "",
    placeholder = "",
) {
    //Transforma o values em array
    var valuesList = [];

    if (values && values !== "") {
        if (values.constructor === Array) {
            valuesList = values;
        }
        else {
            var valuesReturn = JSON.parse(values);
            valuesReturn.forEach(item => {
                var select = {
                    value: item.Text ? item.Text.trim() : '',
                    text: item.Text ? item.Text.trim() : '',
                }
                valuesList.push(select);
            });
        }
    }

    //Tratamento para adaptar modelo de mascara do razor para o redux
    var newMask = "";
    if (mask && mask !== "") {
        newMask = mask.replace(/0/g, "9");
    }

    const object = {
        id: id,
        name: name,
        value: value,
        error: error,
        errorMessage: errorMessage,
        required: required,
        type: type,
        values: valuesList,
        maxLength: maxLength,
        mask: newMask,
        disabled: disabled,
        dbReference: dbReference,
        placeholder: placeholder,
    }

    return object;
}
