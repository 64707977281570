import React from "react";

function VideoMp4(props) {
  const urlFormat = props.location.pathname.replace(
    `/${props.match.params.id}/videomp4&url=`,
    ""
  );
  return (
    <div
      style={{
        display: "flex",
        position: 'fixed',
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "black",
      }}
    >
      <video
        width="100%"
        height="100%"
        controls
        controlsList="nodownload"
        src={urlFormat}
        preload="none"
      ></video>
    </div>
  );
  
}

export default VideoMp4;
