import { SET_NEW_NOTIFICATION, MERGE_NOTIFICATIONS, INATIVE_NOTIFICATION, RESET_NOTIFICATIONS } from '../actions';

const INITIAL_STATE = [];

export default function pushNotificationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_NEW_NOTIFICATION:
            return [...state, action.notification];
        case MERGE_NOTIFICATIONS:
            var newState = [];

            action.notifications.forEach(item => {
                var local = state.find(st => {
                    return st.id === item.id;
                });

                if (local) {
                    newState.push(local);
                }
                else {
                    newState.push(item);
                }
            });
            return newState;
        case INATIVE_NOTIFICATION:
            return state.map((item) => (
                item.id === action.notification.id
                    ? { ...item, viewed: true }
                    : item
            ));
        case RESET_NOTIFICATIONS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
