export const SET_EVENT = 'SET_EVENT';
const setEventLocal = event => ({
    type: SET_EVENT,
    event,
});
export function setEvent(event) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setEventLocal(event));
            resolve(true);
        });
    };
}
