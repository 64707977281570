import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const Component = props => {
    const {
        data,
        color = 'category10',
    } = props;

    return (
        <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: color }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabel={function (e) {
                return (
                    <React.Fragment>
                        <tspan style={{ fontSize: "2vh" }} x="0" dy="-0.6em">{e.id + " (" + e.percentage + ")"}</tspan>
                    </React.Fragment>
                );
            }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#fbf9f9"
            sliceLabel={function (e) {
                return (
                    <React.Fragment>
                        {/* <tspan style={{ fontSize: "2vh" }}>{e.value}</tspan> */}
                    </React.Fragment>
                );
            }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            defs={
                [
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
            legends={
                [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        translateY: 56,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        symbolSize: 20,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
        />
    )
};

export default Component;

// const data = [
//     {
//         "id": "php",
//         "label": "php",
//         "value": 223,
//     },
//     {
//         "id": "java",
//         "label": "java",
//         "value": 177,
//     },
//     {
//         "id": "python",
//         "label": "python",
//         "value": 453,
//     },
//     {
//         "id": "javascript",
//         "label": "javascript",
//         "value": 546,
//     },
//     {
//         "id": "hack",
//         "label": "hack",
//         "value": 16,
//     }
// ]
