export const SET_SCHEDULES = 'SET_SCHEDULES';
const setSchedulesLocal = schedules => ({
    type: SET_SCHEDULES,
    schedules,
});
export function setSchedules(schedules) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setSchedulesLocal(schedules));
            resolve(true);
        });
    };
}

export const RESET_SCHEDULES = 'RESET_SCHEDULES';
const resetSchedulesLocal = () => ({
    type: RESET_SCHEDULES
});
export function resetSchedules() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetSchedulesLocal());
            resolve(true);
        });
    };
}
