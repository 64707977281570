import { SET_SPEAKERS } from '../actions';

const INITIAL_STATE = [];

export default function scheduleReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_SPEAKERS:
            return [...state, ...action.speakers];
        default:
            return state;
    }
}
