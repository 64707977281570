import React from 'react';
import { getDic } from "../../assets/i18n/dictionary";
import {
  MenuButton,
  BackButton,
} from "../../components";

const NavigationButtons = props => {
  const {
    history,
  } = props;

  return (
    <div style={{ width: '100%' }}>
      <MenuButton history={history} />
      <BackButton history={history} color="#212121">
        {getDic("voltar").toUpperCase()}
      </BackButton>
    </div>
  )
};

export default NavigationButtons;
