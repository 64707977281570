import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { getConfigListByPage } from '../services/configService';
import { setNewFormDataFile, checkIfFileExists } from '../services/fileService';
import { getFileExtension, convertFileSizeFromBytesToMb } from '../utils';
import Success from '@material-ui/icons/CheckCircleOutline';
import "../scss/UploadPage.scss";

//Imports de components
import {
    Container,
    FormRow,
    TextBig,
    ActivityIndicator,
    FormColumn,
    InputFieldOutlined,
    Button,
    Loading,
    Alert,
    TextSmall,
    MenuButton,
    TextMedium,
    BackButton
} from '../components';

class UploadPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            configList: [],
            success: false,
            // height: "93vh",
            height: "100vh",
            loadingMessage: getDic("enviando"),
            openLoading: false,
            alertOpen: false,
            alertTitle: "",
            alertMessage: "",

            primaryTitle: {
                text: getDic("upload-mensagem"),
                color: "black",
                size: "25px",
            },
            colorBackground: "#e8eced",
            imageBackgroundUrl: "",
            positionContent: "top",

            userId: this.props.isModal ? this.props.user.id.toString() : this.props.match.params.user,
            contractId: this.props.isModal ? this.props.event.contractId.toString() : this.props.match.params.contract,
            category: this.props.isModal ? "INTEEGRA" : this.props.match.params.category,
            isInternal: this.props.isModal ? true : parseInt(this.props.match.params.internal) > 0 ? true : false,
            file: null,
            eventId: this.props.isModal ? this.props.event.id.toString() : this.props.match.params.id,

            //Controle de arquivo
            fileMaxSize: this.props.fileConfig && this.props.fileConfig[0] && this.props.fileConfig[0] !== 0 ? this.props.fileConfig[0] : null,
            fileTypes: this.props.fileConfig && this.props.fileConfig[1] && this.props.fileConfig[1] !== "" ? this.props.fileConfig[1] : null,
            maxNumberOfUploads: this.props.fileConfig && this.props.fileConfig[2] && this.props.fileConfig[2] !== 0 ? this.props.fileConfig[2] : null,
            userFileUploaded: false,
            uploadingFile: false,
            uploadProgress: 0
        }
    }

    async componentDidMount() {
        if (
            this.state.maxNumberOfUploads &&
            this.state.maxNumberOfUploads > 0 &&
            !this.props.user.isAdmin
        ) {
            try {
                await checkIfFileExists();
                this.setState({ userFileUploaded: true });
            } catch (error) {
                if (error.response.status !== 404) {
                    return;
                }
            }
        }

        //Trava para impedir que acesso remoto possa visualizar recursos do sistema
        if (this.state.isInternal) {
            if (!this.props.user || this.props.user.id === 0 ||
                !this.props.event || this.props.event.id === 0
            ) {
                this.props.history.replace("/");
            }
        }

        var configList = [];
        if (!this.props.isModal) {
            //Aplica configurações
            configList = await getConfigListByPage(this.props.match.url);

            configList.find(item => {
                if (item.codeScreen === "COLOR-PAGE") {
                    this.setState({ colorBackground: item.url[0] });
                }
                if (item.codeScreen === "IMAGE-001") {
                    this.setState({ imageBackgroundUrl: item.url[0], colorBackground: "#ffffff00" });
                }
                if (item.codeScreen === "POSITION-CONTENT") {
                    if (item.url[0].toUpperCase() === "CENTER") {
                        this.setState({ positionContent: "center" });
                    }
                    if (item.url[0].toUpperCase() === "BOTTOM") {
                        this.setState({ positionContent: "bottom" });
                    }

                }
                if (item.codeScreen === "TITTLE-001") {
                    var newObj = this.state.primaryTitle;
                    newObj.text = item.tooltip;
                    if (item.url.length > 0) {
                        item.url.forEach(url => {
                            var conf = url.split(":");
                            newObj[conf[0]] = conf[0] === "size" ? conf[1] + "px" : conf[1];
                        });
                    }
                    this.setState({ primaryTitle: newObj })
                }
                return null;
            });
        }

        this.setState({ isLoading: false, configList: configList });

        if (!this.props.isModal) {
            await insertNewAccess(this.props.match.url, "", getSystemActions().accessPage);
        }
    }

    async componentWillUnmount() {
        if (!this.props.isModal) {
            await insertNewAccess(this.props.match.url, "", getSystemActions().exitPage);
        }
    }

    componentDidCatch(e) {
        var error = e.toString();

        if (error.includes("Error: Maximum update depth exceeded")) {
            window.location.reload();
        }
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] })
    }

    sendNewFile() {
        // this.setState({ openLoading: true });
        if (this.state.file) {
            //Verifica se há restrições para envio de arquivo, se sim, checar so o arquivo está de acordo
            var fileSize = convertFileSizeFromBytesToMb(this.state.file);
            var fileType = getFileExtension(this.state.file);
            if (this.state.fileMaxSize && this.state.fileMaxSize > 0 && fileSize > this.state.fileMaxSize) {
                this.setState({
                    alertTitle: getDic("erro"),
                    alertMessage: getDic("arquivo-erro-tamanho"),
                    alertOpen: true,
                    openLoading: false,
                });
            }
            else if (this.state.fileTypes && this.state.fileTypes !== "" && !this.state.fileTypes.toUpperCase().includes(fileType)) {
                this.setState({
                    alertTitle: getDic("erro"),
                    alertMessage: getDic("arquivo-erro-tipo"),
                    alertOpen: true,
                    openLoading: false,
                });
            }
            else {
                setNewFormDataFile(this.state.file, this.state.contractId, this.state.userId, this.state.category, this.state.eventId, this.state.isInternal, (pge => {
                    if (!this.state.uploadingFile) this.setState({ uploadingFile: true });
                    this.setState({ uploadProgress: ((pge.loaded / pge.total) * 100).toFixed(2) });
                }))
                    .then(res => {
                        this.setState({
                            alertTitle: this.props.isModal ? getDic("enviado-sucesso") : "",
                            alertMessage: this.props.isModal ? `URL: ${res.url}` : getDic("enviado-sucesso"),
                            alertOpen: true,
                            file: null,
                            success: true,
                        });
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({
                            alertTitle: getDic("erro"),
                            alertMessage: getDic("enviado-erro"),
                            alertOpen: true
                        });
                    })
                    .finally(() => {
                        // this.setState({ openLoading: false });
                    })
            }
        }
        else {
            this.setState({
                alertTitle: getDic(""),
                alertMessage: getDic("arquivo-nao-selecionado-erro-upload"),
                alertOpen: true,
                openLoading: false,
            });
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Container background="#e8eced" align="center" centralize={true}>
                    <FormRow align="center">
                        <ActivityIndicator />
                    </FormRow>
                </Container>
            );
        }
        if (this.state.userFileUploaded) {
            return (
                <Container background={this.state.colorBackground} align="center" centralize={true}>
                    <img src={this.state.imageBackgroundUrl} style={{ position: "fixed", width: "100%", height: "100%", zIndex: "-2" }} />
                    <FormRow align="center">
                        <TextMedium>{getDic("arquivo-erro-upload-realizado")}</TextMedium>
                    </FormRow>
                </Container>
            );
        }
        if (this.state.success && !this.props.isModal) {
            return (
                <Container background={this.state.colorBackground} align="center" centralize={true}>
                    {this.state.isInternal ? (
                        <div>
                            <MenuButton history={this.props.history} />
                            <BackButton
                                history={this.props.history}
                                color="#212121"
                            >
                                {getDic("voltar").toUpperCase()}
                            </BackButton>

                        </div>
                    ) : (null)}

                    <img src={this.state.imageBackgroundUrl} style={{ position: "fixed", width: "100%", height: "100%", zIndex: "-2" }} />
                    <FormRow align="center">
                        <Success style={{ color: "green", fontSize: 150 }} />
                    </FormRow>
                    <FormRow align="center">
                        <TextBig color="green">{getDic("enviado-sucesso")}!</TextBig>
                    </FormRow>
                </Container>
            )
        }
        return (
            <div>
                {this.state.isInternal ? (
                    <div>
                        <MenuButton history={this.props.history} />
                        <BackButton
                            history={this.props.history}
                            color="#212121"
                        >
                            {getDic("voltar").toUpperCase()}
                        </BackButton>

                    </div>
                ) : (null)}

                <img src={this.state.imageBackgroundUrl} style={{ position: "fixed", width: "100%", height: "100%", zIndex: "-2" }} />
                <Container background={this.state.colorBackground} align="center" height={this.state.height}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'wrap',
                            flexGrow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: this.state.positionContent === "top" ? "10px"
                                : this.state.positionContent === "center" ? "30vh"
                                    : this.state.positionContent === "bottom" ? "auto" : "10px",
                            bottom: this.state.positionContent === "bottom" ? "0px" : "auto"
                        }}
                    >
                        <FormRow align="center">
                            <TextBig
                                fontSize={this.state.primaryTitle.size}
                                color={this.state.primaryTitle.color}
                            >
                                {this.state.primaryTitle.text}
                            </TextBig>
                        </FormRow>
                        <FormRow alignItems="center" direction="column" width="30%" padding="50px">
                            <div style={{ maxWidth: "400px", marginBottom: "24px" }}>
                                <InputFieldOutlined
                                    id="uploadFile"
                                    key="file"
                                    type="file"
                                    title={getDic("arquivo")}
                                    placeholder={getDic("arquivo")}
                                    onChange={e => this.onChange(e)}
                                />
                            </div>
                            {
                                this.state.uploadingFile ?
                                    <div className="progressbar">
                                        <div className="progressbar__progress"
                                            style={{
                                                width: `${this.state.uploadProgress}%`,
                                                backgroundColor: this.state.uploadProgress == 100 ? "#4BB543" : "#ffae42"
                                            }}>
                                        </div>
                                        <p className="progressbar__percent"
                                            style={{ fontWeight: this.state.uploadProgress == 100 ? "bold" : "initial" }}
                                        >
                                            {Number(this.state.uploadProgress).toFixed(0)} %
                                        </p>
                                    </div> : null
                            }
                            {!this.state.uploadingFile ? (
                                <Button
                                    text={getDic("enviar")}
                                    onClick={() => this.sendNewFile()}
                                />
                            ) : (null)}
                        </FormRow>
                        <FormRow align="center">
                            {this.state.fileMaxSize && this.state.fileMaxSize > 0 ? (
                                <TextSmall>{getDic("tamanho-maximo")} {this.state.fileMaxSize} MB</TextSmall>
                            ) : (null)}
                        </FormRow>
                        <FormRow align="center">
                            {this.state.fileTypes && this.state.fileTypes !== "" ? (
                                <TextSmall>{getDic("formatos-permitidos")}: {this.state.fileTypes.replace(/,/g, ", ")}</TextSmall>
                            ) : (null)}
                        </FormRow>
                    </div>
                </Container>

                <Alert
                    open={this.state.alertOpen}
                    onClose={() => this.setState({ alertOpen: false })}
                    onClick={() => this.setState({ alertOpen: false })}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                />

                <Loading open={this.state.openLoading} message={this.state.loadingMessage} />
            </div>
        )
    }
}

function mapStateToProps(state) {

    var backgroundColor = state.config.find(item => {
        return item.codeScreen === "COLOR-PAGE";
    });

    var fileConfig = state.config.find(item => {
        return item.codeScreen === "FILE-UPLOAD-CONFIG";
    });
    var fileConfigArray = [];
    if (fileConfig && fileConfig.url) {
        fileConfigArray = fileConfig.url;
    }
    else {
        fileConfigArray.push(0);
        fileConfigArray.push("");
    }

    return {
        event: state.event,
        user: state.user,
        fileConfig: fileConfigArray,
    }
}

export default connect(mapStateToProps, null)(UploadPage)
