import { getDic } from '../assets/i18n/dictionary';
import { getModalTypeList } from '../services/configService';
import { createFormControlModel } from '../models/formControlModel';
import { store } from '../store';
import moment from 'moment';

export function createSpeakerModel(
    id = 0,
    name = '',
    company = '',
    role = '',
    biography = '',
    image = '',
    video = '',
    facebook = '',
    twitter = '',
    linkedin = '',
    site = '',
    email = '',
) {
    const speaker = {
        id: id,
        name: name,
        company: company ? company : '',
        role: role ? role : '',
        biography: biography ? biography : '',
        image: image ? image : '',
        video: video ? video : '',
        facebook: facebook ? facebook : '',
        twitter: twitter ? twitter : '',
        linkedin: linkedin ? linkedin : '',
        site: site ? site : '',
        email: email ? email : '',
    }

    return speaker;
}

export function convertSpeakerObjectToForm(speaker) {
    const _form = [];

    var _index = 0;

    for (var key in speaker) {
        if (speaker.hasOwnProperty(key)) {

            var _hasForm = false;
            var _type = "text";
            var _options = null;
            var _required = true;
            var _name = "";
            var _value = speaker[key] ? speaker[key].toString() : null;

            switch (key.toString()) {
                case "id":
                    _hasForm = true;
                    _name = "id";
                    _required = true;
                    _value = parseInt(speaker[key]);
                    break;
                case "name":
                    _hasForm = true;
                    _name = `${getDic("nome")}`;
                    _required = true;
                    break;
                case "company":
                    _hasForm = true;
                    _name = `${getDic("empresa")}`;
                    _required = false;
                    break;
                case "role":
                    _hasForm = true;
                    _name = `${getDic("cargo")}`;
                    _required = false;
                    break;
                case "biography":
                    _hasForm = true;
                    _name = `${getDic("biografia")}`;
                    _required = false;
                    break;
                case "image":
                    _hasForm = true;
                    _name = `${getDic("foto")}`;
                    _required = false;
                    break;
                case "video":
                    _hasForm = true;
                    _name = `${getDic("video")}`;
                    _required = false;
                    break;
                case "facebook":
                    _hasForm = true;
                    _name = "Facebook";
                    _required = false;
                    break;
                case "twitter":
                    _hasForm = true;
                    _name = "Twitter";
                    _required = false;
                    break;
                case "linkedin":
                    _hasForm = true;
                    _name = "Linkedin";
                    _required = false;
                    break;
                case "site":
                    _hasForm = true;
                    _name = "Site";
                    _required = false;
                    break;
                case "email":
                    _hasForm = true;
                    _name = "Email";
                    _required = false;
                    break;
                default:
                    break;
            }

            if (_hasForm) {
                const _obj = createFormControlModel(
                    _index,
                    _name,
                    _value,
                    _type,
                    null,
                    null,
                    _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                    _required,
                    null,
                    null,
                    null,
                    key
                );
                _form.push(_obj);
                _index++;
            }
        }
    }
    return _form;
}

export function convertFormToSpeakerObject(form) {
    var _speaker = createSpeakerModel();
    form.forEach(item => {
        var _value;

        switch (item.dbReference) {
            case "id":
                _value = parseInt(item.value);
                break;
            case "name":
                _value = item.value;
                break;
            case "company":
                _value = item.value;
                break;
            case "role":
                _value = item.value;
                break;
            case "biography":
                _value = item.value;
                break;
            case "image":
                _value = item.value;
                break;
            case "video":
                _value = item.value;
                break;
            case "facebook":
                _value = item.value;
                break;
            case "twitter":
                _value = item.value;
                break;
            case "linkedin":
                _value = item.value;
                break;
            case "site":
                _value = item.value;
                break;
            case "email":
                _value = item.value;
                break;
            default:
                break;
        }
        _speaker[item.dbReference] = _value;
    });
    return _speaker;
}
