import React from 'react'
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import {
  getPagesList,
  getScreenCodeListApi,
  getConfigListByPageIdApi,
  getModalTypeList,
  deleteConfigApi,
  updateConfigApi,
  addNewConfigApi,
} from '../services/configService';
import { getStandsListApi } from '../services/standService';
import { getGroupListApi } from '../services/attributesService';
import { createConfigModel, convertConfigObjectToForm, convertFormToConfigObject } from "../models/configModel";
import { validateForm, getFileExtension } from '../utils';
import { importConfigTable } from '../importers/configImporter';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import InfoIcon from "@material-ui/icons/InfoOutlined";
import DateTime from "react-datetime";
import moment from "moment";
import FilesPage from '../pages/FilesPage';
import UploadPage from '../pages/UploadPage';
import ConfigImporterExcel from '../excel/ConfigImporterExcel';
import ConfigImporter from '../importers/configImporter';
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/pt";
import * as navigationHistoryService from '../services/navigationHistoryService';

//Imports de components
import {
  MenuButton,
  BackButton,
  Container,
  FormRow,
  InputFieldOutlined,
  SelectList,
  Button,
  FormColumn,
  Alert,
  Loading,
  Modal,
  TextSmall,
  GridRow,
  GridCell,
  TextMedium,
  Dialog,
  Link,
  ModalConcatUrl,
  JsonFormater
} from '../components';

const ConfigPage = (props) => {
  const [state, setState] = React.useState(true);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Dialog control
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [dialogConfirmKey, setDialogConfirmKey] = React.useState("");
  const [dialogConfirmKeyErrorMessage, setDialogConfirmKeyErrorMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(true);

  //Modal Control
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalFiles, setOpenModalFiles] = React.useState(false);
  const [openModalUpload, setOpenModalUpload] = React.useState(false);
  const [openModalConcatUrl, setOpenModalConcatUrl] = React.useState(false);
  const [openModalEditJson, setOpenModalEditJson] = React.useState(false);
  const [editJsonBase, setEditJsonBase] = React.useState();
  const [editJsonBaseIndex, setEditJsonBaseIndex] = React.useState();

  const [pagesList, setPagesList] = React.useState([]);
  const [pagesListValue, setPagesListValue] = React.useState(1);
  const [screenCodeList, setScreenCodeList] = React.useState([]);
  const [screenCodeListValue, setScreenCodeListValue] = React.useState(1);
  const [standsList, setStandsList] = React.useState([]);
  const [standsListValue, setStandsListValue] = React.useState();
  const [modalTypeList, setModalTypeList] = React.useState([]);
  const [modalTypeListValue, setModalTypeListValue] = React.useState();
  const [pavilionsList, setPavilionsList] = React.useState([]);
  const [pavilionsListValue, setPavilionsListValue] = React.useState(1);
  const [configList, setConfigList] = React.useState([]);
  const [groupList, setGroupList] = React.useState([]);

  const [newConfigForm, setNewConfigForm] = React.useState([]);
  const [chosenConfig, setChosenConfig] = React.useState(null);

  const [openModalImporter, setOpenModalImporter] = React.useState(false);
  const [exportTable, setExportTable] = React.useState(false);
  const [importerTable, setImporterTable] = React.useState(null);
  const [importerReturnTable, setImporterReturnTable] = React.useState(null);
  const [executeConfigImporter, setExecuteConfigImporter] = React.useState(false);

  React.useEffect(() => {
    //Bloqueio caso o usuário não seja admin
    if (!props.user.isAdmin) {
      props.history.goBack();
    }

    getPageData();

    insertNewAccess(props.match.url, "", getSystemActions().accessPage);

    return () => {
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    }
  }, []);

  const getPageData = async () => {

    const _pagesList = [];
    const _basePagesList = getPagesList();
    _basePagesList.forEach(item => {
      const _page = {
        value: item.id,
        text: item.string,
      }
      _pagesList.push(_page);
    });
    _pagesList.sort((a, b) => {
      return a.text > b.text
        ? 1
        : b.text > a.text
          ? -1
          : 0;
    });
    setPagesList(_pagesList);

    var _screenCodeList = [];
    await getScreenCodeListApi()
      .then(ret => {
        _screenCodeList = ret;
      });
    setScreenCodeList(_screenCodeList);

    var _standsList = [];
    await getStandsListApi()
      .then(ret => {
        ret.forEach(item => {
          const _obj = {
            text: `${item.name} - ${getDic("pavilhao")} ${item.pavilion}`,
            value: item.id,
            pavilion: item.pavilion,
          }
          _standsList.push(_obj);
        });
      });
    setStandsList(_standsList);

    var _modalTypeList = [];
    var _modalTypeBase = getModalTypeList();
    _modalTypeBase.forEach(item => {
      const _obj = {
        text: item.text,
        value: item.id,
      }
      _modalTypeList.push(_obj);
    });
    setModalTypeList(_modalTypeList);

    var _pavilionsList = [];
    for (let index = 1; index < 10; index++) {
      const _obj = {
        text: index,
        value: index,
      }
      _pavilionsList.push(_obj);
    }
    setPavilionsList(_pavilionsList);

    var _groupList = [];
    await getGroupListApi()
      .then(ret => {
        _groupList = ret;
      });
    setGroupList(_groupList);

    setOpenLoading(false);

    verifyConfigList(pagesListValue);
  }

  const verifyConfigList = (pageId, pavilionId, standId) => {
    // Verifica se a página Stand possui um stand selecionado e se a Stands possui um pavilhão
    if (pageId === 7 && standId && standId !== 0) {
      getConfigList(pageId, pavilionId, standId);
    }
    else if (pageId === 2 && pavilionId && pavilionId !== 0) {
      getConfigList(pageId, pavilionId, standId);
    }
    else if (pageId !== 2 && pageId !== 7) {
      getConfigList(pageId, pavilionId, standId);
    }
  }

  const getConfigList = (pageId, pavilionId, standId) => {
    setOpenLoading(true);

    getConfigListByPageIdApi(pageId)
      .then(res => {
        if (pageId === 2 && pavilionId && pavilionId !== 0) {
          const _return = res.filter(item => {
            return item.pavilion === pavilionId;
          })
          setConfigList(_return);
        }
        else if (pageId === 7 && standId && standId !== 0) {
          const _return = res.filter(item => {
            return item.standId === standId;
          })
          setConfigList(_return);
        }
        else {
          setConfigList(res);
        }
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }
  const onChangeHandlerData = (index, _a) => {
    if (!_a.toDate) return;
    var _newForm = newConfigForm;
    let value = moment(_a.toDate()).format("YYYY-MM-DDTHH:mm");
    _newForm[index].value = value;
    setNewConfigForm(_newForm);
    setState(state === true ? false : true);
  }

  const onChangeHandler = (index, value) => {
    var _newForm = newConfigForm;
    _newForm[index].value = value;
    setNewConfigForm(_newForm);
    setState(state === true ? false : true);
  }

  const resetConfigForm = () => {
    const _newConfig = createConfigModel();
    const _newForm = convertConfigObjectToForm(_newConfig, screenCodeList, groupList);
    setNewConfigForm(_newForm);
  }

  const createNewConfig = () => {
    const _newConfig = createConfigModel();
    const _newForm = convertConfigObjectToForm(_newConfig, screenCodeList, groupList);
    setNewConfigForm(_newForm);
    setOpenModal(true);
  }

  const editConfig = (config) => {
    const _newForm = convertConfigObjectToForm(config, screenCodeList, groupList);
    setNewConfigForm(_newForm);
    setOpenModal(true);
  }

  const deleteConfig = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("salvando"));

    deleteConfigApi(chosenConfig)
      .then(res => {
        const _newConfigList = [];
        configList.forEach(item => {
          if (item.id !== chosenConfig.id) {
            _newConfigList.push(item);
          }
        });
        _newConfigList.sort((a, b) => {
          return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
        });
        setConfigList(_newConfigList);
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setOpenLoading(false);
      })
      .finally(() => {
        setOpenLoading(false);
      })
  }

  const saveConfig = async () => {
    var validation = validateForm(newConfigForm);
    if (validation.isValid) {

      var _newConfig = convertFormToConfigObject(newConfigForm);

      //Verifica se a configuração já esxiste para a tela
      var _screenCode = screenCodeList.find(item => {
        return item.value === parseInt(_newConfig.codeScreen);
      });
      const _hasConfig = configList.find(item => {
        if (item.codeScreen === _screenCode.text && item.id !== _newConfig.id) {
          var _hasGid;

          if (_newConfig.groupId.length === 0 & item.groupId.length === 0) {
            _hasGid = true
          }
          else {
            // Verifica se a configuração é para grupos diferentes
            _hasGid = _newConfig.groupId.find(gId => {
              if (item.groupId.includes(gId)) {
                return true;
              }
            });
          }

          if (_hasGid) {
            return item;
          }
        }
      });
      if (!_hasConfig) {
        setOpenLoading(true);
        setLoadingMessage(getDic("salvando"));

        _newConfig.codeScreen = _screenCode.text;
        _newConfig.eventId = props.event.id;
        _newConfig.screenId = pagesListValue;
        _newConfig.standId = standsListValue;
        _newConfig.pavilion = pavilionsListValue;

        var _error = false;
        const _newConfigList = [];

        if (_newConfig.id > 0) {
          await updateConfigApi(_newConfig, _screenCode.value)
            .then(res => {
              _newConfigList.push(res);
            })
            .catch(err => {
              _error = true;
            })
        }
        else {
          await addNewConfigApi(_newConfig, _screenCode.value)
            .then(res => {
              _newConfigList.push(res);
            })
            .catch(err => {
              _error = true;
            })
        }

        if (_error) {
          setAlertOpen(true);
          setAlertTitle(getDic("erro"));
          setAlertMessage(getDic("enviado-erro"));
          setOpenLoading(false);
        }
        else {
          configList.forEach(item => {
            if (item.id !== _newConfig.id) {
              _newConfigList.push(item);
            }
          });
          _newConfigList.sort((a, b) => {
            return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
          });
          setConfigList(_newConfigList);
          setAlertOpen(true);
          setAlertTitle("");
          setAlertMessage(getDic("enviado-sucesso"));
          resetConfigForm();
          setOpenLoading(false);
          setOpenModal(false);
        }
      }
      else {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("configuracao-cadastro-erro"));
        setOpenLoading(false);
      }
    }
    else {
      setNewConfigForm(validation.form);
    }
  }

  const renderButtonNewConfig = () => {
    // Verifica se a página Stand possui um stand selecionado e se a Stands possui um pavilhão
    var _show = false;
    if (pagesListValue === 7 && standsListValue && standsListValue !== 0) {
      _show = true;
    }
    else if (pagesListValue === 2 && pavilionsListValue && pavilionsListValue !== 0) {
      _show = true;
    }
    else if (pagesListValue !== 2 && pagesListValue !== 7) {
      _show = true;
    }

    if (_show) {
      return (
        <div style={{ padding: "5px" }}>
          <Button
            onClick={() => createNewConfig()}
          >
            {getDic("cadastrar") + " Config"}
          </Button>
        </div>
      )
    }
  }

  const exportEventTable = () => {
    var _btn = document.getElementById("btnExportTable");
    if (_btn) {
      setTimeout(() => {
        _btn.click();
      }, 500);
    }
    setTimeout(() => {
      setExportTable(false);
    }, 1000);
  }

  const setTableFile = (file) => {
    if (file) {
      var _fileType = getFileExtension(file);
      if (_fileType === "XLSX") {
        setImporterTable(file);
      }
      else {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("arquivo-erro-tipo"));
        setImporterTable(null);
      }
    }
  }

  const returnImportFile = (res) => {
    if (!res.error) {
      setAlertOpen(true);
      setAlertTitle("");
      setAlertMessage(getDic("enviado-sucesso"));
      getPageData();
    }
    else {
      setAlertOpen(true);
      setAlertTitle(`${getDic("erro")}`);
      setAlertMessage(res.errorMessage);
    }

    if (res.data && res.data.length > 0) {
      setImporterReturnTable(res.data);
    }

    setImporterTable(null);
    setExecuteConfigImporter(false);
    var _btn = document.getElementById("btnImporterReturnTable");
    if (_btn) {
      setTimeout(() => {
        _btn.click();
      }, 500);
    }
    setTimeout(() => {
      setImporterReturnTable(null);
    }, 2000);
  }

  return (
    <Container background="#e8eced" align="center">
      <MenuButton history={props.history} />
      <BackButton
        history={props.history}
        color="#212121"
      >
        {getDic("voltar").toUpperCase()}
      </BackButton>

      <FormColumn>
        <FormRow
          minHeight="50px"
          align="center"
          background="#e8eced"
          margin="0px"
        />
        <FormRow
          minHeight="50px"
          align="start"
          background="#e8eced"
          margin="0px"
        >
          <div style={{ padding: "5px", maxWidth: "400px" }}>
            <SelectList
              title={getDic("pagina")}
              width="160px"
              maxWidth="160px"
              value={pagesListValue}
              menuItens={pagesList}
              onChange={event => {
                setPagesListValue(event.target.value);
                setStandsListValue(null);
                setPavilionsListValue(1);
                setConfigList([]);
                verifyConfigList(event.target.value, 1, null);
              }}
            />
          </div>
          {pagesListValue === 2 && (
            <div style={{ padding: "5px", maxWidth: "400px" }}>
              <SelectList
                title={getDic("pavilhao")}
                width="160px"
                maxWidth="160px"
                value={pavilionsListValue}
                menuItens={pavilionsList}
                onChange={event => {
                  setPavilionsListValue(event.target.value);
                  verifyConfigList(pagesListValue, event.target.value, null);
                }}
              />
            </div>
          )}
          {pagesListValue === 7 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ padding: "5px", maxWidth: "400px" }}>
                <SelectList
                  title="Stand"
                  width="260px"
                  maxWidth="260px"
                  value={standsListValue}
                  menuItens={standsList}
                  onChange={event => {
                    const _stand = standsList.find(item => {
                      return item.value === event.target.value;
                    })
                    setStandsListValue(event.target.value);
                    setPavilionsListValue(_stand.pavilion);
                    verifyConfigList(pagesListValue, _stand.pavilion, event.target.value);
                  }}
                />
              </div>
              <div style={{ padding: "5px" }}>
                <Button
                  color="green"
                  onClick={() => navigationHistoryService.setHistory(props.history, '/' + props.event.id + '/standsconfig')}
                >
                  {getDic("cadastrar") + " Stand"}
                </Button>
              </div>
            </div>
          )}
          {renderButtonNewConfig()}
          <div style={{ padding: "5px" }}>
            <Link>
              <InfoIcon
                onClick={() => {
                  window.open("https://docs.google.com/document/d/e/2PACX-1vRuW7F2qzN5rg6-oIseyL9B3pkeHn5EwPha0cyd1yZdHrSoCM6A3FAjHWb285zbapFF1q0zENvWKjw_/pub", "_blank");
                }}
              />
            </Link>
          </div>
          <div style={{ paddingRight: "5px", maxWidth: "400px" }}>
            <Button
              color="lightgray"
              textColor="black"
              onClick={() => setOpenModalImporter(true)}
            >
              {`${getDic("importador")}`}
            </Button>
          </div>
        </FormRow>
        <FormRow align="center" margin="0px">
          <div style={{ padding: 5, width: "100%" }}>
            <GridRow backgroundColor="#d9d9d9">
              <GridCell width="5%" border top first >
                <TextMedium>ID</TextMedium>
              </GridCell>
              <GridCell width="10%" border top>
                <TextMedium>COD {getDic("tela").toUpperCase()}</TextMedium>
              </GridCell>
              <GridCell width="8%" border top>
                <TextMedium>{getDic("tipo").toUpperCase()}</TextMedium>
              </GridCell>
              <GridCell width="25%" border top>
                <TextMedium>URL</TextMedium>
              </GridCell>
              <GridCell width="15%" border top>
                <TextMedium>TOOLTIP</TextMedium>
              </GridCell>
              <GridCell width="10%" border top>
                <TextMedium>{getDic("grupo").toUpperCase()}</TextMedium>
              </GridCell>
              <GridCell width="10%" border top>
                <TextMedium>{getDic("senha").toUpperCase()}</TextMedium>
              </GridCell>
              <GridCell width="10%" border top>
                <TextMedium>{getDic("descricao").toUpperCase()}</TextMedium>
              </GridCell>
              <GridCell width="7%" border top>
                <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
              </GridCell>
            </GridRow>

            {configList.length > 0 ?
              (
                configList.map((config, i) => {
                  const _type = modalTypeList.find(tip => {
                    return tip.value === config.typeId
                  });
                  var _group = "";
                  config.groupId.forEach(group => {
                    var _strGroup = groupList.find(obj => {
                      return group === obj.value;
                    });
                    if (_strGroup) {
                      _group = _group === "" ? _strGroup.text : _group + ", " + _strGroup.text;
                    }
                  });
                  return (
                    <GridRow
                      key={config.id}
                      autoColor={i}
                    >
                      <GridCell width="5%" border first>
                        <TextSmall>{config.id}</TextSmall>
                      </GridCell>
                      <GridCell width="10%" border>
                        <TextSmall>{config.codeScreen}</TextSmall>
                      </GridCell>
                      <GridCell width="8%" border>
                        <TextSmall>{_type.text}</TextSmall>
                      </GridCell>
                      <GridCell width="25%" border>
                        <TextSmall>{config.urlString}</TextSmall>
                      </GridCell>
                      <GridCell width="15%" border>
                        <TextSmall>{config.tooltip}</TextSmall>
                      </GridCell>
                      <GridCell width="10%" border>
                        <TextSmall>{_group}</TextSmall>
                      </GridCell>
                      <GridCell width="10%" border>
                        <TextSmall>{config.password}</TextSmall>
                      </GridCell>
                      <GridCell width="10%" border>
                        <TextSmall>{config.description}</TextSmall>
                      </GridCell>
                      <GridCell width="7%" border>
                        <EditIcon onClick={() => editConfig(config)} style={{ cursor: "pointer" }} />
                        <DeleteIcon
                          onClick={() => {
                            setChosenConfig(config);
                            setDialogConfirmOpen(true);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </GridCell>
                    </GridRow>
                  )
                })
              ) : (
                <TextMedium>
                  {getDic("pesquisa-sem-resultado")}
                </TextMedium>
              )
            }
          </div>
        </FormRow>
      </FormColumn>

      <Modal
        isVideo
        height="80vh"
        open={openModal}
        closeButtonClick={e => setOpenModal(false)}
        onClose={ret => {
          setOpenModal(false);
        }}
      >
        <Container background="#e8eced" align="left" height="100%" >
          <FormColumn padding="5px" margin="0px" background="#e8eced">
            {newConfigForm.map((item, i) => {
              if (i === 0) {
                return (
                  <>
                    <FormRow key={item.id} align="center" paddingTop="7px">
                      <TextMedium>
                        {item.value > 0 ? (
                          `${getDic("editar")} Config`
                        ) : (
                          `${getDic("novo")} Config`
                        )}
                      </TextMedium>
                    </FormRow>
                    <FormRow key={item.id} align="center" paddingTop="7px">
                      <div style={{ padding: 5 }}>
                        <Button
                          text={`${getDic("consultar")} ${getDic("arquivos")}`}
                          onClick={() => setOpenModalFiles(true)}
                        />
                      </div>
                      <div style={{ padding: 5 }}>
                        <Button
                          text={`${getDic("adicionar")} ${getDic("arquivo")}`}
                          onClick={() => setOpenModalUpload(true)}
                        />
                      </div>
                    </FormRow>
                  </>
                )
              }
              else {
                if (item.type === "select" && item.values) {
                  return (
                    <FormRow key={item.id} align="center" paddingTop="7px">
                      <SelectList
                        required={item.required}
                        error={item.error}
                        errorMessage={item.errorMessage}
                        title={item.name}
                        width="100%"
                        value={item.value}
                        menuItens={item.values}
                        onChange={async (event) =>
                          onChangeHandler(i, event.target.value)
                        }
                        multiple={Array.isArray(item.value) ? true : false}
                      />
                    </FormRow>
                  );
                }
                else {
                  if (item.name.toUpperCase() !== "HTML" ||
                    (item.name.toUpperCase() === "HTML" &&
                      (newConfigForm[2].value === 448 || newConfigForm[2].value === 86 || pagesListValue === 32)
                    )
                  ) {
                    var _screenCode = screenCodeList.find(scr => {
                      return newConfigForm[2].value === scr.value;
                    });

                    if (item.name.toUpperCase() === "HTML" && pagesListValue === 216) {
                      var _json;
                      try {
                        _json = JSON.parse(item.value);
                      } catch (error) {
                        console.log('Erro ao converter JSON', item.value);
                      }

                      return (
                        <>
                          <FormRow key={item.id} align="center" paddingTop="7px">
                            <button
                              onClick={() => {
                                setEditJsonBase(item.value);
                                setEditJsonBaseIndex(i);
                                setOpenModalEditJson(true);
                              }}
                            >
                              <TextSmall>
                                {getDic("editar")} JSON
                              </TextSmall>
                            </button>
                          </FormRow>
                          <FormRow key={item.id} margin="0px">
                            <div style={{ borderRadius: 5, background: 'white', width: '100%', paddingLeft: 5 }}>
                              <TextMedium color="gray">
                                HTML
                              </TextMedium>
                            </div>
                          </FormRow>
                          <FormRow margin="0px" key={item.id}>
                            <JSONInput
                              id={item.id}
                              placeholder={_json}
                              locale={locale}
                              height="100%"
                              width="100%"
                              onChange={(e) => {
                                if (!e.error) {
                                  onChangeHandler(i, e.json);
                                }
                              }}
                            />
                          </FormRow>
                        </>
                      )
                    }

                    return (
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <InputFieldOutlined
                          key={item.id}
                          required={item.required}
                          maxLength={item.maxLength}
                          error={item.error}
                          errorMessage={item.errorMessage}
                          value={item.value}
                          title={item.name}
                          type={item.type}
                          onChange={(event) => {
                            onChangeHandler(i, event.target.value)
                          }}
                          multiline={item.name.toUpperCase() === "HTML" ? true : false}
                          endAdornment={item.dbReference === "urlString" &&
                            _screenCode && _screenCode.text.includes("ELEMENT-") ? (
                            <Link onClick={() => setOpenModalConcatUrl(true)}>
                              <EditIcon />
                            </Link>
                          ) : (null)}
                        />

                        {item.dbReference === "urlString" && (
                          <ModalConcatUrl
                            open={openModalConcatUrl}
                            urlString={item.value}
                            onClose={ret => {
                              setOpenModalConcatUrl(false);
                              onChangeHandler(i, ret);
                            }}
                          />
                        )}
                      </FormRow>
                    );
                  }
                }
              }
            })}
            <FormRow align="center" paddingTop="7px">
              <Button
                text={getDic("salvar")}
                onClick={() => saveConfig()}
              />
            </FormRow>
          </FormColumn>
        </Container>
      </Modal>

      <Modal
        isVideo
        height="80vh"
        open={openModalFiles}
        closeButtonClick={e => setOpenModalFiles(false)}
        onClose={ret => {
          setOpenModalFiles(false);
        }}
      >
        <FilesPage isModal />
      </Modal>

      <Modal
        isVideo
        height="80vh"
        open={openModalUpload}
        closeButtonClick={e => setOpenModalUpload(false)}
        onClose={ret => {
          setOpenModalUpload(false);
        }}
      >
        <UploadPage isModal />
      </Modal>

      <Modal
        isVideo
        height="80vh"
        open={openModalImporter}
        closeButtonClick={e => {
          setOpenModalImporter(false);
          setImporterTable(null);
        }}
        onClose={ret => {
          setOpenModalImporter(false);
          setImporterTable(null);
        }}
      >
        <Container background="#e8eced" align="center" height="100%" >
          <FormRow alignItems="center" direction="column">
            <div style={{ paddingTop: "10px", maxWidth: "400px" }}>
              <ConfigImporterExcel
                exampleFile
                groupList={groupList}
                pagesList={pagesList}
                screenCodeList={screenCodeList}
                standsList={standsList}
                pavilionsList={pavilionsList}
                button={
                  <Button color="green" textColor="white">
                    {getDic("gerar")} {getDic("planilha")} {getDic("importador")}
                  </Button>
                }
              />
            </div>

            {/* A planilha possui uma consulta para exportação que será realizada dentro do component */}
            {/* Desta forma, para impedir uma consulta indevida, o component só será renderizado no momento do click */}
            <div style={{ paddingTop: "10px", maxWidth: "400px" }}>
              {exportTable && (
                <div hidden>
                  <ConfigImporterExcel
                    exportTable
                    groupList={groupList}
                    pagesList={pagesList}
                    screenCodeList={screenCodeList}
                    standsList={standsList}
                    pavilionsList={pavilionsList}
                    onDownload={() => exportEventTable()}
                    button={<button id="btnExportTable">X</button>}
                  />
                </div>
              )}
              <Button color="blue" textColor="white" onClick={() => setExportTable(true)}>
                {getDic("exportar")} {getDic("planilha")} {getDic("evento")}
              </Button>
            </div>
            <div style={{ maxWidth: "400px", paddingTop: "20px" }}>
              <InputFieldOutlined
                id="uploadFile"
                key="file"
                type="file"
                title={`${getDic("importar")} ${getDic("planilha")}`}
                placeholder={getDic("planilha")}
                onChange={e => setTableFile(e.target.files[0])}
              />
              <FormRow align="center">
                <TextSmall>{getDic("formatos-permitidos")}: XLSX</TextSmall>
              </FormRow>
            </div>
            {importerTable && (
              <div style={{ maxWidth: "400px", paddingTop: "20px" }}>
                <Button color="blue" textColor="white" onClick={() => setExecuteConfigImporter(true)}>
                  {getDic("importar")} {getDic("planilha")}
                </Button>
              </div>
            )}

            {/* Planilha de retorno do importador */}
            {importerReturnTable && (
              <div hidden>
                <ConfigImporterExcel
                  data={importerReturnTable}
                  groupList={groupList}
                  pagesList={pagesList}
                  screenCodeList={screenCodeList}
                  standsList={standsList}
                  pavilionsList={pavilionsList}
                  button={<button id="btnImporterReturnTable">X</button>}
                />
              </div>
            )}

            {executeConfigImporter && (
              <ConfigImporter
                file={importerTable}
                groupList={groupList}
                pagesList={pagesList}
                screenCodeList={screenCodeList}
                standsList={standsList}
                pavilionsList={pavilionsList}
                returnImportFile={res => {
                  returnImportFile(res);
                }}
              />
            )}
          </FormRow>
        </Container>
      </Modal>

      <Modal
        isVideo
        height="80vh"
        open={openModalEditJson}
        closeButtonClick={e => {
          setOpenModalEditJson(false);
        }}
        onClose={ret => {
          setOpenModalEditJson(false);
        }}
      >
        <JsonFormater
          jsonBase={editJsonBase}
          pageId={216}
          standsList={standsList}
          returnObject={(obj) => {
            const _string = JSON.stringify(obj);
            onChangeHandler(editJsonBaseIndex, _string);
            setOpenModalEditJson(false);
          }}
        />
      </Modal>

      <Dialog
        open={dialogConfirmOpen}
        onClose={() => setDialogConfirmOpen(false)}
        title={getDic("atencao")}
        textButtonYes={getDic("deletar")}
        onClickYes={() => {
          if (dialogConfirmKey && dialogConfirmKey != "" && dialogConfirmKey.toUpperCase() === "SIM") {
            deleteConfig();
            setDialogConfirmOpen(false);
            setDialogConfirmKey("");
          }
          else {
            setDialogConfirmKeyErrorMessage(true);
            setTimeout(() => {
              setDialogConfirmKeyErrorMessage(false);
            }, 3000); // 1 minuto = 60000
          }
        }}
        textButtonNo={getDic("cancelar")}
        onClickNo={() => {
          setDialogConfirmOpen(false);
          setDialogConfirmKey("");
        }}
      >
        <FormRow>
          <TextSmall>
            {getDic("confirmacao-de-acao")}
          </TextSmall>
        </FormRow>
        <FormRow align="center" paddingTop="5px">
          <InputFieldOutlined
            key="password"
            value={dialogConfirmKey}
            onChange={event => setDialogConfirmKey(event.target.value)}
          />
        </FormRow>
        {dialogConfirmKeyErrorMessage ? (
          <TextSmall color="red">{getDic("erro")}</TextSmall>
        ) : (null)}
      </Dialog>

      <Alert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={() => setAlertOpen(false)}
        title={alertTitle}
        message={alertMessage}
      />

      <Loading open={openLoading} message={loadingMessage} />
    </Container>
  )
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(ConfigPage)
