import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";

//Imports de components
import { TextMedium } from "../components";

class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: this.props.expanded ? this.props.expanded : false
    };
  }

  render() {
    const {
      children,
      id = "",
      expanded,
      onChange,
      onClick,
      headerText = "",
      headerContent,
      headerTextStyle,
      footerPanelStyle
    } = this.props;

    return (
      <ExpansionPanel
        expanded={onClick ? expanded : this.state.expanded}
        onChange={onChange}
      >
        <ExpansionPanelSummary
          style={this.props.expansionStyle}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id={id}
          onClick={
            onClick
              ? onClick
              : () =>
                  this.state.expanded === true
                    ? this.setState({ expanded: false })
                    : this.setState({ expanded: true })
          }
        >
          <span style={headerTextStyle ? headerTextStyle : { fontWeight: 700 }}>
            {headerText}
          </span>
          <div>{headerContent}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={footerPanelStyle && footerPanelStyle}>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default Component;
