import React from "react";
import { emojiIndex } from 'emoji-mart'
import { getEmojiDataFromNative, Emoji } from 'emoji-mart'
import data from 'emoji-mart/data/all.json'

const convertToTime = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate() > 9 ? a.getDate() : "0" + a.getDate();
  var hour = a.getHours() > 9 ? a.getHours() : "0" + a.getHours();
  var min = a.getMinutes() > 9 ? a.getMinutes() : "0" + a.getMinutes();
  var sec = a.getSeconds() > 9 ? a.getSeconds() : "0" + a.getSeconds();
  var time1 =
    date + "  " + month + "  " + year + " - " + hour + ":" + min + ":" + sec;
  var time2 = hour + ":" + min;
  return time2;
};

const MessageCard = (props) => {

  const getDateHash = (date) => {
    let DateFull = new Date(date);
    return parseInt(DateFull.getDate() + DateFull.getMonth() + 1 + DateFull.getFullYear());
  }
  // Tue Oct 20 2020 09:49:39 GMT-0300 (Horário Padrão de Brasília)
  // Wed Oct 21 2020 09:19:42 GMT-0300 (Horário Padrão de Brasília)
  const getCurrentTime = (date) => {
    var a = new Date();
    var b = new Date(date);
    let final_date;

    if(getDateHash(a) - getDateHash(b) == 1) {
      final_date = "Ontem";
    }
    
    if(getDateHash(a) == getDateHash(b)) {
      final_date = "Hoje";
    }

    if(a > b && getDateHash(a) - getDateHash(b) > 1) {
      final_date = `${new Date(date).getDate()}/${new Date(date).getMonth() + 1}/${new Date(date).getFullYear()}`;
    }

    return final_date;
  }
  const emojiData = props.message ? getEmojiDataFromNative(props.message, 'apple', data) : false;

  return <React.Fragment>
          <div className="row message-body" style={{
            justifyContent: "center",
            display: props.firstDay ? "flex" : "none",
            margin: 10
          }}>
            <div style={{    
              display: "inline-block",
              padding: "5px 12px 6px",
              textAlign: "center",
              textShadow: "0 1px 0 rgba(var(--inverse-rgb),.4)",
              borderRadius: 7.5,
              boxShadow: "0 1px .5px rgba(var(--shadow-rgb),.13)",
              background: "#e1f3fb"
              }}
            >
              {getCurrentTime(new Date(props.timestamp))}
          </div>
          </div>
          <div className="row message-body">
          <div
            className={`col-sm-12 ${
              props.sender ? "message-main-sender" : "message-main-receiver"
            }`}
          >
            <div className={`${props.sender ? "sender" : "receiver"}`}>
              <div className="message-text" style={emojiData && {fontSize: 25}}>{props.message}</div>
              <div className="col-sm-12" style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    left: 13
              }}>
                <div style={{display: "flex"}}>
                  <span className="message-time pull-right">
                  {convertToTime(new Date(props.timestamp))}
                  </span>
                  <div style={{display: props.sender ? 'contents' : 'none'}}>
                  <span style={props.data.membersWhoSawTheMessage && props.data.membersWhoSawTheMessage.length > 1 ? {marginLeft: 5, color:'#00a7e6'}: {marginLeft: 5, color: '#777'}} data-testid="msg-dblcheck" aria-label=" Lida " data-icon="msg-dblcheck" class="_3xkAl">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15">
                      <path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path>
                    </svg>
                  </span>
              </div>       
                </div>
                
              </div>
            </div>
          </div>
        </div>
  </React.Fragment>
}

export default MessageCard;
