import firebase from 'firebase/app';

firebase.initializeApp({
    apiKey: "AIzaSyAhKPyrn3tqGUEDZvjEAVwn392y1PNlLjQ",
    authDomain: "inteegra-ping.firebaseapp.com",
    projectId: "inteegra-ping",
    storageBucket: "inteegra-ping.appspot.com",
    messagingSenderId: "533801235392",
    appId: "1:533801235392:web:c4586f755ac24adb09ce25",
    measurementId: "G-LBFS50E3C7"
})


const firestore = firebase.firestore();


export {
    firestore,
    firebase
}