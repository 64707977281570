import React from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { firestore } from "../../../../utils/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

const OnlineChecker = (props) => {
  const [onlineUser, setOnlineuser] = React.useState(false);
  const onlineUserQuery = firestore
    .collection(`healthmap/${props.event.id}/ping`)
    .doc(`${props.id}`);
  const [online] = useDocumentData(onlineUserQuery, { idField: "id" });

  React.useEffect(() => {
    if (online && online.accessDate) {
      const dt = new Date();
      dt.setMinutes(dt.getMinutes() - 5);
      const expirationDate = online.accessDate.toDate();
      if (dt > new Date(expirationDate)) setOnlineuser(true);
      else setOnlineuser(false);
    } else setOnlineuser(true);
  }, [online]);
  return (
    <div>
      {onlineUser ? (
        <React.Fragment>
          <i className="mdi mdi-circle text-danger font-size-10" />
          {props.text && " Offline"}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <i className="mdi mdi-circle text-success font-size-10" />
          {props.text && " Online"}
        </React.Fragment>
      )}
    </div>
  );
};

export default OnlineChecker;
