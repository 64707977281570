import React from "react";
import { FeedData, InputFieldOutlined, FormRow } from "../../../components";
import { getDic } from "../../../assets/i18n/dictionary";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { Photo, Videocam, Mic, AttachFile } from "@material-ui/icons";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { Picker } from "emoji-mart";
import { isMobile, isIOS } from "react-device-detect";
import { Link } from "react-router-dom";
import * as fileService from '../../../services/fileService';
import * as fileTreatmentUtils from '../../../utils/fileTreatment';
import muxjs from 'mux.js';

import CircularProgressWithLabel from './CircularProgressWithLabel';
import DialogUploadAudio from './DialogUploadAudio';
import ModalRecordAudio from './ModalRecordAudio';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 765,
    marginBottom: 10,
    marginTop: 10,
    padding: 10,
    opacity: 0.96,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  rowPost: {
    display: "flex",
    paddingTop: 12,
    paddingBottom: 12,
  },
  spanPost: {
    outline: "0px!important",
    background: "#f0f2f5",
    borderRadius: 19,
    padding: 11,
    color: "#65676b",
    marginLeft: 10,
    width: "100%",
    border: 0,
    resize: "none",
    fontFamily: "inherit",
    transition: "0.15s cubic-bezier(0, 0, 0.66, 1.08)",
  },
  alignRow: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  btnPublish: {
    border: "1px solid #f4f9ff",
    borderRadius: 10,
    fontSize: 17,
    color: "white",
    padding: 9,
    textAlign: "center",
    cursor: "pointer",
  },
  closeImgReset: {
    width: 15,
    height: 15,
    position: "absolute",
    top: -4,
    right: -5,
  },
}));
const emojiToUnicode = (s) => {
  return s
    .match(/\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]/g)
    .map(
      (e) =>
        "\\u" +
        e.charCodeAt(0).toString(16) +
        "\\u" +
        e.charCodeAt(1).toString(16)
    );
};

const FeedNewPost = (props) => {
  const classes = useStyles();
  const [firstLoading, setFirstLoading] = React.useState(true);
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [firstTextAreaChecker, setFirstTextAreaChecker] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [loadingNewPosts, setLoadingNewPosts] = React.useState(false);
  const [showTextArea, setTextarea] = React.useState(true);
  const [openModalEmoji, setModalEmoji] = React.useState(false);
  const [postContent, setPostContent] = React.useState("");
  const [imgURL, setImgURL] = React.useState("");
  const [fileUrl, setFileUrl] = React.useState("");
  const [fileBase64, setFileBase64] = React.useState("");
  const [imgFormData, setImgFormData] = React.useState("");
  const [imgBase64, setImgBase64] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const wrapperRef = React.useRef(null);
  const inputTextArea = React.useRef(null);
  const emojiBox = React.useRef(null);
  const emojiBtn = React.useRef(null);
  const [inputFileIosControl, setInputFileIosControl] = React.useState({
    open: false,
    file: null
  });

  //Context
  const ContextRef = React.useContext(FeedData);
  const [dialogUploadAudioControl, setDialogUploadAudioControl] = React.useState({
    open: false,
  });
  const [modalRecordAudioControl, setModalRecordAudioControl] = React.useState({
    open: false,
  });

  //
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setTextarea(false);
    }
    if (emojiBox && !emojiBox.current.contains(event.target)) {
      setModalEmoji(false);
    }
  };

  const postNewFeed = async () => {
    try {
      if (loadingNewPosts) {
        setErrorMsg(`${getDic("salvando")}, ${getDic("aguarde-por-favor")}`);
        return;
      }
      if (uploadingFile) {
        setErrorMsg(`${getDic("salvando")}, ${getDic("aguarde-por-favor")}`);
        return;
      }
      if (!imgURL && postContent.length < 2) {
        setErrorMsg(`${getDic("minimo-de-caracters")} 2 ${getDic("caracteres")}`);
        return;
      }
      setErrorMsg("");
      //ativa o loading
      setLoadingNewPosts(true);

      let _fileUrl = await getFileUrl();
      setTextarea(false);
      setModalEmoji(false);
      ContextRef._createNewPost({
        text: postContent,
        url: _fileUrl,
      }).then((result) => {
        ContextRef._getPosts({ pageSize: 11, pageIndex: 1 }).then(() => {
          setLoadingNewPosts(false);
          setModalEmoji(false);
          setTextarea(false);
        });
        setModalEmoji(false);
        setImgBase64("");
        setPostContent("");
        setFileUrl("");
        setFileBase64("");
        setInputFileIosControl({
          open: false,
          file: null
        });
        //console.log(result);
      });
    } catch (error) {
      console.log('Error postNewFeed', error);
      return;
    }

    async function getFileUrl() {
      try {
        if (inputFileIosControl.open && inputFileIosControl.file) {
          let _url = await setFileToServer(inputFileIosControl.file);
          return _url;
        }
        return imgURL;
      } catch (error) {
        console.log('Error getFileUrl', error);
        throw error;
      }
    }
  };

  const onEmojiClick = (emojiObject, props) => {
    let sym = emojiObject.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    var message = `${postContent}${emoji}`;
    setPostContent(message);
    setModalEmoji(false);
    inputTextArea.current.focus();
  };

  const getImgURL = () => {
    setErrorMsg("");
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg";

    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file.size >= 1024 * 1024 * 15) {
        setErrorMsg(`${getDic("arquivo-erro-tamanho")} ${getDic("de")} 15 MB`);
        return;
      } else {
        console.log("Tamanho do arquivo OK", file.size);
      }

      let _extension = fileTreatmentUtils.getFileExtension(file);
      let _fileType = fileService.checkFileFormat(_extension);
      if (_fileType !== 'image') {
        setErrorMsg(`${getDic("formato")} ${getDic("de")} ${getDic("arquivo")} ${getDic("invalido")}`);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = function () {
        const base64data = reader.result;
        setImgBase64(base64data);

        setFileToServer(file);
      };
      setImgFormData(file);
    };
    input.click();
  };

  const getVideoUrl = () => {
    try {
      setErrorMsg("");
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "video/*";

      input.onchange = async (e) => {
        const file = e.target.files[0];
        if (file.size >= 1024 * 1024 * 50) {
          setErrorMsg(`${getDic("arquivo-erro-tamanho")} ${getDic("de")} 50 MB`);
          return;
        } else {
          console.log("Tamanho do arquivo OK", file.size);
        }

        let _extension = fileTreatmentUtils.getFileExtension(file);
        let _fileType = fileService.checkFileFormat(_extension);
        if (_fileType !== 'video') {
          setErrorMsg(`${getDic("formato")} ${getDic("de")} ${getDic("arquivo")} ${getDic("invalido")}`);
          return;
        }

        let _isValid = await validateVideoCodec(file, _extension);
        if (!_isValid) {
          setErrorMsg(`${getDic("formato")} ${getDic("de")} ${getDic("arquivo")} ${getDic("invalido")}`);
          return;
        }

        setFileToServer(file);
      };
      input.click();
    } catch (error) {
      console.log('Error getVideoUrl', error)
    }

    async function validateVideoCodec(file, extension) {
      try {
        let _return = true;
        if (extension.toUpperCase() === 'MP4') {
          const buffer = await file.arrayBuffer();
          let byteArray = new Int8Array(buffer);
          var parsed = muxjs.mp4.tools.inspect(byteArray);
          parsed.forEach(parse => {
            if (parse && parse.nals) {
              parse.nals.forEach(nal => {
                if (nal && nal.includes('MALFORMED DATA')) {
                  _return = false;
                }
              });
            }
          });
        }
        return _return;
      } catch (error) {
        return false;
      }
    }
  };

  const getAudioUrl = () => {
    try {
      setErrorMsg("");
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "audio/*";
      if (!isMobile) {
        input.accept = "video/*";
      }

      input.onchange = (e) => {
        const file = e.target.files[0];

        if (file.size >= 1024 * 1024 * 20) {
          setErrorMsg(`${getDic("arquivo-erro-tamanho")} ${getDic("de")} 20 MB`);
          return;
        } else {
          console.log("Tamanho do arquivo OK", file.size);
        }

        let _extension = fileTreatmentUtils.getFileExtension(file);
        let _fileType = fileService.checkFileFormat(_extension);
        if (_fileType !== 'audio' || _extension.toUpperCase() === 'OGG' || _extension.toUpperCase() === 'WAV') {
          setErrorMsg(`${getDic("formato")} ${getDic("de")} ${getDic("arquivo")} ${getDic("invalido")}`);
          return;
        }

        setFileToServer(file);
      };
      input.click();
    } catch (error) {
      console.log('Error getAudioUrl', error)
    }
  };

  const setFileToServer = async (file) => {
    try {
      let _isValid = validateIosFile();
      if (!_isValid) {
        setLoadingNewPosts(false);
        throw new Error();
      }
      let res = await saveFile();

      setUploadingFile(false);
      setUploadProgress(0);
      setImgURL(res.url);
      setFileUrl(file.name);
      return res.url;

    } catch (error) {
      console.log('Error setFileToServer', error);
      setUploadingFile(false);
      setUploadProgress(0);
      throw error;
    }

    function validateIosFile() {
      try {
        if (isIOS) {
          let _extension = fileTreatmentUtils.getFileExtension(file);
          let _fileType = fileService.checkFileFormat(_extension);
          if (_fileType !== 'audio' && _fileType !== 'video' && _fileType !== 'image') {
            setErrorMsg(`${getDic("formato")} ${getDic("de")} ${getDic("arquivo")} ${getDic("invalido")}`);
            return false;
          }
        }
        return true;
      } catch (error) {
        console.log('Error validateIosFile', error);
        setUploadingFile(false);
        setUploadProgress(0);
        throw error;
      }
    }

    async function saveFile() {
      try {
        let res = await fileService.setNewFormDataFile(
          file,
          ContextRef._event.contractId,
          ContextRef._user.id,
          '',
          ContextRef._event.id,
          false,
          (pge) => {
            if (!uploadingFile) setUploadingFile(true);
            setUploadProgress(((pge.loaded / pge.total) * 100).toFixed(2));
          }
        );

        setUploadingFile(false);
        setUploadProgress(0);
        setImgURL(res.url);
        setFileUrl(file.name);
        return res;

      } catch (error) {
        console.log('Error saveFile', error);
        setUploadingFile(false);
        setUploadProgress(0);
        throw error;
      }
    }
  };

  const preLoadTextArea = () => {
    if (firstTextAreaChecker) {
      if (props.openTextArea < 1) {
        setTextarea(true);
      } else {
        setTextarea(false);
        setFirstTextAreaChecker(false);
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    preLoadTextArea();
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <React.Fragment>
      <Card className={classes.root} ref={wrapperRef}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{ color: "white", height: 0 }}
        >
          This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the
          mussels, if you like.
        </Typography>
        <div className={classes.rowPost}>
          <div className={classes.alignRow}>
            <Link to="./profile">
              <Avatar
                style={{ cursor: "pointer" }}
                aria-label="recipe"
                src={ContextRef._user.image}
              >
                {ContextRef._user.name[0]}
              </Avatar>
            </Link>
          </div>
          <div
            className={classes.alignRow}
            style={{ width: "100%", position: "relative" }}
          >
            <textarea
              ref={inputTextArea}
              rows="1"
              cols="50"
              className={classes.spanPost}
              style={{
                height: showTextArea ? 150 : 20,
                cursor: showTextArea ? "auto" : "pointer",
              }}
              placeholder={`${getDic("no-que-voce-esta-pensando")}, ${ContextRef._user.name
                }`}
              onClick={() => setTextarea(true)}
              onChange={(e) => setPostContent(e.target.value)}
              value={postContent}
            />
            <span
              style={{
                position: "absolute",
                bottom: -18,
                fontSize: 13,
                color: "red",
                fontWeight: 500,
              }}
            >
              {showTextArea ? errorMsg : ""}
            </span>
            <div
              ref={emojiBox}
              style={{
                position: "fixed",
                zIndex: 199999,
                top:
                  openModalEmoji && isMobile
                    ? emojiBtn.current.getBoundingClientRect().top - 220
                    : openModalEmoji
                      ? emojiBtn.current.getBoundingClientRect().top - 10
                      : 0,
                height: "auto",
              }}
            >
              {openModalEmoji && (
                <Picker
                  showPreview={false}
                  showSkinTones={false}
                  style={{
                    height: "auto",
                  }}
                  onClick={(emoji, props) => {
                    onEmojiClick(emoji, props);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {renderIosInput()}
        <div
          style={{
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            height: showTextArea ? "auto" : 0,
            transition: "0.35s cubic-bezier(0, 0, 0.66, 1.08)",
          }}
        >
          <div
            style={{
              width: "80%",
              height: "auto",
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
              transition: "0.35s cubic-bezier(0, 0, 0.66, 1.08)",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img
              src={imgBase64}
              style={{
                display: imgBase64 && showTextArea ? "block" : "none",
                width: "100%",
                height: showTextArea ? "100%" : 0,
              }}
            />
            <CircularProgressWithLabel uploadProgress={uploadProgress} />
            <div
              className={classes.closeImgReset}
              style={{
                display: imgBase64 && showTextArea ? "block" : "none",
                background: "white",
                borderRadius: 50,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <i
                onClick={() => {
                  setImgBase64(null);
                  setImgURL("");
                  setFileUrl("");
                }}
                class="fas fa-times-circle"
                style={{
                  color: "#757575",
                  cursor: "pointer",
                  display: imgBase64 && showTextArea ? "block" : "none",
                }}
              ></i>
            </div>
            {fileUrl !== '' && showTextArea && (
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <div style={{
                  paddingRight: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <AttachFile fontSize="small" style={{ color: "#707070" }} />
                  {fileUrl}
                </div>
                <i
                  onClick={() => {
                    setImgBase64(null);
                    setImgURL("");
                    setFileUrl("");
                  }}
                  class="fas fa-times-circle"
                  style={{
                    color: "#757575",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #ced0d4",
              borderRadius: 10,
              marginTop: 10,
              marginBottom: 10,
              overflow: "hidden",
            }}
          >
            <span>{getDic("adicionar-a-sua-publicacao")}</span>
            <VideoLibraryIcon
              className="video_btn_disabled"
              style={{ display: "none", margin: 10 }}
            />
            {!isIOS && fileUrl === '' && renderWebButtons()}
            {isIOS && renderIosButtons()}
            <InsertEmoticonIcon
              onClick={() => setModalEmoji(true)}
              className="emoji_btn"
              ref={emojiBtn}
              style={{ color: "#f7b928", margin: 10, cursor: "pointer" }}
            />
          </div>
          <div
            className={`${classes.btnPublish} publish_btn`}
            onClick={() => postNewFeed()}
            style={
              (!uploadProgress && !loadingNewPosts && postContent.length > 1) ||
                imgURL.length > 1
                ? { backgroundColor: "#3f51b5" }
                : { backgroundColor: "#757575" }
            }
          >
            <span>
              {!loadingNewPosts
                ? getDic("publicar")
                : `${getDic("publicando")}...`}
            </span>
          </div>
        </div>
      </Card>
      {loadingNewPosts && (
        <CircularProgressWithLabel uploadProgress={uploadProgress} />
      )}

      <DialogUploadAudio
        dialogUploadAudioControl={dialogUploadAudioControl}
        setDialogUploadAudioControl={setDialogUploadAudioControl}
        modalRecordAudioControl={modalRecordAudioControl}
        setModalRecordAudioControl={setModalRecordAudioControl}
        getAudioUrl={getAudioUrl}
        setTextarea={setTextarea}
      />

      <ModalRecordAudio
        modalRecordAudioControl={modalRecordAudioControl}
        setModalRecordAudioControl={setModalRecordAudioControl}
        setTextarea={setTextarea}
        setFileToServer={setFileToServer}
      />
    </React.Fragment>
  );

  function renderWebButtons() {
    return (
      <>
        <Photo
          onClick={() => getImgURL()}
          className="photo_btn"
          style={{ color: "#26c426", margin: 10, cursor: "pointer" }}
        />
        <Videocam
          onClick={() => getVideoUrl()}
          className="photo_btn"
          style={{ color: "#c41306", margin: 10, cursor: "pointer" }}
        />
        <Mic
          onClick={() => {
            getAudioUrl();
            // if (isIOS) {
            //   getAudioUrl();
            // } else {
            //   setDialogUploadAudioControl({ ...dialogUploadAudioControl, open: true });
            // }
          }}
          className="photo_btn"
          style={{ color: "#176ae6", margin: 10, cursor: "pointer" }}
        />
      </>
    )
  }

  function renderIosButtons() {
    return (
      <AttachFile
        onClick={() => setInputFileIosControl({ ...inputFileIosControl, open: !inputFileIosControl.open })}
        className="photo_btn"
        style={{ color: "#26c426", margin: 10, cursor: "pointer" }}
      />
    )
  }

  function renderIosInput() {
    if (!inputFileIosControl.open) {
      return null;
    }
    return (
      <FormRow>
        <InputFieldOutlined
          id="uploadFile"
          key="file"
          type="file"
          placeholder={getDic("arquivo")}
          onChange={e => setInputFileIosControl({ ...inputFileIosControl, file: e.target.files[0] })}
        />
      </FormRow>
    )
  }
};

export default FeedNewPost;
