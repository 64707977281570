import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import { store } from '../store';
import readXlsxFile from 'read-excel-file';
import { createQuestionModel, convertFormToQuestionObject, convertQuestionObjectToForm } from '../models/questionModel';
import { addNewQuestionApi, updateQuestionApi } from '../services/questionsService';
import {
  getReturnObject,
  checkIfRegisterExists,
  validateFormControlList,
  verifyIfColtrolRowIsComplete,
  verifyIfTitleRowExists,
} from './utilsImporter';
import { getTypeQuestionList } from '../services/questionsService';
import moment from 'moment';

//Imports de components
import { Loading } from '../components';

const QuestionImporter = (props) => {

  //O método fará a leitura do arquivo
  //Irá verificar se as colunas estão conforme a planilha de exemplo
  //Depois verificará se os dados foram preencidos corretamente
  //Em caso de erro, montar um array do tipo formControlModel para retornar ao usuário onde está o erro
  function importQuestionsTable(file) {
    return new Promise(async (resolve, reject) => {
      readXlsxFile(file)
        .then(async (rows) => {
          var _returnVerify = null;

          //Primeiro, encontrar o index da linha de controle, método duck typing
          var _controlLineIndex = null;
          _returnVerify = verifyIfControlRowExists(rows);
          if (_returnVerify.hasError.error) {
            reject(_returnVerify.hasError);
            return;
          }
          else {
            _controlLineIndex = _returnVerify.controlLineIndex;
          }

          //Segundo, verificar se alguma coluna foi deletada
          const _questionFormList = convertQuestionObjectToForm(createQuestionModel(), true);
          _returnVerify = verifyIfColtrolRowIsComplete(rows[_controlLineIndex], _questionFormList);
          if (_returnVerify.hasError.error) {
            reject(_returnVerify.hasError);
            return;
          }

          //Terceiro, verificar se a linha de título foi deletada
          _returnVerify = verifyIfTitleRowExists(rows, _controlLineIndex);
          if (_returnVerify.hasError.error) {
            reject(_returnVerify.hasError);
            return;
          }

          //Quarto, converter o array de linhas em um array de objetos do tipo questions e formControl
          _returnVerify = convertRowsInQuestionAndFormControlModel(rows, _controlLineIndex);
          var _questionsList = _returnVerify.questionsList;
          if (_questionsList === 0) {
            var _hasError = getReturnObject();
            _hasError.error = true;
            _hasError.errorMessage = "Não há dados para cadastrar";
            reject(_hasError);
            return;
          }

          //Quinto, validar se os dados estão corretos
          var _formControlList = _returnVerify.formControlList;
          _returnVerify = validateFormControlList(_formControlList);
          if (_returnVerify.error) {
            reject(_returnVerify);
            return;
          }

          //Sexto, salvar os dados um por um, caso retorne erro, sinalizar quais linhas não foram salvas
          //Ao salvar, atribuir o id aos elementos salvos para retornar a planilha ao usuário
          const _newFormControlList = [];
          var _count = 0;

          do {
            const item = _formControlList[_count];
            var _newForm = item;
            if (!_returnVerify.error) {
              await saveQuestion(item, _count, _formControlList.length)
                .then(res => {
                  item.find((fr, i) => {
                    if (fr.dbReference === "id") {
                      _newForm[i].value = res.id;
                    }
                  });
                })
                .catch(err => {
                  // _returnVerify.error = true;
                  _returnVerify.errorMessage = "Um ou mais dados não foram salvos, verifique a planilha de retorno.";
                  item.find((fr, i) => {
                    if (fr.dbReference === "id") {
                      _newForm[i].error = true;
                      _newForm[i].errorMessage = err;
                    }
                  });
                })
                .finally(() => {
                  _newFormControlList.push(_newForm);
                  _count++;
                });
            }
            else {
              _count = _formControlList.length;
            }
          } while (_count < _formControlList.length);

          if (_count === _formControlList.length) {
            _returnVerify.data = _newFormControlList;
            resolve(_returnVerify);
          }

        })
        .catch(err => {
          console.log("Erro importQuestionsTable", err);
          var _hasError = getReturnObject();
          _hasError.error = true;
          _hasError.errorMessage = `Erro ao importar planilha: ${err.toString()}`;
          reject(_hasError);
        });
    });
  }

  function verifyIfControlRowExists(rows) {
    var _hasError = getReturnObject();
    var _controlLineIndex = null;
    rows.forEach((columns, i) => {
      if (columns.includes("id") && columns.includes("typeQuestion") && columns.includes("question")) {
        _controlLineIndex = i;
      }
    });
    if (!_controlLineIndex) {
      _hasError.error = true;
      _hasError.errorMessage = "Linha de controle deletada";
    }
    return { hasError: _hasError, controlLineIndex: _controlLineIndex };
  }

  function convertRowsInQuestionAndFormControlModel(rows, controlLineIndex) {
    const _controlRow = rows[controlLineIndex];
    const _questionsList = [];
    const _formControlList = [];

    //Cria um array de questions com os dados da planilha e um array com validadores de dados
    for (let index = controlLineIndex + 2; index < rows.length; index++) {
      const item = rows[index];
      var _question = createQuestionModel();
      var _questionReturnObject = [];
      _controlRow.forEach((dbReference, i) => {
        var _value = item[i] && item[i].toString().trim() !== "" ? item[i].toString().trim() : null;
        var _objReturn = validateData(dbReference, _value);
        _question[dbReference] = _value;
        _questionReturnObject.push({ dbReference: dbReference, field: _objReturn });
      });

      _questionsList.push(_question);

      //Atribui valores e campos de erro ao form object
      const _formControlBase = convertQuestionObjectToForm(_question, true);

      const _formControlReturn = [];
      _formControlBase.forEach(obj => {
        var _field = _questionReturnObject.find(ret => {
          return ret.dbReference === obj.dbReference;
        });
        var _newField = obj;
        _newField.error = _field.field.error;
        _newField.errorMessage = _field.field.errorMessage;
        _newField.value = _field.field.data;

        //Verifica se os campos obrigatórios foram preenchidos
        if (_newField.dbReference !== "id" && _newField.required && (_newField.value === null || _newField.value === "")) {
          _newField.error = true;
          _newField.errorMessage = "Preenchimento obrigatório";
        }

        _formControlReturn.push(_newField);
      });
      _formControlList.push(_formControlReturn);
    }
    return { formControlList: _formControlList, questionsList: _questionsList };
  }

  function validateData(dbReference, data) {
    var _returnObj = getReturnObject();
    switch (dbReference) {
      case "id":
        _returnObj.data = data && parseInt(data) ? parseInt(data) : null;
        break;
      case "typeQuestion":
        var _options = getTypeQuestionList();
        _returnObj = checkIfRegisterExists(data, _options, _returnObj);
        break;
      case "schedulesId":
        const _schedules = [];
        store.getState().schedules.forEach(item => {
          var _sched = {
            text: item.title,
            value: item.id
          }
          _schedules.push(_sched);
        });
        _returnObj = checkIfRegisterExists(data, _schedules, _returnObj);
        break;
      case "configsId":
        const _configList = [];
        store.getState().config.forEach(item => {
          if (item.codeScreen.includes("ELEMENT") && item.urlString.includes("QUESTIONS")) {
            var _conf = {
              text: `${item.codeScreen} - ${item.tooltip}`,
              value: item.id
            }
            _configList.push(_conf);
          }
        });
        _returnObj = checkIfRegisterExists(data, _configList, _returnObj);
        break;
      default:
        _returnObj.data = data ? data : null;
        break;
    }
    return _returnObj;
  }

  function saveQuestion(formControl, index, totalRegister) {

    return new Promise(async (resolve, reject) => {
      setLoadingMessage(`Salvando ${index + 1} de ${totalRegister}`);
      var _newQuestion = convertFormToQuestionObject(formControl, true);

      if (_newQuestion.id > 0) {
        //Ao dar update, verificar se a pergunta é do evento correspondente
        var _isQuestionFromEvent = props.questionsList.find(quest => {
          return quest.id === _newQuestion.id;
        });
        if (_isQuestionFromEvent) {
          await updateQuestionApi(_newQuestion)
            .then(res => {
              _newQuestion = res;
              resolve(_newQuestion);
            })
            .catch(err => {
              reject(getDic("erro ", err.toString()));
            });
        }
        else {
          reject("ID não pertence a este evento");
        }
      }
      else {
        await addNewQuestionApi(_newQuestion)
          .then(res => {
            _newQuestion.id = res.id;
            resolve(_newQuestion);
          })
          .catch(err => {
            reject(getDic("erro ", err.toString()));
          });
      }
    });
  }

  const importFile = () => {
    setOpenLoading(true);
    setLoadingMessage("Carregando");

    var _returnObject;
    importQuestionsTable(props.file, props.questionsList)
      .then(res => {
        _returnObject = res;
      })
      .catch(err => {
        _returnObject = err;
      })
      .finally(() => {
        setOpenLoading(false);
        returnImportFile(_returnObject);
      });
  }

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState("Carregando");
  const [openLoading, setOpenLoading] = React.useState(false);

  React.useEffect(() => {
    importFile();
  }, []);

  const returnImportFile = (returnObject) => {
    if (props.returnImportFile) {
      props.returnImportFile(returnObject);
    }
  }

  return (
    <>
      <Loading open={openLoading} message={loadingMessage} />
    </>
  );
}

export default QuestionImporter;
