export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
const userLoginSuccessLocal = user => ({
    type: USER_LOGIN_SUCCESS,
    user
});
export function userLoginSuccess(user) {
    return async function (dispatch, getState) {
        return new Promise(async (resolve, reject) => {
            await dispatch(userLoginSuccessLocal(user));
            resolve(true);
        });
    };
}

export const USER_LOGOUT = 'USER_LOGOUT';
const userLogoutLocal = () => ({
    type: USER_LOGOUT,
});
export function userLogout() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(userLogoutLocal());
            resolve(true);
        });
    };
}

export const USER_UPDATE = 'USER_UPDATE';
const userUpdateLocal = (user, firstTerm, secondTerm) => ({
    type: USER_UPDATE,
    user,
    firstTerm,
    secondTerm
});
export function userUpdate(user, firstTerm, secondTerm) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(userUpdateLocal(user, firstTerm, secondTerm));
            resolve(true);
        });
    };
}
