import React from "react";
import { isMobile } from "react-device-detect";

const IframePlayer = (props) => {
  const {
    src = "",
    height = "100%",
    width = "100%",
    autoplay = true,
    onClick,
    history
  } = props;

  const historyLenght = React.useRef();

  React.useEffect(() => {

  }, [src]);

  React.useEffect(() => {
    getLength();
    return () => {
      returnLength();
    }
  }, []);

  const getLength = () => {
    const _history = history ? history.length : window.history.length;
    historyLenght.current = _history;
  }

  const returnLength = () => {
    const _goBack = window.history.length - historyLenght.current;
    for (let index = 0; index < _goBack; index++) {
      window.history.back();
    }
  }

  const styles = {
    video: {
      backgroundColor: "transparent",
      width: width,
      height: height,
      display: "flex",
      justifyContent: "space-around",
    },
  };

  return (
    <div style={styles.video} className={isMobile && "mainFrame"}>
      <iframe
        key={src}
        title={src}
        width="100%"
        height="100%"
        src={src}
        frameBorder="0"
        allow={`microphone; camera${autoplay ? "; autoplay" : ""}`}
        allowFullScreen
        autoplay={autoplay}
        onClick={onClick}
      />
    </div>
  );
};

export default IframePlayer;
