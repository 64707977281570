export function createRankingModel(
    id = 0,
    guestId = 0,
    guestName = "",
    points = 0,
    levelId = 0,
    levelName = "",
    photoUrl = "",
) {
    const object = {
        id: id,
        guestId: guestId,
        guestName: guestName,
        points: points,
        levelId: levelId,
        levelName: levelName,
        image: photoUrl,
    }

    return object;
}

export function createPointCompositionModel(
    actionTypeId = 0,
    actionType = "",
    actionId = 0,
    points = 0,
    description = "",
    dateCreated = "",
) {
    const object = {
        actionTypeId: actionTypeId,
        actionType: actionType,
        actionId: actionId,
        points: points,
        description: description,
        dateCreated: dateCreated,
    }

    return object;
}
