export function getReturnObject() {
    return {
        error: false,
        errorMessage: "",
        data: null,
    };
}

// formControlList é um array do tipo formControlModel baseado no objeto que o importador trata
export function verifyIfColtrolRowIsComplete(controlRow, formControlList) {
    var _hasError = getReturnObject();
    formControlList.forEach(item => {
        if (!controlRow.includes(item.dbReference)) {
            _hasError.error = true;
            _hasError.errorMessage = `A coluna "${item.name}" foi deletada`;
        }
    });
    return { hasError: _hasError };
}

export function verifyIfTitleRowExists(rows, controlLineIndex) {
    var _hasError = getReturnObject();
    var _titleLineIndex = controlLineIndex + 1;
    if (!rows[_titleLineIndex].includes("ID *")) {
        _hasError.error = true;
        _hasError.errorMessage = "Linha de título deletada";
    }
    return { hasError: _hasError };
}

// options corresponde a um array do tipo selectList
// cada objeto é composto por => {text: val, value: val}
export function checkIfRegisterExists(data, options, returnObj) {
    var _returnObj = returnObj;
    if (data) {
        var _check;
        if (parseInt(data)) {
            _check = options.find(opt => {
                return opt.value === parseInt(data);
            });
        }
        else {
            _check = options.find(opt => {
                return opt.text.toUpperCase() === data.toUpperCase() || (opt.value && opt.value.toUpperCase ? opt.value.toUpperCase() : opt.value) === data.toUpperCase();
            });
        }

        if (!_check) {
            _returnObj.error = true;
            _returnObj.errorMessage = `Registro ${data} não localizado`;
            _returnObj.data = data;
        }
        else {
            _returnObj.data = _check.value;
        }
    }
    return _returnObj;
}

export function validateFormControlList(formControlList) {
    var _returnObj = getReturnObject();
    _returnObj.data = formControlList;
    formControlList.forEach(formControl => {
        formControl.forEach(item => {
            if (!_returnObj.error) {
                if (item.error) {
                    _returnObj.error = true;
                    _returnObj.errorMessage = "Planilha com dados inconsistentes, verifique os erros na planilha de retorno e tente novamente."
                }
            }
        });
    });
    return _returnObj;
}
