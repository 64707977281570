import axios from 'axios';
import { store } from '../store';
import { getUrl, getToken, getDate } from './apiService';
import { firestore } from "../utils/firebase";
import { createPushNotificationModel, createPushObjectV2 } from '../models/pushNotificationModel';
import moment from "moment";

export async function setNotificationRead(id) {

  const token = await getToken();
  const event = store.getState().event;
  const user = store.getState().user;
  var method =
    getUrl().hall + 'event/communication/notification/read';
  /*"http://localhost:5000/api/event/communication/notification/read";*/


  return new Promise((resolve, reject) => {

    const options = {
      url: method,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token
      },
      data: {
        PushNotificationId: id,
        Login: user.id
      }
    };
    axios(options)
      .then(res => {
        resolve(true);
      })
      .catch(err => {
        console.log("Erro getNotificationsByEvent", err);
        reject(err);
      })

  });

}

export async function getNotificationsByEvent() {

  const token = await getToken();
  const event = store.getState().event;
  const user = store.getState().user;
  var method = getUrl().hall + 'event/communication/notification/list?eventId=' + event.id.toString() + '&systemId=2&login=' + user.email;

  return new Promise((resolve, reject) => {

    const options = {
      url: method,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token
      }
    };
    axios(options)
      .then(res => {
        var notifications = [];

        if (res.data && res.data.data.length > 0) {
          res.data.data.forEach(item => {
            if (item.active) {
              const notify = createPushNotificationModel(
                item.id,
                item.text,
                item.eventID,
                item.insertDate,
                false,
                item.active
              );
              notifications.push(notify);
            }
          });
        }
        resolve(notifications);
      })
      .catch(err => {
        console.log("Erro getNotificationsByEvent", err);
        reject(err);
      })

  });

}

export async function getNotificationsByEventAdmin() {

  const token = await getToken();
  const event = store.getState().event;
  var method = getUrl().hall + 'event/communication/notification/' + event.id.toString();

  return new Promise((resolve, reject) => {

    const options = {
      url: method,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token
      }
    };
    axios(options)
      .then(res => {
        var notifications = [];

        if (res.data && res.data.length > 0) {
          res.data.forEach(item => {
            const notify = createPushNotificationModel(
              item.id,
              item.text,
              item.eventID,
              item.insertDate,
              false,
              item.active
            );
            notifications.push(notify);
          });
        }

        //Ordenar mensagens por data
        const ret = notifications.sort(function (a, b) {
          return b.insertDate > a.insertDate
        });

        resolve(ret);
      })
      .catch(err => {
        console.log("Erro getNotificationsByEventAdmin", err);
        reject(err);
      })
  });
}

export async function inactiveNotificationById(notificationId) {

  const token = await getToken();
  var method = getUrl().hall + `event/communication/notification/${notificationId}/inactivate`;

  return new Promise((resolve, reject) => {

    const options = {
      url: method,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token
      }
    };
    axios(options)
      .then(res => {
        if (res.data && res.data.success) {
          resolve(true);
        }
        else {
          reject(false);
        }
      })
      .catch(err => {
        console.log("Erro inactiveNotificationById", err);
        reject(err);
      })

  });

}

export async function addNewNotification(text, date) {

  const token = await getToken();
  const event = store.getState().event;
  var method = getUrl().hall + `event/communication/notification/register`;

  return new Promise((resolve, reject) => {

    const options = {
      url: method,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token
      },
      data: {
        text: text,
        eventId: event.id,
        systemId: 2,
        insertDate: date
      }
    };
    axios(options)
      .then(res => {

        if (res.data && res.data.success) {
          const notify = createPushNotificationModel(
            res.data.data.id,
            res.data.data.text,
            res.data.data.eventID,
            res.data.data.insertDate,
            false,
            res.data.data.active
          );

          resolve(notify);
        }
        else {
          reject(false);
        }
      })
      .catch(err => {
        console.log("Erro inactiveNotificationById", err);
        reject(err);
      })
  });
}

export async function addNewNotificationV2Firebase(pushV2, pushId) {

  return new Promise(async (resolve, reject) => {
    const _event = store.getState().event;
    const _currentDate = await getDate();

    var _url = `push/${_event.id}/`;
    if (pushV2.date > _currentDate) {
      _url += 'history_temp';
    }
    else {
      _url += 'history';
    }

    const method = firestore.collection(_url);

    const _date = new Date(pushV2.date);

    let _data = {
      version: pushV2.version,
      text: pushV2.text,
      text2: pushV2.text2,
      date: _date,
      command: pushV2.command,
      page: pushV2.page,
      openConfigId: pushV2.openConfigId,
      openScheduleId: pushV2.openScheduleId,
      replaceOpenConfig: pushV2.replaceOpenConfig,
      groups: pushV2.groups,
      configs: pushV2.configs,
      schedules: pushV2.schedules,
      questions: pushV2.questions,
      questionsObject: pushV2.questionsObject,
      style: pushV2.style,
      users: [0],
      active: pushV2.active,
    };

    if (pushId) {
      await method.doc(pushId).set(_data)
        .then(docRef => {
          const _return = {
            ..._data,
            id: pushId,
            date: pushV2.date,
          }
          resolve(_return);
        })
        .catch(err => {
          console.log("Erro addNewNotificationV2Firebase", err);
          reject(err);
        });
      return;
    }
    await method.add(_data)
      .then(docRef => {
        const _return = {
          ..._data,
          id: docRef.id,
          date: pushV2.date,
        }
        resolve(_return);
      })
      .catch(err => {
        console.log("Erro addNewNotificationV2Firebase", err);
        reject(err);
      });
  });
}

export async function updateScheduledNotificationV2Firebase(pushV2) {

  return new Promise(async (resolve, reject) => {
    const _event = store.getState().event;

    var _url = `push/${_event.id}/history_temp`;

    const method = firestore.collection(_url).doc(pushV2.id);

    const _date = new Date(pushV2.date);

    let _data = {
      version: pushV2.version,
      text: pushV2.text,
      text2: pushV2.text2,
      date: _date,
      command: pushV2.command,
      page: pushV2.page,
      openConfigId: pushV2.openConfigId,
      openScheduleId: pushV2.openScheduleId,
      replaceOpenConfig: pushV2.replaceOpenConfig,
      groups: pushV2.groups,
      configs: pushV2.configs,
      schedules: pushV2.schedules,
      questions: pushV2.questions,
      questionsObject: pushV2.questionsObject,
      style: pushV2.style,
      users: [0],
      active: pushV2.active,
    };

    await method.update(_data)
      .then(docRef => {
        resolve(pushV2);
      })
      .catch(err => {
        console.log("Erro updateNotificationV2Firebase", err);
        reject(err);
      });
  });
}

export async function getNotificationsV2Firebase() {

  return new Promise(async (resolve, reject) => {
    const _event = store.getState().event;

    var _url = `push/${_event.id}/history`;

    const _method = firestore.collection(_url).orderBy('date', 'desc');
    const _snapshot = await _method.get();
    const _return = [];

    if (!_snapshot.empty) {
      _snapshot.forEach(doc => {
        const _obj = createPushObjectV2(
          doc.data().version,
          doc.id,
          doc.data().text,
          doc.data().text2 ? doc.data().text2 : '',
          moment(new Date(1970, 0, 1))
            .startOf('day')
            .seconds(doc.data().date.seconds)
            .subtract(3, 'hours')
            .format(),
          doc.data().command,
          doc.data().page,
          doc.data().openConfigId,
          doc.data().openScheduleId,
          doc.data().replaceOpenConfig,
          doc.data().groups,
          doc.data().configs,
          doc.data().schedules,
          doc.data().questions,
          doc.data().style,
          doc.data().questionsObject,
          doc.data().active,
        )
        _return.push(_obj);
      });
    }

    resolve(_return);
  });
}

export async function getScheduledNotificationsV2Firebase() {

  return new Promise(async (resolve, reject) => {
    const _event = store.getState().event;

    var _url = `push/${_event.id}/history_temp`;

    const _method = firestore.collection(_url).orderBy('date', 'asc');
    const _snapshot = await _method.get();
    const _return = [];

    if (!_snapshot.empty) {
      _snapshot.forEach(doc => {
        const _obj = createPushObjectV2(
          doc.data().version,
          doc.id,
          doc.data().text,
          doc.data().text2 ? doc.data().text2 : '',
          moment(new Date(1970, 0, 1))
            .startOf('day')
            .seconds(doc.data().date.seconds)
            .subtract(3, 'hours')
            .format(),
          doc.data().command,
          doc.data().page,
          doc.data().openConfigId,
          doc.data().openScheduleId,
          doc.data().replaceOpenConfig,
          doc.data().groups,
          doc.data().configs,
          doc.data().schedules,
          doc.data().questions,
          doc.data().style,
          doc.data().questionsObject,
          doc.data().active,
        )
        _return.push(_obj);
      });
    }

    resolve(_return);
  });
}

export async function getTrashNotificationsV2Firebase() {

  return new Promise(async (resolve, reject) => {
    const _event = store.getState().event;

    var _url = `push/${_event.id}/trash`;

    const _method = firestore.collection(_url).orderBy('date', 'desc');
    const _snapshot = await _method.get();
    const _return = [];

    if (!_snapshot.empty) {
      _snapshot.forEach(doc => {
        const _obj = createPushObjectV2(
          doc.data().version,
          doc.id,
          doc.data().text,
          doc.data().text2 ? doc.data().text2 : '',
          moment(new Date(1970, 0, 1))
            .startOf('day')
            .seconds(doc.data().date.seconds)
            .subtract(3, 'hours')
            .format(),
          doc.data().command,
          doc.data().page,
          doc.data().openConfigId,
          doc.data().openScheduleId,
          doc.data().replaceOpenConfig,
          doc.data().groups,
          doc.data().configs,
          doc.data().schedules,
          doc.data().questions,
          doc.data().style,
          doc.data().questionsObject,
          doc.data().active,
        )
        _return.push(_obj);
      });
    }

    resolve(_return);
  });
}

export async function inactiveNotificationV2Firebase(notification) {
  try {
    const _event = store.getState().event;
    notification.date = new Date(notification.date);
    await firestore.collection(`push/${_event.id}/history`).doc(notification.id).update(notification);
  } catch (error) {
    console.log("Erro inactiveNotificationsV2Firebase", error);
    throw error;
  }
}

export async function deleteNotificationV2Firebase(pushV2) {
  try {
    const _event = store.getState().event;

    var _url = `push/${_event.id}/history`;
    const _doc = await firestore.collection(_url).doc(pushV2.id).get();

    await firestore.collection(`push/${_event.id}/trash`).doc(pushV2.id).set(_doc.data());

    await firestore.collection(_url).doc(pushV2.id).delete();
    return;
  } catch (error) {
    console.log('Error deleteNotificationV2Firebase', error);
    throw error;
  }
}

export async function deleteScheduledNotificationV2Firebase(pushV2) {

  return new Promise(async (resolve, reject) => {
    const _event = store.getState().event;

    var _url = `push/${_event.id}/history_temp`;

    const method = firestore.collection(_url).doc(pushV2.id);

    await method.delete()
      .then(docRef => {
        resolve(true);
      })
      .catch(err => {
        console.log("Erro deleteScheduledNotificationV2Firebase", err);
        reject(err);
      });
  });
}
