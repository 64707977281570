import { getDic } from '../assets/i18n/dictionary';
import { validateEmail, validateDatetime } from '../utils';

export function validateForm(form = [], isValidateEmail = true) {

    try {
        var isValid = true;
        const returnForm = [];
        //Verifica campos obrigatórios para validar se estão preenchidos
        form.forEach(field => {
            var item = field;
            var error = false;
            if (Array.isArray(item.value)) {
                if (item.value.length === 0 && item.required) {
                    error = true;
                }
            }
            else if ((typeof item.value === 'string' || item.value instanceof String) && item.dbReference !== 'id') {
                var verifyInput = item.value ? item.value.replace(/ /g, "") : ""; //Replace all
                if (item.required && verifyInput === "") {
                    error = true;
                }
                item.value = item.value ? item.value.trim() : "";
            }
            else if (item.required && item.value === null && item.dbReference !== 'id') {
                error = true;
            }

            var errorEmail = false;
            //Verifica se o campo é e-mail, se sim, passar pelo validador
            if (isValidateEmail && item.dbReference && item.dbReference.toUpperCase().indexOf("EMAIL") > -1 && item.value && item.value !== "") {
                if ((item.name.toUpperCase() === "EMAIL" || item.dbReference.toUpperCase() === "E-MAIL") && item.value && item.value !== "") {
                    var err = validateEmail(item.value);
                    errorEmail = !err ? true : false;
                }
            }

            var errorCpf = false;
            //Verifica se o campo é cpf, se sim, passar pelo validador
            if (item.dbReference && item.dbReference.toUpperCase().indexOf("CPF") > -1) {
                if (item.name.toUpperCase().includes("CPF") || item.dbReference.toUpperCase().includes("CPF")) {
                    var err = validateCPF(item.value);
                    errorCpf = !err ? true : false;
                }
            }

            var errorCrm = false;
            //Verifica se o campo é cpf, se sim, passar pelo validador
            if (item.dbReference && item.dbReference.toUpperCase().indexOf("CRM") > -1 && verifyInput !== "") {
                if (item.name.toUpperCase().includes("CRM") || item.dbReference.toUpperCase().includes("CRM")) {
                    var err = validadeCRM(item.value);
                    errorCrm = !err ? true : false;
                }
            }

            var newField = item;
            if (error) {
                newField.error = true;
                newField.errorMessage = getDic("preenchimento-obrigatorio");
                isValid = false;
            } else if (errorEmail) {
                newField.error = true;
                newField.errorMessage = getDic("email-invalido");
                isValid = false;
            } else if (errorCpf) {
                newField.error = true;
                newField.errorMessage = getDic("cpf-invalido");
                isValid = false;
            } else if (errorCrm) {
                newField.error = true;
                newField.errorMessage = "CRM " + getDic("invalido");
                isValid = false;
            } else {
                newField.error = false;
                newField.errorMessage = "";
            }
            returnForm.push(newField)
        });

        return { form: returnForm, isValid: isValid };
    }
    catch (err) {
        console.log("Erro validateForm", err);
        return { form: form, isValid: false, error: err };
    }

}

export function validatePassword(password) {
    var _ret = true;

    if (password.length < 8) {
        _ret = false;
    }
    if (password.search(/[a-z]/i) < 0) {
        _ret = false;
    }
    if (password.toLowerCase() === password) {
        _ret = false;
    }
    if (password.toUpperCase() === password) {
        _ret = false;
    }
    if (password.search(/[0-9]/) < 0) {
        _ret = false;
    }

    var regularExpression = /^(?=.*[a-zA-Z!#$%&? "])[a-zA-Z0-9!#$%&?]{8,20}$/;

    if (regularExpression.test(password)) {
        _ret = false;
    }

    return _ret;
}

export function validadeCRM(crm) {
    if (crm.length <= 3) return false;
    else return true;
}

export function validateCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
    ) {
        return false
    }
    var _sum = 0
    var _rest
    for (var i = 1; i <= 9; i++)
        _sum = _sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    _rest = (_sum * 10) % 11
    if ((_rest == 10) || (_rest == 11)) _rest = 0
    if (_rest != parseInt(cpf.substring(9, 10))) return false
    _sum = 0
    for (var i = 1; i <= 10; i++)
        _sum = _sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    _rest = (_sum * 10) % 11
    if ((_rest == 10) || (_rest == 11)) _rest = 0
    if (_rest != parseInt(cpf.substring(10, 11))) return false
    return true
}
