import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { getModalTypeList } from '../services/configService';
import { createScheduleModel, convertScheduleObjectToForm } from '../models/scheduleModel';
import { createExampleLine, createTableBase, createTableOptions, getBorderObject } from './ImporterUtils';
import moment from 'moment';
import ReactExport from "react-data-export";
// https://www.npmjs.com/package/react-data-export
// Biblioteca para ler excel npm i read-excel-file

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelCell = ReactExport.ExcelFile.ExcelCell;
const border = getBorderObject();

const ScheduleImporter = (props) => {
    const {
        exampleFile = false,
        data,
        button,
        groupList,
        speakersList,
    } = props;
    //O component funciona tanto para exportar a planilha de exemplo, como para devolver a planilha preenchida com os erros
    //Data deve ser um array de objetos do tipo createFormControlModel, conforme conversão de objeto convertScheduleObjectToForm, ou uma lista de schedules
    //Caso seja uma planilha de exemplo, devolver os values com os exemplos a serem seguidos

    const scheduleFormList = convertScheduleObjectToForm(createScheduleModel());
    const meetingTypes = getModalTypeList();

    function createTableInstructions() {
        var _multiDataSet = [
            {
                columns: [
                    {
                        title: getDic("coluna"),
                        style: {
                            font: { bold: true },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "9e9e9e" }
                            },
                            border: border
                        }
                    },
                    {
                        title: getDic("valor"),
                        style: {
                            font: { bold: true },
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "9e9e9e" }
                            },
                            border: border
                        }
                    }
                ],
                data: []
            },
        ];

        const _rows = [];
        scheduleFormList.forEach(item => {
            const _row = [];
            const _obj1 = {
                value: `${item.name.toUpperCase()}${item.required ? " *" : ""}`,
                style: {
                    border: border
                }
            }
            _row.push(_obj1);

            var _instruction = "";
            switch (item.dbReference) {
                case "id":
                    _instruction = "Campo numérico, para cadastrar um registro novo, deixar em branco, caso esteja preenchido, o registro existente será atualizado";
                    break;
                case "title":
                    _instruction = "Campo Obrigatório: Texto comum";
                    break;
                case "description":
                    _instruction = "Texto comum";
                    break;
                case "startDate":
                    _instruction = "Campo Obrigatório: Data no formato numérico DD/MM/AAAA";
                    break;
                case "startTime":
                    _instruction = "Campo Obrigatório: Horário no formato numérico HH:MM";
                    break;
                case "endTime":
                    _instruction = "Campo Obrigatório: Horário no formato numérico HH:MM";
                    break;
                case "maximumNumberOfGuets":
                    _instruction = "Campo numérico, apenas números inteiros";
                    break;
                case "meetingCode":
                    _instruction = "Campo Obrigatório: Texto comum";
                    break;
                case "meetingType":
                    _instruction = "Campo Obrigatório: Um único dado podendo ser o ID ou o Texto conforme a aba " + `${getDic("tipo")} ${getDic("conferencia")}`;
                    break;
                case "speakers":
                    _instruction = "Um ou mais dados (Caso seja mais de um, separar por vírgula ',') podendo ser o ID ou o Texto conforme a aba " + getDic("palestrantes");
                    break;
                case "groupId":
                    _instruction = "Um ou mais dados (Caso seja mais de um, separar por vírgula ',') podendo ser o ID ou o Texto conforme a aba " + getDic("grupo");
                    break;
                case "stylesString":
                    _instruction = "Texto comum";
                    break;
                case "restrictAccess":
                    _instruction = "Campo de escolha fechado (Sim ou Não), sendo o valor 'true' para 'Sim' e 'false' para 'Não'";
                    break;
                case "password":
                    _instruction = "Texto comum";
                    break;
                default:
                    break;
            }

            const _obj2 = {
                value: _instruction,
                style: {
                    border: border
                }
            }
            _row.push(_obj2);
            _rows.push(_row);
        });

        _multiDataSet[0].data = _rows;

        return _multiDataSet;
    }

    function createDataList() {
        const _rows = [];
        data.forEach(item => {
            const _row = [];
            var _formObject;
            if (item[0] && item[0].dbReference) {
                _formObject = item;
            }
            else {
                _formObject = convertScheduleObjectToForm(item);
            }
            _formObject.forEach(obj => {
                var _value = obj.value ? obj.value.toString() : "";
                switch (obj.dbReference) {
                    case "startDate":
                        if (!obj.error) {
                            _value = obj.value ? moment(obj.value).format("DD/MM/YYYY") : "";
                        }
                        else {
                            _value = obj.value;
                        }
                        break;
                    case "startTime":
                        if (!obj.error) {
                            _value = obj.value ? moment(obj.value).format("HH:mm") : "";
                        }
                        else {
                            _value = obj.value;
                        }
                        break;
                    case "endTime":
                        if (!obj.error) {
                            _value = obj.value ? moment(obj.value).format("HH:mm") : "";
                        }
                        else {
                            _value = obj.value;
                        }
                        break;
                    case "restrictAccess":
                        _value = obj.value === true ? "true" : "";
                        break;
                    default:
                        break;
                }

                const _obj = {
                    value: _value + (obj.error ? ` => ${getDic("erro")}: ${obj.errorMessage}` : ""),
                    style: {
                        border: border,
                        fill: {
                            fgColor: { rgb: obj.error ? "ff0000" : '00FFFFFF' }
                        },
                    }
                };
                _row.push(_obj);
            });
            _rows.push(_row);
        });
        return _rows;
    }

    if (data && data.length > 0 || exampleFile) {

        const _instructions = createTableInstructions();
        const _meetingTypes = createTableOptions(meetingTypes, `${getDic("tipo")} ${getDic("conferencia")}`);
        const _speakersList = createTableOptions(speakersList, getDic("palestrantes"));
        const _groupList = createTableOptions(groupList, getDic("grupo"));
        var _multiDataSet = createTableBase(`${getDic("importador")} ${getDic("programacao")}`, scheduleFormList);
        var _rows = [];

        if (exampleFile) {
            _rows.push(createExampleLine(scheduleFormList));
        }
        else if (data.length > 0) {
            _rows = createDataList();
        }

        _multiDataSet[3].data = _rows;

        return (
            <ExcelFile filename={`${getDic("programacao")} - ID ${props.event.id} - ${moment().format("DD-MM-YYYY HH-MM")}`} element={button}>
                <ExcelSheet dataSet={_multiDataSet} name={getDic("importador")} />
                <ExcelSheet dataSet={_meetingTypes} name={`${getDic("tipo")} ${getDic("conferencia")}`} />
                <ExcelSheet dataSet={_speakersList} name={getDic("palestrantes")} />
                <ExcelSheet dataSet={_groupList} name={getDic("grupo")} />
                <ExcelSheet dataSet={_instructions} name={getDic("instrucoes")} />
            </ExcelFile>
        );
    }
    return (
        <ExcelFile element={button} />
    );
}

function mapStateToProps(state) {
    return {
        event: state.event,
    }
}

export default connect(mapStateToProps, null)(ScheduleImporter);

