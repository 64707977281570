import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { store } from "../../store";
import { changeUrlTags } from "../../utils";
import { ElementTreatment } from "../";
import { addNewUserImageApiAvatar } from "../../services/fileService";
import { userUpdate, userLoginSuccess } from "../../store/actions";
import { getRankingApi3DVista } from "../../services/gameficationService";

const Hall3D = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [executeThread, setExecuteThread] = React.useState(false);
  const [executeElement, setExecuteElement] = React.useState(false);
  const [chosenConfig, setChosenConfig] = React.useState(null);
  const [widthStatic, setWidthStatic] = React.useState(0);
  const [heightStatic, setheightStatic] = React.useState(0);
  const [url, setUrl] = React.useState(changeUrlTags([props.hall_360_url]));

  const executeConfig = (_id) => {
    const _config = props.configList.find((item) => {
      return item.id === parseInt(_id);
    });
    if (_config) {
      setChosenConfig(_config);
      setExecuteElement(true);
    }
  };

  const onLoad = () => {
    if (true) {
      var inIFrame = false;
      if (!inIFrame) {
        var onResize = function (async) {
          [0, 250, 1000, 2000].forEach(function (delay) {
            setTimeout(function () {
              var viewer = document.querySelector(".viewer");
              if (!viewer) {
                //clearTimeout(checkViewer.current);
                return;
              }
              var scale =
                window.innerWidth / document.documentElement.clientWidth;
              var width = window.innerWidth;
              var height = Math.round(window.innerHeight / scale);
              viewer.style.width = width + "px";
              viewer.style.height = height + "px";
              //viewer.style.left = Math.round((window.innerWidth - width) * 0.5) + 'px';
              //viewer.style.top = Math.round((window.innerHeight - height) * 0.5) + 'px';
              viewer.style.transform = "scale(" + scale + ", " + scale + ")";
              viewer.style.position = "absolute";
              if (
                /AppleWebKit/.test(navigator.userAgent) &&
                /Mobile\/\w+/.test(navigator.userAgent)
              ) {
                window.scrollTo(100, 0);
              }
            }, delay);
          });
        };
        var onTouchEnd = function () {
          document.body.removeEventListener("touchend", onTouchEnd);
          clearInterval(resizeIntervalId);
          onResize();
          if (/CriOS/.test(navigator.userAgent)) setInterval(onResize, 4000);
        };
        document.body.addEventListener("touchend", onTouchEnd);
        var resizeIntervalId = setInterval(onResize, 300);
        window.addEventListener("resize", onResize);
        onResize();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", async (event) => {
      if (event.data.site_loaded) {
        const myPoints = await getRankingApi3DVista(props.user.id);
        const points = myPoints.find((e) => e.guestId == props.user.id)
          ? myPoints.find((e) => e.guestId == props.user.id).points
          : 0;

        document
          .getElementById("cross_domain_page")
          .contentWindow.postMessage({ points: points }, "*");
        console.log("pp", points);

        document
          .getElementById("cross_domain_page")
          .contentWindow.postMessage({ name: props.user.name }, "*");

        document
          .getElementById("cross_domain_page")
          .contentWindow.postMessage(
            { set_avatar: true, url: props.user.image },
            "*"
          );
        document
          .getElementById("cross_domain_page")
          .contentWindow.postMessage({ show_img: true }, "*");

        document
          .getElementById("cross_domain_page")
          .contentWindow.postMessage({ groupid: props.user.groupId }, "*");

        console.log("enviando comandos...", props.user);
      }
      /* if (event.data.avatar_id) {
        document
          .getElementById("cross_domain_page")
          .contentWindow.postMessage({ hidden_img: true }, "*");

        let avatar = {
          1: "https://s3.us-east-1.amazonaws.com/bucket.aws.public/hall_74648/Thunder/RAYO.png",
          2: "https://s3.us-east-1.amazonaws.com/bucket.aws.public/hall_74648/Thunder/MAR%C3%89.png",
          3: "https://s3.us-east-1.amazonaws.com/bucket.aws.public/hall_74648/Thunder/LUCE.png",
          4: "https://s3.us-east-1.amazonaws.com/bucket.aws.public/hall_74648/Thunder/MAESTRI.png",
          5: "https://s3.us-east-1.amazonaws.com/bucket.aws.public/hall_74648/Thunder/S.I.A.png",
          6: "https://s3.us-east-1.amazonaws.com/bucket.aws.public/hall_74648/Thunder/TOM-TOM.png",
        };
        const _r = await addNewUserImageApiAvatar(avatar[event.data.avatar_id]);
        let newUser = props.user;
        newUser.image = _r;
        await props.userLoginSuccess(newUser);
        document
          .getElementById("cross_domain_page")
          .contentWindow.postMessage(
            { set_avatar: true, url: avatar[event.data.avatar_id] },
            "*"
          );
        document
          .getElementById("cross_domain_page")
          .contentWindow.postMessage({ show_img: true }, "*");
      } */
      const { config_id } = event.data;
      executeConfig(config_id);
    });

    setWidthStatic(window.innerWidth);
    setheightStatic(window.screen.height - window.innerHeight);
    window.addEventListener("resize", function (event) {
      setWidthStatic(document.documentElement.clientWidth);
      setheightStatic(document.documentElement.clientHeight);
    });
    setLoaded(true);
    onLoad();

    const poweredBy = document.getElementById("poweredBy");
    if (poweredBy) poweredBy.style.position = "absolute";

    return () => {
      const poweredBy = document.getElementById("poweredBy");
      if (poweredBy) poweredBy.style.position = "absolute";
    };
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          overflow: "hidden",
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      >
        <iframe
          id="cross_domain_page"
          key="viewer"
          className="viewer"
          src={url}
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          scrolling="no"
          seamless="seamless"
          style={{
            position: "relative",
            border: "none",
            top: "0!important",
            left: "0!important",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        ></iframe>
      </div>
      {executeElement && (
        <ElementTreatment
          history={props.history}
          chosenConfig={chosenConfig}
          onClose={() => {
            setExecuteElement(false);
            setChosenConfig(null);
          }}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    event: state.event,
    config: state.config,
  };
};
const mapDispatchToProps = {
  userUpdate,
  userLoginSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Hall3D);
