import React from "react";
import ReactHlsPlayer from "react-hls-player";

function VideoM3U8(props) {
  const urlFormat = props.location.pathname.replace(
    `/${props.match.params.id}/videom3u8&url=`,
    ""
  ).concat(props.location?.search)
  // let width = "98%";
  // const dimension = new URLSearchParams(window.location.search).get(
  //   "dimension"
  // );
  // if (dimension >= 1900) {
  //   width = "100%";
  // }
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        outline: 'none'
      }}
    >
      <ReactHlsPlayer
      style={{margin:0,padding:0}}
        autoplay={true}
        controls={true}
        url={urlFormat}
        width="100%"
        height="auto"
      />
    </div>
  );
}

export default VideoM3U8;
