import { SET_ACCESS, DELETE_ACCESS, RESET_ALL_ACCESS } from '../actions';

const INITIAL_STATE = [];

export default function accessReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_ACCESS:
            return [...state, action.access];
        case DELETE_ACCESS:
            var newState = state.filter(obj => {
                return obj.accessDate !== action.access.accessDate;
            });
            return newState;
        case RESET_ALL_ACCESS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
