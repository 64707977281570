import React from 'react';

const Container = props => {
    const {
        children,
        id = "",
        background = null,
        align = null,
        backgroundImage = null,
        backgroundRepeat = 'no-repeat',
        backgroundSize = null,
        height = '100vh',
        centralize = false,
    } = props;

    const styles = {
        container: {
            backgroundColor: background ? background : 'white',
            height: "100%",
            minHeight: height,
            width: '100%',
            // position: 'absolute',
            display: 'flex',
            flexDirection: centralize ? "column" : "auto",
            alignItems: 'flex-start',
            justifyContent: align ? align : 'left',
            zIndex: '-1',
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: backgroundRepeat,
            flexGrow: '1',
            backgroundSize: backgroundSize ? backgroundSize : null,
        },
    };

    return (
        <div id={id} style={
            styles.container
        }>
            {children}
        </div>
    )
};

export default Container;
