import React, { useRef } from "react";
import { connect } from "react-redux";
import { getDic } from "../../assets/i18n/dictionary";
//Imports de components
//Imports de components
import {
  MenuButton,
  BackButton,

  Card,
  Alert,
  Loading,
  Container,
  FormRow,
  FormColumn,
  Avatar,
  FabButton,
  TextMedium,
  TextBig,
  Button,
  Modal,
  InputFieldOutlined,
  TextSmall,
  SelectList,
} from "../../components";
import moment from "moment-timezone";
import AddToCalendar from "react-add-to-calendar";

import "../../scss/BootStrap/index.scss";
import "../../scss/CustomPageCss.scss";
const ics = require("ics");

const SessionContent = (props) => {
  const title = () => {
    let text = props.title.split(":");
    if (text.length > 1) text[0] = text[0] + ':'
    return text[0];
  }
  const subTitle = () => {
    //props.title.split(":").filter((e,index)=> index != 0).map((e,s) => e = e + ':' )
    let text = props.title.split(":");
    let regSub = text.filter((e, index) => index != 0);
    let regMapSub = regSub.map((e, index) => {
      if (regSub.length > 1 && index == 0) e = e + ':';
      return e;
    })
    return regMapSub;
  }
  return (
    <React.Fragment>
      <div
        class="row"
        style={{
          borderBottom: "1px solid #5f5f5f",
          marginBottom: 30,
          display: props.firstSession ? "block" : "none",
        }}
      >
        <div
          class="col-xs-7 col-sm-5 col-md-5 d-md-flex align-items-md-stretch"
          style={{ height: 117, alignItems: "flex-end", display: "flex" }}
        >
          <div
            style={{
              top: "3.25rem",
              left: "3.375rem",
              height: "4.4375rem",
              width: "10.0625rem",
              zIndex: 28,
            }}
          >
            <div
              style={{
                background: `url(${props.imgA})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: "100%",
                display: "block",
                height: "100%",
                width: "100%",
                marginTop: props.imgAStyle,
              }}
            />
          </div>
        </div>
        <div
          class="col-xs-5 col-sm-7 col-md-7 d-md-flex align-items-md-stretch"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              height: "7.4375rem",
              width: props.imgBStyle,
              zIndex: 30,
              position: "relative",
              alignItems: "flex-end",
              display: "flex",
            }}
          >
            <img src={props.imgB} style={{ width: "100%" }} />
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-12 d-md-flex align-items-md-stretch"
          style={{ marginBottom: 22 }}
        >
          <p>{props.sessionNotes}</p>
          <p>
            <p>
              <span
                style={{
                  color: props.defaultColor,
                  fontSize: "1.60rem",
                  fontWeight: 700,
                }}
              >
                {title()}
              </span>
            </p>
            <p>
              <span
                style={{
                  color: props.defaultColor,
                  fontSize: "1.60rem",
                  fontWeight: 400,
                }}
              >
                {subTitle()}
              </span>
            </p>
          </p>
        </div>
        <div
          class="col-md-7 d-md-flex align-items-md-stretch"
          style={{ fontSize: "0.8669rem" }}
        >
          <p>
            <strong>Synopsis</strong>
          </p>
          <p>
            <span>{props.synopsis}</span>
          </p>
        </div>
        <div
          class="col-md-5 d-md-flex align-items-md-stretch"
          style={{ borderLeft: "1px solid #5d5d5d", fontSize: "0.8669rem" }}
        >
          <p>
            <strong>Speakers</strong>
          </p>
          <p>
            <ul class="x_aa37d1ab x_8b9ce48e">{props.speakers}</ul>
          </p>
          <br />
          <p>
            <strong>Session time options ({props.duration}' session):</strong>
          </p>
          <p class="checkDate">{props.times}</p>
        </div>
      </div>
      <div className="row">
        <div class="col-md-6 d-md-flex align-items-md-stretch">
          <p>
            <br />
            <br />
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

/*
CET - Europe/Berlin
EST - America/New_York
HKT - Asia/Hong_Kong
*/
const DataStructure = {
  title: "",
  description: "",
  start: "",
  timeZonestart: "",
  end: "",
  timeZoneend: "",
};
const PageCustom = (props) => {
  const [isSafari, setIsSafari] = React.useState(true);
  const [icsDone, setIcsDone] = React.useState(false);
  const [eventA, setEventA] = React.useState(DataStructure);
  const [eventB, setEventB] = React.useState(DataStructure);
  const [eventC, setEventC] = React.useState(DataStructure);
  const [eventD, setEventD] = React.useState(DataStructure);
  const [eventE, setEventE] = React.useState(DataStructure);
  const [eventF, setEventF] = React.useState(DataStructure);
  const [eventG, setEventG] = React.useState(DataStructure);
  const [eventH, setEventH] = React.useState(DataStructure);
  const [eventI, setEventI] = React.useState(DataStructure);
  const [eventJ, setEventJ] = React.useState(DataStructure);
  const [eventL, setEventL] = React.useState(DataStructure);
  const [eventM, setEventM] = React.useState(DataStructure);
  const [eventN, setEventN] = React.useState(DataStructure);
  const [eventO, setEventO] = React.useState(DataStructure);
  const [eventP, setEventP] = React.useState(DataStructure);
  const [eventQ, setEventQ] = React.useState(DataStructure);


  /*
    CET - Europe/Berlin
    EST - America/New_York
    HKT - Asia/Hong_Kong    
  */

  const returnDateTimeZone = (strStartTime, timeEnd) => {
    // parse with NYC time, and then convert to UTC 
    var CurrentTime = moment(strStartTime)

    //MystartTime = new Date(MystartTime.format().toString())
    if (timeEnd) CurrentTime = CurrentTime.add(timeEnd, 'minutes')
    else CurrentTime = CurrentTime


    let myDate = [
      parseInt(CurrentTime.format("YYYY")),
      parseInt(CurrentTime.format("MM")),
      parseInt(CurrentTime.format("DD")),
      parseInt(CurrentTime.format("HH")),
      parseInt(CurrentTime.format("mm")),
    ];

    return myDate;
  };

  const saveZip = (data) => {
    const JSZip = require("jszip");
    var zip = new JSZip();
    data.forEach(_ics => {
      zip.file(`${_ics.title}.ics`, _ics.data)
    });

    //var ics = zip.folder("images");
    //zip.file("calendar.ics", data, {base64: true});
    if (data.length < 2) {
      var dataStr = encodeURI(
        "data:text/calendar;charset=utf8," + data[0].data.split("\n").join("\n")
      );
      var downloadAnchorNode = document.getElementById("downloadICS");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", `${data[0].title}.ics`);
      //document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
    } else {
      zip.generateAsync({ type: "base64" }).then(function (content) {
        // see FileSaver.js
        //saveAs(content, "example.zip");
        var downloadAnchorNode = document.getElementById("downloadICS");
        downloadAnchorNode.setAttribute("href", "data:application/zip;base64," + content);
        downloadAnchorNode.setAttribute("download", `calendar.zip`);
        downloadAnchorNode.click();
      });
    }
  }

  const createUnitEvents = () => {
    let events = [];
    if (eventA.title) events.push(eventA);
    if (eventB.title) events.push(eventB);
    if (eventC.title) events.push(eventC);
    if (eventD.title) events.push(eventD);
    if (eventE.title) events.push(eventE);
    if (eventF.title) events.push(eventF);
    if (eventG.title) events.push(eventG);
    if (eventH.title) events.push(eventH);
    if (eventI.title) events.push(eventI);
    if (eventJ.title) events.push(eventJ);
    if (eventL.title) events.push(eventL);
    if (eventM.title) events.push(eventM);
    if (eventN.title) events.push(eventN);
    if (eventO.title) events.push(eventO);
    if (eventP.title) events.push(eventP);
    if (eventQ.title) events.push(eventQ);

    let icsZipFile = [];

    events.forEach(item => {
      ics.createEvent(item, function (error, value) {
        if (error) {
          console.log(error)
        }

        var removeZIndex = value.split("\n").map((item) => {
          if (item.indexOf('DTSTAM') > -1) {
            item = item.replace('Z', '\r\n');
          }
          if (item.indexOf('DTSTAR') > -1) {
            item = item.replace('Z', '\r\n');
          }
          if (item.indexOf('DTEND') > -1) {
            item = item.replace('Z', '\r\n');
          }
          return item;
        });

        let finalCalendar = removeZIndex.join("\n").replace(/DTEND:/g, "DTEND;TZID=Europe/Berlin:")
        finalCalendar = finalCalendar.replace(/DTSTART:/g, "LOCATION:https://welcome.leaderinsights.co\r\nDTSTART;TZID=Europe/Berlin:")
        finalCalendar = finalCalendar.replace(/SUMMARY:/g, "SUMMARY:Teleperformance LIF2020 - ")
        finalCalendar = finalCalendar.replace("X-PUBLISHED-TTL:PT1H", `X-PUBLISHED-TTL:PT1H\r\nBEGIN:VTIMEZONE\r\nTZID:Europe/Berlin\r\nTZURL:http://tzurl.org/zoneinfo-outlook/Europe/Berlin\r\nX-LIC-LOCATION:Europe/Berlin\r\nBEGIN:DAYLIGHT\r\nTZOFFSETFROM:+0100\r\nTZOFFSETTO:+0200\r\nTZNAME:CEST\r\nDTSTART:19700329T020000\r\nRRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU\r\nEND:DAYLIGHT\r\nBEGIN:STANDARD\r\nTZOFFSETFROM:+0200\r\nTZOFFSETTO:+0100\r\nTZNAME:CET\r\nDTSTART:19701025T030000\r\nRRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU\r\nEND:STANDARD\r\nEND:VTIMEZONE\r\n`)

        //var dataStr = encodeURI(
        //  "data:text/calendar;charset=utf8," + finalCalendar.split("\n").join("\n")
        //);
        //var downloadAnchorNode = document.getElementById("downloadICS");
        //downloadAnchorNode.setAttribute("href", dataStr);
        //downloadAnchorNode.setAttribute("download", `${item.title}.ics`);
        //if(!isSafari){
        //document.body.appendChild(downloadAnchorNode); // required for firefox
        //downloadAnchorNode.click();
        //}
        setIcsDone(true);
        icsZipFile.push({ title: item.title, data: finalCalendar });
      });
    });
    saveZip(icsZipFile)
  };

  const createMultipleEvents = () => {
    let events = [];
    if (eventA.title) events.push(eventA);
    if (eventB.title) events.push(eventB);
    if (eventC.title) events.push(eventC);
    if (eventD.title) events.push(eventD);
    if (eventE.title) events.push(eventE);
    if (eventF.title) events.push(eventF);
    if (eventG.title) events.push(eventG);
    if (eventH.title) events.push(eventH);
    if (eventI.title) events.push(eventI);
    if (eventJ.title) events.push(eventJ);
    if (eventL.title) events.push(eventL);
    if (eventM.title) events.push(eventM);
    if (eventN.title) events.push(eventN);
    if (eventO.title) events.push(eventO);
    if (eventP.title) events.push(eventP);
    if (eventQ.title) events.push(eventQ);



    ics.createEvents(events, function (error, value) {
      if (error) {
        console.log(error)
      }

      var removeZIndex = value.split("\n").map((item) => {
        if (item.indexOf('DTSTAM') > -1) {
          item = item.replace('Z', '\r\n');
        }
        if (item.indexOf('DTSTAR') > -1) {
          item = item.replace('Z', '\r\n');
        }
        if (item.indexOf('DTEND') > -1) {
          item = item.replace('Z', '\r\n');
        }
        return item;
      });

      let finalCalendar = removeZIndex.join("\n").replace(/DTEND:/g, "DTEND;TZID=Europe/Berlin:")
      finalCalendar = finalCalendar.replace(/DTSTART:/g, "LOCATION:https://welcome.leaderinsights.co\r\nDTSTART;TZID=Europe/Berlin:")
      finalCalendar = finalCalendar.replace(/SUMMARY:/g, "SUMMARY:Teleperformance LIF2020 - ")
      finalCalendar = finalCalendar.replace("X-PUBLISHED-TTL:PT1H", `X-PUBLISHED-TTL:PT1H\r\nBEGIN:VTIMEZONE\r\nTZID:Europe/Berlin\r\nTZURL:http://tzurl.org/zoneinfo-outlook/Europe/Berlin\r\nX-LIC-LOCATION:Europe/Berlin\r\nBEGIN:DAYLIGHT\r\nTZOFFSETFROM:+0100\r\nTZOFFSETTO:+0200\r\nTZNAME:CEST\r\nDTSTART:19700329T020000\r\nRRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU\r\nEND:DAYLIGHT\r\nBEGIN:STANDARD\r\nTZOFFSETFROM:+0200\r\nTZOFFSETTO:+0100\r\nTZNAME:CET\r\nDTSTART:19701025T030000\r\nRRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU\r\nEND:STANDARD\r\nEND:VTIMEZONE\r\n`)

      var dataStr = encodeURI(
        "data:text/calendar;charset=utf8," + finalCalendar.split("\n").join("\n")
      );
      var downloadAnchorNode = document.getElementById("downloadICS");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "calendar.ics");
      if (!isSafari) {
        //document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
      }
      setIcsDone(true);

    });
  };

  const iOS = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
      // android or ETC
      toMatch.some((toMatchItem) => { return navigator.userAgent.match(toMatchItem); })
    );
  };

  React.useEffect(() => {
    let a = "";
    setIsSafari(iOS());
    setIcsDone(false);
    return () => {
      let b = "";
    };
  }, [
    eventA, eventB, eventC, eventD,
    eventE, eventF, eventG, eventH,
    eventI, eventJ, eventL, eventM,
    eventN, eventO, eventP, eventQ
  ]);

  return (
    <React.Fragment>
      <div className="parent" style={{ backgroundColor: "white" }}>
        <div style={{ paddingTop: 50 }}>
          <div id="downloadAnchorElem" />

          <div
            class="container-fluid"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <div class="content d-flex flex-column justify-content-center">
              {/*Início da Punk CX  */}
              <section style={{ paddingRight: "10%", paddingLeft: "10%" }}>
                <SessionContent
                  title="Punk CX"
                  firstSession={true}
                  imgAStyle={0}
                  imgBStyle="8.5rem"
                  imgA="https://v.fastcdn.co/u/30a313ac/52551596-0-amaze.png"
                  imgB="https://v.fastcdn.co/u/30a313ac/52572046-0-onda01.png"
                  synopsis="Punk exploded out of progressive rock with its intense, back-to-basics approach that inspired both a cultural and musical movement that changed people’s entire mindset. Given that many reports suggest that around 70% of customer experience projects fail to deliver on their promises, Adrian shares a daring perspective on what a punk rock version of CX would look and feel like."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="Punk CX"
                          checked={
                            eventA.start.toString() ==
                              returnDateTimeZone(
                                "2020/10/05 05:00:00 PM "
                              ).toString()
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventA.start.toString() ==
                              returnDateTimeZone(
                                "2020/10/05 05:00:00 PM"
                              ).toString()
                              ? setEventA(DataStructure)
                              : setEventA({
                                title: "Punk CX",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Keynote Session, Adrian Swinscoe will be sharing insights around Punk CX.

Punk exploded out of progressive rock with its intense, back-to-basics approach that inspired both a cultural and musical movement that changed people’s entire mindset. Given that many reports suggest that around 70% of customer experience projects fail to deliver on their promises, Adrian shares a daring perspective on what a punk rock version of CX would look and feel like.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, a recorded version will be available tomorrow at 10:30 am (Paris time) | 4:30 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                    `,
                                start: returnDateTimeZone(
                                  "2020/10/05 05:00:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/05 05:00:00 PM",
                                  45
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_7b2817bf">
                          <span style={{ color: "#37465A" }}>
                            October 5 - 11:00 am (Miami time) | 5:00 pm (Paris time)
                          </span>
                        </span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="Punk CX"
                          checked={
                            eventA.start.toString() ==
                              returnDateTimeZone(
                                "2020/10/06 10:30:00 AM"
                              ).toString()
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventA.start.toString() ==
                              returnDateTimeZone("2020/10/06 10:30:00 AM")
                              ? setEventA(DataStructure)
                              : setEventA({
                                title: "Punk CX",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Keynote Session, Adrian Swinscoe will be sharing insights around Punk CX.

Punk exploded out of progressive rock with its intense, back-to-basics approach that inspired both a cultural and musical movement that changed people’s entire mindset. Given that many reports suggest that around 70% of customer experience projects fail to deliver on their promises, Adrian shares a daring perspective on what a punk rock version of CX would look and feel like.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, a recorded version will be available tomorrow at 10:30 am (Paris time) | 4:30 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                    `
                                ,
                                start: returnDateTimeZone(
                                  "2020/10/06 10:30:00 AM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/06 10:30:00 AM",
                                  45
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_7b2817bf">
                          <span style={{ color: "#37465A" }}>
                            October 6 - 10:30 am (Paris time) | 4:30 pm (Hong Kong time)
                          </span>
                        </span>
                      </li>
                    </ul>
                  }
                  duration={45}
                  sessionNotes="Keynote Session: Adrian Swinscoe"
                  speakers={
                    <li>
                      <span class="x_7b2817bf">
                        <strong>Adrian Swinscoe, </strong>Keynote Speaker and
                        Author
                      </span>
                    </li>
                  }
                  defaultColor="#ff5c00"
                />
                <SessionContent
                  title="Amazing CX: Building Amazing Customer Experiences at Scale"
                  synopsis="In a fiercely competitive world, the difference between keeping and losing a customer can come down to one single interaction with your brand. In this keynote session, you will hear from two leading, global brands on how they remain competitive and aim to delight their customers – whenever and wherever they interact with their customers ¬ across channels, services, geographies, and customer segments."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <span class="x_7b2817bf">
                          <input
                            type="radio"
                            name="Amazing CX"
                            checked={
                              eventB.start.toString() ==
                                returnDateTimeZone("2020/10/05 5:50:00 PM")
                                ? true
                                : false
                            }
                            onClick={(e) =>
                              eventB.start.toString() ==
                                returnDateTimeZone("2020/10/05 5:50:00 PM")
                                ? setEventB(DataStructure)
                                : setEventB({
                                  title:
                                    "Amazing CX: Building Amazing Customer Experiences at Scale",
                                  description: `Thank you for registering for the Leader Insights Forum! 

At this Roundtable, Lisa Stoner, Global Head of Support Operations at Uber, Kristin Embury, Vice President, Customer Service at Netflix, and Dekyi Boorsma, Director of Customer Services, EMEA at Netflix, will speak about Building Amazing Customer Experiences at Scale. 

In a fiercely competitive world, the difference between keeping and losing a customer can come down to one single interaction with your brand. In this keynote session, you will hear from two leading, global brands on how they remain competitive and aim to delight their customers — whenever and wherever they interact with their customers — across channels, services, geographies, and customer segments.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, you can watch the recorded version tomorrow at 11:20 am (Paris time) | 5:20 pm (Hong Kong time). Please visit the event lobby to access the content.  

Thank you and enjoy the session! 
                                      `,
                                  start: returnDateTimeZone(
                                    "2020/10/05 5:50:00 PM"
                                  ),
                                  /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                  end: returnDateTimeZone(
                                    "2020/10/05 5:50:00 PM",
                                    45
                                  ),
                                  startInputType: "utc",
                                  endInputType: "utc",
                                })
                            }
                          />
                          <span style={{ color: "#37465A" }}>
                            October 5 - 11:50 am (Miami time) | 5:50 pm (Paris time)
                          </span>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <input
                            type="radio"
                            name="Amazing CX"
                            checked={
                              eventB.start.toString() ==
                                returnDateTimeZone("2020/10/06 11:20:00 AM")
                                ? true
                                : false
                            }
                            onClick={(e) =>
                              eventB.start.toString() ==
                                returnDateTimeZone("2020/10/06 11:20:00 AM")
                                ? setEventB(DataStructure)
                                : setEventB({
                                  title:
                                    "Amazing CX: Building Amazing Customer Experiences at Scale",
                                  description: `Thank you for registering for the Leader Insights Forum! 

At this Roundtable, Lisa Stoner, Global Head of Support Operations at Uber, Kristin Embury, Vice President, Customer Service at Netflix, and Dekyi Boorsma, Director of Customer Services, EMEA at Netflix, will speak about Building Amazing Customer Experiences at Scale. 

In a fiercely competitive world, the difference between keeping and losing a customer can come down to one single interaction with your brand. In this keynote session, you will hear from two leading, global brands on how they remain competitive and aim to delight their customers — whenever and wherever they interact with their customers — across channels, services, geographies, and customer segments.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, you can watch the recorded version tomorrow at 11:20 am (Paris time) | 5:20 pm (Hong Kong time). Please visit the event lobby to access the content.  

Thank you and enjoy the session! 
                                        `
                                  ,
                                  start: returnDateTimeZone(
                                    "2020/10/06 11:20:00 AM"
                                  ),
                                  /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                  end: returnDateTimeZone(
                                    "2020/10/06 11:20:00 AM",
                                    45
                                  ),
                                  startInputType: "utc",
                                  endInputType: "utc",
                                })
                            }
                          />
                          <span style={{ color: "#37465A" }}>
                            October 6 - 11:20 am (Paris time) | 5:20 pm (Hong Kong time)
                          </span>
                        </span>
                      </li>
                    </ul>
                  }
                  duration={45}
                  sessionNotes="Roundtable - CX: Netflix and Uber"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Lisa Stoner, </strong>Global Head of Support
                          Operations, <strong>Uber</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Kristin Embury,</strong> Vice President,
                          Customer Service, <strong>Netflix</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Dekyi Boorsma,</strong> Director of Customer
                          Services, EMEA, <strong>Netflix</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Miranda Collard (Moderator), </strong>Chief
                          Client Officer,<strong> Teleperformance</strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#ff5c00"
                />
                <SessionContent
                  title="Digital Convenience: Managing CX in a Digital Age for Financial Services"
                  synopsis="Digital transformation is recommended to delight customers regardless of cost and return, implying that, the more you invest, the greater the financial return. This relationship, however, is much more complex. Join BCG's experts as they discuss how financial service organizations can strike the right balance between digital convenience and customer expectations, while also considering what makes sense from a financial perspective."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <span class="x_7b2817bf">
                          <input
                            type="radio"
                            name="Digital Convenience: Managing CX in a Digital Age for Financial Services"
                            checked={
                              eventC.start.toString() ==
                                returnDateTimeZone("2020/10/05 06:35:00 PM")
                                ? true
                                : false
                            }
                            onClick={(e) =>
                              eventC.start.toString() ==
                                returnDateTimeZone("2020/10/05 06:35:00 PM")
                                ? setEventC(DataStructure)
                                : setEventC({
                                  title:
                                    "Digital Convenience: Managing CX in a Digital Age for Financial Services",
                                  description: `Thank you for registering for the Leader Insights Forum! 

At this Session, Christophe Duthoit, Managing Director and Senior Partner at BCG, and Bharat Poddar, Managing Director and Senior Partner at BCG, will be sharing their expertise on Managing CX in a Digital Age for Financial Services.

Digital transformation is recommended to delight customers regardless of cost and return, implying that the more you invest, the greater the financial return. This relationship, however, is much more complex. Join BCG's experts as they discuss how financial service organizations can strike the right balance between digital convenience and customer expectations, while also considering what makes sense from a financial perspective.

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, its recorded version will be available tomorrow at 12:05 pm (Paris time) | 6:05 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                      `,
                                  start: returnDateTimeZone(
                                    "2020/10/05 06:35:00 PM"
                                  ),
                                  /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                  end: returnDateTimeZone(
                                    "2020/10/05 06:35:00 PM",
                                    30
                                  ),
                                  startInputType: "utc",
                                  endInputType: "utc",
                                })
                            }
                          />
                          <span style={{ color: "#37465A" }}>
                            October 5 - 12:35 pm (Miami time) | 6:35 pm (Paris time)
                          </span>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <input
                            type="radio"
                            name="Digital Convenience: Managing CX in a Digital Age for Financial Services"
                            checked={
                              eventC.start.toString() ==
                                returnDateTimeZone("2020/10/06 12:05:00 pm")
                                ? true
                                : false
                            }
                            onClick={(e) =>
                              eventC.start.toString() ==
                                returnDateTimeZone("2020/10/06 12:05:00 pm")
                                ? setEventC(DataStructure)
                                : setEventC({
                                  title:
                                    "Digital Convenience: Managing CX in a Digital Age for Financial Services'",
                                  description: `Thank you for registering for the Leader Insights Forum! 

At this Session, Christophe Duthoit, Managing Director and Senior Partner at BCG, and Bharat Poddar, Managing Director and Senior Partner at BCG, will be sharing their expertise on Managing CX in a Digital Age for Financial Services.

Digital transformation is recommended to delight customers regardless of cost and return, implying that the more you invest, the greater the financial return. This relationship, however, is much more complex. Join BCG's experts as they discuss how financial service organizations can strike the right balance between digital convenience and customer expectations, while also considering what makes sense from a financial perspective.

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, its recorded version will be available tomorrow at 12:05 pm (Paris time) | 6:05 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                      `,
                                  start: returnDateTimeZone(
                                    "2020/10/06 12:05:00 pm"
                                  ),
                                  /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                  end: returnDateTimeZone(
                                    "2020/10/06 12:05:00 pm",
                                    30
                                  ),
                                  startInputType: "utc",
                                  endInputType: "utc",
                                })
                            }
                          />
                          <span style={{ color: "#37465A" }}>
                            October 6 - 12:05 pm (Paris time) | 6:05 pm (Hong Kong time)
                          </span>
                        </span>
                      </li>
                    </ul>
                  }
                  duration={30}
                  sessionNotes="Case Study - Financial Services: BCG (Boston Consulting Group)"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Christophe Duthoit, </strong>Managing Director
                          and Senior Partner,<strong> BCG</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Bharat Poddar, </strong>Managing Director
                          &amp; Senior Partner,<strong> BCG</strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#ff5c00"
                />
              </section>
              {/*Fim da section Punk CX*/}
              {/*Inicio da section Economic Outlook for 2021*/}
              <section
                style={{
                  backgroundColor: "#ededed",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
              >
                <SessionContent
                  title="Reignition: Economic Outlook for 2021"
                  firstSession={true}
                  imgAStyle={23}
                  imgBStyle="8.5rem"
                  imgA="https://v.fastcdn.co/u/30a313ac/53117111-0-accelerate-the.png"
                  imgB="https://v.fastcdn.co/u/30a313ac/52572061-0-onda02.png"
                  synopsis="As the COVID-19 pandemic continues to impact global economies, HSBC’s expert on Emerging Markets will provide economic insights, projections for 2021, and a look at the political challenges that lay ahead."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="Reignition: Economic Outlook for 2021"
                          checked={
                            eventD.start.toString() ==
                              returnDateTimeZone("2020/10/06 05:00:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventD.start.toString() ==
                              returnDateTimeZone("2020/10/06 05:00:00 PM")
                              ? setEventD(DataStructure)
                              : setEventD({
                                title: "Reignition: Economic Outlook for 2021",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Keynote Session, Paul Mackel, Head of Global Emerging Markets FX Research at HSBC, will be sharing insights around the Economic Outlook for 2021. 

As the COVID-19 pandemic continues to impact global economies, HSBC’s expert on Emerging Markets will provide economic insights, projections for 2021, and a look at the political challenges that lay ahead.

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, its recorded version will be available tomorrow at 10:30 am (Paris time) | 4:30 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
`,
                                start: returnDateTimeZone(
                                  "2020/10/06 05:00:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/06 05:00:00 PM",
                                  45
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_22a0fbd4">
                          October 6 - 11:00 am (Miami time) | 5:00 pm (Paris time)
                        </span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="Reignition: Economic Outlook for 2021"
                          checked={
                            eventD.start.toString() ==
                              returnDateTimeZone("2020/10/07 10:30:00 AM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventD.start.toString() ==
                              returnDateTimeZone("2020/10/07 10:30:00 AM")
                              ? setEventD(DataStructure)
                              : setEventD({
                                title: "Reignition: Economic Outlook for 2021",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Keynote Session, Paul Mackel, Head of Global Emerging Markets FX Research at HSBC, will be sharing insights around the Economic Outlook for 2021. 

As the COVID-19 pandemic continues to impact global economies, HSBC’s expert on Emerging Markets will provide economic insights, projections for 2021, and a look at the political challenges that lay ahead.

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, its recorded version will be available tomorrow at 10:30 am (Paris time) | 4:30 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                  `,
                                start: returnDateTimeZone(
                                  "2020/10/07 10:30:00 AM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/07 10:30:00 AM",
                                  45
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_1aca3346">
                          October 7 - 10:30 am (Paris time) | 4:30 pm (Hong Kong time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={45}
                  sessionNotes="Keynote Session - Economy: HSBC"
                  speakers={
                    <li>
                      <span class="x_7b2817bf">
                        <strong>Paul Mackel, </strong>Head of Global Emerging
                        Markets FX Research,<strong> HSBC</strong>
                      </span>
                    </li>
                  }
                  defaultColor="#ff0082"
                />
                <SessionContent
                  title="Digitally Transformed Operations: The New Norm"
                  synopsis="The current times have forced a sudden and accelerated need for digital transformation and integrated business services, where the customer’s needs and experiences are front and center in the entire business operations. Join our banking experts as we discuss why digital transformation and automation in operations is the way forward for business resilience and higher productivity. "
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="Digitally Transformed Operations: The New Norm"
                          checked={
                            eventE.start.toString() ==
                              returnDateTimeZone("2020/10/06 05:45:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventE.start.toString() ==
                              returnDateTimeZone("2020/10/06 05:45:00 PM")
                              ? setEventE(DataStructure)
                              : setEventE({
                                title: "Digitally Transformed Operations: The New Norm",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Session, Afzal Khanani, Head of Retail Operations at Abu Dhabi Islamic Bank, will be sharing their expertise on Digitally Transformed Operations: The New Norm.

The current times have forced a sudden and accelerated need for digital transformation and integrated business services, where the customer’s needs and experiences are front and center in the entire business operations. Join our banking experts as we discuss why digital transformation and automation in operations is the way forward for business resilience and higher productivity. 

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, its recorded version will be available tomorrow at 11:15 am (Paris time) | 5:15 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                  `,
                                start: returnDateTimeZone(
                                  "2020/10/06 05:45:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/06 05:45:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_1aca3346">
                          October 6 - 11:45 am (Miami time) | 5:45 pm (Paris time)
                        </span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="Digitally Transformed Operations: The New Norm"
                          checked={
                            eventE.start.toString() ==
                              returnDateTimeZone("2020/10/07 11:15:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventE.start.toString() ==
                              returnDateTimeZone("2020/10/07 11:15:00 PM")
                              ? setEventE(DataStructure)
                              : setEventE({
                                title: "Digitally Transformed Operations: The New Norm",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Session, Afzal Khanani, Head of Retail Operations at Abu Dhabi Islamic Bank, will be sharing their expertise on Digitally Transformed Operations: The New Norm.

The current times have forced a sudden and accelerated need for digital transformation and integrated business services, where the customer’s needs and experiences are front and center in the entire business operations. Join our banking experts as we discuss why digital transformation and automation in operations is the way forward for business resilience and higher productivity. 

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, its recorded version will be available tomorrow at 11:15 am (Paris time) | 5:15 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                  `,
                                start: returnDateTimeZone(
                                  "2020/10/07 11:15:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/07 11:15:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_1aca3346">
                          October 7 - 11:15 am (Paris time) | 5:15 pm (Hong Kong time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={30}
                  sessionNotes="Case Study - Digital Transformation: ADIB (Abu Dhabi Islamic Bank)"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_0c14bed9">Afzal Kh</span>
                        <span class="x_e1c17884">
                          <strong>anani, </strong>Head of Retail Operations,
                          <strong> Abu Dhabi Islamic Bank</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_ef16cb30">
                          <strong>Rupa Ramamurthy (Moderator), </strong>
                          Executive Vice President – Banking Operations,{" "}
                          <strong>Teleperformance</strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#ff0082"
                />
                <SessionContent
                  title="Data Storm: Privacy in the New Normal: Data Trends, Regulatory Challenges, and Digital Responsibilities"
                  synopsis="The COVID-19 pandemic has accelerated the collection and use of data in unprecedented ways. When we step back from this flurry of digital activity triggered by the global crisis, we are left wondering how this will forever change the way we look at data. How will the use of data continue to evolve, and what does the “new normal” look like for privacy practitioners, organizations, regulators, policymakers and entire societies."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="Data Storm: Privacy in the New Normal: Data Trends, Regulatory Challenges, and Digital Responsibilities"
                          checked={
                            eventF.start.toString() ==
                              returnDateTimeZone("2020/10/06 06:15:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventF.start.toString() ==
                              returnDateTimeZone("2020/10/06 06:15:00 PM")
                              ? setEventF(DataStructure)
                              : setEventF({
                                title:
                                  "Data Storm: Privacy in the New Normal: Data Trends, Regulatory Challenges, and Digital Responsibilities",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Keynote Session, Bojana Bellamy, the President of Centre for Information Policy Leadership, will be sharing insights around Privacy in the New Normal:  Data Trends, Regulatory Challenges, and Digital Responsibilities. 

The COVID-19 pandemic has accelerated the collection and use of data in unprecedented ways. When we step back from this flurry of digital activity triggered by the global crisis, we are left wondering how this will forever change the way we look at data. How will the use of data continue to evolve, and what does the “new normal” look like for privacy practitioners, organizations, regulators, policymakers, and entire societies?

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, its recorded version will be available tomorrow at 11:45 am (Paris time) | 5:45 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                    `,
                                start: returnDateTimeZone(
                                  "2020/10/06 06:15:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/06 06:15:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_22a0fbd4">
                          October 6 - 12:15 pm (Miami time) | 6:15 pm (Paris time)
                        </span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="Data Storm: Privacy in the New Normal: Data Trends, Regulatory Challenges, and Digital Responsibilities"
                          checked={
                            eventF.start.toString() ==
                              returnDateTimeZone("2020/10/07 11:45:00 AM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventF.start.toString() ==
                              returnDateTimeZone("2020/10/07 11:45:00 AM")
                              ? setEventF(DataStructure)
                              : setEventF({
                                title:
                                  "Data Storm: Privacy in the New Normal: Data Trends, Regulatory Challenges, and Digital Responsibilities",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Keynote Session, Bojana Bellamy, the President of Centre for Information Policy Leadership, will be sharing insights around Privacy in the New Normal:  Data Trends, Regulatory Challenges, and Digital Responsibilities. 

The COVID-19 pandemic has accelerated the collection and use of data in unprecedented ways. When we step back from this flurry of digital activity triggered by the global crisis, we are left wondering how this will forever change the way we look at data. How will the use of data continue to evolve, and what does the “new normal” look like for privacy practitioners, organizations, regulators, policymakers, and entire societies?

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, its recorded version will be available tomorrow at 11:45 am (Paris time) | 5:45 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                                                      `,
                                start: returnDateTimeZone(
                                  "2020/10/07 11:45:00 AM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/07 11:45:00 AM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_1aca3346">
                          October 7 - 11:45 am (Paris time) | 5:45 pm (Hong Kong time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={30}
                  sessionNotes="Keynote Session – Data Security & Privacy: Bojana Bellamy"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Bojana Bellamy, </strong>President,{" "}
                          <strong>
                            Centre for Information Policy Leadership
                          </strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#ff0082"
                />
                <SessionContent
                  title="Power to the People: The “How” of Transformation"
                  synopsis="Traditional business transformations are challenging. Digital transformations are even more complex. Academic research suggests that roughly 70% percent of them fail. But the results from Dropbox customer service transformation point to investment in people, not just technology, as key to unlocking the digital transformation opportunity."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="Power to the People: The “How” of Transformation"
                          checked={
                            eventG.start.toString() ==
                              returnDateTimeZone("2020/10/06 06:45:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventG.start.toString() ==
                              returnDateTimeZone("2020/10/06 06:45:00 PM")
                              ? setEventG(DataStructure)
                              : setEventG({
                                title:
                                  "Power to the People: The “How” of Transformation",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Session, Lewis Taylor, Global Head of Support at Dropbox, will be sharing their expertise on Power to the People: The “How” of Transformation.

Traditional business transformations are challenging. Digital transformations are even more complex. Academic research suggests that roughly 70% percent of them fail. But the results from Dropbox customer service transformation point to investment in people, not just technology, as key to unlocking the digital transformation opportunity.

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, its recorded version will be available tomorrow at 12:15 pm (Paris time) | 6:15 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                    `,
                                start: returnDateTimeZone(
                                  "2020/10/06 06:45:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/06 06:45:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_22a0fbd4">
                          October 6 - 12:45 pm (Miami time) | 6:45 pm (Paris time)
                        </span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="Power to the People: The “How” of Transformation"
                          checked={
                            eventG.start.toString() ==
                              returnDateTimeZone("2020/10/07 12:15:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventG.start.toString() ==
                              returnDateTimeZone("2020/10/07 12:15:00 PM")
                              ? setEventG(DataStructure)
                              : setEventG({
                                title:
                                  "Power to the People: The “How” of Transformation",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Session, Lewis Taylor, Global Head of Support at Dropbox, will be sharing their expertise on Power to the People: The “How” of Transformation.

Traditional business transformations are challenging. Digital transformations are even more complex. Academic research suggests that roughly 70% percent of them fail. But the results from Dropbox customer service transformation point to investment in people, not just technology, as key to unlocking the digital transformation opportunity.

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, its recorded version will be available tomorrow at 12:15 pm (Paris time) | 6:15 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                                                      `,
                                start: returnDateTimeZone(
                                  "2020/10/07 12:15:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/07 12:15:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_1aca3346">
                          October 7 - 12:15 pm (Paris time) | 6:15 pm (Hong Kong time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={30}
                  sessionNotes="Case Study – Transformation: Dropbox"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Lewis Taylor, </strong>Global Head of Support,{" "}
                          <strong>Dropbox</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf"><strong>Athina Karahogiti (Moderator),
                        </strong>EVP Strategic Account<br /> Management,
                          <strong>Teleperformance</strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#ff0082"
                />
              </section>
              {/*Fim da section Economic Outlook for 2021*/}
              {/*Inicio da section Equality, Diversity and Inclusion at Work*/}
              <section
                style={{
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
              >
                <SessionContent
                  title="One People: Equality, Diversity and Inclusion at Work"
                  firstSession={true}
                  imgAStyle={17}
                  imgBStyle="9.5rem"
                  imgA="https://v.fastcdn.co/u/30a313ac/53145056-0-All-People.png"
                  imgB="https://v.fastcdn.co/u/30a313ac/52572066-0-onda03.png"
                  synopsis="Diversity and Inclusion-focused initiatives are no longer just HR-led, feel-good exercises. In today’s modern marketplace, diversity has been embraced as a strategic business imperative. Join Tatiana as she shares her experience navigating corporate America as a female from a minority group, and key lessons learned in creating a culture of diversity, inclusion and true belonging."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="One People: Equality, Diversity and Inclusion at Work"
                          checked={
                            eventH.start.toString() ==
                              returnDateTimeZone("2020/10/07 05:00:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventH.start.toString() ==
                              returnDateTimeZone("2020/10/07 05:00:00 PM")
                              ? setEventH(DataStructure)
                              : setEventH({
                                title:
                                  "One People: Equality, Diversity and Inclusion at Work",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Keynote Session Tatiana Quaife, Director, Marketing Strategy at Disneyland will be sharing insights around Equality, Diversity and Inclusion at Work.

Diversity and Inclusion-focused initiatives are no longer just HR-led, feel-good exercises. In today’s modern marketplace, diversity has been embraced as a strategic business imperative. Join Tatiana as she shares her experience navigating corporate America as a female from a minority group, and key lessons learned in creating a culture of diversity, inclusion and true belonging.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this live session, a recorded version will be available tomorrow at 10:30 am (Paris time) | 4:30 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                    `,
                                start: returnDateTimeZone(
                                  "2020/10/07 05:00:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/07 05:00:00 PM",
                                  45
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 7 - 11:00 am (Miami time) | 5:00 pm (Paris time)
                        </span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="One People: Equality, Diversity and Inclusion at Work"
                          checked={
                            eventH.start.toString() ==
                              returnDateTimeZone("2020/10/08 10:30:00 AM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventH.start.toString() ==
                              returnDateTimeZone("2020/10/08 10:30:00 AM")
                              ? setEventH(DataStructure)
                              : setEventH({
                                title:
                                  "One People: Equality, Diversity and Inclusion at Work",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Keynote Session Tatiana Quaife, Director, Marketing Strategy at Disneyland will be sharing insights around Equality, Diversity and Inclusion at Work.

Diversity and Inclusion-focused initiatives are no longer just HR-led, feel-good exercises. In today’s modern marketplace, diversity has been embraced as a strategic business imperative. Join Tatiana as she shares her experience navigating corporate America as a female from a minority group, and key lessons learned in creating a culture of diversity, inclusion and true belonging.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this live session, a recorded version will be available tomorrow at 10:30 am (Paris time) | 4:30 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                                                      `,
                                start: returnDateTimeZone(
                                  "2020/10/08 10:30:00 AM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/08 10:30:00 AM",
                                  45
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 8 - 10:30 am (Paris time) | 4:30 pm (Hong Kong time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={45}
                  sessionNotes="Keynote Session - Inclusion: Tatiana Quaife"
                  speakers={
                    <li>
                      <span class="x_7b2817bf">
                        <strong>Tatiana Quaife, </strong>Director, Marketing
                        Strategy,<strong> Disneyland</strong>
                      </span>
                    </li>
                  }
                  defaultColor="#780096"
                />
                <SessionContent
                  title="Evolving Behaviors: What Lies Ahead for CX"
                  synopsis="For the first time in human history, we have five generations living, working, and quarantining simultaneously. So, how will the pandemic impact these five, coexisting generations when it comes to customer behavior and customer service? Backed by seven years of customer insights expertise, the Teleperformance CX Lab will share their latest industry analysis, and offer projections for what lies ahead in the “new normal”."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="Evolving Behaviors: What Lies Ahead for CX"
                          checked={
                            eventJ.start.toString() ==
                              returnDateTimeZone("2020/10/07 05:45:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventJ.start.toString() ==
                              returnDateTimeZone("2020/10/07 05:45:00 PM")
                              ? setEventJ(DataStructure)
                              : setEventJ({
                                title: "Evolving Behaviors: What Lies Ahead for CX",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Session Marina Netto Campos, Global Head of CX Lab & Strategic Sales Support at Teleperformance will speak about Evolving Behaviors: What Lies Ahead for CX.  

For the first time in human history, we have five generations living, working, and quarantining simultaneously. So, how will the pandemic impact these five, coexisting generations when it comes to customer behavior and customer service? Backed by seven years of customer insights expertise, the Teleperformance CX Lab will share their latest industry analysis, and offer projections for what lies ahead in the “new normal”. 

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this live session, you can watch the recorded version tomorrow at 11:15 am (Paris time) | 5:15 pm (Hong Kong time). Please visit the event lobby to access the content.  

Thank you and enjoy the session! 
                                    `,
                                start: returnDateTimeZone(
                                  "2020/10/07 05:45:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/07 05:45:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 7 - 11:45 pm (Miami time) | 5:45 pm (Paris time)
                        </span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="Evolving Behaviors: What Lies Ahead for CX"
                          checked={
                            eventJ.start.toString() ==
                              returnDateTimeZone("2020/10/08 11:15:00 AM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventJ.start.toString() ==
                              returnDateTimeZone("2020/10/08 11:15:00 AM")
                              ? setEventJ(DataStructure)
                              : setEventJ({
                                title: "Evolving Behaviors: What Lies Ahead for CX",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Session Marina Netto Campos, Global Head of CX Lab & Strategic Sales Support at Teleperformance will speak about Evolving Behaviors: What Lies Ahead for CX.  

For the first time in human history, we have five generations living, working, and quarantining simultaneously. So, how will the pandemic impact these five, coexisting generations when it comes to customer behavior and customer service? Backed by seven years of customer insights expertise, the Teleperformance CX Lab will share their latest industry analysis, and offer projections for what lies ahead in the “new normal”. 

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this live session, you can watch the recorded version tomorrow at 11:15 am (Paris time) | 5:15 pm (Hong Kong time). Please visit the event lobby to access the content.  

Thank you and enjoy the session! 
                                                                      `,
                                start: returnDateTimeZone(
                                  "2020/10/08 11:15:00 AM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/08 11:15:00 AM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 8 - 11:15 am (Paris time) | 5:15 pm (Hong Kong time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={30}
                  sessionNotes="Case Study –CX Research: CX Lab"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Marina Netto Campos, </strong>Global Head of
                          CX Lab &amp; Strategic Sales Support,
                          <strong> Teleperformance Group</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Monica Spigner (Moderator), </strong>Executive
                          Vice President, Business Transformation for English
                          World, <strong>Teleperformance</strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#780096"
                />
                <SessionContent
                  title="From Adapting to Adopting:Embracing Change for Long-Term CX Success"
                  synopsis="Widespread, global lockdowns caused demand for food delivery services to spike. Fortunately, Grubhub was ready. Already using an agile delivery model, they transitioned their full workforce to work-at-home in record time – while hiring hundreds more. With KPI improvements across the board, they’ve decided to remain remote. So, what’s next? Always innovating, Grubhub is already refining operations and optimizing their remote workforce with TP’s Cloud Campus model. Tricia and Jason will share lessons learned, best practices, and their plans for the future."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="From Adapting to Adopting:Embracing Change for Long-Term CX Success"
                          checked={
                            eventL.start.toString() ==
                              returnDateTimeZone("2020/10/07 06:15:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventL.start.toString() ==
                              returnDateTimeZone("2020/10/07 06:15:00 PM")
                              ? setEventL(DataStructure)
                              : setEventL({
                                title:
                                  "From Adapting to Adopting:Embracing Change for Long-Term CX Success",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Roundtable Tricia Thomsen, Director of Care at Grubhub and Jason Weaver, Director of Care at Grubhub will be sharing their expertise on Embracing Change for Long-Term CX Success. 

Widespread, global lockdowns caused demand for food delivery services to spike. Fortunately, Grubhub was ready. Already using an agile delivery model, they transitioned their full workforce to work-at-home in record time – while hiring hundreds more. With KPI improvements across the board, they’ve decided to remain remote. So, what’s next? Always innovating, Grubhub is already refining operations and optimizing their remote workforce with TP’s Cloud Campus model. Tricia and Jason will share lessons learned, best practices, and plans for the future.
  

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, a recorded version will be available tomorrow at 11:45 pm (Paris time) | 5:45 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                  `,
                                start: returnDateTimeZone(
                                  "2020/10/07 06:15:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/07 06:15:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 7 - 12:15 pm (Miami time) | 6:15 pm (Paris time)
                        </span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="From Adapting to Adopting:Embracing Change for Long-Term CX Success"
                          checked={
                            eventL.start.toString() ==
                              returnDateTimeZone("2020/10/08 11:45:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventL.start.toString() ==
                              returnDateTimeZone("2020/10/08 11:45:00 PM")
                              ? setEventL(DataStructure)
                              : setEventL({
                                title:
                                  "From Adapting to Adopting:Embracing Change for Long-Term CX Success",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Roundtable Tricia Thomsen, Director of Care at Grubhub and Jason Weaver, Director of Care at Grubhub will be sharing their expertise on Embracing Change for Long-Term CX Success. 

Widespread, global lockdowns caused demand for food delivery services to spike. Fortunately, Grubhub was ready. Already using an agile delivery model, they transitioned their full workforce to work-at-home in record time – while hiring hundreds more. With KPI improvements across the board, they’ve decided to remain remote. So, what’s next? Always innovating, Grubhub is already refining operations and optimizing their remote workforce with TP’s Cloud Campus model. Tricia and Jason will share lessons learned, best practices, and plans for the future.
  

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, a recorded version will be available tomorrow at 11:45 pm (Paris time) | 5:45 pm (Hong Kong time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                                                    `,
                                start: returnDateTimeZone(
                                  "2020/10/08 11:45:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/08 11:45:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 8 - 11:45 pm (Paris time) | 5:45 pm (Hong Kong time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={30}
                  sessionNotes="Roundtable – Waha: Grubhub"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Tricia Thomsen, </strong>Director of Care,
                          <strong> Grubhub</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Jason Weaver, </strong>Director of Care,
                          <strong>Grubhub</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Erin Broecker (Moderator),</strong> Director, Global Strategy,
                          <strong>Teleperformance</strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#780096"
                />
              </section>
              {/*Fim da section Equality, Diversity and Inclusion at Work*/}
              {/*Inicio da section Engage, Innovate, Compete*/}
              <section
                style={{
                  backgroundColor: "#ededed",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
              >
                <SessionContent
                  title="Engage, Innovate, Compete: FinTech’s CX Imperative Going Forward"
                  firstSession={true}
                  imgAStyle={24}
                  imgBStyle="7.5rem"
                  imgA="https://v.fastcdn.co/u/30a313ac/52551626-0-exceed.png"
                  imgB="https://v.fastcdn.co/u/30a313ac/52572051-0-onda04.png"
                  synopsis="In this session, we will look at the payments landscape, including how disruptive financial solutions are influencing and changing customer preferences and needs. We will analyze the customer/merchant journey to identify opportunities to digitize experiences. Our expert panel will discuss the future of financial services, including how automation will complement human experiences, and how to innovate as digital, P2P, and customer experience all begin to converge."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="Engage, Innovate, Compete: FinTech’s CX Imperative Going Forward"
                          checked={
                            eventM.start.toString() ==
                              returnDateTimeZone("2020/10/08 05:00:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventM.start.toString() ==
                              returnDateTimeZone("2020/10/08 05:00:00 PM")
                              ? setEventM(DataStructure)
                              : setEventM({
                                title:
                                  "Engage, Innovate, Compete: FinTech’s CX Imperative Going Forward",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Roundtable Matt Bochenek, Chief Operating Officer at Avant and Patrick Guckes, VP Strategic Initiatives at Synchrony Financial will speak about  FinTech’s CX Imperative Going Forward. 

In this session, we will look at the payments landscape, including how disruptive financial solutions are influencing and changing customer preferences and needs. We will analyze the customer/merchant journey to identify opportunities to digitize experiences. Our expert panel will discuss the future of financial services, including how automation will complement human experiences, and how to innovate as digital, P2P, and customer experience all begin to converge.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co


Thank you and enjoy the session! 
                                    `,
                                start: returnDateTimeZone(
                                  "2020/10/08 05:00:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/08 05:00:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 8 - 11:00 am (Miami time) | 5:00 pm (Paris time) | 11:00 pm (Hong Kong time)
                        </span>
                      </li>{" "}
                    </ul>
                  }
                  duration={30}
                  sessionNotes="Roundtable – Fintech: Avant, Synchrony Financial"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Matt Bochenek, </strong>Chief Operating
                          Officer,<strong> Avant</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Patrick Guckes, </strong>VP Strategic
                          Initiatives,<strong> Synchrony Financial</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Mamta Rodrigues (Moderator), </strong>
                          Divisional President BFSI,{" "}
                          <strong>Teleperformance</strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#00af9b"
                />
                <SessionContent
                  title="E-commerce Disrupted: Navigating in a Volatile, Uncertain, Complex, and Ambiguous (VUCA) World"
                  synopsis="Rising internet and mobile penetration have changed the way people communicate and do business. E-commerce, heavily placed on the internet and mobile phone revolution to fundamentally rework the way businesses reach their customers, continues to grow at a significant compound annual growth rate (CAGR). Doing business in a volatile, uncertain, complex, and ambiguous (VUCA) environment is challenging for any player. Making the right decisions in times of uncertainty demands new business strategies."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="E-commerce Disrupted: Navigating in a Volatile, Uncertain, Complex, and Ambiguous (VUCA) World"
                          checked={
                            eventN.start.toString() ==
                              returnDateTimeZone("2020/10/08 05:30:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventN.start.toString() ==
                              returnDateTimeZone("2020/10/08 05:30:00 PM")
                              ? setEventN(DataStructure)
                              : setEventN({
                                title: "E-commerce Disrupted: Navigating in a Volatile, Uncertain, Complex, and Ambiguous (VUCA) World",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Session Samdani Basha, Head of CX Design and Operations at Flipkart will be sharing his expertise on Navigating in a Volatile, Uncertain, Complex, and Ambiguous (VUCA) World. 

Rising internet and mobile penetration have changed the way people communicate and do business. E-Commerce, heavily relying on the internet and mobile phone revolution to fundamentally rework the way businesses reach their customers, continues to grow at a significant compound annual growth rate (CAGR). Doing business in a volatile, uncertain, complex, and ambiguous (VUCA) environment is challenging for any player. Making the right decisions in times of uncertainty demands new business strategies.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co


Thank you and enjoy the session! 
                                  `,
                                start: returnDateTimeZone(
                                  "2020/10/08 05:30:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/08 05:30:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 8 - 11:30 am (Miami time) | 5:30 pm (Paris time) | 11:30 pm (Hong Kong time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={30}
                  sessionNotes="Case Study - New Economy: Flipkart"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Samdani Basha, </strong>Head of CX Design and
                          Operations,<strong> Flipkart</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Bireswar Singh (Moderator), </strong>MD -
                          India Domestic, <strong>Teleperformance</strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#00af9b"
                />
                <SessionContent
                  title="Buyers Rule: Selling in a Hyper-Competitive Environment"
                  synopsis="The world of B2B and B2C sales has changed dramatically in recent months. Consumers have more options than ever before, digital sales are exploding, and face-to-face selling has halted. However, one thing that has not changed – a strong focus on RPC and LTV. So, we will share insights, lesson learned, and best practices for convincing today’s prospects to buy, increasing wallet share, and ensuring repeat business."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="Buyers Rule: Selling in a Hyper-Competitive Environment"
                          checked={
                            eventO.start.toString() ==
                              returnDateTimeZone("2020/10/08 06:00:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventO.start.toString() ==
                              returnDateTimeZone("2020/10/08 06:00:00 PM")
                              ? setEventO(DataStructure)
                              : setEventO({
                                title:
                                  "Buyers Rule: Selling in a Hyper-Competitive Environment",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Session Jeffrey Myers, Vice President and General Manager Customer Care at SiriusXM and Danilo Colnaghi, Western EMEA Lead Vendor Strategy & Operations at Google Customer Solutions will be sharing their expertise on Selling in a Hyper-Competitive Environment. 

The world of B2B and B2C sales has changed dramatically in recent months. Consumers have more options than ever before, digital sales are exploding, and face-to-face selling has halted. However, one thing that has not changed – a strong focus on RPC and LTV. So, we will share insights, lesson learned, and best practices for convincing today’s prospects to buy, increasing wallet share, and ensuring repeat business.

Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co


Thank you and enjoy the session! 
                                    `,
                                start: returnDateTimeZone(
                                  "2020/10/08 06:00:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/08 06:00:00 PM",
                                  30
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 8 - 12:00 pm (Miami time) | 6:00 pm (Paris time) | 12:00 am (Hong Kong time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={30}
                  sessionNotes="Case Study – Sales: SiriusXM, Google"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Jeffrey Myers, </strong>Vice President and General Manager Customer Care,
                          <strong> SiriusXM</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Danilo Colnaghi, </strong>Western EMEA Lead Vendor Strategy&amp;Operations,
                          <strong> Google Customer Solutions</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Michael Aronowitz, </strong>EVP Digital Sales &amp; Strategy,
                          <strong>Teleperformance</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Amit Shankardass (Moderator), </strong>EVP Market Engagement,
                          <strong>Teleperformance</strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#00af9b"
                />
                <SessionContent
                  title="Uncharted Territory: Understanding E-Commerce Challenges in an Unpredictable World"
                  synopsis="Hosting the largest online commerce and payments ecosystem in the region, with more than 166 million users and 4,000 searches per minute on their Marketplace, Mercado Libre is leading the Latin American market. In this session, Mercado Libre’s team of CX experts will discuss key, disruptive changes in the E-Commerce industry, and how to navigate the “new normal”."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="Uncharted Territory: Understanding E-Commerce Challenges in an Unpredictable World"
                          checked={
                            eventP.start.toString() ==
                              returnDateTimeZone("2020/10/08 06:30:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventP.start.toString() ==
                              returnDateTimeZone("2020/10/08 06:30:00 PM")
                              ? setEventP(DataStructure)
                              : setEventP({
                                title:
                                  "Uncharted Territory: Understanding E-Commerce Challenges in an Unpredictable World",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Roundtable Mercardo Libre executives Fernando Garcia, Customer Experience Director, Marcio Ap de Souza, Customer Experience Director and Gabriel Codesal, First Party Senior Director, will speak about Understanding E-Commerce Challenges in an Unpredictable World. 

Hosting the largest online commerce and payments ecosystem in the region, with more than 166 million users and 4,000 searches per minute on their Marketplace, Mercado Libre is leading the Latin American market. In this session, Mercado Libre’s team of CX experts will discuss key, disruptive changes in the E-Commerce industry, and how to navigate the “new normal”.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

Thank you and enjoy the session! 
                                    `,
                                start: returnDateTimeZone(
                                  "2020/10/08 06:30:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/08 06:30:00 PM",
                                  40
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 8 - 12:30 pm (Miami time) | 6:30 pm (Paris time) | 12:30 am (Hong Kong time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={40}
                  sessionNotes="Case Study – E-Commerce: Mercado Libre"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Fernando Garcia, </strong>Customer Experience
                          Director<strong>, Mercado Libre</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Marcio Ap de Souza, </strong>Customer Experience
                          Director,<strong> Mercado Libre</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Gabriel Codesal, </strong>First Party Senior
                          Director,<strong> Mercado Libre</strong>
                        </span>
                      </li>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Gustavo Mir (Moderator), </strong>CCO Ibero
                          Latam, <strong>Teleperformance</strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#00af9b"
                />
              </section>
              {/*Fim da section*/}
              {/*Inicio da section*/}
              <section
                style={{
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
              >
                <SessionContent
                  title="Getting Ready for the ‘Day After Tomorrow’"
                  firstSession={true}
                  imgBStyle="8.5rem"
                  imgAStyle={15}
                  imgA="https://v.fastcdn.co/u/30a313ac/53117106-0-discover-the.png"
                  imgB="https://v.fastcdn.co/u/30a313ac/52572056-0-onda05.png"
                  synopsis="As a company, how can you help consumers’ dreams come true and eliminate the obstacles in their day-to-day lives? Today, companies have the opportunity to help customers overcome concrete, real-world problems such as climate, healthcare, government issues, and mobility challenges. To succeed in the next decade, it will become more critical than ever for companies to play an active role in consumers’ lives by helping to solve tangible social issues while striking the right balance between automated and human interactions."
                  times={
                    <ul class="x_aa37d1ab x_8b9ce48e">
                      <li>
                        <input
                          type="radio"
                          name="Getting Ready for the ‘Day After Tomorrow’"
                          checked={
                            eventQ.start.toString() ==
                              returnDateTimeZone("2020/10/09 10:30:00 AM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventQ.start.toString() ==
                              returnDateTimeZone("2020/10/09 10:30:00 AM")
                              ? setEventQ(DataStructure)
                              : setEventQ({
                                title:
                                  "Getting Ready for the ‘Day After Tomorrow’",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Keynote Session Steven Van Belleghem will be sharing insights around Getting Ready for the ‘Day After Tomorrow’.

As a company, how can you help consumers’ dreams come true and eliminate the obstacles in their day-to-day lives? Today, companies have the opportunity to help customers overcome concrete, real-world problems such as climate, healthcare, government issues, and mobility challenges. To succeed in the next decade, it will become more critical than ever for companies to play an active role in consumers’ lives by helping to solve tangible social issues while striking the right balance between automated and human interactions.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co

If you can’t attend this session, a recorded version will be available today at 11:00 am (Miami time) | 5:00 pm (Paris time). Please visit the event lobby to access the content.  


Thank you and enjoy the session! 
                                    `,
                                start: returnDateTimeZone(
                                  "2020/10/09 10:30:00 AM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/09 10:30:00 AM",
                                  45
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 9 - 10:30 am (Paris time) | 4:30 pm (Hong Kong time)
                        </span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="Getting Ready for the ‘Day After Tomorrow’"
                          checked={
                            eventQ.start.toString() ==
                              returnDateTimeZone("2020/10/09 05:00:00 PM")
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            eventQ.start.toString() ==
                              returnDateTimeZone("2020/10/09 05:00:00 PM")
                              ? setEventQ(DataStructure)
                              : setEventQ({
                                title:
                                  "Getting Ready for the ‘Day After Tomorrow’",
                                description: `Thank you for registering for the Leader Insights Forum! 

At this Keynote Session Steven Van Belleghem will be sharing insights around Getting Ready for the ‘Day After Tomorrow’.

As a company, how can you help consumers’ dreams come true and eliminate the obstacles in their day-to-day lives? Today, companies have the opportunity to help customers overcome concrete, real-world problems such as climate, healthcare, government issues, and mobility challenges. To succeed in the next decade, it will become more critical than ever for companies to play an active role in consumers’ lives by helping to solve tangible social issues while striking the right balance between automated and human interactions.


Use the link below and the email you’ve registered with to enter the event lobby up to 15 minutes before the start.
EVENT LOBBY: https://welcome.leaderinsights.co


Thank you and enjoy the session! 
                                                                      `,
                                start: returnDateTimeZone(
                                  "2020/10/09 05:00:00 PM"
                                ),
                                /*timeZonestart: returnDateTimeZone("Europe/Berlin",*/
                                end: returnDateTimeZone(
                                  "2020/10/09 05:00:00 PM",
                                  45
                                ),
                                startInputType: "utc",
                                endInputType: "utc",
                              })
                          }
                        />
                        <span class="x_e1c17884">
                          October 9 - 11:00 am (Miami time) | 5:00 pm (Paris time)
                        </span>
                      </li>
                    </ul>
                  }
                  duration={45}
                  sessionNotes="Keynote Session - Innovation: Steven Van Belleghem"
                  speakers={
                    <React.Fragment>
                      <li>
                        <span class="x_7b2817bf">
                          <strong>Steven Van Belleghem, </strong>Thought Leader on the <br />Transformation of Customer Relationships
                          <strong> </strong>
                        </span>
                      </li>
                    </React.Fragment>
                  }
                  defaultColor="#3047b0"
                />
              </section>
              <section
                style={{
                  marginBottom: 100,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  class="widget "
                  id="element-1469"
                  style={{ textAlign: "center" }}
                >
                  {eventA.title ||
                    eventB.title ||
                    eventC.title ||
                    eventD.title ||
                    eventE.title ||
                    eventF.title ||
                    eventG.title ||
                    eventH.title ||
                    eventI.title ||
                    eventJ.title ||
                    eventL.title ||
                    eventM.title ||
                    eventN.title ||
                    eventO.title ||
                    eventP.title ||
                    eventQ.title ? (
                    <React.Fragment>
                      <span style={{ fontWeight: "600", color: "#F44336" }}>
                        <a
                          id="downloadICS"
                          href=""
                        >
                          {icsDone && isSafari && "Download ICS File"}
                        </a>
                        <br />
                      </span>
                      {!icsDone && isSafari && (
                        <span
                          id="link-ud4ukevr4g"
                          class="onpage-link btn    item-block"
                          data-at="button"
                          data-link-ud4ukevr4g=""
                          onClick={() => createMultipleEvents()}
                        >
                          Add to Calendar
                        </span>
                      )}
                      {!icsDone && !isSafari && (
                        <span
                          id="link-ud4ukevr4g"
                          class="onpage-link btn    item-block"
                          data-at="button"
                          data-link-ud4ukevr4g=""
                          onClick={() => createUnitEvents()}
                        >
                          Add to Calendar
                        </span>
                      )}
                    </React.Fragment>
                  ) : (
                    <span style={{ fontWeight: "600", color: "#F44336" }}>
                      Select at least one schedule!
                    </span>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
  };
}

export default connect(mapStateToProps)(PageCustom);
