export const SET_NOTIFICATION = 'SET_NOTIFICATION';
const notificationAction = notification => ({
    type: SET_NOTIFICATION,
    notification,
});
export function setNotification(notification) {
    
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(notificationAction(notification));
            resolve(true);
        });
    };
}