import { SET_EVENT } from '../actions';
import * as eventModel from '../../models/eventModel';

const INITIAL_STATE = eventModel.createEventModel();

export default function eventReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_EVENT:
            return { ...state, ...action.event };
        default:
            return state;
    }
}
