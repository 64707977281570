import React from "react";
import Avatar from '@material-ui/core/Avatar';
import { getDic } from "../../../../assets/i18n/dictionary";
import { store } from '../../../../store';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import 'moment/locale/es';

const convertToTime = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp);
    var months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate() > 9 ? a.getDate() : "0" + a.getDate();
    var hour = a.getHours() > 9 ? a.getHours() : "0" + a.getHours();
    var min = a.getMinutes() > 9 ? a.getMinutes() : "0" + a.getMinutes();
    var sec = a.getSeconds() > 9 ? a.getSeconds() : "0" + a.getSeconds();
    var dateNow = new Date();

    var time1 = `${getDic("postado")} ${getDic("hoje")} ${getDic("as")} ${hour}:${min}`;
    const language = store.getState().lang.lang === "sp" ? "es" : store.getState().lang.lang;
    var _date = `${moment(a).locale(language).format('dddd, LL ')} ${getDic("as")} ${moment(a).locale(language).format(' LT')}`;
    var time2 = `${getDic("postado")} ${_date}`;
    return UNIX_timestamp
        ? dateNow.getDate() == a.getDate()
            ? time1
            : time2
        : "";
};

const useStyles = makeStyles((theme) => ({
    rowRoot: {
        display: "flex",
        flexDirection: "row",
        background: "#e9ebec",
        padding: 10,
        borderRadius: 19,
        marginTop: 10
    },
    rowItems: {
        display: "flex",
        flexDirection: "column"
    },
    textName: {
        fontWeight: 500,
        fontSize: 14,
        color: "#3e3d3d"
    },
    textDate: {
        fontWeight: 100,
        fontSize: 12
    },
    textComment: {
        fontWeight: 300,
        fontSize: 14,
        color: "#1d1d1d",
        marginTop: 9
    },
    avatarSizeC: {
        width: 30,
        height: 30
    }
}));

const CommentElement = props => {
    const classes = useStyles();
    const [deleteOpen, setdeleteOpen] = React.useState(false);
    const popUpMenu = React.useRef(null);
    const [postAlreadyDeleted, setPostAlreadyDeleted] = React.useState(false);
    const handleClickOutside = event => {
        if (popUpMenu && !popUpMenu.current.contains(event.target)) {
            setdeleteOpen(false);
        }
    }
    const deletePost = _d => {
        props.deleteComment({
            commentdId: props._comment.id
        }).then(() => {
            setPostAlreadyDeleted(true);
        });
        //console.log("delete");
    }
    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        })
    })
    return <React.Fragment>
        <div className={classes.rowRoot} style={{ display: postAlreadyDeleted ? 'none' : 'flex' }}>
            <div className={classes.rowItems}>
                <Avatar
                    className={classes.avatarSizeC}
                    aria-label="recipe"
                    style={{ marginRight: 10 }}
                    src={props._comment.guest.urlImage}
                >
                    {props._comment.guest.name[0].toUpperCase()}
                </Avatar>
            </div>
            <div className={classes.rowItems} style={{ width: "100%" }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: -28,
                    position: 'relative'
                }}>
                    <div style={{ width: "100%" }}>
                        <span className={classes.textLabel}>
                            {props._comment.guest.name}
                        </span>
                    </div>
                    {
                        props.guestId == props._comment.guest.id ?
                            <IconButton aria-label="settings" onClick={() => setdeleteOpen(!deleteOpen)}>
                                <MoreVertIcon />
                            </IconButton>
                            :
                            <IconButton aria-label="settings" disabled>
                                <MoreVertIcon style={{ color: 'transparent' }} />
                            </IconButton>
                    }
                    <div ref={popUpMenu}>
                        {deleteOpen && <div className="baloon-card"> <span onClick={() => deletePost()}>{getDic("deletar")}</span> </div>}
                    </div>
                </div>
                <span className={classes.textDate}>
                    {convertToTime(props._comment.createdDate)}
                </span>
                <span className={classes.textComment}>
                    {props._comment.text}
                </span>
            </div>
        </div>
    </React.Fragment>
}
export default CommentElement;
