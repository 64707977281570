import React, { useRef } from "react";
import { connect } from "react-redux";
import { getDic } from "../assets/i18n/dictionary";
import { insertNewAccess, getSystemActions } from "../services/accessService";
import { getOpenedChatsByUserApi } from "../services/chatPersonalService";
import { getGuestList } from "../services/guestService";
import { setPrivateChatControl } from '../store/actions';

import {
  MainContainer,
  MenuButton,
  BackButton,

  ChatModalFloated,
  ChatContext,
} from "../components";

const ChatHistoryPage = (props) => {
  const [guestIndexRequest, setNextRequest] = React.useState(1);
  const [privateGuest, setPrivateGuests] = React.useState([]);
  const [guestLimit, setGuestLimit] = React.useState(60);
  const [searchText, setSearchText] = React.useState("");
  const [privateChat, setPrivateChat] = React.useState([]);
  const [tempPrivateChat, setTempPrivateChat] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
  const [searchTermBox, setNewSearchTermBox] = React.useState("");
  const [userNewMwssage, setUserNewMwssage] = React.useState("");
  // const [isScrolling, setIsScrolling] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [loadingMoreMessages, setLoadingMoreMessages] = React.useState(false);

  const [pageIndex, setPageIndex] = React.useState(0);
  const [totalPageIndex, setTotalPageIndex] = React.useState(0);
  const [noMoreOldMessages, setNoMoreOldMessages] = React.useState(false);
  const [
    endInfiniteScroolerSearch,
    setEndInfiniteScroolerSearch,
  ] = React.useState(false);

  //Controle de emoji
  const [openModalEmoji, setOpenModalEmoji] = React.useState(false);

  const [currenGuestID, setcurrenGuestID] = React.useState(0);
  const [currenChatID, setcurrenChatID] = React.useState(0);
  const [oldChatID, setOldChatID] = React.useState(0);

  const [chatObject, setNewChatObject] = React.useState({
    id: 0,
    name: "",
    avatar:
      "https://s3.amazonaws.com/bucket.aws.public/FotoPerfilInteegra/DEFAULT.png",
    lastActive: "",
  });
  const [areaMessages, setAreaMessages] = React.useState([]);
  const isModal = props.isModal ? true : false;

  /*
    Recupera a lista de chats que eu(usuario) iniciei.
  */
  const getAllPrivateChats = (e) => {
    let privates = [];
    if (
      privateChat.filter(
        (i) => i.guests[1].guestId === e.newChat.guests[1].guestId
      ).length < 1
    ) {
      setPrivateChat(privates.concat(privateChat, e.newChat));
      setcurrenGuestID(e.newChat.guests[1].guestHasChatId);
    } else {
      setcurrenGuestID(
        privateChat.filter((i) => i.guestName === e.newChat.guestName)[0]
          .guests[1].guestHasChatId
      );
    }
  };

  /*const getCurrentMessages = (chatId, index, mode) => {
    getMessagesByChatIdApi(chatId, index).then((result) => {
      console.log(
        `todas mensagens do atual chat ${chatId} com index: ${index}`,
        result
      );
      let chats = [];
      setAreaMessages(result.messages);
    });
  };*/

  /*
    Recupera a lista de participantes da reunião
  */
  const getPrivateGuests = (search_term) => {
    getGuestList(search_term ? 1 : guestIndexRequest, searchText).then(
      (res) => {
        if (res.guestList.length == 0) {
          setEndInfiniteScroolerSearch(false);
        } else {
          setGuestLimit(res.totalPages * res.guestList.length);
          let concatAll = [];
          if (search_term)
            setPrivateGuests(
              res.guestList.filter((e) => e.id != props.user.id)
            );
          else setPrivateGuests(concatAll.concat(privateGuest, res.guestList));
          setNextRequest(guestIndexRequest + 1);
          setEndInfiniteScroolerSearch(true);
        }
      }
    );
  };
  /*
    Minimiza o menu lateral de adicionar participante
  */
  const cleanPagination = () => {
    setNextRequest(1);
    setPrivateGuests([]);
    getPrivateGuests(true);
  };

  React.useEffect(() => {
    //atualiza lista de guests

    if (!isModal) {
      insertNewAccess(props.match.url, "", getSystemActions().accessPage);
    }
    let helpButton = document.getElementById("launcher");
    if (helpButton) {
      helpButton.style.display = "none";
    }

    /*getPrivate();

    //Remove sinal de mensagem nova
    var _chatControl = props.chatPrivateControl;
    _chatControl.viewed = true;
    props.setPrivateChatControl(_chatControl);
    */
    return () => {
      if (!isModal) {
        insertNewAccess(props.match.url, "", getSystemActions().exitPage);
      }
      if (helpButton) {
        helpButton.style.display = "block";
      }
    };
  }, []);
  return (
    <ChatContext.Provider
      value={{
        listUsers: privateGuest,
        getPrivateGuests: getPrivateGuests,
        cleanPagination: cleanPagination,
        guestLimit: guestLimit,
        setSearchText: setSearchText,
        getAllPrivateChats: getAllPrivateChats,
        privateChat: privateChat,
        setPrivateChat: setPrivateChat,
        currenGuestID: currenGuestID,
        setcurrenGuestID: setcurrenGuestID,
        areaMessages: areaMessages,
        setAreaMessages: setAreaMessages,
        setMessages: setMessages,
        messages: messages,
        setUserNewMwssage: setUserNewMwssage,
        loadingMoreMessages: loadingMoreMessages,
        setNoMoreOldMessages: setNoMoreOldMessages,
        noMoreOldMessages: noMoreOldMessages,
        currenChatID: currenChatID,
        setcurrenChatID: setcurrenChatID,
        currenGuestID: currenGuestID,
        openModalEmoji: openModalEmoji,
        setOpenModalEmoji: setOpenModalEmoji,
        sending: sending,
        setSending: setSending,
        pageIndex: pageIndex,
        setPageIndex: setPageIndex,
        totalPageIndex: totalPageIndex,
        setTotalPageIndex: setTotalPageIndex,
        setLoadingMoreMessages: setLoadingMoreMessages,
        chatObject: chatObject,
        setNewChatObject: setNewChatObject,
        endInfiniteScroolerSearch: endInfiniteScroolerSearch,
        setNewSearchTermBox: setNewSearchTermBox,
        searchTermBox: searchTermBox,
        tempPrivateChat: tempPrivateChat,
        setTempPrivateChat: setTempPrivateChat,
      }}
    >
      <div style={{ overflow: "hidden", height: "100vh" }}>

        <div
          style={{
            height: "8vh",
            backgroundColor: "#f7f7f7",
            borderBottom: "1px solid #e4e4e4",
          }}
        >
          {!isModal && (
            <>
              <MenuButton history={props.history} />
              <BackButton history={props.history} color="#212121">
                {getDic("voltar").toUpperCase()}
              </BackButton>

            </>
          )}
        </div>

        <div style={{ height: "92vh" }}>
          <MainContainer history={props.history} />
        </div>
      </div>
    </ChatContext.Provider>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
    chatPrivateControl: state.chatPrivateControl,
  };
}

const mapDispatchToProps = {
  setPrivateChatControl
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatHistoryPage);
