import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";

const InfiniteScrollProp = (props) => {

    const {
        children,
        ...rest
    } = props;

    return (
        <InfiniteScroll
            {...rest}
        >
            {children}
        </InfiniteScroll>
    )
}

export default InfiniteScrollProp;
