import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CardReports } from "./CardReports";
import { connect } from "react-redux";
import EventReportsContext from "./EventReportsContext";
import "../../scss/BootStrap/index.scss";

function ReportsContainer(props) {
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    console.log(props);
  }, []);

  return (
    <div className="parent" style={{ width: "100%" }}>
      <div
        className="container-fluid"
        style={{ backgroundColor: "white", margin: 10, borderRadius: 7 }}
      >
        <div className="my-header" style={{ marginTop: 100 }}></div>
        <div>
          <div className="row " style={{ padding: 10 }}>
            <CardReports ReportName="Primeiro Acesso" />
            <CardReports ReportName="Acessos Stands" />
            <CardReports ReportName="Acessos Programações" />
            <CardReports ReportName="Acessos Configurações" />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(ReportsContainer);
