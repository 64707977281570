import React, { useRef, useState, useEffect } from "react";
import { getDic } from '../../assets/i18n/dictionary';
import { connect } from "react-redux";
import 'firebase/firestore';
import 'firebase/auth';
import { firestore } from "../../utils/firebase";
import { useCollectionData, useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import {
    FormColumn,
    FormRow,
    MenuButton,
    TextMedium,
    BackButton,

    GridRow,
    GridCell,
} from '../../components';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import AllChats from "./AllChats";
import AprovedChats from "./AprovedChats";
import ReprovedChats from "./ReprovedChats";


const ModeratorV2 = props => {
    let chatList = [];
    props.config.forEach(element => {
        if (element.url && element.url.length > 1) {
            element.url.forEach(url => {
                if (url.indexOf("idconfigChat") > -1) {

                    chatList.push({
                        ...element,
                        id: url.replace("idconfigChat:", "")
                    })
                }
                if (url === "CHAT") {
                    chatList.push({
                        ...element
                    })
                }
            })
        }
    });
    const [syncRealtime, setSyncRealtime] = React.useState(true);
    const [currentSelected, setCurrentSelected] = React.useState(chatList[0].id)

    const [queryType, setQueryType] = React.useState(0)
    const [paginationIndex, setPaginationIndex] = React.useState(100);

    const loadOldMsg = o => {
        setPaginationIndex(paginationIndex + 100)
    }

    React.useEffect(() => {
    })

    return (
        <React.Fragment>
            <MenuButton history={props.history} />
            <BackButton
                history={props.history}
                color="#212121"
            >
                {getDic("voltar").toUpperCase()}
            </BackButton>

            <table style={{ width: '100%' }}>
                <tr>
                    <FormRow
                        minHeight="50px"
                        height="7vh"
                        align="center"
                        background="#e8eced"
                        margin="0px"
                    />
                    <FormRow
                        minHeight="60px"
                        height="9vh"
                        align="center"
                        background="#e8eced"
                        margin="0px"
                        paddingTop="5px"
                        paddingBottom="5px"
                    >
                        <div style={{ paddingRight: "10px" }}>
                            <FormControl
                                variant="outlined"
                                style={styles.field}
                            >
                                <InputLabel>Programação</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={currentSelected}
                                    onChange={event => { setCurrentSelected(event.target.value) }}
                                >
                                    {chatList.map((e, index) => <MenuItem key={index} value={e.id}>{e.description ? `${e.id} - ${e.description}` : `${e.id} - `}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
                            <FormControl
                                variant="outlined"
                                style={styles.field}
                            >
                                <InputLabel>{getDic("aprovado") + "/" + getDic("desaprovado")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={queryType}
                                    onChange={event => { setQueryType(event.target.value) }}
                                >
                                    <MenuItem key={1} value={0}>Todos</MenuItem>
                                    <MenuItem key={2} value={1}>Aprovados</MenuItem>
                                    <MenuItem key={3} value={2}>Desaprovados</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </FormRow>
                </tr>
                <tr>
                    <FormColumn align="center" background="#e8eced" margin="0px">
                        <div
                            id="messages"
                            style={{
                                height: "68vh",
                                backgroundColor: "transparent",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 80
                            }}
                        >
                            <div
                                id={"chatComponent"}
                                style={{
                                    padding: "5px",
                                    backgroundColor: "transparent",
                                    height: "100%",
                                    overflowX: "auto",
                                    overflowY: "auto"
                                }}
                            >
                                <GridRow backgroundColor="#d9d9d9">
                                    <GridCell width="5%" border top first >

                                    </GridCell>
                                    <GridCell width="11%" border top>
                                        <TextMedium>{getDic("data").toUpperCase()}</TextMedium>
                                    </GridCell>
                                    <GridCell width="15%" border top>
                                        <TextMedium>{getDic("programacao").toUpperCase()}</TextMedium>
                                    </GridCell>
                                    <GridCell width="20%" border top>
                                        <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                                    </GridCell>
                                    <GridCell width="40%" border top>
                                        <TextMedium>{getDic("mensagem").toUpperCase()}</TextMedium>
                                    </GridCell>
                                    <GridCell width="10%" border top>
                                        <TextMedium>STATUS</TextMedium>
                                    </GridCell>
                                </GridRow>

                                {queryType == 0 && <AllChats
                                    eventId={props.event.id}
                                    currentSelected={currentSelected}
                                    paginationIndex={paginationIndex} />}

                                {queryType == 1 && <AprovedChats
                                    eventId={props.event.id}
                                    currentSelected={currentSelected}
                                    paginationIndex={paginationIndex} />}

                                {queryType == 2 && <ReprovedChats
                                    eventId={props.event.id}
                                    currentSelected={currentSelected}
                                    paginationIndex={paginationIndex} />}

                                <div style={{ padding: 10 }}>
                                    <span
                                        onClick={() => loadOldMsg()}
                                        style={{
                                            fontSize: 16,
                                            background: "#212121",
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            borderRadius: 10,
                                            color: "white",
                                            cursor: "pointer"
                                        }}>mais...</span>
                                </div>
                            </div>
                        </div>
                    </FormColumn>
                </tr>
            </table>
        </React.Fragment>)
}

const styles = {
    field: {
        fontFamily: "verdana",
        fontSize: "25px",
        minWidth: 160,
        maxWidth: 160,
        background: "white",
        textAlign: "left",
        borderRadius: 5,
    },
    btn_red: {
        color: "white",
        background: "#f44336",
        padding: 5,
        borderRadius: 6,
        border: 0,
        fontSize: 13,
        cursor: "pointer",
        display: "block",
        textAlign: "center",
        position: "relative",
        paddingRight: 22,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: 57,
        margin: 3
    },
    btn_green: {
        color: "white",
        background: "#4caf50",
        padding: 5,
        borderRadius: 6,
        border: 0,
        fontSize: 13,
        cursor: "pointer",
        display: "block",
        textAlign: "center",
        position: "relative",
        paddingRight: 22,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: 57,
        margin: 3
    }
};

function mapStateToProps(state) {
    return {
        event: state.event,
        user: state.user,
        config: state.config
    };
}

export default connect(mapStateToProps, null)(ModeratorV2);
