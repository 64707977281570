import React from 'react'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

const Table = (props) => {
  const {
    columns,
    data,
    ...rest
  } = props;

  return (
    <ReactTable
      columns={columns}
      data={data}
      {...rest}
    />
  )
}

export default Table;
