import React from "react";
import { createMulticonfigModel } from '../models/configModel';
import { getDic } from '../assets/i18n/dictionary';
import { createFormControlModel } from '../models/formControlModel';

//Imports de components
import {
    Modal,
    Container,
    FormRow,
    FormColumn,
    SelectList,
    InputFieldOutlined,
    Button
} from "../components";

const ModalConcatUrl = (props) => {
    const {
        open = false,
        onClose,
        urlString = "",
        isSchedule = false,
    } = props;

    const [state, setState] = React.useState(true);

    const multiConfigObject = createMulticonfigModel();
    const [urlObject, setUrlObject] = React.useState({
        multilanguage: false,
        urlDefault: null,
        urlPt: "",
        urlEn: "",
        urlSp: "",
        hasChat: false,
        hasQuestions: false,
        chatQuestionsOrder: 0, // 0 coloca o chat antes das perguntas e 1 inverte a ordem
        fullScreen: false,
        SET_GIF_MAP: "",
        SET_IFRAME_MAP: "",
        BACKGROUND_CHANGE: "",
        ...multiConfigObject
    });

    const [newUrlForm, setNewUrlForm] = React.useState([]);

    // Método para retornar parametro ao elemento pai
    const localOnClose = (url) => {
        if (url) {
            onClose(url);
        }
        else {
            onClose(urlString);
        }
    };

    React.useEffect(() => {
        getPageData();
        return () => {
        };
    }, []);

    const getPageData = () => {
        const _urlObj = convertUrlStringToObject(urlString ? urlString : "");
        setUrlObject(_urlObj);
        var _form = convertUrlObjectToForm(_urlObj);
        setNewUrlForm(_form);
    }

    const getBoolenList = () => {
        const _obj = [
            {
                text: getDic("nao"),
                value: false
            },
            {
                text: getDic("sim"),
                value: true
            },
        ]
        return _obj;
    }

    const getChatOrderList = () => {
        const _obj = [
            {
                text: `Chat/${getDic("perguntas")}`,
                value: 0
            },
            {
                text: `${getDic("perguntas")}/Chat`,
                value: 1
            },
        ]
        return _obj;
    }

    const getUrlDefaultList = () => {
        const _obj = [
            {
                text: getDic("selecione"),
                value: null
            },
            {
                text: getDic("portugues"),
                value: "pt"
            },
            {
                text: getDic("ingles"),
                value: "en"
            },
            {
                text: getDic("espanhol"),
                value: "sp"
            },
        ]
        return _obj;
    }

    const convertUrlStringToObject = (url) => {
        var _obj = urlObject;
        var _urlArray = url.split(";");

        _obj.multilanguage = url.includes("pt{") || url.includes("en{") || url.includes("sp{") ? true : false;

        if (_obj.multilanguage) {
            if (url.includes("pt{")) {
                var _url1 = url.split("pt{");
                var _url2 = _url1[1].split("}");
                _obj.urlPt = _url2[0];
            }
            if (url.includes("en{")) {
                var _url1 = url.split("en{");
                var _url2 = _url1[1].split("}");
                _obj.urlEn = _url2[0];
            }
            if (url.includes("sp{")) {
                var _url1 = url.split("sp{");
                var _url2 = _url1[1].split("}");
                _obj.urlSp = _url2[0];
            }
            if (url.includes("default{")) {
                var _url1 = url.split("default{");
                var _url2 = _url1[1].split("}");
                _obj.urlDefault = _url2[0];
            }
        }
        else {
            _obj.urlPt = _urlArray[0] ? _urlArray[0] : "";
        }

        _obj.hasChat = url.includes("CHAT") ? true : false;
        _obj.hasQuestions = url.includes("QUESTIONS") ? true : false;
        _obj.chatQuestionsOrder = _urlArray[1] === "CHAT" ? 0 : 1; // 0 coloca o chat antes das perguntas e 1 inverte a ordem
        _obj.fullScreen = url.includes(";FULL") ? true : false;

        if (url.includes("SET_GIF_MAP{")) {
            var _urlGif1 = url.split("SET_GIF_MAP{");
            var _urlGif2 = _urlGif1[1].split("}");
            _obj.SET_GIF_MAP = _urlGif2[0];
        }
        if (url.includes("SET_IFRAME_MAP{")) {
            var _urlGif1 = url.split("SET_IFRAME_MAP{");
            var _urlGif2 = _urlGif1[1].split("}");
            _obj.SET_IFRAME_MAP = _urlGif2[0];
        }
        if (url.includes("BACKGROUND_CHANGE{")) {
            var _urlGif1 = url.split("BACKGROUND_CHANGE{");
            var _urlGif2 = _urlGif1[1].split("}");
            _obj.BACKGROUND_CHANGE = _urlGif2[0];
        }
        if (url && url !== "") {
            const _incame = url.split(",style{");

            if (_incame[1]) {
                var _array = _incame[1]
                    .replace(/style{/g, "")
                    .replace(/}/g, "")
                    .split(",");

                _array.forEach((conf) => {
                    var _conf = conf.split(":");
                    const _value = _conf[1] === "true" ? true : _conf[1] === "false" ? false : _conf[1];
                    _obj[_conf[0]] = _value;
                });
            }
        }

        return _obj;
    }

    const convertUrlObjectToString = (objectUrl) => {
        var _finalString = "";

        // Monta url principal
        if (objectUrl.multilanguage) {
            if (objectUrl.urlPt && objectUrl.urlPt !== "") {
                _finalString += `pt{${objectUrl.urlPt}}`;
            }
            if (objectUrl.urlEn && objectUrl.urlEn !== "") {
                _finalString += `${_finalString !== "" ? "," : ""}en{${objectUrl.urlEn}}`;
            }
            if (objectUrl.urlSp && objectUrl.urlSp !== "") {
                _finalString += `${_finalString !== "" ? "," : ""}sp{${objectUrl.urlSp}}`;
            }
            if (objectUrl.urlDefault && objectUrl.urlDefault !== "") {
                _finalString += `${_finalString !== "" ? "," : ""}default{${objectUrl.urlDefault}}`;
            }
        }
        else {
            _finalString += objectUrl.urlPt;
        }

        var _strStyle = "";
        //Adiciona style
        if (objectUrl.iframeBackgroundColor && objectUrl.iframeBackgroundColor !== "") {
            _strStyle += `${_strStyle !== "" ? "," : ""}iframeBackgroundColor:${objectUrl.iframeBackgroundColor}`
        }
        if (objectUrl.iframeWidth && objectUrl.iframeWidth !== "") {
            _strStyle += `${_strStyle !== "" ? "," : ""}iframeWidth:${objectUrl.iframeWidth}`
        }
        if (objectUrl.interactBackgroundColor && objectUrl.interactBackgroundColor !== "") {
            _strStyle += `${_strStyle !== "" ? "," : ""}interactBackgroundColor:${objectUrl.interactBackgroundColor}`
        }
        if (objectUrl.interactWidth && objectUrl.interactWidth !== "") {
            _strStyle += `${_strStyle !== "" ? "," : ""}interactWidth:${objectUrl.interactWidth}`
        }
        if (objectUrl.calendar && objectUrl.calendar !== "") {
            _strStyle += `${_strStyle !== "" ? "," : ""}calendar:${objectUrl.calendar ? "true" : "false"}`
        }
        if (objectUrl.category && objectUrl.category !== "") {
            _strStyle += `${_strStyle !== "" ? "," : ""}category:${objectUrl.category}`
        }
        if (objectUrl.backgroundButton && objectUrl.backgroundButton !== "") {
            _strStyle += `${_strStyle !== "" ? "," : ""}backgroundButton:${objectUrl.backgroundButton}`
        }
        if (objectUrl.textColorButton && objectUrl.textColorButton !== "") {
            _strStyle += `${_strStyle !== "" ? "," : ""}textColorButton:${objectUrl.textColorButton}`
        }
        if (objectUrl.openWithPage && objectUrl.openWithPage !== "") {
            _strStyle += `${_strStyle !== "" ? "," : ""}openWithPage:${objectUrl.openWithPage ? "true" : "false"}`
        }

        _finalString += _strStyle !== "" ? `,style{${_strStyle}}` : "";

        //Verifica se possui chat e perguntas e qual a ordem
        if (objectUrl.hasChat || objectUrl.hasQuestions) {
            // 0 coloca o chat antes das perguntas e 1 inverte a ordem
            if (objectUrl.chatQuestionsOrder === 1) {
                _finalString += `${objectUrl.hasQuestions && ";QUESTIONS"}${objectUrl.hasChat && ";CHAT"}`;
            }
            else {
                _finalString += `${objectUrl.hasChat && ";CHAT"}${objectUrl.hasQuestions && ";QUESTIONS"}`;
            }
        }

        //Verifica se deve inserir gif, imagem ou iframe
        if (objectUrl.SET_GIF_MAP && objectUrl.SET_GIF_MAP !== "") {
            _finalString += `;SET_IFRAME_MAP{${objectUrl.SET_GIF_MAP}`;
        }
        if (objectUrl.SET_IFRAME_MAP && objectUrl.SET_IFRAME_MAP !== "") {
            _finalString += `;SET_IFRAME_MAP{${objectUrl.SET_IFRAME_MAP}`;
        }
        if (objectUrl.BACKGROUND_CHANGE && objectUrl.BACKGROUND_CHANGE !== "") {
            _finalString += `;BACKGROUND_CHANGE{${objectUrl.BACKGROUND_CHANGE}`;
        }

        //Aplicar fullscreen caso tenha
        if (objectUrl.fullScreen) {
            _finalString += `;FULL`;
        }

        return _finalString;
    }

    const convertUrlObjectToForm = (objectUrl) => {
        const _form = [];

        var _index = 0;

        for (var key in objectUrl) {
            if (objectUrl.hasOwnProperty(key)) {

                var _hasForm = false;
                var _type = "text";
                var _options = null;
                var _required = true;
                var _name = "";
                var _value = objectUrl[key] ? objectUrl[key].toString() : null;

                switch (key.toString()) {
                    case "multilanguage":
                        _hasForm = true;
                        _name = getDic("multilinguagem");
                        _required = false;
                        _options = getBoolenList();
                        _type = "select";
                        _value = _value === "true" ? true : false;
                        break;
                    case "urlPt":
                        _hasForm = true;
                        _name = `URL ${getDic("portugues")}`;
                        _required = false;
                        break;
                    case "urlEn":
                        _hasForm = true;
                        _name = `URL ${getDic("ingles")}`;
                        _required = false;
                        break;
                    case "urlSp":
                        _hasForm = true;
                        _name = `URL ${getDic("espanhol")}`;
                        _required = false;
                        break;
                    case "urlDefault":
                        _hasForm = true;
                        _name = `URL Default`;
                        _required = false;
                        _type = "select";
                        _options = getUrlDefaultList();
                        break;
                    case "hasChat":
                        _hasForm = true;
                        _name = `${getDic("possui")} Chat`;
                        _required = false;
                        _options = getBoolenList();
                        _type = "select";
                        _value = _value === "true" ? true : false;
                        break;
                    case "hasQuestions":
                        _hasForm = true;
                        _name = `${getDic("possui")} ${getDic("perguntas")}`;
                        _required = false;
                        _options = getBoolenList();
                        _type = "select";
                        _value = _value === "true" ? true : false;
                        break;
                    case "chatQuestionsOrder":
                        _hasForm = true;
                        _name = getDic("ordem");
                        _required = false;
                        _options = getChatOrderList();
                        _type = "select";
                        _value = _value === "true" ? true : false;
                        break;
                    case "fullScreen":
                        _hasForm = true;
                        _name = "Fullscreen";
                        _required = false;
                        _options = getBoolenList();
                        _type = "select";
                        _value = _value === "true" ? true : false;
                        break;
                    case "SET_GIF_MAP":
                        if (!isSchedule) {
                            _hasForm = true;
                            _name = "Url Gif (SET_GIF_MAP)";
                            _required = false;
                        }
                        break;
                    case "SET_IFRAME_MAP":
                        if (!isSchedule) {
                            _hasForm = true;
                            _name = "Url Iframe (SET_IFRAME_MAP)";
                            _required = false;
                        }
                        break;
                    case "BACKGROUND_CHANGE":
                        if (!isSchedule) {
                            _hasForm = true;
                            _name = "Url Background (BACKGROUND_CHANGE)";
                            _required = false;
                        }
                        break;
                    case "iframeBackgroundColor":
                        _hasForm = true;
                        _name = "Iframe Background Color";
                        _required = false;
                        _value = _value === "transparent" ? null : _value;
                        break;
                    case "iframeWidth":
                        _hasForm = true;
                        _name = `${getDic("largura")} Iframe`;
                        _required = false;
                        _type = "number";
                        break;
                    case "interactBackgroundColor":
                        _hasForm = true;
                        _name = "Interact Background Color";
                        _required = false;
                        _value = _value === "transparent" ? null : _value;
                        break;
                    case "interactWidth":
                        _hasForm = true;
                        _name = `${getDic("largura")} Interact`;
                        _required = false;
                        _type = "number";
                        break;
                    case "calendar":
                        if (isSchedule) {
                            _hasForm = true;
                            _name = `${getDic("calendario")}`;
                            _required = false;
                            _options = getBoolenList();
                            _type = "select";
                            _value = _value === "true" ? true : false;
                        }
                        break;
                    case "category":
                        if (isSchedule) {
                            _hasForm = true;
                            _name = `${getDic("categoria")}`;
                            _required = false;
                        }
                        break;
                    case "backgroundButton":
                        _hasForm = true;
                        _name = `Background ${getDic("botao")}`;
                        _required = false;
                        _value = _value === "#3f51b5" ? null : _value;
                        break;
                    case "textColorButton":
                        _hasForm = true;
                        _name = `${getDic("cor")} ${getDic("texto")} ${getDic("botao")}`;
                        _required = false;
                        _value = _value === "white" ? null : _value;
                        break;
                    case "openWithPage":
                        _hasForm = true;
                        _name = `${getDic("abrir-com-pagina")}`;
                        _required = false;
                        _options = getBoolenList();
                        _type = "select";
                        _value = _value === "true" ? true : false;
                        break;
                    default:
                        break;
                }

                if (_hasForm) {
                    const _obj = createFormControlModel(
                        _index,
                        _name,
                        _value,
                        _type,
                        null,
                        null,
                        _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                        _required,
                        null,
                        null,
                        null,
                        key
                    );
                    _form.push(_obj);
                    _index++;
                }
            }
        }
        return _form;
    }

    const convertFormToUrlObject = (form) => {
        var _config = urlObject;
        form.forEach(item => {
            _config[item.dbReference] = item.value;
        });
        return _config;
    }

    const onChangeHandler = (index, value) => {
        var _newForm = newUrlForm;
        _newForm[index].value = value;
        setNewUrlForm(_newForm);
        setState(state === true ? false : true);
    }

    const saveUrl = () => {
        const _urlObj = convertFormToUrlObject(newUrlForm);
        var _newUrlStr = convertUrlObjectToString(_urlObj);
        localOnClose(_newUrlStr);
    }

    return (
        <Modal
            isVideo
            height="60vh"
            open={open}
            closeButtonClick={e => localOnClose(false)}
            onClose={ret => {
                localOnClose(false);
            }}
        >
            <Container background="#e8eced" align="left" height="100%">
                <FormColumn padding="5px" margin="0px" background="#e8eced">
                    {newUrlForm.map((item, i) => {
                        var _renderField = true;
                        var _fieldName = item.name;
                        if (item.dbReference === "urlPt" && !newUrlForm[0].value) {
                            _fieldName = "URL";
                        }
                        if (item.dbReference === "urlEn" && !newUrlForm[0].value) {
                            _renderField = false;
                        }
                        if (item.dbReference === "urlSp" && !newUrlForm[0].value) {
                            _renderField = false;
                        }
                        if (item.dbReference === "urlDefault" && !newUrlForm[0].value) {
                            _renderField = false;
                        }

                        if (_renderField) {
                            if (item.type === "select" && item.values) {
                                return (
                                    <FormRow key={item.id} align="center" paddingTop="7px">
                                        <SelectList
                                            required={item.required}
                                            error={item.error}
                                            errorMessage={item.errorMessage}
                                            title={_fieldName}
                                            width="100%"
                                            value={item.value}
                                            menuItens={item.values}
                                            onChange={async (event) =>
                                                onChangeHandler(i, event.target.value)
                                            }
                                            multiple={Array.isArray(item.value) ? true : false}
                                        />
                                    </FormRow>
                                );
                            }
                            else {
                                return (
                                    <FormRow key={item.id} align="center" paddingTop="7px">
                                        <InputFieldOutlined
                                            key={item.id}
                                            required={item.required}
                                            maxLength={item.maxLength}
                                            error={item.error}
                                            errorMessage={item.errorMessage}
                                            value={item.value}
                                            title={_fieldName}
                                            type={item.type}
                                            onChange={(event) => {
                                                onChangeHandler(i, event.target.value)
                                            }}
                                            multiline={item.dbReference === "scheduleType" ||
                                                item.dbReference === "groupId" || item.dbReference === "speakers" ? true : false}
                                        />
                                    </FormRow>
                                );
                            }
                        }
                    })}
                    <FormRow align="center" paddingTop="7px">
                        <Button
                            text={getDic("salvar")}
                            onClick={() => saveUrl()}
                        />
                    </FormRow>
                </FormColumn>
            </Container>
        </Modal>
    );
};

export default ModalConcatUrl;
