import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'

const fabric = window.fabric

class Image extends React.Component {
  static propTypes = {
    canvas: PropTypes.object,
    url: PropTypes.string.isRequired,
    scale: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
  }

  static defaultProps = {
    scale: 1.0,
  }

  componentDidMount() {
    const options = omit(this.props, ['scale']);
    fabric.util.loadImage(this.props.url, img => {
      var fabImage = new fabric.Image(img);
      fabImage.scale(this.props.scale);
      fabImage.set({
        selectable: false,
        evented: false,
      });
      this.props.canvas.add(fabImage);
      fabImage.sendToBack();
    }, options, 'anonymous');
  }

  render() {
    return null
  }
}

export default Image
