export function createDateModel(
    serverDate = "",
    localDate = "",
) {
    const obj = {
        serverDate: serverDate,
        localDate: localDate,
    }

    return obj;
}
