import React from "react";
import { connect } from "react-redux";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import ScreenRotationIcon from "@material-ui/icons/ScreenRotation";
import { isMobile, isIOS } from "react-device-detect";
import { getDic } from "../../assets/i18n/dictionary";
import MobileOrientationInstructionsPage from "../../pages/MobileOrientationInstructionsPage";
import * as navigationHistoryService from "../../services/navigationHistoryService";

//Imports de components
import {
  Container,
  FormRow,
  TextBig,
  TextMedium,
  Modal,
} from "../../components";

const MobileOrientation = (props) => {
  const [isTransmissionOrChat, setIsTransmissionOrChat] = React.useState(false);

  //Modal Control
  const [openModal, setOpenModal] = React.useState(false);

  React.useEffect(() => {
    eventListeners();

    return () => { };
  }, []);

  const eventListeners = () => {
    window.addEventListener("orientationchange", () => {
      if (verifyIfIsTransmissionOrChatV2()) {
        setIsTransmissionOrChat(true);
      } else {
        setIsTransmissionOrChat(false);
      }
    });

    window.addEventListener("message", function (e) {
      if (
        e.data &&
        e.data.message &&
        e.data.message === "onModalComponentClose"
      ) {
        verifyIfIsTransmissionOrChatV2();
      }
    });
  };

  const verifyIfIsTransmissionOrChatV2 = () => {
    var _component = document.getElementById("isTransmissionPage");
    if (_component) {
      return true;
    }

    _component = document.getElementById("isChatV2Component");
    if (_component) {
      return true;
    }

    return false;
  };

  const navigateInstructions = () => {
    navigationHistoryService.setHistory(props.history, `/${props.event.id}/orientationinstructions`);
  };

  if (isMobile && !props.isHall360) {
    return (
      <div style={{ zIndex: 1200, position: "fixed", width: "100%" }}>
        <DeviceOrientation lockOrientation={"landscape"}>
          <Orientation orientation="landscape" alwaysRender={false}>
            {props.children}
          </Orientation>
          <Orientation orientation="portrait" alwaysRender={false}>
            {isTransmissionOrChat ? (
              <>{props.children}</>
            ) : (
              <Container background="#e8eced" align="center" centralize={true}>
                <FormRow align="center">
                  <ScreenRotationIcon style={{ fontSize: 150 }} />
                </FormRow>
                <FormRow align="center">
                  <TextBig>{getDic("posicione-device-horizontal")}.</TextBig>
                </FormRow>
                <FormRow align="center">
                  <TextMedium>
                    {getDic("posicione-device-horizontal-mensagem")}.
                  </TextMedium>
                </FormRow>
                {isIOS ? (
                  <FormRow align="center">
                    <TextMedium>
                      <b
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenModal(true)}
                      >
                        {getDic("instrucao-orientacao-clique-aqui")}
                      </b>
                      .
                    </TextMedium>
                  </FormRow>
                ) : (
                  <FormRow align="center">
                    <TextMedium>
                      <b
                        style={{ cursor: "pointer" }}
                        onClick={navigateInstructions}
                      >
                        {getDic("instrucao-orientacao-clique-aqui")}
                      </b>
                      .
                    </TextMedium>
                  </FormRow>
                )}

                <Modal
                  height="80vh"
                  zIndex={999}
                  open={openModal}
                  closeButtonClick={(e) => {
                    setOpenModal(false);
                  }}
                  onClose={(ret) => {
                    setOpenModal(false);
                  }}
                >
                  <MobileOrientationInstructionsPage
                    isModal
                    history={props.history}
                  />
                </Modal>
              </Container>
            )}
          </Orientation>
        </DeviceOrientation>
      </div>
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};

function mapStateToProps(state) {
  return {
    event: state.event,
  };
}

export default connect(mapStateToProps, null)(MobileOrientation);
