import React from "react";

export function getCpfMask(value = "") {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function getIntegerMask(value = "") {
  return value.replace(/\D/g, ""); // substitui qualquer caracter que nao seja numero por nada
}

export function getNumberMask(value = "", decimalPlaces = 2) {
  var re = /^(?:(\d+)(\d{2})|(\d{1,2}))$/;
  var dec = ",";
  var m;
  if ((m = value.match(re)) !== null) {
    if (m[3]) {
      // We have 1 or 2 digit number
      return m[3].length == 1 ? "0" + dec + "0" + m[3] : "0" + dec + m[3];
    } else {
      // We have a longer number
      return m[1].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + ".") + dec + m[2];
    }
  }

  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

export function validateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(mail)) {
    return true;
  }
  console.log("E-mail inválido");
  return false;
}

export function insertHyperLink(text) {
  let parts = text.split(" "); // re is a matching regular expression
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].includes("http://") || 
        parts[i].includes("https://")|| 
        parts[i].includes("HTTPS://")|| 
        parts[i].includes("HTTP://")
      ) {
      parts[i] = (
        <a key={"link" + i} href={parts[i]} target="_blank">
          {parts[i]}{" "}
        </a>
      );
    } else {
      parts[i] = parts[i] + " ";
    }
  }
  return <div>{parts}</div>;
}

export function validateDatetime(dt) {
  try {
    var isValidDate = false;
    var arr1 = dt.split("/");
    var year = 0;
    var month = 0;
    var day = 0;
    var hour = 0;
    var minute = 0;
    var sec = 0;
    if (arr1.length == 3) {
      var arr2 = arr1[2].split(" ");
      if (arr2.length == 2) {
        var arr3 = arr2[1].split(":");
        try {
          year = parseInt(arr2[0], 10);
          month = parseInt(arr1[1], 10);
          day = parseInt(arr1[0], 10);
          hour = parseInt(arr3[0], 10);
          minute = parseInt(arr3[1], 10);
          //sec = parseInt(arr3[0],10);
          sec = 0;
          var isValidTime = false;
          if (
            hour >= 0 &&
            hour <= 23 &&
            minute >= 0 &&
            minute <= 59 &&
            sec >= 0 &&
            sec <= 59
          )
            isValidTime = true;
          else if (hour == 24 && minute == 0 && sec == 0) isValidTime = true;

          if (isValidTime) {
            var isLeapYear = false;
            if (year % 4 == 0) isLeapYear = true;

            if (
              (month == 4 || month == 6 || month == 9 || month == 11) &&
              day >= 0 &&
              day <= 30
            )
              isValidDate = true;
            else if (month != 2 && day >= 0 && day <= 31) isValidDate = true;

            if (!isValidDate) {
              if (isLeapYear) {
                if (month == 2 && day >= 0 && day <= 29) isValidDate = true;
              } else {
                if (month == 2 && day >= 0 && day <= 28) isValidDate = true;
              }
            }
          }
        } catch (er) {
          isValidDate = false;
        }
      }
    }

    return isValidDate;
  } catch (err) {
    console.log("ValidateDate: " + err);
    return false;
  }
}
