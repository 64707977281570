import React from "react";
import GoogleLogin from "react-google-login";
import "../../../scss/SocialButton.scss";

const LoginGoogle = (props) => {
  const responseGoogle = (response) => {
    console.log(response);
  };

  return (
    <GoogleLogin
      clientId="912398355772-mdqecdln71lhq8plbbgvjepptth7bo0j.apps.googleusercontent.com"
      render={renderProps => (
        <div className="google">
          <span 
            onClick={renderProps.onClick} 
            disabled={renderProps.disabled}>
              Google
          </span>
        </div>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      buttonText="Login Google"
      cookiePolicy={"single_host_origin"}
    />
  );
};

export default LoginGoogle;
