export function createEventModel(
    id = 0,
    name = "",
    startDate = "",
    endDate = "",
    contractId = 0,
    googleAnalyticsId = null,
) {
    const event = {
        id: id,
        name: name,
        startDate: startDate,
        endDate: endDate,
        contractId: contractId,
        hasGoogleAnalytics: googleAnalyticsId ? true : false,
        googleAnalyticsId: googleAnalyticsId,
    }

    return event;
}
