import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../../assets/i18n/dictionary';
import { getConfigListByPage, getCoordinateList } from '../../services/configService';
import { insertNewAccess, getSystemActions } from '../../services/accessService';
import { isMobile } from "react-device-detect";

//Imports de components
import {
    Container,
    FormRow,
    ActivityIndicator,
    MenuButton,
    BackButton,

    MobileRender,
    ImageMapRender,
    Hall3D
} from '../../components';

class GeneralPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            configList: [],
            pageTitle: "",
            mobileRender: false,

            imageURLBackground_1600x900: "",
            map_1600x900: {
                name: "my-map",
                areas: [
                    // { name: "ELEMENT-001", shape: "poly", coords: [476, 184, 969, 126, 971, 262, 476, 304], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                ]
            }
        }
    }

    async componentDidMount() {
        //Aplica configurações
        const _url = !this.props.isModal ? this.props.match.url : `/${this.props.event.id}/general70`;
        const configList = await getConfigListByPage(_url);

        configList.find(item => {
            if (item.codeScreen === "TITTLE-001") {
                this.setState({ pageTitle: item.tooltip.toUpperCase() })
            }
            if (item.codeScreen === "NO-MOBILE") {
                this.setState({ mobileRender: true });
            }
            return null;
        });

        const coordinateList = await getCoordinateList(this.state.map_1600x900, configList);

        this.setState({ isLoading: false, configList: configList, map_1600x900: coordinateList });

        if (!this.props.isModal) {
            await insertNewAccess(this.props.match.url, this.state.pageTitle, getSystemActions().accessPage, this.state.pageTitle);
        }
    }

    async componentWillUnmount() {
        if (!this.props.isModal) {
            await insertNewAccess(this.props.match.url, this.state.pageTitle, getSystemActions().accessPage, this.state.pageTitle);
        }
    }

    componentDidCatch(e) {
        var error = e.toString();

        if (error.includes("Error: Maximum update depth exceeded")) {
            window.location.reload();
        }
    }

    render() {
        if (this.state.isLoading)
            return (
                <Container background="#e8eced" align="center" >
                    <FormRow align="center">
                        <ActivityIndicator />
                    </FormRow>
                </Container>
            );
        if (this.state.configList.find(e => e.codeScreen == 'ENABLE_HALL_3D'))
            return (
                <React.Fragment>
                    <MenuButton history={this.props.history} />
                    <BackButton
                        history={this.props.history}
                        color="#212121"
                    >
                        {getDic("voltar").toUpperCase()}
                    </BackButton>

                    <Hall3D
                        history={this.props.history}
                        hall_360_url={this.state.configList.find(e => e.codeScreen == 'ENABLE_HALL_3D').url[0]}
                        configList={this.state.configList}
                    />
                </React.Fragment>
            )
        return (
            <div>
                {!this.props.isModal && (
                    <>
                        <MenuButton history={this.props.history} />
                        <BackButton history={this.props.history} color="#212121">
                            {getDic("voltar").toUpperCase()}
                        </BackButton>

                    </>
                )}

                {isMobile && this.state.mobileRender ? (
                    <MobileRender
                        configList={this.state.configList}
                        defaultImage={this.state.imageURLBackground_1600x900}
                        history={this.props.history}
                    />
                ) : (
                    <div>
                        <ImageMapRender
                            configList={this.state.configList}
                            history={this.props.history}
                            defaultImage={this.state.imageURLBackground_1600x900}
                            defaultMap={this.state.map_1600x900}
                        />
                    </div>
                )
                }
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        event: state.event,
        user: state.user,
    }
}

export default connect(mapStateToProps, null)(GeneralPage);
