export const SET_CHOSEN_CONFIG = 'SET_CHOSEN_CONFIG';
const setChosenConfigLocal = config => ({
    type: SET_CHOSEN_CONFIG,
    config
});
export function setChosenConfig(config) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setChosenConfigLocal(config));
            resolve(true);
        });
    };
}

export const RESET_CHOSEN_CONFIG = 'RESET_CHOSEN_CONFIG';
const resetChosenConfigLocal = () => ({
    type: RESET_CHOSEN_CONFIG
});
export function resetChosenConfig() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetChosenConfigLocal());
            resolve(true);
        });
    };
}
