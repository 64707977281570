import React from 'react';
import { getDic } from "../assets/i18n/dictionary";
import { getFileExtension, validateForm } from '../utils';
import WhatsappImporterExcel from '../excel/WhatsappImporterExcel';
import WhatsappImporter from '../importers/whatsappImporter';
import { getTemplateList } from '../services/whatsappService';

//Imports de components
import {
  MenuButton,
  BackButton,

  Alert,
  Container,
  FormRow,
  Button,
  InputFieldOutlined,
  TextSmall,
  Loading
} from '../components';

const App = (props) => {
  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(true);

  const [importerTable, setImporterTable] = React.useState(null);
  const [importerReturnTable, setImporterReturnTable] = React.useState(null);
  const [executeWhatsappImporter, setExecuteWhatsappImporter] = React.useState(false);

  const [templatesList, setTemplatesList] = React.useState(false);

  React.useEffect(() => {
    getPageData();
  }, []);

  const getPageData = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("carregando"));
    getTemplateList()
      .then(res => {
        setTemplatesList(res);
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle("Erro");
        setAlertMessage("Não foi possível conectar ao servidor");
      })
      .finally(() => {
        setOpenLoading(false);
      })
  }

  const setTableFile = (file) => {
    if (file) {
      var _fileType = getFileExtension(file);
      if (_fileType === "XLSX") {
        setImporterTable(file);
      }
      else {
        setAlertOpen(true);
        setAlertTitle("Erro");
        setAlertMessage("Formato de arquivo inválido");
        setImporterTable(null);
      }
    }
  }

  const returnImportFile = (res) => {
    if (!res.error && res.errorMessage === '') {
      setAlertOpen(true);
      setAlertTitle("");
      setAlertMessage("Enviado com sucesso");
    }
    else {
      setAlertOpen(true);
      setAlertTitle(`Erro`);
      setAlertMessage(res.errorMessage);
    }

    if (res.data && res.data.length > 0) {
      setImporterReturnTable(res.data);
    }

    setImporterTable(null);
    setExecuteWhatsappImporter(false);
    var _btn = document.getElementById("btnImporterReturnTable");
    if (_btn) {
      setTimeout(() => {
        _btn.click();
      }, 500);
    }
    setTimeout(() => {
      setImporterReturnTable(null);
      var _input = document.getElementById("uploadFile");
      if (_input) {
        _input.value = null;
      }
      setTableFile(null);
    }, 2000);
  }

  return (
    <Container background="#e8eced" align="center" height="100vh" >
      <div>
        <MenuButton history={props.history} />
        <BackButton history={props.history} color="#212121">
          {getDic("voltar").toUpperCase()}
        </BackButton>

      </div>
      <FormRow alignItems="center" direction="column">
        <div style={{ paddingTop: "10px", maxWidth: "400px" }}>
          <WhatsappImporterExcel
            exampleFile
            templatesList={templatesList}
            button={
              <Button color="green" textColor="white">
                Gerar Planilha Importador
              </Button>
            }
          />
        </div>
        <div style={{ maxWidth: "400px", paddingTop: "20px" }}>
          <InputFieldOutlined
            id="uploadFile"
            key="file"
            type="file"
            title={`Importar Planilha`}
            placeholder={"Planilha"}
            onChange={e => setTableFile(e.target.files[0])}
          />
          <FormRow align="center">
            <TextSmall>Formatos Permitidos: XLSX</TextSmall>
          </FormRow>
        </div>
        {importerTable && (
          <div style={{ maxWidth: "400px", paddingTop: "20px" }}>
            <Button color="blue" textColor="white" onClick={() => setExecuteWhatsappImporter(true)}>
              Importar Planilha
            </Button>
          </div>
        )}

        {/* Planilha de retorno do importador */}
        {importerReturnTable && (
          <div hidden>
            <WhatsappImporterExcel
              hideElement
              templatesList={templatesList}
              data={importerReturnTable}
              button={
                <button id="btnImporterReturnTable">X</button>
              }
            />
          </div>
        )}

        {executeWhatsappImporter && (
          <WhatsappImporter
            templatesList={templatesList}
            file={importerTable}
            returnImportFile={res => {
              returnImportFile(res);
            }}
          />
        )}

      </FormRow>

      <Alert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={() => setAlertOpen(false)}
        title={alertTitle}
        message={alertMessage}
      />

      <Loading open={openLoading} message={loadingMessage} />
    </Container>
  );
}

export default App;
