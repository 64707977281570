import { SET_CHOSEN_CAMPAIGN, RESET_CHOSEN_CAMPAIGN } from '../actions';

export default function chosenCampaignReducer(state = null, action) {
    switch (action.type) {
        case SET_CHOSEN_CAMPAIGN:
            return action.campaign;
        case RESET_CHOSEN_CAMPAIGN:
            return null;
        default:
            return state;
    }
}
