import * as apiService from './apiService';
import axios from 'axios';

export async function setAdminPlusRankingPoints(rankingCode = '', rankingCodeSubtract = false, guestId = '', eventId = '') {
  try {
    const _options = {
      url: `${apiService.getUrl().gateway}api-admin-ranking/events/${eventId}/logins/${guestId}/earnedpointcreate`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        code: rankingCode,
        positive: rankingCodeSubtract ? false : true,
        log: 'Hall',
      }
    };

    await axios(_options);

  } catch (error) {
    console.log('Error setAdminPlusRankingPoints', error);
    throw error;
  }
}
