import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { resetChosenCampaign } from "../store/actions";
import { createCampaignModel } from "../models/campaignModel";
import {
  getGuestIdsByGroupApi,
  sendCampaignApi,
  getCampaignLogApi,
  getGuestsApi,
  getCampaignApi,
  updateCampaignApi,
  openCampaignSocketConnection
} from '../services/campaignService';
import { getGroupsApi } from '../services/groupService';
import SearchIcon from '@material-ui/icons/Search';
import ReceivedIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/HighlightOff';
import NotReceivedIcon from '@material-ui/icons/History';
import AwaitIcon from '@material-ui/icons/PauseCircleOutline';
import RemoveIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/ArrowForward';
//Imports de components
import {
  MenuButton,
  BackButton,
  Alert,
  Loading,
  Container,
  FormRow,
  FormColumn,
  GridRow,
  GridCell,
  TextMedium,
  Checkbox,
  Button,
  Dialog,
  InputFieldOutlined,
  TextSmall,
  InfiniteScroll,
  SelectList,
  Modal,
  ActivityIndicator,
  TextBig
} from '../components';
import moment from 'moment';

const CampaignsRegisterPage = (props) => {
  const [state, setState] = React.useState(true);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(true);

  //Dialog control
  const [dialogSendCampaignOpen, setDialogSendCampaignOpen] = React.useState(false);
  const [dialogConfirmKey, setDialogConfirmKey] = React.useState("");
  const [dialogConfirmKeyErrorMessage, setDialogConfirmKeyErrorMessage] = React.useState("");

  //Modal Control
  const [openModalParticipants, setOpenModalParticipants] = React.useState(false);
  const [openModalLogs, setOpenModalLogs] = React.useState(false);

  const chosenCampaign = React.useRef(createCampaignModel());
  const [groupsList, setGroupsList] = React.useState([]);
  const [chosenGroup, setChosenGroup] = React.useState(null);
  const [groupsAddList, setGroupsAddList] = React.useState([]);
  const [chosenAddGroup, setChosenAddGroup] = React.useState(null);
  const [isMessageDelivered, setIsMessageDelivered] = React.useState([
    { text: getDic("todos"), value: "all" },
    { text: getDic("recebida"), value: "received" },
    { text: `${getDic("nao")} ${getDic("recebida")}`, value: "notReceived" },
  ]);
  const [chosenIsMessageDelivered, setChosenIsMessageDelivered] = React.useState("all");
  const [guestsList, setGuestsList] = React.useState([])
  const [hasMore, setHasMore] = React.useState(true);
  const [listIndex, setListIndex] = React.useState(1);
  const searchNameText = React.useRef();

  const [campaignLogs, setCampaignLogs] = React.useState([]);
  const searchParticipantText = React.useRef();
  const [participantsList, setParticipantsList] = React.useState([]);
  const [participantsIndex, setParticipantsIndex] = React.useState(1);
  const [participantsHasMore, setParticipantsHasMore] = React.useState(true);
  const [notParticipantsList, setNotParticipantsList] = React.useState([]);
  const [notParticipantsIndex, setNotParticipantsIndex] = React.useState(1);
  const [notParticipantsHasMore, setNotParticipantsHasMore] = React.useState(true);

  React.useEffect(() => {
    //Bloqueio caso o usuário não seja admin
    if (!props.user.isAdmin || !props.chosenCampaign) {
      props.history.goBack();
    }

    var helpButton = document.getElementById("launcher");
    if (helpButton) {
      helpButton.style.visibility = 'hidden';
    }

    getPageData();

    insertNewAccess(props.match.url, "", getSystemActions().accessPage);

    return () => {
      resetChosenCampaign();
      var helpButton = document.getElementById("launcher");
      if (helpButton) {
        helpButton.style.visibility = 'visible';
      }
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    }
  }, []);

  const getPageData = async () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("carregando"));

    const _socket = await openCampaignSocketConnection();
    _socket.on(`sendWhatsappMessage_${props.chosenCampaign.id}`, data => {
      getSocketWhatsappMessageReturn(data);
    });

    getGroupsApi()
      .then(res => {
        const _groups = res.map(g => {
          return {
            text: g.name,
            value: g.id
          }
        })
        setGroupsList([{
          text: getDic("todos"),
          value: null
        },
        ..._groups]);
        setGroupsAddList(_groups);
      })
      .catch(() => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      });

    getCampaignData();
  }

  const getCampaignData = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("carregando"));
    getCampaignApi(props.chosenCampaign.id)
      .then(res => {
        chosenCampaign.current = res;
      })
      .catch(() => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      })
      .finally(() => {
        setOpenLoading(false);
        getGuestsList();
      });
  }

  const getSocketWhatsappMessageReturn = (data) => {
    if (data.campaignId !== props.chosenCampaign.id) return;
    if (data.action !== 'messageSent') return;

    if (data.success === true) {
      chosenCampaign.current.targetsSent.push(data.guestId);
    } else {
      chosenCampaign.current.targetsError.push(data.guestId);
    }
    forceUpdate();
    if (data.isLastMessage) {
      getCampaignData();
    }
  }

  const getGuestsList = (pageIndex = 1) => {
    var _ids = chosenCampaign.current.targets.length > 0 ? chosenCampaign.current.targets : [0];
    var _notIds;
    if (chosenIsMessageDelivered === "received") {
      _ids = chosenCampaign.current.targetsSent;
    } else if (chosenIsMessageDelivered === "notReceived") {
      _notIds = chosenCampaign.current.targetsSent;
    }

    getGuestsApi(searchNameText.current, chosenGroup, _ids, _notIds, pageIndex)
      .then(res => {
        if (res.totalIndex <= pageIndex) {
          setHasMore(false);
        } else {
          setListIndex(pageIndex + 1);
        }

        if (pageIndex === 1) {
          setGuestsList([...res.guests]);
        } else {
          setGuestsList([...guestsList, ...res.guests]);
        }
      })
      .catch(() => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      });
  }

  const getParticipantsList = (pageIndex = 1) => {
    var _ids = chosenCampaign.current.targets.length > 0 ? chosenCampaign.current.targets : [0];

    getGuestsApi(searchParticipantText.current, chosenGroup, _ids, null, pageIndex)
      .then(res => {
        if (res.totalIndex <= pageIndex) {
          setParticipantsHasMore(false);
        } else {
          setParticipantsIndex(pageIndex + 1);
        }

        if (pageIndex === 1) {
          setParticipantsList([...res.guests]);
        } else {
          setParticipantsList([...participantsList, ...res.guests]);
        }
      })
      .catch(() => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      });
  }

  const getNotParticipantsList = (pageIndex = 1) => {
    var _notIds = chosenCampaign.current.targets;

    getGuestsApi(searchParticipantText.current, chosenGroup, null, _notIds, pageIndex)
      .then(res => {
        if (res.totalIndex <= pageIndex) {
          setNotParticipantsHasMore(false);
        } else {
          setNotParticipantsIndex(pageIndex + 1);
        }

        if (pageIndex === 1) {
          setNotParticipantsList([...res.guests]);
        } else {
          setNotParticipantsList([...notParticipantsList, ...res.guests]);
        }
      })
      .catch(() => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      });
  }

  const clearGuestsSearch = () => {
    setListIndex(1);
    setHasMore(true);
    setGuestsList([]);
  }

  const clearParticipantsSearch = () => {
    setParticipantsIndex(1);
    setParticipantsHasMore(true);
    setParticipantsList([]);
    setNotParticipantsIndex(1);
    setNotParticipantsHasMore(true);
    setNotParticipantsList([]);
  }

  const addGroups = (group = 0) => {
    setOpenLoading(true);
    setLoadingMessage(getDic("salvando"));
    getGuestIdsByGroupApi(group)
      .then(res => {
        var _campaign = chosenCampaign.current;
        _campaign.targets = [..._campaign.targets, ...res];

        saveCampaign(_campaign);
      })
      .catch(() => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
        setOpenLoading(false);
      });
  }

  const removeGroups = (group = 0) => {
    setOpenLoading(true);
    setLoadingMessage(getDic("salvando"));
    getGuestIdsByGroupApi(group)
      .then(res => {
        var _campaign = chosenCampaign.current;
        res.forEach(item => {
          _campaign.targets = _campaign.targets.filter(id => id !== item);
        });

        saveCampaign(_campaign);
      })
      .catch(() => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
        setOpenLoading(false);
      });
  }

  const sendCampaign = async (sendAll = true) => {
    if (dialogConfirmKey && dialogConfirmKey != "" && dialogConfirmKey.toUpperCase() === "SIM") {
      setOpenLoading(true);
      setLoadingMessage(getDic("salvando"));

      try {
        await sendCampaignApi(chosenCampaign.current, sendAll === true ? false : true);
        chosenCampaign.current.status = 'sending';
        chosenCampaign.current.targetsError = [];
        if (sendAll) {
          chosenCampaign.current.targetsSent = [];
        }
        setOpenLoading(false);
        setAlertOpen(true);
        setAlertTitle('');
        setAlertMessage(getDic("enviado-sucesso"));
        getCampaignData();
      } catch (error) {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
        setOpenLoading(false);
      }

      setDialogSendCampaignOpen(false);
      setDialogConfirmKey("");
    }
    else {
      setDialogConfirmKeyErrorMessage(true);
      setTimeout(() => {
        setDialogConfirmKeyErrorMessage(false);
      }, 3000); // 1 minuto = 60000
    }
  }

  const getCampaignLog = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("carregando"));
    getCampaignLogApi(chosenCampaign.current.id)
      .then(res => {
        setCampaignLogs(res);
        setOpenModalLogs(true);
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  const checkIfGuestReceivedMessage = (guest) => {
    var _messageStatus = 'waiting';
    if (chosenCampaign.current.targetsSent.includes(guest.id)) {
      _messageStatus = 'received';
    } else if (chosenCampaign.current.targetsError.includes(guest.id)) {
      _messageStatus = 'error';
    } else if (chosenCampaign.current.status === 'sending') {
      _messageStatus = 'notReceived';
    }
    return _messageStatus;
  }

  const addParticipants = () => {
    const _participants = notParticipantsList.filter(p => p.selected === true).map(p => p.id);
    if (_participants.length > 0) {
      setOpenLoading(true);
      setLoadingMessage(getDic("salvando"));

      var _campaign = chosenCampaign.current;
      _campaign.targets = [..._campaign.targets, ..._participants];

      saveCampaign(_campaign);
    }
  }

  const removeParticipants = () => {
    const _participants = participantsList.filter(p => p.selected === true).map(p => p.id);
    if (_participants.length > 0) {
      setOpenLoading(true);
      setLoadingMessage(getDic("salvando"));

      var _campaign = chosenCampaign.current;
      _participants.forEach(item => {
        _campaign.targets = _campaign.targets.filter(id => id !== item);
      });

      saveCampaign(_campaign);
    }
  }

  const saveCampaign = (campaign) => {
    updateCampaignApi(campaign)
      .then(res => {
        chosenCampaign.current = res;
        clearParticipantsSearch();
        getParticipantsList(1);
        getNotParticipantsList(1);
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  return (
    <Container background="#e8eced" align="center">
      <FormColumn align="center" background="#e8eced" margin="0px">
        <FormRow align="center" background="#e8eced" margin="0px">
          <TextMedium><b>{getDic("campanha")}:</b> {chosenCampaign.current.name}</TextMedium>
        </FormRow>
        <FormRow align="center" background="#e8eced" margin="0px">
          <TextMedium>
            <b>Status:</b> {chosenCampaign.current.status === 'sending' ? getDic("enviando") :
              chosenCampaign.current.status === 'sent' ? getDic("enviado") : `${getDic("aguardando")} ${getDic("envio")}`}
          </TextMedium>
        </FormRow>
        <FormRow align="center" background="#e8eced" margin="0px">
          <div>
            <MenuButton history={props.history} />
            <BackButton history={props.history} color="#212121">
              {getDic("voltar").toUpperCase()}
            </BackButton>
          </div>

          <FormRow
            minHeight="60px"
            align="center"
            background="#e8eced"
            margin="0px"
            paddingTop="5px"
          >
            <div style={{ width: 150, background: 'white', borderRadius: 5, border: "3px solid green" }}>
              <FormColumn margin="0px">
                <FormRow margin="0px" align="center">
                  <TextMedium>{getDic("recebido")}</TextMedium>
                </FormRow>
                <FormRow margin="0px" align="center">
                  <TextBig>{chosenCampaign.current.targetsSent.length}</TextBig>
                </FormRow>
              </FormColumn>
            </div>
            <div style={{ width: 10 }} />
            <div style={{ width: 150, background: 'white', borderRadius: 5, border: "3px solid blue" }}>
              <FormColumn margin="0px">
                <FormRow margin="0px" align="center">
                  <TextMedium>{getDic("aguardando")}</TextMedium>
                </FormRow>
                <FormRow margin="0px" align="center">
                  <TextBig>
                    {chosenCampaign.current.targets.length - chosenCampaign.current.targetsSent.length - chosenCampaign.current.targetsError.length}
                  </TextBig>
                </FormRow>
              </FormColumn>
            </div>
            <div style={{ width: 10 }} />
            <div style={{ width: 150, background: 'white', borderRadius: 5, border: "3px solid red" }}>
              <FormColumn margin="0px">
                <FormRow margin="0px" align="center">
                  <TextMedium>{getDic("erro")}</TextMedium>
                </FormRow>
                <FormRow margin="0px" align="center">
                  <TextBig>{chosenCampaign.current.targetsError.length}</TextBig>
                </FormRow>
              </FormColumn>
            </div>
          </FormRow>
          <FormRow
            minHeight="60px"
            align="flex-end"
            background="#e8eced"
            margin="0px"
            paddingTop="5px"
          >
            <div style={{ padding: 5 }}>
              <Button
                color="lightgray"
                textColor="black"
                onClick={() => getCampaignLog()}
              >
                {`${getDic("consultar")} Log`}
              </Button>
            </div>
            {chosenCampaign.current.status !== 'sending' && (
              <>
                <div style={{ padding: 5 }}>
                  <Button
                    color="orange"
                    onClick={() => {
                      searchParticipantText.current = "";
                      clearParticipantsSearch();
                      getParticipantsList(1);
                      getNotParticipantsList(1);
                      setOpenModalParticipants(true);
                    }}
                  >
                    {`${getDic("adicionar")}/${getDic("remover")} ${getDic("participantes")}`}
                  </Button>
                </div>
                <div style={{ padding: 5 }}>
                  <Button
                    color="green"
                    onClick={() => setDialogSendCampaignOpen(true)}
                  >
                    {`${getDic("enviar")} ${getDic("campanha")}`}
                  </Button>
                </div>
              </>
            )}
          </FormRow>
          <FormRow
            minHeight="60px"
            align="flex-end"
            background="#e8eced"
            margin="0px"
            paddingTop="5px"
          >
            <div style={{ padding: 5, maxWidth: "400px" }}>
              <InputFieldOutlined
                value={searchNameText.current}
                onChange={e => {
                  searchNameText.current = e.target.value;
                  setState(!state);
                }}
                placeholder={`${getDic("buscar")} ${getDic("nome")}`}
                endAdornment={<SearchIcon />}
              />
            </div>
            <div style={{ padding: 5, maxWidth: "400px" }}>
              <SelectList
                title={getDic("grupo")}
                width="200px"
                value={chosenGroup}
                menuItens={groupsList}
                onChange={(event) => setChosenGroup(event.target.value)}
              />
            </div>
            <div style={{ padding: 5, maxWidth: "400px" }}>
              <SelectList
                title={`${getDic("mensagem")} ${getDic("recebida")}`}
                width="200px"
                value={chosenIsMessageDelivered}
                menuItens={isMessageDelivered}
                onChange={(event) => setChosenIsMessageDelivered(event.target.value)}
              />
            </div>
            <div style={{ padding: 5 }}>
              <Button
                onClick={() => {
                  clearGuestsSearch();
                  getGuestsList(1);
                }}
              >
                {getDic("buscar")}
              </Button>
            </div>
          </FormRow>

          <FormColumn align="center" background="#e8eced" margin="0px" padding="5px">
            {guestsList.length > 0 ? (
              <InfiniteScroll
                dataLength={guestsList.length}
                next={() => getGuestsList(listIndex)}
                hasMore={hasMore}
                pageStart={0}
                height={"60vh"}
                loader={<ActivityIndicator />}
              >
                <GridRow backgroundColor="#d9d9d9">
                  <GridCell align="center" width="20%" border top first>
                    <TextMedium>ID</TextMedium>
                  </GridCell>
                  <GridCell align="center" width="50%" border top>
                    <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                  </GridCell>
                  <GridCell align="center" width="30%" border top>
                    <TextMedium>{`STATUS ${getDic("mensagem")}`.toUpperCase()}</TextMedium>
                  </GridCell>
                </GridRow>
                {guestsList.map((item, i) => {
                  const _messageStatus = checkIfGuestReceivedMessage(item);
                  return (
                    <GridRow
                      key={item.id}
                      autoColor={i}
                    >
                      <GridCell width="20%" border first>
                        <TextMedium>{item.id}</TextMedium>
                      </GridCell>
                      <GridCell width="50%" border>
                        <TextMedium>{item.name}</TextMedium>
                      </GridCell>
                      <GridCell align="center" width="30%" border>
                        {_messageStatus === 'received' ? (
                          <><ReceivedIcon style={{ color: "green" }} /> {getDic("recebido")}</>
                        ) : _messageStatus === 'waiting' ? (
                          <><AwaitIcon style={{ color: "blue" }} /> {getDic("aguardando")} {getDic("envio")}</>
                        ) : _messageStatus === 'notReceived' ? (
                          <><NotReceivedIcon style={{ color: "yellow" }} /> {getDic("enviando")}</>
                        ) : (
                          <><ErrorIcon style={{ color: "red" }} /> {getDic("erro")} {getDic("ao")} {getDic("enviar")}</>
                        )}
                      </GridCell>
                    </GridRow>
                  );
                })}
              </InfiniteScroll>
            ) : (
              <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
            )}
          </FormColumn>

          <Modal
            isVideo
            openModalFullScreen={true}
            open={openModalParticipants}
            closeButtonClick={e => {
              setParticipantsList([]);
              setNotParticipantsList([]);
              setOpenModalParticipants(false);
              clearGuestsSearch();
              getGuestsList(1);
            }}
            onClose={ret => {
              setParticipantsList([]);
              setNotParticipantsList([]);
              setOpenModalParticipants(false);
            }}
          >
            <Container background="#e8eced" align="left" height="100%">
              <FormColumn align="center" background="#e8eced" margin="0px">
                <FormRow align="center" minHeight="50px" background="#e8eced" margin="0px" paddingTop="5px">
                  {groupsAddList.length > 0 && (
                    <>
                      <div style={{ padding: 5, maxWidth: "400px" }}>
                        <SelectList
                          title={getDic("grupo")}
                          width="200px"
                          value={chosenAddGroup}
                          menuItens={groupsAddList}
                          onChange={(event) => setChosenAddGroup(event.target.value)}
                        />
                      </div>
                      <div style={{ padding: 5 }}>
                        <Button
                          color="green"
                          onClick={() => {
                            addGroups(chosenAddGroup);
                          }}
                        >
                          {getDic("adicionar")} {getDic("grupo")}
                        </Button>
                      </div>
                      <div style={{ padding: 5 }}>
                        <Button
                          color="orange"
                          onClick={() => {
                            removeGroups(chosenAddGroup);
                          }}
                        >
                          {getDic("remover")} {getDic("grupo")}
                        </Button>
                      </div>
                    </>
                  )}
                </FormRow>
                <FormRow
                  minHeight="60px"
                  align="center"
                  background="#e8eced"
                  margin="0px"
                  paddingTop="5px"
                >
                  <div style={{ padding: 5, maxWidth: "400px" }}>
                    <InputFieldOutlined
                      value={searchParticipantText.current}
                      onChange={e => {
                        searchParticipantText.current = e.target.value;
                        setState(!state);
                      }}
                      placeholder={`${getDic("buscar")} ${getDic("nome")}`}
                      endAdornment={<SearchIcon />}
                    />
                  </div>
                  <div style={{ padding: 5 }}>
                    <Button
                      onClick={() => {
                        clearParticipantsSearch();
                        getParticipantsList(1);
                        getNotParticipantsList(1);
                      }}
                    >
                      {getDic("buscar")}
                    </Button>
                  </div>
                </FormRow>

                <FormColumn align="center" background="#e8eced" margin="0px">
                  <FormRow align="center" background="#e8eced" margin="0px">
                    <FormColumn align="center" background="#e8eced" margin="0px" width="40%">
                      <TextMedium fontWeight="bold">{getDic("nao")} {getDic("participantes")}</TextMedium>
                    </FormColumn>
                    <FormColumn align="center" background="#e8eced" margin="0px" width="10%">
                      <TextMedium fontWeight="bold">{getDic("acao")}</TextMedium>
                    </FormColumn>
                    <FormColumn align="center" background="#e8eced" margin="0px" width="40%">
                      <TextMedium fontWeight="bold">{getDic("participantes")}</TextMedium>
                    </FormColumn>
                  </FormRow>
                  <FormRow align="center" background="#e8eced" margin="0px">
                    <FormColumn align="center" background="#e8eced" margin="0px" width="40%">
                      {notParticipantsList.length > 0 ? (
                        <InfiniteScroll
                          next={() => getNotParticipantsList(notParticipantsIndex)}
                          hasMore={notParticipantsHasMore}
                          pageStart={0}
                          dataLength={notParticipantsList.length}
                          height={"71vh"}
                          loader={<ActivityIndicator />}
                        >
                          <GridRow backgroundColor="#d9d9d9">
                            <GridCell width="15%" border top first>
                            </GridCell>
                            <GridCell align="center" width="25%" border top>
                              <TextMedium>ID</TextMedium>
                            </GridCell>
                            <GridCell align="center" width="60%" border top>
                              <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                            </GridCell>
                          </GridRow>

                          {notParticipantsList.map((item, i) => {
                            return (
                              <GridRow
                                key={item.id}
                                autoColor={i}
                              >
                                <GridCell width="15%" border first>
                                  <Checkbox
                                    checked={item.selected}
                                    onClick={() => {
                                      var change = notParticipantsList;
                                      change[i].selected = !change[i].selected;
                                      setNotParticipantsList(change);
                                      setState(!state);
                                    }}
                                  />
                                </GridCell>
                                <GridCell width="25%" border>
                                  <TextMedium>{item.id}</TextMedium>
                                </GridCell>
                                <GridCell width="60%" border>
                                  <TextMedium>{item.name}</TextMedium>
                                </GridCell>
                              </GridRow>
                            );
                          })}
                        </InfiniteScroll>
                      ) : (
                        <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
                      )}
                    </FormColumn>
                    <FormColumn alignItems="center" align="center" background="#e8eced" margin="0px" width="10%">
                      <div style={{ padding: 5 }}>
                        <Button
                          onClick={() => {
                            addGroups();
                          }}
                        >
                          {getDic("adicionar")} {getDic("todos")}
                        </Button>
                      </div>
                      <div style={{ padding: 5 }}>
                        <Button
                          onClick={() => addParticipants()}
                        >
                          <AddIcon fontSize="large" />
                        </Button>
                      </div>
                      <div style={{ padding: 5 }}>
                        <Button
                          color="#ed5028"
                          onClick={() => removeParticipants()}
                        >
                          <RemoveIcon fontSize="large" />
                        </Button>
                      </div>
                      <div style={{ padding: 5 }}>
                        <Button
                          color="#ed5028"
                          onClick={() => {
                            removeGroups();
                          }}
                        >
                          {getDic("remover")} {getDic("todos")}
                        </Button>
                      </div>
                    </FormColumn>
                    <FormColumn align="center" background="#e8eced" margin="0px" width="40%">
                      {participantsList.length > 0 ? (
                        <InfiniteScroll
                          next={() => getParticipantsList(participantsIndex)}
                          hasMore={participantsHasMore}
                          pageStart={0}
                          dataLength={participantsList.length}
                          height={"71vh"}
                          loader={<ActivityIndicator />}
                        >
                          <GridRow backgroundColor="#d9d9d9">
                            <GridCell width="15%" border top first>
                            </GridCell>
                            <GridCell align="center" width="25%" border top>
                              <TextMedium>ID</TextMedium>
                            </GridCell>
                            <GridCell align="center" width="60%" border top>
                              <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                            </GridCell>
                          </GridRow>

                          {participantsList.map((item, i) => {
                            return (
                              <GridRow
                                key={item.id}
                                autoColor={i}
                              >
                                <GridCell width="15%" border first>
                                  <Checkbox
                                    checked={item.selected}
                                    onClick={() => {
                                      var change = participantsList;
                                      change[i].selected = !change[i].selected;
                                      setParticipantsList(change);
                                      setState(!state);
                                    }}
                                  />
                                </GridCell>
                                <GridCell width="25%" border>
                                  <TextMedium>{item.id}</TextMedium>
                                </GridCell>
                                <GridCell width="60%" border>
                                  <TextMedium>{item.name}</TextMedium>
                                </GridCell>
                              </GridRow>
                            );
                          })}
                        </InfiniteScroll>
                      ) : (
                        <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
                      )}
                    </FormColumn>
                  </FormRow>
                </FormColumn>
              </FormColumn>
            </Container>
          </Modal>

          <Modal
            isVideo
            height="80vh"
            open={openModalLogs}
            closeButtonClick={e => {
              setCampaignLogs([]);
              setOpenModalLogs(false);
            }}
            onClose={ret => {
              setCampaignLogs([]);
              setOpenModalLogs(false);
            }}
          >
            <Container background="#e8eced" align="left" height="100%" >
              {campaignLogs.length > 0 ? (
                <InfiniteScroll
                  pageStart={0}
                  dataLength={campaignLogs.length}
                  height={"79vh"}
                  loader={<ActivityIndicator />}
                >
                  <GridRow backgroundColor="#d9d9d9">
                    <GridCell width="20%" border top first>
                      <TextMedium>{getDic("data").toUpperCase()}</TextMedium>
                    </GridCell>
                    <GridCell width="20%" border top>
                      <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border top>
                      <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
                    </GridCell>
                    <GridCell width="50%" border top>
                      <TextMedium>LOG</TextMedium>
                    </GridCell>
                  </GridRow>

                  {campaignLogs.map((item, i) => {
                    return (
                      <GridRow
                        key={item.id}
                        autoColor={i}
                      >
                        <GridCell width="20%" border first>
                          <TextMedium>{moment(item.date).format('LLLL')}</TextMedium>
                        </GridCell>
                        <GridCell width="20%" border>
                          <TextMedium>{item.name}</TextMedium>
                        </GridCell>
                        <GridCell width="10%" border>
                          <TextMedium>{item.log}</TextMedium>
                        </GridCell>
                        <GridCell width="50%" border>
                          <TextMedium>{item.object}</TextMedium>
                        </GridCell>
                      </GridRow>
                    );
                  })}
                </InfiniteScroll>
              ) : (
                <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
              )}
            </Container>
          </Modal>

          <Alert
            open={alertOpen}
            onClose={() => setAlertOpen(false)}
            onClick={() => setAlertOpen(false)}
            title={alertTitle}
            message={alertMessage}
          />

          <Dialog
            open={dialogSendCampaignOpen}
            onClose={() => setDialogSendCampaignOpen(false)}
            title={getDic("atencao")}
          >
            <FormRow>
              <TextSmall>
                {getDic("confirmacao-de-acao")}
              </TextSmall>
            </FormRow>
            <FormRow align="center" paddingTop="5px">
              <InputFieldOutlined
                key="password"
                value={dialogConfirmKey}
                onChange={event => setDialogConfirmKey(event.target.value)}
              />
            </FormRow>
            <FormRow align="center" paddingTop="5px">
              <div style={{ padding: 5 }}>
                <Button
                  color="red"
                  onClick={() => {
                    setDialogSendCampaignOpen(false);
                    setDialogConfirmKey("");
                  }}
                >
                  {getDic("cancelar")}
                </Button>
              </div>
              <div style={{ padding: 5 }}>
                <Button
                  onClick={() => sendCampaign(true)}
                >
                  {`${getDic("enviar")} ${getDic("todos")}`}
                </Button>
              </div>
              <div style={{ padding: 5 }}>
                <Button
                  color="green"
                  onClick={() => sendCampaign(false)}
                >
                  {`${getDic("enviar")} ${getDic("nao")} ${getDic("recebidos")}`}
                </Button>
              </div>
            </FormRow>
            {dialogConfirmKeyErrorMessage ? (
              <TextSmall color="red">{getDic("erro")}</TextSmall>
            ) : (null)}
          </Dialog>

          <Dialog
            open={dialogSendCampaignOpen}
            onClose={() => setDialogSendCampaignOpen(false)}
            title={getDic("atencao")}
          >
            <FormRow>
              <TextSmall>
                {getDic("confirmacao-de-acao")}
              </TextSmall>
            </FormRow>
            <FormRow align="center" paddingTop="5px">
              <InputFieldOutlined
                key="password"
                value={dialogConfirmKey}
                onChange={event => setDialogConfirmKey(event.target.value)}
              />
            </FormRow>
            <FormRow align="center" paddingTop="5px">
              <div style={{ padding: 5 }}>
                <Button
                  color="red"
                  onClick={() => {
                    setDialogSendCampaignOpen(false);
                    setDialogConfirmKey("");
                  }}
                >
                  {getDic("cancelar")}
                </Button>
              </div>
              <div style={{ padding: 5 }}>
                <Button
                  onClick={() => sendCampaign(true)}
                >
                  {`${getDic("enviar")} ${getDic("todos")}`}
                </Button>
              </div>
              <div style={{ padding: 5 }}>
                <Button
                  color="green"
                  onClick={() => sendCampaign(false)}
                >
                  {`${getDic("enviar")} ${getDic("nao")} ${getDic("recebidos")}`}
                </Button>
              </div>
            </FormRow>
            {dialogConfirmKeyErrorMessage ? (
              <TextSmall color="red">{getDic("erro")}</TextSmall>
            ) : (null)}
          </Dialog>

          <Loading open={openLoading} message={loadingMessage} />
        </FormRow>
      </FormColumn>
    </Container >
  )
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
    chosenCampaign: state.chosenCampaign,
  }
}

const mapDispatchToProps = {
  resetChosenCampaign
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsRegisterPage);
