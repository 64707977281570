export function getFileName(file) {
    var fileType = file.name.split(/\.(?=[^\.]+$)/);
    return fileType[0];
}

export function getFileExtension(file) {
    var fileType = file.name.split(/\.(?=[^\.]+$)/);
    var returnString = "";
    if (fileType[1]) {
        returnString = fileType[1].toUpperCase();
    }
    return returnString;
}

export function convertFileSizeFromBytesToMb(file) {
    var fileSize = 0;
    if (file && file.size && file.size > 0) {
        fileSize = file.size / Math.pow(1024, 2);
    }
    return fileSize;
}

export function convertSerialDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}
