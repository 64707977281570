import { BlipChat } from "blip-chat-widget";
import * as configModel from '../models/configModel';

let isUsing = false;

export default {
  init(config = configModel.createConfigModel()) {
    try {
      if (isUsing) { return }

      isUsing = true;
      let _params = getConfigParams();

      new BlipChat()
        .withAppKey(_params.appKey)
        .withButton({ "color": _params.color, "icon": _params.icon })
        .withCustomCommonUrl(_params.commonUrl)
        .build();

    } catch (error) {
      console.log('Error init', error);
      throw error;
    }

    function getConfigParams() {
      let _params = {
        appKey: 'YWNoZTIwMjQ6ZTllZDJmY2ItZmJmNy00NGQxLWEwNzktMzdiYTAxOWZkMDNh',
        color: "#d9166e",
        icon: '',
        commonUrl: 'https://inteegraeventos.chat.blip.ai/',
      }

      config.url.forEach((conf) => {
        var _conf = conf.split(/:(.+)/);
        _params[_conf[0]] = _conf[1];
      });

      return _params;
    }
  }
}
