import axios from 'axios';
import * as apiService from './apiService';

export async function sendEmailApi(emailTo = '', subject = '', htmlContent = '') {
  try {
    let _token = await apiService.getToken()
    const _options = {
      url: `${apiService.getUrl().externalApiBase}email/api/emails`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${_token}`
      },
      data: {
        to: [emailTo],
        from: "naoresponder@inteegra.com.br",
        subject: subject,
        htmlContent: htmlContent,
      },
    };

    await axios(_options);

  } catch (error) {
    console.log('Error sendEmailApi', error);
    throw error;
  }
}
