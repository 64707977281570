import React from 'react';
import { store } from "../store";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';

const CheckboxProp = props => {
  const {
    checked = false,
    textColor = "black",
    size = null,
    label = "Start",
    text = "",
    textPosition = "start",
    onChange,
    onClick,
    fontSize = "14px",
    fontWeight = "none",
    disabled = false,
  } = props;

  const [state, setState] = React.useState(true);
  const [styles, setStyles] = React.useState({
    backgroundColor: "white",
    borderColor: "gray",
    checkedColor: "#3f51b5",
    textColor: "white"
  });

  React.useEffect(() => {
    changeButtonConfig();
  }, []);

  const changeButtonConfig = () => {
    try {
      var _config = store.getState().config.find(c => c.codeScreen === "CHECK-BOX-CONFIG");
      if (!_config) return;

      var _styles = styles;
      _config.url.forEach(conf => {
        const _conf = conf.split(":");
        if (_styles.hasOwnProperty(_conf[0])) {
          _styles[_conf[0]] = _conf[1];
        }
      });
      setStyles(_styles);
      setState(!state);
    } catch (error) {
      console.log("Erro changeButtonConfig", error);
    }
  }

  const changeChecked = () => {
    if (onClick) {
      onClick();
    }
    if (onChange) {
      onChange(!checked);
    }
  }

  if (text && text != "") {
    return (
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="top"
            control={
              <div style={{ paddingLeft: 10 }}>
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    background: checked ? styles.checkedColor : styles.backgroundColor,
                    borderRadius: 3,
                    border: `1px solid ${styles.borderColor}`,
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={changeChecked}
                >
                  <CheckIcon fontSize="small" style={{ fontWeight: "bold", color: checked ? styles.textColor : styles.backgroundColor }} />
                </div>
              </div>
            }
            label={
              <Typography
                style={{
                  color: textColor,
                  fontSize: fontSize,
                  fontFamily: "verdana",
                  fontWeight: fontWeight
                }}
              >
                {text}
              </Typography>
            }
            labelPlacement={textPosition}
          />
        </FormGroup>
      </FormControl>
    )
  }

  return (
    <div
      style={{
        width: '20px',
        height: '20px',
        background: checked ? styles.checkedColor : styles.backgroundColor,
        borderRadius: 3,
        border: `1px solid ${styles.borderColor}`,
        verticalAlign: 'middle',
        textAlign: 'center',
        cursor: 'pointer'
      }}
      onClick={changeChecked}
    >
      <CheckIcon fontSize="small" style={{ fontWeight: "bold", color: checked ? styles.textColor : styles.backgroundColor }} />
    </div>
  )
};

export default CheckboxProp;
