import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { ActivityIndicator, FormRow } from '../components';

const Loading = props => {
    const {
        open,
        message
    } = props;

    return (
        <Dialog
            style={{ zIndex: "99999" }}
            open={open}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
                <FormRow align="center">
                    <ActivityIndicator />
                </FormRow>
            </DialogContent>

        </Dialog>
    )
};

export default Loading;
