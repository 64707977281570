import React from 'react';

const CardProgramacao = props => {

  const [color, setColor] = React.useState("#FFF")
  const [cursor, setCursor] = React.useState("normal")

  //add style to hover programacao
  const programacaoCardContainer = (color, cursor) => {
    return {
      display: "flex",
      flexDirection: "row",
      height: "auto",
      width: "100%",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "2%",
      backgroundColor: color,
      marginBottom: "2%",
      borderRadius: "5px",
      cursor: cursor,
    }
  };

  return (
    // <div  className="programacao-card-container"  onClick = {() => {
    <div style={programacaoCardContainer(color, cursor)}
      onMouseOver={() => {
        setColor(props?.color)
        setCursor("pointer")
      }}
      onMouseLeave={() => {
        setColor("#FFF")
        setCursor("normal")
      }}
      onClick={() => {
        window.postMessage({
          message: 'transmissionChangeScheduleAndCloseModal',
          scheduleId: props.id
        }, '*');
      }}>
      <div className="programacao-card-horario" >
        <div>{props.inicio}</div>
        <div>{props.fim}</div>
      </div>
      <div className="programacao-card-descricao" >
        <div>{props.descricao}</div>
      </div>
    </div >
  )
};

export default CardProgramacao;
