function CSSTextGenerator(theme) {

    return `      
      --content-color-background: ${theme.contentColorBackground};
      --background-image: url("${theme.backgroundImage}");
  `;
}

function setGlobalCSS(css) {
    document.documentElement.style.cssText = css;
}

export function setNewTheme(theme) {
    var newTheme = CSSTextGenerator(theme);
    setGlobalCSS(newTheme);
}

export function setMobileBackgroundImage(image) {
    var newImage = `--mobile-screen-background: url("${image}");`;
    document.documentElement.style.cssText = newImage;
}
