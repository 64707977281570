import React from "react";
import TestPageContext from './TestPageContext';
import { connect } from "react-redux";

const TestPageProvider = (props) => {

    const [testState, setTestState] = React.useState(123);

    const testFunction = () => {
        document.body.requestFullscreen()
            .then(res => {
                // window.screen.orientation.lock('landscape')
                //     .then(res => console.log(res))
                //     .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    }

    return (
        <TestPageContext.Provider value={{
            state: {
                testState: testState, setTestState,
            },
            functions: {
                testFunction,
            }
        }}>
            {props.children}
        </TestPageContext.Provider>
    );
}

function mapStateToProps(state) {
    return {
        event: state.event,
    };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPageProvider);
