import React from 'react';
import { getDic } from "../../assets/i18n/dictionary";
import * as raffleModel from '../../models/raffleModel';
import * as navigationHistoryService from "../../services/navigationHistoryService";
import {
  FormRow,
  Button,
} from "../../components";

const CommandButtons = props => {
  const {
    modalRaffleRegisterControl,
    setModalRaffleRegisterControl,
    openModalBlocklist,
    history,
    event,
    exportExcelAllRafflesWinnersList,
  } = props;

  return (
    <FormRow
      paddingTop="50px"
      minHeight="50px"
      align="center"
      margin="0px"
    >
      <div style={{ padding: "5px", maxWidth: "400px" }}>
        <Button
          color="green"
          onClick={() => {
            setModalRaffleRegisterControl({
              ...modalRaffleRegisterControl,
              open: true,
              raffle: raffleModel.createRaffleModel()
            });
          }}
        >
          {`${getDic("cadastrar")} ${getDic("novo")}`}
        </Button>
      </div>
      <div style={{ padding: "5px", maxWidth: "400px" }}>
        <Button
          color="black"
          onClick={() => openModalBlocklist()}
        >
          {`${getDic("lista")} ${getDic("de")} ${getDic("bloqueio")}`}
        </Button>
      </div>
      <div style={{ padding: "5px", maxWidth: "400px" }}>
        <Button
          onClick={() => navigationHistoryService.setHistory(history, `/${event.id}/rafflepresentation`)}
        >
          {`${getDic("pagina")} ${getDic("de")} ${getDic("apresentacao")}`}
        </Button>
      </div>
      <div style={{ padding: "5px", maxWidth: "400px" }}>
        <Button
          color="#53995a"
          onClick={() => exportExcelAllRafflesWinnersList()}
        >
          {`${getDic("exportar")} Excel ${getDic("vencedores")}`}
        </Button>
      </div>
    </FormRow>
  )
};

export default CommandButtons;
