import axios from "axios";
import { getUrl, getToken } from "./apiService";
import { store } from "../store";
import { createCalendarModel, createLocationModel } from '../models/calendarModel';
import moment from "moment-timezone";

export async function getCalendarListApi(eventId) {
    var method = getUrl().base + "agenda/api/appointments/" + (eventId ? eventId : store.getState().event.id.toString());
    const _token = await getToken();

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + _token
            }
        };
        axios(options)
            .then(res => {
                const _calendarList = [];
                if (res.data && res.data.success && res.data.data && res.data.data.result) {
                    res.data.data.result.forEach(item => {
                        if (item.active) {
                            // Cria objeto de localização
                            var _location = null;
                            if (item.location) {
                                _location = createLocationModel(
                                    item.location.id,
                                    item.location.description,
                                    item.location.zipCode,
                                    item.location.address,
                                    item.location.number,
                                    item.location.district,
                                    item.location.complement,
                                    item.location.city,
                                    item.location.state,
                                    item.location.country,
                                );
                            }

                            const _calendar = createCalendarModel(
                                item.id,
                                item.eventId,
                                item.startDate,
                                item.endDate,
                                item.title,
                                item.description,
                                item.typeId,
                                item.type ? item.type.description : null,
                                _location
                            );

                            _calendarList.push(_calendar);
                        }
                    });
                }

                //Ordenar por data
                const _ret = _calendarList.sort(function (a, b) {
                    return b.startDate < a.startDate;
                });

                resolve(_ret);
            })
            .catch(err => {
                console.log("Erro getCalendarListApi", err);
                reject(err);
            });
    });
}

export async function addNewCalendarApi(calendar) {
    var method = getUrl().base + "agenda/api/appointments";
    const _token = await getToken();

    const _calendar = {
        eventId: store.getState().event.id,
        startDate: calendar.startDate,
        endDate: calendar.endDate,
        title: calendar.title,
        description: calendar.description,
        type: {
            description: calendar.type,
        },
        location: {
            description: calendar.location.descriptionLocation,
            zipCode: calendar.location.zipCode,
            address: calendar.location.address,
            number: calendar.location.number,
            district: calendar.location.district,
            complement: calendar.location.complement,
            city: calendar.location.city,
            state: calendar.location.state,
            country: calendar.location.country,
        }
    }

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + _token,
            },
            data: _calendar,
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    var _objReturn = calendar;
                    _objReturn.id = res.data.data;
                    resolve(_objReturn);
                } else {
                    reject("Erro interno ao salvar");
                }
            })
            .catch((err) => {
                console.log("Erro addNewCalendarApi", err);
                reject(err);
            });
    });
}

export async function updateCalendarApi(calendar) {
    var method = getUrl().base + "agenda/api/appointments";
    const _token = await getToken();

    const _calendar = {
        id: calendar.id,
        eventId: store.getState().event.id,
        startDate: calendar.startDate,
        endDate: calendar.endDate,
        title: calendar.title,
        description: calendar.description,
        type: {
            id: calendar.typeId,
            description: calendar.type,
        },
        location: {
            id: calendar.location.idLocation,
            description: calendar.location.descriptionLocation,
            zipCode: calendar.location.zipCode,
            address: calendar.location.address,
            number: calendar.location.number,
            district: calendar.location.district,
            complement: calendar.location.complement,
            city: calendar.location.city,
            state: calendar.location.state,
            country: calendar.location.country,
        }
    }

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + _token,
            },
            data: _calendar,
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    resolve(calendar);
                } else {
                    reject("Erro interno ao salvar");
                }
            })
            .catch((err) => {
                console.log("Erro updateCalendarApi", err);
                reject(err);
            });
    });
}

export async function deleteCalendarApi(calendarId) {
    var method = getUrl().base + "agenda/api/appointments/" + calendarId.toString();
    const _token = await getToken();

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + _token,
            },
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    resolve(true);
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log("Erro deleteCalendarApi", err);
                reject(err);
            });
    });
}

export async function convertCalendarObjectToGoogleCalendarLink(calendar) {
    const _startTime = moment(calendar.startDate).format("YYYYMMDDTHHmmss");
    const _endTime = moment(calendar.endDate).format("YYYYMMDDTHHmmss");

    const _url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${calendar.title ? calendar.title : ""}&ctz=America/Sao_Paulo&dates=${_startTime}/${_endTime}&details=${calendar.description ? calendar.description : ""}&location=${calendar.location ? getLocationString(calendar.location) : ""}&trp=false&sprop&sprop=name:&sf=true&output=xml#main_7`;

    return _url;
}

export async function convertCalendarObjectToIcsFile(calendar) {
    var _event = getEventObject();

    if (calendar) {
        try {
            _event.title = calendar.title ? calendar.title : "";
            _event.description = calendar.description ? calendar.description : "";
            _event.location = calendar.location ? getLocationString(calendar.location) : "";
            _event.start = calendar.startDate ? moment(calendar.startDate).format() : "";
            _event.end = calendar.endDate ? moment(calendar.endDate).format() : "";
        } catch (error) {
            console.log("Erro convertCalendarObjectInIcsFile", error);
        }
    }
    const _ics = await createIcsEventStringFromObject(_event);
    downloadIcsFile(_ics, calendar.title);
    return;
}

export async function convertCalendarObjectToIcsOutlookFile(calendar) {
    var _event = getEventObject();

    if (calendar) {
        try {
            _event.title = calendar.title ? calendar.title : "";
            _event.description = calendar.description ? calendar.description : "";
            _event.location = calendar.location ? getLocationString(calendar.location) : "";
            _event.start = calendar.startDate ? moment(calendar.startDate).format() : "";
            _event.end = calendar.endDate ? moment(calendar.endDate).format() : "";
        } catch (error) {
            console.log("Erro convertCalendarObjectInIcsFile", error);
        }
    }
    const _ics = await createIcsEventStringFromObject(_event);
    downloadIcsFile(_ics, calendar.title);
    return;
}

export function getEventObject() {
    return {
        title: "",
        description: "",
        location: "",
        start: "",
        end: "",
        startInputType: "utc",
        endInputType: "utc",
    }
}

function createIcsEventStringFromObject(eventObj) {
    return new Promise((resolve, reject) => {
        const ics = require("ics");
        ics.createEvent(eventObj, function (error, value) {
            if (error) {
                console.log(error);
            }

            var _dateStartISO = moment(eventObj.start).toISOString();
            _dateStartISO = _dateStartISO.replace(/-/g, '').replace(/:/g, '').replace(/./g, '');

            const finalCalendar =
                `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
PRODID:adamgibbons/ics
METHOD:PUBLISH
X-PUBLISHED-TTL:PT1H
BEGIN:VTIMEZONE
TZID:America/Sao_Paulo
TZURL:http://tzurl.org/zoneinfo-outlook/America/Sao_Paulo
X-LIC-LOCATION:America/Sao_Paulo
BEGIN:DAYLIGHT
TZOFFSETFROM:-0300
TZOFFSETTO:-0300
TZNAME:-03
DTSTART:16011104T000000
RRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU
END:DAYLIGHT
BEGIN:STANDARD
TZOFFSETFROM:-0300
TZOFFSETTO:-0300
TZNAME:-03
DTSTART:16011104T000000
RRULE:FREQ=YEARLY;BYMONTH=2;BYDAY=3SU
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
DTSTAMP:${_dateStartISO}
UID:${eventObj.uid}
DTSTART;TZID=America/Sao_Paulo:${moment(eventObj.start).format('YYYYMMDDTHHmmss')}
DTEND;TZID=America/Sao_Paulo:${moment(eventObj.end).format('YYYYMMDDTHHmmss')}
LOCATION:${eventObj.location}
SUMMARY:${eventObj.title}
DESCRIPTION:${eventObj.description}
END:VEVENT
END:VCALENDAR`;

            resolve(finalCalendar);
        });
    });
};

function getLocationString(locationObj) {
    var _stringLocation = locationObj.address;
    _stringLocation += locationObj.number && locationObj.number != "" && locationObj.number != "<string>" ? `, ${locationObj.number}` : "";
    _stringLocation += locationObj.complement && locationObj.complement != "" && locationObj.complement != "<string>" ? ` ${locationObj.complement}` : "";
    _stringLocation += locationObj.district && locationObj.district != "" && locationObj.district != "<string>" ? `, ${locationObj.district}` : "";
    _stringLocation += locationObj.zipCode && locationObj.zipCode != "" && locationObj.zipCode != "<string>" ? ` - ${locationObj.zipCode}` : "";
    _stringLocation += locationObj.city && locationObj.city != "" && locationObj.city != "<string>" ? ` - ${locationObj.city}` : "";
    _stringLocation += locationObj.state && locationObj.state != "" && locationObj.state != "<string>" ? `, ${locationObj.state}` : "";
    _stringLocation += locationObj.country && locationObj.country != "" && locationObj.country != "<string>" ? ` - ${locationObj.country}` : "";
    _stringLocation += locationObj.descriptionLocation && locationObj.descriptionLocation != "" && locationObj.descriptionLocation != "<string>" ? ` - ${locationObj.descriptionLocation}` : "";
    return _stringLocation;
}

function downloadIcsFile(icsEventObject, title) {
    var dataStr = encodeURI("data:text/calendar;charset=utf8," + icsEventObject.split("\n").join("\n"));
    let a = document.createElement("a");
    a.href = dataStr;
    a.target = "_blank";
    a.download = `${title}.ics`;
    a.click();
}
