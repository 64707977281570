import React from 'react';
import { WordCloud } from "../";

const WordCloudC = props => {
  const {
    wordsArray,
    timeWordCloud,
    windowWidth
  } = props;

  const styles = {
    position: "absolute"
  }

  return React.useMemo(() => {
    return (
      <WordCloud
        wordsArray={wordsArray} // Array de strings
        largerWordSize={30}
        minimalWordSize={10}
        padding={12}
        width="100%"
        height={windowWidth > 925 ? "100%" : "190px"}
      />
    );
  }, [wordsArray, timeWordCloud]);
};

export default WordCloudC;
