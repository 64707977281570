import { createFormControlModel } from './formControlModel';

export function createWhatsappModel(
    id = 0,
    templateName = "",
    countryNumber = "",
    mobileNumber = "",
    url = "",
    tag1 = "",
    tag2 = "",
    tag3 = "",
    tag4 = "",
    tag5 = "",
    tag6 = "",
    tag7 = "",
    tag8 = "",
    tag9 = "",
    tag10 = "",
) {
    const _obj = {
        id: id,
        templateName: templateName,
        countryNumber: countryNumber,
        mobileNumber: mobileNumber,
        url: url,
        tag1: tag1,
        tag2: tag2,
        tag3: tag3,
        tag4: tag4,
        tag5: tag5,
        tag6: tag6,
        tag7: tag7,
        tag8: tag8,
        tag9: tag9,
        tag10: tag10,
    }

    return _obj;
}

export function convertWhatsappObjectToForm(whatsapp, groupList, speakersList) {
    const _form = [];

    var _index = 0;

    for (var key in whatsapp) {
        if (whatsapp.hasOwnProperty(key)) {

            var _hasForm = false;
            var _type = "text";
            var _options = null;
            var _required = true;
            var _name = "";
            var _value = whatsapp[key] ? whatsapp[key].toString() : null;

            switch (key.toString()) {
                case "id":
                    _hasForm = true;
                    _name = "id";
                    _required = true;
                    _value = parseInt(whatsapp[key]);
                    break;
                case "templateName":
                    _hasForm = true;
                    _name = "Template";
                    _required = true;
                    break;
                case "countryNumber":
                    _hasForm = true;
                    _name = "DDI";
                    _required = true;
                    _value = _value ? _value : "55";
                    break;
                case "mobileNumber":
                    _hasForm = true;
                    _name = "Telefone (Com DDD)";
                    _required = true;
                    break;
                case "url":
                    _hasForm = true;
                    _name = "URL da Imagem/Arquivo";
                    _required = false;
                    break;
                case "tag1":
                    _hasForm = true;
                    _name = "Tag 1";
                    _required = false;
                    break;
                case "tag2":
                    _hasForm = true;
                    _name = "Tag 2";
                    _required = false;
                    break;
                case "tag3":
                    _hasForm = true;
                    _name = "Tag 3";
                    _required = false;
                    break;
                case "tag4":
                    _hasForm = true;
                    _name = "Tag 4";
                    _required = false;
                    break;
                case "tag5":
                    _hasForm = true;
                    _name = "Tag 5";
                    _required = false;
                    break;
                case "tag6":
                    _hasForm = true;
                    _name = "Tag 6";
                    _required = false;
                    break;
                case "tag7":
                    _hasForm = true;
                    _name = "Tag 7";
                    _required = false;
                    break;
                case "tag8":
                    _hasForm = true;
                    _name = "Tag 8";
                    _required = false;
                    break;
                case "tag9":
                    _hasForm = true;
                    _name = "Tag 9";
                    _required = false;
                    break;
                case "tag10":
                    _hasForm = true;
                    _name = "Tag 10";
                    _required = false;
                    break;
                default:
                    break;
            }

            if (_hasForm) {
                const _obj = createFormControlModel(
                    _index,
                    _name,
                    _value,
                    _type,
                    null,
                    null,
                    _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                    _required,
                    null,
                    null,
                    null,
                    key
                );
                _form.push(_obj);
                _index++;
            }
        }
    }
    return _form;
}

export function convertFormToWhatsappObject(form) {
    var _config = createWhatsappModel();
    form.forEach(item => {
        var _value;

        switch (item.dbReference) {
            case "id":
                _value = item.value ? parseInt(item.value) : null;
                break;
            case "templateName":
                _value = item.value ? item.value.toString() : null;
                break;
            case "countryNumber":
                _value = item.value ? item.value.toString() : null;
                break;
            case "mobileNumber":
                _value = item.value ? item.value.toString() : null;
                break;
            case "url":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tag1":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tag2":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tag3":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tag4":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tag5":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tag6":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tag7":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tag8":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tag9":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tag10":
                _value = item.value ? item.value.toString() : null;
                break;
            default:
                break;
        }
        _config[item.dbReference] = _value;
    });
    return _config;
}
