import { SET_NOTIFICATION } from '../actions';

const INITIAL_STATE = { notification: true };

export default function notificationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_NOTIFICATION:
            return action.notification;
        default:
            return state;
    }
}