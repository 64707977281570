import { store } from "../store";
import * as actions from "../store/actions";

export function setHistory(history, path = '') {
  try {
    store.dispatch(actions.setHistory(path));
    history.replace(path);
  } catch (error) {
    console.log('Error setHistory', error);
  }
}

export function goBackHistory(history) {
  try {
    let _length = store.getState().history.length - 2;
    let _path = store.getState().history.find((h, i) => {
      if (i === _length) {
        return h;
      }
    });
    store.dispatch(actions.goBackHistory());

    if (_path) {
      history.replace(_path);
    }
  } catch (error) {
    console.log('Error setHistory', error);
  }
}
