import axios from "axios";
import { getUrl, getToken } from "./apiService";

export function getBase64ByURL(url) {
  return new Promise((resolve, reject) => {
    const method = getUrl().hall + "event/converts/getBase64?url=" + url;

    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro getBase64ByURL", err);
        reject(err);
      });
  });
}
