export const SET_LANGUAGE = 'SET_LANGUAGE';
const setLanguageLocal = lang => ({
    type: SET_LANGUAGE,
    lang,
});
export function setLanguage(lang) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setLanguageLocal(lang));
            resolve(true);
        });
    };
}
