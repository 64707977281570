import React from "react";
import { getDic } from "../../assets/i18n/dictionary";
import { connect } from "react-redux";
import "firebase/firestore";
import "firebase/auth";
import exportFromJSON from "export-from-json";
import { firestore } from "./../../utils/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Container, Row, Col, Spinner } from "reactstrap";
import ContainerProp from "../../components/Container";
import {
  getQuestionsApi,
  getAnswersListApi,
  addNewSurvey,
  getQuestionsFromSurvey,
} from "../../services/questionsService";
import ModalSurveyList from "./ModalSurveyList";
import * as questionsService from "../../services/questionsService";
import {
  MenuButton,
  BackButton,
  Modal,
  GridCell,
  GridRow,
  TextMedium,
  FormRow,
} from "../../components";

import "./assets_network/scss/theme.scss";
import Blacklist from "./Blacklist";

const RankingSurvey = (props) => {
  const [blackList, addBlackList] = React.useState(false);
  const [modalCreateSurvey, setModalCreateSurvey] = React.useState(false); //controle do modal (aberto/fechado)
  const [questions, setQuestions] = React.useState([]); //aqui carregamos a lista de questões vinda do DB
  const [categories, setCategories] = React.useState([]); //aqui carregamos a lista de categorias vinda do DB
  const [inputsQuestions, setInputsQuestions] = React.useState([0]);
  const [loadingSavingSurvey, setLoadingSavingSurvey] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [loadingID, setLoadingID] = React.useState("");
  const [questionsHelp, setQuestionsHelp] = React.useState([]);
  const [modalBG, setModalBG] = React.useState("");
  const [blackListForm, enableBlackListForm] = React.useState(false);
  const [surveyDetails, setSurveyDetails] = React.useState({
    index: 0,
    enable: false,
  });
  const [modalRankingControl, setModalRankingControl] = React.useState({
    open: false,
    list: [],
  });
  //paginação
  const [currentPage, setCurrentPage] = React.useState(1);
  //
  const surveyFirebase = firestore.collection(
    `surveyRanking/${props.event.id}/survey`
  );

  const [surveys] = useCollectionData(surveyFirebase, { idField: "id" });

  const searchUserAnswer = async (surveyId, userId) => {
    const answer = await getAnswersListApi(
      props.user.eventId,
      1,
      500000000,
      null,
      null,
      null,
      null,
      null,
      userId
    );

    const { awnswers } = answer;
    return awnswers.filter((e) => processLogin(e.answerLogin) == userId);
  };
  const loadQuestions = async () => {
    const questions = await getQuestionsApi(props.user.eventId);
    setQuestions(questions.questions);
    setCategories(questions.categories);
    console.log(questions);
  };
  const toDate = (date, hour) => {
    let answerDate = date.split("/");
    let answerHour = hour;
    let finalData = new Date(
      `${answerDate[2]}/${answerDate[1]}/${answerDate[0]} ${answerHour}`
    );
    return finalData;
  };

  const processLogin = (p) => {
    return p.split("(")[1].replaceAll(")", "").replaceAll(" ", "").toString();
  };
  const processAllAwnswer = async (e) => {
    setLoadingID(e.id);
    //
    const { questions } = e;
    let awnsersFinal = [];
    let maxScore = 0;
    let { data: questionFromSurveyMice } = await getQuestionsFromSurvey(
      e.surveyId
    );

    //seleciona só as perguntas que fazem parte da enquete
    for (let s = 0; s < questions.length; s++) {
      /* const answer = await getAnswersListApi(
        props.user.eventId,
        1,
        500000000,
        null,
        questions[s].question.id
      );
      const { awnswers } = answer; */
      maxScore += parseInt(questions[s].score);
      awnsersFinal = [
        ...awnsersFinal,

        ...[
          ...questionFromSurveyMice
            .filter((e) => e.questionId == questions[s].question.id)
            .filter(
              (e) =>
                e.questionId == questions[s].question.id &&
                questions[s].answer.indexOf(e.answer) > -1
            )
            .filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.guestId === value.guestId
                  /* processLogin(t.answerLogin) ===
                    processLogin(value.answerLogin) */
                )
            ),
        ],
      ].map((e) => {
        return {
          ...e,
          score: parseInt(questions[s].score),
          currentData:
            /* toDate(e.answerDate, e.answerHour).getTime() - */
            new Date(e.createdAt).getTime() -
            new Date("01/18/2020 00:00:00").getTime(),
        };
      });
    }

    let geral = [];
    awnsersFinal.map((s) => {
      let search = geral.findIndex((f) => f.guestId == s.guestId);

      if (search == -1) {
        geral.push({
          ...s,
        });
      } else {
        geral[search].score += s.score;
        geral[search].currentData += s.currentData;
      }
    });

    let arraySorted = geral.sort((a, b) => b.score - a.score);
    geral = geral;

    /* .filter((e) => e.score >= arraySorted[0].score)
      .sort((a, b) => a.currentData - b.currentData); */
    //se escolher o modo não repetir ganhadores
    let treatment = [];
    let referenceArrays = surveys.filter((s) => s.id != e.id);
    //await Promise.All(
    referenceArrays.map(async (ref, refIndex) => {
      const checkDisabled = await surveyFirebase.doc(ref.id).get();
      if (!checkDisabled.data().enabled) return;

      const checkUser = await surveyFirebase
        .doc(ref.id)
        .collection("surveyList")
        .where("rewarded", "==", true)
        .get();
      checkUser.docs.map((r) => {
        treatment = geral.map((g, gi) => {
          let finalItem = geral;
          if (g.guestId == r.data().guestId) {
            geral = finalItem.filter((eg) => eg.guestId != g.guestId);
          }
          return finalItem;
        });
      });
    });

    //);
    /* geral.map((item, index) => {
      referenceArrays.map(async (ref, iref) => {
        const checkUser = await surveyFirebase
          .doc(ref.id)
          .collection("surveyList")
          .where("reward", "==", true)
          .get();
        console.log(checkUser);
      });
    }); */

    //fim do tratamento
    const allList = await surveyFirebase
      .doc(e.id)
      .collection("surveyList")
      .get();
    await Promise.all(
      allList.docs.map(async (qt) => {
        await surveyFirebase
          .doc(e.id)
          .collection("surveyList")
          .doc(qt.id)
          .delete();
      })
    );
    //AQUI ATIVA O BLOQUEIO DE REPETIDOS
    /* let tempGeral = geral.sort(
      (a, b) => b.score - a.score || a.currentData - b.currentData
    );
    for (let i = 0; i < e.rewardCount; i++) {
      if (tempGeral[i]) tempGeral[i].rewarded = true;
    }
    geral = tempGeral; */
    await Promise.all(
      geral.map(async (surv) => {
        await surveyFirebase
          .doc(e.id)
          .collection("surveyList")
          .add({
            ...surv,
          });
      })
    );
    await surveyFirebase.doc(e.id).update({
      lastGeneratedList: new Date(),
    });

    alert("Perguntas processadas com sucesso!");
    setLoadingID("");
  };
  const removeFromBlacklist = async (_i) => {
    const blacklistFirebase = firestore
      .collection(`surveyRanking`)
      .doc(`${props.event.id}`);
    firestore
      .runTransaction((t) => {
        return t.get(blacklistFirebase).then((doc) => {
          let currentArray = doc.data().blacklist;

          const newUserArray = [...currentArray.filter((e, i) => i != _i)];
          t.set(
            blacklistFirebase,
            { blacklist: newUserArray },
            { merge: true }
          );
        });
      })
      .catch(console.log);
  };
  const addUserToBlacklist = async (_user) => {
    const blacklistFirebase = firestore
      .collection(`surveyRanking`)
      .doc(`${props.event.id}`);
    if (_user) {
      if (_user.indexOf(";") > -1) {
        _user = _user.split(";").map((e) => {
          return {
            user: e,
            addedAt: new Date(),
          };
        });
      } else {
        _user = [
          {
            user: _user,
            addedAt: new Date(),
          },
        ];
      }
      firestore
        .runTransaction((t) => {
          return t.get(blacklistFirebase).then((doc) => {
            if (!doc?.data()?.blacklist) {
              t.set(
                blacklistFirebase,
                {
                  blacklist: [..._user],
                },
                { merge: true }
              );
            } else {
              const newUserArray = [...doc.data().blacklist, ..._user];
              t.set(
                blacklistFirebase,
                { blacklist: newUserArray },
                { merge: true }
              );
            }
          });
        })
        .catch(console.log);
    }
  };
  const getSurveyResults = async (_id) => {
    let docs = [];

    let surveyResults = await surveyFirebase
      .doc(_id)
      .collection("surveyList")
      .get();

    const getBlockedUsers = await firestore
      .collection(`surveyRanking`)
      .doc(`${props.event.id}`)
      .get();

    surveyResults.docs.map((e) => {
      if (
        getBlockedUsers.exists &&
        getBlockedUsers.data().blacklist &&
        !getBlockedUsers
          .data()
          .blacklist.find((b) => e.data().guestId == b.user)
      )
        docs.push(e.data());

      if (!getBlockedUsers.exists) docs.push(e.data());
    });
    generateExcell(docs);
  };
  const getSurveyForModal = async (_id) => {
    let docs = [];

    let surveyResults = await surveyFirebase
      .doc(_id)
      .collection("surveyList")
      .get();

    const getBlockedUsers = await firestore
      .collection(`surveyRanking`)
      .doc(`${props.event.id}`)
      .get();

    surveyResults.docs.map((e) => {
      if (
        getBlockedUsers.exists &&
        getBlockedUsers.data().blacklist &&
        !getBlockedUsers
          .data()
          .blacklist.find((b) => e.data().guestId == b.user)
      )
        docs.push(e.data());

      if (!getBlockedUsers.exists) docs.push(e.data());
    });
    return docs;
  };
  const deleteSurvey = async (e) => {
    var resultado = window.confirm("Deseja desativar a enquete?");
    if (resultado == true) {
      await surveyFirebase.doc(e.id).update({
        enabled: false,
        deletedAt: new Date(),
      });
    }
  };
  const recoverySurvey = async (e) => {
    await surveyFirebase.doc(e.id).update({
      enabled: true,
    });
  };
  const getSurveyRanking = async (surveyId) => {
    try {
      let _ranking = await questionsService.getSurveyRankingById(surveyId);
      let _finalList = [];
      _ranking.forEach((item) => {
        _finalList.push({
          ...item,
          openInfo: false,
        });
      });
      setModalRankingControl({
        open: true,
        list: _finalList,
      });
    } catch (error) {
      alert("Erro ao consultar ranking");
    }
  };
  React.useEffect(() => {
    loadQuestions();
    console.log("button", props);
    var setCustomModalBG = props.config.find((item) => {
      return item.codeScreen === "SURVEY_MODAL_BG";
    });
    if (setCustomModalBG) setModalBG(setCustomModalBG.urlString);
    console.log("render");
  }, [surveys]);
  return (
    <ContainerProp background="#e8eced" align="center">
      <MenuButton history={props.history} />
      <BackButton history={props.history} color="#212121">
        {getDic("voltar").toUpperCase()}
      </BackButton>
      <div
        className="statistic-container page-content"
        style={{ paddingTop: 40, width: "100%" }}
      >
        <Container>
          <Row>
            <Col lg="12">
              {/* LISTA DE ENQUETES */}
              <div
                data-test="datatable"
                className="dataTables_wrapper dt-bootstrap4 mt-5"
              >
                <div className="row">
                  <div
                    data-test="datatable-entries"
                    className="col-sm-10 col-md-10"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      data-test="datatable-select"
                      className="dataTables_length bs-select"
                      style={{ display: "flex" }}
                    >
                      <button
                        class="btn-green-add"
                        style={{
                          backgroundColor: "#f44336",
                          padding: "5px 10px",
                          marginRight: 10,
                        }}
                        onClick={() => addBlackList(true)}
                      >
                        Black List
                      </button>
                    </div>
                    <div
                      data-test="datatable-select"
                      className="dataTables_length bs-select"
                      style={{ display: "flex" }}
                    >
                      <button
                        class="btn-green-add"
                        onClick={() => setModalCreateSurvey(true)}
                      >
                        Cadastrar Enquete
                      </button>
                    </div>
                  </div>
                  <div
                    data-test="datatable-search"
                    className="col-sm-2 col-md-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      data-test="datatable-input"
                      className="mdb-datatable-filter flex-row"
                    >
                      <input
                        className="form-control form-control-sm ml-0 my-1"
                        type="text"
                        placeholder="Pesquisar Enquete"
                        aria-label="Search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div data-test="datatable-table" className="col-sm-12">
                    <div data-test="table" className="table-responsive">
                      <table
                        entries={2}
                        className="table table-bordered table-striped dataTable"
                      >
                        <thead data-test="datatable-head">
                          <tr>
                            <th
                              className="sorting"
                              style={{ textAlign: "center" }}
                            >
                              #
                            </th>
                            <th
                              className="sorting"
                              style={{ textAlign: "center" }}
                            >
                              Enquete
                            </th>
                            <th
                              className="sorting"
                              style={{ textAlign: "center" }}
                            >
                              Perguntas
                            </th>
                            <th
                              className="sorting"
                              style={{ textAlign: "center" }}
                            >
                              Data Criação
                            </th>
                            <th
                              className="sorting"
                              style={{ textAlign: "center" }}
                            >
                              Status
                            </th>
                            <th
                              className="sorting"
                              style={{ textAlign: "center" }}
                            >
                              Ação
                            </th>
                          </tr>
                        </thead>
                        {surveys &&
                          surveys
                            .filter(
                              (e) =>
                                e.surveyName
                                  .toUpperCase()
                                  .indexOf(search.toUpperCase()) > -1
                            )
                            .map((e, i) => (
                              <tbody data-test="table-body" key={i}>
                                <tr>
                                  <td>{e.id}</td>
                                  <td>{e.surveyName}</td>
                                  <td>{e.questions.length}</td>
                                  <td>
                                    {getCurrentTime(e.createdAt.toDate())}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {e.enabled ? (
                                      <span className="enabled-survey">
                                        aberta
                                      </span>
                                    ) : (
                                      <span className="disabled-survey">
                                        desativada
                                      </span>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {e.enabled ? (
                                      <div
                                        style={{
                                          flexDirection: "row",
                                          display: "flex",
                                        }}
                                      >
                                        <i
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Detalhes da Enquete"
                                          class="fas fa-question"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            setSurveyDetails({
                                              index: i,
                                              enable:
                                                surveyDetails.index == i
                                                  ? !surveyDetails.enable
                                                  : true,
                                            })
                                          }
                                        ></i>
                                        <i
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Desativar Enquete"
                                          class="fas fa-trash"
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: 10,
                                          }}
                                          onClick={() => deleteSurvey(e)}
                                        ></i>
                                        {loadingID == e.id ? (
                                          <Spinner
                                            animation="border"
                                            style={{
                                              width: 15,
                                              height: 15,
                                              marginLeft: 10,
                                            }}
                                          />
                                        ) : (
                                          <i
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Calcular Resultados"
                                            class="fas fa-database"
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: 10,
                                            }}
                                            onClick={() => processAllAwnswer(e)}
                                          ></i>
                                        )}
                                        {e.lastGeneratedList && (
                                          <React.Fragment>
                                            {loadingID == e.id ? (
                                              <Spinner
                                                animation="border"
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                  marginLeft: 10,
                                                }}
                                              />
                                            ) : (
                                              <ModalSurveyList
                                                survey={e}
                                                modalBG={modalBG}
                                                id={e.id}
                                                getSurveyForModal={
                                                  getSurveyForModal
                                                }
                                                searchUserAnswer={
                                                  searchUserAnswer
                                                }
                                              />
                                            )}
                                            {loadingID == e.id ? (
                                              <Spinner
                                                animation="border"
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                  marginLeft: 10,
                                                }}
                                              />
                                            ) : (
                                              <i
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Gerar Arquivo Excell"
                                                onClick={() =>
                                                  getSurveyResults(e.id)
                                                }
                                                class="fas fa-file-csv"
                                                style={{
                                                  cursor: "pointer",
                                                  marginLeft: 10,
                                                }}
                                              ></i>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </div>
                                    ) : (
                                      <i
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Recuperar Enquete"
                                        class="fas fa-recycle"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => recoverySurvey(e)}
                                      ></i>
                                    )}

                                    {surveyDetails.index == i &&
                                      surveyDetails.enable && (
                                        <div style={{ marginTop: 10 }}>
                                          <table
                                            entries={2}
                                            className=""
                                            style={{
                                              width: "100%",
                                              borderTop: "1px solid #eff2f7",
                                            }}
                                          >
                                            <thead data-test="datatable-head">
                                              <tr>
                                                <th
                                                  className="sorting"
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Pergunta
                                                </th>
                                                <th
                                                  className="sorting"
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Resposta
                                                </th>
                                                <th
                                                  className="sorting"
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Pontuação
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody data-test="table-body">
                                              {e.questions.map((s, si) => (
                                                <tr key={si}>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {s.question.question}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {s.answer
                                                      ? s.answer
                                                      : s.answerLogin}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {s.score}
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                          <span
                                            style={{
                                              background: "#e8eced",
                                              borderRadius: 10,
                                              padding: "0px 9px",
                                            }}
                                          >
                                            Limite de ganhadores:{" "}
                                            {e.rewardCount}
                                          </span>
                                        </div>
                                      )}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    data-test="datatable-info"
                    className="col-sm-12 col-md-5"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="dataTables_info"
                      role="status"
                      aria-live="polite"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <td
                        style={{
                          fontSize: 13,
                          fontWeight: 600,
                          paddingLeft: 3,
                        }}
                      >
                        Exibindo página {currentPage} de{" "}
                        {surveys && Math.ceil(surveys.length / 5)} com total de{" "}
                        {surveys && surveys.length} enquetes
                      </td>
                    </div>
                  </div>
                  <div
                    data-test="datatable-pagination"
                    className="col-sm-12 col-md-7"
                  >
                    <div className="dataTables_paginate">
                      <ul data-test="pagination" className="pagination">
                        <li
                          data-test="disabled page-item"
                          className={"disabled page-item"}
                        >
                          <a
                            data-test="page-link"
                            aria-label="Previous"
                            className="page-link page-link"
                          >
                            <span>First</span>
                          </a>
                        </li>
                        <li
                          data-test="page-item"
                          className={"active page-item"}
                          style={{ display: "block" }}
                        >
                          <a
                            data-test="page-link"
                            className="page-link page-link"
                          >
                            1
                          </a>
                        </li>
                        <li
                          data-test="page-item"
                          className={"disabled page-item"}
                        >
                          <a
                            data-test="page-link"
                            aria-label="Next"
                            className="page-link page-link"
                          >
                            <span>Last</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* LISTA DE ENQUETES */}
              {/* BLACK LIST MODAL */}
              {blackList && (
                <Blacklist
                  addUserToBlacklist={addUserToBlacklist}
                  removeFromBlacklist={removeFromBlacklist}
                  addBlackList={addBlackList}
                  blackList={blackList}
                  eventId={props.event.id}
                  getCurrentTime={getCurrentTime}
                />
              )}
              {/* END BLACK LIST MODAL */}
              {/* MODAL */}
              {modalCreateSurvey && (
                <div
                  className={`modal ${modalCreateSurvey ? "show" : "fade"}`}
                  style={{ display: "block", background: "#bbb6b65c" }}
                  role="dialog"
                  tabIndex={-1}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Criação de Enquete</h4>
                        <button
                          type="button"
                          className="close"
                          aria-label="Close"
                          onClick={() => setModalCreateSurvey(false)}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form
                          tabIndex={-1}
                          onSubmit={async (e) => {
                            e.preventDefault();

                            let formData = e.target.elements;
                            let questionInputCount = 1;
                            let questionsArray = [];
                            let surveyName = "";
                            let rewardCount = 0;
                            let noRepeatWiner = false;
                            setLoadingSavingSurvey(true);
                            for (let l = 0; l < formData.length; l++) {
                              if (
                                formData[l].tagName == "INPUT" ||
                                formData[l].tagName == "SELECT"
                              ) {
                                if (l == 0) {
                                  surveyName = formData[l].value;
                                } else if (l == 1) {
                                  console.log(formData[1]);
                                  noRepeatWiner = true;
                                } else if (l == 2) {
                                  rewardCount = formData[2].value;
                                } else {
                                  if (l == questionInputCount + 2) {
                                    questionsArray.push({
                                      question: JSON.parse(formData[l].value),
                                      score: formData[l + 1].value,
                                      answer: formData[l + 2].value,
                                    });
                                    questionInputCount += 3;
                                  }
                                }
                              }
                            }

                            let micePayload = {
                              eventId: props.event.id,
                              description: surveyName,
                              questionIds: questionsArray.map((e) => {
                                return e.question.id;
                              }),
                            };
                            //aqui cria no banco do mice também o survey
                            let surveyMice = await addNewSurvey(micePayload);
                            if (surveyMice.data.success) {
                              await surveyFirebase.add({
                                surveyName: surveyName,
                                rewardCount: rewardCount,
                                questions: questionsArray,
                                enabled: true,
                                createdAt: new Date(),
                                surveyId: surveyMice.data.data,
                              });
                            }

                            //console.log("valorDoMice", micePayload);
                            setLoadingSavingSurvey(false);
                            setModalCreateSurvey(false);
                          }}
                        >
                          <table
                            className="table table-responsive w-100 d-block d-md-table"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <tbody style={{ borderWidth: 0 }}>
                              <tr>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="survey-name"
                                  defaultValue=""
                                  placeholder="Nome da Enquete"
                                />
                              </tr>
                              <tr>
                                <br />
                              </tr>
                              <tr
                                style={{
                                  display: "none",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id="horns"
                                  name="horns"
                                  value={true}
                                  checked={true}
                                />
                                <div className="col-md-12">
                                  <span className="">
                                    Não repetir ganhadores
                                  </span>
                                </div>
                              </tr>
                              <tr>
                                <br />
                              </tr>
                              <tr
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div className="col-md-2">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    required
                                  />
                                </div>
                                <div
                                  className="col-md-10"
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    display: "flex",
                                  }}
                                >
                                  <span className="">Limite de Ganhadores</span>
                                </div>
                              </tr>
                              {/* <tr>
                                <input
                                  type="checkbox"
                                  id="horns"
                                  name="horns"
                                  onChange={(e) =>
                                    enableBlackListForm(!blackListForm)
                                  }
                                />
                                <span className="">
                                  Carregar lista de excluídos
                                </span>
                              </tr> */}
                              <tr>
                                <br />
                              </tr>
                              <tr>
                                {blackListForm && (
                                  <textarea
                                    id="story"
                                    name="story"
                                    rows="5"
                                    cols="33"
                                    placeholder="Informe a lista de usuários "
                                    className="form-control"
                                    style={{ width: "100%" }}
                                  ></textarea>
                                )}
                              </tr>
                              <tr>
                                <br />
                              </tr>
                              <tr>
                                <span className="">Lista de Perguntas</span>
                              </tr>
                              <tr>
                                <br />
                              </tr>
                              <tr>
                                {inputsQuestions.map((e, i) => (
                                  <div className="row" key={i}>
                                    <div className="col-md-7">
                                      <select
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        defaultValue=""
                                        placeholder="Nome da Enquete"
                                        onChange={(e) => {
                                          let q = [...questionsHelp];
                                          q[i] = e.target.value;
                                          setQuestionsHelp(q);
                                        }}
                                      >
                                        <option value="volvo">
                                          Escolha uma pergunta
                                        </option>
                                        {questions.map((e, i) => (
                                          <option
                                            value={JSON.stringify(e)}
                                            key={i}
                                            dataIndex={i}
                                          >
                                            {e.question}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div className="col-md-2">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        defaultValue=""
                                        placeholder="Pont."
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      {questionsHelp.length > 0 &&
                                      questionsHelp[i] &&
                                      JSON.parse(questionsHelp[i]).values
                                        .length > 0 ? (
                                        <select
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          defaultValue=""
                                          placeholder="Nome da Enquete"
                                        >
                                          <option value="volvo">
                                            Escolha uma pergunta
                                          </option>
                                          {questionsHelp.length > 0 &&
                                            JSON.parse(
                                              questionsHelp[i]
                                            ).values.map((e, i) => (
                                              <option value={e.value} key={i}>
                                                {e.text}
                                              </option>
                                            ))}
                                        </select>
                                      ) : (
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          defaultValue=""
                                          placeholder="Resp."
                                        />
                                      )}
                                    </div>
                                    <div
                                      className="col-md-1"
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                      }}
                                    >
                                      {inputsQuestions.length - 1 == i && (
                                        <i
                                          class="fas fa-trash-alt"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setInputsQuestions(
                                              inputsQuestions.slice(
                                                0,
                                                inputsQuestions.length - 1
                                              )
                                            );
                                          }}
                                        ></i>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </tr>
                              <tr>
                                <br />
                                <div
                                  class="div-questions"
                                  onClick={() =>
                                    setInputsQuestions([...inputsQuestions, 0])
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    class="fas fa-plus"
                                    style={{ fontSize: 18, marginRight: 6 }}
                                  ></i>
                                  <span style={{ fontSize: 15 }}>
                                    {" "}
                                    Adicionar Nova Pergunta
                                  </span>
                                </div>
                              </tr>
                            </tbody>
                          </table>
                          {!loadingSavingSurvey ? (
                            <button
                              className={`btn btn-success btn-block waves-effect waves-light`}
                              type="submit"
                            >
                              Criar Enquete
                            </button>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner animation="border" />
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* MODAL */}
            </Col>
          </Row>
        </Container>

        <Modal
          open={modalRankingControl.open}
          closeButtonClick={(e) => {
            setModalRankingControl({ ...modalRankingControl, open: false });
          }}
          onClose={(ret) => {
            setModalRankingControl({ ...modalRankingControl, open: false });
          }}
        >
          <div style={{ padding: 5 }}>
            <div data-test="table" className="table-responsive">
              <table
                entries={2}
                className=""
                style={{ width: "100%", borderTop: "1px solid #eff2f7" }}
              >
                <thead data-test="datatable-head">
                  <tr>
                    <th className="sorting" style={{ textAlign: "left" }}>
                      #
                    </th>
                    <th className="sorting" style={{ textAlign: "left" }}>
                      ID
                    </th>
                    <th className="sorting" style={{ textAlign: "left" }}>
                      {getDic("nome").toUpperCase()}
                    </th>
                    <th className="sorting" style={{ textAlign: "left" }}>
                      {getDic("email").toUpperCase()}
                    </th>
                    <th className="sorting" style={{ textAlign: "left" }}>
                      {getDic("pontos").toUpperCase()}
                    </th>
                    <th className="sorting" style={{ textAlign: "left" }}></th>
                  </tr>
                </thead>
                <tbody data-test="table-body">
                  {modalRankingControl.list.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ textAlign: "left" }}>
                          {i > 2 && `${i + 1}º`}
                          {i == 0 && (
                            <i
                              class="fas fa-medal"
                              style={{ color: "#ff9800", fontSize: 25 }}
                            ></i>
                          )}
                          {i == 1 && (
                            <i
                              class="fas fa-medal"
                              style={{ color: "#9e9e9e", fontSize: 25 }}
                            ></i>
                          )}
                          {i == 2 && (
                            <i
                              class="fas fa-medal"
                              style={{ color: "#c97903", fontSize: 25 }}
                            ></i>
                          )}
                        </td>
                        <td style={{ textAlign: "left" }}>{item.userId}</td>
                        <td style={{ textAlign: "left" }}>{item.userName}</td>
                        <td style={{ textAlign: "left" }}>{item.userEmail}</td>
                        <td style={{ textAlign: "left" }}>{item.points}</td>
                        {/* <td style={{ textAlign: "center" }}>
                          {item.answeredQuestions.length > 0 ? (
                            <i
                              class="fas fa-times-circle"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                let _list = modalRankingControl.list;
                                _list[i].openInfo = true;
                                setModalRankingControl({ ...modalRankingControl, list: _list });
                              }}
                            >
                              {" "}
                            </i>
                          ) : (
                            <i
                              class="fas fa-info-circle"
                              style={{ cursor: "pointer" }}
                            // onClick={() => { _open = false }}
                            ></i>
                          )}
                          {item.answeredQuestions.length > 0 && item.openInfo && (
                            <div>
                              <table
                                entries={2}
                                className=""
                                style={{ width: "100%", borderTop: "1px solid #eff2f7" }}
                              >
                                <thead data-test="datatable-head">
                                  <tr>
                                    <th className="sorting" style={{ textAlign: "left" }}>
                                      {getDic("pergunta")}
                                    </th>
                                    <th className="sorting" style={{ textAlign: "left" }}>
                                      {getDic("resposta")}
                                    </th>
                                    <th className="sorting" style={{ textAlign: "left" }}>
                                      Correto
                                    </th>
                                  </tr>
                                </thead>
                                <tbody data-test="table-body">
                                  {item.answeredQuestions.map((e, index) => (
                                    <tr key={index}>
                                      <td style={{ textAlign: "center" }}>{e.questionInfo.question}</td>
                                      <td style={{ textAlign: "center" }}>{e.answer}</td>
                                      <td style={{ textAlign: "center" }}>

                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      </div>
    </ContainerProp>
  );
};

const getCurrentTime = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate() > 9 ? a.getDate() : "0" + a.getDate();
  var hour = a.getHours() > 9 ? a.getHours() : "0" + a.getHours();
  var min = a.getMinutes() > 9 ? a.getMinutes() : "0" + a.getMinutes();
  var sec = a.getSeconds() > 9 ? a.getSeconds() : "0" + a.getSeconds();
  var time =
    date + "  " + month + "  " + year + " - " + hour + ":" + min + ":" + sec;
  return time;
};

const generateExcell = (listData) => {
  const newStructure = listData
    .sort((a, b) => b.score - a.score || a.currentData - b.currentData)
    .splice(0, 1000)
    .map((e, i) => {
      return {
        rank: `${i + 1} º`,
        guestName: `${e.guestName ? e.guestName : e.answerLogin} ${
          e.guestName && `(${e.guestId})`
        }`,
        score: e.score,
      };
    });
  const fileName = `relatório-${new Date().getDate()}-${
    new Date().getMonth() + 1
  }-${new Date().getFullYear()}-${new Date().getHours()}:${new Date().getMinutes()}`;
  const exportType = "xls";
  exportFromJSON({ data: newStructure, fileName, exportType });
};

const mapStateToProps = (state) => {
  return {
    event: state.event,
    user: state.user,
    config: state.config,
  };
};

export default connect(mapStateToProps, null)(RankingSurvey);
