import React from "react";
import { connect } from "react-redux";
import { getDic } from "../assets/i18n/dictionary";
import { createAttributeValueModel } from "../models/attributeValueModel";
import { getQuestionsApi, sendAnswersApi } from "../services/questionsService";
import "../scss/VideoReact.scss";

//Imports de components
import {
  Container,
  FormRow,
  FormColumn,
  SelectList,
  InputFieldOutlined,
  TextSmall,
  Button,
  ExpansionPanel,
  ActivityIndicator,
  Chat,
} from "../components";

class QuestionsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSending: false,
      questions: [],
      attributesValueList: [],
      questionsConfirm: false,
      questionsConfirmMessage: "",
      categoryList: [],
      hasChat: this.props.hasChat,
      chatOrder: this.props.chatOrder ? this.props.chatOrder : 2,
    };
  }

  componentDidMount() {
    getQuestionsApi(this.props.event.id, this.props.chosenConfig.id)
      .then((res) => {
        // Filtro para não exibir perguntas do tipo ENQUETE
        const quest = [];
        res.questions.forEach((item) => {
          if (item.typeQuestion !== "ENQUETE") {
            quest.push(item);
          }
        });

        //Cria lista de campos para preencher
        const attributesValueList = [];
        const categoryList = [];

        quest.forEach((item) => {
          var field = createAttributeValueModel(
            item.id,
            item.name,
            "",
            false,
            "",
            "",
            false
          );

          //Verifica se a categoria já existe na lista geral
          var hasCategory = categoryList.find((sp) => {
            if (sp.name === item.category) {
              return true;
            }
            return false;
          });
          if (!hasCategory) {
            const category = {
              name: item.category,
            };
            categoryList.push(category);
          }
          attributesValueList.push(field);
        });

        this.setState({
          isLoading: false,
          questions: quest,
          attributesValueList: attributesValueList,
          categoryList: categoryList,
        });
      })
      .catch((err) => {
        console.log("Erro getQuestionsApi", err);
      });
  }

  onChangeHandler(fieldId, value) {
    const list = this.state.attributesValueList.map((item) => {
      if (item.attributeId === fieldId) {
        var newField = item;
        newField.value = value;
        return newField;
      } else {
        return item;
      }
    });

    this.setState({ attributesValueList: list });
  }

  tryRegister(formName) {
    this.setState({ isSending: true });

    //Verifica campos obrigatórios para validar se estão preenchidos
    const list = this.state.attributesValueList.map((item) => {
      var error = this.state.questions.find((atr) => {
        if (
          atr.id === item.attributeId &&
          item.value === "" &&
          atr.category === formName
        )
          return true;
        return false;
      });

      var newField = item;
      if (error) {
        newField.error = true;
        newField.errorMessage = getDic("preenchimento-obrigatorio");
        return newField;
      } else {
        newField.error = false;
        newField.errorMessage = "";
        return newField;
      }
    });
    this.setState({ attributesValueList: list });

    //Filtra lista de campos para mandar apenas os da categoria
    var questionsList = this.state.questions.filter((item) => {
      return item.category === formName;
    });

    var attributesValueList = [];
    questionsList.forEach((item) => {
      var attribute = list.find((atr) => {
        return atr.attributeId === item.id;
      });
      attributesValueList.push(attribute);
    });

    //verifica se não há erros, se estiver ok, tentar registrar pax no sistema
    if (attributesValueList.find((f) => f.error === true)) {
      this.setState({ isSending: false });
      return;
    } else {
      sendAnswersApi(attributesValueList)
        .then((res) => {
          console.log("Respostas cadastradas");

          //Apaga os campos
          const listFinal = this.state.attributesValueList.map((item) => {
            var newField = item;
            newField.error = false;
            newField.errorMessage = "";
            newField.value = "";
            return newField;
          });
          this.setState({ attributesValueList: listFinal });

          this.setState({
            questionsConfirm: true,
            questionsConfirmMessage: getDic("respostas-enviadas"),
          });
          var timeout = setTimeout(() => {
            this.setState({
              questionsConfirm: false,
              questionsConfirmMessage: "",
            });
            clearTimeout(timeout);
          }, 6000); // 1 minuto = 60000
        })
        .catch((err) => {
          console.log("Erro sendAnswersApi", err);
          this.setState({
            questionsConfirm: true,
            questionsConfirmMessage:
              getDic("erro") + " " + getDic("sem-conexao"),
          });
          var timeout = setTimeout(() => {
            this.setState({
              questionsConfirm: false,
              questionsConfirmMessage: "",
            });
            clearTimeout(timeout);
          }, 6000); // 1 minuto = 60000
        })
        .finally(() => {
          this.setState({ isSending: false });
        });
    }
  }

  renderChat(order) {
    if (this.state.hasChat && this.state.chatOrder === order) {
      return (
        <ExpansionPanel
          headerText="Bate Papo"
          expanded
          expansionStyle={{
            height: 10,
            minHeight: 30,
            /*borderBottom: "1px solid rgb(230 227 227 / 43%)"*/
          }}
          headerTextStyle={{
            fontWeight: 700,
            color: "#424242",
          }}
          footerPanelStyle={{
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          <div style={{ width: "100%" }}>
            <Chat chosenConfig={this.props.chosenConfig} height="400px" />
          </div>
        </ExpansionPanel>
      );
    }
  }

  render() {
    const { height } = this.props;

    if (this.state.isLoading)
      return (
        <Container background="transparent" align="center">
          <FormRow align="center">
            <ActivityIndicator />
          </FormRow>
        </Container>
      );

    return (
      <div
        style={{
          height: height,
          backgroundColor: "transparent",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ padding: "5px", overflowX: "hidden", overflowY: "auto" }}>
          {this.renderChat(1)}
          {this.state.questions.length > 0
            ? //Monta campos de expansão
            this.state.categoryList.map((cat) => {
              return (
                <ExpansionPanel headerText={cat.name} expanded>
                  <FormColumn>
                    {this.state.questions.map((item) => {
                      if (cat.name === item.category) {
                        if (
                          item.typeQuestion === "SELECIONAR" &&
                          item.values
                        ) {
                          return (
                            <FormRow key={item.id} align="center">
                              <TextSmall>{item.question}</TextSmall>
                              <SelectList
                                error={
                                  this.state.attributesValueList.find(
                                    (f) => f.attributeId === item.id
                                  ).error
                                }
                                errorMessage={
                                  this.state.attributesValueList.find(
                                    (f) => f.attributeId === item.id
                                  ).errorMessage
                                }
                                width="100%"
                                value={
                                  this.state.attributesValueList.find(
                                    (f) => f.attributeId === item.id
                                  ).value
                                }
                                menuItens={item.values}
                                onChange={async (event) =>
                                  this.onChangeHandler(
                                    item.id,
                                    event.target.value
                                  )
                                }
                              />
                            </FormRow>
                          );
                        } else {
                          return (
                            <FormRow key={item.id} align="center">
                              <TextSmall>{item.question}</TextSmall>
                              <InputFieldOutlined
                                key={item.id}
                                error={
                                  this.state.attributesValueList.find(
                                    (f) => f.attributeId === item.id
                                  ).error
                                }
                                errorMessage={
                                  this.state.attributesValueList.find(
                                    (f) => f.attributeId === item.id
                                  ).errorMessage
                                }
                                value={
                                  this.state.attributesValueList.find(
                                    (f) => f.attributeId === item.id
                                  ).value
                                }
                                onChange={(event) =>
                                  this.onChangeHandler(
                                    item.id,
                                    event.target.value
                                  )
                                }
                                mask={item.mask}
                              />
                            </FormRow>
                          );
                        }
                      }
                    })}
                    <br />
                    {this.state.isSending ? (
                      <div style={{ textAlign: "center" }}>
                        <ActivityIndicator />
                      </div>
                    ) : (
                        <Button
                          text={getDic("enviar")}
                          onClick={(event) => this.tryRegister(cat.name)}
                          color={this.props.chosenConfig.multiConfig.backgroundButton}
                          textColor={this.props.chosenConfig.multiConfig.textColorButton}
                        />
                      )}
                    {this.state.questionsConfirm ? (
                      <TextSmall>{getDic("enviado")}</TextSmall>
                    ) : null}
                    <br />
                  </FormColumn>
                </ExpansionPanel>
              );
            })
            : null}
          {this.renderChat(2)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
    chosenConfig: state.chosenConfig,
  };
}

export default connect(mapStateToProps, null)(QuestionsPanel);
