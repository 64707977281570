import React from "react";
import { getDic } from "../../../assets/i18n/dictionary";

const CardReports = (props) => {
  return (
    <div className="col-sm-4 col-12">
      <div
        class="card"
        style={{
          width: "18rem",
          backgroundColor: "whitesmoke",
          height: "auto",
          border: "1px solid #e0e0e0",
          borderRadius: 5,
          padding: 14,
          margin: 20,
        }}
      >
        <div class="card-body">
          <h5 class="card-title" style={{ fontSize: 20 }}>
            {props.ReportName}
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">
            Último relatório 00/00/0000 00:00
          </h6>
          <p class="card-text">
            Efetue o download completo do relatório do evento selecionado,{" "}
            {props.ReportName}.
          </p>
          <div className="row" style={{ textAlign: "center" }}>
            <button class="btn btn-primary btn-sm">
              <i class="fa fa-download" style={{ marginRight: 5 }}>
                {" "}
              </i>{" "}
              {getDic("download").toUpperCase()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CardReports };
