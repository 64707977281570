import React from "react";

const FeedData = React.createContext({
    _loading: null,
    _createNewPost: {},
    _posts: [],
    _getPosts: {},
    _user: null,
    _event: null,
    _post_loaded: false,
    _sendLikeToPost: {},
    _removeLike: {},
    _commentPost: {},
    _removePost: {},
    _removeComment: {},
    _newLikeArrayList: false,
    _setNewLikeArrayList: {},
    _removePostNow: {}
});

export default FeedData;