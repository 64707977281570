import React from "react";
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import {
    PostFeed,
    FeedData
} from "../components";
import {
    createNewPost,
    removePost,
    getAllPosts,
    setLike,
    removeLike,
    removeComment,
    setComment
} from "../services/feedService";
import {
    getGuestList
} from "../services/guestService";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getConfigListByPage } from "../services/configService";
import "../scss/PostFeed.scss";


const FeedPostPage = props => {
    const [loading, setLoading] = React.useState(false);
    const [loadingPosts, setLoadingPosts] = React.useState(true);
    const [postsLoaded, setPostLoaded] = React.useState(false);
    const [allPosts, setAllPosts] = React.useState([]);
    const [feedImgBackground, setFeedImgBackground] = React.useState('#');
    const [newLikeArrayList, setNewLikeArrayList] = React.useState(false);
    const newPost = (data) => {
        return createNewPost(data).then(result => {
            console.log(result);
        })
    }

    const getPosts = (data) => {
        let pageIndex = data.pageIndex != null ? data.pageIndex : 1;
        let pageSize = data.pageSize != null ? data.pageSize : 5;

        return getAllPosts({ pageSize: pageSize, pageIndex: pageIndex }).then(r => {
            const _data = r.data.result;
            let _postsTemp = [...allPosts, ..._data];
            setNewLikeArrayList(true);
            setAllPosts(_data);
            setPostLoaded(true);
            setLoadingPosts(false);
            return r;
        })
    }

    const sendLikeToPost = data => {
        return setLike(data);
    }
    const removeLikeToPost = data => {
        return removeLike(data).then(r => {
            console.log("like removido");
        });
    }
    const commentPost = data => {
        return setComment(data).then(r => {
            console.log("Comentário adicionado");
        })
    }
    const removePostNow = p => {
        let _postsTemp = [...allPosts.filter(e => e.id != p)];
        setAllPosts(_postsTemp)
        removePost({
            postId: p
        });
    }

    React.useEffect(() => {
        getPageConfig();
        if (!loading) {
            getPosts({ pageSize: 100, pageIndex: 1 });
            setLoading(false);
        }
    }, [])

    const getPageConfig = async () => {
        const _url = !props.isModal
            ? props.match.url
            : `/${props.event.id}/eventhall`;
        const configList = await getConfigListByPage(_url);
        configList.sort((a, b) => (a.codeScreen > b.codeScreen) ? 1 : -1)
        configList.find((item) => {
            if (item.codeScreen === "FEED-IMAGE-BACKGROUND") {
                const validUrl = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
                if (item.url[0] !== "#" && validUrl.test(item.url[0])) {
                    setFeedImgBackground(item.url[0])
                }
            }
        })
    }
    return <FeedData.Provider value={{
        _loading: loading,
        _createNewPost: newPost,
        _posts: allPosts,
        _getPosts: getPosts,
        _user: props.user,
        _event: props.event,
        _feed_image_background: feedImgBackground,
        _post_loaded: postsLoaded,
        _sendLikeToPost: sendLikeToPost,
        _removeLike: removeLikeToPost,
        _commentPost: commentPost,
        _removePost: removePost,
        _removeComment: removeComment,
        _newLikeArrayList: newLikeArrayList,
        _setNewLikeArrayList: setNewLikeArrayList,
        _removePostNow: removePostNow
    }}>
        {!loadingPosts ?
            <PostFeed />
            :
            <div style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress
                    style={{
                        cursor: "pointer",
                        width: 22,
                        height: 22
                    }}
                />
                <span>{getDic("carregando")}</span>
            </div>
        }
    </FeedData.Provider>
}

function mapStateToProps(state) {
    return {
        user: state.user,
        event: state.event
    };
}

export default connect(mapStateToProps, null)(FeedPostPage);
