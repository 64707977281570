import React, { useContext } from "react";
import TestPageContext from './TestPageContext';
import TestPageProvider from "./TestPageProvider";
import TestPageVisual from "./TestPageVisual";

const TestPage = (props) => {

    return (
        <TestPageProvider history={props.history} params={props.match.params}>
            <TestPageVisual />
        </TestPageProvider>
    );
}

export default TestPage;
