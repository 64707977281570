import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const AvatarProp = props => {
  const {
    src = "",
    size = null,
    onClick,
    onMouseOver,
    status = "none" // online, offline, inactive
  } = props;

  const styles = {
    big: {
      width: "150px",
      height: "150px"
    },
    medium: {
      width: "100px",
      height: "100px"
    },
    small: {
      width: "50px",
      height: "50px"
    },
    mediumSmall: {
      width: "30px",
      height: "30px"
    },
    smaller: {
      width: "25px",
      height: "25px"
    }
  };

  var color = "#44b700";
  if (status === "offline") color = "#b3b3b3";
  if (status === "inactive") color = "#ff0000";

  const StyledBadge = withStyles(theme => ({
    badge: {
      backgroundColor: color,
      color: color,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""'
      }
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0
      }
    }
  }))(Badge);

  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1)
      }
    }
  }));

  const classes = useStyles();

  if (status === "none") {
    return (
      <Avatar
        style={
          size === "big"
            ? styles.big
            : size === "medium"
            ? styles.medium
            : size === "small"
            ? styles.small
            : size === "smaller"
            ? styles.smaller
            : size == "medium-small"
            ? styles.mediumSmall
            : styles.small
        }
        src={src}
        onClick={onClick}
        onMouseOver={onMouseOver}
      />
    );
  } else {
    return (
      <div className={classes.root}>
        <StyledBadge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          variant="dot"
        >
          <Avatar src={src} onClick={onClick} onMouseOver={onMouseOver} />
        </StyledBadge>
      </div>
    );
  }
};

export default AvatarProp;
