import React from "react";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import InputAdornment from "@material-ui/core/InputAdornment";
import Avatar from "./Avatar";
import "../scss/ChatFixes.scss";

const ChatInputFieldOutlined = props => {
  const {
    key,
    value,
    title,
    placeholder = "",
    onChange,
    onKeyPress,
    error = false,
    errorMessage = "",
    required = false,
    maxLength = -1,
    mask = "",
    backgroundColor = "rgba(0, 0, 0, 0)",
    disabled = false,
    onBlur,
    autoFocus = false,
    inputRef,
    startAdornment,
    endAdornment,
    type = "text"
  } = props;

  const styles = {
    containerForm: {
      borderRadius: 0,
      border: "0!important",
      padding: "10px 10px 10px !important"
    },
    flexBoxVertical: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 5,
      paddingTop: 15
    }
  };

  if (mask && mask != "" && !disabled) {
    return (
      <React.Fragment>
        <InputMask
          mask={mask}
          value={value}
          onChange={onChange}
          maskChar=""
          onBlur={onBlur}
        >
          {() => (
            <React.Fragment>
              <TextField
                style={styles.containerForm}
                InputProps={{
                  maxLength: maxLength,
                  startAdornment: startAdornment ? (
                    <InputAdornment position="start">
                      {startAdornment}
                    </InputAdornment>
                  ) : null,
                  endAdornment: endAdornment ? (
                    <InputAdornment position="end">
                      {endAdornment}
                    </InputAdornment>
                  ) : null
                }}
                required={required}
                error={error}
                helperText={errorMessage}
                key={key}
                label={title}
                placeholder={placeholder}
                variant="outlined"
                fullWidth
                onKeyPress={onKeyPress}
                autoFocus={autoFocus}
                inputRef={inputRef}
                type={type}
              />
            </React.Fragment>
          )}
        </InputMask>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div
          style={{
            flexDirection: "row",
            flex: 1,
            display: "flex",
            borderTop: "1px solid rgb(230 227 227 / 43%)",
            height: 27
          }}
        >
          <div style={styles.flexBoxVertical}>
            <Avatar src={props.userAvatar} size="smaller" />
          </div>
          <div style={{ flex: 5 }}>
            <TextField
              style={styles.containerForm}
              required={required}
              error={error}
              helperText={errorMessage}
              key={key}
              label={title}
              placeholder={placeholder}
              variant="filled"
              fullWidth
              value={value}
              onChange={onChange}
              onKeyPress={onKeyPress}
              disabled={disabled}
              onBlur={onBlur}
              autoFocus={autoFocus}
              inputRef={inputRef}
              type={type}
            />
          </div>
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <div style={styles.flexBoxVertical}>{startAdornment}</div>
            <div style={styles.flexBoxVertical}>{endAdornment}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default ChatInputFieldOutlined;
