export function createAttributeModel(
    id = 0,
    eventId = 0,
    name = "",
    required = false,
    maxLength = 0,
    order = 0,
    mask = "",
    type = "", //Texto ou Selecionar
    values = "", // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
    dbReference = "",
) {
    //Tratamento para adaptar modelo de mascara do razor para o redux
    var newMask = "";
    if (mask && mask !== "") {
        newMask = mask.replace(/0/g, "9");
    }

    const object = {
        id: id,
        eventId: eventId,
        name: name,
        required: required,
        maxLength: maxLength,
        order: order,
        mask: newMask,
        type: type,
        values: values,
        dbReference: dbReference
    }

    return object;
}
