import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import LockIcon from '@material-ui/icons/Lock';

//Imports de components
import {
  Container,
  FormRow,
  FormColumn,
  TextMedium,
  BackButton
} from '../components';

const MobileOrientationInstructionsPage = (props) => {
  const [imageBackgroundUrl, setImageBackgroundUrl] = React.useState(null);
  const [colorBackGround, setColorBackGround] = React.useState('#e8eced');

  React.useEffect(() => {
    if (!isMobile && !props.isModal) {
      props.history.goBack();
    }

    return () => {
    };
  }, []);

  return (
    <Container backgroundImage={imageBackgroundUrl} background={colorBackGround} align="center" height={props.isModal ? "100%" : "100vh"}>
      {!props.isModal && (
        <>
          <BackButton history={props.history} color="#212121">
            {getDic("voltar").toUpperCase()}
          </BackButton>
        </>
      )}
      <FormColumn backgroundImage={imageBackgroundUrl} background={colorBackGround} margin="0px" align="center">
        {isIOS ? (
          <>
            <FormRow margin="0px" align="center" minHeight="60px">
              <TextMedium fontWeight="bold">
                {getDic("instrucoes").toUpperCase()}
              </TextMedium>
            </FormRow>
            <FormRow margin="0px" align="center" minHeight="60px">
              <TextMedium>
                1 - {getDic("instrucao-orientacao-1-ios")}.
          </TextMedium>
            </FormRow>
            <FormRow margin="0px" align="center" minHeight="60px">
              <TextMedium>
                2 - {getDic("instrucao-orientacao-2-a-ios")} <LockIcon /> {getDic("instrucao-orientacao-2-b-ios")}.
          </TextMedium>
            </FormRow>
            <FormRow margin="0px" align="center" minHeight="60px">
              <TextMedium>
                3 - {getDic("instrucao-orientacao-3")}.
          </TextMedium>
            </FormRow>
            <FormRow margin="0px" align="center">
              <div style={{ width: "100%", maxWidth: "300px" }}>
                <img alt="" src="https://s3.amazonaws.com/bucket.aws.public/2021/235149.GIF" style={{ width: "100%" }} />
              </div>
            </FormRow>
          </>
        ) : (
          <>
            <FormRow margin="0px" align="center" minHeight="60px">
              <TextMedium fontWeight="bold">
                {getDic("instrucoes").toUpperCase()}
              </TextMedium>
            </FormRow>
            <FormRow margin="0px" align="center" minHeight="60px">
              <TextMedium>
                1 - {getDic("instrucao-orientacao-1-android")}.
          </TextMedium>
            </FormRow>
            <FormRow margin="0px" align="center" minHeight="60px">
              <TextMedium>
                2 - {getDic("instrucao-orientacao-2-a-android")} <LockIcon /> {getDic("instrucao-orientacao-2-b-android")}.
          </TextMedium>
            </FormRow>
            <FormRow margin="0px" align="center" minHeight="60px">
              <TextMedium>
                3 - {getDic("instrucao-orientacao-3")}.
          </TextMedium>
            </FormRow>
            <FormRow margin="0px" align="center">
              <div style={{ width: "100%", maxWidth: "300px" }}>
                <img alt="" src="https://s3.amazonaws.com/bucket.aws.public/2021/235230.GIF" style={{ width: "100%" }} />
              </div>
            </FormRow>
          </>
        )}
      </FormColumn>
    </Container>
  );
};

export default MobileOrientationInstructionsPage;
