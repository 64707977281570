import React from "react";
import { getDic } from "../assets/i18n/dictionary";
import { getConfigListByPageIdApi } from '../services/configService';
import {
  getCalendarListApi,
  convertCalendarObjectToIcsFile,
  convertCalendarObjectToIcsOutlookFile,
  convertCalendarObjectToGoogleCalendarLink
} from '../services/calendarService';
import AppleIcon from '@material-ui/icons/Apple';

//Imports de components
import {
  Container,
  Button,
  FormRow,
  TextSmall,
  Alert,
  ActivityIndicator,
} from "../components";

const CalendarPage = (props) => {
  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [isLoading, setIsLoading] = React.useState(true);

  const [state, setState] = React.useState(true);
  const [event, setEvent] = React.useState();
  const [configObject, setConfigObject] = React.useState({
    imageBackgroundUrl: null,
    imageBlock: null,
    colorBackground: "#e8eced",
    colorButton: "#3f51b5",
    colorTextButton: "white",
    textButton: getDic("adicionar"),
    height: "100vh",
    positionContent: "top",
  });

  React.useEffect(() => {
    getPageData();
    return () => {
    };
  }, []);

  const getPageData = () => {
    if (props.match.params.id && props.match.params.id > 0) {
      getConfigListByPageIdApi(133, props.match.params.id)
        .then(res => {
          setPageConfig(res);
        })
        .catch(err => {
          setAlertOpen(true);
          setAlertTitle(getDic("erro"));
          setAlertMessage(getDic("enviado-erro"));
        })
        .finally(() => {
          setIsLoading(false);
        });

      if (props.match.params.category) {
        getCalendarListApi(props.match.params.id)
          .then(res => {
            const _calendar = res.find(item => {
              return item.type === props.match.params.category;
            });

            if (_calendar) {
              setEvent(_calendar);
            }
          })
          .catch(err => {
            setAlertOpen(true);
            setAlertTitle(getDic("erro"));
            setAlertMessage(getDic("enviado-erro"));
          });
      }
    }
  }

  const setPageConfig = (pageConfigList) => {
    if (pageConfigList.length > 0) {
      var _newConfig = configObject;
      pageConfigList.forEach(conf => {
        if (conf.codeScreen === "ELEMENT-001") {
          conf.url.forEach(item => {
            var _data = item.split(/:(.+)/);
            _newConfig[_data[0]] = _data[1] ? _data[1].trim() : _newConfig[_data[0]];
          });
        }
      });
      setConfigObject(_newConfig);
      setState(state === true ? false : true);
    }
  }

  const getIcsFile = (calendarObj) => {
    convertCalendarObjectToIcsFile(calendarObj);
  }

  const getIcsOutlookFile = (calendarObj) => {
    convertCalendarObjectToIcsOutlookFile(calendarObj);
  }

  const getGoogleCalendarLink = async (calendarObj) => {
    const _url = await convertCalendarObjectToGoogleCalendarLink(calendarObj);
    let a = document.createElement("a");
    a.href = _url;
    a.target = "_blank";
    a.click();
  }

  if (isLoading) {
    return (
      <Container background="#e8eced" align="center">
        <FormRow align="center">
          <ActivityIndicator />
        </FormRow>
      </Container>
    );
  }
  return (
    <div>
      <img src={configObject.imageBackgroundUrl} style={{ position: "fixed", width: "100%", height: "100%", zIndex: "-2" }} />
      <Container background={configObject.imageBackgroundUrl ? "transparent" : configObject.colorBackground} align="center" height={configObject.height}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: configObject.positionContent === "top" ? "10px"
              : configObject.positionContent === "center" ? "30vh"
                : configObject.positionContent === "bottom" ? "auto" : "10px",
            bottom: configObject.positionContent === "bottom" ? "0px" : "auto"
          }}
        >
          <FormRow align="center" margin="0px">
            <div style={{ width: "100%", maxWidth: "600px", padding: 5 }}>
              <img alt="" src={configObject.imageBlock} style={{ width: "100%" }} />
            </div>
          </FormRow>
          <FormRow align="center" margin="0px">
            <div style={{ padding: "5px", maxWidth: "150px" }}>
              <Button
                color={configObject.colorButton}
                textColor={configObject.colorTextButton}
                onClick={() => getIcsFile(event)}
              >
                <AppleIcon />
                <TextSmall color="transparent">.</TextSmall>
                {" ICalendar"}
              </Button>
            </div>
            <div style={{ padding: "5px", maxWidth: "150px" }}>
              <Button
                color={configObject.colorButton}
                textColor={configObject.colorTextButton}
                onClick={() => getIcsOutlookFile(event)}
              >
                <i class="fa fa-windows" aria-hidden="true"></i><br />
                <TextSmall color="transparent">.</TextSmall>
                {" Outlook"}
              </Button>
            </div>
            <div style={{ padding: "5px", maxWidth: "150px" }}>
              <Button
                color={configObject.colorButton}
                textColor={configObject.colorTextButton}
                onClick={() => getGoogleCalendarLink(event)}
              >
                <i class="fa fa-google" aria-hidden="true"></i><br />
                <TextSmall color="transparent">.</TextSmall>
                {" Google"}
              </Button>
            </div>
          </FormRow>
        </div>
      </Container>

      <Alert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={() => setAlertOpen(false)}
        title={alertTitle}
        message={alertMessage}
      />
    </div>
  );
}

export default CalendarPage;
