import React from 'react';
import { connect } from "react-redux";
import { getLatestMessageByUserApi } from '../services/chatPersonalService';
import { setPrivateChatControl, setNotification } from '../store/actions';
import ChatHistoryPage from "../pages/ChatHistoryPage";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from '@material-ui/icons/NotificationsActive';
import CloseIcon from '@material-ui/icons/Close';
import * as navigationHistoryService from '../services/navigationHistoryService';

//Imports de components
import {
    Dialog,
} from '../components';

var timeout;

const PrivateChatControl = (props) => {

    const [dialogChatOpen, setDialogChatOpen] = React.useState(false);
    const [showButton, setShowButton] = React.useState(false);
    const [enabled, setEnabled] = React.useState(false);

    React.useEffect(() => {
        var _page = window.location.pathname.split('/');

        // Esconder botão na tela de chat
        if (_page[2] !== "chat") {
            setShowButton(false);
        }

        var _chatControl = props.chatPrivateControl;
        if (!props.chatPrivateControl.hasChat) {
            _chatControl.hasChat = true;
            props.setPrivateChatControl(_chatControl);
        }

        //checkChatPrivateNewMessage();
        execute();
        return () => {
            clearTimeout(timeout);
        }
    }, []);

    const checkChatPrivateNewMessage = () => {
        timeout = setTimeout(() => {
            execute();
            //Reiniciar thread
            clearTimeout(timeout);
            checkChatPrivateNewMessage();
        }, 60000); // 1 minuto = 60000
    }

    const execute = () => {

        var _chatControl = props.chatPrivateControl;
        var _page = window.location.pathname.split('/');
        // A thread deve rodar apenas se o usuário não estiver na página de chat
        getLatestMessageByUserApi()
            .then(res => {
                if (res.data == false) {
                    props.setNotification(false);
                    setEnabled(false)
                    setShowButton(false);
                } else {
                    props.setNotification(true);
                    setEnabled(true)
                    setShowButton(true);
                }
            })
            .finally(() => {
                //
            })
    }

    return (
        <div>
            {props.notification && window.location.pathname.split('/')[2] !== "chat" &&
                <div
                    title="Chat"
                    /*onClick={() => {
                        setDialogChatOpen(true);
                        setShowButton(false);
                    }}*/
                    onClick={() => {
                        setShowButton(false);
                        //setDialogChatOpen(true);
                        navigationHistoryService.setHistory(props.history, '/' + props.event.id + '/chat');
                    }}
                    style={{
                        backgroundColor: "#c70000",
                        width: 24,
                        height: 24,
                        position: "absolute",
                        top: 5,
                        left: 70,
                        zIndex: enabled ? 12 : 0,
                        borderRadius: 12,
                        color: "white",
                        cursor: "pointer"
                    }}
                >
                    <NotificationsIcon style={{ height: 15, margin: 0 }} />
                </div>
            }
            <Dialog
                open={dialogChatOpen}
                onClose={() => setDialogChatOpen(false)}
                fullScreen={false}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div>
                    <div
                        style={{
                            backgroundColor: "transparent",
                            width: '100%',
                            textAlign: 'right',
                            top: '0px',
                            right: '10px',
                            position: "fixed",
                            zIndex: "1"
                        }}
                    >
                        <IconButton
                            size="small"
                            onClick={() => setDialogChatOpen(false)}
                            style={{
                                marginRight: "10px",
                                color: "white",
                                backgroundColor: "gray"
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <ChatHistoryPage isModal />
                </div>
            </Dialog>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
        event: state.event,
        chatPrivateControl: state.chatPrivateControl,
        notification: state.notification
    };
}

const mapDispatchToProps = {
    setNotification,
    setPrivateChatControl
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateChatControl);
