export const SET_CONFIG = 'SET_CONFIG';
const setConfigLoca = config => ({
    type: SET_CONFIG,
    config
});
export function setConfig(config) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setConfigLoca(config));
            resolve(true);
        });
    };
}

export const UPDATE_CONFIG = 'UPDATE_CONFIG';
const updateConfigLocal = config => ({
    type: UPDATE_CONFIG,
    config
});
export function updateConfig(configProp) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            const _newConfigList = [];
            getState().config.forEach(item => {
                if (configProp.id === item.id) {
                    _newConfigList.push(configProp);
                }
                else {
                    _newConfigList.push(item);
                }
            });

            dispatch(updateConfigLocal(_newConfigList));
            resolve(true);
        });
    };
}

export const MERGE_CONFIG = 'MERGE_CONFIG';
const mergeConfigLocal = config => ({
    type: MERGE_CONFIG,
    config
});
export function mergeConfig(config) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(mergeConfigLocal(config));
            resolve(true);
        });
    };
}

export const RESET_CONFIG = 'RESET_CONFIG';
const resetConfigLocal = () => ({
    type: RESET_CONFIG
});
export function resetConfig() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetConfigLocal());
            resolve(true);
        });
    };
}
