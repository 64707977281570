export const CHECK_V_LIBRAS = 'CHECK_V_LIBRAS';
const checkLibras = () => ({
    type: CHECK_V_LIBRAS
});
export function checkGlobalVlibras() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            checkVLibras();
            dispatch(checkLibras());
            resolve(true);
        });
    };
}


function checkVLibras(){
    let elem = document.querySelector("#vlibras").childNodes;
		let vLibrasEnabled;
		let elementCheck;
		
		if(elem.length < 1) return;

		elem.forEach(element => {
		if(element instanceof HTMLDivElement){
			if(element.getAttributeNames().includes("vw-access-button")){
			elementCheck = element;
			if(element.className.indexOf("active") > -1){
				//Aqui o libras ta fechado
				vLibrasEnabled = false;
			} else {
				vLibrasEnabled = true;
			}
			}
		}
		});
		if (vLibrasEnabled) {
			let vLibrasCloseBN = document.querySelector('.vpw-settings-btn-close');
			if(vLibrasCloseBN){
				vLibrasCloseBN.click();
				if (document.querySelector('.access-button'))
					document.querySelector('.access-button').click();
			}
		}
}
