import React from 'react';
import { getDic } from "../../assets/i18n/dictionary";
import { isIOS } from "react-device-detect";
import { FormColumn, FormRow } from "..";

const HeaderBox = props => {
  const {
    isVisibleChannelOne,
    isVisibleChannelTwo,
    isVisibleChannelThree,
    chanelControl,
    setChanelControl,
    setVisibleIdioms,
    iframeControl,
    setIframeControl,
    setIframeVisible,
    isVisiblePo,
    isVisibleEn,
    isVisibleEs,
    isVisibleRus,
    isVisibleFra,
    isVisibleGer,
    isVisibleIta,
    isVisibleAt,
    isVisibleAo,
    translationButtonsText,
    windowWidth,
  } = props;

  const styles = {
    position: "absolute"
  }

  // if (isIOS) {
  //   return renderIos();
  // }
  return (
    <div className="header-container" style={{ marginLeft: windowWidth < 926 ? '0%' : '5%' }}>
      {renderButtons()}
    </div>
  )

  function renderIos() {
    return (
      <FormRow margin='0px'>
        {renderButtons()}
      </FormRow>
    )
  }

  function renderButtons() {
    return (
      <>
        {isVisibleChannelOne && (
          <button
            className={
              chanelControl.current === 1
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={chanelControl.current === 1 ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}
            onClick={() => {
              setChanelControl({ ...chanelControl, current: 1 });
              setVisibleIdioms(1);
              setIframeVisible(1);
            }}
          >
            {/* CANAL 1 */}
            {chanelControl.buttonText.one !== "" ? chanelControl.buttonText.one.toUpperCase() : `${getDic("canal").toUpperCase()} 1`}
          </button>
        )}
        {isVisibleChannelTwo && (
          <button
            className={
              chanelControl.current === 2
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={chanelControl.current === 2 ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}

            onClick={async () => {
              setChanelControl({ ...chanelControl, current: 2 });
              setVisibleIdioms(2);
              setIframeVisible(2);
            }}
          >
            {chanelControl.buttonText.two !== "" ? chanelControl.buttonText.two.toUpperCase() : `${getDic("canal").toUpperCase()} 2`}
          </button>
        )}
        {isVisibleChannelThree && (
          <button
            className={
              chanelControl.current === 3
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={chanelControl.current === 3 ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}

            onClick={async () => {
              setChanelControl({ ...chanelControl, current: 3 });
              setVisibleIdioms(3);
              setIframeVisible(3);
            }}
          >
            {chanelControl.buttonText.three !== "" ? chanelControl.buttonText.three.toUpperCase() : `${getDic("canal").toUpperCase()} 3`}
          </button>
        )}
        <span>|</span>
        {isVisiblePo && (
          <button
            className={
              iframeControl.current === 'pt'
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={iframeControl.current === 'pt' ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}
            onClick={() => {
              setIframeControl({ ...iframeControl, current: 'pt' });
            }}
          >
            {translationButtonsText.pt}
          </button>
        )}
        {isVisibleEn && (
          <button
            className={
              iframeControl.current === 'en'
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={iframeControl.current === 'en' ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}

            onClick={() => {
              setIframeControl({ ...iframeControl, current: 'en' });
            }}
          >
            {translationButtonsText.en}
          </button>
        )}
        {isVisibleEs && (
          <button
            className={
              iframeControl.current === 'sp'
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={iframeControl.current === 'sp' ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}

            onClick={() => {
              setIframeControl({ ...iframeControl, current: 'sp' });
            }}
          >
            {translationButtonsText.sp}
          </button>
        )}
        {isVisibleRus && (
          <button
            className={
              iframeControl.current === 'rus'
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={iframeControl.current === 'rus' ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}

            onClick={() => {
              setIframeControl({ ...iframeControl, current: 'rus' });
            }}
          >
            {translationButtonsText.rus}
          </button>
        )}
        {isVisibleFra && (
          <button
            className={
              iframeControl.current === 'fra'
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={iframeControl.current === 'fra' ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}

            onClick={() => {
              setIframeControl({ ...iframeControl, current: 'fra' });
            }}
          >
            {translationButtonsText.fre}
          </button>
        )}
        {isVisibleGer && (
          <button
            className={
              iframeControl.current === 'ger'
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={iframeControl.current === 'ger' ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}

            onClick={() => {
              setIframeControl({ ...iframeControl, current: 'ger' });
            }}
          >
            {translationButtonsText.ger}
          </button>
        )}
        {isVisibleIta && (
          <button
            className={
              iframeControl.current === 'ita'
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={iframeControl.current === 'ita' ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}

            onClick={() => {
              setIframeControl({ ...iframeControl, current: 'ita' });
            }}
          >
            {translationButtonsText.ita}
          </button>
        )}
        {isVisibleAt && (
          <button
            className={
              iframeControl.current === 'at'
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={isVisibleAt && iframeControl.current === 'at' ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}

            onClick={() => {
              setIframeControl({ ...iframeControl, current: 'at' });

            }}
          >
            {getDic("audio-traduzido").toUpperCase()}
          </button>
        )}

        {isVisibleAo && (
          <button
            className={
              iframeControl.current === 'ao'
                ? "header-container-button-on"
                : "header-container-button-off"
            }
            style={iframeControl.current === 'ao' ? chanelControl.buttonColor !== '' ? { backgroundColor: chanelControl.buttonColor } : {} : { backgroundColor: 'transparent' }}

            onClick={() => {
              setIframeControl({ ...iframeControl, current: 'ao' });

            }}
          >
            {getDic("audio-original").toUpperCase()}
          </button>
        )}
      </>
    )
  }
};

export default HeaderBox;
