import axios from 'axios';
import { store } from '../store';
import { getToken, getUrl } from './apiService';

export async function getFirstAccessExcelReport(initialDate, endDate) {

    const token = await getToken();
    const event = store.getState().event;
    var method = getUrl().excelReports + `FirstAccess?eventId=${event.id}&initialDate=${initialDate}&endDate=${endDate}`;

    return new Promise((resolve, reject) => {

        const options = {
            url: method,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + token
            },
        };
        axios(options)
            .then(res => {
                if (res.data) {
                    resolve(res.data);
                }
                else {
                    reject(res);
                }
            })
            .catch(err => {
                console.log("Erro getFirstAccessReport", err);
                reject(err);
            })
    });
}

export async function getScheduleFirstAccessExcelReport(initialDate, endDate) {

    const token = await getToken();
    const event = store.getState().event;
    var method = getUrl().excelReports + `ScheduleFirstAccess?eventId=${event.id}&initialDate=${initialDate}&endDate=${endDate}`;

    return new Promise((resolve, reject) => {

        const options = {
            url: method,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + token
            },
        };
        axios(options)
            .then(res => {
                if (res.data) {
                    resolve(res.data);
                }
                else {
                    reject(res);
                }
            })
            .catch(err => {
                console.log("Erro getFirstAccessReport", err);
                reject(err);
            })
    });
}

export async function getStandFirstAccessExcelReport(initialDate, endDate) {

    const token = await getToken();
    const event = store.getState().event;
    var method = getUrl().excelReports + `StandFirstAccess?eventId=${event.id}&initialDate=${initialDate}&endDate=${endDate}`;

    return new Promise((resolve, reject) => {

        const options = {
            url: method,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + token
            },
        };
        axios(options)
            .then(res => {
                if (res.data) {
                    resolve(res.data);
                }
                else {
                    reject(res);
                }
            })
            .catch(err => {
                console.log("Erro getFirstAccessReport", err);
                reject(err);
            })
    });
}



export async function getChatExcelReport(initialDate, endDate) {

    const token = await getToken();
    const event = store.getState().event;
    var method = getUrl().excelReports + `Chat/simple-chat?eventId=${event.id}&initialDate=${initialDate}&endDate=${endDate}`;

    return new Promise((resolve, reject) => {

        const options = {
            url: method,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + token
            },
        };
        axios(options)
            .then(res => {
                if (res.data) {
                    resolve(res.data);
                }
                else {
                    reject(res);
                }
            })
            .catch(err => {
                console.log("Erro getChatExcelReport", err);
                reject(err);
            })
    });
}

export async function getClicableScheduleExcelReport(initialDate, endDate) {

    const token = await getToken();
    const event = store.getState().event;
    var method = getUrl().excelReports + `ClicableSchedule?eventId=${event.id}&initialDate=${initialDate}&endDate=${endDate}`;

    return new Promise((resolve, reject) => {

        const options = {
            url: method,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + token
            },
        };
        axios(options)
            .then(res => {
                if (res.data) {
                    resolve(res.data);
                }
                else {
                    reject(res);
                }
            })
            .catch(err => {
                console.log("Erro getChatExcelReport", err);
                reject(err);
            })
    });
}


export async function getQuestionsAndAnswersExcelReport(initialDate, endDate) {

    const token = await getToken();
    const event = store.getState().event;
    var method = getUrl().excelReports + `QuestionsAndAnswers?eventId=${event.id}&initialDate=${initialDate}&endDate=${endDate}`;

    return new Promise((resolve, reject) => {

        const options = {
            url: method,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + token
            },
        };
        axios(options)
            .then(res => {
                if (res.data) {
                    resolve(res.data);
                }
                else {
                    reject(res);
                }
            })
            .catch(err => {
                console.log("Erro getChatExcelReport", err);
                reject(err);
            })
    });
}
