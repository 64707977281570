import axios from 'axios';
import { store } from '../store';
import { getDic } from '../assets/i18n/dictionary';
import { getToken, getUrl } from './apiService';

export async function setComment(data) {
    const token = await getToken();
    const _eventId = store.getState().event.id;
    const _userId = store.getState().user.id;
    const method = getUrl().feeds + `api/comments`;
    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + token
            },
            data: {
                postId: data.postId,
                guestId: _userId,
                text: data.text
            }
        };
        axios(options)
            .then((res) => {
                if (res.data.success) {
                    resolve(res.data);
                } else {
                    reject(res.data);
                }
            })
            .catch((err) => {
                console.log('Erro createNewPost', err);
                reject(err);
            });
    });
}


export async function removeComment(data) {
    const token = await getToken();
    const _eventId = store.getState().event.id;
    const _userId = store.getState().user.id;
    const method = getUrl().feeds + `api/comments/${data.commentdId}`;
    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + token
            }
        };
        axios(options)
            .then((res) => {
                if (res.data.success) {
                    resolve(res.data);
                } else {
                    reject(res.data);
                }
            })
            .catch((err) => {
                console.log('Erro createNewPost', err);
                reject(err);
            });
    });
}

export async function removeLike(data) {
    const token = await getToken();
    const _eventId = store.getState().event.id;
    const _userId = store.getState().user.id;
    const method = getUrl().feeds + `api/likes/${data.likeId}`;
    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + token
            }
        };
        axios(options)
            .then((res) => {
                if (res.data.success) {
                    resolve(res.data);
                } else {
                    reject(res.data);
                }
            })
            .catch((err) => {
                console.log('Erro createNewPost', err);
                reject(err);
            });
    });
}

export async function setLike(data) {
    const token = await getToken();
    const _eventId = store.getState().event.id;
    const _userId = store.getState().user.id;
    const method = getUrl().feeds + `api/likes`;
    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + token
            },
            data: {
                postId: data.postId,
                guestId: _userId
            }
        };
        axios(options)
            .then((res) => {
                if (res.data.success) {
                    resolve(res.data);
                } else {
                    reject(res.data);
                }
            })
            .catch((err) => {
                console.log('Erro createNewPost', err);
                reject(err);
            });
    });
}

export async function removePost(data) {
    const token = await getToken();
    const _eventId = store.getState().event.id;
    const _userId = store.getState().user.id;
    const method = getUrl().feeds + `api/posts/${data.postId}`;
    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + token
            }
        };
        axios(options)
            .then((res) => {
                if (res.data.success) {
                    resolve(res.data);
                } else {
                    reject(res.data);
                }
            })
            .catch((err) => {
                console.log('Erro createNewPost', err);
                reject(err);
            });
    });
}

export async function getAllPosts(data) {
    const token = await getToken();
    const _eventId = store.getState().event.id;
    const _userId = store.getState().user.id;
    const method = getUrl().feeds + `api/posts?eventId=${_eventId}&pageSize=${data.pageSize}&pageIndex=${data.pageIndex}`;
    console.log(method);
    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + token
            }
        };
        axios(options)
            .then((res) => {
                if (res.data.success) {
                    resolve(res.data);
                } else {
                    reject(res.data);
                }
            })
            .catch((err) => {
                console.log('Erro createNewPost', err);
                reject(err);
            });
    });
}

export async function createNewPost(data) {
    const token = await getToken();
    const method = getUrl().feeds + 'api/posts';
    const _eventId = store.getState().event.id;
    const _userId = store.getState().user.id;

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + token
            },
            data: {
                guestId: _userId,
                eventId: _eventId,
                text: data.text,
                url: data.url,
            }
        };
        axios(options)
            .then((res) => {
                if (res.data.success) {
                    resolve(res.data);
                } else {
                    reject(res.data);
                }
            })
            .catch((err) => {
                console.log('Erro createNewPost', err);
                reject(err);
            });
    });
}


