import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingGlobal = props => {
    const { open } = props;

    if (open) {
        var helpButton = document.getElementById("launcher");
        if (helpButton) {
            helpButton.style.visibility = 'hidden';
        }
    }
    else {
        var helpButton = document.getElementById("launcher");
        if (helpButton) {
            helpButton.style.visibility = 'visible';
        }
    }

    return (
        <Backdrop
            style={{ backgroundColor: "#e8eced", zIndex: "999" }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
};

export default LoadingGlobal;
