import React, { useState, useEffect } from "react";
import "firebase/firestore";
import "firebase/auth";
import exportFromJSON from "export-from-json";
import { firestore } from "./../../utils/firebase";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";

const Blacklist = ({
  addBlackList,
  blackList,
  addUserToBlacklist,
  removeFromBlacklist,
  eventId,
  getCurrentTime,
}) => {
  const [user, setUser] = useState("");
  const surveyFirebase = firestore
    .collection(`surveyRanking`)
    .doc(`${eventId}`);

  const [blackListReturn] = useDocumentData(surveyFirebase);

  return (
    <div
      className={`modal ${blackList ? "show" : "fade"}`}
      style={{ display: "block", background: "#bbb6b65c" }}
      role="dialog"
      tabIndex={-1}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Adicionar Usuários</h4>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => addBlackList(false)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
              }}
            >
              <input
                type="text"
                className="form-control"
                id="survey-name"
                defaultValue=""
                placeholder="ID do usuário"
                value={user}
                onChange={(e) => setUser(e.target.value)}
              />
              <i
                class="fas fa-plus-square"
                onClick={() => {
                  addUserToBlacklist(user);
                  setUser("");
                }}
                style={{
                  fontSize: 38,
                  marginLeft: 10,
                  color: "#607d8b",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <div data-test="datatable-table" className="col-sm-12">
              <div data-test="table" className="table-responsive">
                <table
                  entries={2}
                  className=""
                  style={{
                    width: "100%",
                    borderTop: "1px solid #eff2f7",
                  }}
                >
                  <thead data-test="datatable-head">
                    <tr>
                      <th className="sorting" style={{ textAlign: "left" }}>
                        #
                      </th>
                      <th className="sorting" style={{ textAlign: "left" }}>
                        Usuário
                      </th>
                      <th className="sorting" style={{ textAlign: "center" }}>
                        Data de Inserção
                      </th>
                      <th className="sorting" style={{ textAlign: "center" }}>
                        Ação
                      </th>
                    </tr>
                  </thead>
                  <tbody data-test="table-body">
                    {blackListReturn &&
                      blackListReturn.blacklist &&
                      blackListReturn.blacklist.map((e, i) => (
                        <tr key={i}>
                          <td style={{ textAlign: "left" }}>{i + 1}</td>
                          <td style={{ textAlign: "left" }}>{e.user}</td>
                          <td style={{ textAlign: "center" }}>
                            {getCurrentTime(e.addedAt.toDate())}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <i
                              class="fas fa-trash-alt"
                              onClick={() => removeFromBlacklist(i)}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blacklist;
