import React from "react";
import ReactApexChart from "react-apexcharts";

function groupBy(collection, property) {
  var i = 0,
    val,
    index,
    values = [],
    result = [];
  for (; i < collection.length; i++) {
    val = collection[i][property];
    index = values.indexOf(val);
    if (index > -1) result[index].push(collection[i]);
    else {
      values.push(val);
      result.push([collection[i]]);
    }
  }
  return result;
}

const PieChart = ({ listData }) => {
  const config_id = groupBy(
    listData.filter((c) => c.configId !== ""),
    "configId"
  );
  const schedule = groupBy(
    listData.filter((c) => c.scheduleId !== ""),
    "scheduleId"
  );
  const pages = groupBy(
    listData.filter((e) => e.scheduleId.length < 1 && e.configId.length < 1),
    "page"
  );

  let temp_ar = [...config_id, ...schedule, ...pages];
  const series = temp_ar.map((e) => {
    return (e.length / listData.length) * 100;
  });
  const labels = temp_ar.map((e) => {
    if (!e[0].configId && !e[0].scheduleId)
      return e[0].description + `(${e[0].page})`;
    if (!e[0].configId && e[0].scheduleId)
      return e[0].description + `(${e[0].scheduleId})`;
    if (e[0].configId && !e[0].scheduleId)
      return e[0].description + `(${e[0].configId})`;
  });

  const options = {
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) => {
          return value.toFixed(2) + "%";
        },
      },
    },
    labels: labels,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };
  React.useEffect(() => {
    console.log("chart");
  }, []);
  return (
    <ReactApexChart options={options} series={series} type="pie" height="580" />
  );
};

export default PieChart;
