import { store } from '../store';
import { createFormControlModel } from './formControlModel';
import { getDic } from '../assets/i18n/dictionary';

export function createCampaignModel(
    id = '',
    eventId = 0,
    name = '',
    type = '',
    templateName = '',
    template = '',
    tags = [],
    targets = [],
    status = '',
    sendDate = '',
    targetsSent = [],
    targetsError = [],
    active = true,
    url = '',
    createDate = '',
    typeUrl = '',
) {
    const object = {
        id: id,
        eventId: eventId,
        name: name,
        type: type,
        templateName: templateName,
        template: template,
        tags: tags,
        targets: targets,
        status: status,
        sendDate: sendDate,
        targetsSent: targetsSent,
        targetsError: targetsError,
        active: active,
        url: url,
        createDate: createDate,
        typeUrl: typeUrl,
    }

    return object;
}

export function convertCampaignObjectToForm(campaign, whatsappTemplates) {
    const _form = [];

    var _index = 0;

    for (var key in campaign) {
        if (campaign.hasOwnProperty(key)) {

            var _hasForm = false;
            var _type = "text";
            var _options = null;
            var _required = true;
            var _name = "";
            var _value = campaign[key] ? campaign[key].toString() : null;

            switch (key.toString()) {
                case "id":
                    _hasForm = true;
                    _name = "id";
                    _required = true;
                    break;
                case "name":
                    _hasForm = true;
                    _name = getDic("nome");
                    _required = true;
                    break;
                case "type":
                    _hasForm = true;
                    _type = "select";
                    _options = [
                        // {
                        //     text: 'SMS',
                        //     value: 'sms'
                        // },
                        {
                            text: 'Whatsapp',
                            value: 'whatsapp'
                        }
                    ];
                    _name = getDic("tipo");
                    _required = true;
                    break;
                case "templateName":
                    _hasForm = true;
                    _type = "select";
                    _options = whatsappTemplates;
                    _name = `${getDic("nome")} Template`;
                    _required = false;
                    break;
                case "template":
                    _hasForm = true;
                    _name = 'Template';
                    _required = true;
                    break;
                case "tags":
                    _hasForm = true;
                    _name = 'Tags';
                    _required = false;
                    break;
                case "targets":
                    _hasForm = true;
                    _name = getDic("participantes");
                    _required = false;
                    _value = campaign[key] ? campaign[key] : [];
                    break;
                case "url":
                    _hasForm = true;
                    _name = `URL`;
                    _required = false;
                    break;
                default:
                    break;
            }

            if (_hasForm) {
                const _obj = createFormControlModel(
                    _index,
                    _name,
                    _value,
                    _type,
                    null,
                    null,
                    _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
                    _required,
                    null,
                    null,
                    null,
                    key
                );
                _form.push(_obj);
                _index++;
            }
        }
    }
    return _form;
}

export function convertFormToCampaignObject(form) {
    var _obj = createCampaignModel();
    form.forEach(item => {
        var _value;

        switch (item.dbReference) {
            case "id":
                _value = item.value ? item.value.toString() : null;
                break;
            case "name":
                _value = item.value ? item.value.toString() : null;
                break;
            case "type":
                _value = item.value ? item.value.toString() : null;
                break;
            case "templateName":
                _value = item.value ? item.value.toString() : null;
                break;
            case "template":
                _value = item.value ? item.value.toString() : null;
                break;
            case "tags":
                _value = item.value;
                break;
            case "targets":
                _value = item.value;
                break;
            case "url":
                _value = item.value ? item.value.toString() : null;
                break;
            default:
                break;
        }
        _obj[item.dbReference] = _value;
    });
    return _obj;
}
