import React from 'react';
import readXlsxFile from 'read-excel-file';
import { getDic } from '../assets/i18n/dictionary';
import { store } from "../store";
import { createScheduleModel, convertFormToScheduleObject, convertScheduleObjectToForm } from '../models/scheduleModel';
import { addNewScheduleApi, updateScheduleApi } from '../services/scheduleService';
import { getModalTypeList } from '../services/configService';
import { convertSerialDateToJSDate } from '../utils/fileTreatment';
import moment from 'moment';
import {
    getReturnObject,
    checkIfRegisterExists,
    validateFormControlList,
    verifyIfColtrolRowIsComplete,
    verifyIfTitleRowExists,
} from './utilsImporter';

//Imports de components
import { Loading } from '../components';

const ScheduleImporter = (props) => {

    //O método fará a leitura do arquivo
    //Irá verificar se as colunas estão conforme a planilha de exemplo
    //Depois verificará se os dados foram preencidos corretamente
    //Em caso de erro, montar um array do tipo formControlModel para retornar ao usuário onde está o erro
    function importSchedulesTable(file, schedulesList, groupList, speakersList) {
        return new Promise(async (resolve, reject) => {
            readXlsxFile(file)
                .then(async (rows) => {
                    var _returnVerify = null;

                    //Primeiro, encontrar o index da linha de controle, método duck typing
                    var _controlLineIndex = null;
                    _returnVerify = verifyIfControlRowExists(rows);
                    if (_returnVerify.hasError.error) {
                        reject(_returnVerify.hasError);
                        return;
                    }
                    else {
                        _controlLineIndex = _returnVerify.controlLineIndex;
                    }

                    //Segundo, verificar se alguma coluna foi deletada
                    const _scheduleFormList = convertScheduleObjectToForm(createScheduleModel());
                    _returnVerify = verifyIfColtrolRowIsComplete(rows[_controlLineIndex], _scheduleFormList);
                    if (_returnVerify.hasError.error) {
                        reject(_returnVerify.hasError);
                        return;
                    }

                    //Terceiro, verificar se a linha de título foi deletada
                    _returnVerify = verifyIfTitleRowExists(rows, _controlLineIndex);
                    if (_returnVerify.hasError.error) {
                        reject(_returnVerify.hasError);
                        return;
                    }

                    //Quarto, converter o array de linhas em um array de objetos do tipo schedules e formControl
                    _returnVerify = convertRowsInScheduleAndFormControlModel(rows, _controlLineIndex, groupList, speakersList);
                    var _schedulesList = _returnVerify.schedulesList;
                    if (_schedulesList === 0) {
                        var _hasError = getReturnObject();
                        _hasError.error = true;
                        _hasError.errorMessage = "Não há dados para cadastrar";
                        reject(_hasError);
                        return;
                    }

                    //Quinto, validar se os dados estão corretos
                    var _formControlList = _returnVerify.formControlList;
                    _returnVerify = validateFormControlList(_formControlList);
                    if (_returnVerify.error) {
                        reject(_returnVerify);
                        return;
                    }

                    //Sexto, salvar os dados um por um, caso retorne erro, sinalizar quais linhas não foram salvas
                    //Ao salvar, atribuir o id aos elementos salvos para retornar a planilha ao usuário
                    const _newFormControlList = [];
                    var _count = 0;

                    do {
                        const item = _formControlList[_count];
                        var _newForm = item;
                        if (!_returnVerify.error) {
                            await saveSchedule(item, schedulesList, _count, _formControlList.length)
                                .then(res => {
                                    item.find((fr, i) => {
                                        if (fr.dbReference === "id") {
                                            _newForm[i].value = res.id;
                                        }
                                    });
                                })
                                .catch(err => {
                                    _returnVerify.error = true;
                                    _returnVerify.errorMessage = "Um ou mais dados não foram salvos, verifique a planilha de retorno.";
                                    item.find((fr, i) => {
                                        if (fr.dbReference === "id") {
                                            _newForm[i].error = true;
                                            _newForm[i].errorMessage = err;
                                        }
                                    });
                                })
                                .finally(() => {
                                    _newFormControlList.push(_newForm);
                                    _count++;
                                });
                        }
                        else {
                            _count = _formControlList.length;
                        }
                    } while (_count < _formControlList.length);

                    if (_count === _formControlList.length) {
                        _returnVerify.data = _newFormControlList;
                        resolve(_returnVerify);
                    }

                })
                .catch(err => {
                    console.log("Erro importSchedulesTable", err);
                    var _hasError = getReturnObject();
                    _hasError.error = true;
                    _hasError.errorMessage = `${getDic("erro")} ${getDic("ao")} ${getDic("importar")} ${getDic("planilha")}: ${err.toString()}`;
                    reject(_hasError);
                });
        });
    }

    function verifyIfControlRowExists(rows) {
        var _hasError = getReturnObject();
        var _controlLineIndex = null;
        rows.forEach((columns, i) => {
            if (columns.includes("id") && columns.includes("title") && columns.includes("startDate")) {
                _controlLineIndex = i;
            }
        });
        if (!_controlLineIndex) {
            _hasError.error = true;
            _hasError.errorMessage = "Linha de controle deletada";
        }
        return { hasError: _hasError, controlLineIndex: _controlLineIndex };
    }

    function convertRowsInScheduleAndFormControlModel(rows, controlLineIndex, groupList, speakersList) {
        const _controlRow = rows[controlLineIndex];
        const _schedulesList = [];
        const _formControlList = [];

        //Cria um array de schedules com os dados da planilha e um array com validadores de dados
        for (let index = controlLineIndex + 2; index < rows.length; index++) {
            const item = rows[index];
            var _schedule = createScheduleModel();
            const _scheduleReturnObject = [];
            _controlRow.forEach((dbReference, i) => {
                var _value = item[i] && item[i].toString().trim() !== "" ? item[i].toString().trim() : null;
                var _objReturn = validateData(dbReference, _value, groupList, speakersList);
                _schedule[dbReference] = _value;
                _scheduleReturnObject.push({ dbReference: dbReference, field: _objReturn });
            });
            _schedulesList.push(_schedule);

            //Atribui valores e campos de erro ao form object
            const _formControlBase = convertScheduleObjectToForm(_schedule);

            const _formControlReturn = [];
            _formControlBase.forEach(obj => {
                var _field = _scheduleReturnObject.find(ret => {
                    return ret.dbReference === obj.dbReference;
                });
                var _newField = obj;
                _newField.error = _field.field.error;
                _newField.errorMessage = _field.field.errorMessage;

                //Tratamento especial para campos de data
                if (_newField.dbReference === "startDate" ||
                    _newField.dbReference === "startTime" ||
                    _newField.dbReference === "endTime"
                ) {
                    if (!_newField.error && _field.field.data && _field.field.data !== "") {
                        _newField.value = _field.field.data;
                    }
                }
                else {
                    _newField.value = _field.field.data;
                }

                //Verifica se os campos obrigatórios foram preenchidos
                if (_newField.dbReference !== "id" && _newField.required && (_newField.value === null || _newField.value === "")) {
                    _newField.error = true;
                    _newField.errorMessage = getDic("preenchimento-obrigatorio");
                }

                _formControlReturn.push(_newField);
            });
            _formControlList.push(_formControlReturn);
        }
        return { formControlList: _formControlList, schedulesList: _schedulesList };
    }

    function validateData(dbReference, data, groupList, speakersList) {
        var _returnObj = getReturnObject();
        switch (dbReference) {
            case "id":
                _returnObj.data = data && parseInt(data) ? parseInt(data) : null;
                break;
            case "startDate":
                _returnObj = validateDate(data);
                break;
            case "startTime":
                _returnObj = validateHour(data);
                break;
            case "endTime":
                _returnObj = validateHour(data);
                break;
            case "maximumNumberOfGuets":
                _returnObj.data = data && parseInt(data) ? parseInt(data) : null;
                break;
            case "restrictAccess":
                _returnObj.data = data === "true" ? true : false;
                break;
            case "meetingType":
                var _options = getModalTypeList();
                _returnObj = checkIfRegisterExists(data, _options, _returnObj);
                break;
            case "groupId":
                if (data) {
                    const _dataArray = data.split(",");
                    const _dataReturn = [];
                    _dataArray.forEach(entr => {
                        if (!_returnObj.error && entr && entr.trim() !== "") {
                            _returnObj = checkIfRegisterExists(entr, groupList, _returnObj);

                            if (!_returnObj.error) {
                                _dataReturn.push(_returnObj.data);
                            }
                        }
                    });
                    if (!_returnObj.error) {
                        _returnObj.data = _dataReturn;
                    }
                    else {
                        _returnObj.data = data;
                    }
                }
                else {
                    _returnObj.data = [];
                }
                break;
            case "speakers":
                if (data) {
                    const _dataArray = data.split(",");
                    const _dataReturn = [];
                    _dataArray.forEach(entr => {
                        if (!_returnObj.error && entr && entr.trim() !== "") {
                            _returnObj = checkIfRegisterExists(entr, speakersList, _returnObj);

                            if (!_returnObj.error) {
                                _dataReturn.push(_returnObj.data);
                            }
                        }
                    });
                    if (!_returnObj.error) {
                        _returnObj.data = _dataReturn;
                    }
                    else {
                        _returnObj.data = data;
                    }
                }
                else {
                    _returnObj.data = [];
                }
                break;
            default:
                _returnObj.data = data ? data : null;
                break;
        }
        return _returnObj;
    }

    function validateDate(date) {
        var _returnObj = getReturnObject();
        _returnObj.data = date;
        if (date && date !== "") {
            var _date = date;
            if (date.length > 10) {
                _date = moment(date).format("DD/MM/YYYY");
            }

            if (!_date || _date.length > 10 || _date.length < 10) {
                _returnObj.error = true;
                _returnObj.errorMessage = "Data preenchida em formato incorreto";
            }
            const _dateArray = _date.split("/");
            if (
                !_dateArray[0] || parseInt(_dateArray[0]) < 0 || parseInt(_dateArray[0]) > 31 ||
                !_dateArray[1] || parseInt(_dateArray[1]) < 0 || parseInt(_dateArray[1]) > 12 ||
                !_dateArray[2] || parseInt(_dateArray[2]) < 1900 || parseInt(_dateArray[2]) > 2100
            ) {
                _returnObj.error = true;
                _returnObj.errorMessage = "Data inválida";
            }

            if (!_returnObj.error) {
                const _newDate = `${_dateArray[2]}-${_dateArray[1]}-${_dateArray[0]}T00:00:00`;
                _returnObj.data = moment(_newDate).format();
                if (!_returnObj.data) {
                    _returnObj.error = true;
                    _returnObj.errorMessage = "Data inválida";
                }
            }
        }
        return _returnObj;
    }

    function validateHour(hour) {
        var _returnObj = getReturnObject();
        _returnObj.data = hour ? hour : "00:00";
        if (hour && hour !== "") {
            //Caso o horário venha no formato serial, tentar converter para string
            var _hour = hour;
            if (parseFloat(hour) > 0 && parseFloat(hour) < 1) {
                try {
                    var _date = convertSerialDateToJSDate(parseFloat(hour));
                    _hour = moment(_date).format("HH:mm");
                } catch (error) {
                    _returnObj.error = true;
                    _returnObj.errorMessage = "Horário inválido: " + error.toString();
                    return _returnObj;
                }
            }

            if (_hour.length > 5 || _hour.length < 5) {
                _returnObj.error = true;
                _returnObj.errorMessage = "Horário preenchido em formato incorreto";
            }
            const _hourArray = _hour.split(":");
            if (
                !_hourArray[0] || parseInt(_hourArray[0]) < 0 || parseInt(_hourArray[0]) > 23 ||
                !_hourArray[1] || parseInt(_hourArray[1]) < 0 || parseInt(_hourArray[1]) > 59
            ) {
                _returnObj.error = true;
                _returnObj.errorMessage = "Horário inválido";
            }

            if (!_returnObj.error) {
                var _date = moment().format().split("T");
                _returnObj.data = `${_date[0]}T${_hour}:00-03:00`;
            }
        }
        return _returnObj;
    }

    function saveSchedule(formControl, schedulesList, index, totalRegister) {

        return new Promise(async (resolve, reject) => {
            setLoadingMessage(`${getDic("salvando")} ${index + 1} de ${totalRegister}`);
            var _newSchedule = convertFormToScheduleObject(formControl);

            _newSchedule.eventId = store.getState().event.id;

            if (_newSchedule.id > 0) {
                //Ao dar update, verificar se o schedule é do evento correspondente
                var _isScheduleFromEvent = schedulesList.find(sched => {
                    return sched.id === _newSchedule.id;
                });
                if (_isScheduleFromEvent) {
                    await updateScheduleApi(_newSchedule)
                        .then(res => {
                            _newSchedule = res;
                            resolve(_newSchedule);
                        })
                        .catch(err => {
                            reject(getDic("erro ", err.toString()));
                        });
                }
                else {
                    reject("ID não pertence a este evento");
                }
            }
            else {
                await addNewScheduleApi(_newSchedule)
                    .then(res => {
                        _newSchedule.id = res.id;
                        resolve(_newSchedule);
                    })
                    .catch(err => {
                        reject(getDic("erro ", err.toString()));
                    });
            }
        });
    }

    const importFile = () => {
        setOpenLoading(true);
        setLoadingMessage(getDic("carregando"));

        var _returnObject;
        importSchedulesTable(props.file, props.schedulesList, props.groupList, props.speakersList)
            .then(res => {
                _returnObject = res;
            })
            .catch(err => {
                _returnObject = err;
            })
            .finally(() => {
                setOpenLoading(false);
                returnImportFile(_returnObject);
            });
    }

    //Loading control
    const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
    const [openLoading, setOpenLoading] = React.useState(false);

    React.useEffect(() => {
        importFile();
    }, []);

    const returnImportFile = (returnObject) => {
        if (props.returnImportFile) {
            props.returnImportFile(returnObject);
        }
    }

    return (
        <>
            <Loading open={openLoading} message={loadingMessage} />
        </>
    );
}

export default ScheduleImporter;
