import moment from 'moment';
import { getDic } from '../assets/i18n/dictionary';
import { getDate } from '../services/apiService';
import { createFormControlModel } from '../models/formControlModel';
import { store } from '../store';
import { getPagesList } from '../services/configService';

export function createPushNotificationModel(
  id = 0,
  text = "",
  eventId = 0,
  createDate = "",
  viewed = false,
  active = true,
) {
  const ret = {
    id: id,
    text: text,
    eventId: eventId,
    createDate: moment(createDate).locale('pt-br').format(),
    viewed: viewed,
    active: active,

    //Parametro para a página de controle
    selected: false,
  }

  return ret;
}

export function createPushObjectV2(
  version = 2,
  id = '',
  text = '',
  text2 = '',
  date = '',
  command = '',
  page = '',
  openConfigId = 0,
  openScheduleId = 0,
  replaceOpenConfig = false,
  groups = [],
  configs = [],
  schedules = [],
  questions = [],
  style = {
    colorButton1: '',
    textColorButton1: '',
    textButton1: '',
  },
  questionsObject = [],
  active = true,
) {
  return {
    version: version,
    id: id,
    command: command,
    text: text,
    text2: text2,
    date: date,
    page: page,
    openConfigId: openConfigId,
    openScheduleId: openScheduleId,
    replaceOpenConfig: replaceOpenConfig,
    groups: groups,
    configs: configs,
    schedules: schedules,
    questions: questions,
    style: style,
    questionsObject: questionsObject,
    active: active
  };
}

export function convertPushNotificationObjectToNotificationV2(text) {
  // ${PUSHV2{}}
  var _pushObj = createPushObjectV2();

  return new Promise((resolve, reject) => {
    try {
      if (text.includes('${PUSHV2{')) {
        _pushObj = convertPushV2(text);
      } else {
        _pushObj = convertPushV1(text);
      }
      resolve(_pushObj);
    } catch (error) {
      console.log("Erro convertPushNotificationObjectToNotificationV2", error);
      resolve(_pushObj);
    }
  });
}

function convertPushV1(text = '') {
  var _ret = createPushObjectV2();
  _ret.version = 1;
  _ret.text = text;

  return _ret;
}

function convertPushV2(text = '') {
  var _ret = createPushObjectV2();
  _ret.version = 2;

  try {
    var _textString = text.replace('${PUSHV2', '').slice(0, -1);
    const _pushObject = JSON.parse(_textString);

    for (var key in _pushObject) {
      if (_ret.hasOwnProperty(key)) {
        var _value;
        if (!Array.isArray(_ret[key]) && typeof _ret[key] === 'object' && _ret[key] !== null) {
          _value = { ..._ret[key], ..._pushObject[key] }
        } else {
          _value = _pushObject[key];
        }
        _ret = { ..._ret, [key]: _value };
      }
    }
  } catch (error) {
    console.log('Erro convertPushV2', error);
    _ret.text = text;
    _ret.version = 1;
  }

  return _ret;
}

export function getPushNotificationCommands() {
  const _ret = [
    {
      text: getDic('selecione'),
      value: '',
    },
    {
      text: `${getDic('abrir')} ${getDic('configuracao')}`,
      value: 'openConfig',
    },
    {
      text: `${getDic('abrir')} ${getDic('programacao')}`,
      value: 'openSchedule',
    },
    {
      text: `${getDic('sincronizar')} ${getDic('dados')}`,
      value: 'forceRefresh',
    },
    {
      text: `Quiz`,
      value: 'quiz',
    },
    // {
    //   text: `${getDic('abrir')} Modal ${getDic('arrastavel')}`,
    //   value: 'openDraggableModal',
    // },
  ];
  return _ret;
}

function getBooleanList() {
  const _obj = [
    {
      text: getDic("nao"),
      value: false
    },
    {
      text: getDic("sim"),
      value: true
    },
  ]
  return _obj;
}

export function convertPushV2ObjectToForm(pushV2, groupsList, questionsList) {
  var _form = [];

  var _index = 0;

  const _configList = store.getState().config.map(conf => {
    return { value: conf.id, text: `${conf.id} - ${conf.codeScreen} ${conf.description ? "- " + conf.description : conf.tooltip ? "- " + conf.tooltip : ""}` }
  });
  const _schedulesList = store.getState().schedules.map(sched => {
    return { value: sched.id, text: `${sched.id} - ${sched.title}` }
  });

  for (var key in pushV2) {
    if (pushV2.hasOwnProperty(key)) {

      var _hasForm = false;
      var _type = "text";
      var _options = null;
      var _required = true;
      var _name = "";
      var _value = pushV2[key] ? pushV2[key].toString() : '';

      switch (key.toString()) {
        case "id":
          _hasForm = true;
          _name = "id";
          _required = true;
          break;
        case "text":
          _hasForm = true;
          _name = getDic("texto");
          _required = true;
          break;
        case "text2":
          _hasForm = true;
          _name = `${getDic("texto")} 2`;
          _required = false;
          break;
        case "command":
          _hasForm = true;
          _name = getDic("comando");
          _options = getPushNotificationCommands();
          _type = "select";
          _required = false;
          break;
        case "page":
          _hasForm = true;
          _name = getDic("pagina");
          _type = "select";
          _required = false;
          const _pagesList = getPagesList()
            .map(pag => {
              return {
                value: pag.id,
                text: pag.string,
              };
            })
            .sort((a, b) => {
              return a.text > b.text
                ? 1
                : b.text > a.text
                  ? -1
                  : 0;
            });
          _options = _pagesList;
          break;
        case "openConfigId":
          _hasForm = true;
          _name = `${getDic("abrir")} ${getDic("configuracao")}`;
          _options = _configList;
          _type = "select";
          _required = false;
          break;
        case "openScheduleId":
          _hasForm = true;
          _name = `${getDic("abrir")} ${getDic("programacao")}`;
          _options = _schedulesList;
          _type = "select";
          _required = false;
          break;
        case "replaceOpenConfig":
          _hasForm = true;
          _name = `${getDic("fechar")} Modal ${getDic("atual")} ${getDic("ao")} ${getDic("abrir")} ${getDic("novo")}`;
          _required = false;
          _options = getBooleanList();
          _type = "select";
          _value = _value === "true" ? true : false;
          break;
        case "groups":
          _hasForm = true;
          _name = getDic("grupo");
          _required = false;
          _options = groupsList;
          _type = "select";
          _value = pushV2[key] ? pushV2[key] : [];
          break;
        case "questions":
          _hasForm = true;
          _name = getDic("perguntas");
          _required = false;
          _options = questionsList;
          _type = "select";
          _value = pushV2[key] ? pushV2[key] : [];
          break;
        case "date":
          _value = moment().format();
          _hasForm = true;
          _name = `${getDic("data")} ${getDic("inicio")}`;
          _required = true;
          _type = "datetime-local";
          break;
        default:
          break;
      }

      if (_hasForm) {
        const _obj = createFormControlModel(
          _index,
          _name,
          _value,
          _type,
          null,
          null,
          _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
          _required,
          null,
          null,
          null,
          key
        );
        _form.push(_obj);
        _index++;
      }
    }
  }

  _form = convertPushV2StyleObjectToForm(pushV2, _form);
  return _form;
}

function convertPushV2StyleObjectToForm(pushV2, form) {
  const _form = form;

  var _index = 0;

  for (var key in pushV2.style) {
    if (pushV2.style.hasOwnProperty(key)) {

      var _hasForm = false;
      var _type = "text";
      var _options = null;
      var _required = true;
      var _name = "";
      var _value = pushV2.style[key] ? pushV2.style[key].toString() : '';

      switch (key.toString()) {
        case "colorButton1":
          _hasForm = true;
          _name = `${getDic("cor")} ${getDic("botao")}`;
          _required = false;
          _value = _value !== '' ? _value : '#6169AA';
          break;
        case "textColorButton1":
          _hasForm = true;
          _name = `${getDic("cor")} ${getDic("texto")} ${getDic("botao")}`;
          _required = false;
          _value = _value !== '' ? _value : 'white';
          break;
        case "textButton1":
          _hasForm = true;
          _name = `${getDic("texto")} ${getDic("botao")}`;
          _required = false;
          _value = _value !== '' ? _value : 'Enquete';
          break;
        default:
          break;
      }

      if (_hasForm) {
        const _obj = createFormControlModel(
          _index,
          _name,
          _value,
          _type,
          null,
          null,
          _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
          _required,
          null,
          null,
          null,
          key
        );
        _form.push(_obj);
        _index++;
      }
    }
  }
  return _form;
}

export function convertFormToPushV2Object(form) {
  var _obj = createPushObjectV2();
  var _style = _obj.style;
  form.forEach(item => {
    var _value;
    var _hasObj = true;

    switch (item.dbReference) {
      case "openConfigId":
        _value = item.value ? parseInt(item.value) : 0;
        break;
      case "openScheduleId":
        _value = item.value ? parseInt(item.value) : 0;
        break;
      case "colorButton1":
        _hasObj = false;
        _style.colorButton1 = item.value ? item.value : '';
        break;
      case "textColorButton1":
        _hasObj = false;
        _style.textColorButton1 = item.value ? item.value : '';
        break;
      case "textButton1":
        _hasObj = false;
        _style.textButton1 = item.value ? item.value : '';
        break;
      default:
        _value = item.value;
        break;
    }
    if (_hasObj) {
      _obj[item.dbReference] = _value;
    }
  });

  _obj.style = _style;
  return _obj;
}

