export function createAccessModel(
    actionId = 0,
    page = "",
    accessDate = "",
    ipAddress = "",
    loginId = "",
    login = "",
    note = "",
    objectDescription = "",
    objectTypeDescription = "",
    eventId = 0
) {
    const access = {
        applicationId: 2,
        actionId: actionId,
        page: page,
        accessDate: accessDate,
        ipAddress: ipAddress,
        loginId: loginId,
        login: login,
        note: note,
        objectDescription: objectDescription,
        objectTypeDescription: objectTypeDescription,
        eventId: eventId
    }

    return access;
}
