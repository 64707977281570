import React from 'react';
import Blink from 'react-blink-text';

const TextMediun = ({
    children,
    fontWeight = 'normal',
    color = "black",
    onClick,
    wordBreak = "break-word",
    blink = false,
    fontSize = "17px",
}) => {

    const styles = {
        text: {
            fontFamily: "verdana",
            fontSize: fontSize,
            fontWeight: fontWeight,
            wordBreak: wordBreak,
            color: color
        }
    };

    if (!blink) {
        return (
            <div style={styles.text} onClick={onClick}>
                {children}
            </div>
        )
    }
    else {
        return (
            <div style={styles.text} onClick={onClick}>
                <Blink color={color} text={children} fontSize={fontSize}>
                </Blink>
            </div>
        );
    }
};

export default TextMediun;
