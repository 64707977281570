import { SET_LANGUAGE } from '../actions';

const INITIAL_STATE = { lang: "pt-br" };

export default function languageReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_LANGUAGE:
            return { ...state, ...action.lang };
        default:
            return state;
    }
}
