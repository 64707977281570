import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import "../../scss/Transmission.scss";
import { getConfigListByPage } from "../../services/configService";
import TransmissionShowBox from "../../components/TransmissionShowBox";
import { changeUrlTags } from "../../utils/urlTagsTreatment";

function Transmission(props) {

  const [configList, setConfigList] = useState([]);
  const [channels, setChannels] = useState([]);
  const [urlDownloadFile, setUrlDownloadFile] = useState('');
  const [idPergunta, setIdPergunta] = useState('');
  const [buttonSendColor, setButtonSendColor] = useState('');
  const [idEmoji, setIdEmoji] = useState('');
  const [idResearch, setIdResearch] = useState('');
  const [idConfigChat, seIdConfigChat] = useState('');
  const [useNewChat, setUseNewChat] = useState(false);
  const [idPerguntaWordCloud, setIdPerguntaWordCloud] = useState("");
  const [isVisibleChat, setIsVisibleChat] = useState(true);
  const [isVisibleSchedule, setIsVisibleSchedule] = useState(false);
  const [scheduleColor, setScheduleColor] = useState('');
  const [scheduleOrderFirst, setScheduleOrderFirst] = useState(false);
  const [scheduleHide, setScheduleHide] = useState('');
  const [isVisibleWordCloud, setIsVisibleWordCloud] = useState(true);
  const [isVisibleWordCloudTitle, setIsVisibleWordCloudTitle] = useState(true);
  const [isVisibleLogoRight, setIsVisibleLogoRight] = useState(true);
  const [isVisibleSideRight, setIsVisibleSideRight] = useState(true);
  const [isVisibleHeader, setIsVisibleHeader] = useState(true);
  const [isVisibleEmoji, setIsVisibleEmoji] = useState(true);
  const [isVisibleResearch, setIsVisibleResearch] = useState(true);
  const [isVisibleQuestion, setIsVisibleQuestion] = useState(true);
  const [isVisibleDownload, setIsVisibleDownload] = useState(true);
  const [downloadButtonColor, setDownloadButtonColor] = useState('');
  const [downloadInstructionsText, setDownloadInstructionsText] = useState('');
  const [sideRightLogo, setSideRightLogo] = useState("");
  const [sideRightImg01, setSideRightImg01] = useState([]);
  const [sideRightImg02, setSideRightImg02] = useState([]);
  const [zoomUrl, setZoomUrl] = useState('');
  const [zoomNumber, setZoomNumber] = useState('');
  const [zoomPassword, setZoomPassword] = useState('');
  const [zoomPhoneNumber, setZoomPhoneNumber] = useState('');
  const [zoomPhoneUrl, setZoomPhoneUrl] = useState('');
  const [whatsappUrl, setWhatsappUrl] = useState('');
  const [whatsappPhoneNumber, setWhatsappPhoneNumber] = useState('');
  const [liveCommerceList, setLiveCommerceList] = useState([]);
  const [translationButtonsText, setTranslationButtonsText] = useState({
    pt: 'PORT',
    en: 'ENG',
    sp: 'SPA',
    rus: 'RUS',
    fre: 'FRE',
    ger: 'GER',
    ita: 'ITA'
  });

  useEffect(() => {
    getPageConfig();
  }, []);

  const getPageConfig = async () => {
    const _url = !props.isModal ? props.match.url : `/${props.event.id}/transmission28`;
    const _configList = await getConfigListByPage(_url);
    _configList.sort((a, b) => (a.codeScreen > b.codeScreen) ? 1 : -1);
    setConfigList(_configList);
    _configList.find((item) => {
      if (item.codeScreen === "ELEMENT-001") {
        let newObj = item.url;
        if (item.url.length > 0) {
          const _urls = changeUrlTags(item.url);
          _urls.forEach((el) => {
            if (el.includes("canal1-pt:")) {
              newObj["canal1-pt"] = el.replace("canal1-pt:", "");
            }
            if (el.includes("canal2-pt:")) {
              newObj["canal2-pt"] = el.replace("canal2-pt:", "");
            }
            if (el.includes("canal3-pt:")) {
              newObj["canal3-pt"] = el.replace("canal3-pt:", "");
            }
            if (el.includes("canal1-en:")) {
              newObj["canal1-en"] = el.replace("canal1-en:", "");
            }
            if (el.includes("canal2-en:")) {
              newObj["canal2-en"] = el.replace("canal2-en:", "");
            }
            if (el.includes("canal3-en:")) {
              newObj["canal3-en"] = el.replace("canal3-en:", "");
            }
            if (el.includes("canal1-es:")) {
              newObj["canal1-es"] = el.replace("canal1-es:", "");
            }
            if (el.includes("canal2-es:")) {
              newObj["canal2-es"] = el.replace("canal2-es:", "");
            }
            if (el.includes("canal3-es:")) {
              newObj["canal3-es"] = el.replace("canal3-es:", "");
            }
            if (el.includes("canal1-rus:")) {
              newObj["canal1-rus"] = el.replace("canal1-rus:", "");
            }
            if (el.includes("canal2-rus:")) {
              newObj["canal2-rus"] = el.replace("canal2-rus:", "");
            }
            if (el.includes("canal3-rus:")) {
              newObj["canal3-rus"] = el.replace("canal3-rus:", "");
            }
            if (el.includes("canal1-fra:")) {
              newObj["canal1-fra"] = el.replace("canal1-fra:", "");
            }
            if (el.includes("canal2-fra:")) {
              newObj["canal2-fra"] = el.replace("canal2-fra:", "");
            }
            if (el.includes("canal3-fra:")) {
              newObj["canal3-fra"] = el.replace("canal3-fra:", "");
            }
            if (el.includes("canal1-ger:")) {
              newObj["canal1-ger"] = el.replace("canal1-ger:", "");
            }
            if (el.includes("canal2-ger:")) {
              newObj["canal2-ger"] = el.replace("canal2-ger:", "");
            }
            if (el.includes("canal3-ger:")) {
              newObj["canal3-ger"] = el.replace("canal3-ger:", "");
            }
            if (el.includes("canal1-ita:")) {
              newObj["canal1-ita"] = el.replace("canal1-ita:", "");
            }
            if (el.includes("canal2-ita:")) {
              newObj["canal2-ita"] = el.replace("canal2-ita:", "");
            }
            if (el.includes("canal3-ita:")) {
              newObj["canal3-ita"] = el.replace("canal3-ita:", "");
            }
            if (el.includes("canal1-at:")) {
              newObj["canal1-at"] = el.replace("canal1-at:", "");
            }
            if (el.includes("canal2-at:")) {
              newObj["canal2-at"] = el.replace("canal2-at:", "");
            }
            if (el.includes("canal3-at:")) {
              newObj["canal3-at"] = el.replace("canal3-at:", "");
            }
            if (el.includes("canal1-ao:")) {
              newObj["canal1-ao"] = el.replace("canal1-ao:", "");
            }
            if (el.includes("canal2-ao:")) {
              newObj["canal2-ao"] = el.replace("canal2-ao:", "");
            }
            if (el.includes("canal3-ao:")) {
              newObj["canal3-ao"] = el.replace("canal3-ao:", "");
            }
            if (el.includes("text-canal1:")) {
              newObj["text-canal1"] = el.replace("text-canal1:", "");
            }
            if (el.includes("text-canal2:")) {
              newObj["text-canal2"] = el.replace("text-canal2:", "");
            }
            if (el.includes("text-canal3:")) {
              newObj["text-canal3"] = el.replace("text-canal3:", "");
            }
            if (el.includes("button-channel-color:")) {
              newObj["button-channel-color"] = el.replace("button-channel-color:", "");
            }


          });
        }
        setChannels(item.url);
      }

      if (item.codeScreen === "ELEMENT-002") {
        item.url.forEach((el) => {
          if (el.includes("urldownloadfile")) {
            setUrlDownloadFile(el.replace("urldownloadfile:", ""));
          }
        })
      }

      if (item.codeScreen === "ELEMENT-003") {
        item.url.forEach((el) => {
          if (el.includes("isvisible")) {
            setIsVisibleQuestion(el.replace("isvisible=", "") === "true");
          }
          if (el.includes("idpergunta")) {
            setIdPergunta(parseInt(el.replace("idpergunta:", "")));
          }
          if (el.includes("button-send-color:")) {
            setButtonSendColor(el.replace("button-send-color:", ""));
          }
          if (el.includes("zoom-phone-number:")) {
            setZoomPhoneNumber(el.replace("zoom-phone-number:", ""));
          }
          if (el.includes("zoom-phone-url")) {
            setZoomPhoneUrl(el.replace("zoom-phone-url=", ""));
          }
          if (el.includes("zoom-url")) {
            setZoomUrl(el.replace("zoom-url=", ""));
          }
          if (el.includes("zoom-number")) {
            setZoomNumber(el.replace("zoom-number:", ""));
          }
          if (el.includes("zoom-password")) {
            setZoomPassword(el.replace("zoom-password:", ""));
          }
          if (el.includes("whatsapp-url")) {
            setWhatsappUrl(el.replace("whatsapp-url=", ""));
          }
          if (el.includes("whatsapp-phone-number")) {
            setWhatsappPhoneNumber(el.replace("whatsapp-phone-number:", ""));
          }
        })
      }

      if (item.codeScreen === "ELEMENT-004") {
        item.url.forEach((el) => {
          if (el.includes("isvisible")) {
            setIsVisibleChat(el.replace("isvisible=", "") === "true");
          }
          if (el.includes("idconfigChat")) {
            seIdConfigChat(parseInt(el.replace("idconfigChat:", "")));
          }
          if (el.includes("useNewChat")) {
            setUseNewChat(el.replace("useNewChat=", "") === "true")
          }
          if (el.includes("isVisibleSchedule")) {
            setIsVisibleSchedule(el.replace("isVisibleSchedule=", "") === "true");
          }
          if (el.includes("scheduleColor")) {
            setScheduleColor(el.replace("scheduleColor=", ""));
          }
          if (el.includes("scheduleOrderFirst")) {
            setScheduleOrderFirst(el.replace("scheduleOrderFirst=", "") === "true");
          }
          if (el.includes("scheduleHide")) {
            setScheduleHide(el.replace("scheduleHide=", ""));
          }
        })
      }

      if (item.codeScreen === "ELEMENT-006") {
        item.url.forEach((el) => {
          if (el.includes("isvisible")) {
            setIsVisibleWordCloud(el.replace("isvisible=", "") === "true");
          }
          if (el.includes("idpergunta")) {
            setIdPerguntaWordCloud(parseInt(el.replace("idpergunta:", "")));
          }
          if (el.includes("wordcloud-title")) {
            setIsVisibleWordCloudTitle(el.replace("wordcloud-title:", "") === "true");
          }
        })
      }

      if (item.codeScreen === "ELEMENT-007") {
        item.url.forEach((el) => {
          if (el.includes("isvisible")) {
            setIsVisibleLogoRight(el.replace("isvisible=", "") === "true");
          }
          if (el.includes("image-001")) {
            setSideRightLogo(el.replace("image-001=", ""));
          }
        });
      }

      if (item.codeScreen === "ELEMENT-008") {
        item.url.forEach((el) => {
          if (el.includes("isvisible")) {
            setIsVisibleSideRight(el.replace("isvisible=", "") === "true");
          }
          if (el.includes("image-001")) {
            setSideRightImg01(el.replace("image-001=", ""));
          }
          if (el.includes("image-002")) {
            setSideRightImg02(el.replace("image-002=", ""));
          }
        });
      }

      if (item.codeScreen === "ELEMENT-009") {
        item.url.forEach((el) => {
          if (el.includes("isvisible")) {
            setIsVisibleHeader(el.replace("isvisible=", "") === "true");
          }
        });
      }

      if (item.codeScreen === "ELEMENT-010") {
        item.url.forEach((el) => {
          if (el.includes("isvisible")) {
            setIsVisibleEmoji(el.replace("isvisible=", "") === "true");
          }
          if (el.includes("idpergunta")) {
            setIdEmoji(parseInt(el.replace("idpergunta:", "")));
          }
        });
      }
      if (item.codeScreen === "ELEMENT-011") {
        item.url.forEach((el) => {
          if (el.includes("idpergunta")) {
            setIdResearch(parseInt(el.replace("idpergunta:", "")));
          }
          if (el.includes("isvisible")) {
            setIsVisibleResearch(el.replace("isvisible=", "") === "true");
          }
        });
      }

      if (item.codeScreen === "ELEMENT-013") {
        item.url.forEach((el) => {
          if (el.includes("isvisible")) {
            setIsVisibleDownload(el.replace("isvisible=", "") === "true" ? true : false);
          }
          if (el.includes("downloadButtonColor")) {
            setDownloadButtonColor(el.replace("downloadButtonColor=", ""));
          }
          if (el.includes("downloadInstructionsText")) {
            try {
              let _array = item.urlString.split(';');
              _array.forEach(str => {
                if (str.includes("downloadInstructionsText")) {
                  setDownloadInstructionsText(str.replace("downloadInstructionsText=", "").trim());
                }
              });
            } catch (error) {
              console.log('Error ELEMENT-013 downloadInstructionsText', error);
            }
          }
        });
      }

      if (item.codeScreen === "ELEMENT-014") {
        getLiveCommerceConfig(item);
      }

      if (item.codeScreen === "ELEMENT-015") {
        getTranslationButtonsText(item);
      }
    });
  };

  const getLiveCommerceConfig = (config) => {
    try {
      const _liveCommerceList = [];

      config.url.forEach(item => {
        let _str = item.trim().substring(1);
        _str = _str.trim().replace(/.$/, "");
        _str = _str.split(',');

        let _config = {
          image: '',
          configId: '',
        }

        _str.forEach(prop => {
          let _prop = prop.trim().split(/:(.+)/);
          _prop[0] = _prop[0].trim();
          _prop[1] = _prop[1].trim();
          _config[_prop[0]] = _prop[0] === "configId" ? parseInt(_prop[1]) : _prop[1];
        });

        _liveCommerceList.push(_config);
      });

      setLiveCommerceList(_liveCommerceList);
    } catch (err) {
      console.log('Erro getLiveCommerceConfig', err);
    }
  }

  const getTranslationButtonsText = (config) => {
    try {
      let _text = { ...translationButtonsText };
      config.url.forEach(item => {
        let _prop = item.split(':');
        _text[_prop[0]] = _prop[1];
      });

      setTranslationButtonsText(_text);
    } catch (err) {
      console.log('Erro getTranslationButtonsText', err);
    }
  }

  return (
    <div className="">
      {channels.length > 0 && sideRightImg01.length > 0 && <TransmissionShowBox
        configList={configList}
        sideRight={isVisibleSideRight}
        sideRightLogo={sideRightLogo}
        sideRightImg01={sideRightImg01}
        sideRightImg02={sideRightImg02}
        logoRight={isVisibleLogoRight}
        chatBox={isVisibleChat}
        isVisibleSchedule={isVisibleSchedule}
        scheduleColor={scheduleColor}
        scheduleOrderFirst={scheduleOrderFirst}
        scheduleHide={scheduleHide}
        wordCloud={isVisibleWordCloud}
        isVisibleWordCloudTitle={isVisibleWordCloudTitle}
        headerBox={isVisibleHeader}
        emojiBox={isVisibleEmoji}
        downloadBox={isVisibleDownload}
        downloadButtonColor={downloadButtonColor}
        downloadInstructionsText={downloadInstructionsText}
        researchBox={isVisibleResearch}
        questionBox={isVisibleQuestion}
        channels={channels}
        urlDownloadFile={urlDownloadFile}
        configChat={idConfigChat}
        useNewChat={useNewChat}
        idPergunta={idPergunta}
        buttonSendColor={buttonSendColor}
        idPerguntaWordCloud={idPerguntaWordCloud}
        idEmoji={idEmoji}
        idResearch={idResearch}
        currentPage={"TRANSMISSION28"}
        isModal={props.isModal}
        height={props.height}
        width={props.width}
        history={props.history}
        zoomPhoneNumber={zoomPhoneNumber}
        zoomPhoneUrl={zoomPhoneUrl}
        zoomUrl={zoomUrl}
        zoomNumber={zoomNumber}
        zoomPassword={zoomPassword}
        whatsappUrl={whatsappUrl}
        whatsappPhoneNumber={whatsappPhoneNumber}
        liveCommerceList={liveCommerceList}
        translationButtonsText={translationButtonsText}
      />}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(Transmission);
