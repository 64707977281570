import React, { useRef } from "react";
import { getDic } from "../../../assets/i18n/dictionary";
import {
  addNewChatPersonalMessageApi,
  getMessagesByChatIdApi,
  getOpenedChatsByUserApi,
  getChatSize,
  setMessageSawApi,
  setNewChatMessage,
  getChatMessage,
  setRawMessage,
  getAllChatGuests
} from "../../../services/chatPersonalService";
import { connect } from "react-redux";

import MessageCard from "../MessageCard";
import ChatContext from "../ChatContext";
import CloseIcon from "@material-ui/icons/Close";

import './emoji-mart/emoji-mart.css';
import { Picker } from 'emoji-mart';

import { ActivityIndicator, Link } from "../../../components";

var timeoutMessages;

const convertToTime = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate() > 9 ? a.getDate() : "0" + a.getDate();
  var hour = a.getHours() > 9 ? a.getHours() : "0" + a.getHours();
  var min = a.getMinutes() > 9 ? a.getMinutes() : "0" + a.getMinutes();
  var sec = a.getSeconds() > 9 ? a.getSeconds() : "0" + a.getSeconds();
  var dateNow = new Date();

  var time1 = `Visto hoje às ${hour}:${min}`;
  var time2 = `Visto em ${date} de ${month} as ${hour}:${min}`;
  return UNIX_timestamp
    ? dateNow.getDate() == a.getDate()
      ? time1
      : time2
    : "";
};

function ChatArea(props) {
  const ContextEffect = React.useContext(ChatContext);
  const conversationEnd = useRef(null);
  const staticTempPrivateChat = useRef(ContextEffect.tempPrivateChat);
  staticTempPrivateChat.current = ContextEffect.tempPrivateChat;
  const [inputMessage, setInputMessages] = React.useState("");
  const [areaMessages, setAreMessages] = React.useState("");
  const [userData, setUserData] = React.useState({ name: "", image: "" });
  const [ currentUser, setCurrentUser] = React.useState({})
  const [ localMessage, setLocalMessage] = React.useState([]);
  const [ openModalEmoji, setModalEmoji] = React.useState(false)
  const chatArea = React.useRef(null);
  //
  const [currentPageIndex, setPageIndex] = React.useState(1);
  const pageIndex = useRef(currentPageIndex);
  pageIndex.current = currentPageIndex;
  //Preserva o contexto da variável
  const chatObj = useRef(ContextEffect.chatObject);
  ContextEffect.chatObject.avatar ? chatObj.current = ContextEffect.chatObject : chatObj.current = {
    avatar: "https://s3.amazonaws.com/bucket.aws.public/FotoPerfilInteegra/DEFAULT.png",
    chat_id: ContextEffect.chatObject.chat_id,
    id: ContextEffect.chatObject.id,
    institution: ContextEffect.chatObject.institution,
    name: ContextEffect.chatObject.name,
  };
  //
  let privateMessages;
  //
  const chatID = useRef(ContextEffect.currenChatID);
  const privateChatStatic = useRef(ContextEffect.privateChat);
  const currenGuestIDStatic = useRef(ContextEffect.currenGuestID);
  const tempPrivateChatStatic = useRef(ContextEffect.tempPrivateChat);

  privateChatStatic.current = ContextEffect.privateChat;
  currenGuestIDStatic.current = ContextEffect.currenGuestID;
  chatID.current = ContextEffect.currenChatID;
  tempPrivateChatStatic.current = ContextEffect.tempPrivateChat;

  const setMessage = (value) => {
    setInputMessages(value);
    ContextEffect.setUserNewMwssage(value);
    scrollToBottom();
  };
  const scrollToBottom = () => {
    //conversationEnd.current.scrollIntoView({ behavior: "smooth" });
    if (!conversationEnd.current) return;
    conversationEnd.current.scrollTop = conversationEnd.current.scrollHeight;
  };
  const setMessageSawApi = saw => {
    //console.log(saw);
    saw.forEach(element => {
      if(element && element.membersWhoSawTheMessage && element.membersWhoSawTheMessage.filter(e=> e == props.user.id).length < 1){
        setRawMessage({
          "ChatId": element.chatId,
          "MessageId": element.id,
          "GuestId": props.user.id
        })
      }
    });
  }

  const getDateHash = (date) => {
    let DateFull = new Date(date);
    return DateFull.getDate() +"/"+ DateFull.getMonth() +"/"+ DateFull.getFullYear();
  }

  const getAllOldPrivateMessages = () => {
    if(!chatObj.current.chat_id) return;    
    let Newmessages = [];
    setPageIndex(pageIndex.current + 1);
    let Oldmessages = localMessage;
    let currentIndex = pageIndex.current + 1;
    return getChatMessage(chatObj.current.chat_id, 30 * currentIndex, 1).then(result => {

      const { messages, totalPages } = result.data;
      if(messages.length > 0){
        let messagesTemp = [];
        messages.forEach(element => {
          messagesTemp.push(element);
        });
        
        Newmessages = [...messagesTemp];

        messagesTemp = messagesTemp.sort(function (a, b) {
          return a.dateCreated > b.dateCreated ? 1 : b.dateCreated > a.dateCreated ? -1 : 0;
        });
        
        let currentDay = getDateHash(new Date());
        messagesTemp.forEach(msg => {
          if(currentDay != getDateHash(msg.dateCreated))
          {
            msg.first = true;
            currentDay = getDateHash(msg.dateCreated);
          } else {
            msg.first = false;
          }          
        })

        setLocalMessage(messagesTemp);

      } else {
        console.log("sem mais mensagens");
      }
    })
  }
  
  //localMessage, setLocalMessage
  const getAllPrivateMessages = () => {
    if(!chatObj.current.chat_id) return;
    let Oldmessages = localMessage;
    let Newmessages = [];
        
    return getChatMessage(chatObj.current.chat_id, 30 * pageIndex.current, 1).then(result => {
      //console.log(result)
      const { messages, totalPages } = result.data;
      if(messages.length > 0){
        let messagesTemp = [];
        messages.forEach(element => { 
            messagesTemp.push(element);
        });

        Newmessages = [...Oldmessages, ...messagesTemp];

        messagesTemp = messagesTemp.sort(function (a, b) {
          return a.dateCreated > b.dateCreated ? 1 : b.dateCreated > a.dateCreated ? -1 : 0;
        });

        let currentDay = getDateHash(new Date());
        messagesTemp.forEach(msg => {
          if(currentDay != getDateHash(msg.dateCreated))
          {
            msg.first = true;
            currentDay = getDateHash(msg.dateCreated);
          } else {
            msg.first = false;
          }          
        })
        setLocalMessage(messagesTemp);

      } else {
        setLocalMessage([]);
      }
    }).finally(()=>{
      if(Oldmessages.length != Newmessages.length){
        scrollToBottom();
        setMessageSawApi(Newmessages);
      }
    })
  }
  
  const getAllChat = async () => {
    return getAllChatGuests(props.user.id).then(result=>{

      const { data } = result;
      let activeChats = data.filter(e=> e.messages.length > 0);
      let chatData = [];
      activeChats.forEach(element => {
        element.lastMessage = element.messages[element.messages.length - 1].dateCreated;
        chatData.push(element);
      });

      ContextEffect.setPrivateChat([...chatData])
    })
  }

  const sendConversation = () => {
    getAllChat();
    ContextEffect.setSending(true);
    setInputMessages("");
    setNewChatMessage({
      "chatId": chatObj.current.chat_id,
      "guestId":chatObj.current.id,
      "message": inputMessage
    }).then(result=>{
      ContextEffect.setSending(false);
      getAllPrivateMessages()
    }).finally(()=> {
      scrollToBottom();
      chatArea.current.focus();
    })
    /*
    if (inputMessage.length > 0 && chatID.current > 0) {
      ContextEffect.setSending(true);
      setInputMessages("");
      addNewChatPersonalMessageApi(
        inputMessage,
        currenGuestIDStatic.current
      ).then((result) => {
        getMessagesAPI(false, false, 0, chatID.current);
        ContextEffect.setSending(false);
      });
    }*/
  };
  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendConversation();
    }
  };

  // Adiciona emoji ao campo
  const onEmojiClick = (emojiObject, props) => {
    let sym = emojiObject.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);

    var message = `${inputMessage}${emoji}`;
    setMessage(message)
    setModalEmoji(false);
  };

  /*const getCurrentMessages = (chatId, index, mode) => {
    getMessagesByChatIdApi(chatId, index).then((result) => {
      console.log(
        `todas mensagens do atual chat ${chatId} com index: ${index}`,
        result
      );
      let chats = [];
      setAreaMessages(result.messages);
    });
  };*/
  const getPrivate = () => {
    getOpenedChatsByUserApi().then((res) => {
      const joinAllChats = [];
      if (res.chatsList.length > 0) {
        const finalList = [];
        res.chatsList.forEach((item) => {
          const hasItem = finalList.find((e) => {
            return e.chatId === item.chatId;
          });
          if (!hasItem) {
            finalList.push(item);
          }
        });
        staticTempPrivateChat.current.forEach((item, i) => {
          const hasItem = finalList.find((e) => {
            return e.chatId === item.chatId;
          });
          if (hasItem) {
            ContextEffect.setTempPrivateChat(
              staticTempPrivateChat.current.filter(
                (e) => e.chatId != hasItem.chatId
              )
            );
          }
        });
        
        ContextEffect.setPrivateChat(
          joinAllChats.concat(finalList, staticTempPrivateChat.current)
        );
      }
    });
  };

  // Método para pegar mensagens novas
  const getMessagesAPI = (
    first = false,
    moreMessages = false,
    lastIndex,
    chatId
  ) => {
    //Buscar mensagens a cada período de tempo
    clearTimeout(timeoutMessages);
    var index = (lastIndex ? lastIndex : ContextEffect.pageIndex) + 1;
    if (!moreMessages) {
      ContextEffect.setPageIndex(0);
      index = 1;
    }

    var searchMoreMessages = false;
    var hasError = false;
    var messagesReceived = 0;
    var newMessages = 0;
    getMessagesByChatIdApi(
      chatId, // <== Adicionar ID do chat
      index,
      "" // <== Adicionar campo de busca de mensagens
    )
      .then((res) => {
        if (res.messages && res.messages.length > 0) {
          //Verifica quantas mensagens foram recebidas e quantas não estavam no array
          //Caso o array inteiro não estiver na lista, buscar a próxima página até encontrar a ultima mensagem
          messagesReceived = res.messages.length;

          var finalMessages = first ? [] : localMessage;
          res.messages.forEach((mes) => {
            //Verifica se a mensagem já existe, se não, inserir no array
            const message = localMessage.find((mesState) => {
              return mesState.id === mes.id;
            });
            if (!message) {
              newMessages++;
              finalMessages.push(mes);
            }
          });

          if (!first && messagesReceived === newMessages) {
            searchMoreMessages = true;
          }

          //Ordenar mensagens
          finalMessages = finalMessages.sort(function (a, b) {
            return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
          });

          ContextEffect.setMessages(finalMessages);
          ContextEffect.setPageIndex(index);
          ContextEffect.setTotalPageIndex(res.totalPages);

          privateChatStatic.current.find((item) => {
            return item.chatId === chatId;
          });

          //Marca a ultima mensagem como lida
          setMessageSawApi(
            finalMessages[finalMessages.length - 1].id,
            currenGuestIDStatic.current
          );
        }
      })
      .catch((err) => {
        //console.log("Erro getMessagesApi", err);
        hasError = true;
      })
      .finally(() => {
        if (!hasError && searchMoreMessages) {
          getMessagesAPI(false, true, index, chatID.current);
        } else {
          scrollToBottom();
          timeoutMessages = setTimeout(() => {
            getPrivate();
            getMessagesAPI(false, false, 0, chatID.current);
            // }, 10000); // 1 minuto = 60000
          }, 10000); // 1 minuto = 60000
        }
      });
  };

  // Método para pegar mensagens antigas
  const getOldMessages = (first = false, lastIndex, chatId) => {
    //Buscar mensagens a cada período de tempo
    clearTimeout(timeoutMessages);
    ContextEffect.setLoadingMoreMessages(true);

    var index = (lastIndex ? lastIndex : ContextEffect.pageIndex) + 1;
    if (first) {
      ContextEffect.setPageIndex(0);
      index = 1;
    }

    var searchMoreMessages = false;
    var hasError = false;
    var newMessages = 0;

    getMessagesByChatIdApi(
      chatId, // <== Adicionar ID do chat
      index,
      "" // <== Adicionar campo de busca de mensagens
    )
      .then((res) => {
        if (res.messages && res.messages.length > 0) {
          ContextEffect.setTotalPageIndex(res.totalPages);

          var finalMessages = ContextEffect.messages;

          res.messages.forEach((mes) => {
            //Verifica se a mensagem já existe, se não, inserir no array
            const message = ContextEffect.messages.find((mesState) => {
              return mesState.id === mes.id;
            });
            if (!message) {
              newMessages++;
              finalMessages.push(mes);
            }
          });

          if (
            newMessages < getChatSize() &&
            index < ContextEffect.totalPageIndex
          ) {
            searchMoreMessages = true;
          }

          //Ordenar mensagens
          finalMessages = finalMessages.sort(function (a, b) {
            return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
          });

          ContextEffect.setMessages(finalMessages);
          ContextEffect.setPageIndex(index);
        }
      })
      .catch((err) => {
        console.log("Erro getMessagesApi", err);
        hasError = true;
      })
      .finally(() => {
        ContextEffect.setLoadingMoreMessages(false);
        if (index === ContextEffect.totalPageIndex) {
          ContextEffect.setNoMoreOldMessages(true);
        }

        if (!hasError && searchMoreMessages) {
          getOldMessages(false, index, chatId);
        } else {
          timeoutMessages = setTimeout(() => {
            getPrivate();
            getMessagesAPI(false, false, 0, ContextEffect.stateChatID);
            // }, 10000); // 1 minuto = 60000
          }, 10000); // 1 minuto = 60000
        }
      });
  };

  React.useEffect(() => {   
    ContextEffect.setMessages([]);
    chatArea.current.focus();
    setPageIndex(1);
    getAllPrivateMessages();
    const privateMessages = setInterval(() => {
        getAllPrivateMessages();
    }, 10000);
    return () => {
      clearTimeout(privateMessages);
    }  
  }, [ContextEffect.chatObject]);

  const messageFiltered = () => {
    if(!localMessage ) return 0;
    const _messages = localMessage.filter(e=> e.membersWhoSawTheMessage && e.guestId != props.user.id && e.membersWhoSawTheMessage.length > 1)

    return _messages;
  }

  
  return (
    <div
      className="col-sm-8 col-md-8 conversation"
      style={props.collapseChat ? { height: "92vh" } : { height: "0" }}
    >
      <div className="row heading">
        <div className="col-sm-9 col-md-10 col-xs-9 heading-avatar">
          <div className="heading-avatar-icon" style={{ display: "flex" }}>
            <img              
              style={{cursor: 'pointer'}}
              src={
                chatObj.current.avatar ? 
                chatObj.current.avatar
                  : "https://s3.amazonaws.com/bucket.aws.public/FotoPerfilInteegra/DEFAULT.png"                  
              }              
            />
            <div>
              <a
                className="heading-name-meta"
                style={{ marginLeft: 15, marginTop: -6, position: "relative" }}
              >
                {ContextEffect.chatObject && ContextEffect.chatObject.name}
              </a>
              <span
                style={{
                  top: 0,
                  marginLeft: 20,
                  position: "relative",
                  fontSize: 13,
                  color: "#93918f",
                }}
              >
                {ContextEffect.chatObject &&
                  ` ${convertToTime(
                    messageFiltered()[messageFiltered().length - 1] ? messageFiltered()[messageFiltered().length - 1].dateCreated : 0
                  )}`}
              </span>
            </div>
          </div>
        </div>

        <div className="col-sm-2 col-xs-2  heading-dot pull-right">
          <i
            className="fa fa-ellipsis-v fa-2x  pull-right display-dots"
            aria-hidden="true"
            onClick={() => props.onChatSelectPress(false)}
          />
        </div>
      </div>
      <div className="row message" id="conversationS" ref={conversationEnd}>
        {!ContextEffect.noMoreOldMessages && (
          <div className="row message-previous">
            <div className="col-sm-12 previous">
              {ContextEffect.loadingMoreMessages ? (
                <ActivityIndicator />
              ) : (
                <a
                  onClick={() => getAllOldPrivateMessages()}
                  id="ankitjain28"
                  name={20}
                  style={{display: localMessage.length > 25 ? 'block' : 'none'}}
                >
                  {getDic("mais")}...
                </a>
              )}
            </div>
          </div>
        )}
        {localMessage.map((e, index) => (
          <MessageCard
            key={index}
            message={e.message}
            firstDay={e.first}
            sender={props.user.id != e.guestId ? true : false}
            timestamp={e.dateCreated}
            data={e}
          />
        ))
      }

        <div ref={conversationEnd} style={{ height: 10 }} />
      </div>
      {ContextEffect.sending ? (
        <div className="col-sm-12 previous">
          <ActivityIndicator />
        </div>
      ) : (
        <div className="row reply">
          <div className="col-sm-1 col-xs-1 reply-emojis">
              {!openModalEmoji ? (
                <i
                  className="fa fa-smile-o fa-2x"
                  onClick={() => setModalEmoji(true)}
                />
              ) : (
                  <Link color="gray">
                    <CloseIcon
                      onClick={() => setModalEmoji(false)}
                    />
                  </Link>
                )}
            </div>
            <div className="col-sm-10 col-xs-10 reply-main">
              <textarea
                className="form-control"
                rows={1}
                maxlength="3000"
                ref={chatArea}
                id="comment"
                defaultValue={""}
                value={inputMessage}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={_handleKeyDown}
              />
            </div>
            <div className="col-sm-1 col-xs-1 reply-send">
              <i
                className="fa fa-send fa-2x"
                aria-hidden="true"
                onClick={() => sendConversation()}
              />
            </div>
        </div>
      )}
      {openModalEmoji && (
        <Picker
          showPreview={false}
          showSkinTones={false}
          style={{
            position: "fixed",
            zIndex: 199999,
            bottom: 59,
            height: 'auto'
          }}
          onClick={(emoji, props) => { onEmojiClick(emoji, props); } }
          />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps)(ChatArea);
