import React from "react";
import { getDic } from '../../assets/i18n/dictionary';
import { getUrl } from '../../services/apiService';
import { connect } from "react-redux";
import 'fabric-webpack'
import { jsPDF } from "jspdf";
import { isIOS } from "react-device-detect";

//Imports de components
import {
  Container,
  FormRow,
  FormColumn,
  Button,
  InputFieldOutlined,
  Dialog
} from '../../components';
import FabricCanvasDesign from './FabricCanvasDesign';
import FabricCanvasImage from './FabricCanvasImage';
import FabricCanvasText from './FabricCanvasText';

const UserCertificatePage = (props) => {
  const [state, setState] = React.useState(false);
  const [colorBackground, setColorBackground] = React.useState("#e8eced");
  const [buttonConfig, setButtonConfig] = React.useState({
    colorButton: "#3f51b5",
    colorTextButton: "white",
  });
  const [userEditName, setUserEditName] = React.useState();
  const [textConfig, setTextConfig] = React.useState({
    text: props.user.name.substring(0, 30),
    left: 550,
    top: 300,
    textColor: "yellow",
    textFontSize: 20,
    textFontType: "verdana",
  });
  const [imageConfig, setImageConfig] = React.useState({
    image: '',
  });
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [removeButtonEditName, setRemoveButtonEditName] = React.useState(false);

  React.useEffect(() => {
    getPageData();
    addListeners();
  }, []);

  const getPageData = React.useCallback(() => {
    if (props.chosenConfig && props.chosenConfig.urlString) {
      try {
        var _array = props.chosenConfig.urlString.split(",style{");
        setImageConfig({ image: `${getUrl().corsAnywhere}${_array[0]}` });

        _array = _array[1].replace('}', '').split(',');

        var _textConfig = textConfig;
        var _buttonConfig = buttonConfig;
        _array.forEach(item => {
          var _prop = item.split(':');
          if (_textConfig.hasOwnProperty(_prop[0])) {
            _textConfig[_prop[0]] = _prop[1] === "true" ? true : _prop[1] === "false" ? false : _prop[1];
          }
          if (_buttonConfig.hasOwnProperty(_prop[0])) {
            _buttonConfig[_prop[0]] = _prop[1] === "true" ? true : _prop[1] === "false" ? false : _prop[1];
          }

          if (_prop[0] === "height") {
            _textConfig.top = parseInt(_prop[1]) / 100 * 550;
          }
          if (_prop[0] === "width") {
            _textConfig.left = parseInt(_prop[1]) / 100 * 1000;
          }

          if (_prop[0] === "colorBackground") {
            setColorBackground(_prop[1]);
          }

          if (_prop[0] === "removeButtonEditName") {
            setRemoveButtonEditName(_prop[1] && _prop[1].toLowerCase() === 'true' ? true : false);
          }
        });
        setTextConfig(_textConfig);
        setButtonConfig(_buttonConfig);

        resizeCanvas();
      } catch (error) {
        console.log(error);
      }
    }
  });

  const addListeners = () => {
    window.addEventListener("resize", resizeCanvas);
  }

  const resizeCanvas = () => {
    var _canvas = document.getElementById("certificate");
    if (_canvas && _canvas.style) {
      _canvas.style.width = `100%`;
      _canvas.style.height = `${_canvas.offsetWidth * 0.55}px`;
      setState(!state);
    }
  }

  const downloadImageJpeg = () => {
    if (isIOS) {
      var link = document.createElement("a");
      link.download = `${getDic("certificado")}.svg`;
      link.target = "_blank";
      link.href = document.getElementById("certificate").toDataURL();
      link.click();
    } else {
      var link = document.createElement('a');
      link.download = `${getDic("certificado")}.jpeg`;
      link.href = document.getElementById("certificate").toDataURL();
      link.click();
    }
  }

  const downloadImagePdf = () => {
    if (isIOS) {
      const doc = new jsPDF();
      const _dataUrl = document.getElementById("certificate").toDataURL("image/jpeg");
      var svgimg = document.createElementNS("http://www.w3.org/2000/svg", "image");
      svgimg.setAttributeNS("http://www.w3.org/1999/xlink", 'xlink:href', _dataUrl);
      document.getElementById("pdfSvg").appendChild(svgimg);
      doc.addSvgAsImage(svgimg, 0, 0);
      doc.save(`${getDic("certificado")}.pdf`);
    } else {
      var imgData = document.getElementById("certificate").toDataURL("image/jpeg");
      var pdf = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [10.42, 5.73]
      });

      pdf.addImage(imgData, 'JPEG', 0, 0);
      pdf.save(`${getDic("certificado")}.pdf`);
    }
  }

  const openEditUserName = () => {
    setUserEditName(textConfig.text);
    setDialogOpen(true);
    setIsEditing(true);
  }

  const saveUserName = () => {
    var _textConfig = textConfig;
    _textConfig.text = userEditName;
    setTextConfig(_textConfig);
    setDialogOpen(false);
    setIsEditing(false);
  };

  return (
    <Container
      background={colorBackground}
      align="center"
      backgroundSize="100% 100%"
      height="100vh"
    >
      <FormColumn align="center" background={colorBackground} margin="0px">
        {!removeButtonEditName && (
          <FormRow align="center" background="transparent" margin="0px">
            <div style={{ padding: 5 }}>
              <Button
                color={buttonConfig.colorButton}
                textColor={buttonConfig.colorTextButton}
                onClick={openEditUserName}
              >
                {getDic("editar")} {getDic("nome")}
              </Button>
            </div>
          </FormRow>
        )}
        <FormRow align="center" background="transparent" margin="0px">
          <div style={{ padding: 5 }}>
            <Button
              color={buttonConfig.colorButton}
              textColor={buttonConfig.colorTextButton}
              onClick={downloadImageJpeg}
            >
              JPEG
            </Button>
          </div>
          {/* {!isIOS && (
            <div style={{ padding: 5 }}>
              <Button
                color={buttonConfig.colorButton}
                textColor={buttonConfig.colorTextButton}
                onClick={downloadImagePdf}
              >
                PDF
              </Button>
            </div>
          )} */}
        </FormRow>

        {!isEditing && (
          <FormRow align="center" background="transparent" margin="0px" paddingTop="5px">
            <FabricCanvasDesign key="1">
              <FabricCanvasText
                text={textConfig.text}
                left={textConfig.left}
                top={textConfig.top}
                color={textConfig.textColor}
                fontSize={textConfig.textFontSize}
                fontType={textConfig.textFontType}
              />
              <FabricCanvasImage url={imageConfig.image} scale={1} top={0} />
            </FabricCanvasDesign>
            <svg id="pdfSvg" xmlns="http://www.w3.org/2000/svg" />
          </FormRow>
        )}
      </FormColumn>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={getDic("nome")}
        textButtonYes={getDic("salvar")}
        textButtonNo={getDic("cancelar")}
        onClickYes={saveUserName}
        onClickNo={() => {
          setDialogOpen(false);
          setIsEditing(false);
        }}
      >
        <FormRow align="center" paddingTop="5px">
          <InputFieldOutlined
            key="name"
            value={userEditName}
            onChange={event => setUserEditName(event.target.value)}
            maxLength={30}
          />
        </FormRow>
      </Dialog>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(UserCertificatePage);
