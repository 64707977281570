import React from 'react';
import Button from '@material-ui/core/Button';
import { getDic } from '../assets/i18n/dictionary';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

class AppBarHomeButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            event: this.props.event
        }
    }

    render() {
        const { color = null, size = null } = this.props;

        return (
            <Button
                color={color ? color : "inherit"}
                size={size ? size : "medium"}
            >
                <Link to={"/" + this.state.event.id + "/eventhall"} style={{ textDecoration: 'none', color: 'white' }}>
                    {getDic("hall-evento")}
                </Link>
            </Button>
        )
    }
};

function mapStateToProps(state) {
    return {
        event: state.event
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBarHomeButton)
