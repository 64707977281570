import axios from "axios";
import { store } from "../store";
import { getDic } from "../assets/i18n/dictionary";
import { getUrl, getToken, getDate } from "./apiService";
import {
  createQuestionModel,
  createAwnswerModel,
} from "../models/questionModel";
import { createSurveyModel } from "../models/surveyModel";
import { firestore } from "../utils/firebase";

export function getTypeQuestionList() {
  const _obj = [
    {
      text: getDic("texto").toUpperCase(),
      value: "TEXTO",
    },
    {
      text: getDic("selecionar").toUpperCase(),
      value: "SELECIONAR",
    },
    {
      text: getDic("enquete").toUpperCase(),
      value: "ENQUETE",
    },
  ];
  return _obj;
}

export async function getQuestionsApi(eventId, configId, listIds) {
  var method =
    getUrl().hall + "event/question/list?eventId=" + eventId.toString();
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data.data) {
          const questions = [];
          const categories = [];
          const def = {
            text: getDic("todos"),
            value: null,
          };
          categories.push(def);

          res.data.data.forEach((object) => {
            const item = object;

            if (item.active) {
              const question = createQuestionModel(
                item.id,
                item.eventId,
                item.typeQuestion,
                item.category,
                item.questionText,
                item.answer,
                null,
                null
              );

              const hasCategory = categories.find((cat) => {
                return cat.value === question.category;
              });
              if (!hasCategory) {
                const category = {
                  text: question.category,
                  value: question.category,
                };
                categories.push(category);
              }

              //Verifica se as perguntas são para esta configuração
              if (configId) {
                if (
                  question.scheduleOrConfigId.includes(configId) ||
                  question.scheduleOrConfigId.length === 0
                ) {
                  questions.push(question);
                }
              } else if (listIds && listIds.length > 0) {
                const id = listIds.find((i) => {
                  return parseInt(i) === question.id;
                });
                if (id) {
                  questions.push(question);
                }
              } else {
                questions.push(question);
              }
            }
          });

          resolve({ questions: questions, categories: categories });
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getQuestionsApi", err);
        reject(err);
      });
  });
}

export async function addNewQuestionApi(question) {
  var method = getUrl().hall + "event/question/register";
  const token = await getToken();
  const _event = store.getState().event;

  const _questions = [
    {
      eventID: _event.id,
      questionText: question.question,
      typeQuestion: question.typeQuestion,
      answer: question.valuesStr,
      category: question.category,
      schedules: question.schedulesId,
      configs: question.configsId,
    },
  ];

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: _questions,
    };
    axios(options)
      .then((res) => {
        if (res && res.data[0].success) {
          const _objReturn = createQuestionModel(
            res.data[0].data.id,
            _event.id,
            question.typeQuestion,
            question.category,
            question.question,
            question.valuesStr,
            question.schedulesId,
            question.configsId
          );

          resolve(_objReturn);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log("Erro addNewQuestionApi", err);
        reject(err);
      });
  });
}

export async function updateQuestionApi(question) {
  var method = getUrl().hall + "event/question/update";
  const token = await getToken();
  const _event = store.getState().event;

  const _questions = [
    {
      id: question.id,
      eventID: _event.id,
      questionText: question.question,
      typeQuestion: question.typeQuestion,
      answer: question.valuesStr,
      category: question.category,
      schedules: question.schedulesId,
      configs: question.configsId,
    },
  ];

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: _questions,
    };
    axios(options)
      .then((res) => {
        if (res && res.data[0].success) {
          const _objReturn = createQuestionModel(
            res.data[0].data.id,
            _event.id,
            question.typeQuestion,
            question.category,
            question.question,
            question.valuesStr,
            question.schedulesId,
            question.configsId
          );

          resolve(_objReturn);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log("Erro updateQuestionApi", err);
        reject(err);
      });
  });
}

export async function deleteQuestionApi(questionId) {
  var method = getUrl().hall + "event/question/inactive";
  const token = await getToken();

  const _questions = [
    {
      id: questionId,
    },
  ];

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: _questions,
    };
    axios(options)
      .then((res) => {
        if (res && res.data.success) {
          resolve(true);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log("Erro deleteQuestionApi", err);
        reject(err);
      });
  });
}

export async function sendAnswersApi(attributesValueList) {
  try {
    var method = getUrl().hall + "event/question/answer/register";
    const token = await getToken();
    const user = store.getState().user;
    const eventId = store.getState().event.id;

    //Monta lista de atributos para sistema
    const attributesApi = [];
    attributesValueList.forEach((item) => {
      const attribute = {
        questionId: item.attributeId ? item.attributeId : item.id,
        answer: item.value.toString(),
        login: `${user.name} (${user.id})`,
        guestId: user.id,
      };
      attributesApi.push(attribute);
    });

    try {
      await saveAnswersAtFirestoreSurveyList(eventId, user, attributesApi);
    } catch (error) {
      console.log("Erro saveAnswersAtFirestoreSurveyList", error);
    }

    return new Promise((resolve, reject) => {
      const options = {
        url: method,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + token,
        },
        data: attributesApi,
      };
      axios(options)
        .then((res) => {
          if (res) {
            resolve(true);
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          console.log("Erro sendAnswersApi", err);
          reject(err);
        });
    });
  } catch (error) {
    console.log("Erro sendAnswersApi", error);
    throw error;
  }
}

async function saveAnswersAtFirestoreSurveyList(eventId, user, attributesList) {
  try {
    const _surveys = await firestore
      .collection(`surveyRanking/${eventId}/survey`)
      .get();
    if (_surveys.empty) {
      return;
    }

    let _count = 0;
    do {
      let _answer = attributesList[_count];

      let _surveysWithQuestion = getSurveysThatContainsQuestion(
        _surveys,
        _answer
      );

      if (_surveysWithQuestion && _surveysWithQuestion.length > 0) {
        await saveAnswerAtSurveys(_surveysWithQuestion, _answer);
      }
      _count++;
    } while (_count < attributesList.length);
  } catch (error) {
    console.log("Error saveAnswerAtFirestoreSurveyList", error);
    throw error;
  }

  function getSurveysThatContainsQuestion(surveys, answer) {
    let _surveysWithQuestion = [];
    surveys.docs.forEach((s) => {
      let _s = s.data();
      let _question = _s.questions.find((q) => {
        return q.question.id === answer.questionId;
      });
      if (_question) {
        _surveysWithQuestion.push({
          ..._s,
          id: s.id,
        });
      }
    });

    return _surveysWithQuestion;
  }

  async function saveAnswerAtSurveys(surveys, answer) {
    try {
      let _count = 0;
      do {
        let _survey = surveys[_count];
        let _question = _survey.questions.find((q) => {
          return q.question.id === answer.questionId;
        });
        let _isAnswerCorrect =
          answer.answer.trim() === _question.answer.trim() ? true : false;
        let _date = await getDate();
        let _currentDate =
          new Date(_date).getTime() - new Date("01/01/2020").getTime();

        let _users = await firestore
          .collection(`surveyRanking/${eventId}/survey/${_survey.id}/guests`)
          .where("userId", "==", user.id)
          .get();

        if (_users.empty) {
          await saveDataWithoutUser(
            _survey,
            _question,
            answer,
            _isAnswerCorrect,
            _currentDate
          );
        } else {
          let _user = _users.docs[0].data();
          let _hasAnswered = _user.answeredQuestions.find((q) => {
            return q.questionInfo.question.id === answer.questionId;
          });
          if (!_hasAnswered) {
            await saveDataWithUser(
              _survey,
              _question,
              answer,
              _isAnswerCorrect,
              _currentDate,
              _user,
              _users.docs[0].id
            );
          }
        }

        _count++;
      } while (_count < surveys.length);
    } catch (error) {
      console.log("Error saveAnsweAtSurveys", error);
      throw error;
    }
  }

  async function saveDataWithoutUser(
    survey,
    question,
    answer,
    isAnswerCorrect,
    currentDate
  ) {
    try {
      let _dataToSave = {
        userId: user.id,
        userName: user.name,
        userEmail: user.email,
        answeredQuestions: [
          {
            isCorrect: isAnswerCorrect,
            questionInfo: question,
            answer: answer.answer.trim(),
          },
        ],
        points: isAnswerCorrect ? parseInt(question.score) : 0,
        dateAverage: currentDate,
      };

      await firestore
        .collection(`surveyRanking/${eventId}/survey/${survey.id}/guests`)
        .add(_dataToSave);
    } catch (error) {
      console.log("Error saveDataWithoutUser", error);
      throw error;
    }
  }

  async function saveDataWithUser(
    survey,
    question,
    answer,
    isAnswerCorrect,
    currentDate,
    surveyUserData,
    surveyUserId
  ) {
    try {
      let _dataToSave = { ...surveyUserData };

      if (isAnswerCorrect) {
        _dataToSave.points = _dataToSave.points + parseInt(question.score);
      }
      _dataToSave.dateAverage = _dataToSave.dateAverage + currentDate;
      _dataToSave.answeredQuestions.push({
        isCorrect: isAnswerCorrect,
        questionInfo: question,
        answer: answer.answer.trim(),
      });

      await firestore
        .collection(`surveyRanking/${eventId}/survey/${survey.id}/guests`)
        .doc(surveyUserId)
        .update(_dataToSave);
    } catch (error) {
      console.log("Error saveDataWithoutUser", error);
      throw error;
    }
  }
}

export async function getSurveyRankingById(id) {
  try {
    let _res = await firestore
      .collection(
        `surveyRanking/${store.getState().event.id}/survey/${id}/guests`
      )
      .orderBy("points", "desc")
      .orderBy("dateAverage")
      .limit(100)
      .get();

    let _return = [];

    _res.docs.forEach((d) => {
      _return.push({
        ...d.data(),
        id: d.id,
      });
    });

    return _return;
  } catch (error) {
    console.log("Error getSurveyRankingById", error);
    throw error;
  }
}

export async function getAnswersListApi(
  eventId,
  pageIndex,
  pageSize,
  category,
  questionId,
  status,
  initialDate,
  endDate,
  guestId
) {
  const token = await getToken();

  var method =
    getUrl().hall +
    `event/question/awnswer/list/${eventId}?pageSize=${pageSize}&pageIndex=${pageIndex}`;
  if (category) {
    method = method + "&category=" + category;
  }
  if (questionId) {
    method = method + "&questionId=" + questionId;
  }
  if (status) {
    if (status === "active") {
      method = method + "&activeAnswer=true";
    } else {
      method = method + "&activeAnswer=false";
    }
  }
  if (initialDate) {
    method = method + "&initialDate=" + initialDate;
  }
  if (endDate) {
    method = method + "&endDate=" + endDate;
  }
  if (guestId) {
    method = method + "&guestId=" + guestId;
  }

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data.result) {
          const awnswers = [];

          res.data.result.forEach((item) => {
            var awnswer = createAwnswerModel(
              item.questionId,
              item.question,
              item.questionType,
              item.answer,
              item.eventId,
              item.activeQuestion,
              item.activeAnswer,
              item.questionCategory,
              item.answerId,
              item.userAnswer,
              item.answerDate,
              item.answerHour,
              item.answerLogin
            );
            awnswers.push(awnswer);
          });

          resolve({ awnswers, totalPages: res.data.totalPages });
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getAnswersListApi", err);
        reject(err);
      });
  });
}

export async function getAnswersQuantityByQuestionApi(questionId) {
  const token = await getToken();
  var method = getUrl().hall + `event/survey/question/${questionId}`;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data.success) {
          const awnswers = [];

          res.data.data.forEach((item) => {
            var awnswer = createSurveyModel(
              item.questionId,
              item.question,
              item.eventId,
              item.answer,
              item.quantityOfAnswers
            );
            awnswers.push(awnswer);
          });

          resolve(awnswers);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getAnswersQuantityByQuestionApi", err);
        reject(err);
      });
  });
}

export async function getAnswersByQuestionAndGuestApi(initialDate, endDate) {
  const token = await getToken();
  const event = store.getState().event;
  var method =
    getUrl().base +
    `report/quiz/answers?eventId=${event.id}&initialDate=${initialDate}&endDate=${endDate}`;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro getAnswersByQuestionAndGuestApi", err);
        reject(err);
      });
  });
}

export async function addNewSurvey(data) {
  var method = getUrl().hall + "event/questionnarie";
  //var method =
  //"https://api-externa.inteegra.com.br/hall/api/event/questionnarie";
  const token = await getToken();
  const _event = store.getState().event;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: data,
    };
    axios(options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log("Erro addNewQuestionApi", err);
        reject(err);
      });
  });
}

export async function getQuestionsFromSurvey(questionnarieId) {
  const _event = store.getState().event;

  var method =
    getUrl().hall +
    `event/questionnarie?eventId=${_event.id}&guestId=0&questionnarieId=${questionnarieId}&questionId=0`;
  //var method = `https://api-externa.inteegra.com.br/hall/api/event/questionnarie?eventId=${_event.id}&guestId=0&questionnarieId=${questionnarieId}&questionId=0`;
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      /* data: data, */
    };
    axios(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("Erro addNewQuestionApi", err);
        reject(err);
      });
  });
}

export async function inactiveAnswersApi(answers) {
  const token = await getToken();
  var method = getUrl().hall + `event/question/answer/inactive`;

  const data = [];
  answers.forEach((item) => {
    const obj = {
      id: item.answerId,
    };
    data.push(obj);
  });

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: data,
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          resolve(true);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro inactiveAnswersApi", err);
        reject(err);
      });
  });
}
