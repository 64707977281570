import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { getSchedulesApi, addNewScheduleApi, updateScheduleApi, deleteScheduleApi } from '../services/scheduleService';
import { createScheduleModel, convertFormToScheduleObject, convertScheduleObjectToForm } from '../models/scheduleModel';
import { getFileExtension } from '../utils';
import { getSpeakersApi } from '../services/speakerService';
import { getGroupListApi } from '../services/attributesService';
import { validateForm } from '../utils';
import ScheduleImporter from '../importers/scheduleImporter';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import ScheduleImporterExcel from '../excel/ScheduleImporterExcel';

//Imports de components
import {
  MenuButton,
  BackButton,

  Alert,
  Loading,
  Container,
  FormRow,
  FormColumn,
  GridRow,
  GridCell,
  TextMedium,
  Button,
  Dialog,
  InputFieldOutlined,
  TextSmall,
  InfiniteScroll,
  SelectList,
  Modal,
  Link,
  ModalConcatUrl,
} from '../components';

const SchedulesRegisterPage = (props) => {
  const [state, setState] = React.useState(true);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(true);

  //Dialog control
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [dialogConfirmKey, setDialogConfirmKey] = React.useState("");
  const [dialogConfirmKeyErrorMessage, setDialogConfirmKeyErrorMessage] = React.useState("");

  //Modal Control
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalConcatUrl, setOpenModalConcatUrl] = React.useState(false);
  const [openModalImporter, setOpenModalImporter] = React.useState(false);

  const [schedulesList, setSchedulesList] = React.useState([]);
  const [chosenSchedule, setChosenSchedule] = React.useState(null);
  const [newScheduleForm, setNewScheduleForm] = React.useState([]);

  const [groupList, setGroupList] = React.useState([]);
  const [speakersList, setSpeakersList] = React.useState([]);

  const [importerTable, setImporterTable] = React.useState(null);
  const [importerReturnTable, setImporterReturnTable] = React.useState(null);
  const [executeScheduleImporter, setExecuteScheduleImporter] = React.useState(false);

  React.useEffect(() => {
    //Bloqueio caso o usuário não seja admin
    if (!props.user.isAdmin) {
      props.history.goBack();
    }

    var helpButton = document.getElementById("launcher");
    if (helpButton) {
      helpButton.style.visibility = 'hidden';
    }

    getPageData();
    insertNewAccess(props.match.url, "", getSystemActions().accessPage);

    return () => {
      var helpButton = document.getElementById("launcher");
      if (helpButton) {
        helpButton.style.visibility = 'visible';
      }
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    }
  }, []);

  const getPageData = async () => {
    setOpenLoading(true);

    var _groupList = [];
    await getGroupListApi()
      .then(ret => {
        _groupList = ret;
      });
    setGroupList(_groupList);

    var _speakersList = [];
    await getSpeakersApi()
      .then(ret => {
        ret.forEach(item => {
          const _obj = {
            text: item.name,
            value: item.id,
          }
          _speakersList.push(_obj);
        });
      });
    setSpeakersList(_speakersList);

    getSchedulesApi(props.event.id)
      .then(res => {
        setSchedulesList(res);
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      })
      .finally(() => {
        setOpenLoading(false);
      })

    setOpenLoading(false);
  }

  const onChangeHandler = (index, value) => {
    var _newForm = newScheduleForm;
    _newForm[index].value = value;
    setNewScheduleForm(_newForm);
    setState(state === true ? false : true);
  }

  const resetScheduleForm = () => {
    const _newSchedule = createScheduleModel();
    const _newForm = convertScheduleObjectToForm(_newSchedule, groupList, speakersList);
    setNewScheduleForm(_newForm);
  }

  const createNewSchedule = () => {
    const _newSchedule = createScheduleModel();
    const _newForm = convertScheduleObjectToForm(_newSchedule, groupList, speakersList);
    setNewScheduleForm(_newForm);
    setOpenModal(true);
  }

  const editSchedule = (schedule) => {
    const _newForm = convertScheduleObjectToForm(schedule, groupList, speakersList);
    setNewScheduleForm(_newForm);
    setOpenModal(true);
  }

  const inactiveSchedule = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("salvando"));

    deleteScheduleApi(chosenSchedule.id)
      .then(res => {
        const _newScheduleList = [];
        schedulesList.forEach(item => {
          if (item.id !== chosenSchedule.id) {
            _newScheduleList.push(item);
          }
        });
        _newScheduleList.sort((a, b) => {
          return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
        });
        setSchedulesList(_newScheduleList);
        setAlertOpen(true);
        setAlertTitle("");
        setAlertMessage(getDic("enviado-sucesso"));
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setOpenLoading(false);
      })
      .finally(() => {
        setOpenLoading(false);
      })
  }

  const saveSchedule = async () => {
    var validation = validateForm(newScheduleForm);
    if (validation.isValid) {

      var _newSchedule = convertFormToScheduleObject(newScheduleForm);

      setOpenLoading(true);
      setLoadingMessage(getDic("salvando"));

      _newSchedule.eventId = props.event.id;

      var _error = false;
      const _newScheduleList = [];

      if (_newSchedule.id > 0) {
        await updateScheduleApi(_newSchedule)
          .then(res => {
            _newScheduleList.push(res);
          })
          .catch(err => {
            _error = true;
          })
      }
      else {
        await addNewScheduleApi(_newSchedule)
          .then(res => {
            _newSchedule.id = res.id;
            _newScheduleList.push(_newSchedule);
          })
          .catch(err => {
            _error = true;
          })
      }

      if (_error) {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setOpenLoading(false);
      }
      else {
        schedulesList.forEach(item => {
          if (item.id !== _newSchedule.id) {
            _newScheduleList.push(item);
          }
        });
        _newScheduleList.sort((a, b) => {
          return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
        });
        setSchedulesList(_newScheduleList);
        setAlertOpen(true);
        setAlertTitle("");
        setAlertMessage(getDic("enviado-sucesso"));
        resetScheduleForm();
        setOpenLoading(false);
        setOpenModal(false);
        setState(state === true ? false : true);
      }
    }
    else {
      setNewScheduleForm(validation.form);
    }
  }

  const setTableFile = (file) => {
    if (file) {
      var _fileType = getFileExtension(file);
      if (_fileType === "XLSX") {
        setImporterTable(file);
      }
      else {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("arquivo-erro-tipo"));
        setImporterTable(null);
      }
    }
  }

  const returnImportFile = (res) => {
    if (!res.error) {
      setAlertOpen(true);
      setAlertTitle("");
      setAlertMessage(getDic("enviado-sucesso"));
      getPageData();
    }
    else {
      setAlertOpen(true);
      setAlertTitle(`${getDic("erro")}`);
      setAlertMessage(res.errorMessage);
    }

    if (res.data && res.data.length > 0) {
      setImporterReturnTable(res.data);
    }

    setImporterTable(null);
    setExecuteScheduleImporter(false);
    var _btn = document.getElementById("btnImporterReturnTable");
    if (_btn) {
      setTimeout(() => {
        _btn.click();
      }, 500);
    }
    setTimeout(() => {
      setImporterReturnTable(null);
    }, 2000);
  }

  return (
    <Container background="#e8eced" align="center">
      <FormRow align="center" background="#e8eced" margin="0px">
        <div>
          <MenuButton history={props.history} />
          <BackButton history={props.history} color="#212121">
            {getDic("voltar").toUpperCase()}
          </BackButton>

        </div>

        <FormRow
          minHeight="60px"
          align="center"
          background="#e8eced"
          margin="0px"
        />
        <FormRow
          minHeight="60px"
          align="flex-end"
          background="#e8eced"
          margin="0px"
          paddingTop="5px"
        >
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <Button
              color="lightgray"
              textColor="black"
              onClick={() => setOpenModalImporter(true)}
            >
              {`${getDic("importador")}`}
            </Button>
          </div>
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <Button
              color="green"
              textColor="white"
              onClick={() => createNewSchedule()}
            >
              {`${getDic("cadastrar")} ${getDic("programacao")}`}
            </Button>
          </div>
        </FormRow>

        <FormColumn align="center" background="#e8eced" margin="0px" padding="5px">
          {schedulesList.length > 0 ? (
            <InfiniteScroll
              pageStart={0}
              dataLength={schedulesList.length}
              height={"79vh"}
            >
              <GridRow backgroundColor="#d9d9d9">
                <GridCell width="10%" border top first>
                  <TextMedium>ID</TextMedium>
                </GridCell>
                <GridCell width="40%" border top>
                  <TextMedium>{getDic("titulo").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="15%" border top>
                  <TextMedium>{getDic("data").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("inicio").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("fim").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="15%" border top>
                  <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
                </GridCell>
              </GridRow>

              {schedulesList.map((item, i) => {
                return (
                  <GridRow
                    key={item.id}
                    autoColor={i}
                  >
                    <GridCell width="10%" border first>
                      <TextMedium>{item.id}</TextMedium>
                    </GridCell>
                    <GridCell width="40%" border>
                      <TextMedium>{item.title}</TextMedium>
                    </GridCell>
                    <GridCell width="15%" border>
                      <TextMedium>{moment(item.startDate).format("DD/MM/YYYY")}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border>
                      <TextMedium>{item.startTime}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border>
                      <TextMedium>{item.endTime}</TextMedium>
                    </GridCell>
                    <GridCell width="15%" border>
                      <EditIcon onClick={() => editSchedule(item)} style={{ cursor: "pointer" }} />
                      <DeleteIcon
                        onClick={() => {
                          setChosenSchedule(item);
                          setDialogConfirmOpen(true);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </GridCell>
                  </GridRow>
                );
              })}
            </InfiniteScroll>
          ) : (
            <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
          )}
        </FormColumn>

        <Modal
          isVideo
          height="80vh"
          open={openModal}
          closeButtonClick={e => setOpenModal(false)}
          onClose={ret => {
            setOpenModal(false);
          }}
        >
          <Container background="#e8eced" align="left" height="100%" >
            <FormColumn padding="5px" margin="0px" background="#e8eced">
              {newScheduleForm.map((item, i) => {
                if (i === 0) {
                  return (
                    <>
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <TextMedium>
                          {item.value > 0 ? (
                            `${getDic("editar")} ${getDic("programacao")}`
                          ) : (
                            `${getDic("nova")} ${getDic("programacao")}`
                          )}
                        </TextMedium>
                      </FormRow>
                    </>
                  )
                }
                else {
                  if (item.type === "select" && item.values) {
                    return (
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <SelectList
                          required={item.required}
                          error={item.error}
                          errorMessage={item.errorMessage}
                          title={item.name}
                          width="100%"
                          value={item.value}
                          menuItens={item.values}
                          onChange={async (event) =>
                            onChangeHandler(i, event.target.value)
                          }
                          multiple={Array.isArray(item.value) ? true : false}
                        />
                      </FormRow>
                    );
                  }
                  else {
                    return (
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <InputFieldOutlined
                          key={item.id}
                          required={item.required}
                          maxLength={item.maxLength}
                          error={item.error}
                          errorMessage={item.errorMessage}
                          value={item.value}
                          title={item.name}
                          type={item.type}
                          onChange={(event) => {
                            onChangeHandler(i, event.target.value)
                          }}
                          multiline={item.dbReference === "scheduleType" ||
                            item.dbReference === "groupId" || item.dbReference === "speakers" ? true : false}
                          endAdornment={item.dbReference === "meetingCode" ? (
                            <Link onClick={() => setOpenModalConcatUrl(true)}>
                              <EditIcon />
                            </Link>
                          ) : (null)}
                        />

                        {item.dbReference === "meetingCode" && (
                          <ModalConcatUrl
                            open={openModalConcatUrl}
                            urlString={item.value}
                            isSchedule={true}
                            onClose={ret => {
                              setOpenModalConcatUrl(false);
                              onChangeHandler(i, ret);
                            }}
                          />
                        )}
                      </FormRow>
                    );
                  }
                }
              })}
              <FormRow align="center" paddingTop="7px">
                <Button
                  text={getDic("salvar")}
                  onClick={() => saveSchedule()}
                />
              </FormRow>
            </FormColumn>
          </Container>
        </Modal>

        <Modal
          isVideo
          height="80vh"
          open={openModalImporter}
          closeButtonClick={e => {
            setOpenModalImporter(false);
            setImporterTable(null);
          }}
          onClose={ret => {
            setOpenModalImporter(false);
            setImporterTable(null);
          }}
        >
          <Container background="#e8eced" align="center" height="100%" >
            <FormRow alignItems="center" direction="column">
              <div style={{ paddingTop: "10px", maxWidth: "400px" }}>
                <ScheduleImporterExcel
                  exampleFile
                  groupList={groupList}
                  speakersList={speakersList}
                  button={
                    <Button color="green" textColor="white">
                      {getDic("gerar")} {getDic("planilha")} {getDic("importador")}
                    </Button>
                  }
                />
              </div>
              <div style={{ paddingTop: "10px", maxWidth: "400px" }}>
                <ScheduleImporterExcel
                  data={schedulesList}
                  groupList={groupList}
                  speakersList={speakersList}
                  button={
                    <Button color="blue" textColor="white">
                      {getDic("exportar")} {getDic("planilha")} {getDic("evento")}
                    </Button>
                  }
                />
              </div>
              <div style={{ maxWidth: "400px", paddingTop: "20px" }}>
                <InputFieldOutlined
                  id="uploadFile"
                  key="file"
                  type="file"
                  title={`${getDic("importar")} ${getDic("planilha")}`}
                  placeholder={getDic("planilha")}
                  onChange={e => setTableFile(e.target.files[0])}
                />
                <FormRow align="center">
                  <TextSmall>{getDic("formatos-permitidos")}: XLSX</TextSmall>
                </FormRow>
              </div>
              {importerTable && (
                <div style={{ maxWidth: "400px", paddingTop: "20px" }}>
                  <Button color="blue" textColor="white" onClick={() => setExecuteScheduleImporter(true)}>
                    {getDic("importar")} {getDic("planilha")}
                  </Button>
                </div>
              )}

              {/* Planilha de retorno do importador */}
              {importerReturnTable && (
                <div hidden>
                  <ScheduleImporterExcel
                    hideElement
                    data={importerReturnTable}
                    groupList={groupList}
                    speakersList={speakersList}
                    button={
                      <button id="btnImporterReturnTable">X</button>
                    }
                  />
                </div>
              )}

              {executeScheduleImporter && (
                <ScheduleImporter
                  file={importerTable}
                  schedulesList={schedulesList}
                  groupList={groupList}
                  speakersList={speakersList}
                  returnImportFile={res => {
                    returnImportFile(res);
                  }}
                />
              )}

            </FormRow>
          </Container>
        </Modal>

        <Alert
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          onClick={() => setAlertOpen(false)}
          title={alertTitle}
          message={alertMessage}
        />

        <Dialog
          open={dialogConfirmOpen}
          onClose={() => setDialogConfirmOpen(false)}
          title={getDic("atencao")}
          textButtonYes={getDic("inativar")}
          onClickYes={() => {
            if (dialogConfirmKey && dialogConfirmKey != "" && dialogConfirmKey.toUpperCase() === "SIM") {
              inactiveSchedule();
              setDialogConfirmOpen(false);
              setDialogConfirmKey("");
            }
            else {
              setDialogConfirmKeyErrorMessage(true);
              setTimeout(() => {
                setDialogConfirmKeyErrorMessage(false);
              }, 3000); // 1 minuto = 60000
            }
          }}
          textButtonNo={getDic("cancelar")}
          onClickNo={() => {
            setDialogConfirmOpen(false);
            setDialogConfirmKey("");
          }}
        >
          <FormRow>
            <TextSmall>
              {getDic("confirmacao-de-acao")}
            </TextSmall>
          </FormRow>
          <FormRow align="center" paddingTop="5px">
            <InputFieldOutlined
              key="password"
              value={dialogConfirmKey}
              onChange={event => setDialogConfirmKey(event.target.value)}
            />
          </FormRow>
          {dialogConfirmKeyErrorMessage ? (
            <TextSmall color="red">{getDic("erro")}</TextSmall>
          ) : (null)}
        </Dialog>

        <Loading open={openLoading} message={loadingMessage} />
      </FormRow>
    </Container>
  )
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(SchedulesRegisterPage);
