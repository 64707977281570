import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ActivityIndicator = props => {
    const {
        open,
        onClose,
        onClick,
        title = "",
        message = ""
    } = props;

    const styles = {
        position: "absolute"
    }

    return (
        <Dialog
            style={styles}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClick} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ActivityIndicator;
