import em01 from "./emj01.svg";
import em02 from "./emj02.svg";
import em03 from "./emj03.svg";
import em04 from "./emj04.svg";
import em05 from "./emj05.svg";
import em06 from "./emj06.svg";
import em07 from "./emj07.svg";
import em08 from "./emj08.svg";
import em09 from "./emj09.svg";
import em10 from "./emj10.svg";
import em11 from "./emj11.svg";
import em12 from "./emj12.svg";
import em13 from "./emj13.svg";
import em14 from "./emj14.svg";
import em15 from "./emj15.svg";
import em16 from "./emj16.svg";
import em17 from "./emj17.svg";
import em18 from "./emj18.svg";
import em19 from "./emj19.svg";
import em20 from "./emj20.svg";
import em21 from "./emj21.svg";
import em22 from "./emj22.svg";
import em23 from "./emj23.svg";

export default {
  em01,
  em02,
  em03,
  em04,
  em05,
  em06,
  em07,
  em08,
  em09,
  em10,
  em11,
  em12,
  em13,
  em14,
  em15,
  em16,
  em17,
  em18,
  em19,
  em20,
  em21,
  em22,
  em23,
}
