export function createAttributeValueModel(
    attributeId = 0,
    name = "",
    value = "",
    error = false,
    errorMessage = "",
    dbReference = "",
    disabled = false,
) {
    const object = {
        attributeId: attributeId,
        name: name,
        value: value,
        error: error,
        errorMessage: errorMessage,
        dbReference: dbReference,
        disabled: disabled
    }

    return object;
}
