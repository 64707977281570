import axios from 'axios';
import { store } from '../store';
import { createConfigModel } from '../models/configModel';
import { convertScheduleMeetingTypeInModalType, convertModalTypeInScheduleMeetingType } from './configService';
import { setSchedules, setSpeakers, resetSchedules, mergeConfig } from '../store/actions';
import { getToken, getUrl } from './apiService';
import { createScheduleModel, createSpeakerChildModel, createFileChildModel, getTimeObject } from '../models/scheduleModel';
import * as speakerModel from '../models/speakerModel';

export function getMeetingLink(meetingType = 1, meetingCode = "") {
    switch (meetingType) {
        case 2: // Zomm
            return "https://success.zoom.us/wc/join/" + meetingCode;
        case 3: // Hangouts
            return meetingCode;
        case 4: // Youtube
            return meetingCode;
        case 5: // Embed
            return meetingCode;
        case 6: // Vídeo
            return meetingCode;
        case 7: // Iframe
            return meetingCode;
        default:
            return meetingCode;
    }
}

export async function getSchedulesAndSpeakersAndConfigListFromApiReturn(data) {

    return new Promise(async (resolve, reject) => {

        if (data.schedules) {
            const schedules = [];

            //Transforma o conteúdo em configurações
            const configList = [];

            //Monta lista geral de palestrantes
            var speakersList = [];

            data.schedules.forEach(item => {
                if (item.schedule.active === null || item.schedule.active === true) {
                    //Monta lista local de palestrantes
                    var speakers = [];
                    item.speakers.forEach(speak => {
                        //Verifica se o palestrante veio repetido
                        var hasSpeaker = speakers.find(sp => {
                            if (sp.id === speak.id) {
                                return true;
                            }
                            return false;
                        });

                        if (!hasSpeaker) {
                            //Cria url imagem
                            var image = '';
                            if (speak.files.length > 0) {
                                image = getUrl().files + speak.files[0].year + "/" + speak.files[0].id + "." + speak.files[0].extension;
                            }

                            const speakerChild = createSpeakerChildModel(
                                speak.id,
                                speak.name,
                                image
                            )
                            speakers.push(speakerChild);

                            //Verifica se o palestrante já existe na lista geral
                            var hasSpeakerList = speakersList.find(sp => {
                                if (sp.id === speak.id) {
                                    return true;
                                }
                                return false;
                            });

                            if (!hasSpeakerList) {
                                const speakerList = speakerModel.createSpeakerModel(
                                    speak.id,
                                    speak.name,
                                    speak.company,
                                    speak.role,
                                    speak.bio,
                                    image,
                                    speak.video,
                                    speak.facebook,
                                    speak.twitter,
                                    speak.linkedin,
                                    speak.site,
                                    speak.email
                                );
                                speakersList.push(speakerList);
                            }
                        }
                    });

                    //Verifica se a programação possui arquivos
                    var files = [];
                    item.files.forEach(file => {
                        const fil = createFileChildModel(
                            file.id,
                            file.name,
                            getUrl().files + file.year + "/" + file.id + "." + file.extension
                        )
                        files.push(fil);
                    });

                    const _groups = item.scheduleXGroup.filter(grp => {
                        return grp.active === true;
                    });

                    //Monta um config conforme o conteúdo
                    var config = createConfigModel(
                        item.schedule.id,
                        1,
                        3,
                        item.schedule.meetingId ? convertScheduleMeetingTypeInModalType(item.schedule.meetingId) : 0,
                        "SCHEDULE-" + item.schedule.id.toString(),
                        getMeetingLink(item.schedule.meetingId, item.schedule.meetingCode),
                        item.schedule.title ? item.schedule.title : '',
                        item.schedule.eventId,
                        null,
                        null,
                        null,
                        null,
                        item.schedule.id,
                        item.schedule.password,
                        _groups,
                        null,
                        null,
                        null,
                        null,
                        null,
                        item.levels ? item.levels : null,
                    );
                    configList.push(config);

                    //Monta programação
                    const schedule = createScheduleModel(
                        item.schedule.id,
                        item.schedule.eventId,
                        item.schedule.title,
                        item.schedule.description ? item.schedule.description : '',
                        item.schedule.startDate,
                        item.schedule.maximumNumberOfGuets ? item.schedule.maximumNumberOfGuets : 0,
                        item.schedule.numberOfGuests ? item.schedule.numberOfGuests : 0,
                        item.schedule.startTime,
                        item.schedule.endTime,
                        item.schedule.status,
                        item.schedule.meetingCode ? item.schedule.meetingCode : '',
                        item.schedule.meetingId ? convertScheduleMeetingTypeInModalType(item.schedule.meetingId) : 0,
                        item.schedule.hasMeeting ? item.schedule.hasMeeting : false,
                        speakers,
                        files,
                        _groups,
                        item.schedule.style,
                        config.multiConfig,
                    );
                    schedules.push(schedule);
                }
            });

            await store.dispatch(resetSchedules());

            await store.dispatch(setSchedules(schedules));
            await store.dispatch(setSpeakers(speakersList));
            await store.dispatch(mergeConfig(configList));

            resolve({ success: true, message: "Ok" });
        }
        else {
            reject({ success: false, message: "Programações não localizadas" });
        }
    })
}

export async function getSchedulesApi() {

    const _event = store.getState().event;
    var method = getUrl().hall + "schedule?eventId=" + _event.id.toString();
    const token = await getToken();

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
        };
        axios(options)
            .then((res) => {
                if (res.data) {
                    const _schedules = [];

                    res.data.forEach(item => {
                        if (item.active === null || item.active === true) {
                            const _scheduleType = [];
                            if (item.pe === "S") {
                                _scheduleType.push("E");
                            }
                            if (item.pp === "S") {
                                _scheduleType.push("P");
                            }
                            if (item.po === "S") {
                                _scheduleType.push("O");
                            }

                            const _speakers = [];
                            item.virtualSpeaker.forEach(speak => {
                                _speakers.push(speak.speakerId);
                            });

                            const _groups = [];
                            item.virtualGroup.forEach(group => {
                                if (group.active) {
                                    _groups.push(group.groupId);
                                }
                            });

                            const _sched = createScheduleModel(
                                item.id,
                                item.eventId,
                                item.title,
                                item.description,
                                item.startDate,
                                item.maximumNumberOfGuets,
                                item.numberOfGuests,
                                item.startTime,
                                item.endTime,
                                item.status,
                                item.meetingCode,
                                item.meetingId ? convertScheduleMeetingTypeInModalType(item.meetingId) : null,
                                item.hasMeeting,
                                _speakers, // speakers
                                null, // files
                                _groups, // scheduleXGroup
                                item.style,
                                null, // multiConfig
                                item.restrictAccess,
                                item.password,
                                _scheduleType
                            );
                            _schedules.push(_sched);
                        }
                    });
                    resolve(_schedules);
                } else {
                    reject(res.data);
                }
            })
            .catch((err) => {
                console.log("Erro getSchedulesApi", err);
                reject(err);
            });
    });
}

export async function addNewScheduleApi(schedule) {

    var method = getUrl().hall + "schedule";
    const token = await getToken();
    const _event = store.getState().event;

    const _date = schedule.startDate.split("T");
    const _schedule = {
        eventId: _event.id,
        type: ["E", "P", "O"], // Evento, Participante, Operacional
        initialDate: `${_date[0]}T${schedule.startTime}:00-03:00`,
        endDate: `${_date[0]}T${schedule.endTime}:00-03:00`,
        title: schedule.title,
        maximumCapacity: schedule.maximumNumberOfGuets ? parseInt(schedule.maximumNumberOfGuets) : null,
        confirmedNumberOfGuests: schedule.numberOfGuests ? parseInt(schedule.numberOfGuests) : null,
        restrictAccess: schedule.restrictAccess,
        hasMeeting: true,
        meetingId: convertModalTypeInScheduleMeetingType(schedule.meetingType),
        meetingCode: schedule.meetingCode,
        password: schedule.password,
        styleCSS: schedule.stylesString,
        location: null,
        description: schedule.description,
        group: schedule.groupId,
    }

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
            data: _schedule,
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    var _objReturn = schedule;
                    _objReturn.id = res.data.data.id;

                    addSpeakersToScheduleApi(_objReturn, _objReturn.speakers)
                        .then(() => {
                            resolve(_objReturn);
                        })
                        .catch(err => {
                            reject(err);
                        });
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log("Erro addNewScheduleApi", err);
                reject(err);
            });
    });
}

export async function updateScheduleApi(schedule) {
    var method = getUrl().hall + "schedule";
    const token = await getToken();

    //Concatenar a hora inicial junto com a data para padronizar com o cadastro
    var _dateArray = schedule.startDate.split("T");
    var _startDate = `${_dateArray[0]}T00:00:00-03:00`;

    const _schedule = {
        id: schedule.id,
        type: ["E", "P", "O"], // Evento, Participante, Operacional
        date: _startDate,
        startHour: `${schedule.startTime}:00`,
        endHour: `${schedule.endTime}:00`,
        title: schedule.title,
        maximumCapacity: schedule.maximumNumberOfGuets ? parseInt(schedule.maximumNumberOfGuets) : null,
        confirmedNumberOfGuests: schedule.numberOfGuests ? parseInt(schedule.numberOfGuests) : null,
        restrictAccess: schedule.restrictAccess,
        hasMeeting: true,
        meetingId: convertModalTypeInScheduleMeetingType(schedule.meetingType),
        meetingCode: schedule.meetingCode,
        password: schedule.password,
        styleCSS: schedule.stylesString,
        location: null,
        description: schedule.description,
        group: schedule.groupId,
    }

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
            data: _schedule,
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    addSpeakersToScheduleApi(schedule, schedule.speakers)
                        .then(() => {
                            resolve(schedule);
                        })
                        .catch(err => {
                            reject(err);
                        });
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log("Erro updateScheduleApi", err);
                reject(err);
            });
    });
}

async function addSpeakersToScheduleApi(schedule, speakersIdList) {

    //O método irá verificar se a lista de ids enviadas está na schedule
    var method1 = getUrl().hall + "schedule/speakers?sheduleId=" + schedule.id;
    const token = await getToken();

    return new Promise((resolve, reject) => {
        const options1 = {
            url: method1,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
        };
        axios(options1)
            .then(async (res) => {
                var _hasError = false;
                if (res && res.data) {
                    //Verifica se os palestrantes estão na lista, se não, incluir
                    speakersIdList.forEach(async speakId => {
                        if (!_hasError) {
                            var _found = false;
                            for (var i = 0; i < res.data.length; i++) {
                                if (res.data[i].speakerId == speakId) {
                                    _found = true;
                                    break;
                                }
                            }
                            if (!_found) {
                                await addSpeakerToScheduleApi(schedule, speakId)
                                    .catch(err => {
                                        _hasError = true;
                                    })
                            }
                        }
                    });

                    //Verifica se o palestrante saiu da lista, se sim excluir
                    res.data.forEach(async speaker => {
                        var _hasSpeaker = speakersIdList.find(sp => {
                            return sp === speaker.speakerId;
                        });
                        if (!_hasSpeaker) {
                            await deleteSpeakerFromScheduleApi(speaker)
                                .catch(err => {
                                    _hasError = true;
                                });
                        }
                    });

                    if (_hasError) {
                        reject("Erro ao cadastrar palestrante");
                    }
                    else {
                        resolve(true);
                    }
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log("Erro addSpeakersToScheduleApi", err);
                reject(err);
            });
    });
}

async function addSpeakerToScheduleApi(schedule, speakerId) {

    var method = getUrl().hall + "schedule/speakers";
    const token = await getToken();
    const _event = store.getState().event;

    const _data = {
        scheduleId: schedule.id,
        eventId: _event.id,
        speakerId: speakerId,
        date: schedule.startDate,
        initialHour: `${schedule.startTime}:00`,
        endHour: `${schedule.endTime}:00`,
    }

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
            data: _data
        };
        axios(options)
            .then((res) => {
                if (res && res.data) {
                    resolve(true);
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log("Erro addSpeakerToScheduleApi", err);
                reject(err);
            });
    });
}

async function deleteSpeakerFromScheduleApi(speakerObj) {

    var method = getUrl().hall + "schedule/speakers/" + speakerObj.id;
    const token = await getToken();

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            }
        };
        console.log(options)
        axios(options)
            .then((res) => {
                if (res && res.data) {
                    resolve(true);
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log("Erro deleteSpeakerFromScheduleApi", err);
                reject(err);
            });
    });
}

export async function deleteScheduleApi(scheduleId) {
    var method = getUrl().hall + "schedule/" + scheduleId.toString();
    const token = await getToken();

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    resolve(true);
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log("Erro deleteScheduleApi", err);
                reject(err);
            });
    });
}

