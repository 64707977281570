import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { store } from '../store';
import { userLogout } from '../store/actions';
import { insertNewAccess, getSystemActions, insertNewAccessListApi } from '../services/accessService';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

//Imports de components
import {
    Loading,
} from '../components';

const ExitButton = (props) => {

    const [dialogOpen, setDialogOpen] = React.useState();

    //Loading control
    const [loadingMessage, setLoadingMessage] = React.useState(getDic("salvando"));
    const [openLoading, setOpenLoading] = React.useState(false);
    const [showButton, setShowButton] = React.useState(true);

    React.useEffect(() => {
        getButtonData();
        setDialogOpen(false);
    }, []);

    const getButtonData = () => {
        const blockButton = store.getState().config.find(conf => {
            return conf.codeScreen === 'DISABLE-EXIT-BUTTON'
        });
        if (blockButton) {
            setShowButton(false);
        }
    }

    const logoutApp = async () => {
        setDialogOpen(false);
        setOpenLoading(true);
        await insertNewAccess("system", "", getSystemActions().exitPage);

        const access = store.getState().access;
        insertNewAccessListApi(access)
            .then(ret => {
                console.log("Ok");
            })
            .catch(err => {
                console.log("Erro insertNewAccessListApi", err);
            })
            .finally(async () => {
                await props.userLogout();
                setOpenLoading(false);
                props.history.replace("/" + props.event.id);
            });
    }

    const {
        color = "#212121",
        size = null,
        textColor = 'white',
    } = props;

    const styles = {
        button: {
            zIndex: '11',
            display: 'flex',
            position: 'fixed',
            top: '5px',
            right: '5px',
            backgroundColor: color,
            color: textColor,
        }
    };

    if (!showButton) { return <div /> }
    if (window.self !== window.top) return <div />
    else return (
        <div id="exit_btn">
            <Fab
                class="MuiButtonBase-root MuiFab-root MuiFab-sizeMedium MuiFab-secondary MuiFab-extended"
                style={styles.button}
                variant="contained"
                size={size ? size : "medium"}
                onClick={e => setDialogOpen(true)}
            >
                {getDic("sair")}
            </Fab>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{getDic("sair")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {getDic("deseja-sair-sistema")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        {getDic("nao")}
                    </Button>
                    <Button onClick={() => logoutApp()} color="primary" autoFocus>
                        {getDic("sim")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Loading open={openLoading} message={loadingMessage} />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        event: state.event
    }
}

const mapDispatchToProps = {
    userLogout
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitButton)
