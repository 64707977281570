import React from "react";
// import ReactWordcloud from "react-wordcloud";
import { connect } from "react-redux";
import { getDic } from "../assets/i18n/dictionary";
import { insertNewAccess, getSystemActions } from "../services/accessService";
import {
  getAnswersQuantityByQuestionApi,
  getQuestionsApi,
} from "../services/questionsService";
import SurveyReport from "../excel/SurveyReport";
import RefreshIcon from "@material-ui/icons/Refresh";

//Imports de components
import {
  MenuButton,
  BackButton,

  Card,
  Alert,
  Loading,
  Container,
  FormRow,
  TextMedium,
  TextBig,
  SelectList,
  ChartPie,
  ChartBar,
  Button,
  WordCloud,
} from "../components";
import { set } from "react-ga";

const ReportsSurveyPage = (props) => {
  const [state, setState] = React.useState(true);
  const [isInternal, setIsInternal] = React.useState(
    parseInt(props.match.params.internal) > 0 ? true : false
  );

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(
    getDic("carregando")
  );
  const [openLoading, setOpenLoading] = React.useState(true);

  const [noQuestions, setNoQuestions] = React.useState(false);
  const [chosenQuestion, setChosenQuestion] = React.useState(null);
  const [defaultQuestionsList, setDefaultQuestionsList] = React.useState([]);
  const [questionsList, setQuestionsList] = React.useState([]);
  const [chosenCategory, setChosenCategory] = React.useState(null);
  const [categoriesList, setCategoriesList] = React.useState([]);
  const [chartPieData, setChartPieData] = React.useState([]);
  const [chartBarData, setChartBarData] = React.useState([]);
  const [chartBarKeys, setChartBarKeys] = React.useState([]);
  const [chartNuvemData, setChartNuvemData] = React.useState([]);
  const [excelData, setExcelData] = React.useState([]);
  const wordCloudColor = props.match.params.wordCloudColor ? props.match.params.wordCloudColor : null;
  const [wordCloudSize, setWordCloudSize] = React.useState({
    width: 600,
    height: 400,
  });

  const [chartType, setChartType] = React.useState("bar");
  const [chartTypeList, setChartTypeList] = React.useState([]);

  React.useEffect(() => {
    //Trava para impedir que acesso remoto possa visualizar recursos do sistema
    if (isInternal) {
      if (
        !props.user ||
        props.user.id === 0 ||
        !props.event ||
        props.event.id === 0
      ) {
        props.history.replace("/");
      }
    }

    //Monta switch para escolher tipo de gráfico
    const _chartTypeList = [];
    const _chart1 = {
      value: "pie",
      text: "Pizza",
    };
    _chartTypeList.push(_chart1);

    const _chart2 = {
      value: "bar",
      text: getDic("barra"),
    };
    _chartTypeList.push(_chart2);

    const _chart3 = {
      value: "num",
      text: getDic("nuvem"),
    };
    _chartTypeList.push(_chart3);

    setChartTypeList(_chartTypeList);

    getQuestionsApi(props.match.params.id)
      .then((res) => {
        const _questionsList = [];

        res.questions.forEach((item) => {
          const _obj = {
            value: item.id,
            text: item.question,
            category: item.category,
          };
          _questionsList.push(_obj);
        });

        if (_questionsList.length > 0) {
          setQuestionsList(_questionsList);
          setDefaultQuestionsList(_questionsList);
          setChosenQuestion(_questionsList[0].value);
          getAnswersQuantity(_questionsList[0].value);
          setCategoriesList(res.categories);
          setChosenCategory(res.categories[0].value);
        } else {
          setNoQuestions(true);
        }
      })
      .catch((err) => {
        setNoQuestions(true);
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      })
      .finally(() => {
        setOpenLoading(false);
      });

    insertNewAccess(props.match.url, "", getSystemActions().accessPage);

    return () => {
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    };
  }, []);

  const getAnswersQuantity = (questionId) => {
    setLoadingMessage(getDic("carregando"));
    setOpenLoading(true);

    if (questionId) {
      getAnswersQuantityByQuestionApi(questionId)
        .then((res) => {
          const _pieData = [];
          const _barData = [];
          const _NuvemData = [];
          const _chartBarKeys = [];
          const _chartNuvemKeys = [];

          var _total = 0;
          res.forEach((item) => {
            _total += item.quantity;
          });

          res.forEach((item) => {
            var _ret = (item.quantity * 100) / _total;

            const _objPie = {
              id: item.answer,
              label: item.answer,
              value: item.quantity,
              percentage: _ret.toFixed(2) + "%",
            };
            _pieData.push(_objPie);

            const _objBar = {
              [getDic("quantidade")]: item.answer,
              [item.answer]: item.quantity,
              quantity: item.quantity,
              percentage: _ret.toFixed(2) + "%",
              text: item.answer,
            };

            _barData.push(_objBar);
            _chartBarKeys.push(item.answer);

            const _objNuvem = {
              text: item.answer,
              value: Math.round(_ret.toFixed(2)),
            };
            _NuvemData.push(_objNuvem);
            _chartNuvemKeys.push(item.answer);
          });

          setChartPieData(_pieData);
          setChartBarData(_barData);

          wordCloudDataTreatment(_NuvemData);
          setChartBarKeys(_chartBarKeys);

          setExcelData(res);
        })
        .catch((err) => {
          setAlertOpen(true);
          setAlertTitle(getDic("erro"));
          setAlertMessage(getDic("sem-conexao"));
        })
        .finally(() => {
          setOpenLoading(false);
          setState(state === true ? false : true);
        });
    }
  };

  const wordCloudDataTreatment = (data) => {
    console.log(data, "chartNuvemData");
    // const fontSizeMapper = (word) => Math.log2(word.value + 1) * 3;
    const newData = data.map((item) => ({
      text: item.text.split(" ")[0],
      value: (item.value + 1) * 4,
    }));

    function compared(a, b) {
      if (a.value < b.value) return 1;
      if (a.value > b.value) return -1;
      return 0;
    }
    const newDataOrder = newData.sort(compared);
    const dataTop = [];
    const dataTopUpperCase = [];
    newDataOrder.forEach((data, index) => {
      if (dataTop.length <= 50) {
        if (!dataTopUpperCase.includes(data.text.toUpperCase())) {
          dataTop.push(data.text);
          dataTopUpperCase.push(data.text.toUpperCase());
        }
      }
    });
    setChartNuvemData(dataTop);

    var _wordCloudSize = {
      width: 400,
      height: 400,
    }
    if (dataTop.length > 10 && dataTop.length <= 15) {
      _wordCloudSize.width = 500;
    }
    if (dataTop.length > 15 && dataTop.length <= 20) {
      _wordCloudSize.width = 600;
    }
    if (dataTop.length > 20 && dataTop.length <= 25) {
      _wordCloudSize.width = 700;
    }
    if (dataTop.length > 25) {
      _wordCloudSize.width = 800;
    }
    setWordCloudSize(_wordCloudSize);
  }

  const setCategory = (category) => {
    if (category) {
      const _questionsList = defaultQuestionsList.filter(quest => {
        return quest.category === category;
      });
      var hasQuestion = _questionsList.find(quest => {
        return quest.value === chosenQuestion;
      });
      if (!hasQuestion) {
        setChosenQuestion(_questionsList[0].value);
        getAnswersQuantity(_questionsList[0].value);
      }
      setQuestionsList(_questionsList);
    } else {
      setQuestionsList(defaultQuestionsList);
    }
    setChosenCategory(category);
  }

  if (noQuestions) {
    return (
      <Container background="#e8eced" align="center">
        {isInternal ? (
          <div>
            <MenuButton history={props.history} />
            <BackButton history={props.history} color="#212121">
              {getDic("voltar").toUpperCase()}
            </BackButton>

          </div>
        ) : null}

        <FormRow align="center">
          <TextBig>{getDic("pesquisa-sem-resultado")}</TextBig>
        </FormRow>
      </Container>
    );
  }

  return (
    <Container background="#e8eced" align="center">
      <FormRow align="center" background="#e8eced" margin="0px">
        {isInternal ? (
          <div>
            <MenuButton history={props.history} />
            <BackButton history={props.history} color="#212121">
              {getDic("voltar").toUpperCase()}
            </BackButton>

          </div>
        ) : null}

        <FormRow
          minHeight="60px"
          align="center"
          background="#e8eced"
          margin="0px"
        />
        <FormRow
          minHeight="60px"
          align="flex-end"
          background="#e8eced"
          margin="0px"
          paddingTop="5px"
        >
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <SurveyReport
              data={excelData}
              button={
                <Button color="green" textColor="white">
                  Excel
                </Button>
              }
            />
          </div>
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <Button
              color="#e0e0e0"
              textColor="black"
              onClick={() => getAnswersQuantity(chosenQuestion)}
            >
              <RefreshIcon />
            </Button>
          </div>
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <SelectList
              title={getDic("tipo") + " " + getDic("grafico")}
              width="150px"
              maxWidth="150px"
              value={chartType}
              menuItens={chartTypeList}
              onChange={(event) => {
                setChartType(event.target.value);
              }}
            />
          </div>
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <SelectList
              title={getDic("categoria")}
              width="300px"
              maxWidth="300px"
              value={chosenCategory}
              menuItens={categoriesList}
              onChange={(event) => {
                setCategory(event.target.value);
              }}
            />
          </div>
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <SelectList
              title={getDic("pergunta")}
              width="300px"
              maxWidth="300px"
              value={chosenQuestion}
              menuItens={questionsList}
              onChange={(event) => {
                setChosenQuestion(event.target.value);
                getAnswersQuantity(event.target.value);
              }}
            />
          </div>
        </FormRow>

        <FormRow margin="0px">
          <div
            style={{ width: "100%", paddingLeft: "10px", paddingRight: "10px" }}
          >
            <Card>
              <div
                style={{
                  padding: "10px",
                  textAlign: "left",
                  overflow: "hidden",
                  height: "65px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <TextMedium fontSize={30}>
                    {chosenQuestion
                      ? questionsList.find((q) => q.value === chosenQuestion)
                        .text
                      : ""}
                  </TextMedium>
                </div>
              </div>
              <FormRow margin="0px">
                {/* Gráfico de respostas Pie */}
                {chartType === "pie" && (
                  <div style={{ height: "65vh", width: "100%" }}>
                    <ChartPie data={chartPieData} />
                  </div>
                )}

                {/* Gráfico de respostas Bar */}
                {chartType === "bar" && (
                  <div style={{ height: "65vh", width: "100%" }}>
                    <ChartBar
                      keys={chartBarKeys}
                      idexedBy={getDic("quantidade")}
                      data={chartBarData}
                    />
                  </div>
                )}
                {/* Gráfico de respostas Nuvem */}

                {chartType === "num" && (
                  <div style={{ width: "100%" }}>
                    <div className="nuvem-box center">
                      <WordCloud
                        width={wordCloudSize.width}
                        height={wordCloudSize.height}
                        wordsArray={chartNuvemData}
                        hueColor={wordCloudColor}
                      />
                    </div>
                  </div>
                )}
              </FormRow>
            </Card>
          </div>
        </FormRow>
      </FormRow>

      <Alert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={() => setAlertOpen(false)}
        title={alertTitle}
        message={alertMessage}
      />

      <Loading open={openLoading} message={loadingMessage} />
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(ReportsSurveyPage);
