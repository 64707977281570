import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

const Component = props => {
    const {
        children,
        open,
        onClose,
        title = "",
        disableBackdropClick = false,
        onClickYes,
        onClickNo,
        textButtonYes = getDic("sim"),
        textButtonNo = getDic("nao"),
        fullScreen = false,
    } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            disableBackdropClick={disableBackdropClick}
            scroll={"body"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullScreen={fullScreen}
            style={{
                zIndex: 12
            }}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {onClickYes || onClickNo ? (
                <DialogActions>
                    {onClickNo && (
                        <Button onClick={onClickNo} color="primary">
                            {textButtonNo}
                        </Button>
                    )}
                    {onClickYes && (
                        <Button onClick={onClickYes} color="primary" autoFocus>
                            {textButtonYes}
                        </Button>
                    )}
                </DialogActions>
            ) : (null)}

        </Dialog>
    )
};

export default Component;
