export function createSurveyModel(
    questionId = 0,
    question = "",
    eventId = 0,
    answer = "",
    quantity = 0
) {
    const obj = {
        questionId: questionId,
        question: question,
        eventId: eventId,
        answer: answer,
        quantity: quantity,
    }

    return obj;
}
