import axios from 'axios';
import { store } from '../store';
import * as apiService from './apiService';
import * as checkinModel from '../models/checkinModel';
import moment from 'moment';

export async function insertNewCheckin(scheduleId = 0) {
    return;

    // var method = apiService.getUrl().hall + 'event/schedule/checkin';
    // const token = await apiService.getToken();

    // const user = store.getState().user;
    // const date = await apiService.getDate();

    // var checkin = checkinModel.createCheckinModel(scheduleId, date, user.id);

    // return new Promise((resolve, reject) => {

    //     if (scheduleId === 0 || !parseInt(scheduleId))
    //         reject("ID da sessão não informado");

    //     const options = {
    //         url: method,
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'bearer ' + token
    //         },
    //         data: [{
    //             scheduleEventId: checkin.scheduleEventId,
    //             cpf: "",
    //             registryDate: checkin.registryDate,
    //             checkInCodeDate: checkin.registryDate,
    //             checkInCode: "",
    //             participantId: checkin.ParticipantId
    //         }]
    //     };
    //     axios(options)
    //         .then(res => {
    //             if (res.data.data[0].success) {
    //                 console.log(res.data.data[0].message);
    //                 resolve(true);
    //             }
    //             else {
    //                 reject(res.data.data[0]);
    //             }
    //         })
    //         .catch(err => {
    //             console.log("Erro insertNewCheckin", err);
    //             reject(err);
    //         })
    // });
}
