import React from 'react';
import TagCloud from 'react-tag-cloud';
import randomColor from 'randomcolor';
import moment from 'moment';

const WordCloud = ({
    wordsArray = [],
    fintSize = 12,
    largerWordSize = 50,
    minimalWordSize = 15,
    // width = '100%',
    // height = '100%',
    width = '',
    height = '',
    rotate = false,
    seconds = moment().seconds(),
    hueColor = null,
    color = null,
    padding = 5,
}) => {

    const getFontSize = (index) => {
        if (index === 0) {
            return largerWordSize;
        }
        if (index === 1) {
            return largerWordSize - 10;
        }
        if (index === 2) {
            return largerWordSize - 15;
        }
        if (index > 2 && index <= 10) {
            return largerWordSize - 20;
        }
        if (index >= 11 && index <= 20) {
            return largerWordSize - 25;
        }
        return largerWordSize - 30;
    }

    const getWordRotate = (index) => {
        if (rotate) {
            var _base = seconds > 9 ? seconds % 10 : seconds;
            var _basePositive = (seconds + 3) > 9 ? (seconds + 3) % 10 : (seconds + 3);
            var _baseNegative = (seconds - 3) < 0 ? 0 : (seconds - 3) > 9 ? (seconds - 3) % 10 : (seconds - 3);
            var _baseInvert = (seconds + 6) > 9 ? (seconds + 6) % 10 : (seconds + 6);
            var _indexBase = index > 9 ? index % 10 : index;
            if (_indexBase === _base) {
                return -90;
            }
            if (_indexBase === _baseNegative) {
                return -45;
            }
            if (_indexBase === _basePositive) {
                return 45;
            }
            if (_indexBase === _baseInvert) {
                return 90;
            }
        }
    }

    return (
        <TagCloud
            style={{
                fontFamily: 'sans-serif',
                fontSize: fintSize,
                fontWeight: 'bold',
                // fontStyle: 'italic',
                color: () => randomColor({
                    hue: hueColor
                }),
                padding: padding,
                width: width,
                height: height,
            }}>
            {wordsArray.map((word, i) => {
                const _size = getFontSize(i);
                return (
                    <div
                        rotate={getWordRotate(i)}
                        style={{
                            fontSize: _size < minimalWordSize ? minimalWordSize : _size,
                            color: color ? color : null
                        }}
                    >
                        {word}
                    </div>
                )
            })}
        </TagCloud>
    );
};

export default WordCloud;
