import React from 'react';
import '../scss/ContainerImage.scss';

const ContainerImage = props => {
    const {
        children,
        // align = null,
        // image = null
    } = props;

    // const styles = {
    //     container: {
    //         width: '1920px',
    //         height: '1080px',
    //         position: 'fixed',
    //         display: 'flex',
    //         alignItems: 'flex-start',
    //         justifyContent: align ? align : 'center',
    //         alignContent: align ? align : 'center',
    //         zIndex: '1',
    //         background: `url(${image})`,
    //         //backgroundSize: 'cover',
    //         backgroundSize: '100% 100%',
    //         backgrounRepeat: 'no-repeat',
    //     }
    // };

    return (
        <div className="ContainerImage">
            {children}
        </div>
    )
};

export default ContainerImage;
