import axios from "axios";
import { store } from "../store";
import { getDic } from "../assets/i18n/dictionary";
import { getUrl, getToken } from "./apiService";
import { createCampaignModel } from "../models/campaignModel";
import { createGuestModel } from "../models/guestModel";
import * as openSocket from "socket.io-client";
import moment from "moment";

export async function getCampaignsApi() {
  const _event = store.getState().event;
  var _method = getUrl().campaign + "campaign/getCampaigns/" + _event.id.toString();
  const _token = await getToken();

  return new Promise((resolve, reject) => {
    const _options = {
      url: _method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + _token,
      }
    };
    axios(_options)
      .then((res) => {
        if (res.data.success && res.data && res.data.data) {
          const _campaigns = [];
          res.data.data.forEach(item => {
            if (item.active) {
              const _obj = createCampaignModel(
                item.id,
                item.eventId,
                item.name,
                item.type,
                item.templateName,
                item.template,
                item.tags,
                item.targets,
                item.status,
                item.sendDate,
                item.targetsSent,
                item.targetsError,
                item.active,
                item.url,
                item.createDate,
                item.typeUrl
              );
              _campaigns.push(_obj);
            }
          });
          _campaigns.sort((a, b) => (a.createDate > b.createDate) ? 1 : -1)
          resolve(_campaigns);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getCampaignsApi", err);
        reject(err);
      });
  });
}

export async function getCampaignApi(campaignId) {
  const _event = store.getState().event;
  var _method = getUrl().campaign + `campaign/getCampaign/${_event.id}/${campaignId}`;
  const _token = await getToken();

  return new Promise((resolve, reject) => {
    const _options = {
      url: _method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + _token,
      }
    };
    axios(_options)
      .then((res) => {
        if (res.data.success && res.data && res.data.data) {
          const item = res.data.data;
          const _campaign = createCampaignModel(
            item.id,
            item.eventId,
            item.name,
            item.type,
            item.templateName,
            item.template,
            item.tags,
            item.targets,
            item.status,
            item.sendDate,
            item.targetsSent,
            item.targetsError,
            item.active,
            item.url,
            item.createDate,
            item.typeUrl
          );
          resolve(_campaign);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getCampaignApi", err);
        reject(err);
      });
  });
}

export async function getGuestIdsByGroupApi(groupId) {
  const _event = store.getState().event;
  var _method = getUrl().campaign + `guest/getGuestIdsByGroup/${_event.id}/${groupId}`;
  const _token = await getToken();

  return new Promise((resolve, reject) => {
    const _options = {
      url: _method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + _token,
      }
    };
    axios(_options)
      .then((res) => {
        if (res.data.success && res.data && res.data.data) {
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getGuestIdsByGroupApi", err);
        reject(err);
      });
  });
}

export async function getGuestsByCampaign(campaignId) {
  const _event = store.getState().event;
  var _method = getUrl().campaign + `guest/getGuestsByCampaign/${_event.id}/${campaignId}`;
  const _token = await getToken();

  return new Promise((resolve, reject) => {
    const _options = {
      url: _method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + _token,
      }
    };
    axios(_options)
      .then((res) => {
        if (res.data.success && res.data && res.data.data) {
          const _guestList = [];
          res.data.data.forEach(item => {
            var _guest = createGuestModel(
              item.id,
              item.name,
            );
            _guest.selected = true;
            _guestList.push(_guest);
          });
          resolve(_guestList);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getGuestsByCampaign", err);
        reject(err);
      });
  });
}

export async function getGuestsByIdsArray(ids) {
  const _event = store.getState().event;
  var _method = getUrl().campaign + "guest/getGuestsByIds";
  const _token = await getToken();

  return new Promise((resolve, reject) => {
    const _options = {
      url: _method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + _token,
      },
      data: {
        eventId: _event.id,
        ids: ids
      }
    };
    axios(_options)
      .then((res) => {
        if (res.data.success && res.data && res.data.data) {
          const _guestList = [];
          res.data.data.forEach(item => {
            var _guest = createGuestModel(
              item.id,
              item.name,
            );
            _guestList.push(_guest);
          });
          resolve(_guestList);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getGuestsByIdsArray", err);
        reject(err);
      });
  });
}

export async function getGuestsApi(nameSearch, groupId, ids, notIds, index) {
  const _event = store.getState().event;
  var _method = getUrl().campaign + `guest/getGuests`;
  const _token = await getToken();

  const _data = {
    eventId: _event.id,
    nameSearch: nameSearch ? nameSearch : null,
    groupId: groupId ? groupId : null,
    ids: ids && ids.length > 0 ? ids : [],
    notIds: notIds && notIds.length > 0 ? notIds : [],
    index: index ? index : 1,
    perPage: 30
  };

  return new Promise((resolve, reject) => {
    const _options = {
      url: _method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + _token,
      },
      data: _data
    };
    axios(_options)
      .then((res) => {
        if (res.data.success && res.data.data) {
          const _guestList = [];
          res.data.data.data.forEach(item => {
            var _guest = createGuestModel(
              item.id,
              item.name,
            );
            _guestList.push(_guest);
          });
          resolve({
            totalIndex: res.data.data.totalIndex,
            totalItems: res.data.data.totalItems,
            guests: _guestList
          });
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getGuestsApi", err);
        reject(err);
      });
  });
}

export async function getCampaignLogApi(campaignId) {
  const _event = store.getState().event;
  var _method = getUrl().campaign + `campaign/getCampaignLog/${_event.id}/${campaignId}`;
  const _token = await getToken();

  return new Promise((resolve, reject) => {
    const _options = {
      url: _method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + _token,
      }
    };
    axios(_options)
      .then((res) => {
        if (res.data.success && res.data && res.data.data) {
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getCampaignLogApi", err);
        reject(err);
      });
  });
}

export async function addNewCampaignApi(campaign) {
  var method = getUrl().campaign + "campaign/createCampaign";
  const token = await getToken();
  const _event = store.getState().event;
  const _user = store.getState().user;

  const _campaign = {
    eventId: _event.id,
    userId: _user.id,
    name: campaign.name,
    type: campaign.type,
    templateName: campaign.templateName,
    template: campaign.template,
    tags: campaign.tags,
    targets: campaign.targets,
    url: campaign.url,
    typeUrl: campaign.typeUrl
  };

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: _campaign,
    };
    axios(options)
      .then((res) => {
        if (res && res.data.success) {
          const _objReturn = createCampaignModel(
            res.data.data.id,
            _event.id,
            campaign.name,
            campaign.type,
            campaign.templateName,
            campaign.template,
            campaign.tags,
            campaign.targets,
            res.data.data.status,
            res.data.data.sendDate,
            res.data.data.targetsSent,
            res.data.data.targetsError,
            res.data.data.active,
            res.data.data.url,
            moment().format(),
            res.data.data.typeUrl,
          );
          resolve(_objReturn);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro addNewCampaignApi", err);
        reject(err);
      });
  });
}

export async function updateCampaignApi(campaign) {
  var method = getUrl().campaign + "campaign/updateCampaign";
  const token = await getToken();
  const _event = store.getState().event;
  const _user = store.getState().user;

  const _campaign = {
    id: campaign.id,
    eventId: _event.id,
    userId: _user.id,
    name: campaign.name,
    type: campaign.type,
    templateName: campaign.templateName,
    template: campaign.template,
    tags: campaign.tags,
    targets: campaign.targets,
    url: campaign.url,
    typeUrl: campaign.typeUrl
  };

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: _campaign,
    };
    axios(options)
      .then((res) => {
        if (res && res.data.success) {
          const _objReturn = createCampaignModel(
            res.data.data.id,
            _event.id,
            campaign.name,
            campaign.type,
            campaign.templateName,
            campaign.template,
            campaign.tags,
            campaign.targets,
            res.data.data.status,
            res.data.data.sendDate,
            res.data.data.targetsSent,
            res.data.data.targetsError,
            res.data.data.active,
            res.data.data.url,
            res.data.data.createDate,
            campaign.typeUrl
          );

          resolve(_objReturn);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro updateCampaignApi", err);
        reject(err);
      });
  });
}

export async function sendCampaignApi(campaign, sendNotReceivedOnly) {
  var method = getUrl().campaign + "campaign/sendCampaign";
  const token = await getToken();
  const _event = store.getState().event;
  const _user = store.getState().user;

  const _data = {
    id: campaign.id,
    eventId: _event.id,
    userId: _user.id,
    sendNotReceivedOnly: sendNotReceivedOnly
  };

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: _data,
    };
    axios(options)
      .then((res) => {
        if (res && res.data.success) {
          resolve(res.data.data);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro sendCampaignApi", err);
        reject(err);
      });
  });
}

export async function deleteCampaignApi(campaignId) {
  var method = getUrl().campaign + "campaign/deleteCampaign";
  const token = await getToken();
  const _event = store.getState().event;
  const _user = store.getState().user;

  const _campaign = {
    id: campaignId,
    eventId: _event.id,
    userId: _user.id
  }

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: _campaign,
    };
    axios(options)
      .then((res) => {
        if (res && res.data.success) {
          resolve(true);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro deleteCampaignApi", err);
        reject(err);
      });
  });
}

export async function openCampaignSocketConnection() {
  try {
    var _method = getUrl().campaign.replace(/.$/, "");
    const _socket = await openSocket(_method, { transports: ['websocket'], path: '/message-trigger/socket.io' });
    return _socket;
  } catch (error) {
    console.log("Erro openCampaignSocketConnection", error);
    throw error;
  }
}
