export const SET_HISTORY = 'SET_HISTORY';
const setHistoryLocal = history => ({
    type: SET_HISTORY,
    history
});
export function setHistory(history) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setHistoryLocal(history));
            resolve(true);
        });
    };
}

export const GO_BACK_HISTORY = 'GO_BACK_HISTORY';
const goBackHistoryLocal = () => ({
    type: GO_BACK_HISTORY
});
export function goBackHistory() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(goBackHistoryLocal());
            resolve(true);
        });
    };
}

export const RESET_HISTORY = 'RESET_HISTORY';
const resetHistoryLocal = () => ({
    type: RESET_HISTORY
});
export function resetHistory() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetHistoryLocal());
            resolve(true);
        });
    };
}
