export const SET_NEW_NOTIFICATION = 'SET_NEW_NOTIFICATION';
const setNewNotificationLocal = notification => ({
    type: SET_NEW_NOTIFICATION,
    notification
});
export function setNewNotification(notification) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setNewNotificationLocal(notification));
            resolve(true);
        });
    };
}

export const MERGE_NOTIFICATIONS = 'MERGE_NOTIFICATIONS';
const mergeNotificationsLocal = notifications => ({
    type: MERGE_NOTIFICATIONS,
    notifications
});
export function mergeNotifications(notifications) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(mergeNotificationsLocal(notifications));
            resolve(true);
        });
    };
}

export const INATIVE_NOTIFICATION = 'INATIVE_NOTIFICATION';
const inativeNotificationLocal = notification => ({
    type: INATIVE_NOTIFICATION,
    notification
});
export function inativeNotification(notification) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(inativeNotificationLocal(notification));
            resolve(true);
        });
    };
}

export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
const resetNotificationsLocal = () => ({
    type: RESET_NOTIFICATIONS
});
export function resetNotifications() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(resetNotificationsLocal());
            resolve(true);
        });
    };
}
