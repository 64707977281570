import { SET_CONFIG, MERGE_CONFIG, RESET_CONFIG, UPDATE_CONFIG } from '../actions';

const INITIAL_STATE = [];

export default function eventReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_CONFIG:
            return [...state, ...action.config];
        case UPDATE_CONFIG:
            return [...state, ...action.config];
        case MERGE_CONFIG:
            return [...state, ...action.config];
        case RESET_CONFIG:
            return INITIAL_STATE;
        default:
            return state;
    }
}
