import React from 'react';
import { getDic } from "../../../assets/i18n/dictionary";
import {
  FormRow,
  Dialog,
  TextSmall,
  Button,
} from "../../../components";

const DialogUploadAudio = props => {
  const {
    dialogUploadAudioControl,
    setDialogUploadAudioControl,
    modalRecordAudioControl,
    setModalRecordAudioControl,
    getAudioUrl,
    setTextarea,
  } = props;

  return (
    <Dialog
      open={dialogUploadAudioControl.open}
      onClose={() => setDialogUploadAudioControl({ ...dialogUploadAudioControl, open: false })}
      onClickYes={() => setDialogUploadAudioControl({ ...dialogUploadAudioControl, open: false })}
      textButtonYes={getDic("cancelar")}
    >
      <FormRow>
        <TextSmall>
          {getDic("upload-audio-pergunta")}
        </TextSmall>
      </FormRow>
      <FormRow align="center">
        <div style={{ padding: 5 }}>
          <Button color='green' onClick={() => {
            setDialogUploadAudioControl({ ...dialogUploadAudioControl, open: false });
            setModalRecordAudioControl({ ...modalRecordAudioControl, open: true });
            setTextarea(true);
          }}>
            {getDic("gravar")}
          </Button>
        </div>
        <div style={{ padding: 5 }}>
          <Button onClick={() => {
            setDialogUploadAudioControl({ ...dialogUploadAudioControl, open: false });
            getAudioUrl();
            setTextarea(true);
          }}>
            UPLOAD
          </Button>
        </div>
      </FormRow>
    </Dialog>
  )
};

export default DialogUploadAudio;
