import React from "react";
import { FacebookProvider, LoginButton, Login } from "react-facebook";
import "../../../scss/SocialButton.scss";
const LoginFacebook = (props) => {
  const handleResponse = (data) => {
    console.log(data);
  };

  const handleError = (error) => {
    console.log(error);
  };

  return (
    <FacebookProvider appId="1065107456860939">
      <Login scope="email" onCompleted={handleResponse} onError={handleError}>
        {({ loading, handleClick, error, data }) => (
          <div className="google">
            <span onClick={handleClick} className="fb">
              Facebook
            </span>
          </div>
        )}
      </Login>
    </FacebookProvider>
  );
};

export default LoginFacebook;
