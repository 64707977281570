import React from 'react';
import { getDic } from "../../assets/i18n/dictionary";
import {
  FormRow,
  Dialog,
  TextSmall,
  InputFieldOutlined
} from "../../components";

const DialogConfirmAction = props => {
  const {
    dialogConfirmActionControl,
    setDialogConfirmActionControl,
    inactiveRaffle,
    realizeRaffle,
    removeGuestFromBlocklist,
    clearWinnersDataAndRedoRaffle,
  } = props;

  return (
    <Dialog
      open={dialogConfirmActionControl.open}
      onClose={() => setDialogConfirmActionControl({ ...dialogConfirmActionControl, open: false })}
      title={getDic("atencao")}
      onClickYes={() => {
        if (dialogConfirmActionControl.confirmText && dialogConfirmActionControl.confirmKey === dialogConfirmActionControl.confirmText) {
          if (dialogConfirmActionControl.confirmText === getDic("deletar")) {
            inactiveRaffle();
          }
          if (dialogConfirmActionControl.confirmText === getDic("sorteio")) {
            realizeRaffle(dialogConfirmActionControl.raffle);
          }
          if (dialogConfirmActionControl.confirmText === 'Blocklist') {
            removeGuestFromBlocklist();
          }
          if (dialogConfirmActionControl.confirmText === `${getDic("refazer")} ${getDic("sorteio")}`) {
            clearWinnersDataAndRedoRaffle(dialogConfirmActionControl.raffle);
          }

          setDialogConfirmActionControl({
            ...dialogConfirmActionControl,
            open: false
          });
        }
        else {
          setDialogConfirmActionControl({ ...dialogConfirmActionControl, error: true });
          setTimeout(() => {
            setDialogConfirmActionControl({ ...dialogConfirmActionControl, error: false });
          }, 3000);
        }
      }}
      onClickNo={() => setDialogConfirmActionControl({
        ...dialogConfirmActionControl,
        open: false,
        confirmText: '',
      })}
    >
      <FormRow>
        <TextSmall>
          {`${getDic("confirmacao-de-acao-dinamica")} '${dialogConfirmActionControl.confirmKey}'`}
        </TextSmall>
      </FormRow>
      <FormRow align="center" paddingTop="5px">
        <InputFieldOutlined
          key="password"
          value={dialogConfirmActionControl.confirmText}
          onChange={event => setDialogConfirmActionControl({ ...dialogConfirmActionControl, confirmText: event.target.value })}
        />
      </FormRow>
      {dialogConfirmActionControl.error ? (
        <TextSmall color="red">{getDic("erro")}</TextSmall>
      ) : (null)}
    </Dialog>
  )
};

export default DialogConfirmAction;
