import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

//Imports de components
import {
    TextSmall,
    Container,
    FormRow,
    FormColumn,
    IframePlayer
} from '../components';

class ModalProp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fullScreen: false,
            marginTop: '60px',
            marginBottom: '60px',
            marginLeft: '20%',
            marginRight: '20%',
            maxWidth: "60%",
            height: this.props.height ? this.props.height : "80vh",
            videoHeight: "500px",
            chatHeight: "80vh"
        }
    }

    changeFullScreen() {
        if (this.state.fullScreen) {
            this.setState({
                fullScreen: false,
                marginTop: '60px',
                marginBottom: '60px',
                marginLeft: '20%',
                marginRight: '20%',
                maxWidth: "60%",
                height: this.props.height ? this.props.height : "80vh",
                videoHeight: "500px",
                chatHeight: "80vh"
            });
        }
        else {
            this.setState({
                fullScreen: true,
                marginTop: '0px',
                marginBottom: '0px',
                marginLeft: '0px',
                marginRight: '0px',
                maxWidth: "100%",
                height: "96vh",
                videoHeight: "80vh",
                chatHeight: "96vh"
            });
        }
    }

    renderButton() {
        const {
            isVideo = false
        } = this.props;

        const styles = {
            button: {
                marginRight: "10px"
            }
        };

        if (!isVideo) {
            if (!this.state.fullScreen) {
                return (
                    <IconButton size="small" onClick={() => this.changeFullScreen()} style={styles.button}>.
                        <TextSmall>{getDic("tela-cheia").toUpperCase()}</TextSmall>
                    </IconButton>
                );
            }
            else {
                return (
                    <IconButton size="small" onClick={() => this.changeFullScreen()} style={styles.button}>.
                        <TextSmall>{getDic("minimizar").toUpperCase()}</TextSmall>
                    </IconButton>
                );
            }
        }
    }

    render() {
        const {
            open = false,
            background = 'white',
            closeButtonClick,
            onClose,
            disableBackdropClick = true,
            id,
            url = []
        } = this.props;

        const styles = {
            modal: {
                backgroundColor: background,
                marginTop: this.state.marginTop,
                marginBottom: this.state.marginBottom,
                marginLeft: this.state.marginLeft,
                marginRight: this.state.marginRight,
                maxWidth: this.state.maxWidth,
                // maxHeight: "60%",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
            },
            header: {
                backgroundColor: "#e0e0e0",
                width: '100%',
                textAlign: 'right',
                height: '4vh'
                // paddingLeft: '10px',
                // paddingRight: '10px'
            },
            body: {
                overflow: 'auto',
                width: '100%',
                height: this.state.height
            },
            button: {
                marginRight: "10px"
            }
        };

        return (
            <Modal
                open={open}
                onClose={onClose}
                disableBackdropClick={disableBackdropClick}
            >
                <div id={id} style={styles.modal}>
                    <div style={styles.header}>
                        {this.renderButton()}
                        <IconButton size="small" onClick={closeButtonClick} style={styles.button}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <div style={styles.body}>
                        <Container background="black" align="center" height="100%" >
                            <FormRow align="center" background="black" margin="0px">
                                <FormColumn width="70%" margin="0px" minWidth="200px">
                                    <IframePlayer
                                        height={this.state.videoHeight}
                                        src={url[0]}
                                    />
                                </FormColumn>
                                <FormColumn width="30%" margin="0px" minWidth="200px">
                                    <IframePlayer
                                        height={this.state.chatHeight}
                                        src={url[1]}
                                    />
                                </FormColumn>
                            </FormRow>
                        </Container>
                    </div>
                </div>
            </Modal>
        )
    }
};

export default ModalProp;
