import { store } from '../store';
import { setEvent } from '../store/actions';
import ReactGA from 'react-ga';
import * as eventModel from '../models/eventModel';

export async function getEventFromApiReturn(data) {

    return new Promise(async (resolve, reject) => {

        if (data.event && data.event.id) {
            // Verifica se o evento possui Google Analytics
            const _gaConfig = data.configurations.find(conf => {
                return conf.configuration.codeScreen === "USE-GOOGLE-ANALYTICS";
            });

            const event = eventModel.createEventModel(
                data.event.id,
                data.event.name,
                data.event.startDate,
                data.event.endDate,
                data.event.contractId,
                _gaConfig ? _gaConfig.configuration.url : null,
            );
            await store.dispatch(setEvent(event));
            resolve({ success: true, message: "Ok" });
        }
        else {
            reject({ success: false, message: "Evento não localizado" });
        }
    });
}

export function initGoogleAnalytics(gaId = "") {
    if (store.getState().event.id &&
        store.getState().event.id > 0 &&
        store.getState().user.id &&
        store.getState().user.id > 0
    ) {
        ReactGA.initialize(gaId ? gaId : "", {
            gaOptions: {
                userId: store.getState().user.id
            }
        });
    }
}
