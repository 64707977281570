import React from 'react';
import readXlsxFile from 'read-excel-file';
import { createWhatsappModel, convertFormToWhatsappObject, convertWhatsappObjectToForm } from '../models/whatsappModel';
import { addNewWhatsappApi, getTemplateList } from '../services/whatsappService';
import {
  getReturnObject,
  checkIfRegisterExists,
  validateFormControlList,
  verifyIfColtrolRowIsComplete,
  verifyIfTitleRowExists,
} from './utilsImporter';
import moment from 'moment';

//Imports de components
import { Loading } from '../components';

const WhatsappImporter = (props) => {

  //O método fará a leitura do arquivo
  //Irá verificar se as colunas estão conforme a planilha de exemplo
  //Depois verificará se os dados foram preencidos corretamente
  //Em caso de erro, montar um array do tipo formControlModel para retornar ao usuário onde está o erro
  function importWhatsappsTable(file) {
    return new Promise(async (resolve, reject) => {
      readXlsxFile(file)
        .then(async (rows) => {
          var _returnVerify = null;

          //Primeiro, encontrar o index da linha de controle, método duck typing
          var _controlLineIndex = null;
          _returnVerify = verifyIfControlRowExists(rows);
          if (_returnVerify.hasError.error) {
            reject(_returnVerify.hasError);
            return;
          }
          else {
            _controlLineIndex = _returnVerify.controlLineIndex;
          }

          //Segundo, verificar se alguma coluna foi deletada
          const _whatsappFormList = convertWhatsappObjectToForm(createWhatsappModel());
          _returnVerify = verifyIfColtrolRowIsComplete(rows[_controlLineIndex], _whatsappFormList);
          if (_returnVerify.hasError.error) {
            reject(_returnVerify.hasError);
            return;
          }

          //Terceiro, verificar se a linha de título foi deletada
          _returnVerify = verifyIfTitleRowExists(rows, _controlLineIndex);
          if (_returnVerify.hasError.error) {
            reject(_returnVerify.hasError);
            return;
          }

          //Quarto, converter o array de linhas em um array de objetos do tipo whatsapps e formControl
          _returnVerify = convertRowsInWhatsappAndFormControlModel(rows, _controlLineIndex);
          var _whatsappsList = _returnVerify.whatsappsList;
          if (_whatsappsList === 0) {
            var _hasError = getReturnObject();
            _hasError.error = true;
            _hasError.errorMessage = "Não há dados para cadastrar";
            reject(_hasError);
            return;
          }

          //Quinto, validar se os dados estão corretos
          var _formControlList = _returnVerify.formControlList;
          _returnVerify = validateFormControlList(_formControlList);
          if (_returnVerify.error) {
            reject(_returnVerify);
            return;
          }

          //Sexto, salvar os dados um por um, caso retorne erro, sinalizar quais linhas não foram salvas
          //Ao salvar, atribuir o id aos elementos salvos para retornar a planilha ao usuário
          const _newFormControlList = [];
          var _count = 0;

          const _templateList = await getTemplateList();

          do {
            const item = _formControlList[_count];
            var _newForm = item;
            if (!_returnVerify.error) {
              const _templateName = item.find(f => f.dbReference === 'templateName');
              const _template = _templateList.find(t => t.value === _templateName.value);
              await saveWhatsapp(item, _count, _formControlList.length, _template)
                .then(res => {
                  item.find((fr, i) => {
                    if (fr.dbReference === "id") {
                      _newForm[i].value = res.id;
                    }
                  });
                })
                .catch(err => {
                  // _returnVerify.error = true;
                  _returnVerify.errorMessage = "Um ou mais dados não foram salvos, verifique a planilha de retorno.";
                  item.find((fr, i) => {
                    if (fr.dbReference === "id") {
                      _newForm[i].error = true;
                      _newForm[i].errorMessage = err;
                    }
                  });
                })
                .finally(() => {
                  _newFormControlList.push(_newForm);
                  _count++;
                });
            }
            else {
              _count = _formControlList.length;
            }
          } while (_count < _formControlList.length);

          if (_count === _formControlList.length) {
            _returnVerify.data = _newFormControlList;
            resolve(_returnVerify);
          }

        })
        .catch(err => {
          console.log("Erro importWhatsappsTable", err);
          var _hasError = getReturnObject();
          _hasError.error = true;
          _hasError.errorMessage = `Erro ao importar planilha: ${err.toString()}`;
          reject(_hasError);
        });
    });
  }

  function verifyIfControlRowExists(rows) {
    var _hasError = getReturnObject();
    var _controlLineIndex = null;
    rows.forEach((columns, i) => {
      if (columns.includes("id") && columns.includes("templateName") && columns.includes("mobileNumber")) {
        _controlLineIndex = i;
      }
    });
    if (!_controlLineIndex) {
      _hasError.error = true;
      _hasError.errorMessage = "Linha de controle deletada";
    }
    return { hasError: _hasError, controlLineIndex: _controlLineIndex };
  }

  function convertRowsInWhatsappAndFormControlModel(rows, controlLineIndex) {
    const _controlRow = rows[controlLineIndex];
    const _whatsappsList = [];
    const _formControlList = [];

    //Cria um array de whatsapps com os dados da planilha e um array com validadores de dados
    for (let index = controlLineIndex + 2; index < rows.length; index++) {
      const item = rows[index];
      var _whatsapp = createWhatsappModel();
      var _whatsappReturnObject = [];
      _controlRow.forEach((dbReference, i) => {
        var _value = item[i] && item[i].toString().trim() !== "" ? item[i].toString().trim() : null;
        var _objReturn = validateData(dbReference, _value);
        _whatsapp[dbReference] = _value;
        _whatsappReturnObject.push({ dbReference: dbReference, field: _objReturn });
      });

      const _template = findTemplate(_whatsappReturnObject);
      const _ddi = findDdi(_whatsappReturnObject);
      if (_template && _ddi) {
        _controlRow.forEach((dbReference, i) => {
          var _value = item[i] && item[i].toString().trim() !== "" ? item[i].toString().trim() : null;
          var _objReturn;
          var _update = false;

          const _fieldTag = dbReference.substring(0, 3);
          if (_fieldTag === "tag") {
            _update = true;
            _objReturn = validateTags(dbReference, _value, _template);
          }
          if (dbReference === "mobileNumber") {
            _update = true;
            _objReturn = validatePhoneNumber(_ddi, _value);
          }
          if (dbReference === "url") {
            _update = true;
            _objReturn = validateUrl(_template, _value);
          }

          if (_update) {
            _whatsapp[dbReference] = _value;
            const _whatsappReturnIndex = _whatsappReturnObject.findIndex(obj => {
              return obj.dbReference === dbReference;
            });
            _whatsappReturnObject[_whatsappReturnIndex] = { dbReference: dbReference, field: _objReturn };
          }
        });
      }

      _whatsappsList.push(_whatsapp);

      //Atribui valores e campos de erro ao form object
      const _formControlBase = convertWhatsappObjectToForm(_whatsapp);

      const _formControlReturn = [];
      _formControlBase.forEach(obj => {
        var _field = _whatsappReturnObject.find(ret => {
          return ret.dbReference === obj.dbReference;
        });
        var _newField = obj;
        _newField.error = _field.field.error;
        _newField.errorMessage = _field.field.errorMessage;
        _newField.value = _field.field.data;

        //Verifica se os campos obrigatórios foram preenchidos
        if (_newField.dbReference !== "id" && _newField.required && (_newField.value === null || _newField.value === "")) {
          _newField.error = true;
          _newField.errorMessage = "Preenchimento obrigatório";
        }

        _formControlReturn.push(_newField);
      });
      _formControlList.push(_formControlReturn);
    }
    return { formControlList: _formControlList, whatsappsList: _whatsappsList };
  }

  function validateData(dbReference, data) {
    var _returnObj = getReturnObject();
    switch (dbReference) {
      case "id":
        _returnObj.data = data && parseInt(data) ? parseInt(data) : null;
        break;
      case "templateName":
        const _templatesList = props.templatesList;
        _returnObj = checkIfRegisterExists(data, _templatesList, _returnObj);
        break;
      case "countryNumber":
        _returnObj.data = data ? data.replace(/\D/g, "") : null;
        break;
      case "mobileNumber":
        _returnObj.data = data ? data.replace(/\D/g, "") : null;
        break;
      default:
        _returnObj.data = data ? data : null;
        break;
    }
    return _returnObj;
  }

  function findTemplate(whatsappReturnObject) {
    const template = whatsappReturnObject.find(obj => {
      return obj.dbReference === "templateName";
    });
    if (template.field.error) {
      return null;
    }
    return template.field.data;
  }

  function validateTags(dbReference, data, template) {
    var _returnObj = getReturnObject();
    const _template = props.templatesList.find(temp => {
      return temp.value === template;
    });
    const _tagId = parseInt(dbReference.substring(3, 5));
    if (_tagId <= _template.numberOfTags) {
      if (!data || data === "") {
        _returnObj.error = true;
        _returnObj.errorMessage = "Preenchimento obrigatório";
      }
      _returnObj.data = data;
    }
    else {
      _returnObj.data = data ? data : null;
    }
    return _returnObj;
  }

  function validateUrl(template, data) {
    var _returnObj = getReturnObject();
    const _template = props.templatesList.find(temp => {
      return temp.value === template;
    });

    if (!_template.hasUrl && (data && data !== '')) {
      _returnObj.error = true;
      _returnObj.errorMessage = "Template não possui url";
      _returnObj.data = data;
    }
    else {
      _returnObj.data = data ? data : null;
    }
    return _returnObj;
  }

  function findDdi(whatsappReturnObject) {
    const _countryNumber = whatsappReturnObject.find(obj => {
      return obj.dbReference === "countryNumber";
    });
    if (_countryNumber.field.error) {
      return null;
    }
    return _countryNumber.field.data;
  }

  function validatePhoneNumber(ddi, phoneNumber) {
    var _returnObj = getReturnObject();
    const _phoneNumber = phoneNumber ? phoneNumber.replace(/\D/g, "") : null;

    if (!_phoneNumber || _phoneNumber === "") {
      _returnObj.error = true;
      _returnObj.errorMessage = "Preenchimento obrigatório";
    }
    else if (ddi === "55") {
      if (_phoneNumber.length < 10) {
        _returnObj.error = true;
        _returnObj.errorMessage = "Número de telefone inválido";
      }
    }
    _returnObj.data = _phoneNumber ? _phoneNumber : null;

    return _returnObj;
  }

  function saveWhatsapp(formControl, index, totalRegister, template) {

    return new Promise(async (resolve, reject) => {
      setLoadingMessage(`Salvando ${index + 1} de ${totalRegister}`);
      var _newWhatsapp = convertFormToWhatsappObject(formControl);
      await addNewWhatsappApi(_newWhatsapp, template)
        .then(res => {
          _newWhatsapp.id = moment().format();
          resolve(_newWhatsapp);
        })
        .catch(err => {
          reject(`Erro ${err.toString()}`);
        });
    });
  }

  const importFile = () => {
    setOpenLoading(true);
    setLoadingMessage("Carregando");

    var _returnObject;
    importWhatsappsTable(props.file, props.whatsappsList)
      .then(res => {
        _returnObject = res;
      })
      .catch(err => {
        _returnObject = err;
      })
      .finally(() => {
        setOpenLoading(false);
        returnImportFile(_returnObject);
      });
  }

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState("Carregando");
  const [openLoading, setOpenLoading] = React.useState(false);

  React.useEffect(() => {
    importFile();
  }, []);

  const returnImportFile = (returnObject) => {
    if (props.returnImportFile) {
      props.returnImportFile(returnObject);
    }
  }

  return (
    <>
      <Loading open={openLoading} message={loadingMessage} />
    </>
  );
}

export default WhatsappImporter;
