export function createChatPersonalModel(
    chatId = 0,
    guestName = "",
    image = "",
    lastMessage = "",
    dateCreatedLastMessage = "",
    lastMessageSaw = false,
    online = false,
    guests = []
) {
    const obj = {
        chatId: chatId,
        guestName: guestName,
        image: image,
        lastMessage: lastMessage,
        dateCreatedLastMessage: dateCreatedLastMessage,
        lastMessageSaw: lastMessageSaw,
        online: online,
        guests: guests
    }

    return obj;
}

export function createChatPersonalGuestModel(
    guestHasChatId = 0,
    guestId = 0,
    name = "",
    urlPhoto = ""
) {
    const obj = {
        guestHasChatId: guestHasChatId,
        guestId: guestId,
        name: name,
        urlPhoto: urlPhoto
    }

    return obj;
}


// [
//     {
//       "id": 1,
//       "eventId": 67710,
//       "guestId": 551942,
//       "nome": "CLAUDIO HIRAKAWA",
//       "email": "claudio.hirakawa@inteegra.com.br",
//       "guestHasChatId": 2,
//       "message": "Firmao?",
//       "dateCreated": "2020-08-12T15:49:57.737",
//       "online": false,
//       "participantsOfTheChat": [
//         {
//           "guestHasChatId": 1,
//           "guestId": 551941,
//           "name": "ANTÔNIO MARCOS",
//           "urlPhoto": null
//         },
//         {
//           "guestHasChatId": 2,
//           "guestId": 551942,
//           "name": "CLAUDIO HIRAKAWA",
//           "urlPhoto": null
//         },
//         {
//           "guestHasChatId": 3,
//           "guestId": 551946,
//           "name": "ELAINA BEZERRA",
//           "urlPhoto": null
//         }
//       ]
//     }
//   ]
