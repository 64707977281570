import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createNewChatV2 } from "../../../services/chatPersonalService";
import { connect } from "react-redux";

import ChatContext from "../ChatContext";
import ContactCard from "../ContactCard";
const AddMemberToChat = (props) => {
  const ContextEffect = React.useContext(ChatContext);
  const [loadingRequest, setLoadingRequest] = React.useState(true);
  const [tTime, setTTime] = React.useState();
  const timerToClearSomewhere = React.useRef(null);

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      ContextEffect.cleanPagination();
    }
  };  
  const _handleKeyUp = (e) => {
    clearTimeout(timerToClearSomewhere.current);
    timerToClearSomewhere.current = setTimeout(doneTyping, 1000);
  }
  const doneTyping = () => {
    console.log(props)
    ContextEffect.cleanPagination();
  }
  const cleanAllData = () => {
    //ContextEffect.cleanPagination();
    props.enableAddNewChat(false);
    //ContextEffect.getPrivateGuests();
  };
  const addNewPrivateChat = (chatId) => {
    //ContextEffect.getAllPrivateChats(res);
    ContextEffect.setcurrenChatID(chatId);
    props.onChatSelectPress();
    props.enableAddNewChat(false);
    clearTimeout(timerToClearSomewhere.current);
  };
  React.useEffect(() => {
    if (loadingRequest) {
      ContextEffect.getPrivateGuests();
      setLoadingRequest(false);
    }
    return () => {
      clearTimeout(timerToClearSomewhere.current);
    }
  }, []);

  return (
    <ChatContext.Consumer>
      {(context) => (
        <div
          className="side-two"
          style={{ left: props.addNewChat ? "0px" : "-100%" }}
        >
          <div className="row newMessage-heading">
            <div className="row newMessage-main">
              <div className="col-sm-2 col-xs-2 newMessage-back">
                {" "}
                <i
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                  onClick={() => cleanAllData()}
                />
              </div>
              <div className="col-sm-10 col-xs-10 newMessage-title">
                {" "}
                Localizar Participante
              </div>
            </div>
          </div>
          <div className="row composeBox">
            <div className="col-sm-12 composeBox-inner">
              <div
                className="form-group has-feedback"
                style={{ paddingLeft: 20 }}
              >
                {" "}
                <input
                  id="composeText"
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Buscar"
                  value={context.searchText}
                  onChange={(e) => context.setSearchText(e.target.value)}
                  onKeyDown={_handleKeyDown}
                  onKeyUp={_handleKeyUp}
                />
                <span
                  className="fa fa-search form-control-feedback"
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() => context.cleanPagination()}
                />
              </div>
            </div>
          </div>
          <div className="row compose-sideBar" id="conversationS">
            <InfiniteScroll
              dataLength={context.listUsers.length}
              next={context.getPrivateGuests}
              hasMore={ContextEffect.endInfiniteScroolerSearch}
              loader={
                <div
                  style={{ height: "auto", textAlign: "center", padding: 33 }}
                >
                  <CircularProgress color="#337ab7" />
                </div>
              }
              height={400}
            >
              {context.listUsers.map((e, index) => (
                <div key={index} style={{ height: 70 }}>
                  <ContactCard
                    id={e.id}
                    key={index}
                    name={e.name}
                    subText={e.position}
                    avatar={e.image}
                    onChatSelectPress={props.onChatSelectPress}
                    areadyPrivate={false}
                    addNewPrivateChat={addNewPrivateChat}
                    guestObject={e}
                    myCurrentUser={props.user}
                  />
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </ChatContext.Consumer>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
    outros: state
  };
}

export default connect(mapStateToProps, null)(AddMemberToChat);
