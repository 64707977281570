export function createChatPrivateControlModel(
    hasChat = false,
    messageId = 0,
    viewed = true,
) {
    const obj = {
        hasChat: hasChat,
        messageId: messageId,
        viewed: viewed,
    }

    return obj;
}
