import React, { useCallback } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getGuestListV2 } from "../../../../services/guestService";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getDic } from "../../../../assets/i18n/dictionary";

const ScrollContacts = props => {
  const [loading, setLoading] = React.useState(true);
  const [contactList, setContactList] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [listIndex, setNewIndex] = React.useState(1);
  const searchTimeout = React.useRef();
  const searchTermContext = React.useRef();

  const searchUsers = s => {
    searchTermContext.current = s;
    clearInterval(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      firstSearch();
    }, 1000);
  };

  const firstSearch = () => {
    setNewIndex(0);
    setContactList([]);
    getContactList(1);
  };

  const getContactList = useCallback(c => {
    getGuestListV2(c, searchTermContext.current).then(res => {
      if (res.guestList.length == 0) {
        setHasMore(false);
      } else {
        if (c == 1) setContactList([...res.guestList]);
        else setContactList([...contactList, ...res.guestList]);
        if (c) setNewIndex(listIndex + 1);
      }
    });
  });

  React.useEffect(() => {
    if (loading) {
      getContactList(1);
      setLoading(false);
    }
  }, [contactList]);

  const documentView = window.screen.height - 340;
  const statusBar = window.screen.height - window.innerHeight;

  return (
    <React.Fragment>
      <div className="search-box chat-search-box py-4">
        <div className="position-relative">
          <input
            placeholder={`${getDic("buscar")}...`}
            type="text"
            className="form-control form-control"
            onChange={e => searchUsers(e.target.value)}
          />
          <i className="bx bx-search-alt search-icon" />
        </div>
      </div>
      <InfiniteScroll
        dataLength={contactList.length}
        next={() => {
          getContactList(listIndex);
        }}
        hasMore={hasMore}
        loader={
          <div style={{ height: "auto", textAlign: "center", padding: 33 }}>
            <CircularProgress
              color="#337ab7"
              style={{ width: 20, height: 20 }}
            />
          </div>
        }
        height={
          props.fullScreen == "80vh"
            ? props.heightParent - 293
            : documentView - statusBar
        }
      >
        {contactList
          .filter(e => e.id != props.user.id)
          .map((e, index) => (
            <li
              index={index}
              key={index}
              style={{
                listStyle: "none",
                marginTop: 5,
                marginBottom: 5,
                transition: "0.5s"
              }}
              onClick={() => {
                props.setMessageArea(true);
                console.log(e);
                props.setCurrentTargetMsg({
                  id: e.id,
                  image: e.image,
                  institution: "",
                  name: e.name,
                  position: "",
                  message: "",
                  lastMsg: ""
                });
              }}
              className={
                props.currentTargetMsg.id == e.id ? "active-contact-list" : ""
              }
            >
              <a>
                <div className="align-items-center media">
                  <div className="avatar-xs me-3">
                    {e.image ? (
                      <img
                        src={e.image}
                        className="rounded-circle avatar-xs"
                        alt
                      />
                    ) : (
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                        {e.name[0].toUpperCase()}
                      </span>
                    )}
                  </div>
                  <div className="media-body">
                    <h5 className="font-size-14 mb-0">
                      {e.name.toUpperCase()}
                    </h5>
                    {e.position && e.institution && (
                      <p>
                        {e.position} - {e.institution}
                      </p>
                    )}
                  </div>
                </div>
              </a>
            </li>
          ))}
      </InfiniteScroll>
    </React.Fragment>
  );
};

export default ScrollContacts;
