export const SET_DATE = 'SET_DATE';
const setDateLocal = date => ({
    type: SET_DATE,
    date
});
export function setDate(date) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(setDateLocal(date));
            resolve(true);
        });
    };
}

export const CLEAR_DATE = 'CLEAR_DATE';
const clearDateLocal = () => ({
    type: CLEAR_DATE
});
export function clearDate() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(clearDateLocal());
            resolve(true);
        });
    };
}
