import { SET_SCHEDULES, RESET_SCHEDULES } from '../actions';

const INITIAL_STATE = [];

export default function scheduleReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_SCHEDULES:
            return [...state, ...action.schedules];
        case RESET_SCHEDULES:
            return INITIAL_STATE;
        default:
            return state;
    }
}
