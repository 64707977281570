import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import {
  getAllChatGuests
} from "../../services/chatPersonalService";
import ChatContext from "./ChatContext";
import ContactCard from "./ContactCard";
import ChatArea from "./ChatArea";
import AddMemberToChat from "./AddMemberToChat";
import "../../scss/ChatList.scss";
import "../../scss/BootStrap/index.scss";
import * as navigationHistoryService from "../../services/navigationHistoryService";

function MainContainer(props) {
  const ContextEffect = React.useContext(ChatContext);
  const [alreadyStarted, setAlreadyStarted] = React.useState(false);
  const [addNewChat, enableAddNewChat] = React.useState(false);
  const [collapseChat, setCollapseChat] = React.useState(false);
  const [firstRequests, setAlreadyRequested] = React.useState(false);
  const [allEventUsers, setEventUsers] = React.useState();
  const privateChatStatic = React.useRef(ContextEffect.privateChat);
  const [openTemp, setOpenTemp] = React.useState(false);
  privateChatStatic.current = ContextEffect.privateChat;

  const onChatSelectPress = (i) => {
    setCollapseChat(i);
    enableAddNewChat(false);
  };
  const changeSearch = (term) => {
    ContextEffect.setNewSearchTermBox(term.toUpperCase());
  };
  const checkOnlineTime = (e) => {
    if (!e.lastOnlineDate) return false;
    let date = new Date(e.lastOnlineDate);
    if (Date.now() - date > 164417) return false;
    else return true;

  }
  const getAllChat = async () => {
    return getAllChatGuests(props.user.id).then(result => {
      const { data } = result;
      let activeChats = data.filter(e => e.messages.length > 0);
      let chatData = [];
      activeChats.forEach(element => {
        element.lastMessage = element.messages[element.messages.length - 1].dateCreated;
        element.online = checkOnlineTime(element.members.filter(e => e.guestId != props.user.id)[0])
        chatData.push(element);
      });
      ContextEffect.setPrivateChat([...chatData])

      if (chatData.length < 1) {
        if (!openTemp) {
          enableAddNewChat(true);
          setOpenTemp(true)
        }
      }
    })
  }

  React.useEffect(() => {
    let privateMessages;
    if (!alreadyStarted) {
      getAllChat();
      setInterval(() => {
        getAllChat();
      }, 5000);
    }
    const poweredBy = document.getElementById("poweredBy");
    if (poweredBy) poweredBy.style.display = 'none';
    setAlreadyStarted(true);
    return () => {
      const poweredBy = document.getElementById("poweredBy");
      if (poweredBy) poweredBy.style.display = 'block';
      clearTimeout(privateMessages);
    }

  }, [ContextEffect.privateChat]);

  return (
    <div className="parent" style={{ overflow: "hidden" }}>
      <div className="container app" style={{ height: "92vh" }}>
        <div className="row app-one" style={{ marginRight: -13 }}>
          <div
            className={`col-sm-4 col-md-4 side fix-contact-list ${collapseChat ? "chat-collapse" : "teste"
              }`}
            style={{ height: "92vh" }}
          >
            <div className="side-one">
              <div
                className="row heading"
                style={{ marginLeft: "0px !important" }}
              >
                <div className="col-sm-2 col-xs-2 heading-avatar">
                  <div className="heading-avatar-icon">
                    <img
                      src={
                        props.user.image
                          ? props.user.image
                          : "https://s3.amazonaws.com/bucket.aws.public/FotoPerfilInteegra/DEFAULT.png"
                      }
                      onClick={() => navigationHistoryService.setHistory(props.history, '/' + props.event.id + '/profile')}
                    />
                  </div>
                </div>

                <div
                  className="col-sm-9 col-xs-9 heading-compose  pull-right"
                  style={{
                    display: "flex",
                    left: 35,
                    marginRight: 15,
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      top: "15%",
                      position: "relative",
                      fontWeight: 600,
                      color: "#565656",
                      cursor: "pointer",
                      flex: 1,
                      textAlign: "right",
                    }}
                    onClick={() => enableAddNewChat(true)}
                  >
                    Localizar Participante
                  </span>
                  <i
                    className="fa fa-comments fa-2x  pull-right"
                    aria-hidden="true"
                    onClick={() => enableAddNewChat(true)}
                  />
                </div>
              </div>
              <div className="row searchBox">
                <div className="col-sm-12 searchBox-inner">
                  <div className="form-group has-feedback">
                    <input
                      id="searchText"
                      type="text"
                      className="form-control"
                      name="searchText"
                      placeholder="Buscar"
                      value={ContextEffect.searchTermBox}
                      onChange={(e) => changeSearch(e.target.value)}
                    />
                    <span className="fa fa-search form-control-feedback" />
                  </div>
                </div>
              </div>
              <div className="row sideBar" id="conversationS">
                {privateChatStatic.current.sort(function (a, b) {
                  return b.lastMessage > a.lastMessage ? 1 : a.lastMessage > b.lastMessage ? -1 : 0;
                }).map((e, index) => (
                  <div key={index} style={{ height: 70 }}>
                    {e.members &&
                      <ContactCard
                        id={e.members.filter(e => e.guestId != props.user.id)[0].guestId}
                        key={index}
                        name={e.members.filter(e => e.guestId != props.user.id)[0].name}
                        subText=""
                        avatar={e.members.filter(e => e.guestId != props.user.id)[0].imageUrl}
                        onChatSelectPress={onChatSelectPress}
                        areadyPrivate={true}
                        data={e}
                        chatObject={{
                          "chat_id": e.id,
                          "id": e.members.filter(e => e.guestId != props.user.id)[0].guestId,
                          "name": e.members.filter(e => e.guestId != props.user.id)[0].name,
                          "avatar": e.members.filter(e => e.guestId != props.user.id)[0].imageUrl,
                          "institution": e.members.filter(e => e.guestId != props.user.id)[0].institution,
                          "online": e.online
                        }}
                        selected={e.id == ContextEffect.chatObject.id ? true : false}
                      />

                    }
                  </div>
                ))}
              </div>
            </div>
            <AddMemberToChat
              addNewChat={addNewChat}
              enableAddNewChat={enableAddNewChat}
              onChatSelectPress={onChatSelectPress}
            />
          </div>
          <ChatArea
            onChatSelectPress={onChatSelectPress}
            collapseChat={collapseChat}
            history={props.history}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(MainContainer);
