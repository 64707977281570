import axios from 'axios';
import { store } from '../store';
import { getToken, getUrl } from './apiService';
import { createGroupModel } from '../models/groupModel';

export async function getGroupsApi() {

    const _event = store.getState().event;
    var method = getUrl().hall + "event/attribute/group/" + _event.id.toString();
    const token = await getToken();

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
        };
        axios(options)
            .then((res) => {
                if (res.data && res.data.success) {
                    const _groups = [];

                    res.data.data.forEach(item => {
                        const _group = createGroupModel(
                            item.id,
                            item.nameGroup,
                        );
                        _groups.push(_group);
                    });
                    resolve(_groups);
                } else {
                    reject(res.data);
                }
            })
            .catch((err) => {
                console.log("Erro getGroupsApi", err);
                reject(err);
            });
    });
}

export async function addNewGroupApi(group) {

    var method = getUrl().hall + "event/attribute/group";
    const token = await getToken();
    const _event = store.getState().event;

    const _group = {
        eventId: _event.id,
        name: group.name,
    }

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
            data: _group,
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    var _objReturn = group;
                    _objReturn.id = res.data.data;

                    resolve(_objReturn);
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log("Erro addNewGroupApi", err);
                reject(err);
            });
    });
}

export async function updateGroupApi(group) {
    var method = getUrl().hall + "event/attribute/group";
    const token = await getToken();
    const _event = store.getState().event;

    const _group = {
        id: group.id,
        eventId: _event.id,
        name: group.name,
    }

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
            data: _group,
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    resolve(group);
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log("Erro updateGroupApi", err);
                reject(err);
            });
    });
}

export async function deleteGroupApi(groupId) {
    var method = getUrl().hall + "event/attribute/group/" + groupId.toString();
    const token = await getToken();

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
        };
        axios(options)
            .then((res) => {
                if (res && res.data.success) {
                    resolve(true);
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                console.log("Erro deleteGroupApi", err);
                reject(err);
            });
    });
}

