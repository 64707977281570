
import * as rankingService from "../services/rankingService";
import { store } from "../store";

export function rankingInteegraPlusReceiver() {
  window.addEventListener("message", function (e) {
    if (
      e.data &&
      e.data.message &&
      e.data.message === "setRankingInteegraPlusReceiver" &&
      e.data.rankingCode
    ) {
      let _useRankingInteegraEventos = store.getState().config.find(c => { return c.codeScreen === 'USE-RANKING-INTEEGRA-EVENTOS-PLUS' });

      if (
        _useRankingInteegraEventos &&
        _useRankingInteegraEventos.urlString &&
        store.getState().guest.internalCode
      ) {
        rankingService.setAdminPlusRankingPoints(
          e.data.rankingCode,
          false,
          store.getState().guest.internalCode,
          _useRankingInteegraEventos.urlString,
        );
      }
    }
  });
}
