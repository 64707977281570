import React from 'react';
import { store } from '../store';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import {
  TextSmall,
  FormColumn
} from './';

const SelectListProp = ({
  title = "",
  width = "120px",
  maxWidth = "none",
  value,
  menuItens,
  onChange,
  error = false,
  errorMessage = "",
  required = false,
  disabled = false,
  multiple = false,
  isQuizTransmission = false,
}) => {

  const [color, setColor] = React.useState("#3f51b5");
  const [borderRadius, setBorderRadius] = React.useState("5px");
  const [useTitleAsPlaceholder, setUseTitleAsPlaceholder] = React.useState(false);
  const [menuItensSanitized, setMenuItensSanitized] = React.useState(menuItens);

  React.useEffect(() => {
    getInputConfig();
  }, []);

  React.useEffect(() => {
    sanitizeMenuItems();
  }, [menuItens]);

  const getInputConfig = () => {
    var inputConfig = store.getState().config.find(item => {
      return item.codeScreen === "INPUT-CONFIG"
    });
    if (inputConfig) {
      inputConfig.url.forEach(item => {
        const _prop = item.split(":");
        switch (_prop[0]) {
          case "borderRounded":
            setBorderRadius(_prop[1] === "true" ? "90px" : "5px");
            break;
          case "useTitleAsPlaceholder":
            setUseTitleAsPlaceholder(_prop[1] === "true" ? true : false);
            break;
          case "color":
            setColor(_prop[1]);
            break;
          default:
            break;
        }
      });
    }
  }

  const sanitizeMenuItems = () => {
    let _menuItems = [];
    menuItens.forEach(item => {
      _menuItems.push({
        text: item.text && typeof item.text === 'string' ? item.text.trim() : item.text,
        value: item.value && typeof item.value === 'string' ? item.value.trim() : item.value,
      });
    });
    setMenuItensSanitized(_menuItems);
  }

  const styles = {
    field: {
      fontFamily: "verdana",
      fontSize: "25px",
      minWidth: width,
      maxWidth: maxWidth,
      background: "white",
      textAlign: "left",
      borderRadius: borderRadius,
      height: error ? "77px" : "56px",
    }
  };

  // var color = "#3f51b5";
  // var inputConfig = store.getState().config.find(item => {
  //     return item.codeScreen === "INPUT-CONFIG"
  // });
  // if (inputConfig) {
  //     color = inputConfig.url[0];
  // }

  // var color = "#3f51b5";
  // var inputConfig = store.getState().config.find(item => {
  //     return item.codeScreen === "INPUT-CONFIG"
  // });
  // if (inputConfig) {
  //     color = inputConfig.url[0];
  // }

  // const theme = createMuiTheme({
  //     palette: {
  //         primary: {
  //             main: color
  //         }
  //     }
  // });

  const localOnChange = (value) => {
    if (onChange) {
      onChange(null, value);
    }
  }

  if (isQuizTransmission) {
    return (
      <>
        <ToggleButtonGroup orientation="vertical" size="small" value={value} exclusive onChange={onChange}>
          <FormColumn margin='0px'>
            {menuItensSanitized.map((item) => {
              return (
                <ToggleButton
                  value={item.value}
                  selected={value === item.value ? true : false}
                  onClick={() => { localOnChange(item.value) }}
                >
                  <div title={item.text} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    {item.text}
                  </div>
                </ToggleButton>
              );
            })}
            {error && (
              <TextSmall color={'red'}>{errorMessage}</TextSmall>
            )}
          </FormColumn>
        </ToggleButtonGroup>
      </>
    );
  }
  return (
    <FormControl
      variant="outlined"
      style={styles.field}
      error={error}
      required={required}
      disabled={disabled}
    >
      <InputLabel>{
        !useTitleAsPlaceholder ? title :
          !value || value === '' ? title : null
      }</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={!useTitleAsPlaceholder ? title :
          !value || value === '' ? title : null}
        multiple={multiple}
        style={{ borderRadius: borderRadius }}
        input={multiple ? <OutlinedInput label={title} /> : null}
        inputProps={{
          style: {
            borderRadius: borderRadius
          }
        }}
        renderValue={multiple ? selected => {
          if (selected.length === 0) {
            return <em>Placeholder</em>;
          }
          var _returnStr = "";
          selected.forEach((item, i) => {
            var _obj = menuItensSanitized.find(obj => {
              return obj.value === item;
            })
            if (_obj) {
              _returnStr += i === 0 ? _obj.text : ", " + _obj.text;
            }
          });
          return _returnStr;
        } : null}
      >
        {menuItensSanitized.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value} style={{ maxWidth: window.innerWidth - 80, whiteSpace: 'normal' }}>
              <div title={item.text} style={{ wordBreak: "break-word", maxWidth: window.innerWidth - 100 }}>
                {item.text}
              </div>
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
};

export default SelectListProp;
