import { convertFileSizeFromBytesToMb } from '../utils';
import { getUrl } from '../services/apiService';

export function createFileModel(
    id = 0,
    contractId = 0,
    tableId = 0,
    dateCreated = "",
    name = "",
    extension = "",
    size = 0,
    category = "",
    defaultUrl = "",
) {
    var fileSize = convertFileSizeFromBytesToMb(size);

    const obj = {
        id: id,
        contractId: contractId,
        tableId: tableId,
        dateCreated: dateCreated,
        name: name,
        extension: extension,
        size: fileSize,
        category: category,
        defaultUrl: defaultUrl,

        //Variavel de controle de renderização para tela de arquivos
        isLoaded: false,
    }

    return obj;
}

export function getUrlFromFileObject(
    fileObject
) {
    var _return;
    if (fileObject.defaultUrl && fileObject.defaultUrl !== "") {
        _return = fileObject.defaultUrl
    }
    else {
        var _date = fileObject.dateCreated.split("-");
        var _return = getUrl().files + _date[0] + "/" + fileObject.id + "." + fileObject.extension;
    }
    return _return;
}

export function inteegraflixExternalUrlModel(
    url = "",
    category = "",
) {
    const obj = {
        url: url,
        categoria: category,
    }

    return obj;
}
