import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { getConfigListByPage, getCoordinateList } from '../services/configService';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { setChosenPavilion } from '../store/actions';
import { isMobile } from "react-device-detect";
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';

//Imports de components
import {
    Container,
    FormRow,
    ActivityIndicator,
    MenuButton,
    BackButton,
    ImageMapRender,

    TextBig,
    MobileRender,
    FabButton,
    Hall3D
} from '../components';

class StandsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            configListComplete: [],
            configList: [],
            mobileRender: false,

            imageURLBackground_1600x900: "",
            mapDefault_1600x900: {
                name: "my-map",
                areas: [
                    { name: "STAND-1", shape: "poly", coords: [2, 365, 179, 363, 179, 436, 280, 439, 282, 587, 170, 652, 2, 649], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "STAND-2", shape: "poly", coords: [252, 358, 351, 355, 355, 406, 419, 410, 416, 513, 367, 544, 286, 540, 285, 434, 228, 434, 228, 417, 252, 412], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "STAND-3", shape: "poly", coords: [376, 351, 443, 351, 446, 390, 490, 390, 492, 472, 461, 486, 419, 485, 422, 406, 362, 403, 379, 392], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "STAND-4", shape: "poly", coords: [447, 350, 499, 348, 502, 378, 535, 378, 540, 443, 518, 457, 492, 454, 492, 386, 449, 386], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "STAND-5", shape: "poly", coords: [1000, 347, 1058, 348, 1061, 386, 1008, 389, 1011, 455, 981, 448, 966, 379], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "STAND-6", shape: "poly", coords: [1065, 351, 1137, 354, 1137, 392, 1158, 396, 1157, 405, 1111, 405, 1113, 491, 1059, 491, 1015, 468, 1012, 391, 1065, 391], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "STAND-7", shape: "poly", coords: [1174, 355, 1267, 356, 1270, 413, 1291, 418, 1292, 431, 1243, 435, 1244, 543, 1152, 537, 1116, 512, 1114, 408, 1172, 408], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "STAND-8", shape: "poly", coords: [1359, 362, 1547, 366, 1550, 452, 1598, 458, 1596, 659, 1404, 649, 1247, 584, 1247, 438, 1359, 434], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                    { name: "ELEMENT-001", shape: "rect", coords: [717, 521, 909, 645], tooltip: "" },//, preFillColor: "transparent", fillColor: "transparent" },
                ]
            },
            map_1600x900: null
        }
    }

    async componentDidMount() {

        //Aplica configurações
        const configList = await getConfigListByPage(this.props.match.url);

        configList.find(item => {
            if (item.codeScreen === "NO-MOBILE") {
                this.setState({ mobileRender: true });
            }
            return null;
        });

        const _chosenPavilion = this.props.chosenPavilion ? this.props.chosenPavilion : 1;
        this.getCoordinatesByPavilion(configList, _chosenPavilion);

        this.setState({ configListComplete: configList });

        await insertNewAccess(this.props.match.url, "", getSystemActions().accessPage);
    }

    componentWillUnmount() {
        insertNewAccess(this.props.match.url, "", getSystemActions().exitPage)
            .then(() => {
                // window.location.reload();
            });
    }

    componentDidCatch(e) {
        var error = e.toString();

        if (error.includes("Error: Maximum update depth exceeded")) {
            window.location.reload();
        }
    }

    async getCoordinatesByPavilion(configList, pavilion) {
        this.setState({ isLoading: true });

        this.props.setChosenPavilion(pavilion);

        const _configListPavilion = configList.filter(item => {
            return item.pavilion === pavilion;
        });

        const coordinateList = await getCoordinateList(this.state.mapDefault_1600x900, _configListPavilion);

        this.setState({ isLoading: false, configList: _configListPavilion, map_1600x900: coordinateList });
    }

    render() {
        if (this.state.isLoading)
            return (
                <Container background="#e8eced" align="center" >
                    <FormRow align="center">
                        <ActivityIndicator />
                    </FormRow>
                </Container>
            );
        if (this.props.stands.length <= 0) {
            return (
                <Container background="#212121" align="center" >
                    <MenuButton history={this.props.history} />
                    <BackButton
                        history={this.props.history}
                        color="#212121"
                    >
                        {getDic("voltar").toUpperCase()}
                    </BackButton>


                    <FormRow align="center">
                        <TextBig color="#e8eced">
                            {getDic("stand-mensagem-erro")}
                        </TextBig>
                    </FormRow>
                </Container>
            );
        }
        if (this.state.configList.find(e => e.codeScreen == 'ENABLE_HALL_3D'))
            return (
                <React.Fragment>
                    <MenuButton history={this.props.history} />
                    <BackButton
                        history={this.props.history}
                        color="#212121"
                    >
                        {getDic("voltar").toUpperCase()}
                    </BackButton>

                    <Hall3D
                        history={this.props.history}
                        hall_360_url={this.state.configList.find(e => e.codeScreen == 'ENABLE_HALL_3D').url[0]}
                        configList={this.state.configList}
                    />
                </React.Fragment>
            )
        return (
            <div>
                {!this.props.isModal && (
                    <>
                        <MenuButton history={this.props.history} />
                        <BackButton history={this.props.history} color="#212121">
                            {getDic("voltar").toUpperCase()}
                        </BackButton>

                    </>
                )}

                {/* Controle dos pavilhões */}
                {this.props.pavilions.length > 1 ? (
                    <div style={{
                        position: "fixed",
                        width: "100%",
                        textAlign: "center",
                        top: "5px",
                        zIndex: "2"
                    }}>
                        <FabButton
                            size="Small"
                            disabled={this.props.chosenPavilion === 1 ? true : false}
                            color="#212121"
                            onClick={() => this.getCoordinatesByPavilion(this.state.configListComplete, this.props.chosenPavilion - 1)}
                        >
                            <ArrowLeft />
                        </FabButton>
                        <FabButton
                            size="Small"
                            color="#212121"
                        >
                            {getDic("pavilhao")} {this.props.chosenPavilion}
                        </FabButton>
                        <FabButton
                            size="Small"
                            disabled={this.props.chosenPavilion === this.props.pavilions.length ? true : false}
                            color="#212121"
                            onClick={() => this.getCoordinatesByPavilion(this.state.configListComplete, this.props.chosenPavilion + 1)}
                        >
                            <ArrowRight />
                        </FabButton>
                    </div>
                ) : (null)}

                {isMobile && this.state.mobileRender ? (
                    <MobileRender
                        configList={this.state.configList}
                        defaultImage={this.state.imageURLBackground_1600x900}
                        history={this.props.history}
                    />
                ) : (
                    <div>
                        <ImageMapRender
                            configList={this.state.configList}
                            history={this.props.history}
                            defaultImage={this.state.imageURLBackground_1600x900}
                            defaultMap={this.state.map_1600x900}
                        />
                    </div>
                )
                }
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        stands: state.stands,
        chosenPavilion: state.chosenPavilion,
        pavilions: state.pavilions
    }
}

const mapDispatchToProps = {
    setChosenPavilion,
}

export default connect(mapStateToProps, mapDispatchToProps)(StandsPage)
