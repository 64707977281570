import React from "react";
import moment from "moment";
import { Button } from "reactstrap";

const App = ({ i, e, searchUserAnswer, id, survey }) => {
  const [questions, setQuestions] = React.useState([]);

  const callUserData = async (currentSurvey, guestId) => {
    const returnData = await searchUserAnswer(id, guestId);

    let sv = [];
    for (let a = 0; a < currentSurvey.questions.length; a++) {
      sv = [
        ...sv,
        returnData.find((e) => e.questionId == survey.questions[a].question.id),
      ];
    }
    setQuestions(sv);
  };
  React.useEffect(() => {});
  return (
    <React.Fragment>
      <tr key={i}>
        <td style={{ textAlign: "left" }}>
          {i > 2 && `${i + 1}º`}
          {i == 0 && (
            <i
              class="fas fa-medal"
              style={{ color: "#ff9800", fontSize: 25 }}
            ></i>
          )}
          {i == 1 && (
            <i
              class="fas fa-medal"
              style={{ color: "#9e9e9e", fontSize: 25 }}
            ></i>
          )}
          {i == 2 && (
            <i
              class="fas fa-medal"
              style={{ color: "#c97903", fontSize: 25 }}
            ></i>
          )}
        </td>
        <td style={{ textAlign: "left" }}>
          {e.guestName ? e.guestName : e.answerLogin}{" "}
          {e.guestName && `(${e.guestId})`}
        </td>
        <td style={{ textAlign: "left" }}>{e.currentData}</td>
        <td style={{ textAlign: "left" }}>{e.score}</td>
        <td style={{ textAlign: "center" }}>
          {questions.length > 0 ? (
            <i
              class="fas fa-times-circle"
              style={{ cursor: "pointer" }}
              onClick={() => setQuestions([])}
            >
              {" "}
            </i>
          ) : (
            <i
              class="fas fa-info-circle"
              style={{ cursor: "pointer" }}
              onClick={() => callUserData(survey, e.guestId)}
            ></i>
          )}
          {questions.length > 0 && (
            <div>
              <table
                entries={2}
                className=""
                style={{ width: "100%", borderTop: "1px solid #eff2f7" }}
              >
                <thead data-test="datatable-head">
                  <tr>
                    <th className="sorting" style={{ textAlign: "left" }}>
                      Pergunta
                    </th>
                    <th className="sorting" style={{ textAlign: "left" }}>
                      Resposta
                    </th>
                    <th className="sorting" style={{ textAlign: "left" }}>
                      Data
                    </th>
                  </tr>
                </thead>
                <tbody data-test="table-body">
                  {questions
                    .filter((e) => e && e.questionId != null)
                    .map((e, i) => (
                      <tr key={i}>
                        <td style={{ textAlign: "center" }}>{e.question}</td>
                        <td style={{ textAlign: "center" }}>{e.userAnswer}</td>
                        <td style={{ textAlign: "center" }}>
                          {e.answerHour} - {e.answerDate}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default App;
