import React from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { firestore } from "../../utils/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import { ElementTreatment } from "../";
const AlertNetwork = (props) => {
  const [enableNetwork, setEnableNetwork] = React.useState(false);
  const alertRef = firestore
    .collection(`network/${props.event ? props.event.id : 0}/messages/`)
    .doc(`${props.user ? props.user.id : 0}`);

  const [privates] = useDocumentData(alertRef, { idField: "id" });

  React.useEffect(() => {}, [privates]);

  return (
    <div
      style={{
        position: "absolute",
        left: 80,
        top: 6,
        zIndex: 1111,
        cursor: "pointer",
      }}
    >
      {enableNetwork && (
        <ElementTreatment
          chosenConfig={
            props.config.filter(
              (e) => e.url.length > 0 && e.url[0].indexOf("chatv2") > -1
            )[0]
          }
          onClose={() => {
            let ids = ["launcher", "menu_btn", "back_btn", "exit_btn"];
            ids.forEach((element) => {
              let _e = document.getElementById(element);
              if (_e) {
                _e.style.display = "block";
              }
            });
            setEnableNetwork(!enableNetwork);
          }}
        />
      )}
      {privates && privates.pendingMsgs && privates.pendingMsgs.length > 0 && (
        <React.Fragment>
          <i
            className="fas fa-bell tada"
            style={{
              fontSize: 23,
              color: "white",
              textShadow: "0 0 10px blue",
            }}
            onClick={() => setEnableNetwork(!enableNetwork)}
          ></i>
          <span
            onClick={() => setEnableNetwork(!enableNetwork)}
            style={{
              borderRadius: 16,
              background: "#e83e8c",
              padding: "0px 5px",
              position: "relative",
              top: -8,
              color: "white",
              fontSize: 12,
              boxShadow: "0 0 10px #ff000059",
            }}
          >
            {privates.pendingMsgs.length}
          </span>
        </React.Fragment>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
    config: state.config,
  };
}

export default connect(mapStateToProps, null)(AlertNetwork);
