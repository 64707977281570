import axios from 'axios';
import { store } from "../store";
import { getDic } from "../assets/i18n/dictionary";
import { getUrl, getToken, getDate } from "./apiService";
import {
  createConfigModel,
  createCoordinateModel,
} from "../models/configModel";
import { createStandModel } from "../models/standModel";
import {
  setStands,
  resetStands,
  setPavilions,
  resetPavilions,
  mergeConfig,
} from "../store/actions";
import moment from 'moment';

export async function getScreenCodeListApi() {

  const token = await getToken();
  var method = getUrl().hall + 'event/configuration/screen-code';

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token
      }
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          const _screenCodeList = [];
          res.data.forEach(item => {
            if (item.active) {
              const _obj = {
                value: item.id,
                text: item.description,
              }
              _screenCodeList.push(_obj);
            }
          });

          _screenCodeList.sort((a, b) => {
            return a.text > b.text
              ? 1
              : b.text > a.text
                ? -1
                : 0;
          });

          resolve(_screenCodeList);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log('Erro getScreenCodeListApi', err);
        reject(err);
      });
  });
}

export async function getConfigListByPageIdApi(pageId, eventId) {

  const token = await getToken();
  const _eventId = eventId ? eventId : store.getState().event.id;

  var method = getUrl().hall + 'event/configuration/list?eventId=' + _eventId;
  if (pageId) {
    method = method + '&screenId=' + pageId.toString();
  }

  return new Promise((resolve, reject) => {

    const options = {
      url: method,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token
      },
    };
    axios(options)
      .then(res => {
        if (res.data) {
          const _configList = [];

          res.data.forEach((object) => {
            const item = object.configuration;
            if (item.active === true) {
              //Pega o standId
              var standId;
              if (item.stand) standId = item.stand.id;

              var _coordinates = [];
              if (item.coordinates && item.coordinates !== "") {
                var coordStr2 = item.coordinates.replace(" ", "");
                _coordinates = coordStr2.split(",");
                for (var i = 0; i < _coordinates.length; i++) {
                  _coordinates[i] = +_coordinates[i];
                }
              }

              var _config = createConfigModel(
                item.id,
                item.templateID,
                item.screenID,
                item.typeID,
                item.codeScreen,
                item.url,
                item.tooltip,
                item.eventID,
                _coordinates,
                item.coordinates,
                standId,
                item.pavilion ? parseInt(item.pavilion) : 0,
                null,
                item.password,
                object.configXGroup,
                item.hasFullScreen,
                item.initialDate,
                item.endDate,
                item.description,
                item.html,
                item.levels,
              );
              _configList.push(_config);
            }
          });

          resolve(_configList);
        }
        else {
          reject(res.data);
        }
      })
      .catch(err => {
        console.log("Erro getConfigListByPageIdApi", err);
        reject(err);
      })
  });
}

export async function addNewConfigApi(config, screenCodeId) {

  const token = await getToken();
  const event = store.getState().event;
  var method = getUrl().hall + 'event/configuration/register';

  const _data = [];
  const _config = {
    templateID: 1,
    screenID: config.screenId,
    typeID: config.typeId,
    codeScreen: config.codeScreen,
    url: config.urlString,
    eventID: event.id,
    tooltip: config.tooltip && config.tooltip !== "" ? config.tooltip : null,
    standId: config.standId > 0 ? config.standId : null,
    password: config.password && config.password !== "" ? config.password : null,
    coordinates: config.coordinatesString && config.coordinatesString !== "" ? config.coordinatesString : null,
    screenCodeId: screenCodeId,
    group: config.groupId,
    hasFullScreen: config.hasFullScreen,
    pavilion: config.pavilion ? config.pavilion.toString() : null,
    initialDate: config.initialDate && config.initialDate !== "" ? config.initialDate : null,
    endDate: config.endingDate && config.endingDate !== "" ? config.endingDate : null,
    description: config.description && config.description !== "" ? config.description : null,
    html: config.html && config.html !== "" ? config.html : null,
  }
  _data.push(_config);

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token
      },
      data: _data
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data[0] && res.data[0].success) {
          var _return = config;
          _return.id = res.data[0].data.id

          resolve(_return);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log('Erro addNewConfigApi', err);
        reject(err);
      });
  });
}

export async function updateConfigApi(config, screenCodeId) {

  const token = await getToken();
  const event = store.getState().event;
  var method = getUrl().hall + 'event/configuration/update';

  const _data = [];
  const _config = {
    id: parseInt(config.id),
    templateID: 1,
    screenID: config.screenId,
    typeID: config.typeId,
    codeScreen: config.codeScreen,
    url: config.urlString,
    active: true,
    eventID: event.id,
    tooltip: config.tooltip && config.tooltip !== "" ? config.tooltip : null,
    coordinates: config.coordinatesString && config.coordinatesString !== "" ? config.coordinatesString : null,
    password: config.password && config.password !== "" ? config.password : null,
    standId: config.standId > 0 ? config.standId : null,
    screenCodeId: screenCodeId,
    group: config.groupId,
    hasFullScreen: config.hasFullScreen,
    pavilion: config.pavilion ? config.pavilion.toString() : null,
    initialDate: config.initialDate && config.initialDate !== "" ? config.initialDate : null,
    endDate: config.endingDate && config.endingDate !== "" ? config.endingDate : null,
    description: config.description && config.description !== "" ? config.description : null,
    html: config.html && config.html !== "" ? config.html : null,
  }
  _data.push(_config);

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token
      },
      data: _data
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data[0] && res.data[0].success) {
          var _return = config;
          _return.id = res.data[0].data.id

          resolve(_return);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log('Erro updateConfigApi', err);
        reject(err);
      });
  });
}

export async function deleteConfigApi(config) {

  const token = await getToken();
  var method = getUrl().hall + 'event/configuration/inactive';

  const _data = [];
  const _config = {
    id: config.id,
  }
  _data.push(_config);

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token
      },
      data: _data
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data[0] && res.data[0].success) {
          resolve(res.data[0].data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log('Erro deleteConfigApi', err);
        reject(err);
      });
  });
}

export async function getConfigListAndStandsAndPavilionsFromApiReturn(data) {
  return new Promise(async (resolve, reject) => {
    if (data.configurations && data.stands) {
      var configList = [];
      var standsList = [];
      var pavilionList = [];

      //Verifica se possui stands
      data.stands.forEach((item) => {
        if (item.active === true) {
          //Verifica se o stand já existe na lista geral
          var hasStand = standsList.find((st) => {
            if (st.id === item.id) {
              return true;
            }
            return false;
          });

          if (!hasStand) {
            const stand = createStandModel(
              item.id,
              item.name,
              item.order,
              item.pavillion
            );
            standsList.push(stand);

            var coordinates = [];
            if (item.coordinates && item.coordinates !== "") {
              var coordStr = item.coordinates.replace(" ", "");
              coordinates = coordStr.split(",");
              for (var i = 0; i < coordinates.length; i++) {
                coordinates[i] = +coordinates[i];
              }
            }

            //O stand tem que se tornar um elemento da página stands
            var config = createConfigModel(
              item.id,
              1,
              2,
              6,
              "STAND-" +
              (item.order ? item.order.toString() : item.id.toString()),
              "stand",
              item.tooltip,
              item.eventId,
              coordinates,
              "",
              item.id,
              item.pavillion,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              item.levels ? item.levels : null,
            );
            configList.push(config);

            //Monta lista de pavilhões
            //Verifica se o pavilhão já existe na lista
            var haspavilion = pavilionList.find((st) => {
              if (st.id === config.pavilion) {
                return true;
              }
              return false;
            });

            if (!haspavilion) {
              const pav = {
                id: config.pavilion,
              };
              pavilionList.push(pav);
            }
          }
        }
      });

      data.configurations.forEach((object) => {
        const item = object.configuration;
        if (item.active === true) {
          //Pega o standId
          var standId;
          if (item.stand) standId = item.stand.id;

          var coordinates2 = [];
          if (item.coordinates && item.coordinates !== "") {
            var coordStr2 = item.coordinates.replace(" ", "");
            coordinates2 = coordStr2.split(",");
            for (var i2 = 0; i2 < coordinates2.length; i2++) {
              coordinates2[i2] = +coordinates2[i2];
            }
          }

          var config2 = createConfigModel(
            item.id,
            item.templateID,
            item.screenID,
            item.typeID,
            item.codeScreen,
            item.url,
            item.tooltip,
            item.eventID,
            coordinates2,
            "",
            standId,
            item.pavilion ? parseInt(item.pavilion) : 0,
            null,
            item.password,
            object.configXGroup,
            item.hasFullScreen,
            item.initialDate,
            item.endDate,
            item.description ? item.description : null,
            item.html ? item.html : null,
            item.levels ? item.levels : null,
          );
          configList.push(config2);

          //Monta lista de pavilhões
          //Verifica se o pavilhão já existe na lista
          var haspavilion = pavilionList.find((st) => {
            if (st.id === config2.pavilion) {
              return true;
            }
            return false;
          });

          if (!haspavilion) {
            const pav = {
              id: config2.pavilion,
            };
            pavilionList.push(pav);
          }
        }
      });

      const finalList = await getDefaultConfig(configList);

      await store.dispatch(resetStands());
      await store.dispatch(resetPavilions());

      await store.dispatch(mergeConfig(finalList));
      await store.dispatch(setStands(standsList));
      await store.dispatch(setPavilions(pavilionList));

      resolve({ success: true, message: "Ok" });
    } else {
      reject({ success: false, message: "Configurações não localizadas" });
    }
  });
}

export async function getConfigListByPage(page) {
  const url = page.split("&");
  const path = url[0].split("/");
  const pagesList = getPagesList();
  var pageConf = pagesList.find((item) => {
    return item.path === path[2];
  });

  const _date = await getDate();
  const configList = await store.getState().config;
  const userGroup = await store.getState().user.groupId;
  const _userLevel = await store.getState().user.levelId;

  var configListPage = configList.filter((item) => {
    var _showConfig = true;
    if (item.hasRangeDate) {
      if (item.initialDate && item.initialDate !== "" && moment(_date).format() < moment(item.initialDate).format()) {
        _showConfig = false;
      }
      if (item.endingDate && item.endingDate !== "" && moment(_date).format() > moment(item.endingDate).format()) {
        _showConfig = false;
      }
    }
    if (item.levelList.length > 0) {
      var _hasLevel = false;
      item.levelList.forEach(level => {
        if (level.id === _userLevel) {
          _hasLevel = true;
        }
      });
      if (!_hasLevel) {
        _showConfig = false;
      }
    }
    if (_showConfig) {
      return (
        item.codeScreen === "NO-MOBILE" ||
        item.codeScreen === "NO-AVATAR-CREATE" ||
        (pageConf &&
          pageConf.id &&
          item.screenId === pageConf.id &&
          (item.groupId.length === 0 || item.groupId.includes(userGroup)))
      );
    }
  });

  var finalList = configListPage;
  if (pageConf && pageConf.id !== 2) {
    //Tratamento para remover possíveis configurações repetidas ou de grupos diferentes
    // Não filtrar caso seja a página de stands
    finalList = filterConfigList(configListPage);
  }

  if (finalList.length > 0) {
    return finalList;
  } else {
    return [];
  }
}

export async function getConfigListByStand(standId = 0) {
  const configList = await store.getState().config;

  const userGroup = await store.getState().user.groupId;
  const _date = await getDate();
  const _userLevel = await store.getState().user.levelId;

  var configListPage = configList.filter((item) => {
    var _showConfig = true;
    if (item.hasRangeDate) {
      if (item.initialDate && item.initialDate !== "" && moment(_date).format() < moment(item.initialDate).format()) {
        _showConfig = false;
      }
      if (item.endingDate && item.endingDate !== "" && moment(_date).format() > moment(item.endingDate).format()) {
        _showConfig = false;
      }
    }
    if (item.levelList.length > 0) {
      var _hasLevel = false;
      item.levelList.forEach(level => {
        if (level.id === _userLevel) {
          _hasLevel = true;
        }
      });
      if (!_hasLevel) {
        _showConfig = false;
      }
    }
    if (_showConfig) {
      return (
        item.codeScreen === "NO-MOBILE" ||
        (item.standId === standId &&
          item.screenId === 7 &&
          (item.groupId.length === 0 || item.groupId.includes(userGroup)))
      );
    }
  });

  //Tratamento para remover possíveis configurações repetidas ou de grupos diferentes
  const finalList = filterConfigList(configListPage);

  if (finalList.length > 0) {
    return finalList;
  } else {
    return [];
  }
}

function filterConfigList(configListPage) {
  const codeConfigList = [];
  configListPage.forEach((item) => {
    //Verifica se o codigo da config está repetido
    var hasConfig = codeConfigList.find((conf) => {
      if (conf && conf.codeScreen === item.codeScreen) {
        return true;
      }
      return false;
    });
    if (!hasConfig) {
      codeConfigList.push(item.codeScreen);
    }
  });

  //Tratamento para remover possíveis configurações repetidas ou de grupos diferentes
  const finalList = [];
  codeConfigList.forEach((conf) => {
    var configs = configListPage.filter((item) => {
      return item.codeScreen === conf;
    });

    if (configs) {
      if (configs.length === 1) {
        finalList.push(configs[0]);
      } else {
        //Filtra as configurações, primeiro por grupo id, depois por id de objeto
        //Será considerado primeiro a configuração do grupo do usuário, depois o id mais recente
        configs = configs.sort((a, b) =>
          a.groupId < b.groupId
            ? 1
            : a.groupId === b.groupId
              ? a.id < b.id
                ? 1
                : -1
              : -1
        );

        var hasConfig = finalList.find((conf) => {
          if (conf && conf.codeScreen === configs[0]?.codeScreen) {
            return true;
          }
          return false;
        });
        if (!hasConfig) {
          finalList.push(configs[0]);
        }
      }
    }
  });

  if (finalList.length > 0) {
    return finalList;
  } else {
    return [];
  }
}

export function getToolTip(configList = [], element) {
  const config = configList.find((item) => {
    return item.codeScreen === element;
  });
  return config && config.tooltip ? config.tooltip : "";
}

export function getCoordinateList(defaultCoord, configList = []) {
  const noMap = configList.find((item) => {
    if (item.codeScreen === "NO-MAP") {
      return true;
    }
    return false;
  });

  return new Promise((resolve, reject) => {
    const coordinates = [];

    if (noMap) {
      configList.forEach((item) => {
        var shape = item.coordinates.length === 4 ? "rect" : "poly";
        var coord = item.coordinates;
        var tooltip = item.tooltip;

        var conf = createCoordinateModel(
          item.codeScreen,
          shape,
          coord,
          tooltip
        );
        coordinates.push(conf);
      });
    } else {
      defaultCoord.areas.forEach((element) => {
        var item = configList.find((item) => {
          return item.codeScreen === element.name;
        });

        if (item) {
          var shape =
            item.coordinates.length > 0
              ? item.coordinates.length === 4
                ? "rect"
                : "poly"
              : element.shape;
          var coord =
            item.coordinates.length > 0 ? item.coordinates : element.coords;
          var tooltip = item.tooltip;

          var conf = createCoordinateModel(
            item.codeScreen,
            shape,
            coord,
            tooltip
          );
          coordinates.push(conf);
        } else {
          coordinates.push(element);
        }
      });
    }

    const ret = { name: "my-map", areas: coordinates };
    resolve(ret);
  });
}

export function getPagesList() {
  const pages = [
    {
      id: 1,
      page: "EventHallPage",
      path: "eventhall",
      string: "Hall",
    },
    {
      id: 2,
      page: "StandsPage",
      path: "stands",
      string: getDic("exposicao"),
    },
    {
      id: 3,
      page: "SchedulesPage",
      path: "schedules",
      string: getDic("sessoes"),
    },
    {
      id: 4,
      page: "LoginPage",
      path: "",
      string: "Login",
    },
    {
      id: 5,
      page: "BadgePage",
      path: "badge",
      string: getDic("cracha"),
    },
    {
      id: 6,
      page: "DeskHallPage",
      path: "deskhall",
      string: `Hall ${getDic("balcao")}`,
    },
    {
      id: 7,
      page: "StandPage",
      path: "stand",
      string: "Stand",
    },
    {
      id: 8,
      page: "GeneralPage",
      path: "general",
      string: `${getDic("generica")} 01`,
    },
    {
      id: 9,
      page: "RegistrationPage",
      path: "registration",
      string: getDic("cadastro"),
    },
    {
      id: 10,
      page: "General2Page",
      path: "general2",
      string: `${getDic("generica")} 02`,
    },
    {
      id: 11,
      page: "General3Page",
      path: "general3",
      string: `${getDic("generica")} 03`,
    },
    {
      id: 12,
      page: "General4Page",
      path: "general4",
      string: `${getDic("generica")} 04`,
    },
    {
      id: 13,
      page: "General5Page",
      path: "general5",
      string: `${getDic("generica")} 05`,
    },
    {
      id: 14,
      page: "General6Page",
      path: "general6",
      string: `${getDic("generica")} 06`,
    },
    {
      id: 15,
      page: "General7Page",
      path: "general7",
      string: `${getDic("generica")} 07`,
    },
    {
      id: 16,
      page: "General8Page",
      path: "general8",
      string: `${getDic("generica")} 08`,
    },
    {
      id: 17,
      page: "General9Page",
      path: "general9",
      string: `${getDic("generica")} 09`,
    },
    {
      id: 18,
      page: "General10Page",
      path: "general10",
      string: `${getDic("generica")} 10`,
    },
    {
      id: 19,
      page: "UploadPage",
      path: "upload",
      string: "Upload",
    },
    {
      id: 20,
      page: "ProfilePage",
      path: "profile",
      string: `${getDic("perfil")}`,
    },
    {
      id: 21,
      page: "General11Page",
      path: "general11",
      string: `${getDic("generica")} 11`,
    },
    {
      id: 22,
      page: "General12Page",
      path: "general12",
      string: `${getDic("generica")} 12`,
    },
    {
      id: 23,
      page: "General13Page",
      path: "general13",
      string: `${getDic("generica")} 13`,
    },
    {
      id: 24,
      page: "General14Page",
      path: "general14",
      string: `${getDic("generica")} 14`,
    },
    {
      id: 25,
      page: "General15Page",
      path: "general15",
      string: `${getDic("generica")} 15`,
    },
    {
      id: 26,
      page: "General16Page",
      path: "general16",
      string: `${getDic("generica")} 16`,
    },
    {
      id: 27,
      page: "General17Page",
      path: "general17",
      string: `${getDic("generica")} 17`,
    },
    {
      id: 28,
      page: "General18Page",
      path: "general18",
      string: `${getDic("generica")} 18`,
    },
    {
      id: 29,
      page: "General19Page",
      path: "general19",
      string: `${getDic("generica")} 19`,
    },
    {
      id: 30,
      page: "General20Page",
      path: "general20",
      string: `${getDic("generica")} 20`,
    },
    {
      id: 31,
      page: "RankingPage",
      path: "ranking",
      string: "Ranking",
    },
    {
      id: 32,
      page: "InteegraflixPage",
      path: "inteegraflix",
      string: "Inteegraflix",
    },
    {
      id: 33,
      page: "Transmission",
      path: "transmission",
      string: `${getDic("transmissao")} 01`,
    },
    {
      id: 34,
      page: "Transmission2",
      path: "transmission2",
      string: `${getDic("transmissao")} 02`,
    },
    {
      id: 35,
      page: "Transmission3",
      path: "transmission3",
      string: `${getDic("transmissao")} 03`,
    },
    {
      id: 36,
      page: "Transmission4",
      path: "transmission4",
      string: `${getDic("transmissao")} 04`,
    },
    {
      id: 37,
      page: "Transmission5",
      path: "transmission5",
      string: `${getDic("transmissao")} 05`,
    },
    {
      id: 38,
      page: "Transmission6",
      path: "transmission6",
      string: `${getDic("transmissao")} 06`,
    },
    {
      id: 39,
      page: "Transmission7",
      path: "transmission7",
      string: `${getDic("transmissao")} 07`,
    },
    {
      id: 40,
      page: "Transmission8",
      path: "transmission8",
      string: `${getDic("transmissao")} 08`,
    },
    {
      id: 41,
      page: "Transmission9",
      path: "transmission9",
      string: `${getDic("transmissao")} 09`,
    },
    {
      id: 42,
      page: "Transmission10",
      path: "transmission10",
      string: `${getDic("transmissao")} 10`,
    },
    {
      id: 43,
      page: "CreateAvatar",
      path: "createavatar",
      string: "Avatar",
    },
    {
      id: 44,
      page: "Transmission11",
      path: "transmission11",
      string: `${getDic("transmissao")} 11`,
    },
    {
      id: 45,
      page: "Transmission12",
      path: "transmission12",
      string: `${getDic("transmissao")} 12`,
    },
    {
      id: 46,
      page: "Transmission13",
      path: "transmission13",
      string: `${getDic("transmissao")} 13`,
    },
    {
      id: 47,
      page: "Transmission14",
      path: "transmission14",
      string: `${getDic("transmissao")} 14`,
    },
    {
      id: 48,
      page: "Transmission15",
      path: "transmission15",
      string: `${getDic("transmissao")} 15`,
    },
    {
      id: 49,
      page: "Transmission16",
      path: "transmission16",
      string: `${getDic("transmissao")} 16`,
    },
    {
      id: 50,
      page: "Transmission17",
      path: "transmission17",
      string: `${getDic("transmissao")} 17`,
    },
    {
      id: 51,
      page: "Transmission18",
      path: "transmission18",
      string: `${getDic("transmissao")} 18`,
    },
    {
      id: 52,
      page: "Transmission19",
      path: "transmission19",
      string: `${getDic("transmissao")} 19`,
    },
    {
      id: 53,
      page: "Transmission20",
      path: "transmission20",
      string: `${getDic("transmissao")} 20`,
    },
    {
      id: 54,
      page: "Transmission21",
      path: "transmission21",
      string: `${getDic("transmissao")} 21`,
    },
    {
      id: 55,
      page: "Transmission22",
      path: "transmission22",
      string: `${getDic("transmissao")} 22`,
    },
    {
      id: 56,
      page: "Transmission23",
      path: "transmission23",
      string: `${getDic("transmissao")} 23`,
    },
    {
      id: 57,
      page: "Transmission24",
      path: "transmission24",
      string: `${getDic("transmissao")} 24`,
    },
    {
      id: 58,
      page: "Transmission25",
      path: "transmission25",
      string: `${getDic("transmissao")} 25`,
    },
    {
      id: 59,
      page: "Transmission26",
      path: "transmission26",
      string: `${getDic("transmissao")} 26`,
    },
    {
      id: 60,
      page: "Transmission27",
      path: "transmission27",
      string: `${getDic("transmissao")} 27`,
    },
    {
      id: 61,
      page: "Transmission28",
      path: "transmission28",
      string: `${getDic("transmissao")} 28`,
    },
    {
      id: 62,
      page: "Transmission29",
      path: "transmission29",
      string: `${getDic("transmissao")} 29`,
    },
    {
      id: 63,
      page: "Transmission30",
      path: "transmission30",
      string: `${getDic("transmissao")} 30`,
    },
    {
      id: 64,
      page: "Transmission31",
      path: "transmission31",
      string: `${getDic("transmissao")} 31`,
    },
    {
      id: 65,
      page: "Transmission32",
      path: "transmission32",
      string: `${getDic("transmissao")} 32`,
    },
    {
      id: 66,
      page: "Transmission33",
      path: "transmission33",
      string: `${getDic("transmissao")} 33`,
    },
    {
      id: 67,
      page: "Transmission34",
      path: "transmission34",
      string: `${getDic("transmissao")} 34`,
    },
    {
      id: 68,
      page: "Transmission35",
      path: "transmission35",
      string: `${getDic("transmissao")} 35`,
    },
    {
      id: 69,
      page: "Transmission36",
      path: "transmission36",
      string: `${getDic("transmissao")} 36`,
    },
    {
      id: 70,
      page: "Transmission37",
      path: "transmission37",
      string: `${getDic("transmissao")} 37`,
    },
    {
      id: 71,
      page: "Transmission38",
      path: "transmission38",
      string: `${getDic("transmissao")} 38`,
    },
    {
      id: 72,
      page: "Transmission39",
      path: "transmission39",
      string: `${getDic("transmissao")} 39`,
    },
    {
      id: 73,
      page: "Transmission40",
      path: "transmission40",
      string: `${getDic("transmissao")} 40`,
    },
    {
      id: 74,
      page: "Transmission41",
      path: "transmission41",
      string: `${getDic("transmissao")} 41`,
    },
    {
      id: 75,
      page: "Transmission42",
      path: "transmission42",
      string: `${getDic("transmissao")} 42`,
    },
    {
      id: 76,
      page: "Transmission43",
      path: "transmission43",
      string: `${getDic("transmissao")} 43`,
    },
    {
      id: 77,
      page: "Transmission44",
      path: "transmission44",
      string: `${getDic("transmissao")} 44`,
    },
    {
      id: 78,
      page: "Transmission45",
      path: "transmission45",
      string: `${getDic("transmissao")} 45`,
    },
    {
      id: 79,
      page: "Transmission46",
      path: "transmission46",
      string: `${getDic("transmissao")} 46`,
    },
    {
      id: 80,
      page: "Transmission47",
      path: "transmission47",
      string: `${getDic("transmissao")} 47`,
    },
    {
      id: 81,
      page: "Transmission48",
      path: "transmission48",
      string: `${getDic("transmissao")} 48`,
    },
    {
      id: 82,
      page: "Transmission49",
      path: "transmission49",
      string: `${getDic("transmissao")} 49`,
    },
    {
      id: 83,
      page: "Transmission50",
      path: "transmission50",
      string: `${getDic("transmissao")} 50`,
    },
    {
      id: 84,
      page: "Transmission51",
      path: "transmission51",
      string: `${getDic("transmissao")} 51`,
    },
    {
      id: 85,
      page: "Transmission52",
      path: "transmission52",
      string: `${getDic("transmissao")} 52`,
    },
    {
      id: 86,
      page: "Transmission53",
      path: "transmission53",
      string: `${getDic("transmissao")} 53`,
    },
    {
      id: 87,
      page: "Transmission54",
      path: "transmission54",
      string: `${getDic("transmissao")} 54`,
    },
    {
      id: 88,
      page: "Transmission55",
      path: "transmission55",
      string: `${getDic("transmissao")} 55`,
    },
    {
      id: 89,
      page: "Transmission56",
      path: "transmission56",
      string: `${getDic("transmissao")} 56`,
    },
    {
      id: 90,
      page: "Transmission57",
      path: "transmission57",
      string: `${getDic("transmissao")} 57`,
    },
    {
      id: 91,
      page: "Transmission58",
      path: "transmission58",
      string: `${getDic("transmissao")} 58`,
    },
    {
      id: 92,
      page: "Transmission59",
      path: "transmission59",
      string: `${getDic("transmissao")} 59`,
    },
    {
      id: 93,
      page: "Transmission60",
      path: "transmission60",
      string: `${getDic("transmissao")} 60`,
    },
    {
      id: 94,
      page: "Transmission61",
      path: "transmission61",
      string: `${getDic("transmissao")} 61`,
    },
    {
      id: 95,
      page: "Transmission62",
      path: "transmission62",
      string: `${getDic("transmissao")} 62`,
    },
    {
      id: 96,
      page: "Transmission63",
      path: "transmission63",
      string: `${getDic("transmissao")} 63`,
    },
    {
      id: 97,
      page: "Transmission64",
      path: "transmission64",
      string: `${getDic("transmissao")} 64`,
    },
    {
      id: 98,
      page: "Transmission65",
      path: "transmission65",
      string: `${getDic("transmissao")} 65`,
    },
    {
      id: 99,
      page: "Transmission66",
      path: "transmission66",
      string: `${getDic("transmissao")} 66`,
    },
    {
      id: 100,
      page: "Transmission67",
      path: "transmission67",
      string: `${getDic("transmissao")} 67`,
    },
    {
      id: 101,
      page: "Transmission68",
      path: "transmission68",
      string: `${getDic("transmissao")} 68`,
    },
    {
      id: 102,
      page: "Transmission69",
      path: "transmission69",
      string: `${getDic("transmissao")} 69`,
    },
    {
      id: 103,
      page: "Transmission70",
      path: "transmission70",
      string: `${getDic("transmissao")} 70`,
    },
    {
      id: 104,
      page: "Transmission71",
      path: "transmission71",
      string: `${getDic("transmissao")} 71`,
    },
    {
      id: 105,
      page: "Transmission72",
      path: "transmission72",
      string: `${getDic("transmissao")} 72`,
    },
    {
      id: 106,
      page: "Transmission73",
      path: "transmission73",
      string: `${getDic("transmissao")} 73`,
    },
    {
      id: 107,
      page: "Transmission74",
      path: "transmission74",
      string: `${getDic("transmissao")} 74`,
    },
    {
      id: 108,
      page: "Transmission75",
      path: "transmission75",
      string: `${getDic("transmissao")} 75`,
    },
    {
      id: 109,
      page: "Transmission76",
      path: "transmission76",
      string: `${getDic("transmissao")} 76`,
    },
    {
      id: 110,
      page: "Transmission77",
      path: "transmission77",
      string: `${getDic("transmissao")} 77`,
    },
    {
      id: 111,
      page: "Transmission78",
      path: "transmission78",
      string: `${getDic("transmissao")} 78`,
    },
    {
      id: 112,
      page: "Transmission79",
      path: "transmission79",
      string: `${getDic("transmissao")} 79`,
    },
    {
      id: 113,
      page: "Transmission80",
      path: "transmission80",
      string: `${getDic("transmissao")} 80`,
    },
    {
      id: 114,
      page: "Transmission81",
      path: "transmission81",
      string: `${getDic("transmissao")} 81`,
    },
    {
      id: 115,
      page: "Transmission82",
      path: "transmission82",
      string: `${getDic("transmissao")} 82`,
    },
    {
      id: 116,
      page: "Transmission83",
      path: "transmission83",
      string: `${getDic("transmissao")} 83`,
    },
    {
      id: 117,
      page: "Transmission84",
      path: "transmission84",
      string: `${getDic("transmissao")} 84`,
    },
    {
      id: 118,
      page: "Transmission85",
      path: "transmission85",
      string: `${getDic("transmissao")} 85`,
    },
    {
      id: 119,
      page: "Transmission86",
      path: "transmission86",
      string: `${getDic("transmissao")} 86`,
    },
    {
      id: 120,
      page: "Transmission87",
      path: "transmission87",
      string: `${getDic("transmissao")} 87`,
    },
    {
      id: 121,
      page: "Transmission88",
      path: "transmission88",
      string: `${getDic("transmissao")} 88`,
    },
    {
      id: 122,
      page: "Transmission89",
      path: "transmission89",
      string: `${getDic("transmissao")} 89`,
    },
    {
      id: 123,
      page: "Transmission91",
      path: "transmission91",
      string: `${getDic("transmissao")} 91`,
    },
    {
      id: 124,
      page: "Transmission92",
      path: "transmission92",
      string: `${getDic("transmissao")} 92`,
    },
    {
      id: 125,
      page: "Transmission93",
      path: "transmission93",
      string: `${getDic("transmissao")} 93`,
    },
    {
      id: 126,
      page: "Transmission94",
      path: "transmission94",
      string: `${getDic("transmissao")} 94`,
    },
    {
      id: 127,
      page: "Transmission95",
      path: "transmission95",
      string: `${getDic("transmissao")} 95`,
    },
    {
      id: 128,
      page: "Transmission96",
      path: "transmission96",
      string: `${getDic("transmissao")} 96`,
    },
    {
      id: 129,
      page: "Transmission97",
      path: "transmission97",
      string: `${getDic("transmissao")} 97`,
    },
    {
      id: 130,
      page: "Transmission98",
      path: "transmission98",
      string: `${getDic("transmissao")} 98`,
    },
    {
      id: 131,
      page: "Transmission99",
      path: "transmission99",
      string: `${getDic("transmissao")} 99`,
    },
    {
      id: 132,
      page: "Transmission100",
      path: "transmission100",
      string: `${getDic("transmissao")} 100`,
    },
    {
      id: 133,
      page: "CalendarPage",
      path: "calendar",
      string: `${getDic("calendario")}`,
    },
    {
      id: 134,
      page: "Transmission90",
      path: "transmission90",
      string: `${getDic("transmissao")} 90`,
    },
    {
      id: 136,
      page: "General21Page",
      path: "general21",
      string: `${getDic("generica")} 21`,
    },
    {
      id: 137,
      page: "General22Page",
      path: "general22",
      string: `${getDic("generica")} 22`,
    },
    {
      id: 138,
      page: "General23Page",
      path: "general23",
      string: `${getDic("generica")} 23`,
    },
    {
      id: 139,
      page: "General24Page",
      path: "general24",
      string: `${getDic("generica")} 24`,
    },
    {
      id: 140,
      page: "General25Page",
      path: "general25",
      string: `${getDic("generica")} 25`,
    },
    {
      id: 141,
      page: "General26Page",
      path: "general26",
      string: `${getDic("generica")} 26`,
    },
    {
      id: 142,
      page: "General27Page",
      path: "general27",
      string: `${getDic("generica")} 27`,
    },
    {
      id: 143,
      page: "General28Page",
      path: "general28",
      string: `${getDic("generica")} 28`,
    },
    {
      id: 144,
      page: "General29Page",
      path: "general29",
      string: `${getDic("generica")} 29`,
    },
    {
      id: 145,
      page: "General30Page",
      path: "general30",
      string: `${getDic("generica")} 30`,
    },
    {
      id: 146,
      page: "General31Page",
      path: "general31",
      string: `${getDic("generica")} 31`,
    },
    {
      id: 147,
      page: "General32Page",
      path: "general32",
      string: `${getDic("generica")} 32`,
    },
    {
      id: 148,
      page: "General33Page",
      path: "general33",
      string: `${getDic("generica")} 33`,
    },
    {
      id: 149,
      page: "General34Page",
      path: "general34",
      string: `${getDic("generica")} 34`,
    },
    {
      id: 150,
      page: "General35Page",
      path: "general35",
      string: `${getDic("generica")} 35`,
    },
    {
      id: 151,
      page: "General36Page",
      path: "general36",
      string: `${getDic("generica")} 36`,
    },
    {
      id: 152,
      page: "General37Page",
      path: "general37",
      string: `${getDic("generica")} 37`,
    },
    {
      id: 153,
      page: "General38Page",
      path: "general38",
      string: `${getDic("generica")} 38`,
    },
    {
      id: 154,
      page: "General39Page",
      path: "general39",
      string: `${getDic("generica")} 39`,
    },
    {
      id: 155,
      page: "General40Page",
      path: "general40",
      string: `${getDic("generica")} 40`,
    },
    {
      id: 156,
      page: "General41Page",
      path: "general41",
      string: `${getDic("generica")} 41`,
    },
    {
      id: 157,
      page: "General42Page",
      path: "general42",
      string: `${getDic("generica")} 42`,
    },
    {
      id: 158,
      page: "General43Page",
      path: "general43",
      string: `${getDic("generica")} 43`,
    },
    {
      id: 159,
      page: "General44Page",
      path: "general44",
      string: `${getDic("generica")} 44`,
    },
    {
      id: 160,
      page: "General45Page",
      path: "general45",
      string: `${getDic("generica")} 45`,
    },
    {
      id: 161,
      page: "General46Page",
      path: "general46",
      string: `${getDic("generica")} 46`,
    },
    {
      id: 162,
      page: "General47Page",
      path: "general47",
      string: `${getDic("generica")} 47`,
    },
    {
      id: 163,
      page: "General48Page",
      path: "general48",
      string: `${getDic("generica")} 48`,
    },
    {
      id: 164,
      page: "General49Page",
      path: "general49",
      string: `${getDic("generica")} 49`,
    },
    {
      id: 165,
      page: "General50Page",
      path: "general50",
      string: `${getDic("generica")} 50`,
    },
    {
      id: 166,
      page: "General51Page",
      path: "general51",
      string: `${getDic("generica")} 51`,
    },
    {
      id: 167,
      page: "General52Page",
      path: "general52",
      string: `${getDic("generica")} 52`,
    },
    {
      id: 168,
      page: "General53Page",
      path: "general53",
      string: `${getDic("generica")} 53`,
    },
    {
      id: 169,
      page: "General54Page",
      path: "general54",
      string: `${getDic("generica")} 54`,
    },
    {
      id: 170,
      page: "General55Page",
      path: "general55",
      string: `${getDic("generica")} 55`,
    },
    {
      id: 171,
      page: "General56Page",
      path: "general56",
      string: `${getDic("generica")} 56`,
    },
    {
      id: 172,
      page: "General57Page",
      path: "general57",
      string: `${getDic("generica")} 57`,
    },
    {
      id: 173,
      page: "General58Page",
      path: "general58",
      string: `${getDic("generica")} 58`,
    },
    {
      id: 174,
      page: "General59Page",
      path: "general59",
      string: `${getDic("generica")} 59`,
    },
    {
      id: 175,
      page: "General60Page",
      path: "general60",
      string: `${getDic("generica")} 60`,
    },
    {
      id: 176,
      page: "General61Page",
      path: "general61",
      string: `${getDic("generica")} 61`,
    },
    {
      id: 177,
      page: "General62Page",
      path: "general62",
      string: `${getDic("generica")} 62`,
    },
    {
      id: 178,
      page: "General63Page",
      path: "general63",
      string: `${getDic("generica")} 63`,
    },
    {
      id: 179,
      page: "General64Page",
      path: "general64",
      string: `${getDic("generica")} 64`,
    },
    {
      id: 180,
      page: "General65Page",
      path: "general65",
      string: `${getDic("generica")} 65`,
    },
    {
      id: 181,
      page: "General66Page",
      path: "general66",
      string: `${getDic("generica")} 66`,
    },
    {
      id: 182,
      page: "General67Page",
      path: "general67",
      string: `${getDic("generica")} 67`,
    },
    {
      id: 183,
      page: "General68Page",
      path: "general68",
      string: `${getDic("generica")} 68`,
    },
    {
      id: 184,
      page: "General69Page",
      path: "general69",
      string: `${getDic("generica")} 69`,
    },
    {
      id: 185,
      page: "General70Page",
      path: "general70",
      string: `${getDic("generica")} 70`,
    },
    {
      id: 186,
      page: "General71Page",
      path: "general71",
      string: `${getDic("generica")} 71`,
    },
    {
      id: 187,
      page: "General72Page",
      path: "general72",
      string: `${getDic("generica")} 72`,
    },
    {
      id: 188,
      page: "General73Page",
      path: "general73",
      string: `${getDic("generica")} 73`,
    },
    {
      id: 189,
      page: "General74Page",
      path: "general74",
      string: `${getDic("generica")} 74`,
    },
    {
      id: 190,
      page: "General75Page",
      path: "general75",
      string: `${getDic("generica")} 75`,
    },
    {
      id: 191,
      page: "General76Page",
      path: "general76",
      string: `${getDic("generica")} 76`,
    },
    {
      id: 192,
      page: "General77Page",
      path: "general77",
      string: `${getDic("generica")} 77`,
    },
    {
      id: 193,
      page: "General78Page",
      path: "general78",
      string: `${getDic("generica")} 78`,
    },
    {
      id: 194,
      page: "General79Page",
      path: "general79",
      string: `${getDic("generica")} 79`,
    },
    {
      id: 195,
      page: "General80Page",
      path: "general80",
      string: `${getDic("generica")} 80`,
    },
    {
      id: 196,
      page: "General81Page",
      path: "general81",
      string: `${getDic("generica")} 81`,
    },
    {
      id: 197,
      page: "General82Page",
      path: "general82",
      string: `${getDic("generica")} 82`,
    },
    {
      id: 198,
      page: "General83Page",
      path: "general83",
      string: `${getDic("generica")} 83`,
    },
    {
      id: 199,
      page: "General84Page",
      path: "general84",
      string: `${getDic("generica")} 84`,
    },
    {
      id: 200,
      page: "General85Page",
      path: "general85",
      string: `${getDic("generica")} 85`,
    },
    {
      id: 201,
      page: "General86Page",
      path: "general86",
      string: `${getDic("generica")} 86`,
    },
    {
      id: 202,
      page: "General87Page",
      path: "general87",
      string: `${getDic("generica")} 87`,
    },
    {
      id: 203,
      page: "General88Page",
      path: "general88",
      string: `${getDic("generica")} 88`,
    },
    {
      id: 204,
      page: "General89Page",
      path: "general89",
      string: `${getDic("generica")} 89`,
    },
    {
      id: 205,
      page: "General90Page",
      path: "general90",
      string: `${getDic("generica")} 90`,
    },
    {
      id: 206,
      page: "General91Page",
      path: "general91",
      string: `${getDic("generica")} 91`,
    },
    {
      id: 207,
      page: "General92Page",
      path: "general92",
      string: `${getDic("generica")} 92`,
    },
    {
      id: 208,
      page: "General93Page",
      path: "general93",
      string: `${getDic("generica")} 93`,
    },
    {
      id: 209,
      page: "General94Page",
      path: "general94",
      string: `${getDic("generica")} 94`,
    },
    {
      id: 210,
      page: "General95Page",
      path: "general95",
      string: `${getDic("generica")} 95`,
    },
    {
      id: 211,
      page: "General96Page",
      path: "general96",
      string: `${getDic("generica")} 96`,
    },
    {
      id: 212,
      page: "General97Page",
      path: "general97",
      string: `${getDic("generica")} 97`,
    },
    {
      id: 213,
      page: "General98Page",
      path: "general98",
      string: `${getDic("generica")} 98`,
    },
    {
      id: 214,
      page: "General99Page",
      path: "general99",
      string: `${getDic("generica")} 99`,
    },
    {
      id: 215,
      page: "General100Page",
      path: "general100",
      string: `${getDic("generica")} 100`,
    },
    {
      id: 216,
      page: "SponsorsPage",
      path: "sponsors",
      string: `${getDic("patrocinadores")}`,
    },
    {
      id: 217,
      page: "RafflePresentationPage",
      path: "rafflepresentation",
      string: `${getDic("sorteio")}`,
    },
  ];
  return pages;
}

export function getModalTypeList(isScheduleModel = false) {
  const _modalTypes = []

  _modalTypes.push({
    id: 1,
    value: 1,
    type: "Video",
    text: getDic("video"),
  });
  if (!isScheduleModel) {
    _modalTypes.push({
      id: 2,
      value: 2,
      type: "Arquive",
      text: getDic("arquivo")
    });
  }
  _modalTypes.push({
    id: 3,
    value: 3,
    type: "Iframe",
    text: "Iframe",
  });
  if (!isScheduleModel) {
    _modalTypes.push({
      id: 4,
      value: 4,
      type: "Image",
      text: getDic("imagem"),
    });
  }
  _modalTypes.push({
    id: 6,
    value: 6,
    type: "Internal Link",
    text: `Link ${getDic("interno")}`,
  });
  _modalTypes.push({
    id: 7,
    value: 7,
    type: "Zoom Web",
    text: "Zoom Web",
  });
  _modalTypes.push({
    id: 8,
    value: 8,
    type: "External Link",
    text: `Link ${getDic("externo")}`,
  });
  if (!isScheduleModel) {
    _modalTypes.push({
      id: 9,
      value: 9,
      type: "Vimeo",
      text: "Vimeo",
    });
  }
  _modalTypes.push({
    id: 10,
    value: 10,
    type: "Iframe With Questions",
    text: `Iframe ${getDic("perguntas")}`,
  });
  _modalTypes.push({
    id: 11,
    value: 11,
    type: "Engage",
    text: "Engage",
  });
  _modalTypes.push({
    id: 12,
    value: 12,
    type: "Chat",
    text: "Chat",
  });
  _modalTypes.push({
    id: 13,
    value: 13,
    type: "Badge",
    text: getDic("cracha"),
  });
  _modalTypes.push({
    id: 14,
    value: 14,
    type: "Chess",
    text: getDic("xadrez"),
  });
  _modalTypes.push({
    id: 17,
    value: 17,
    type: "Internal Link Modal",
    text: `Link ${getDic("interno")} Modal`,
  });
  if (!isScheduleModel) {
    _modalTypes.push({
      id: 18,
      value: 18,
      type: "Certificate",
      text: getDic("certificado"),
    });
  }
  return _modalTypes;
}

export function convertScheduleMeetingTypeInModalType(meetingType) {
  switch (meetingType) {
    case 2:
      return 7;
    case 3:
      return 3;
    case 4:
      return 3;
    case 5:
      return 3;
    case 6:
      return 1;
    case 7:
      return 3;
    case 8:
      return 8;
    case 9:
      return 9;
    case 10:
      return 10;
    case 11:
      return 11;
    case 12:
      return 12;
    case 13:
      return 13;
    case 14:
      return 14;
    case 15:
      return 17;
    case 16:
      return 6;
    default:
      return 3;
  }
}

export function convertModalTypeInScheduleMeetingType(modalType) {
  switch (modalType) {
    case 3:
      return 7;
    case 1:
      return 6;
    case 6:
      return 16;
    case 7:
      return 2;
    case 8:
      return 8;
    case 9:
      return 9;
    case 10:
      return 10;
    case 11:
      return 11;
    case 12:
      return 12;
    case 13:
      return 13;
    case 14:
      return 14;
    case 17:
      return 15;
    default:
      return 7;
  }
}

async function getDefaultConfig(configList) {
  return new Promise((resolve, reject) => {
    var ret = configList;

    //Adiciona configs default EventHallPage
    var configListPage = configList.filter((item) => {
      return item.screenId === 1;
    });

    var noMap = configListPage.find((item) => {
      return item.codeScreen === "NO-MAP";
    });
    if (!noMap) {
      var conf4 = configListPage.find((item) => {
        return item.codeScreen === "ELEMENT-004";
      });
      if (!conf4) {
        var config4 = createConfigModel(
          4,
          1,
          1,
          6,
          "ELEMENT-004",
          "speakers",
          getDic("click-aqui-para") +
          " " +
          getDic("visualizar").toLowerCase() +
          " " +
          getDic("imprensa").toLowerCase(),
          0,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        );
        ret.push(config4);
      }

      var conf5 = configListPage.find((item) => {
        return item.codeScreen === "ELEMENT-005";
      });
      if (!conf5) {
        var config5 = createConfigModel(
          5,
          1,
          1,
          6,
          "ELEMENT-005",
          "schedules&0",
          getDic("click-aqui-para") +
          " " +
          getDic("acessar").toLowerCase() +
          " network",
          0,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        );
        ret.push(config5);
      }

      var conf6 = configListPage.find((item) => {
        return item.codeScreen === "ELEMENT-006";
      });
      if (!conf6) {
        var config6 = createConfigModel(
          6,
          1,
          1,
          6,
          "ELEMENT-006",
          "schedules&0",
          getDic("click-aqui-para") +
          " " +
          getDic("acessar").toLowerCase() +
          " " +
          getDic("sessoes").toLowerCase(),
          0,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        );
        ret.push(config6);
      }

      var conf7 = configListPage.find((item) => {
        return item.codeScreen === "ELEMENT-007";
      });
      if (!conf7) {
        var config7 = createConfigModel(
          7,
          1,
          1,
          6,
          "ELEMENT-007",
          "stands",
          getDic("click-aqui-para") +
          " " +
          getDic("acessar").toLowerCase() +
          " Stands",
          0,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        );
        ret.push(config7);
      }

      var conf8 = configListPage.find((item) => {
        return item.codeScreen === "ELEMENT-008";
      });
      if (!conf8) {
        var config8 = createConfigModel(
          8,
          1,
          1,
          6,
          "ELEMENT-008",
          "deskhall",
          getDic("click-aqui-para") +
          " " +
          getDic("acessar").toLowerCase() +
          " " +
          getDic("balcao-informacoes").toLowerCase(),
          0,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        );
        ret.push(config8);
      }
    }

    //Adciona o NO-MAP para a tela genérica
    var config11 = createConfigModel(
      11,
      1,
      8,
      3,
      "NO-MAP",
      "",
      "",
      0,
    );
    ret.push(config11);

    var config12 = createConfigModel(
      12,
      1,
      10,
      3,
      "NO-MAP",
      "",
      "",
      0,
    );
    ret.push(config12);

    var config13 = createConfigModel(
      13,
      1,
      11,
      3,
      "NO-MAP",
      "",
      "",
      0,
    );
    ret.push(config13);

    var config14 = createConfigModel(
      14,
      1,
      12,
      3,
      "NO-MAP",
      "",
      "",
      0,
    );
    ret.push(config14);

    var config15 = createConfigModel(
      15,
      1,
      13,
      3,
      "NO-MAP",
      "",
      "",
      0,
    );
    ret.push(config15);

    var config16 = createConfigModel(
      16,
      1,
      14,
      3,
      "NO-MAP",
      "",
      "",
      0,
    );
    ret.push(config16);

    var config17 = createConfigModel(
      17,
      1,
      15,
      3,
      "NO-MAP",
      "",
      "",
      0,
    );
    ret.push(config17);

    var config18 = createConfigModel(
      18,
      1,
      16,
      3,
      "NO-MAP",
      "",
      "",
      0,
    );
    ret.push(config18);

    var config19 = createConfigModel(
      19,
      1,
      17,
      3,
      "NO-MAP",
      "",
      "",
      0,
    );
    ret.push(config19);

    var config20 = createConfigModel(
      20,
      1,
      18,
      3,
      "NO-MAP",
      "",
      "",
      0,
    );
    ret.push(config20);

    for (let index = 21; index < 31; index++) {
      const _config = createConfigModel(
        index,
        1,
        index,
        3,
        "NO-MAP",
        "",
        "",
        0,
      );
      ret.push(_config);
    }

    for (let index = 136; index < 216; index++) {
      const _config = createConfigModel(
        index,
        1,
        index,
        3,
        "NO-MAP",
        "",
        "",
        0,
      );
      ret.push(_config);
    }

    resolve(ret);
  });
}
