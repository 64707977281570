import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import ScheduleIcon from '@material-ui/icons/Schedule';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import BusinessIcon from '@material-ui/icons/Business';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
import SyncIcon from '@material-ui/icons/Sync';
import Moderator from '@material-ui/icons/HowToReg';
import Reports from '@material-ui/icons/ListAlt';
import Upload from '@material-ui/icons/Publish';
import Files from '@material-ui/icons/AttachFile';
import { syncData } from '../services/syncDataService';
import Button from '@material-ui/core/Button';
import * as navigationHistoryService from '../services/navigationHistoryService';

import { Loading } from '../components';

class AppBarMenuButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openMenu: false,
            loadingMessage: "",
            openLoading: false,
        }
    }

    syncDataLocal() {
        syncData(this.props.event.id)
            .then(() => {
                this.setState({ openLoading: false });
                window.location.reload();
            });
    }

    render() {
        return (
            <div>
                {this.props.hasMenu ? (
                    <Button
                        color={"inherit"}
                        size={"medium"}
                        onClick={() => this.setState({ openMenu: true })}
                    >
                        MENU
                    </Button>
                ) : (null)}
                <Drawer
                    anchor="left"
                    open={this.state.openMenu}
                    onClose={() => this.setState({ openMenu: false })}
                >
                    <List>
                        {this.props.hasSync ? (
                            <ListItem button key="sync" onClick={() => {
                                this.setState({ openLoading: true, loadingMessage: getDic("sincronizando") });
                                this.syncDataLocal();
                            }}>
                                <ListItemIcon><SyncIcon /></ListItemIcon>
                                <ListItemText primary={getDic("sincronizar") + " " + getDic("dados")} />
                            </ListItem>
                        ) : (null)}
                        <ListItem button key="eventhall" onClick={() => navigationHistoryService.setHistory(this.props.history, '/' + this.props.event.id + '/eventhall')}>
                            <ListItemIcon><HomeIcon /></ListItemIcon>
                            <ListItemText primary={getDic("hall-evento")} />
                        </ListItem>
                        {this.props.hasSchedules ? (
                            <ListItem button key="schedules" onClick={() => navigationHistoryService.setHistory(this.props.history, '/' + this.props.event.id + '/schedules&0')}>
                                <ListItemIcon><ScheduleIcon /></ListItemIcon>
                                <ListItemText primary={getDic("sessoes")} />
                            </ListItem>
                        ) : (null)}
                        {this.props.hasSpeakers ? (
                            <ListItem button key="speakers" onClick={() => navigationHistoryService.setHistory(this.props.history, '/' + this.props.event.id + '/speakers')}>
                                <ListItemIcon><RecordVoiceOverIcon /></ListItemIcon>
                                <ListItemText primary={getDic("palestrantes")} />
                            </ListItem>
                        ) : (null)}
                        {this.props.hasStands ? (
                            <ListItem button key="stands" onClick={() => navigationHistoryService.setHistory(this.props.history, '/' + this.props.event.id + '/stands')}>
                                <ListItemIcon><BusinessIcon /></ListItemIcon>
                                <ListItemText primary={getDic("exposicao")} />
                            </ListItem>
                        ) : (null)}
                        {this.props.hasParticipant && this.props.isAdmin ? ( //Não liberar até terminar dev
                            <ListItem button key="guests" onClick={() => navigationHistoryService.setHistory(this.props.history, '/' + this.props.event.id + '/guests')}>
                                <ListItemIcon><PeopleIcon /></ListItemIcon>
                                <ListItemText primary={getDic("participantes")} />
                            </ListItem>
                        ) : (null)}
                        {this.props.isAdmin ? (
                            <ListItem button key="reports" onClick={() => navigationHistoryService.setHistory(this.props.history, '/' + this.props.event.id + '/reports')}>
                                <ListItemIcon><Reports /></ListItemIcon>
                                <ListItemText primary={getDic("relatorio")} />
                            </ListItem>
                        ) : (null)}
                        {this.props.isAdmin && this.props.isDeveloper ? (
                            <ListItem button key="moderator" onClick={() => navigationHistoryService.setHistory(this.props.history, '/' + this.props.event.id + '/moderator')}>
                                <ListItemIcon><Moderator /></ListItemIcon>
                                <ListItemText primary={getDic("moderador")} />
                            </ListItem>
                        ) : (null)}
                        {this.props.isAdmin && this.props.isDeveloper ? (
                            <ListItem button key="upload" onClick={() => {
                                navigationHistoryService.setHistory(this.props.history, `/${this.props.event.id}/upload/${this.props.user.id}&INTEEGRA&${this.props.event.contractId}`)
                            }}>
                                <ListItemIcon><Upload /></ListItemIcon>
                                <ListItemText primary="Upload" />
                            </ListItem>
                        ) : (null)}
                        {this.props.isAdmin ? (
                            <ListItem button key="files" onClick={() => navigationHistoryService.setHistory(this.props.history, '/' + this.props.event.id + '/files')}>
                                <ListItemIcon><Files /></ListItemIcon>
                                <ListItemText primary={getDic("arquivos")} />
                            </ListItem>
                        ) : (null)}
                    </List>
                </Drawer>

                <Loading open={this.state.openLoading} message={this.state.loadingMessage} />
            </div>
        );
    }
};

function mapStateToProps(state) {

    const noMenu = state.config.find(item => {
        return item.codeScreen === "NO-MENU";
    });
    const noSync = state.config.find(item => {
        return item.codeScreen === "NO-SYNC-DATA";
    });
    const noParticipant = state.config.find(item => {
        return item.codeScreen === "NO-PARTICIPANT";
    });

    return {
        event: state.event,
        user: state.user,
        isAdmin: state.user && state.user.isAdmin ? state.user.isAdmin : false,
        isDeveloper: state.user && state.user.isDeveloper ? state.user.isDeveloper : false,
        hasStands: state.stands.length > 0 ? true : false,
        hasSchedules: state.schedules.length > 0 ? true : false,
        hasMenu: noMenu ? false : true,
        hasSync: noSync ? false : true,
        hasParticipant: noParticipant ? false : true,
        hasSpeakers: state.speakers.length > 0 ? true : false,
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AppBarMenuButton)
