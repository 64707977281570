import { createFormControlModel } from '../models/formControlModel';
import { getDic } from '../assets/i18n/dictionary';

export function createRaffleModel(
  id = '',
  active = true,
  createDate = '',
  title = '',
  numberOfWinners = 0,
  onlyPage = '',
  onlyOnline = false,
  onlyNotAwarded = false,
  groupId = [],
  raffleRealized = false,
  raffleRealizedDate = '',
) {
  const _obj = {
    id,
    active,
    createDate,
    title,
    numberOfWinners,
    onlyPage,
    onlyOnline,
    onlyNotAwarded,
    groupId,
    raffleRealized,
    raffleRealizedDate,
  }

  return _obj;
}

export function createWinnerModel(
  id = '',
  eventId = 0,
  raffleId = '',
  guestId = 0,
  guestName = '',
  guestEmail = '',
  guestPhoto = '',
) {
  return {
    id,
    eventId,
    raffleId,
    guestId,
    guestName,
    guestEmail,
    guestPhoto,
  }
}

export function createBlocklistModel(
  id = 0,
  name = '',
  email = '',
) {
  return {
    id,
    name,
    email
  }
}

export function convertBlocklistObjectToForm(block) {
  const _form = [];

  var _index = 0;

  for (var key in block) {
    if (block.hasOwnProperty(key)) {

      var _hasForm = false;
      var _type = "text";
      var _options = null;
      var _required = true;
      var _name = "";
      var _value = block[key] ? block[key].toString() : null;

      switch (key.toString()) {
        case "id":
          _hasForm = true;
          _name = "id";
          _required = true;
          _value = parseInt(block[key]);
          break;
        case "name":
          _hasForm = true;
          _name = getDic("nome");
          _required = true;
          break;
        case "email":
          _hasForm = true;
          _name = getDic("email");
          _required = true;
          break;
        default:
          break;
      }

      if (_hasForm) {
        const _obj = createFormControlModel(
          _index,
          _name,
          _value,
          _type,
          null,
          null,
          _options, // ex => "[{\"Text\":\"Ingles \"},{\"Text\":\"Espanhol \"},{\"Text\":\"Portugues\"}]"
          _required,
          null,
          null,
          null,
          key
        );
        _form.push(_obj);
        _index++;
      }
    }
  }
  return _form;
}

export function convertFormToBlockObject(form) {
  var _block = createBlocklistModel();
  form.forEach(item => {
    var _value;

    switch (item.dbReference) {
      case "id":
        _value = parseInt(item.value);
        break;
      case "name":
        _value = parseInt(item.value);
        break;
      case "email":
        _value = parseInt(item.value);
        break;
      default:
        break;
    }
    _block[item.dbReference] = _value;
  });
  return _block;
}
