import React, { useContext } from "react";
import LoginPageContext from './LoginPageContext';
import { getDic } from "../../assets/i18n/dictionary";
import ShowZendesk from '../../services/zendeskService';
import Grid from '@material-ui/core/Grid';
import "../../scss/Login.scss";
import NameIcon from '@material-ui/icons/Person';
import CpfIcon from '@material-ui/icons/AssignmentInd';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import '../../scss/ModalAche.scss';

//Imports de components
import {
  Container,
  FormColumn,
  FormRow,
  TextBig,
  TextSmall,
  Card,
  InputFieldOutlined,
  Button,
  ActivityIndicator,
  SelectList,
  Alert,
  Dialog,
  Loading,
  TextMedium,
  Recaptcha,
  SSO,
  Checkbox,
  Link,
  IframePlayer,
  DefaultTerm,
  Modal,
} from "../../components";

const LoginPageVisual = (props) => {
  // Busca o state e os métodos e instancia em uma variável context
  const { state, functions } = useContext(LoginPageContext);

  const renderModalAche = () => {
    if (!state.openModalAche || !state.showModalAche) {
      return null;
    }

    return (
      <div className="modal-ache">
        <div className="container-ache">
          <div className="row-ache">
            <div style={{ width: 50, display: 'flex', alignSelf: 'center' }}>
              <img src="https://www.ache.com.br/wp-content/themes/ache/dist/img/alert.png" />
            </div>
            ATUALIZAÇÃO DA POLÍTICA DE PRIVACIDADE
          </div>
          <span class="close-ache" onClick={() => state.setOpenModalAche(false)}>X</span>
          <div className="row-ache">
            <div className="text-container-1-ache">
              O Aché Laboratórios Farmacêuticos S.A se preocupa com a sua privacidade e quer que você esteja familiarizado com a forma como coletamos, utilizamos e divulgamos suas informações.
            </div>
          </div>
          <FormRow align='center'>
            <div className="text-container-2-ache">
              <div className="row-ache" style={{ textAlign: 'left', justifyContent: 'flex-start' }}>
                COLETA DE INFORMAÇÕES:
              </div>
              <div className="row-ache" style={{ textAlign: 'left', justifyContent: 'flex-start' }}>
                Alguns dados pessoais podem ser solicitados para que você se beneficie de nossos serviços ou programas. Sempre que obrigatório por Lei, seu consentimento será solicitado. Você também poderá exercer seus direitos em relação a seus dados por meio do nosso Canal de Comunicação.
              </div>
            </div>
            <div className="text-container-2-ache">
              <div className="row-ache" style={{ textAlign: 'left', justifyContent: 'flex-start' }}>
                SEGURANÇA:
              </div>
              <div className="row-ache" style={{ textAlign: 'left', justifyContent: 'flex-start' }}>
                Empregamos os melhores esforços para respeitar e proteger seus dados pessoais contra perda, roubo, vazamento ou qualquer modalidade de uso indevido, bem como contra acesso não autorizado, divulgação, alteração e destruição.
              </div>
            </div>
            <div className="text-container-2-ache">
              <div className="row-ache" style={{ textAlign: 'left', justifyContent: 'flex-start' }}>
                CANAL DE COMUNICAÇÃO:
              </div>
              <div className="row-ache" style={{ textAlign: 'left', justifyContent: 'flex-start' }}>
                <p style={{ margin: 0 }}>Se tiver dúvidas, quiser exercer alguns de seus direitos como titular de dados ou entrar em contato com nosso encarregado de proteção dos dados, envie um e-mail para: <a style={{ color: 'white' }} href="mailto: encarregadoprivacidade@ache.com.br" target="_blank">encarregadoprivacidade@ache.com.br</a>.</p>
              </div>
            </div>
          </FormRow>
        </div>
      </div>
    )
  }

  const renderTerms = () => {
    if (state.terms) {
      if (state.isIframeTerms) {
        return <IframePlayer height="80vh" src={state.terms} />;
      } else {
        return (
          <div style={{ maxHeight: '500px' }} dangerouslySetInnerHTML={{ __html: state.terms }} />
        );
      }
    } else {
      return (
        <div style={{ maxHeight: '500px' }}>
          <DefaultTerm />
        </div>
      );
    }
  }

  const renderLoginInputField = (value, onChange) => {
    return (
      <FormRow align="center" paddingTop="10px">
        {state.isCpf ? (
          <InputFieldOutlined
            mask="999.999.999-99"
            title={getDic("cpf")}
            value={value}
            placeholder={getDic("cpf-placeholder")}
            onChange={onChange}
            onKeyPress={(event) => functions.onKeyPressHandler(event.key)}
            startAdornment={state.useInputAdornments ? <CpfIcon /> : null}
          />
        ) : state.isInternalCode && !state.loginByEmailAndInternalCode ? (
          <InputFieldOutlined
            title={state.internalCodeText}
            value={value}
            placeholder={state.internalCodeText}
            onChange={onChange}
            onKeyPress={(event) => functions.onKeyPressHandler(event.key)}
            startAdornment={state.useInputAdornments ? <LockIcon /> : null}
          />
        ) : state.safeLoginConfig.isSafePassword ? (
          <InputFieldOutlined
            mask={state.safeLoginConfig.cpfMask ? "999.999.999-99" : null}
            title={state.safeLoginConfig.title}
            value={value}
            placeholder={state.safeLoginConfig.placeholder}
            onChange={onChange}
            onKeyPress={(event) => functions.onKeyPressHandler(event.key)}
            startAdornment={state.useInputAdornments ? <LockIcon /> : null}
          />
        ) : (
          <InputFieldOutlined
            title={getDic("email")}
            value={value}
            placeholder={getDic("email-placeholder")}
            onChange={onChange}
            onKeyPress={(event) => functions.onKeyPressHandler(event.key)}
            startAdornment={state.useInputAdornments ? <EmailIcon /> : null}
          />
        )}
      </FormRow>
    )
  }

  const renderCardContent = () => {
    if (state.microsoftSso && !state.isAdmin && !state.isStaff) {
      return (
        <FormColumn align="center">
          <FormRow align="center">
            <div style={{ width: "100%", maxWidth: "600px" }}>
              <img alt="" src={state.image001} style={{ width: "100%" }} />
            </div>
          </FormRow>
          <TextBig
            fontSize={state.primaryTitle.size}
            color={state.primaryTitle.color}
          >
            {state.primaryTitle.text}
          </TextBig>
          <TextSmall
            fontSize={state.secondaryTitle.size}
            color={state.secondaryTitle.color}
          >
            {state.secondaryTitle.text}
          </TextSmall>
          <br />
          {state.hasLang && (
            <div
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
            >
              <SelectList
                title={`${getDic('selecione')} ${getDic('idioma')}`}
                width="50%"
                value={state.language}
                menuItens={state.languages}
                onChange={async (e) => functions.changeLanguage(e.target.value)}
              />
            </div>
          )}

          {state.terms && (
            <>
              <br />
              <FormRow align="center">
                <Link color={state.tittle003.color}>
                  <TextMedium
                    color={state.tittle003.color}
                    fontSize={state.tittle003.size}
                    fontWeight="bold"
                    onClick={() => {
                      state.setOpenModalTerms(true);
                      state.setTermsCheckboxDisabled(false);
                    }}
                  >
                    {state.tittle003.text}
                  </TextMedium>
                </Link>
              </FormRow>
              {state.isComboboxTerms ? (
                <FormRow align="center" margin="0px">
                  <div style={{ paddingTop: 12, paddingRight: 5 }}>
                    <TextMedium
                      fontWeight="bold"
                      textColor={state.tittle004.color}
                      fontSize={state.tittle004.size}
                    >
                      {state.tittle004.text}
                    </TextMedium>
                  </div>
                  <SelectList
                    width="100px"
                    value={state.checkAuthorization}
                    menuItens={[
                      { text: getDic("nao"), value: false },
                      { text: getDic("sim"), value: true }
                    ]}
                    onChange={async (event) => {
                      state.setCheckAuthorization(event.target.value)
                    }}
                  />
                </FormRow>
              ) : (
                <FormRow align="center" margin="0px">
                  <div style={{ paddingRight: 10, cursor: 'pointer' }}>
                    <TextMedium
                      color={state.tittle004.color}
                      fontSize={state.tittle004.size}
                      fontWeight="bold"
                      onClick={() => {
                        state.setOpenModalTerms(true);
                        state.setTermsCheckboxDisabled(false);
                      }}
                    >
                      {state.tittle004.text}
                    </TextMedium>
                  </div>
                  <Checkbox
                    checked={state.checkAuthorization}
                    onClick={() => {
                      if (state.autoOpenTerms) {
                        if (!state.termsCheckboxDisabled && !state.checkAuthorization) {
                          state.setOpenModalTerms(true);
                          state.setTermsCheckboxDisabled(false);
                        }
                        state.setCheckAuthorization(state.checkAuthorization === true ? false : true);
                      } else {
                        if (!state.termsCheckboxDisabled) {
                          state.setCheckAuthorization(state.checkAuthorization === true ? false : true);
                        }
                        else {
                          state.setAlertTitle(getDic('atencao'));
                          state.setAlertMessage(`${getDic("necessario")} ${getDic("ler")} ${getDic("termos")}`);
                          state.setAlertOpen(true);
                        }
                      }
                    }}
                  />
                </FormRow>
              )}
            </>
          )}

          <br />
          <Button
            color='lightBlue'
            textColor='black'
            text='Login Microsoft'
            onClick={() => functions.openSsoMicrosoftLoginPopup()}
          />

          {state.button5.active ? (
            <>
              <br />
              <Button
                color={state.button5.color}
                textColor={state.button5.textColor}
                text={state.button5.text}
                onClick={() => {
                  window.open(state.button5.url, "_blank");
                }}
              />
            </>
          ) : null}

          <FormRow align="center" margin="0px">
            <div style={{ width: "100%", maxWidth: "600px" }}>
              <img alt="" src={state.image003} style={{ width: "100%" }} />
            </div>
          </FormRow>
        </FormColumn>
      )
    }


    return (
      <FormColumn align="center">
        <FormRow align="center">
          <div style={{ width: "100%", maxWidth: "600px" }}>
            <img alt="" src={state.image001} style={{ width: "100%" }} />
          </div>
        </FormRow>
        <TextBig
          fontSize={state.primaryTitle.size}
          color={state.primaryTitle.color}
        >
          {state.primaryTitle.text}
        </TextBig>
        <TextSmall
          fontSize={state.secondaryTitle.size}
          color={state.secondaryTitle.color}
        >
          {state.secondaryTitle.text}
        </TextSmall>
        <br />
        {state.useNameInForm && (
          <FormRow align="center" paddingTop="10px">
            <InputFieldOutlined
              title={state.useNameInForm && state.useLastName ? getDic("nome") : getDic("nome-completo")}
              value={state.accessForm.name}
              placeholder={getDic("seu-nome")}
              onChange={(event) =>
                functions.onChangeHandler("name", event.target.value)
              }
              onKeyPress={(event) => functions.onKeyPressHandler(event.key)}
              startAdornment={state.useInputAdornments ? <NameIcon /> : null}
            />
          </FormRow>
        )}
        {state.useNameInForm && state.useLastName && (
          <FormRow align="center" paddingTop="10px">
            <InputFieldOutlined
              title={getDic("sobrenome")}
              value={state.accessForm.lastName}
              placeholder={getDic("seu-nome")}
              onChange={(event) =>
                functions.onChangeHandler("lastName", event.target.value)
              }
              onKeyPress={(event) => functions.onKeyPressHandler(event.key)}
              startAdornment={state.useInputAdornments ? <NameIcon /> : null}
            />
          </FormRow>
        )}
        {renderLoginInputField(state.accessForm.email, (event) => functions.onChangeHandler("email", event.target.value))}
        {/* Exibir campo de senha usuário quando estiver parametrizado */}
        {state.isAdmin || state.isStaff || state.safeLoginConfig.isSafePassword || state.hasPasswordUser || state.loginByEmailAndInternalCode ? (
          <FormRow align="center" paddingTop="10px">
            <InputFieldOutlined
              type={state.loginByEmailAndInternalCode && !state.useInternalCodeHasPassword ? null : "password"}
              title={state.loginByEmailAndInternalCode ? state.internalCodeText : getDic("senha")}
              value={state.passwordUser}
              placeholder={state.loginByEmailAndInternalCode ? state.internalCodeText : getDic("senha")}
              onChange={(event) =>
                state.setPasswordUser(event.target.value)
              }
              onKeyPress={(event) => functions.onKeyPressHandler(event.key)}
              startAdornment={state.useInputAdornments ? <LockIcon /> : null}
            />
          </FormRow>
        ) : null}
        {state.hasLang && (
          <div
            style={{
              textAlign: "left",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            <SelectList
              title={`${getDic('selecione')} ${getDic('idioma')}`}
              width="50%"
              value={state.language}
              menuItens={state.languages}
              onChange={async (e) => functions.changeLanguage(e.target.value)}
            />
          </div>
        )}
        {state.useRecaptcha && !state.enableTwoFactorLogin && !state.openLoading && (
          <FormRow margin="0px" paddingTop="10px" paddingBottom="10px">
            <Recaptcha
              onChange={(res) => state.setRecaptchaVerify(res)}
            />
          </FormRow>
        )}

        {state.terms && (
          <>
            <br />
            <FormRow align="center">
              <Link color={state.tittle003.color}>
                <TextMedium
                  color={state.tittle003.color}
                  fontSize={state.tittle003.size}
                  fontWeight="bold"
                  onClick={() => {
                    state.setOpenModalTerms(true);
                    state.setTermsCheckboxDisabled(false);
                  }}
                >
                  {state.tittle003.text}
                </TextMedium>
              </Link>
            </FormRow>
            {state.isComboboxTerms ? (
              <FormRow align="center" margin="0px">
                <div style={{ paddingTop: 12, paddingRight: 5 }}>
                  <TextMedium
                    fontWeight="bold"
                    textColor={state.tittle004.color}
                    fontSize={state.tittle004.size}
                  >
                    {state.tittle004.text}
                  </TextMedium>
                </div>
                <SelectList
                  width="100px"
                  value={state.checkAuthorization}
                  menuItens={[
                    { text: getDic("nao"), value: false },
                    { text: getDic("sim"), value: true }
                  ]}
                  onChange={async (event) => {
                    state.setCheckAuthorization(event.target.value)
                  }}
                />
              </FormRow>
            ) : (
              <FormRow align="center" margin="0px">
                <div style={{ paddingRight: 10, cursor: 'pointer' }}>
                  <TextMedium
                    color={state.tittle004.color}
                    fontSize={state.tittle004.size}
                    fontWeight="bold"
                    onClick={() => {
                      state.setOpenModalTerms(true);
                      state.setTermsCheckboxDisabled(false);
                    }}
                  >
                    {state.tittle004.text}
                  </TextMedium>
                </div>
                <Checkbox
                  checked={state.checkAuthorization}
                  onClick={() => {
                    if (state.autoOpenTerms) {
                      if (!state.termsCheckboxDisabled && !state.checkAuthorization) {
                        state.setOpenModalTerms(true);
                        state.setTermsCheckboxDisabled(false);
                      }
                      state.setCheckAuthorization(state.checkAuthorization === true ? false : true);
                    } else {
                      if (!state.termsCheckboxDisabled) {
                        state.setCheckAuthorization(state.checkAuthorization === true ? false : true);
                      }
                      else {
                        state.setAlertTitle(getDic('atencao'));
                        state.setAlertMessage(`${getDic("necessario")} ${getDic("ler")} ${getDic("termos")}`);
                        state.setAlertOpen(true);
                      }
                    }
                  }}
                />
              </FormRow>
            )}
          </>
        )}

        <br />
        <Button
          text={state.button1.text}
          color={state.button1.color}
          textColor={state.button1.textColor}
          onClick={() => functions.tryLogin()}
        />

        {state.button5.active ? (
          <>
            <br />
            <Button
              color={state.button5.color}
              textColor={state.button5.textColor}
              text={state.button5.text}
              onClick={() => {
                window.open(state.button5.url, "_blank");
              }}
            />
          </>
        ) : null}
        {(state.hasPasswordUser || state.hasFirstAccessPolicy || state.safeLoginConfig.isSafePassword) && (state.hasRecoverPassword || state.safeLoginConfig.hasRecoverPassword) ? (
          <div
            style={{
              paddingTop: "5px",
              paddingBottom: "10px",
              width: "100%",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => state.setDialogForgotPasswordControl({
              ...state.dialogForgotPasswordControl,
              open: true
            })}
          >
            <TextSmall color={state.button4.color}>
              {state.button4.text}
            </TextSmall>
          </div>
        ) : (
          <br />
        )}
        {state.hasPasswordUser && state.hasFirstAccessPolicy ? (
          <Button
            color={state.button3.color}
            textColor={state.button3.textColor}
            text={state.button3.text}
            onClick={() => {
              state.setIsForgotPassword(false);
            }}
          />
        ) : null}
        {state.hasPasswordUser && state.hasFirstAccessPolicy ? (
          <br />
        ) : null}
        {state.hasRegister ? (
          <Button
            color={state.button2.color}
            textColor={state.button2.textColor}
            text={state.button2.text}
            onClick={() => functions.navigateToRegistrationPage()}
          />
        ) : null}
        <br />
        <div style={{
          display: state.facebook || state.google ? 'block' : 'none',
          overflow: 'hidden',
          height: 100,
          marginTop: 35
        }}>
          <span style={{
            color: "white",
            fontWeight: 601,
            fontSize: 14
          }}>ou, se preferir, conecte-se com</span>
          <br />
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid key={1} item>
                  {state.facebook && <SSO.LoginFacebook />}
                </Grid>
                <Grid key={2} item>
                  {state.google && <SSO.LoginGoogle />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <FormRow align="center" margin="0px">
          <div style={{ width: "100%", maxWidth: "600px" }}>
            <img alt="" src={state.image003} style={{ width: "100%" }} />
          </div>
        </FormRow>
      </FormColumn>
    );
  }

  if (state.isLoading) {
    return (
      <Container background="#e8eced" align="center">
        <FormRow align="center">
          <ActivityIndicator />
        </FormRow>
      </Container>
    );
  } else if (!state.hasEvent) {
    return (
      <Container background="#e8eced" align="center">
        <FormRow align="center">
          <TextBig>{getDic("evento-nao-localizado-mensagem")}</TextBig>
        </FormRow>

        <Alert
          open={state.alertOpen}
          onClose={() => state.setAlertOpen(false)}
          onClick={() => state.setAlertOpen(false)}
          title={state.alertTitle}
          message={state.alertMessage}
        />
      </Container>
    );
  } else if (state.blockAccess.blocked) {
    return (
      <Container background={state.colorBackGround} align="center">
        <img src={state.blockAccess.url} className="bg" style={{ width: "100%", height: "100%" }} />
        <div className="admin-dashboard">
          {state.blockAccess.url === "" ? (
            <FormRow align="center" background={state.colorBackGround}>
              <TextBig>{getDic("evento-nao-disponivel")}</TextBig>
            </FormRow>
          ) : null}
          {state.blockAccess.url !== "" && state.blockAccess.url2 !== "" && (
            <FormRow align="center">
              <div style={{ width: "100%", maxWidth: "600px", zIndex: "10" }}>
                <img alt="" src={state.blockAccess.url2} style={{ width: "100%" }} />
              </div>
            </FormRow>
          )}
        </div>
      </Container>
    );
  }
  return (
    <Container background={state.colorBackGround} align="center" heigth="100%">
      <FormColumn
        margin="0px"
        backgroundImage={state.imageBackgroundUrl}
        backgroundSize="100% 100%"
      >
        <div style={{ position: "relative", width: "100%", height: "100vh", overflow: "auto" }}>
          <div className="admin-dashboard">
            <FormRow
              align="center"
              background={state.colorBackGround}
              margin="0px"
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: "600px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {!state.cardConfig ? (
                  <Card>{renderCardContent()}</Card>
                ) : state.cardConfig === "CARD-SIMP" ? (
                  <Card cardType="simple">{renderCardContent()}</Card>
                ) : (
                  <Card cardType="transparent">{renderCardContent()}</Card>
                )}
              </div>
            </FormRow>

            <Dialog
              open={state.dialogForgotPasswordControl.open}
              onClose={() => {
                state.setDialogForgotPasswordControl({
                  open: false,
                  waiting: false,
                  showInsertToken: false,
                });
                state.setForgotPasswordObject({
                  login: '',
                  token: '',
                  newPassword1: '',
                  newPassword2: '',
                })
              }}
              title={`${getDic("redefinir")} ${getDic("senha")}`}
            >
              {state.dialogForgotPasswordControl.waiting ? (
                <FormRow align="center" paddingTop="5px">
                  <ActivityIndicator />
                </FormRow>
              ) : (
                <>
                  {renderLoginInputField(
                    state.forgotPasswordObject.login,
                    (event) => state.setForgotPasswordObject({
                      ...state.forgotPasswordObject,
                      login: event.target.value
                    })
                  )}
                  {!state.dialogForgotPasswordControl.showInsertToken ? (
                    <>
                      <FormRow align="center" paddingTop="5px">
                        <TextSmall>{getDic("esqueceu-a-senha-mensagem")}</TextSmall>
                      </FormRow>
                      <FormRow align="center" paddingTop="0px">
                        <TextSmall>{getDic("isto-pode-levar-minutos")}</TextSmall>
                      </FormRow>
                    </>
                  ) : (
                    <>
                      <FormRow align="center" paddingTop="10px">
                        <InputFieldOutlined
                          mask="999999"
                          title='Token'
                          value={state.forgotPasswordObject.token}
                          placeholder='Token'
                          onChange={(event) => state.setForgotPasswordObject({
                            ...state.forgotPasswordObject,
                            token: event.target.value
                          })}
                        />
                      </FormRow>
                      <FormRow align="center" paddingTop="10px">
                        <InputFieldOutlined
                          title={`${getDic("nova")} ${getDic("senha")}`}
                          value={state.forgotPasswordObject.newPassword1}
                          placeholder={`${getDic("nova")} ${getDic("senha")}`}
                          onChange={(event) => state.setForgotPasswordObject({
                            ...state.forgotPasswordObject,
                            newPassword1: event.target.value
                          })}
                        />
                      </FormRow>
                      <FormRow align="center" paddingTop="10px">
                        <InputFieldOutlined
                          title={`${getDic("confirmar")} ${getDic("nova")} ${getDic("senha")}`}
                          value={state.forgotPasswordObject.newPassword2}
                          placeholder={`${getDic("confirmar")} ${getDic("nova")} ${getDic("senha")}`}
                          onChange={(event) => state.setForgotPasswordObject({
                            ...state.forgotPasswordObject,
                            newPassword2: event.target.value
                          })}
                        />
                      </FormRow>
                    </>
                  )}

                  <FormRow align="center" paddingTop="5px">
                    <Button
                      text="Ok"
                      onClick={() => functions.forgotPasswordRoutine()}
                    />
                  </FormRow>
                </>
              )}
            </Dialog>

            <Modal
              isVideo
              open={state.openModalTerms}
              closeButtonClick={(e) => {
                state.setOpenModalTerms(false);
              }}
              onClose={(ret) => {
                state.setOpenModalTerms(false);
              }}
            >
              <Container background="#e8eced" align="left" height="100%">
                {renderTerms()}
              </Container>
            </Modal>

            <Dialog
              open={state.openModalTwoFactorLogin}
              onClose={() => {
                state.setOpenModalTwoFactorLogin(false);
              }}
              title={getDic("login-dois-fatores")}
            >
              <FormRow align="center" paddingTop="5px">
                <TextSmall>
                  {getDic("login-dois-fatores-mensagem")}
                </TextSmall>
              </FormRow>
              <FormRow align="center" paddingTop="5px">
                <InputFieldOutlined
                  title="Token"
                  value={state.twoFactorLoginToken}
                  onChange={(event) =>
                    state.setTwoFactorLoginToken(event.target.value)
                  }
                />
              </FormRow>
              {state.useRecaptcha && state.enableTwoFactorLogin && !state.openLoading && (
                <FormRow margin="0px" paddingTop="10px" paddingBottom="10px">
                  <Recaptcha
                    onChange={(res) => state.setRecaptchaVerify(res)}
                  />
                </FormRow>
              )}
              <FormRow align="center" paddingTop="5px">
                <Button
                  text={getDic("enviar")}
                  onClick={() => functions.validateTokenTwoFactorLogin()}
                />
              </FormRow>
            </Dialog>

            <Alert
              open={state.alertOpen}
              onClose={() => state.setAlertOpen(false)}
              onClick={() => state.setAlertOpen(false)}
              title={state.alertTitle}
              message={state.alertMessage}
            />

            <Loading
              open={state.openLoading}
              message={state.loadingMessage}
            />
          </div>
          <ShowZendesk />

          {renderModalAche()}
        </div>
      </FormColumn>
    </Container>
  );
}

export default LoginPageVisual;
