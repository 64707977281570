import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { store } from '../store';
import { userLogout } from '../store/actions';
import { insertNewAccess, getSystemActions, insertNewAccessListApi } from '../services/accessService';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AppBarExitButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
        }
    }

    openDialog() {
        this.setState({ dialogOpen: true });
    }

    async logoutApp() {
        this.setState({ dialogOpen: false });
        await insertNewAccess("system", "", getSystemActions().exitPage);

        const access = store.getState().access;
        insertNewAccessListApi(access)
            .then(ret => {
                console.log("Ok");
            })
            .catch(err => {
                console.log("Erro insertNewAccessListApi", err);
            })
            .finally(async () => {
                await this.props.userLogout();
                this.props.history.replace("/" + this.props.event.id);
            });
    }

    render() {
        const { color = null, size = null } = this.props;

        if (this.state.dialogOpen) {
            return (
                <div>
                    <Button
                        color={color ? color : "inherit"}
                        size={size ? size : "medium"}
                        onClick={e => this.openDialog()}
                    >
                        {getDic("sair")}
                    </Button>
                    <Dialog
                        open={this.state.dialogOpen}
                        onClose={() => this.setState({ dialogOpen: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{getDic("sair")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {getDic("deseja-sair-sistema")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ dialogOpen: false })} color="primary">
                                {getDic("nao")}
                            </Button>
                            <Button onClick={() => this.logoutApp()} color="primary" autoFocus>
                                {getDic("sim")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
        else {
            return (
                <Button
                    color={color ? color : "inherit"}
                    size={size ? size : "medium"}
                    onClick={e => this.openDialog()}
                >
                    {getDic("sair")}
                </Button>
            );
        }

    };
}

function mapStateToProps(state) {
    return {
        event: state.event
    }
}

const mapDispatchToProps = {
    userLogout
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBarExitButton)
