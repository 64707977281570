import { CHOSEN_PAVILION, RESET_PAVILION } from '../actions';

export default function chosenPavilionReducer(state = null, action) {
    switch (action.type) {
        case CHOSEN_PAVILION:
            return action.pavilion;
        case RESET_PAVILION:
            return null;
        default:
            return state;
    }
}
